import React, { Component } from "react";
import TextFieldCustom from "../../atoms/TextFieldCustom";
import iconSearch from "../../../assets/image/icons/svg/search-icon-grey.svg";
import { campaign } from "../../../actions/campaign";
import { filters } from "../../../actions/filters";
import TooltipCustom from "../../atoms/tooltipCustom";
import { app } from "../../../actions/app";
import "./style.scss";
import ButtonCreateCampaigns from "../../atoms/buttonCreateCampaigns";

class CampaignFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: "",
      filters: {
        active: false,
        stopped: false,
        completed: false,
        drafted: false,
        cancelled: false,
      },
      filtersActive: false,
      searchActive: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.state.filtersActive &&
        prevState.filtersActive !== this.state.filtersActive) ||
      (this.state.searchActive &&
        prevState.searchActive !== this.state.searchActive)
    ) {
      this.props.isSearchOrFilterApplied(true);
    }

    if (
      (prevState.filtersActive !== this.state.filtersActive &&
        this.state.filtersActive === false) ||
      (prevState.searchActive !== this.state.searchActive &&
        this.state.searchActive === false)
    ) {
      this.props.isSearchOrFilterApplied(false);
    }
  }

  onSearchChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  onSearchEnter = (e) => {
    if (this.state.searchValue !== this.props.searchValue) {
      campaign.searchValue(this.state.searchValue);
      this.setState({ searchActive: true });
      this.props.setTableCampaignsStatisticsPage(0);
      app.changeTableContentLoaderStatus(true);
      if (this.props.archive) {
        filters.getCampaigns({
          archived: true,
          keywords: e.target.value,
        });
      } else {
        const filtersArray = this.props.buildFiltersArray(this.props.filters);

        this.props.setTableCampaignsStatisticsPage(0);
        filters.getCampaigns({
          archived: false,
          filter: `${filtersArray.join(" or ")}`,
          keywords: e.target.value,
          orderby: this.props.orderby,
        });
      }
      if (this.state.searchValue === "") {
        this.setState({ searchActive: false });
      }
    }
  };

  filterController = async (type) => {
    await campaign.changeFilterStatus({
      ...this.props.filters,
      [type]: !this.props.filters[type],
    });
    this.getCampaignsByStatus(this.props.filters);
    this.setState({
      filtersActive: Object.values(this.props.filters).some(
        (el) => el === true
      ),
    });
  };

  getCampaignsByStatus = (stateFilters) => {
    this.props.setTableCampaignsStatisticsPage(0);
    app.changeTableContentLoaderStatus(true);
    const filtersArray = this.props.buildFiltersArray(stateFilters);
    filters.getCampaigns({
      archived: false,
      filter: `${filtersArray.join(" or ")}`,
      keywords: this.props.searchValue,
      orderby: this.props.orderby,
    });
  };

  render() {
    return (
      <div className="table-container-controllers">
        <p className="campaigns-title">Campaigns</p>
        <div className="btn-group">
          <div className="filter-search">
            <TextFieldCustom
              value={this.state.searchValue}
              onChange={this.onSearchChange}
              onBlur={this.onSearchEnter}
              onEnter={this.onSearchEnter}
              placeholder={"Search"}
              endAdornment={
                <img
                  className="icon-search"
                  src={iconSearch}
                  alt="search"
                  onClick={this.onSearchEnter}
                />
              }
            />
          </div>
          {!this.props.archive && (
            <div className="filter-status">
              <span className="text-page-grey">Status: </span>
              <TooltipCustom title={"draft"} arrow={true}>
                <button
                  className={
                    this.props.filters.drafted
                      ? "btn-filter drafted open"
                      : "btn-filter drafted"
                  }
                  onClick={(e) => this.filterController("drafted")}
                >
                  <div className="dot-grey"></div>
                </button>
              </TooltipCustom>
              <TooltipCustom title={"paused"} arrow={true}>
                <button
                  className={
                    this.props.filters.stopped
                      ? "btn-filter paused open"
                      : "btn-filter paused"
                  }
                  onClick={(e) => this.filterController("stopped")}
                >
                  <div className="dot-yellow"></div>
                </button>
              </TooltipCustom>
              <TooltipCustom title={"active"} arrow={true}>
                <button
                  className={
                    this.props.filters.active
                      ? "btn-filter active open"
                      : "btn-filter active"
                  }
                  onClick={(e) => this.filterController("active")}
                >
                  <div className="dot-green"></div>
                </button>
              </TooltipCustom>
              <TooltipCustom title={"completed"} arrow={true}>
                <button
                  className={
                    this.props.filters.completed
                      ? "btn-filter completed open"
                      : "btn-filter completed"
                  }
                  onClick={(e) => this.filterController("completed")}
                >
                  <div className="dot-blue"></div>
                </button>
              </TooltipCustom>
            </div>
          )}
          <ButtonCreateCampaigns
            type="new"
            classes="action-btn campaign"
            text={"Add campaign"}
            history={this.props.history}
          />
        </div>
      </div>
    );
  }
}

export default CampaignFilters;
