import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
// components
import AuthTemplate from "../../templates/authTemplate";
// img
import dogImg from "../../../assets/image/snail-looking-for.png";
import { CONSTS } from "../../../config/objectConst.js";

export const BadAuthContainer = ({ connector }) => {
  const history = useHistory();
  return (
    <AuthTemplate active={2} show={2}>
      <h2>Oops! We couldn’t connect {CONSTS.PROJECT_NAME} to your account</h2>
      <img src={dogImg} alt="unAuth" width={150} />
      <p className="text-page-auth-modal-desc" style={{ marginBottom: "21px" }}>
        Please, check your Google and/or G-Suite account <br />
        security settings
      </p>
      <button
        onClick={async () => {
          history.push("/");
        }}
      >
        Back
      </button>
    </AuthTemplate>
  );
};

const mapStateToProps = function (state) {
  return {
    connector: state.app.connector,
  };
};

export default connect(mapStateToProps)(BadAuthContainer);
