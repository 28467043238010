import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { HomeTemplate } from "../../components/templates/homeTemplate";
import ErrorNotification from "../../components/atoms/ErrorNotification";
import Mailbox from "./components/Mailbox/Mailbox";
import emptySnail from "../../assets/image/snail-no.png";
import { useEffect } from "react";
import store from "../../redux/store";
import accountSvg from "../../assets/image/icons/svg/account.svg";

const getCurrentAppProfile = (props) => {
  const result = props.profile.appProfile.impersonatedPersona
    ? props.profile.appProfile.impersonatedPersona
    : props.profile.appProfile;
  return result;
};

const MailboxPage = (props) => {
  useEffect(() => {
    let response = {
      type: "mailbox",
      bottomList: [
        {
          id: 1,
          image: accountSvg,
          title: " Mailbox",
          link: "/mailbox",
        },
      ],
    };
    store.dispatch({
      type: "LEFT_SIDE_MENU_LIST",
      payload: {
        leftSide: response,
      },
    });
  }, []);

  const shouldShowMailbox = getCurrentAppProfile(props).isBackendAutomation;
  return (
    <HomeTemplate id="mailbox">
      {(!props.app.linkedInAuth || !props.app.associatedLinkedInProfile) && (
        <ErrorNotification>
          <div className="error-notification__not-auth">
            You are not logged in to LinkedIn with{" "}
            <span onClick={props.app?.openLinkedinProfile}>
              {`${props.profile.linkedInfo.givenName} ${props.profile.linkedInfo.familyName} account.`}
            </span>
          </div>
        </ErrorNotification>
      )}
      {shouldShowMailbox ? (
        <Mailbox />
      ) : (
        <div className="empty-state">
          <p>Mailbox is not available</p>
          <img
            className="mailbox-search-image"
            alt="noResult"
            src={emptySnail}
          />
        </div>
      )}
    </HomeTemplate>
  );
};

const mapStateToProps = function (state) {
  return {
    app: state.app,
    profile: state.user.profileFull,
  };
};

export default connect(mapStateToProps)(withRouter(MailboxPage));
