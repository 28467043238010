import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDatePicker from "../ReactDatePicker";
import "./style.scss";

export class DatePickerRange extends Component {
  static propTypes = {
    startDate: PropTypes.any,
    endDate: PropTypes.any,
    onChangeStartDate: PropTypes.func.isRequired,
    onChangeEndDate: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div className="date-picker-range">
        <ReactDatePicker
          value={this.props.startDate}
          onChange={this.props.onChangeStartDate}
          selectsStart
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          placeholder="From"
          maxDate={this.props.endDate}
        />
        <ReactDatePicker
          value={this.props.endDate}
          onChange={this.props.onChangeEndDate}
          selectsEnd
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          placeholder="To"
          minDate={this.props.startDate}
          popperPlacement="bottom-end"
        />
      </div>
    );
  }
}

export default DatePickerRange;
