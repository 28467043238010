import store from "../redux/store";
import ReactHtmlParser from "react-html-parser";
import { helpersFunc } from "../config/helpers";
import { errors } from "../config/errors";
import { CONSTS } from "../config/objectConst";
import NotAuthorizedError from "../errors/NotAuthorizedError";

export const notification = {
  notificationUse(error, isError = true, duration = 5000) {
    if (isSubscriptionExpired(error)) {
      return;
    }

    let text = error;
    if (error instanceof Error) {
      text = errors.build(error);
      helpersFunc.logEnjectServicePromise(error);
    }
    store.dispatch({
      type: "NOTIFICATION",
      payload: {
        ...{ text: text, status: true, error: isError },
      },
    });
    setTimeout(() => {
      store.dispatch({
        type: "NOTIFICATION",
        payload: {
          ...{ text: "", status: false, error: isError },
        },
      });
    }, duration);
  },
  snacknotificationUse(message) {
    store.dispatch({
      type: "SNACK_NOTIFICATION",
      payload: {
        message: { ...message, content: ReactHtmlParser(message.content) },
      },
    });
  },
};

export const isSubscriptionExpired = function (error) {
  const appProfile = store.getState().app.appProfile;
  if (
    error instanceof NotAuthorizedError &&
    !appProfile?.subscriptionIsActive
  ) {
    notification.snacknotificationUse({
      open: true,
      title: CONSTS.notification["3.17"].title,
      content: CONSTS.notification["3.17"].content,
      button: CONSTS.notification["3.17"].button,
      style: CONSTS.notification["3.17"].style,
    });
    return true;
  }

  return false;
};
