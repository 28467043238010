import { api } from "../api/api";
import store from "../redux/store";
import { app } from "./app";
import { mapping } from "./mapping";
import { notification } from "./notification";

export const teams = {
  getMembers: async () => {
    const response = await api.getTeamMembers();
    return response;
  },

  inviteMember: async (email) => {
    const response = await api.inviteTeamMember(email);
    return response;
  },

  resendInvitation: async (id) => {
    const response = await api.resendTeamInvitation(id);
    return response;
  },

  acceptInvitation: async (code) => {
    const response = await api.acceptTeamInvitation(code);
    return response;
  },

  deleteMember: async (id) => {
    const response = await api.deleteTeamMember(id);
    return response;
  },

  cancelTeamMemberSubscription: async (id) => {
    const response = await api.cancelTeamMemberSubscriptionApi(id);
    return response;
  },
  resumeTeamMemberSubscription: async (id) => {
    const response = await api.resumeTeamMemberSubscriptionApi(id);
    return response;
  },

  buyMemberSubscription: async (id, plan, coupon) => {
    const response = await api.buyMemberSubscriptionApi(id, plan, coupon);
    return response;
  },
  upgradeMemberSubscription: async (id, plan, coupon) => {
    const response = await api.upgradeMemberSubscriptionApi(id, plan, coupon);
    return response;
  },
  editMemberNickName: async (data) => {
    const response = await api.editMemberNickNameApi(data);
    return response;
  },
  getCampaignsTeam: async (data) => {
    !store.getState().app.errorRequest.campaignsTeams &&
      app.setErrorRequest({
        ...store.getState().app.errorRequest,
        campaignsTeams: true,
      });
    try {
      const response = await api.getCampaignsTeamApi(data);
      store.dispatch({
        type: "CAMPAIGNS_TEAMS_ARRAY",
        payload: {
          campaignsTeams: response.data
            ? mapping.buildCampaignsArray(response.data.documents)
            : [],
          campaignsTeamsCount: response.data.total,
        },
      });

      if (response.data) {
        if (response.data.documents.length > 0) {
          teams.haveCampaignsTeams(true);
        }
      }
      app.setErrorRequest({
        ...store.getState().app.errorRequest,
        campaigns: false,
      });
    } catch (error) {
      notification.notificationUse(error);
      store.dispatch({
        type: "CAMPAIGNS_TEAMS_ARRAY",
        payload: {
          campaigns: [],
        },
      });
    }
  },
  haveCampaignsTeams: (status) => {
    store.dispatch({
      type: "HAVE_CAMPAIGNS_TEAMS",
      payload: {
        haveCampaigns: status,
      },
    });
  },
  searchValueTeams: (value) => {
    store.dispatch({
      type: "SEARCH_VALUE_TEAMS",
      payload: {
        searchValue: value,
      },
    });
  },
  changeTeamsLoaderStatus: (value) => {
    store.dispatch({
      type: "LOAD_CAMPAIGNS_TEAMS_CONTROLLER",
      payload: {
        searchValue: value,
      },
    });
  },
};
