import React from "react";
import DateInput from "../../atoms/DateInput";
import "./style.scss";

const DateSelection = ({
  dayOfWeek,
  dateSelectionHandler,
  handleDateChange,
  hours,
}) => {
  return (
    <div
      className={`date-selection ${
        hours.errorMessage.length && hours.isSelected
          ? "date-selection-error"
          : ""
      }`}
    >
      <label className="container-label">
        <input
          type="checkbox"
          checked={hours.isSelected}
          onChange={(e) => dateSelectionHandler(dayOfWeek)}
        />
        <span className="data-text">
          {dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1)}
        </span>
      </label>
      <DateInput
        day={dayOfWeek}
        handleDateChange={handleDateChange}
        value={hours.value}
        errorMessage={hours.errorMessage}
        disabled={!hours.isSelected}
      />
    </div>
  );
};

export default DateSelection;
