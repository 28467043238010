import React, { useEffect, useRef, useState } from "react";
import user from "../../../assets/image/icons/svg/user.svg";
import attach from "../../../assets/image/icons/svg/attach.svg";
import star from "../../../assets/image/icons/svg/star.svg";
import menu from "../../../assets/image/icons/svg/icons/menu.svg";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { findRenderContent, formatDateTime } from "../functions/functions";
import { notification } from "../../../actions/notification";
import { automationApi } from "../../../api/automationApi";

const contentTypePreviewText = {
  vector_image: "an attachment",
  file: "an attachment",
  audio: "a voice message",
  unsupported_content: "a post",
  external_media: "a GIF",
  video_meeting: "a video meeting",
};

export const renderFormat = {
  DEFAULT: "DEFAULT",
  RECALLED: "RECALLED",
  SYSTEM: "SYSTEM",
  EDITED: "EDITED",
};

const MailboxItem = ({ data, userEntityId, onClick, key, ...props }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [isUnread, setIsUnread] = useState(true);
  const menuRef = useRef(null);
  const isMessageDeleted =
    data.events[0].render_format === renderFormat.RECALLED;
  const isSystemMessage = data.events[0].render_format === renderFormat.SYSTEM;
  const activeMailboxItem = props.chosenMailboxId === data?.entity_id;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !event.target.classList.contains("mailbox-menu-icon")
      ) {
        setOpenMenu(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  function getInterlocutorById() {
    const participant = data?.participants.find(
      (participant) => participant.entityId !== userEntityId
    );
    return participant;
  }

  const isInterlocutor =
    getInterlocutorById().entityId === data.events[0].from.entityId;

  function showPreviewText() {
    const sender = isInterlocutor
      ? getInterlocutorById().firstName + " sent"
      : "You sent";
    const renderContent = findRenderContent(data.events[0].render_content);
    const showAttachIcon =
      renderContent === "vector_image" || renderContent === "file";
    const subject = data.events[0].subject;
    return renderContent ? (
      <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
        <p className="mailbox-conversation-type">{data.conversation_type}</p>
        {showAttachIcon && !subject && (
          <img style={{ width: "14px" }} alt="attach" src={attach} />
        )}
        <p>{`${data.conversation_type ? "" : sender} ${
          subject ? subject : contentTypePreviewText[renderContent]
        }`}</p>
      </div>
    ) : subject ? (
      subject
    ) : (
      data.events[0].text
    );
  }

  const handleClickContextMenu = (e) => {
    e.stopPropagation();
    setOpenMenu(!openMenu);
  };

  const handleMailboxItemClick = async () => {
    onClick();
    if (data.unread_count) {
      try {
        await automationApi.markConversationAsRead(data.entity_id);
        setIsUnread(false);
      } catch (error) {
        notification.notificationUse(error);
      }
    }
  };

  const showUnread = data.unread_count && isUnread;

  return (
    <div
      className={
        activeMailboxItem
          ? "mailbox-user-wrapper active_mailbox"
          : "mailbox-user-wrapper"
      }
      key={key}
      onClick={handleMailboxItemClick}
    >
      <img
        alt="avatar"
        className="mailbox-user-avatar"
        src={
          getInterlocutorById()?.pictureUrl
            ? getInterlocutorById()?.pictureUrl
            : user
        }
      />
      <div>
        <div className="mailbox-user-name-wrapper">
          <p className="mailbox-user-name">{getInterlocutorById()?.fullName}</p>
          <img
            onClick={(e) => handleClickContextMenu(e)}
            className="mailbox-menu-icon"
            src={menu}
            alt="menu"
          />
          <p className="mailbox-user-date">
            {formatDateTime(data.events[0].created_at)}
          </p>
        </div>
        <p className="mailbox-headline">{data.headline}</p>
        <div className="mailbox-user-text-wrapper">
          {isMessageDeleted ? (
            <p className="mailbox-user-text">This message has been deleted.</p>
          ) : (
            <p className="mailbox-user-text">
              {!findRenderContent(data.events[0].render_content) &&
                !isSystemMessage && (
                  <span
                    className={
                      data.conversation_type && "mailbox-conversation-type"
                    }
                  >
                    {data.conversation_type
                      ? data.conversation_type + " "
                      : isInterlocutor
                      ? getInterlocutorById()?.firstName + ": "
                      : "You: "}
                  </span>
                )}
              {showPreviewText()}
            </p>
          )}
          {data.is_starred ? (
            <img className="mailbox-star" src={star} alt="star" />
          ) : null}
          {showUnread ? (
            <div className="mailbox-unread">{data.unread_count}</div>
          ) : null}
        </div>
      </div>
      {openMenu && (
        <div
          ref={menuRef}
          onClick={(e) => e.stopPropagation()}
          className="mailbox-menu"
        >
          <p>Move to Other</p>
          <p>Mark as unread</p>
          <p>Star</p>
          <p>Archive</p>
          <p>Report / Block</p>
          <p>Delete conversation</p>
          <p>Manage settings</p>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    chosenMailboxId: state.mailbox.chosenMailboxId,
  };
};

export default connect(mapStateToProps)(withRouter(MailboxItem));
