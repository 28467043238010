import React from "react";
import PropTypes from "prop-types";
import { TextField, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

TextFieldBig.propTypes = {
  fullWidth: PropTypes.bool,
  autoFocus: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  endAdornment: PropTypes.node,
};

function TextFieldBig(props) {
  const classes = useStyles();
  const uniqueId = Math.floor(Math.random() * 1000);

  const onEnter = (e) => {
    if (e.key === "Enter") {
      props.onEnter(e);
    }
  };

  const endIcon = props.endAdornment ? (
    <InputAdornment position="end">{props.endAdornment}</InputAdornment>
  ) : (
    ""
  );

  return (
    <div className="text-field">
      {props.label && (
        <label
          htmlFor={`textField-${uniqueId}`}
          className="select-label text-field__label"
        >
          {props.label}
        </label>
      )}
      <TextField
        type="text"
        id={`textField-${uniqueId}`}
        classes={classes}
        autoFocus={props.autoFocus}
        fullWidth={props.fullWidth}
        placeholder={props.placeholder}
        variant="outlined"
        margin="none"
        value={props.value}
        onChange={props.onChange}
        onKeyUp={onEnter}
        inputProps={{
          onBlur: props.onBlur,
        }}
        InputProps={{
          endAdornment: endIcon,
        }}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "450px",
    backgroundColor: "#fff",
    "& .MuiOutlinedInput-input": {
      padding: "6px 10px 6px 13px",
      color: "#202020",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px",
      backgroundColor: "#ffffff",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#EBEDF2",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#0cb39f",
      borderWidth: "1px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#0cb39f",
      borderWidth: "1px",
    },
  },
}));

export default TextFieldBig;
