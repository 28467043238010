/*
 *
 * App reducer
 *
 */
const initialState = {
  active: null,
  leftSide: {
    label: "",
    bottomList: [],
    list: [],
    type: "",
  },
};

const leftSideMenu = (state = initialState, action) => {
  switch (action.type) {
    case "LEFT_SIDE_MENU_LIST":
      return Object.assign({}, state, {
        leftSide: action.payload.leftSide,
      });
    case "LEFT_SIDE_MENU_LIST_ACTIVE":
      return Object.assign({}, state, {
        active: action.payload.active,
      });

    default:
      return state;
  }
};

export default leftSideMenu;
