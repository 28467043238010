import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import "./styles.scss";
import user from "../../../../assets/image/icons/svg/user.svg";
import ResponsiveButton from "../../../../components/atoms/responsiveButton";
import { mailbox } from "../../../../actions/mailbox";
import { renderFormat } from "../MailboxItem";
import { automationApi } from "../../../../api/automationApi";
import { notification } from "../../../../actions/notification";
import {
  findRenderContent,
  formatChatDate,
  formatFileSize,
  formatTime,
} from "../../functions/functions";
import menu from "../../../../assets/image/icons/svg/icons/menu.svg";
import attachments from "../../../../assets/image/icons/svg/icons/attchments.svg";
import smile from "../../../../assets/image/icons/svg/icons/smile.svg";
import attach from "../../../../assets/image/icons/svg/attach.svg";
import arrow from "../../../../assets/image/icons/svg/icons/arrow.svg";
import { CircularProgress } from "@material-ui/core";
import ModalWindow from "../../../../components/molecules/ModalWindow";
import { CONSTS } from "../../../../config/objectConst";

const contentTypes = {
  VectorImage: "vector_image",
  File: "file",
  Audio: "audio",
  ExternalMedia: "external_media",
  VideoMeeting: "video_meeting",
};

const Chats = React.memo((props) => {
  const [sortedMessages, setSortedMessages] = useState([]);
  const [nextClicked, setNextClicked] = useState(false);
  const scrollableRef = useRef(null);
  const { mailboxId } = useParams();
  const [text, setText] = useState("");
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [textareaHeight, setTextareaHeight] = useState("100px");
  const [overflowY, setOverflowY] = useState("hidden");
  const [textareaBlockHeight, setTextareaBlockHeight] = useState(0);
  const [openMenu, setOpenMenu] = useState(false);
  const menuRef = useRef(null);
  const [editMode, setEditMode] = useState(false);
  const [eventId, setEventId] = useState();
  const [nextMailboxListLoader, setNextMailboxListLoader] = useState(false);
  const [chosenMessage, setChosenMessage] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const currentProfileLinkedInEntityId = props.profile.appProfile
    .impersonatedPersona
    ? props.profile.appProfile.impersonatedPersona.linkedInProfile.entityId
    : props.profile.appProfile.linkedInProfile.entityId;
  const entityId = mailboxId ? mailboxId : props.chosenMailboxId?.entity_id;
  const chats = props.recentEvents[entityId]?.events;
  const nextCursor = props.recentEvents[entityId]?.eventsNextCursor;
  const isSystemMessage = (message) => {
    return message.render_format === renderFormat.SYSTEM;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !event.target.classList.contains("context-menu-button")
      ) {
        setOpenMenu(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const thirdBlock = document.querySelector(".mailbox-textarea-block");
    if (thirdBlock) {
      setTextareaBlockHeight(thirdBlock.offsetHeight + 140);
    }
  }, [text, textareaHeight]);

  useEffect(() => {
    if (chats) {
      const sorted = [...chats].sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );
      setSortedMessages(sorted);
    }
  }, [chats]);

  useEffect(() => {
    if (props.bundledEvents) {
      chats.map((chat) => {
        const renderContent = findRenderContent(chat.render_content);
        const content = chat.render_content.length
          ? chat.render_content[0][renderContent]
          : null;
        const shouldFetchUrl =
          renderContent === contentTypes.Audio ||
          renderContent === contentTypes.VectorImage;
        if (shouldFetchUrl && renderContent) {
          mailbox.getAttachment(
            chat.message_id,
            content.url,
            content.recommended_accept_header
          );
        }
      });
    }
  }, [props.bundledEvents]);

  useEffect(() => {
    if (!nextClicked && scrollableRef.current) {
      scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
    }
  }, [sortedMessages, nextClicked]);

  const fetchFileUrl = async (message) => {
    const renderContent = findRenderContent(message.render_content);
    const content = message.render_content[0][renderContent];
    try {
      const response = await automationApi.getAttachment(
        message.message_id,
        content.url,
        content.recommended_accept_header
      );
      const url = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      notification.notificationUse(error);
    }
  };

  const interlocutor = props.mailboxParticipants?.find(
    (participant) => participant.entityId !== currentProfileLinkedInEntityId
  );

  const handleClickContextMenu = (messageId) => {
    setOpenMenu(!openMenu);
    setChosenMessage(messageId);
  };

  const findLinks = (text) => {
    const regex = /((https?|ftp):\/\/[^\s/$.?#].[^\s]*)/gi;
    const elements = text
      .split(regex)
      .filter((element) => element !== "https" && element !== "http");
    return elements.map((element, index) => {
      if (regex.test(element)) {
        return (
          <a
            className="mailbox-link"
            key={index}
            href={element}
            target="_blank"
            rel="noreferrer"
          >
            {element}
          </a>
        );
      } else {
        return <span key={index}>{element}</span>;
      }
    });
  };

  const handleDeleteClick = (message_id) => {
    setSelectedMessageId(message_id);
    setIsDeleteModalOpen(true);
  };

  const displayMessage = (message) => {
    const renderContent = findRenderContent(message.render_content);
    const content = message.render_content.length
      ? message.render_content[0][renderContent]
      : null;
    const url = props.attachmentUrls[message.message_id]?.url;

    if (message.render_format === renderFormat.RECALLED) {
      return (
        <p className="mailbox-deleted-message">
          This message has been deleted.
        </p>
      );
    }
    const isEditedMessage = message.render_format === renderFormat.EDITED;
    const text = (
      <p style={{ whiteSpace: "pre-wrap" }}>
        {findLinks(message.text)}{" "}
        {isEditedMessage && <span style={{ color: "#565657" }}>(Edited)</span>}
      </p>
    );

    if (!renderContent) {
      return text;
    }

    const contentComponents = {
      [contentTypes.ExternalMedia]: (
        <div>
          {message.text && <p className="attachment-text">{text}</p>}
          <img src={content.url} alt="gif" />
        </div>
      ),
      [contentTypes.Audio]: (
        <div>
          {message.text && <p className="attachment-text">{text}</p>}
          <audio src={url} controls />
        </div>
      ),
      [contentTypes.VectorImage]: (
        <div>
          {message.text && <p className="attachment-text">{text}</p>}
          <div className="image-wrapper">
            <img className="chat-image" src={url} alt="image" />
          </div>
        </div>
      ),
      [contentTypes.File]: (
        <div>
          {message.text && <p className="attachment-text">{text}</p>}
          <button
            style={{ background: "transparent", padding: "0" }}
            onClick={() => fetchFileUrl(message)}
          >
            <div className="mailbox-file">
              <p>{message.render_content[0].file?.name}</p>
              <span>
                {formatFileSize(message.render_content[0].file?.byte_size)}
              </span>
            </div>
          </button>
        </div>
      ),
      [contentTypes.VideoMeeting]: (
        <div>
          <p className="attachment-text warning-text">
            Video meetings are not supported
          </p>
        </div>
      ),
    };

    const contentType = renderContent;
    return contentComponents[contentType] || <p>{message.text}</p>;
  };

  const handleChange = (event) => {
    setText(event.target.value);
    let newHeight = event.target.scrollHeight;
    newHeight = Math.min(newHeight, 200);
    newHeight = newHeight > 100 ? newHeight : 100;
    setTextareaHeight(`${newHeight}px`);
    setOverflowY(newHeight > 199 ? "auto" : "hidden");
  };

  const handleClickEdit = (message) => {
    setEditMode(true);
    setText(message.text);
    setEventId(message.message_id);
  };

  const deleteMessage = async () => {
    try {
      await automationApi.deleteMessage(entityId, selectedMessageId);
      await mailbox.getRecentEvents(entityId, true);
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      setIsDeleteModalOpen(false);
    }
  };

  const expandContainer = () => {
    if (textareaHeight === "calc(100vh - 229px)") {
      setTextareaHeight("100px");
    } else {
      setTextareaHeight("calc(100vh - 229px)");
    }
  };

  const cancelEdit = () => {
    setText("");
    setEditMode(false);
  };

  const sendMessage = async () => {
    setLoadingMessage(true);
    try {
      if (editMode) {
        await automationApi.editMessage(entityId, eventId, text);
      } else {
        await automationApi.sendMessage(entityId, text);
      }
      await mailbox.getRecentEvents(entityId, true);
      setEditMode(false);
      setText("");
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      setLoadingMessage(false);
    }
  };
  const handleScroll = () => {
    const element = scrollableRef.current;
    const oldHeight = element.scrollHeight;
    const handleFetchNextEvents = async () => {
      const chats = props.recentEvents[props.chosenMailboxId]?.events;
      const nextCursor =
        props.recentEvents[props.chosenMailboxId]?.eventsNextCursor;
      setNextMailboxListLoader(true);
      try {
        await mailbox.getNextEvents(props.chosenMailboxId, chats, nextCursor);
      } finally {
        setNextMailboxListLoader(false);
        const newHeight = element.scrollHeight;
        const heightChange = newHeight - oldHeight;

        element.scrollTop += heightChange;
      }
    };
    const shouldFetch =
      element.scrollTop === 0 && nextCursor && !nextMailboxListLoader;
    if (shouldFetch) {
      setNextClicked(true);
      handleFetchNextEvents();
    }
  };
  useEffect(() => {
    scrollableRef.current.addEventListener("scroll", handleScroll);
    return () => {
      scrollableRef.current.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const hideContextMenu = (message) => {
    const timestampDate = new Date(message.created_at);
    const currentTime = new Date();
    const timeDifference = currentTime - timestampDate;
    const hoursDifference = timeDifference / (1000 * 60 * 60);
    const isMoreThanAnHourAgo = hoursDifference > 1;

    const isInterlocutorsMessage =
      message.from.entityId === interlocutor.entityId;
    const result = isMoreThanAnHourAgo || isInterlocutorsMessage;
    return result;
  };

  const renderMessages = () => {
    let currentDate = null;
    const groupedMessages = [];
    let currentGroup = [];

    sortedMessages.forEach((message) => {
      const messageDate = new Date(message.created_at).toLocaleDateString();

      if (messageDate !== currentDate) {
        if (currentGroup.length > 0) {
          groupedMessages.push([...currentGroup]);
        }
        currentGroup = [];
        currentDate = messageDate;
      }
      currentGroup.push(message);
    });
    if (currentGroup.length > 0) {
      groupedMessages.push([...currentGroup]);
    }
    return groupedMessages.map((group, index) => (
      <div key={index}>
        <div className="chat-date">{formatChatDate(group[0].created_at)}</div>
        <div className="chat-messages">
          {group.map((message, index) => (
            <div key={index} className="chat-message">
              <div className={isSystemMessage(message) ? "center" : "left"}>
                <div className="message-content">
                  {!isSystemMessage(message) && (
                    <img
                      className="mailbox-user-avatar chat-avatar"
                      alt="avatar"
                      src={message.from.pictureUrl || user}
                    />
                  )}
                  <div className="message-text-block">
                    <div className="message-sender">
                      <a
                        target="_blank"
                        href={`https://www.linkedin.com/in/${message.from.entityId}/`}
                        className="message-sender-name"
                        rel="noreferrer"
                      >
                        {message.from.fullName}
                      </a>
                      <p className="dot">.</p>
                      <p className="message-sender-time">
                        {formatTime(message.created_at)}
                      </p>
                    </div>
                    <div className="message-block">
                      <div
                        ref={menuRef}
                        className={
                          openMenu && chosenMessage === message.message_id
                            ? "hovered-reactions"
                            : chosenMessage !== message.message_id
                            ? "reactions"
                            : "reactions"
                        }
                      >
                        <p>👍</p>
                        <p>👏</p>
                        <p>😊</p>
                        <p>🤗</p>
                        <p>🤗</p>
                        <p>😁</p>
                        {hideContextMenu(message) || (
                          <img
                            onClick={() =>
                              handleClickContextMenu(message.message_id)
                            }
                            className="context-menu-button"
                            src={menu}
                            alt="context"
                          />
                        )}
                      </div>
                      {openMenu && chosenMessage === message.message_id && (
                        <div
                          ref={menuRef}
                          onClick={(e) => e.stopPropagation()}
                          className="context-menu"
                        >
                          <p
                            onClick={() =>
                              handleDeleteClick(message.message_id)
                            }
                          >
                            Delete
                          </p>
                          <p onClick={() => handleClickEdit(message)}>Edit</p>
                        </div>
                      )}

                      {displayMessage(message)}
                    </div>
                    {message.reaction_summaries.length ? (
                      <div className="mailbox-emoji">
                        <p>{message.reaction_summaries[0]?.emoji}</p>
                        <p>{message.reaction_summaries[0]?.count}</p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    ));
  };

  return (
    <div className="mailboxList-wrapper">
      <div className="mailboxList-header">
        <div>
          <div className="mailbox-user-name-wrapper">
            <p className={`mailbox-user-name mailbox-user-name_full`}>
              {interlocutor?.fullName}
            </p>
          </div>
          <p className="mailboxList-header-subtitle">
            {interlocutor?.occupation}
          </p>
        </div>
      </div>
      <div
        ref={scrollableRef}
        id="chat"
        style={{ maxHeight: `calc(100vh - ${textareaBlockHeight}px)` }}
        className="chat-block"
      >
        {nextMailboxListLoader && (
          <div className="search-wrap">
            <CircularProgress
              color="primary"
              size={30}
              classes={{ colorPrimary: "material-loader" }}
            />
          </div>
        )}
        {renderMessages()}
      </div>
      <div className="mailbox-textarea-block">
        {editMode && <p>Edit Message</p>}
        <div className="mailbox-textarea-contain">
          <textarea
            style={{ height: textareaHeight, overflowY: overflowY }}
            value={text}
            disabled={loadingMessage}
            onChange={handleChange}
            className="mailbox-textarea"
            placeholder="Write a message..."
          />
          <img
            className={
              textareaHeight === "calc(100vh - 229px)" && "rotate-icon"
            }
            onClick={expandContainer}
            src={arrow}
            alt="arrow"
          />
        </div>
        <div className="mailbox-footer">
          <div className="mailbox-footer-actions">
            {!editMode && <img src={attachments} alt="attachments" />}
            {!editMode && <img src={attach} alt="attach" />}
            <img src={smile} alt="smile" />
          </div>
          {editMode ? (
            <div>
              {" "}
              <ResponsiveButton
                onClick={cancelEdit}
                disabled={!text}
                className="mailbox-footer-button cancel"
              >
                Cancel
              </ResponsiveButton>
              <ResponsiveButton
                onClick={sendMessage}
                disabled={!text || loadingMessage}
                className="mailbox-footer-button"
              >
                Save
              </ResponsiveButton>
            </div>
          ) : (
            <ResponsiveButton
              onClick={sendMessage}
              disabled={!text || loadingMessage}
              className="mailbox-footer-button"
            >
              Send
            </ResponsiveButton>
          )}
        </div>
      </div>
      <ModalWindow
        open={isDeleteModalOpen}
        closeText="No"
        confirmText="Yes"
        title={CONSTS.confirmationDialogs[1.13].title}
        content={CONSTS.confirmationDialogs[1.13].content}
        closeHandler={() => setIsDeleteModalOpen(false)}
        confirmHandler={deleteMessage}
      />
    </div>
  );
});

const mapStateToProps = function (state) {
  return {
    app: state.app,
    profile: state.user.profileFull,
    recentEvents: state.mailbox.recentEvents,
    nextEvents: state.mailbox.nextEvents,
    chosenMailbox: state.mailbox.chosenMailbox,
    chosenMailboxId: state.mailbox.chosenMailboxId,
    attachmentUrls: state.mailbox.attachmentUrls,
    bundledEvents: state.mailbox.bundledEvents,
    mailboxParticipants: state.mailbox.mailboxParticipants,
  };
};

export default connect(mapStateToProps)(withRouter(Chats));
