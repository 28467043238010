import React, { useState } from "react";
import lock from "../../../assets/image/icons/svg/lock.svg";
import { CONSTS } from "../../../config/objectConst";
import InformationalTip from "../../molecules/InformationalTip";
import { useSelector } from "react-redux";

import "./style.scss";

const Performed = ({ statistics, limit }) => {
  const appProfile = useSelector((state) => state.app.appProfile);
  const [isTipsVisible, setIsTipsVisible] = useState(false);
  const statList = [
    {
      title: "Invitations",
      value: statistics?.invitations_count || 0,
      maxValue:
        statistics?.invitations_limit && limit
          ? statistics?.invitations_limit
          : "~",
    },
    {
      title: "Messages",
      value: statistics?.messages_count || 0,
      maxValue:
        statistics?.messages_limit && limit ? statistics?.messages_limit : "~",
    },
    {
      title: "Withdrawn",
      value: statistics?.withdrawn_count || 0,
      maxValue:
        statistics?.withdrawn_limit && limit
          ? statistics?.withdrawn_limit
          : "~",
    },

    {
      title: "Likes",
      value: statistics?.react_on_post_count || 0,
      maxValue:
        statistics?.react_on_post_limit && limit
          ? statistics?.react_on_post_limit
          : "~",
    },

    {
      title: "Following",
      value: statistics?.follow_count || 0,
      maxValue:
        statistics?.follow_limit && limit ? statistics?.follow_limit : "~",
    },
  ];

  return (
    <>
      {isTipsVisible && (
        <div className="backdrop" onClick={() => setIsTipsVisible(false)} />
      )}
      <ul className="performed">
        {statList.map((item, index) => {
          const maxValue = item.maxValue === "~" ? 50 : item.maxValue;
          return (
            <li key={index} className="performed-item">
              <div className="performed-data">
                <div>{item.title}</div>

                <div className="flex-container">
                  {item.title === "Invitations" &&
                    appProfile?.exceedLinkedInInvitationsLimit && (
                      <>
                        <img
                          src={lock}
                          alt="iconStop"
                          className="clock-icon"
                          onClick={() => setIsTipsVisible(true)}
                        />
                        {isTipsVisible && (
                          <InformationalTip
                            centered={!limit && "-240px"}
                            arrowPosition={limit ? "38px" : "275px"}
                            onClose={() => setIsTipsVisible(false)}
                            data={CONSTS.TIPS["n4.3"]}
                          />
                        )}
                      </>
                    )}
                  <div>
                    <span>{item.value}</span>/
                    {item.maxValue ? item.maxValue : 0}
                  </div>
                </div>
              </div>
              <div className="performed-progress">
                <div
                  className="performed-bar"
                  style={{
                    width: `${
                      +maxValue <= +item.value
                        ? "100"
                        : (item.value /
                            (maxValue ? maxValue : item.value + 1)) *
                          100
                    }%`,
                  }}
                >
                  &nbsp;
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default Performed;
