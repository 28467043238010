import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "./style.scss";

export class NotFoundContainer extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>404</title>
        </Helmet>
        <div>{this.props.children}</div>
      </React.Fragment>
    );
  }
}

export default NotFoundContainer;
