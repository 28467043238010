import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
// Style
import "./style.scss";

export class NavigationMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <nav className="navigation">
        <Link
          to="/campaigns"
          className={`${
            this.props?.location?.pathname === "/campaigns"
              ? "text-button-small active"
              : "text-button-small"
          }`}
        >
          Campaigns
        </Link>
        <Link
          to="/contacts"
          className={`${
            this.props?.location?.pathname === "/contacts"
              ? "text-button-small active"
              : "text-button-small"
          }`}
        >
          Contacts
        </Link>
        <Link
          to="/team/members"
          className={`${
            this.props?.location?.pathname?.includes("/team")
              ? "text-button-small active"
              : "text-button-small"
          }`}
        >
          Team
        </Link>
        {this.props.app.appProfile.isBetaUser && (
          <Link
            to="/mailbox"
            className={`${
              this.props?.location?.pathname === "/mailbox"
                ? "text-button-small active"
                : "text-button-small"
            }`}
          >
            Mailbox
          </Link>
        )}
      </nav>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    app: state.app,
  };
};

export default connect(mapStateToProps)(withRouter(NavigationMenu));
