import React from "react";
import { CONSTS } from "../../../config/objectConst";
// Components
// Image, Icons
import imageDog from "../../../assets/image/snail-looking-for.png";
import snail from "../../../assets/image/big-board.png";
// Style
import "./style.scss";

const NoContactsYetTemplate = ({ isModal, handlerExportModal }) => {
  return (
    <>
      {isModal ? (
        <div className="no-cont-template">
          <div className="no-cont-template-body">
            <span className="h-2 absolute-text">
              Ooops… No contacts to add!
            </span>
            <img src={imageDog} alt="snail" width={150} className="img-snail" />
            <a
              href={CONSTS.LINKEDIN_URL}
              className="btn-v1 semi-bold"
              target="_blank"
              rel="noopener noreferrer"
            >
              Search on <span className="linkedin-icon">&nbsp;</span>
            </a>
          </div>
        </div>
      ) : (
        // <div className="no-cont-steps">
        //   <div className="no-cont-steps__wrapper">
        //     <div className="no-cont-steps__headline">
        //       5 minutes guide on how to launch
        //       <br /> your first campaign
        //     </div>
        //     <div className="no-cont-steps__video">
        //       <iframe
        //         width="712"
        //         height="400"
        //         src="https://www.youtube.com/embed/wUiU0jOcxPc"
        //         title="Exporting contacts to Snaily.io: how to use Chrome Extension and your lists of contacts?"
        //         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        //         allowFullScreen
        //       ></iframe>
        //     </div>
        //     <div className="no-cont-steps__actions">
        //       <a
        //         href={CONSTS.LINKEDIN_URL}
        //         className="semi-bold"
        //         target="_blank"
        //         rel="noopener noreferrer"
        //       >
        //         Start on <span className="linkedin-icon">&nbsp;</span>
        //       </a>
        //       <button
        //         className="btn-text-icon export-icon upload-icon"
        //         onClick={handlerExportModal(true)}
        //       >
        //         <div className="upload-icon-container">Upload from CSV</div>
        //       </button>
        //     </div>
        //   </div>
        // </div>
        <div className="no-cont-steps">
          <div className="no-cont-steps__wrapper">
            <div
              className="no-cont-steps__headline"
              style={{
                marginTop: "10vh",
              }}
            >
              To get started you need to export contacts.
              <br /> Export contacts from LinkedIn or Upload them
            </div>
            <div
              className="no-cont-steps__video"
              style={{
                marginTop: "100px",
                marginBottom: "100px",
              }}
            >
              <img alt="snail" src={snail} width={300} />
            </div>
            <div className="no-cont-steps__actions">
              <button
                className="btn-text-icon export-icon add-icon"
                onClick={handlerExportModal(true)}
              >
                <div className="upload-icon-container">Add contacts</div>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NoContactsYetTemplate;
