export const referrerDetector = {
  capture: (referrer, redirectUrl) => {
    if (!("referrer" in localStorage)) {
      let endReferrer = referrer ? referrer : document.referrer;
      localStorage.setItem("referrer", endReferrer);
    }

    if (referrer) {
      if (referrer === "web-store") {
        redirectUrl = "https://snaily.io";
      }

      window.location.href = redirectUrl ? redirectUrl : "https://snaily.io";
    }
  },

  get() {
    let result = localStorage.getItem("referrer");
    return result;
  },
};
