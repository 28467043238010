import React, { useEffect, useState } from "react";
import { notification } from "../../../../actions/notification";
import { api } from "../../../../api/api";
import cloud from "../../../../assets/image/icons/svg/icons/cloud.svg";
import warning from "../../../../assets/image/icons/svg/icons/warning.svg";
import computer from "../../../../assets/image/icons/svg/icons/computer.svg";
import "./style.scss";
import { Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

const AutomationMode = () => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const getAutomationMode = async () => {
      setIsLoading(true);
      try {
        const response = await api.getAutomationMode();
        setData(response.data);
      } catch (error) {
        notification.notificationUse(error);
      } finally {
        setIsLoading(false);
      }
    };
    getAutomationMode();
  }, []);
  return (
    <div>
      {isLoading ? (
        <div className="auto-mode-loading">
          <CircularProgress
            color="primary"
            size={30}
            classes={{ colorPrimary: "material-loader" }}
          />{" "}
        </div>
      ) : (
        <div className="auto-mode-wrapper">
          <div className="auto-mode-main">
            {data.is_cloud ? (
              <>
                <p className="auto-mode-info">
                  Processing:{" "}
                  <span className="auto-mode-text">
                    {" "}
                    <img src={cloud} alt="cloud" /> In cloud
                  </span>
                </p>
                <p className="auto-mode-info">
                  IP Address:{" "}
                  <span className="auto-mode-text"> {data.ip_address}</span>
                </p>
                <p className="auto-mode-info">
                  Location:{" "}
                  <span className="auto-mode-text">{`${data.city_name}, ${data.country_code}`}</span>
                </p>
              </>
            ) : (
              <p className="auto-mode-info">
                Processing:{" "}
                <span className="auto-mode-text">
                  {" "}
                  <img src={computer} alt="computer" /> locally on your PC{" "}
                </span>
              </p>
            )}
          </div>
          <div className="auto-mode-warning">
            <img src={warning} alt="warning" />
            {data.is_cloud ? (
              <div>
                <p>
                  Snaily.io operates LinkedIn automation using cloud servers and
                  assigns a dedicated IP address for the process. Through the
                  snaily.io extension, all manual actions performed on LinkedIn
                  are seamlessly executed using the same allocated IP address.
                  <br />
                  <br />
                  This ensures consistency and reliability in your LinkedIn
                  activities while maintaining anonymity and security.
                </p>
              </div>
            ) : (
              <div>
                <p>
                  Snaily.io operates LinkedIn automation from your PC, which is
                  suitable if you manage a single LinkedIn account and can
                  maintain your PC online during working hours.
                  <br />
                  <br /> However, this mode has a drawback: snaily.io does not
                  function when your PC is turned off or if you lose internet
                  connectivity.
                  <br />
                  <br />
                  If you wish to keep automation running even when your PC is
                  turned off or you experience an internet outage, you can
                  transition snaily.io automation to the cloud. This option is
                  available for <span className="strong">PRO</span> or{" "}
                  <span className="strong">TEAM</span> plan subscribers.
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AutomationMode;
