import React, { useEffect, useState } from "react";
import ModalWindow from "../../molecules/ModalWindow";

const ModalForCsvExport = (props) => {
  const [classes, setClasses] = useState(props.classes);

  const dragEnterHandler = (event, classes) => {
    if (classes !== `${props.classes} drop-zone-focused`) {
      setClasses(`${props.classes} drop-zone-focused`);
    }
  };

  const dragLeaveHandler = (event) => {
    if (event.x === 0 && event.y === 0) {
      setClasses(props.classes);
    }
  };

  const dropHandler = () => {
    setClasses(props.classes);
  };

  useEffect(() => {
    if (props.open) {
      window.addEventListener("dragenter", dragEnterHandler);
      window.addEventListener("dragleave", dragLeaveHandler);
      window.addEventListener("drop", dropHandler);
    }
    return () => {
      window.removeEventListener("dragenter", dragEnterHandler);
      window.removeEventListener("dragleave", dragLeaveHandler);
      window.removeEventListener("drop", dropHandler);
    };
  }, [props.open]);

  return (
    <ModalWindow
      open={props.open}
      classes={classes}
      closeHandler={props.closeHandler}
      closeOnDimmerClick={props.closeOnDimmerClick}
    >
      {props.children}
    </ModalWindow>
  );
};

export default ModalForCsvExport;
