import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
// Components
import HomeTemplate from "../../components/templates/homeTemplate";
import TableUsers from "../../components/molecules/TableUsers";
import Filters from "../../components/organisms/filters";
import FilterNavigation from "../../components/molecules/filterNavigation";
import TooltipCustom from "../../components/atoms/tooltipCustom";
// Action
import { app } from "../../actions/app";
import { contacts } from "../../actions/contacts";
import { filters } from "../../actions/filters";
import NoContactsYetTemplate from "../../components/templates/noContactsYetTemplate";
import { CircularProgress } from "@material-ui/core";
import NoSearchContacts from "../../components/templates/noSearchContacts";
import { CONSTS } from "../../config/objectConst";
import { notification } from "../../actions/notification";
import HintTooltip from "../../components/atoms/HintTooltip";
import { listeners } from "../../api/listeners";
import ErrorNotification from "../../components/atoms/ErrorNotification";
import NotfoundPage from "../notfound";
import ModalForCsvExport from "../../components/molecules/ModalForCsvExport";
import CsvExport from "../../components/organisms/CsvExport";
import { functions } from "../../tools/functions";
import { linkedInProfileOpener } from "../../api/linkedInProfileOpener";
import editSvg from "../../assets/image/icons/tables/edit.svg";
import linkSvg from "../../assets/image/icons/tables/defaultLink.svg";
import iconLoading from "../../assets/image/icons/gif/input-loader.gif";
import TextFieldBig from "../../components/atoms/TextFieldBig";
import { imports } from "../../actions/import";
import { contactsPageSize } from "../../api/contactsPageSize";

export class ContactsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtersController: false,
      importObject: {},
      clearFilterData: false,
      pageFound: true,
      isShowExportModal: false,
      keywords: "",
      import: {},
      id: "",
      nameCompany: "",
      inputVisible: false,
      loading: false,
      showAll: false,
      initialValuePagination: contactsPageSize.get(),
      isIconActive: {
        contact: true,
        headline: true,
        account: true,
        location: true,
        contacted: true,
        imported:
          !!this?.props?.history?.location?.pathname.includes("/import"),
      },
      isFiltering: this?.props?.history?.location?.pathname.includes("/import")
        ? "imported asc"
        : "imported desc ",
    };
  }

  clickOnEditAction = () => {
    this.setState({ inputVisible: true });
  };

  onChange = (value) => {
    if (value.length <= CONSTS.IMPORT_MAX_LENGTH) {
      this.setState({ nameCompany: value });
    }
  };

  onEnter = async () => {
    this.setState({ loading: true });
    if (this.state.nameCompany === "")
      this.setState({
        nameCompany: this.props.leftSide.list.find(
          (el) => el.id === this.props.match.params.id
        )?.name,
      });
    try {
      await imports.editImports({
        id: this.props.match.params.id,
        name: this.state.nameCompany,
      });
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      this.setState({ loading: false, inputVisible: false });
    }
  };

  onBlur = () => {
    this.onEnter();
  };

  getContactsById = async () => {
    const { response } = await contacts.getContacts({
      take: this.state.initialValuePagination,
      skip: 0,
      orderby: "imported asc",
      filter: `tags/any(c:c eq '${this.props.match.params.id}')`,
    });
    if (!response) {
      return;
    }

    if (response.status === 204) {
      this.setState({ pageFound: false });
    }
  };

  handlerExportModal = (status) => {
    return () => this.setState({ isShowExportModal: status });
  };

  componentDidMount() {
    let mountFunction = async () => {
      this.setState({ loading: true });
      try {
        filters.clearAllFilters();
        app.changePage("/contacts");
        await contacts.getRecentImports();
        if (this.props.match.params.id) {
          await this.getContactsById();
          this.changeActiveItem();
        } else {
          app.changeActiveItem(1);
          const getAllContacts = async () => {
            try {
              await contacts.getContacts({
                take: this.state.initialValuePagination,
                skip: 0,
              });
            } catch (e) {
              notification.notificationUse(e);
            }
          };
          await getAllContacts();
        }
      } finally {
        this.setState({ loading: false });
      }
    };
    mountFunction();
  }

  clearFilterController = (value) => {
    this.setState({ clearFilterData: value });
  };

  componentDidUpdate(prevProps, prevState) {
    const currentId = this.props.location.pathname.split("/")[3];
    if (this.state.id !== currentId) {
      this.setState({
        id: currentId,
      });
    }
    if (!!this.state.id && this.state.id !== prevState.id) {
      contacts.getImports(currentId).then((response) => {
        this.setState({
          import: response.data.source_uri,
        });
      });
    }

    if (
      this.props.leftSide.list !== prevProps.leftSide.list ||
      this.props.match.params.id !== prevProps.match.params.id
    ) {
      this.setState({
        nameCompany: this.props.leftSide.list.find(
          (el) => el.id === this.props.match.params.id
        )?.name,
      });
    }
    if (this.props.computedMatch.url !== prevProps.computedMatch.url) {
      this.setState({
        keywords: "",
      });
    }
    // case when change 'params id' and load page '/contatcs'
    if (this.props.computedMatch.url !== prevProps.computedMatch.url) {
      this.setActiveFilterIconInitial();
      this.setState({
        isFiltering: this?.props?.history?.location?.pathname.includes(
          "/import"
        )
          ? "imported asc"
          : "imported desc ",
      });
    }
    if (this.props.match.params.id !== prevProps.match.params.id) {
      if (!this.props.match.params.id) {
        app.changeActiveItem(1);
        contacts.getContacts({
          take: this.state.initialValuePagination,
          orderby: "imported desc",
        });
      } else {
        this.getContactsById();
        this.changeActiveItem();
      }
    }

    // case when chage 'params id' and load page '/contacts/import/:id'
    if (
      this.props.leftSide.list !== prevProps.leftSide.list ||
      this.props.match.params.id !== prevProps.match.params.id
    ) {
      const importObject = _.find(this.props.leftSide.list, [
        "id",
        this.props.match.params.id,
      ]);

      this.setState(
        {
          importObject: importObject,
        },
        () => this.changeActiveItem()
      );
    }
  }

  componentWillUnmount() {
    contacts.clearContacts();
    contacts.changeContactsPage(0);
  }

  changeActiveItem() {
    if (this.props.match.params.id) {
      app.changeActiveItem(this.props.match.params.id);
    }
  }

  filtersControllerToggle = () => {
    this.setState({
      filtersController: !this.state.filtersController,
    });
  };

  filtersControllerByParams = (value) => {
    this.setState({
      filtersController: value,
    });
  };

  openLinkedinProfile = async () => {
    const connector = listeners.getConnector();
    await linkedInProfileOpener.open(connector, this.props.profile.linkedInfo);
  };

  setActiveFilterIcon(key, value) {
    this.setState({
      isIconActive: {
        ...{
          contact: true,
          headline: true,
          account: true,
          location: true,
          contacted: true,
          imported: true,
        },
        [key]: value,
      },
    });
  }

  setActiveFilterIconInitial(key, value) {
    this.setState({
      isIconActive: {
        ...{
          contact: true,
          headline: true,
          account: true,
          location: true,
          contacted: true,
          imported:
            !!this?.props?.history?.location?.pathname.includes("/import"),
        },
        [key]: value,
      },
    });
  }

  render() {
    const haveContacts =
      (this.props.contacts.haveContacts &&
        (!this.state.loading || !this.props.app.pageContentLoader)) ||
      this.props.filters.filtersQuery ||
      this.state.keywords;
    return (
      <>
        {this.state.pageFound ? (
          <HomeTemplate id={haveContacts ? "contacts" : "contacts-empty"}>
            {(!this.props.app.linkedInAuth ||
              !this.props.app.associatedLinkedInProfile) && (
              <ErrorNotification>
                <div className="error-notification__not-auth">
                  You are not logged in to LinkedIn with{" "}
                  <span onClick={this.openLinkedinProfile}>
                    {`${this.props.profile.linkedInfo.givenName} ${this.props.profile.linkedInfo.familyName} account.`}
                  </span>
                </div>
              </ErrorNotification>
            )}

            {this.state.loading || this.props.app.pageContentLoader ? (
              <div className="material-loader-wrapper">
                <CircularProgress
                  color="primary"
                  size={60}
                  classes={{ colorPrimary: "material-loader" }}
                />
              </div>
            ) : haveContacts ||
              this.props.error ||
              this.props.location.pathname.includes("/import/") ? (
              <>
                {this.props.location.pathname === "/contacts" && (
                  <div className="contacts-title">All Contacts</div>
                )}
                {this.props.location.pathname.includes("/import/") && (
                  <div className="contacts-title">
                    {this.state.inputVisible ? (
                      <TextFieldBig
                        fullWidth
                        autoFocus
                        value={this.state.nameCompany}
                        onChange={(e) => this.onChange(e.target.value)}
                        onEnter={this.onEnter}
                        onBlur={this.onBlur}
                        endAdornment={
                          this.state.loading ? (
                            <img src={iconLoading} alt="loading" />
                          ) : (
                            ""
                          )
                        }
                      />
                    ) : (
                      <>
                        {this.state.nameCompany}
                        <img
                          className="edit-img"
                          src={editSvg}
                          alt="edit svg"
                          onClick={this.clickOnEditAction}
                        />
                      </>
                    )}
                    {!!this.state.import && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={this.state.import}
                      >
                        <TooltipCustom title="origin" arrow={true}>
                          <img
                            className="link-img"
                            src={linkSvg}
                            alt="link svg"
                          />
                        </TooltipCustom>
                      </a>
                    )}
                  </div>
                )}

                <FilterNavigation
                  setShowAll={(value) =>
                    this.setState({
                      showAll: value,
                    })
                  }
                  showAll={this.state.showAll}
                  from={"contacts"}
                  activeImportId={this.props.match.params.id}
                  filters={this.props.filters}
                  filtersController={this.state.filtersController}
                  filtersControllerToggle={this.filtersControllerToggle}
                  clearFilterController={this.clearFilterController}
                  handlerExportModal={this.handlerExportModal}
                  setKeywords={(value) =>
                    this.setState({
                      keywords: value,
                    })
                  }
                  filtersQuery={this.props.filters.filtersQuery}
                  keywords={this.state.keywords}
                />
                {this.props.error || this.props.contacts.contacts.length > 0 ? (
                  <div className="hint-tooltip-wrapper">
                    <TableUsers
                      from={"contacts"}
                      activeImportId={this.props.match.params.id}
                      classesContainer={"contacts-container"}
                      type="all"
                      contacts={this.props.contacts.contacts}
                      page={this.props.contacts.contactsPage}
                      contactsCount={this.props.contacts.contactsCount}
                      showSteps={false}
                      checkboxs={true}
                      history={this.props.history}
                      app={this.props.app}
                      match={this.props.match}
                      filters={this.props.filters}
                      campaigns={this.props.campaigns}
                      profile={this.props.profile}
                      keywords={this.state.keywords}
                      setActiveFilterIcon={this.setActiveFilterIcon.bind(this)}
                      setInitialValuePagination={(value) =>
                        this.setState({
                          initialValuePagination: value,
                        })
                      }
                      isIconActive={this.state.isIconActive}
                      isFiltering={this.state.isFiltering}
                      setIsFiltering={(value) =>
                        this.setState({
                          isFiltering: value,
                        })
                      }
                    />
                    <HintTooltip
                      placement="top-start"
                      classes="add-contacts-tooltip"
                      enabled={
                        functions.isValidOnBoardingConditions(
                          this.props.app.appProfile,
                          "2.5"
                        ) && !localStorage.getItem("2.5")
                      }
                      tooltip={CONSTS.ON_BOARDING["2.5"]}
                      handlerClose={() => {
                        localStorage.setItem("2.5", "true");
                      }}
                    />
                  </div>
                ) : (
                  <NoSearchContacts />
                )}
              </>
            ) : (
              <NoContactsYetTemplate
                handlerExportModal={this.handlerExportModal}
              />
            )}
            <div
              className={`filters-modal ${
                !this.state.filtersController ? "hide" : ""
              }`}
            >
              <div className="filters-modal__wrapper">
                <Filters
                  show={this.state.filtersController}
                  from={"contacts"}
                  activeImportId={this.props.match.params.id}
                  filters={this.props.filters}
                  page={this.props.contacts.contactsPage}
                  filtersControllerToggle={this.filtersControllerToggle}
                  filtersControllerByParams={this.filtersControllerByParams}
                  clearFilterData={this.state.clearFilterData}
                  clearFilterController={this.clearFilterController}
                  history={this.props.history}
                  keywords={this.state.keywords}
                  isFiltering={this.state.isFiltering}
                />
              </div>
            </div>
            <ModalForCsvExport
              open={this.state.isShowExportModal}
              classes={"csv-export-modal"}
              closeHandler={this.handlerExportModal(false)}
              closeOnDimmerClick={false}
            >
              <CsvExport
                closeExportHandler={this.handlerExportModal(false)}
                isUpdateContacts={!this.props.match.params.id}
              />
            </ModalForCsvExport>
          </HomeTemplate>
        ) : (
          <NotfoundPage />
        )}
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    app: state.app,
    tableContentLoader: state.app.tableContentLoader,
    filters: state.filters,
    contacts: state.contacts,
    leftSide: state.leftSideMenu.leftSide,
    campaigns: state.campaigns,
    error: state.app.errorRequest.contacts,
    profile: state.user.profileFull,
    leftSideMenuActive: state.leftSideMenu.active,
  };
};

export default connect(mapStateToProps)(withRouter(ContactsPage));
