import React from "react";
import "./style.scss";

import dangerIconBlack from "../../../assets/image/icons/svg/icons/dangerIconRed.svg";
import workingHours from "../../../assets/image/snail-no.png";

const WorkingHoursNotification = ({ children }) => {
  return (
    <div className="working-hours-notification">
      <img
        src={workingHours}
        alt="working hours limit icon"
        className="working-hours-notification__png"
        width={40}
      />
      <img
        src={dangerIconBlack}
        alt="working hours limit icon"
        className="working-hours-notification__icon"
      />
      {children}
    </div>
  );
};

export default WorkingHoursNotification;
