class FatalError extends Error {
  constructor(message) {
    super(message);
    this.name = "FatalError";

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FatalError);
    }
  }
}

export default FatalError;
