import React, { useEffect, useState } from "react";
import DateSelection from "../../molecules/dateSelection";
import { CONSTS } from "../../../config/objectConst";

import { useSelector } from "react-redux";
import exclaimIcon from "../../../assets/image/icons/svg/exclaim.svg";
import { app } from "../../../actions/app";
import { notification } from "../../../actions/notification";
import { functions } from "../../../tools/functions";
import ResponsiveButton from "../../atoms/responsiveButton";
import { api } from "../../../api/api";
import { Loader } from "semantic-ui-react";
import SelectTimeZone from "../../molecules/SelectTimeZone";

let replicaHours;

const WorkingHours = ({ getPeriodStatisticsAsync }) => {
  const [loader, setLoader] = useState(false);
  const [editHours, setEditHours] = useState(false);
  const initialHours = "09:00-19:00";
  const { connector } = useSelector((state) => state.app);
  const [loadedTimeZone, setLoadedTimeZone] = useState(false);
  const appProfile = useSelector((state) => state.app.appProfile);
  const [isDataRange, setIsDataRange] = useState(false);
  const [hours, setHours] = useState({
    monday: {
      isSelected: false,
      value: initialHours,
      errorMessage: "",
    },
    tuesday: {
      isSelected: false,
      value: initialHours,
      errorMessage: "",
    },
    wednesday: {
      isSelected: false,
      value: initialHours,
      errorMessage: "",
    },
    thursday: {
      isSelected: false,
      value: initialHours,
      errorMessage: "",
    },
    friday: {
      isSelected: false,
      value: initialHours,
      errorMessage: "",
    },
    saturday: {
      isSelected: false,
      value: initialHours,
      errorMessage: "",
    },
    sunday: {
      isSelected: false,
      value: initialHours,
      errorMessage: "",
    },
  });

  useEffect(() => {
    loadWorkingHours();
  }, []);

  const loadWorkingHours = async () => {
    let response = await api.getWorkingHours();
    const workingHours = response.data;
    const tempHours = { ...hours };
    Object.keys(workingHours).map((key) => {
      let workingDay = workingHours[key];
      if (workingDay.length > 0) {
        const period = workingDay[0];
        const hoursInterval = createHoursInterval(
          period.start,
          period.duration
        );
        tempHours[key] = {
          ...tempHours[key],
          isSelected: true,
          value: hoursInterval,
        };
      }
    });
    setHours(tempHours);
  };

  const handlerCancelEdit = () => {
    setEditHours(false);
    setHours(replicaHours);
  };

  const createHoursInterval = (start, duration) => {
    let startTime;
    let endTime;
    let startHours = Math.floor(start / 60);
    let startMinutes = start % 60;
    let stopHours = Math.floor((start + duration) / 60);
    let stopMinutes = (start + duration) % 60;

    if (startHours < 10 && startMinutes < 10) {
      startTime = `0${startHours}:0${startMinutes}`;
    } else if (startHours < 10 && startMinutes >= 10) {
      startTime = `0${startHours}:${startMinutes}`;
    } else if (startHours >= 10 && startMinutes >= 10) {
      startTime = `${startHours}:${startMinutes}`;
    } else {
      startTime = `${startHours}:0${startMinutes}`;
    }

    if (stopHours < 10 && stopMinutes < 10) {
      endTime = `0${stopHours}:0${stopMinutes}`;
    } else if (stopHours < 10 && stopMinutes >= 10) {
      endTime = `0${stopHours}:${stopMinutes}`;
    } else if (stopHours >= 10 && stopMinutes >= 10) {
      endTime = `${stopHours}:${stopMinutes}`;
    } else {
      endTime = `${stopHours}:0${stopMinutes}`;
    }
    return `${startTime}-${endTime}`;
  };

  const dateSelectionHandler = (day) => {
    setHours({
      ...hours,
      [day]: { ...hours[day], isSelected: !hours[day].isSelected },
    });
  };

  const handleDateChange = (day, value) => {
    setHours({ ...hours, [day]: { ...hours[day], value: value } });
  };

  const handlerEditHours = () => {
    replicaHours = hours;
    setEditHours(true);
  };

  const saveWorkingHours = async () => {
    const tempHours = { ...hours };
    let isValid = true;
    let oneOrMoreSelected = false;
    const requestHours = {};

    for (const [key, value] of Object.entries(tempHours)) {
      if (value.isSelected) {
        const validationMessage = functions.validateHours(value.value);
        const startTimeInHours =
          value.value && value.value.split("-")[0].split(":")[0];
        const startTimeInMinutes =
          value.value && value.value.split("-")[0].split(":")[1];
        const endTimeInHours =
          value.value && value.value.split("-")[1].split(":")[0];
        const endTimeInMinutes =
          value.value && value.value.split("-")[1].split(":")[1];
        const totalTime = functions.getTotalTime(
          Number(startTimeInHours),
          Number(endTimeInHours),
          Number(startTimeInMinutes),
          Number(endTimeInMinutes)
        );

        tempHours[key].errorMessage = validationMessage;
        if (validationMessage.length) isValid = false;
        oneOrMoreSelected = true;
        requestHours[key] = [
          {
            start: Number(startTimeInHours) * 60 + Number(startTimeInMinutes),
            duration: totalTime,
          },
        ];
      }
    }

    setHours(tempHours);

    if (isValid && oneOrMoreSelected) {
      setLoader(true);
      try {
        const response = await app.setNewData(
          "workspace/working-hours-in-minutes",
          requestHours
        );
        if (response.status === 200) {
          setEditHours(false);
          notification.snacknotificationUse({
            open: true,
            title: CONSTS.notification["3.13"].title,
            content: CONSTS.notification["3.13"].content,
            button: CONSTS.notification["3.13"].button,
            style: CONSTS.notification["3.13"].style,
          });
        }
      } catch (e) {
        notification.notificationUse(e);
      } finally {
        setLoader(false);
      }
    }
  };

  const checkIsSelectedHours = () => {
    return Object.keys(hours).some((key) => hours[key].isSelected);
  };

  return (
    <>
      <div className="linkedin__timezone">
        <div className="linkedin__timezone-title">Time zone:</div>
        <div className="linkedin__timezone-select">
          <SelectTimeZone
            loadedTimeZone={loadedTimeZone}
            connector={connector}
            setLoadedTimeZone={setLoadedTimeZone}
            opacityValue={0.1}
            profile={appProfile}
            getPeriodStatisticsAsync={getPeriodStatisticsAsync}
          />
          <Loader size="tiny" active={loadedTimeZone} />
        </div>
      </div>
      {isDataRange && (
        <div
          className="overlay"
          onClick={() => {
            setIsDataRange(false);
          }}
        />
      )}
      <div className="working-hours">
        <div className="working-hours__content">
          <div className="working-hours__item-flex">
            <div className="linkedin__timezone-title">Schedule</div>
            <div className="working-hours__exclaim">
              <img src={exclaimIcon} alt="exclaimIcon" />
              You can work up to {CONSTS.WORKING_HOURS} hours a day.
            </div>
          </div>
          {Object.keys(hours).map((key) => {
            return (
              <div key={key} className="working-hours__item">
                {!editHours && (
                  <div className="working-hours__interval">
                    <p className="working-hours__sub-title">{key}</p>
                    {hours[key].isSelected ? (
                      <span className="hour-interval__not-empty">
                        {hours[key].value}
                      </span>
                    ) : (
                      <span className="working-hours__interval_empty">
                        __:__-__:__
                      </span>
                    )}
                  </div>
                )}
                {editHours && (
                  <DateSelection
                    dayOfWeek={key}
                    hours={hours[key]}
                    dateSelectionHandler={dateSelectionHandler}
                    handleDateChange={handleDateChange}
                  />
                )}
              </div>
            );
          })}
        </div>
        <div className="working-hours__actions">
          {editHours && (
            <button className="working-hours__btn" onClick={handlerCancelEdit}>
              Cancel
            </button>
          )}
          {!editHours && (
            <button
              className="working-hours__btn working-hours__btn_with-bg"
              onClick={handlerEditHours}
            >
              Edit
            </button>
          )}
          {editHours && (
            <ResponsiveButton
              className="working-hours__btn working-hours__btn_with-bg"
              onClick={saveWorkingHours}
              isLoading={loader}
              disabled={!checkIsSelectedHours()}
            >
              {"Apply"}
            </ResponsiveButton>
          )}
        </div>
      </div>
    </>
  );
};

export default WorkingHours;
