import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

// Components
import { HomeTemplate } from "../../components/templates/homeTemplate";
import TableUsers from "../../components/molecules/TableUsers";
import HeaderTemplate from "../../components/organisms/headerTemplate";
import "./style.scss";
// Action
import { app } from "../../actions/app";
import { contacts } from "../../actions/contacts";
import { campaign } from "../../actions/campaign";
import { filters } from "../../actions/filters";
import FinishCreateTemplate from "../../components/templates/finishCreateTemplate";
import { CircularProgress } from "@material-ui/core";
import { CONSTS } from "../../config/objectConst";
import archiveIcon from "../../assets/image/icons/svg/linkedin-icon.svg";

import { notification } from "../../actions/notification";
import { connectorAPI } from "../../actions/connector";
import { listeners } from "../../api/listeners";
import ModalWindow from "../../components/molecules/ModalWindow";
import plusIcon from "../../assets/image/icons/svg/icons/Plus3.svg";
import ConfirmationDialog from "../../components/atoms/ConfirmationDialog";
import { apiCampaign } from "../../api/campaign/apiCampaign";
import AddContactsModal from "../../components/organisms/AddContactsModal";
import NotfoundPage from "../notfound";
import FilterNavigationExecutions from "../../components/molecules/filterNavigationExecutions";
import NoSearchContacts from "../../components/templates/noSearchContacts";
import FiltersExecutions from "../../components/organisms/filtersExecutions";
import iconClose from "../../assets/image/icons/svg/icons/Close-black.svg";
import { contactsPageSize } from "../../api/contactsPageSize";
import SegmentButtonGroup from "../../components/atoms/SegmentButtonGroup";

export class SingleContactsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalData: null,
      confirmationDialog: {
        id: null,
        status: false,
        data: {},
      },
      isIconActiveExecutions: {
        contact: true,
        headline: true,
        account: true,
        location: true,
        contacted: true,
        status: true,
        created: true,
      },
      isIconActive: {
        contact: true,
        headline: true,
        account: true,
        location: true,
        contacted: true,
        imported: true,
      },

      isFiltering: "imported asc",
      isFilteringExecutions: "created asc",

      isIconActiveModal: {
        contact: true,
        headline: true,
        account: true,
        location: true,
        contacted: true,
        imported: false,
      },
      initialValuePagination: contactsPageSize.get(),
      isFilteringModal: "imported desc",
      addNewContactsModal: false,
      pageFound: true,
      isFirstTime: true,
      keywordsForExecutions: "",
      onSearchEnter: false,
      filtersControllerToggle: false,
      clearFilterData: false,
      page: 0,
      isExcludeLoader: false,
      showAll: false,
      isContacts: false,
      isFilterQuery: false,
      filterQueryExecutions: "",
      filterDataExecutions: [
        {
          id: 1,
          name: "connection",
          exclude: false,
          search: false,
          value: [],
          tag: "",
        },
        {
          id: 2,
          name: "location",
          exclude: false,
          search: false,
          value: [],
          tag: "",
        },
        {
          id: 3,
          name: "state",
          exclude: false,
          search: false,
          value: [],
          tag: "",
        },
        {
          id: 4,
          name: "status",
          exclude: false,
          search: false,
          value: "",
          tag: "",
        },
      ],
      filterDataExecutionsLocal: [
        {
          id: 1,
          name: "connection",
          exclude: false,
          search: false,
          value: [],
          tag: "",
        },
        {
          id: 2,
          name: "location",
          exclude: false,
          search: false,
          value: [],
          tag: "",
        },
        {
          id: 3,
          name: "state",
          exclude: false,
          search: false,
          value: [],
          tag: "",
        },
        {
          id: 4,
          name: "status",
          exclude: false,
          search: false,
          value: "",
          tag: "",
        },
      ],
      keywordsForModal: "",
      isError: false,
    };
    this.connector = listeners.getConnector();
  }

  componentDidMount() {
    app.changePageContentLoaderStatus(true);
    filters.clearAllFilters(true);
    filters.changeFilterQuery("", false);
    app.changePage("/campaigns");

    const getData = async (rerender) => {
      try {
        this.setState({ isError: false });
        await campaign.getRecentCampaigns();
        const response = await campaign.getCampaignId(
          this.props.match.params.id,
          rerender
        );

        if (!response) {
          this.setState({ pageFound: false });
          return;
        }

        if (response.status === 200) {
          await this.getContacts(rerender);
          this.setState({ isFirstTime: false });
        }
      } catch (e) {
        this.setState({ isError: true });
        notification.notificationUse(e);
      } finally {
        app.changePageContentLoaderStatus(false);
      }
    };

    getData(true).then(() => {
      app.changePageContentLoaderStatus(false);
    });
    this.connector.eventHandlers.addAutomationEndedEventHandler(async () => {
      await this.getContacts(true);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.contacts.haveContacts !== prevProps.contacts.haveContacts &&
      this.props.contacts.haveContacts === true
    ) {
      this.setState({
        isContacts: true,
      });
    }
    if (
      this.state.keywordsForExecutions === "" &&
      this.props.contacts.haveContacts !== prevProps.contacts.haveContacts &&
      this.props.contacts.haveContacts === false &&
      (this.state.filterQueryExecutions === "" ||
        this.state.filterQueryExecutions ===
          `campaign_id eq '${this.props.campaign.id}'`)
    ) {
      this.setState({
        isContacts: false,
      });
    }
    if (
      this.props.campaign.information !== prevProps.campaign.information &&
      this.state.isFirstTime
    ) {
    }
  }

  componentWillUnmount() {
    contacts.clearContacts();
    contacts.changeContactsPage(0);
    this.setState({ isFirstTime: true });
    this.connector.eventHandlers.removeAutomationEndedEventHandlers();
  }

  setActiveFilterIconExecutions(key, value) {
    this.setState({
      isIconActiveExecutions: {
        ...{
          contact: true,
          headline: true,
          account: true,
          location: true,
          contacted: true,
          status: true,
          created: true,
        },
        [key]: value,
      },
    });
  }

  setActiveFilterIcon(key, value) {
    this.setState({
      isIconActive: {
        ...{
          contact: true,
          headline: true,
          account: true,
          location: true,
          contacted: true,
          imported: true,
        },
        [key]: value,
      },
    });
  }

  setActiveFilterIconModal(key, value) {
    this.setState({
      isIconActiveModal: {
        ...{
          contact: true,
          headline: true,
          account: true,
          location: true,
          contacted: true,
          imported: true,
        },
        [key]: value,
      },
    });
  }

  getContacts = async (rerender) => {
    await contacts.getContacts(
      {
        take: this.state.initialValuePagination,
        skip: 0,
        filter: `campaigns/any(c:c eq '${this.props.match.params.id}')`,
        orderby: "imported asc",
      },
      this.props.campaign.information &&
        this.getStatus(this.props.campaign.information),
      this.props.match.params.id,
      rerender
    );
  };

  getContactsOnSearch = async () => {
    await contacts.getContacts({
      status: "Active",
      skip: 0,
      take: this.state.initialValuePagination,
      filter: this.state.filterQueryExecutions
        ? this.state.filterQueryExecutions
        : [`campaign_id eq '${this.props.match.params.id}'`],
      orderby: this.state.isFilteringExecutions
        ? this.state.isFilteringExecutions
        : "created asc",
      keywords: this.state.keywordsForExecutions
        ? this.state.keywordsForExecutions
        : "",
    });
  };

  getStatus = (info) => {
    return info?.find((item) => item.id === 1).value;
  };

  launch = async () => {
    await campaign.startCampaign(
      this.props.campaign.id,
      this.props.campaign.status
    );

    this.props.history.push(`/campaigns/${this.props.campaign.id}`);
    notification.snacknotificationUse({
      open: true,
      title: CONSTS.notification["3.6"].title,
      content: CONSTS.notification["3.6"].content,
      button: CONSTS.notification["3.6"].button,
      style: CONSTS.notification["3.6"].style,
    });

    const snailyProfile = await connectorAPI.getSnailyProfile();
    if (snailyProfile.onBoarding && !snailyProfile.runningActivitiesEnabled) {
      const connector = listeners.getConnector();
      await connector.auth.refreshAccessToken();
    }
    this.setState({ modalData: null });
  };

  launchCampaign = async () => {
    try {
      app.changeTableContentLoaderStatus(true);
      const preview = await apiCampaign.getPreviewOfStartupCampaing(
        this.props.campaign.id
      );
      if (!preview.data.validation_rules_summary.length) {
        return this.setState({
          modalData: {
            isOpen: true,
            data: preview.data,
          },
        });
      } else {
        return this.setState({
          confirmationDialog: {
            id: this.props.campaign.id,
            status: true,
            data: preview.data,
          },
        });
      }
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      app.changeTableContentLoaderStatus(false);
    }
  };

  openAddNewContactsModal = () => {
    this.setState({ addNewContactsModal: true });
  };

  buildContent = () => {
    if (
      this.props.location.state?.launchCampaignDialog &&
      this.props.contacts.haveContacts
    ) {
      return (
        <>
          <div className="white-wrap andMoreOrStart">
            <FinishCreateTemplate
              styles={{ marginTop: "50px" }}
              header={"<h3>Ready to go! <br/> Launch it?</h3>"}
              description={"You can add more contacts or launch it now."}
            >
              <button
                className="btn with-border semi-bold"
                onClick={this.openAddNewContactsModal}
              >
                Add more contacts
              </button>
              <button className="btn semi-bold" onClick={this.launchCampaign}>
                {this.props.tableContentLoader ? (
                  <CircularProgress color="primary" size={20} />
                ) : (
                  "Launch"
                )}
              </button>
            </FinishCreateTemplate>
          </div>
        </>
      );
    }

    if (
      this.state.isError ||
      this.props.contacts.haveContacts ||
      this.state.filtersControllerToggle
    ) {
      return (
        <>
          <HeaderTemplate
            labelName={this.props.campaign.name}
            campaign={this.props.campaign}
            location={this.props.location}
          >
            <div className="сampaign-actions-wrapper">
              {!this.props.campaign.archive &&
                this.props.campaign?.information?.[0].value === "Compose" &&
                !this.props.tableContentLoader && (
                  <button
                    className="btn-add-to-campaign"
                    onClick={this.openAddNewContactsModal}
                  >
                    <img src={plusIcon} alt="add more icon" />
                    Add more contacts
                  </button>
                )}
              {this.getStatus(this.props.campaign.information) ===
                "Compose" && (
                <SegmentButtonGroup getContacts={this.getContacts} />
              )}
            </div>
          </HeaderTemplate>
          {this.props.campaign.information?.[0].value !== "Compose" && (
            <div className="container-header-filter">
              {this.props.location?.pathname?.includes("contacts") &&
                this.props.campaign.information?.[0].value !== "Compose" && (
                  <div className="container-filter-executions">
                    <FilterNavigationExecutions
                      setShowAll={(value) =>
                        this.setState({
                          showAll: value,
                        })
                      }
                      showAll={this.state.showAll}
                      keywordsForExecutions={this.state.keywordsForExecutions}
                      setKeywordsForExecutions={(value) =>
                        this.setState({
                          keywordsForExecutions: value,
                        })
                      }
                      setOnSearchEnter={(value) => {
                        this.setState({
                          onSearchEnter: value,
                        });
                        this.getContactsOnSearch();
                      }}
                      option={this.props.filters.options}
                      setFiltersControllerToggle={(value) =>
                        this.setState({
                          filtersControllerToggle: value,
                        })
                      }
                      setIsFilterQuery={(value) =>
                        this.setState({
                          isFilterQuery: value,
                        })
                      }
                      id={this.props.match.params.id}
                      getContacts={this.getContacts}
                      pageHandler={(value) => this.state({ page: value })}
                      clearFilterController={(value) =>
                        this.state({ clearFilterController: value })
                      }
                      haveContacts={this.props.contacts.contacts.length > 0}
                      filterDataExecutions={this.state.filterDataExecutions}
                      setFilterDataExecutions={(value) =>
                        this.setState({
                          filterDataExecutions: value,
                        })
                      }
                      setFilterDataExecutionsLocal={(value) =>
                        this.setState({
                          filterDataExecutionsLocal: value,
                        })
                      }
                      setFilterQueryExecutions={(value) =>
                        this.setState({
                          filterQueryExecutions: value,
                        })
                      }
                    />
                  </div>
                )}
              {this.props.campaign.information?.[0].value !== "Compose" && (
                <div className="сampaign-actions-wrapper">
                  <button
                    className="btn-add-to-campaign"
                    onClick={this.openAddNewContactsModal}
                  >
                    <img src={plusIcon} alt="add more icon" />
                    Add more contacts
                  </button>
                </div>
              )}
            </div>
          )}
          {this.state.filtersControllerToggle && (
            <div className="filters-modal">
              <div className="filters-modal__wrapper-executions">
                <FiltersExecutions
                  option={this.props.filters.options}
                  setFiltersControllerToggle={(value) =>
                    this.setState({
                      filtersControllerToggle: value,
                    })
                  }
                  setIsFilterQuery={(value) =>
                    this.setState({
                      isFilterQuery: value,
                    })
                  }
                  keywordsForExecutions={this.state.keywordsForExecutions}
                  id={this.props.match.params.id}
                  getContacts={this.getContacts}
                  pageHandler={(value) => this.state({ page: value })}
                  setKeywordsForExecutions={(value) =>
                    this.setState({
                      keywordsForExecutions: value,
                    })
                  }
                  filterDataExecutions={this.state.filterDataExecutions}
                  setOnSearchEnter={(value) => {
                    this.setState({
                      onSearchEnter: value,
                    });
                    this.getContactsOnSearch();
                  }}
                  clearFilterController={(value) =>
                    this.state({ clearFilterController: value })
                  }
                  haveContacts={this.props.contacts.contacts.length > 0}
                  setFilterDataExecutions={(value) =>
                    this.setState({
                      filterDataExecutions: value,
                    })
                  }
                  filterDataExecutionsLocal={
                    this.state.filterDataExecutionsLocal
                  }
                  setFilterDataExecutionsLocal={(value) =>
                    this.setState({
                      filterDataExecutionsLocal: value,
                    })
                  }
                  setFilterQueryExecutions={(value) =>
                    this.setState({
                      filterQueryExecutions: value,
                    })
                  }
                  isFilteringExecutions={this.state.isFilteringExecutions}
                />
                <button
                  className="button-executions-close"
                  onClick={() =>
                    this.setState({
                      filtersControllerToggle: false,
                    })
                  }
                >
                  <img src={iconClose} alt="add more icon" />
                </button>
              </div>
            </div>
          )}
          {this.state.isError || this.props.contacts.haveContacts ? (
            <TableUsers
              classesContainer={"single-contacts-container"}
              type="single"
              campaignId={this.props.match.params.id}
              campaignStatus={
                this.props.campaign.information
                  ? this.getStatus(this.props.campaign.information)
                  : ""
              }
              archive={this.props.campaign.archive}
              contacts={this.props.contacts.contacts}
              page={this.props.contacts.contactsPage}
              contactsCount={this.props.contacts.contactsCount}
              actions={this.props.campaign.actions}
              showSteps={true}
              checkboxs={true}
              history={this.props.history}
              app={this.props.app}
              match={this.props.match}
              profile={this.props.profile}
              keywordsExecutions={this.state.keywordsForExecutions}
              filterQueryExecutions={this.state.filterQueryExecutions}
              setActiveFilterIcon={this.setActiveFilterIcon.bind(this)}
              isIconActive={this.state.isIconActive}
              isFiltering={this.state.isFiltering}
              setIsFiltering={(value) =>
                this.setState({
                  isFiltering: value,
                })
              }
              setInitialValuePagination={(value) =>
                this.setState({
                  initialValuePagination: value,
                })
              }
              setActiveFilterIconExecutions={this.setActiveFilterIconExecutions.bind(
                this
              )}
              isIconActiveExecutions={this.state.isIconActiveExecutions}
              isFilteringExecutions={this.state.isFilteringExecutions}
              setIsFilteringExecutions={(value) =>
                this.setState({
                  isFilteringExecutions: value,
                })
              }
              setIsExcludeLoader={(value) =>
                this.setState({
                  isExcludeLoader: value,
                })
              }
            />
          ) : (
            <NoSearchContacts />
          )}
        </>
      );
    }

    return (
      <>
        {(this.props.contacts.contacts.length !== 0 &&
          this.state.keywordsForExecutions === "") ||
        (this.state.isFilterQuery === false &&
          this.props.contacts.contacts.length !== 0) ||
        (["Running", "Stopped", "Completed"].includes(
          this.props.campaign.information?.[0].value
        ) &&
          this.props.contacts.contactsCount === 0 &&
          this.state.isContacts === false &&
          !this.state.isExcludeLoader &&
          !this.props.app.pageContentLoader) ? (
          <div className="white-wrap empty-contacts">
            <FinishCreateTemplate
              styles={{ marginTop: "50px" }}
              header={"<h3>Add contacts to your <br/> campaign!</h3>"}
              description={"Select exported or search new on LinkedIn"}
            >
              <button
                className="btn with-border semi-bold"
                onClick={this.openAddNewContactsModal}
              >
                Select exported
              </button>
              <a
                className="btn semi-bold search-link"
                href={CONSTS.LINKEDIN_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Search on <img src={archiveIcon} alt="linkedin icon" />
              </a>
            </FinishCreateTemplate>
          </div>
        ) : (
          <>
            {this.props.campaign?.information?.[0].value !== "Compose" &&
            this.state.isContacts === true ? (
              <>
                <HeaderTemplate
                  campaign={this.props.campaign}
                  location={this.props.location}
                />

                <div className="container-header-filter">
                  <div className="container-filter-executions">
                    <FilterNavigationExecutions
                      setShowAll={(value) =>
                        this.setState({
                          showAll: value,
                        })
                      }
                      showAll={this.state.showAll}
                      keywordsForExecutions={this.state.keywordsForExecutions}
                      setKeywordsForExecutions={(value) =>
                        this.setState({
                          keywordsForExecutions: value,
                        })
                      }
                      setOnSearchEnter={(value) => {
                        this.setState({
                          onSearchEnter: value,
                        });
                        this.getContactsOnSearch();
                      }}
                      option={this.props.filters.options}
                      setFiltersControllerToggle={(value) =>
                        this.setState({
                          filtersControllerToggle: value,
                        })
                      }
                      setIsFilterQuery={(value) =>
                        this.setState({
                          isFilterQuery: value,
                        })
                      }
                      id={this.props.match.params.id}
                      getContacts={this.getContacts}
                      pageHandler={(value) => this.state({ page: value })}
                      clearFilterController={(value) =>
                        this.state({ clearFilterController: value })
                      }
                      haveContacts={this.props.contacts.contacts.length > 0}
                      filterDataExecutions={this.state.filterDataExecutions}
                      setFilterDataExecutions={(value) =>
                        this.setState({
                          filterDataExecutions: value,
                        })
                      }
                      setFilterDataExecutionsLocal={(value) =>
                        this.setState({
                          filterDataExecutionsLocal: value,
                        })
                      }
                      setFilterQueryExecutions={(value) =>
                        this.setState({
                          filterQueryExecutions: value,
                        })
                      }
                    />
                  </div>
                  <div className="сampaign-actions-wrapper">
                    <button
                      className="btn-add-to-campaign"
                      onClick={this.openAddNewContactsModal}
                    >
                      <img src={plusIcon} alt="add more icon" />
                      Add more contacts
                    </button>
                  </div>
                </div>
                <div className="container-notification">
                  <NoSearchContacts />
                </div>
              </>
            ) : (
              <>
                {!this.state.isExcludeLoader &&
                  !this.props.app.pageContentLoader && (
                    <div className="white-wrap empty-contacts">
                      <FinishCreateTemplate
                        styles={{ marginTop: "50px" }}
                        header={
                          "<h3>Add contacts to launch <br/> your campaign!</h3>"
                        }
                        description={
                          "Select exported or search new on LinkedIn"
                        }
                      >
                        <button
                          className="btn with-border semi-bold"
                          onClick={() =>
                            this.setState({ addNewContactsModal: true })
                          }
                        >
                          Select exported
                        </button>
                        <a
                          className="btn semi-bold search-link"
                          href={CONSTS.LINKEDIN_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Search on{" "}
                          <img src={archiveIcon} alt="linkedin icon" />
                        </a>
                      </FinishCreateTemplate>
                    </div>
                  )}
              </>
            )}
          </>
        )}
      </>
    );
  };

  render() {
    return (
      <>
        {this.state.pageFound ? (
          <HomeTemplate id="single-contacts-template">
            {(this.props.app.pageContentLoader ||
              this.state.isExcludeLoader) && (
              <div className="material-loader-wrapper">
                <CircularProgress
                  color="primary"
                  size={60}
                  classes={{ colorPrimary: "material-loader" }}
                />
              </div>
            )}

            {this.buildContent()}
            <ModalWindow
              open={this.state.modalData?.isOpen}
              closeText="No"
              confirmText="Yes"
              loading={this.props.tableContentLoader}
              closeHandler={() => this.setState({ modalData: null })}
              confirmHandler={this.launch}
              title={CONSTS.confirmationDialogs[1.5].title}
              content={CONSTS.confirmationDialogs[1.5].content.replace(
                "{contacts_count}",
                this.state.modalData?.data?.valid_audience_count
              )}
            />
            <ModalWindow
              open={this.state.confirmationDialog.status}
              closeHandler={() =>
                this.setState({
                  confirmationDialog: { id: null, status: false, data: {} },
                })
              }
              classes={"confirmation-launch-modal"}
            >
              <ConfirmationDialog
                confirmationDialog={this.state.confirmationDialog}
                closeHandler={() =>
                  this.setState({
                    confirmationDialog: { id: null, status: false, data: {} },
                  })
                }
                startCampaign={() => this.launch()}
              />
            </ModalWindow>
            <ModalWindow
              open={this.state.addNewContactsModal}
              classes="add-contacts-modal"
              closeHandler={() => this.setState({ addNewContactsModal: false })}
            >
              <AddContactsModal
                classesTableContainer={"add-to-camp-container"}
                type="add-to-campaign"
                from="single-contacts"
                showSteps={false}
                checkboxs={true}
                campaign={this.props.campaign}
                history={this.props.history}
                app={this.props.app}
                campaignId={this.props.match.params.id}
                profile={this.props.profile}
                filters={this.props.filters}
                isIconActiveModal={this.state.isIconActiveModal}
                isFilteringModal={this.state.isFilteringModal}
                setIsFilteringModal={(value) =>
                  this.setState({
                    isFilteringModal: value,
                  })
                }
                keywordsForModal={this.state.keywordsForModal}
                setKeywordsForModal={(value) =>
                  this.setState({
                    keywordsForModal: value,
                  })
                }
                contactsPage={this.props.contacts.contactsPage}
                setActiveFilterIconModal={this.setActiveFilterIconModal.bind(
                  this
                )}
              />
            </ModalWindow>
          </HomeTemplate>
        ) : (
          <NotfoundPage />
        )}
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    app: state.app,
    filters: state.filters,
    campaign: state.campaign,
    campaigns: state.campaigns,
    contacts: state.contacts,
    tableContentLoader: state.app.tableContentLoader,
    profile: state.user.profileFull,
    connector: state.app.connector,
  };
};

export default connect(mapStateToProps)(withRouter(SingleContactsPage));
