import React from "react";
import "./style.scss";

import dogNo from "./../../../assets/image/snail-no.png";
import dogThinks from "./../../../assets/image/snail-typing.png";

const ConfirmationDialogExclude = ({
  confirmationDialog,
  closeHandler,
  exclude,
}) => {
  const handlerConfirm = () => {
    closeHandler();
    exclude();
  };

  const getConfirmationContent = () => {
    let totalCount = 0;

    if (
      confirmationDialog.data.validation_rules_summary.length &&
      confirmationDialog.data.valid_audience_count
    ) {
      return (
        <div className="confirmation-dialog__type-1">
          <p className="confirmation-dialog__title">
            Sorry, the system can't exclude
            <br /> some of the contacts
          </p>
          <div className="confirmation-dialog__list">
            <div className="confirmation-dialog__count">
              {confirmationDialog.data.validation_rules_summary.map(
                (item, index) => {
                  totalCount += item.count;
                  return <span key={index}>{`${item.count} contacts:`}</span>;
                }
              )}
            </div>
            <div className="confirmation-dialog__description">
              {confirmationDialog.data.validation_rules_summary.map(
                (item, index) => {
                  return <span key={index}>{item.description}</span>;
                }
              )}
            </div>
          </div>
          <div className="confirmation-dialog__text">
            <p>
              The system will exclude{" "}
              {confirmationDialog.data.valid_audience_count} contacts,
            </p>
            <p className="cRed">{totalCount} will not be excluded.</p>
            <p>Would you like to proceed?</p>
          </div>

          <div className="confirmation-dialog__actions">
            <button
              className="confirmation-dialog__cancel"
              onClick={closeHandler}
            >
              Cancel
            </button>
            <button
              className="confirmation-dialog__confirm"
              onClick={() => handlerConfirm(confirmationDialog.id)}
            >
              Confirm
            </button>
          </div>
        </div>
      );
    }

    if (
      confirmationDialog.data.validation_rules_summary.length &&
      !confirmationDialog.data.valid_audience_count
    ) {
      return (
        <div className="confirmation-dialog__type-2">
          <p className="confirmation-dialog__title">
            Sorry, the system can't exclude <br />
            these contacts.
          </p>
          <div className="confirmation-dialog__list">
            <div className="confirmation-dialog__count">
              {confirmationDialog.data.validation_rules_summary.map(
                (item, index) => {
                  return <div key={index}>{`${item.count} contacts:`}</div>;
                }
              )}
            </div>
            <div className="confirmation-dialog__description">
              {confirmationDialog.data.validation_rules_summary.map(
                (item, index) => {
                  return <div key={index}>{item.description}</div>;
                }
              )}
            </div>
          </div>
          <div className="confirmation-dialog__text">
            <p>Please, select other contacts.</p>
          </div>
          <div className="confirmation-dialog__actions">
            <button
              className="confirmation-dialog__confirm"
              onClick={closeHandler}
            >
              Ok
            </button>
          </div>
        </div>
      );
    }
  };

  const isPositiveAudienceCount =
    confirmationDialog?.data?.validation_rules_summary?.length &&
    confirmationDialog?.data?.valid_audience_count;

  return (
    <div className="confirmation-dialog">
      {getConfirmationContent()}
      <img
        src={isPositiveAudienceCount ? dogThinks : dogNo}
        alt="confirmation snail"
        width={190}
        className={`confirmation-dialog__dog ${
          !isPositiveAudienceCount ? "confirmation-dialog__dog_no" : ""
        }`}
      />
    </div>
  );
};

export default ConfirmationDialogExclude;
