import React from "react";
import { Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import "./style.scss";

const ModalVerifiedError = ({ errorText, isLoadingAcceptInvitation }) => {
  return (
    <div className="notfound-page">
      {isLoadingAcceptInvitation ? (
        <CircularProgress
          color="secondary"
          size={60}
          classes={{ colorSecondary: "material-loader-accent" }}
        />
      ) : (
        <div className="notfound-page__content">
          <div className="notfound-page__text">
            <h1 className="notfound-page__text-h1">Oooopss...</h1>
            <h3 className="notfound-page__text-h3">{errorText}</h3>
            <Link to={"/campaigns"} className="notfound-page__link">
              Go home!
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalVerifiedError;
