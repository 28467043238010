import React, { useState } from "react";
import "./style.scss";

import stopIcon from "./../../../assets/image/icons/svg/icons/stop.svg";

const CsvExportUploader = ({ stopUploadingHandler, value }) => {
  const [showStopBtn, setShowStopBtn] = useState(true);
  const handleProgress = () => {
    let progress = parseInt((+value.current / +value.total) * 100, 10);
    return progress > 100 ? 100 : progress;
  };

  const getUploadedValue = () => {
    if (value.current > value.total) {
      return `${value.total} / ${value.total}`;
    } else {
      return `${value.current} / ${value.total}`;
    }
  };

  const stopHandler = () => {
    stopUploadingHandler();
    setShowStopBtn(false);
  };

  return (
    <div className="csv-export-uploader">
      <div className="csv-progress">
        <span
          className="csv-progress__active-line"
          style={{ width: `${handleProgress()}%` }}
        ></span>
      </div>
      <div className="csv-export-uploader__footer">
        <p className="upload-counter">Uploaded {getUploadedValue()}</p>
        {showStopBtn && (
          <div className="stop-uploading" onClick={stopHandler}>
            <img src={stopIcon} alt="stop icon" /> Stop
          </div>
        )}
      </div>
      <div className="hidden-close-icon" />
    </div>
  );
};

export default CsvExportUploader;
