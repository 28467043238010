/*
 *
 * campaignCreate reducer
 *
 */
const initialState = {
  name: "",
  finishStep: "",
  zoomMessageWindow: "",
};

const campaignCreate = (state = initialState, action) => {
  switch (action.type) {
    case "CAMPAIGN_CREATE":
      return Object.assign({}, state, {
        name: action.payload.name,
      });

    case "CAMPAIGN_CREATE_FINISH_STEP":
      return Object.assign({}, state, {
        finishStep: action.payload.finishStep,
      });

    case "ZOOM_MESSAGE_WINDOW":
      return Object.assign({}, state, {
        zoomMessageWindow: action.payload.zoomMessageWindow,
      });

    default:
      return state;
  }
};

export default campaignCreate;
