import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import TooltipCustom from "../../atoms/tooltipCustom";
// Actions
import { app } from "../../../actions/app";
import { contacts } from "../../../actions/contacts";
import { filters } from "../../../actions/filters";
// Configs
// Images, and Icons
import clearListIcon from "../../../assets/image/icons/left-side-menu/clear-list.svg";
// Style
import "./style.scss";
import TrialNotification from "../trialNotification";

export class LeftSideMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowExportModal: false,
    };
  }

  linkBuilder = (type, id, archived) => {
    let link = archived ? "/archive" : "/campaigns";
    switch (type) {
      case "campaign":
        return `${link}/${id}`;

      case "team":
        return `${link}/${id}`;

      case "contacts":
        return `/campaigns/${id}/contacts`;

      default:
        break;
    }
  };

  clickOnLink = (item) => {
    app.changeActiveItem(item.id);
    contacts.changeContactsPage(0);
    this.props.history.push(`/campaigns/${item.id}`);
  };

  onClickRecentImportItem = (item) => {
    app.changeActiveItem(item.id);
    filters.clearAllFilters();
    contacts.changeContactsPage(0);
  };

  bottomListActiveHandler = (item) => {
    if (this.props.leftSide.type === "account") return;
    if (this.props.match.params.id) {
      if (this.props.campaign.archive && item.link === "/archive") {
        return "active semi-bold";
      }
      if (!this.props.campaign.archive && item.link === "/campaigns") {
        const campaign = this.props.leftSide.list.find(
          (item) => item.id === this.props.match.params.id
        );
        return !campaign ? "active semi-bold" : "";
      }
    } else {
      return this.props.active === item.id ? "active semi-bold" : "";
    }
  };

  handlerExportModal = (status) => {
    return () => this.setState({ isShowExportModal: status });
  };

  render() {
    return (
      <div className="left-menu">
        <div className="left-menu-list">
          {this.props.leftSide.type === "mailbox" && (
            <>
              <Link
                to={this.props.leftSide.bottomList[0].link}
                className={`first-link text-list ${
                  this.props.leftSide.type === "mailbox" ? "active" : ""
                }`}
              >
                <div className="line-list__img-container">
                  <img
                    src={this.props.leftSide.bottomList[0].image}
                    alt={"pageIcon"}
                  />
                </div>
                {this.props.leftSide.bottomList[0].title}
              </Link>
            </>
          )}
          {this.props.leftSide.type === "account" && (
            <>
              <Link
                to={this.props.leftSide.bottomList[0].link}
                className={`first-link text-list ${
                  this.props.leftSide.type === "account" ? "active" : ""
                }`}
              >
                <div className="line-list__img-container">
                  <img
                    src={this.props.leftSide.bottomList[0].image}
                    alt={"pageIcon"}
                  />
                </div>
                {this.props.leftSide.bottomList[0].title}
              </Link>
            </>
          )}

          {this.props.leftSide.type === "team" &&
            this.props.leftSide.bottomList && (
              <>
                <Link
                  to={this.props.leftSide.bottomList[0].link}
                  className={`first-link text-list ${
                    this.props.active ===
                      this.props.leftSide.bottomList[0].id && "active"
                  }`}
                >
                  <div className="line-list__img-container">
                    <img
                      src={this.props.leftSide.bottomList[0].image}
                      alt={"pageIcon"}
                    />
                  </div>
                  {this.props.leftSide.bottomList[0].title}
                </Link>
                <ul className="list">
                  {this.props.leftSide.bottomList.slice(1).map((item) => {
                    return (
                      <li
                        key={item.id}
                        className={`text-list ${this.bottomListActiveHandler(
                          item
                        )}`}
                      >
                        <Link className={`text-list `} to={item.link}>
                          <div className="line-list__img-container">
                            <img src={item.image} alt={"pageIcon"} />
                          </div>
                          {item.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </>
            )}
          {this.props.leftSide.type === "campaign" && (
            <>
              <Link
                to={this.props.leftSide.bottomList[0].link}
                className={`first-link text-list ${
                  this.props.active === this.props.leftSide.bottomList[0].id &&
                  (this.props.leftSide.type !== "account" ||
                    this.props.leftSide.type !== "team")
                    ? "active"
                    : ""
                }`}
              >
                <div className="line-list__img-container">
                  <img
                    src={this.props.leftSide.bottomList[0].image}
                    alt={"pageIcon"}
                  />
                </div>
                {this.props.leftSide.bottomList[0].title}
              </Link>
              <ul className="list">
                {this.props.leftSide.bottomList.slice(1).map((item) => {
                  return (
                    <li
                      key={item.id}
                      className={`text-list ${this.bottomListActiveHandler(
                        item
                      )}`}
                    >
                      <Link className={`text-list`} to={item.link}>
                        <div className="line-list__img-container">
                          <img src={item.image} alt={"pageIcon"} />
                        </div>
                        {item.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>

              {this.props.leftSide.list?.length > 0 && (
                <>
                  <div className="recently-title">
                    {this.props.leftSide.label}
                  </div>
                  <ul>
                    {this.props.leftSide.list.map((item) => {
                      return (
                        !item.archived && (
                          <li
                            key={item.id}
                            className={`${
                              this.props.active === item.id &&
                              (this.props.leftSide.type !== "account" ||
                                this.props.leftSide.type !== "team")
                                ? "active"
                                : ""
                            }`}
                          >
                            {this.props.leftSide.type === "contacts" ? (
                              <Link
                                to={`/contacts/import/${item.id}`}
                                className="text-list"
                                onClick={() =>
                                  this.onClickRecentImportItem(item)
                                }
                              >
                                <img
                                  src={clearListIcon}
                                  alt={"clockIcon"}
                                  className="with-shift"
                                />
                                <TooltipCustom
                                  title={item.name.length > 24 ? item.name : ""}
                                  arrow={true}
                                >
                                  <p
                                    className={
                                      item.name.length > 24
                                        ? "ellipsis"
                                        : "left-list-text"
                                    }
                                  >
                                    {item.name}
                                  </p>
                                </TooltipCustom>
                              </Link>
                            ) : (
                              <Link
                                to={
                                  this.linkBuilder(
                                    this.props.leftSide.type,
                                    item.id,
                                    item.archived
                                  ) || ""
                                }
                                className="text-list"
                                onClick={() => this.clickOnLink(item)}
                              >
                                <img
                                  src={clearListIcon}
                                  alt={"clockIcon"}
                                  className="with-shift"
                                />
                                <TooltipCustom
                                  title={item.name.length > 24 ? item.name : ""}
                                  arrow={true}
                                >
                                  <p
                                    className={
                                      item.name.length > 24
                                        ? "ellipsis"
                                        : "left-list-text"
                                    }
                                  >
                                    {item.name}
                                  </p>
                                </TooltipCustom>
                              </Link>
                            )}
                          </li>
                        )
                      );
                    })}
                  </ul>
                </>
              )}
            </>
          )}
          {this.props.leftSide.type === "contacts" && (
            <>
              <Link
                to={this.props.leftSide.bottomList[0].link}
                className={`first-link text-list margin-top ${
                  this.props.active === this.props.leftSide.bottomList[0].id
                    ? "active"
                    : ""
                }`}
              >
                <div className="line-list__img-container">
                  <img
                    src={this.props.leftSide.bottomList[0].image}
                    alt={"pageIcon"}
                  />
                </div>
                {this.props.leftSide.bottomList[0].title}
              </Link>
              {this.props.leftSide.list?.length > 0 && (
                <>
                  {" "}
                  <div className="recently-title">
                    {this.props.leftSide.label}
                  </div>
                  <ul>
                    {this.props.leftSide.list.map((item) => {
                      return (
                        !item.archived && (
                          <li
                            key={item.id}
                            className={`${
                              this.props.active === item.id &&
                              (this.props.leftSide.type !== "account" ||
                                this.props.leftSide.type !== "team")
                                ? "active"
                                : ""
                            }`}
                          >
                            {this.props.leftSide.type === "contacts" ? (
                              <Link
                                to={`/contacts/import/${item.id}`}
                                className="text-list"
                                onClick={() =>
                                  this.onClickRecentImportItem(item)
                                }
                              >
                                <img
                                  src={clearListIcon}
                                  alt={"clockIcon"}
                                  className="with-shift"
                                />
                                <TooltipCustom
                                  title={item.name.length > 24 ? item.name : ""}
                                  arrow={true}
                                >
                                  <p
                                    className={
                                      item.name.length > 24
                                        ? "ellipsis"
                                        : "left-list-text"
                                    }
                                  >
                                    {item.name}
                                  </p>
                                </TooltipCustom>
                              </Link>
                            ) : (
                              <Link
                                to={
                                  this.linkBuilder(
                                    this.props.leftSide.type,
                                    item.id,
                                    item.archived
                                  ) || ""
                                }
                                className="text-list"
                                onClick={() => this.clickOnLink(item)}
                              >
                                <img
                                  src={clearListIcon}
                                  alt={"clockIcon"}
                                  className="with-shift"
                                />
                                <TooltipCustom
                                  title={item.name.length > 24 ? item.name : ""}
                                  arrow={true}
                                >
                                  <p
                                    className={
                                      item.name.length > 24
                                        ? "ellipsis"
                                        : "left-list-text"
                                    }
                                  >
                                    {item.name}
                                  </p>
                                </TooltipCustom>
                              </Link>
                            )}
                          </li>
                        )
                      );
                    })}
                  </ul>{" "}
                </>
              )}
            </>
          )}
        </div>
        <TrialNotification />
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    leftSide: state.leftSideMenu.leftSide,
    active: state.leftSideMenu.active,
    pageContentLoader: state.app.pageContentLoader,
    campaign: state.campaign,
    appPage: state.app.appPage,
    appProfile: state.app.appProfile,
    subscription: state.app.subscription,
  };
};

export default connect(mapStateToProps)(withRouter(LeftSideMenu));
