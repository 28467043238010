import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { notification } from "../../../actions/notification";
import { helpersFunc } from "../../../config/helpers";
// components
import AuthTemplate from "../../templates/authTemplate";
// img
import unAuth from "../../../assets/image/auth-steps/unAuth.png";
import { referrerDetector } from "../../../api/referrerDetector";
import { CONSTS } from "../../../config/objectConst.js";

export const NoAuthContainer = ({ connector }) => {
  const history = useHistory();
  return (
    <AuthTemplate active={2}>
      <h2>My name is {CONSTS.PROJECT_NAME} & I am here to help you!</h2>
      <img src={unAuth} alt="unAuth" height={210} />
      <button
        onClick={async () => {
          try {
            let referrer = referrerDetector.get();
            await connector.auth.signIn(history.location.pathname, referrer);
          } catch (e) {
            notification.notificationUse(e);
          }
        }}
      >
        Sign In
      </button>
    </AuthTemplate>
  );
};

const mapStateToProps = function (state) {
  return {
    connector: state.app.connector,
  };
};

export default connect(mapStateToProps)(NoAuthContainer);
