import React from "react";
import Icon from "./icon";

const SortIcon = (props) => {
  const {
    idx,
    isFiltering = "",
    setIsFiltering,
    isSortedAsc,
    setIsSortedAsc,
    sort,
    isRunning,
    text,
  } = props;
  const isActive = isFiltering.includes(sort);
  const getSortType = (idx, type, running) => {
    switch (idx) {
      case "contact":
        return isRunning ? `${running}full_name ${type}` : `full_name ${type}`;
      case "headline":
        return isRunning
          ? `${running}occupation ${type} `
          : `occupation ${type}`;
      case "location":
        return isRunning ? `${running}location ${type} ` : `location ${type}`;
      case "contacted":
        return isRunning ? `${running}contacted ${type}` : `contacted ${type}`;

      case "imported":
        return `imported ${type}`;
      case "status":
        return `state ${type} `;
      case "created":
        return `created ${type}, id ${type}`;
      default:
        return `imported ${type}`;
    }
  };

  return isSortedAsc[idx] ? (
    <div
      className={`container-icon-sort ${isActive ? "active" : ""}`}
      onClick={() => {
        setIsSortedAsc(idx, !isActive ? true : false);
        setIsFiltering(
          `${getSortType(idx, !isActive ? "asc" : "desc", "linkedin_contact/")}`
        );
      }}
    >
      {text}
      <Icon
        name="asc"
        size={18}
        className={isActive ? "title-images-active" : "title-images"}
        onClick={() => {
          setIsSortedAsc(idx, !isActive ? true : false);
          setIsFiltering(
            `${getSortType(
              idx,
              !isActive ? "asc" : "desc",
              "linkedin_contact/"
            )}`
          );
        }}
      />
    </div>
  ) : (
    <div
      className={`container-icon-sort ${isActive ? "active" : ""}`}
      onClick={() => {
        setIsSortedAsc(idx, true);
        setIsFiltering(`${getSortType(idx, "asc", "linkedin_contact/")} `);
      }}
    >
      {text}
      <Icon
        name="desc"
        size={18}
        className={isActive ? "title-images-active" : "title-images"}
        onClick={() => {
          setIsSortedAsc(idx, true);
          setIsFiltering(`${getSortType(idx, "asc", "linkedin_contact/")} `);
        }}
      />
    </div>
  );
};

export default SortIcon;
