import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Modal } from "semantic-ui-react";
import { connectorAPI } from "../../../actions/connector";
// actions
import { notification } from "../../../actions/notification";
// img
import closeIcon from "../../../assets/image/icons/svg/line/Close.svg";
import imgConfirm from "../../../assets/image/auth-steps/unAuth.png";
import ResponsiveButton from "../../atoms/responsiveButton";

export const ModalConfirmLinkedInProfile = (props) => {
  const [actionDone, setActionDone] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    props.confirm && setActionDone(true);
  }, []);

  const handlerModal = () => {
    if (!loader) {
      props.setOpen(!props.open);
    }
    props.setLoader(false);
  };

  const handlerModalLink = async () => {
    setLoader(true);
    setActionDone(false);
    try {
      const metadata = props.connector.utils.createMetadata();
      let profile = await props.connector.api.addLinkedInProfile(
        props.profile,
        metadata
      );

      await props.connector.auth.switchToWorkspace(profile.id, metadata);
      await connectorAPI.getLinkedProfile();
      setActionDone(true);
    } catch (e) {
      handlerModal();
      notification.notificationUse(e);
      setTimeout(() => {
        props.setLoader(false);
      }, 5000);
    } finally {
      setLoader(false);
    }
  };

  const onClick = async () => {
    setLoader(true);
    try {
      await props.connector.auth.refreshAccessToken();
    } catch (e) {
      handlerModal();
      notification.notificationUse(e);
      setTimeout(() => {
        props.setLoader(false);
      }, 5000);
    } finally {
      setLoader(false);
    }
  };

  const modalBodyConfirm = () => {
    return (
      <div className="modal-create-campaign modal-create-linked">
        {/*<div className="modal-create-campaign-header">
          <img
            style={{ display: "none" }}
            height="212"
            src={closeIcon}
            alt="close"
            className="close"
            onClick={handlerModal}
          />
        </div>*/}
        <div className="modal-create-campaign-body">
          <h2 className="confirm-title">Account is ready for use!</h2>
          <img src={imgConfirm} alt="imgConfirm" height={212} />
          <p className="modal-description">Create your first campaign.</p>

          <div className="modal-create-linked__btn-container">
            <ResponsiveButton
              className="btn bold steps btn-v4"
              onClick={onClick}
              isLoading={loader}
            >
              {"Let’s start!"}
            </ResponsiveButton>
          </div>
        </div>
      </div>
    );
  };

  const modalBody = () => {
    return (
      <div className="modal-create-campaign modal-create-linked">
        <div className="modal-create-campaign-header">
          <img
            src={closeIcon}
            alt="close"
            className="close"
            onClick={handlerModal}
          />
        </div>
        <div className="modal-create-campaign-body">
          <h2 style={{ textAlign: "center" }}>Link this account?</h2>
          <p className="text-page-auth-modal-desc">
            You cannot change it later!
          </p>
          {!_.isEmpty(props.profile) && (
            <>
              <div className="modal-create-linked__avatar">
                {props.profile.picture && (
                  <img src={props.profile.picture} alt="pictureUrl" />
                )}

                {!props.profile.picture && <div className="fake-img" />}

                <div className="modal-create-linked__avatar-in" />
              </div>

              <h3>
                {props.profile.firstName} {props.profile.lastName}
              </h3>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="text-page-auth-link"
                href={
                  "https://www.linkedin.com/in/" +
                  props.profile.publicIdentifier
                }
              >
                {"https://www.linkedin.com/in/" +
                  props.profile.publicIdentifier}
              </a>

              <div className="modal-create-linked__btn-container">
                <button className="btn btn-v3 btn-v4" onClick={handlerModal}>
                  Back
                </button>
                <ResponsiveButton
                  className="btn bold steps btn-v4"
                  onClick={handlerModalLink}
                  isLoading={loader}
                >
                  {"Link"}
                </ResponsiveButton>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };
  return (
    <Modal
      open={props.open}
      onClose={() => {
        handlerModal();
        if (actionDone) {
          props.history.push("/contacts");
          document.location.reload();
        }
      }}
    >
      {props.confirm ? modalBodyConfirm() : modalBody()}
    </Modal>
  );
};

export default ModalConfirmLinkedInProfile;
