import React, { useEffect, useRef, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "../styles.scss";
import { app } from "../../../../actions/app";
import { mailbox } from "../../../../actions/mailbox";
import {
  CircularProgress,
  FormControl,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import SettingsButton from "../../../../assets/image/icons/buttons/settings.svg";
import Close from "../../../../assets/image/icons/buttons/close.svg";
import { Tabs } from "../../components/Tabs";
import Unread from "../../../../assets/image/icons/svg/icons/unread.svg";
import Archived from "../../../../assets/image/icons/svg/icons/archived.svg";
import InMail from "../../../../assets/image/icons/svg/icons/inmail.svg";
import MyConnections from "../../../../assets/image/icons/svg/icons/myConnections.svg";
import Spam from "../../../../assets/image/icons/svg/icons/spam.svg";
import Starred from "../../../../assets/image/icons/svg/icons/starred.svg";
import MailboxItem from "../MailboxItem";
import close from "../../../../assets/image/icons/svg/icons/Close-black.svg";
import emptySnail from "../../../../assets/image/snail-no.png";
import { automationApi } from "../../../../api/automationApi";
import store from "../../../../redux/store";
import { notification } from "../../../../actions/notification";
import Chats from "../../components/Chats/Chats";

const filters = [
  { icon: Unread, title: "Unread" },
  { icon: Starred, title: "Starred" },
  { icon: InMail, title: "InMail" },
  { icon: MyConnections, title: "My connections" },
  { icon: Archived, title: "Archived" },
  { icon: Spam, title: "Spam" },
];

export const mailboxType = {
  Focused: "Focused",
  Archived: "Archived",
  Starred: "Starred",
  InMail: "InMail",
  MyConnections: "My connections",
  Spam: "Spam",
  Unread: "Unread",
  Other: "Other",
  Search: "Search",
  Primary: "Primary",
};

const Mailbox = (props) => {
  const [nextLoader, setNextLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [searchMode, setSearchMode] = useState(false);
  const [searchLoader, setSearchLoader] = useState(true);
  const [mailboxListLoader, setMailboxListLoader] = useState(false);
  const [settingsLoader, setSettingsLoader] = useState(false);
  const [filter, setFilter] = useState("");
  const [filtersOpened, setFiltersOpened] = useState(false);
  const [focusedInboxEnabled, setFocusedInboxEnabled] = useState(null);
  const [headerHeight, setHeaderHeight] = useState(110);
  const mailboxRef = useRef(null);
  const { mailboxId } = useParams();
  const filteredConversations = () => {
    if (searchMode && search) {
      return props.searchResult;
    }
    const mailboxFunctions = {
      [mailboxType.Unread]: props.unread,
      [mailboxType.Focused]: props.focused,
      [mailboxType.Other]: props.other,
      [mailboxType.Starred]: props.starred,
      [mailboxType.InMail]: props.inMail,
      [mailboxType.MyConnections]: props.myConnections,
      [mailboxType.Archived]: props.archive,
      [mailboxType.Spam]: props.spam,
      [mailboxType.Primary]: props.primary,
    };
    return mailboxFunctions[filter] || props.primary;
  };
  const filteredConv = filteredConversations();

  const fetchData = async (fetchFunction) => {
    const filteredConv = filteredConversations();
    if (!filteredConv?.length) {
      setSearchLoader(true);
      try {
        await fetchFunction();
      } finally {
        setSearchLoader(false);
      }
    }
  };

  const fetchNextData = async (fetchFunction) => {
    setNextLoader(true);
    try {
      await fetchFunction();
    } finally {
      setNextLoader(false);
    }
  };
  const getFocusedRecentConv = () =>
    fetchData(mailbox.getFocusedRecentConversations);
  const getOtherRecentConv = () =>
    fetchData(mailbox.getOtherRecentConversations);
  const getSpamRecentConv = () => fetchData(mailbox.getSpamRecentConversations);
  const getArchivedRecentConv = () =>
    fetchData(mailbox.getArchivedRecentConversations);
  const getUnreadRecentConv = () =>
    fetchData(mailbox.getUnreadRecentConversations);
  const getInMailRecentConv = () =>
    fetchData(mailbox.getInMailRecentConversations);
  const getMyConnectionsRecentConv = () =>
    fetchData(mailbox.getMyConnectionsRecentConversations);
  const getStarredRecentConv = () =>
    fetchData(mailbox.getStarredRecentConversations);
  const getPrimaryRecentConv = () =>
    fetchData(mailbox.getPrimaryRecentConversations);

  const getSearchRecentConv = async () => {
    setSearchLoader(true);
    try {
      const response = await automationApi.getRecentSearchConversationsApi(
        search
      );
      store.dispatch({
        type: "CONVERSATIONS_SEARCH",
        payload: {
          conversations: response.data.items,
          nextCursor: response.data.nextCursor,
        },
      });
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      setSearchLoader(false);
    }
  };
  const getOtherNextConv = () =>
    fetchNextData(() =>
      mailbox.getOtherNextConversations(
        props.focused.nextCursor,
        props.focused.conversations
      )
    );
  const getStarredNextConv = () =>
    fetchNextData(() =>
      mailbox.getStarredNextConversations(
        props.starred.nextCursor,
        props.starred.conversations
      )
    );
  const getFocusedNextConv = () =>
    fetchNextData(() =>
      mailbox.getFocusedNextConversations(
        props.focused.nextCursor,
        props.focused.conversations
      )
    );
  const getSpamNextConv = () =>
    fetchNextData(() =>
      mailbox.getSpamNextConversations(
        props.spam.nextCursor,
        props.spam.conversations
      )
    );
  const getArchivedNextConv = () =>
    fetchNextData(() =>
      mailbox.getArchivedNextConversations(
        props.archive.nextCursor,
        props.archive.conversations
      )
    );
  const getUnreadNextConv = () =>
    fetchNextData(() =>
      mailbox.getUnreadNextConversations(
        props.unread.nextCursor,
        props.unread.conversations
      )
    );
  const getInMailNextConv = () =>
    fetchNextData(() =>
      mailbox.getInMailNextConversations(
        props.inMail.nextCursor,
        props.inMail.conversations
      )
    );
  const getMyConnectionsNextConv = () =>
    fetchNextData(() =>
      mailbox.getMyConnectionsNextConversations(
        props.myConnections.nextCursor,
        props.myConnections.conversations
      )
    );
  const getPrimaryNextConv = () =>
    fetchNextData(() =>
      mailbox.getPrimaryNextConversations(
        props.primary.nextCursor,
        props.primary.conversations
      )
    );

  const getSearchNextConv = () =>
    fetchNextData(() =>
      mailbox.getSearchNextConversations(
        props.searchResult.nextCursor,
        search,
        props.searchResult.conversations
      )
    );

  const handleFetchNextList = () => {
    if (search) {
      getSearchNextConv();
      return;
    }
    const nextConvFunctions = {
      [mailboxType.Unread]: getUnreadNextConv,
      [mailboxType.Focused]: getFocusedNextConv,
      [mailboxType.Other]: getOtherNextConv,
      [mailboxType.Starred]: getStarredNextConv,
      [mailboxType.InMail]: getInMailNextConv,
      [mailboxType.MyConnections]: getMyConnectionsNextConv,
      [mailboxType.Archived]: getArchivedNextConv,
      [mailboxType.Spam]: getSpamNextConv,
      [mailboxType.Primary]: getPrimaryNextConv,
    };
    const getNextConvFunction = nextConvFunctions[filter] || getPrimaryNextConv;
    getNextConvFunction();
  };
  const handleChooseFilter = () => {
    const recentConvFunctions = {
      [mailboxType.Unread]: getUnreadRecentConv,
      [mailboxType.Focused]: getFocusedRecentConv,
      [mailboxType.Other]: getOtherRecentConv,
      [mailboxType.Starred]: getStarredRecentConv,
      [mailboxType.InMail]: getInMailRecentConv,
      [mailboxType.MyConnections]: getMyConnectionsRecentConv,
      [mailboxType.Archived]: getArchivedRecentConv,
      [mailboxType.Spam]: getSpamRecentConv,
      [mailboxType.Primary]: getPrimaryRecentConv,
    };
    const getRecentConvFunction = recentConvFunctions[filter];
    if (getRecentConvFunction) {
      getRecentConvFunction();
    }
  };
  const handleScroll = () => {
    const element = mailboxRef.current;
    const { scrollTop, clientHeight, scrollHeight } = element;
    const shouldFetchNextList =
      scrollTop + clientHeight >= scrollHeight - 100 &&
      filteredConv.nextCursor &&
      !nextLoader;
    if (shouldFetchNextList) {
      handleFetchNextList();
    }
  };
  useEffect(() => {
    const mailboxElement = mailboxRef.current;
    if (mailboxElement) {
      mailboxElement.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (mailboxElement) {
        mailboxElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    if (filteredConv.conversations?.length && mailboxId) {
      const chosenMailboxInfo = filteredConv.conversations.find(
        (conv) => conv.entity_id === mailboxId
      );
      if (chosenMailboxInfo) {
        mailbox.setParticipants(chosenMailboxInfo.participants);
      } else {
        mailbox.getParticipants(mailboxId);
      }
    }
  }, [filteredConv.conversations, mailboxId]);

  useEffect(() => {
    if (!search) {
      handleChooseFilter();
    }
  }, [search, filter]);

  useEffect(() => {
    !mailboxId ? setMailboxListLoader(true) : setMailboxListLoader(false);
  }, [mailboxId]);

  useEffect(() => {
    filter === mailboxType.Primary
      ? setHeaderHeight(116)
      : setHeaderHeight(160);
  }, [filter]);

  useEffect(() => {
    app.changePage("/mailbox");
    const fetchSettings = async () => {
      setSettingsLoader(true);
      try {
        const response = await automationApi.getMailboxSettings();
        setFocusedInboxEnabled(response.data.focusedInboxEnabled);
        if (response.data.focusedInboxEnabled) {
          setFilter(mailboxType.Focused);
        } else {
          setFilter(mailboxType.Primary);
        }
      } catch (error) {
        notification.notificationUse(error);
      } finally {
        setSettingsLoader(false);
      }
    };

    fetchSettings();
  }, []);
  const handleKeyPress = (e) => {
    if (e.key === "Enter" || e.key === "Return") {
      if (e.target.value) {
        getSearchRecentConv();
        setSearchMode(true);
      } else {
        setSearchMode(false);
      }
    }
  };

  const handleClickFilters = () => {
    setFiltersOpened(!filtersOpened);
  };

  const handleResetSearchQuery = () => {
    setSearch("");
  };

  const handleClickInputEnd = (e) => {
    setSearchMode(true);
    search ? handleResetSearchQuery() : handleClickFilters();
    e.stopPropagation();
  };
  const handleGetMessages = async (id) => {
    mailbox.setChosenMailboxId(id);
    setMailboxListLoader(true);
    try {
      await mailbox.getRecentEvents(id);
      props.history.push(`/mailbox/thread/${id}`);
    } finally {
      setMailboxListLoader(false);
    }
  };

  useEffect(() => {
    if (filteredConv.conversations?.length) {
      setSearchLoader(false);
    }
    if (mailboxId && filteredConv.conversations?.length) {
      handleGetMessages(mailboxId);
    } else if (!mailboxId && filteredConv.conversations?.length) {
      handleGetMessages(filteredConv.conversations[0].entity_id);
    }
  }, [filteredConv.conversations, mailboxId]);
  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const handleCloseTag = () => {
    if (focusedInboxEnabled) {
      setFilter(mailboxType.Focused);
    } else {
      setFilter(mailboxType.Primary);
    }
  };

  const getCurrentAppProfile = (props) => {
    const result = props.profile.appProfile.impersonatedPersona
      ? props.profile.appProfile.impersonatedPersona
      : props.profile.appProfile;
    return result;
  };

  const isChatLoading = mailboxListLoader || !props.mailboxParticipants;
  const initialLoading = searchLoader || (mailboxListLoader && settingsLoader);
  const shouldShowFilterButton =
    (filter === mailboxType.Unread ||
      filter === mailboxType.InMail ||
      filter === mailboxType.Archived ||
      filter === mailboxType.MyConnections ||
      filter === mailboxType.Starred ||
      filter === mailboxType.Spam) &&
    !search;
  const shouldShowTabs =
    !search && (filter === mailboxType.Focused || filter === mailboxType.Other);
  return (
    <>
      {initialLoading ? (
        <div className="auto-mode-loading">
          <CircularProgress
            color="primary"
            size={60}
            classes={{ colorPrimary: "material-loader" }}
          />
        </div>
      ) : (
        <div>
          <div
            className="wrapper-mailbox"
            onClick={() => setFiltersOpened(false)}
          >
            <div className="mailbox-messages-list">
              <FormControl>
                <TextField
                  value={search}
                  onChange={(e) => handleChange(e)}
                  size="small"
                  variant="outlined"
                  InputProps={{
                    onKeyPress: (e) => handleKeyPress(e),
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => handleClickInputEnd(e)}
                      >
                        {search ? (
                          <img
                            alt="close"
                            className="mailbox-search-close"
                            src={Close}
                          />
                        ) : (
                          <img
                            alt="filters"
                            style={{ width: "38px" }}
                            src={SettingsButton}
                          />
                        )}
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: "32px",
                      paddingRight: "0",
                      paddingTop: "0",
                    },
                  }}
                  style={{
                    borderRadius: "32px",
                    paddingLeft: "12px",
                    paddingTop: "12px",
                    width: "330px",
                    userSelect: "none",
                  }}
                />
              </FormControl>
              {shouldShowFilterButton && (
                <button className="mailbox-tag" onClick={handleCloseTag}>
                  <img alt="close" src={close} />
                  <p>{filter}</p>
                </button>
              )}
              {filtersOpened && (
                <div
                  className="mailbox-filters"
                  onClick={(e) => e.stopPropagation()}
                >
                  {filters.map((filter) => (
                    <div
                      key={filter.title}
                      onClick={() => {
                        setFilter(filter.title);
                        setFiltersOpened(false);
                      }}
                      className="mailbox-filters-item"
                    >
                      <img alt="filterIcon" src={filter.icon} />
                      <p>{filter.title}</p>
                    </div>
                  ))}
                </div>
              )}
              {shouldShowTabs && (
                <Tabs
                  filter={filter}
                  setFilter={setFilter}
                  handleResetSearchQuery={handleResetSearchQuery}
                />
              )}
              <div
                id="mailbox"
                ref={mailboxRef}
                className="mailbox-wrapper"
                style={{ height: `calc(100vh - ${headerHeight}px)` }}
              >
                {searchLoader ? (
                  <div className="mailbox-search-mode">
                    <CircularProgress
                      color="primary"
                      size={40}
                      classes={{ colorPrimary: "material-loader" }}
                    />
                  </div>
                ) : filteredConv.conversations?.length ? (
                  filteredConv.conversations.map((item) => (
                    <MailboxItem
                      onClick={() => handleGetMessages(item.entity_id)}
                      userEntityId={
                        getCurrentAppProfile(props).linkedInProfile.entityId
                      }
                      key={item.id}
                      data={item}
                    />
                  ))
                ) : (
                  <div className="mailbox-search-mode">
                    <img
                      className="mailbox-search-image"
                      alt="noResult"
                      src={emptySnail}
                    />
                    <p>No results found</p>
                  </div>
                )}
                {nextLoader && filteredConv.nextCursor && (
                  <div className="search-wrap">
                    <CircularProgress
                      color="primary"
                      size={30}
                      classes={{ colorPrimary: "material-loader" }}
                    />
                  </div>
                )}
              </div>
            </div>
            {isChatLoading ? (
              <div className="mailbox-loading-mode">
                <CircularProgress
                  color="primary"
                  size={40}
                  classes={{ colorPrimary: "material-loader" }}
                />
              </div>
            ) : (
              <Chats
                handleGetMessages={handleGetMessages}
                loader={mailboxListLoader}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = function (state) {
  return {
    app: state.app,
    profile: state.user.profileFull,
    focused: state.mailbox.focusedConversations,
    unread: state.mailbox.unreadConversations,
    starred: state.mailbox.starredConversations,
    inMail: state.mailbox.inMailConversations,
    myConnections: state.mailbox.myConnectionsConversations,
    other: state.mailbox.otherConversations,
    spam: state.mailbox.spamConversations,
    primary: state.mailbox.primaryConversations,
    searchResult: state.mailbox.searchConversations,
    archive: state.mailbox.archiveConversations,
    recentEvents: state.mailbox.recentEvents,
    eventsNextCursor: state.mailbox.eventsNextCursor,
    chosenMailboxId: state.mailbox.chosenMailboxId,
    mailboxParticipants: state.mailbox.mailboxParticipants,
  };
};

export default connect(mapStateToProps)(withRouter(Mailbox));
