import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import _ from "lodash";
// Style
import "./style.scss";
import DropdownSimple from "../../atoms/DropdownSimple";
import { filters } from "../../../actions/filters";
import RadioGroupCustom from "../../atoms/RadioGroupCustom";
import LocationIcon from "../../../assets/image/icons/svg/location-icon-yellow.svg";
import DatePickerRange from "../../atoms/DatePickerRange";
import { app } from "../../../actions/app";
import { contacts } from "../../../actions/contacts";
import { CONSTS } from "../../../config/objectConst";

export class Filters extends Component {
  static propTypes = {
    filtersData: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = this.createStateFromProps();
  }

  createStateFromProps() {
    let result = {
      filterDataForModal: _.cloneDeep(this.props.filters.filterDataForModal),
      previousPropsFilterDataForModal: _.cloneDeep(
        this.props.filters.filterDataForModal
      ),
      filterData: _.cloneDeep(this.props.filters.filterData),
      previousPropsFilterData: _.cloneDeep(this.props.filters.filterData),
    };
    return result;
  }

  /* TODO #596 `Состояние фильтров не делиться между страницами, а обнуляется.`
      clearAllFilters when change router and component mount
  */
  componentDidMount() {
    // this.clearFilterData();
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      let isModal = this.props.from === "addToCampaignOfModal";
      if (!isModal) {
        this.clearAllFilters(); // clear for local state
        filters.clearAllFilters(isModal); // clear global state
        this.props.filtersControllerByParams(false); // close filters
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Here are coming actual props, not previous.
    // Most likely they are improperly maintained
    // Due to this we store previous props in local state as a workaround
    if (
      !_.isEqual(
        this.props.filters.filterDataForModal,
        this.state.previousPropsFilterDataForModal
      ) ||
      !_.isEqual(
        this.props.filters.filterData,
        this.state.previousPropsFilterData
      )
    ) {
      this.setState(this.createStateFromProps());
    }

    if (
      this.props.keywordsForModal !== prevProps.keywordsForModal ||
      this.props.keywords !== prevProps.keywords
    ) {
      this.setState(this.createStateFromProps(), async () => {
        await this.applyFilter();
      });
    }
  }

  clearFilterData() {
    const filterDataInitial = [
      {
        id: 1,
        name: "connection",
        exclude: false,
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 2,
        name: "location",
        exclude: false,
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 3,
        name: "industry",
        exclude: false,
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 4,
        name: "keywords",
        exclude: false,
        search: true,
        value: [],
        tag: "",
      },
      {
        id: 5,
        name: "company",
        exclude: false,
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 6,
        selectId: 9,
        name: "contacted",
        exclude: false,
        search: false,
        value: [
          { id: 1, value: null },
          { id: 2, value: null },
        ],
        tag: "",
      },
      {
        id: 7,
        name: "status",
        exclude: false,
        search: false,
        value: "",
        tag: "",
      },
      {
        id: 8,
        name: "createdSelect",
        exclude: false,
        search: false,
        value: null,
      },
      {
        id: 9,
        name: "contactedSelect",
        exclude: false,
        search: false,
        value: null,
      },
      {
        id: 10,
        name: "campaigns",
        exclude: false,
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 11,
        name: "exclude contactedSelect",
        exclude: false,
        search: false,
        value: null,
      },
      {
        id: 12,
        name: "NOT: keywords",
        exclude: true,
        search: true,
        value: [],
        tag: "",
      },
      {
        id: 13,
        name: "NOT: campaigns",
        exclude: true,
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 14,
        selectId: 11,
        name: "NOT: contacted",
        exclude: true,
        search: false,
        value: [
          { id: 1, value: null },
          { id: 2, value: null },
        ],
        tag: "",
      },
      {
        id: 15,
        selectId: 8,
        name: "created",
        exclude: false,
        search: false,
        value: [
          { id: 1, value: null },
          { id: 2, value: null },
        ],
        tag: "",
      },
    ];
    if (this.props.from === "addToCampaignOfModal") {
      this.setState({ filterDataForModal: filterDataInitial });
    } else {
      this.setState({ filterData: filterDataInitial });
    }
  }

  getValue = (id) => {
    const filterData =
      this.props.from === "addToCampaignOfModal"
        ? this.state.filterDataForModal
        : this.state.filterData;
    const element = _.find(filterData, ["id", id]);
    return element.value;
  };

  getStartEndValue = (id, dateId) => {
    const arrayOfValues = this.getValue(id);
    const value = _.find(arrayOfValues, ["id", dateId]);
    return value.value;
  };

  setStartEndValue = (id, dateId, newValue) => {
    const filterData =
      this.props.from === "addToCampaignOfModal"
        ? this.state.filterDataForModal
        : this.state.filterData;
    const element = _.find(filterData, ["id", id]);

    const changedElement = element.value.map((item) => {
      if (item.id === dateId) {
        item.value = newValue;
      }
      return item;
    });

    const index = filterData.indexOf(changedElement);
    filterData[index] = element;

    if (this.props.from === "addToCampaignOfModal") {
      this.setState({ filterDataForModal: filterData });
    } else {
      this.setState({ filterData: filterData });
    }
  };

  setValue = (id, value) => {
    const filterData =
      this.props.from === "addToCampaignOfModal"
        ? this.state.filterDataForModal
        : this.state.filterData;
    const element = _.find(filterData, ["id", id]);
    element.value = value;

    const index = filterData.indexOf(element);
    filterData[index] = element;

    if (this.props.from === "addToCampaignOfModal") {
      this.setState({ filterDataForModal: filterData });
    } else {
      this.setState({ filterData: filterData });
    }
  };

  setDateValue = (dateId, selectValue) => {
    const filterData =
      this.props.from === "addToCampaignOfModal"
        ? this.state.filterDataForModal
        : this.state.filterData;
    if (selectValue) {
      let nowDate = new Date();
      let lastDay = new Date();
      let lastWeak = new Date();
      let lastMonth = new Date();
      let lastYear = new Date();
      lastDay.setDate(nowDate.getDate() - 1);
      lastWeak.setDate(nowDate.getDate() - 7);
      lastMonth.setMonth(nowDate.getMonth() - 1);
      lastYear.setFullYear(nowDate.getFullYear() - 1);
      const element = _.find(filterData, ["id", dateId]);
      this.setValue(element.selectId, selectValue);
      switch (selectValue && selectValue.value) {
        case "day":
          this.setStartEndValue(dateId, 1, lastDay);
          this.setStartEndValue(dateId, 2, nowDate);
          break;
        case "week":
          this.setStartEndValue(dateId, 1, lastWeak);
          this.setStartEndValue(dateId, 2, nowDate);
          break;
        case "month":
          this.setStartEndValue(dateId, 1, lastMonth);
          this.setStartEndValue(dateId, 2, nowDate);
          break;
        case "year":
          this.setStartEndValue(dateId, 1, lastYear);
          this.setStartEndValue(dateId, 2, nowDate);
          break;

        default:
          break;
      }
    } else {
      const element = _.find(filterData, ["id", dateId]);
      element.value = [
        { id: 1, value: null },
        { id: 2, value: null },
      ];
      this.setValue(element.selectId, selectValue);

      const index = filterData.indexOf(element);
      filterData[index] = element;

      if (this.props.from === "addToCampaignOfModal") {
        this.setState({ filterDataForModal: filterData });
      } else {
        this.setState({ filterData: filterData });
      }
    }
  };

  applyFilter = async () => {
    let filterData =
      this.props.from === "addToCampaignOfModal"
        ? _.cloneDeep(this.state.filterDataForModal)
        : _.cloneDeep(this.state.filterData);

    filters.changeAllTags(filterData);
    if (this.props.from === "addToCampaignOfModal") {
      this.props.loadHandlerForModal(true);
      this.props.pageHandler(0);

      const filter = [
        `not (campaigns/any(c:c eq '${this.props.activeImportId}'))`,
      ];
      const filtersQuery = filters.buildFilterQuery(filterData);
      if (filtersQuery) {
        filter.push(filtersQuery);
      }
      filters.changeFiltersQueryForModal(
        filterData,
        this.props.from,
        this.props.activeImportId
      );
      this.props.getContacts({
        skip: 0,
        filter: filter.join(" and "),
        keywords: this.props.keywordsForModal,
        orderby: this.props.orderby,
      });
      filters.changeFiltersForModal(filterData, true);
      if (this.props.show === true) {
        this.props.filtersControllerToggle();
      }
    } else {
      app.changeTableContentLoaderStatus(true);
      contacts.changeContactsPage(0);
      await filters.getContacts(
        filterData,
        this.props.activeImportId,
        this.props.from,
        this.props.page,
        this.props.keywords,
        this.props.history.location.pathname,
        this.props.isFiltering
      );
      filters.changeFilters(filterData, true);
      if (this.props.show === true) {
        this.props.filtersControllerToggle();
      }
    }
    this.props.clearFilterController(false);
  };

  clearAllFilters = () => {
    const filterDataInitial = [
      {
        id: 1,
        name: "connection",
        exclude: false,
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 2,
        name: "location",
        exclude: false,
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 3,
        name: "industry",
        exclude: false,
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 4,
        name: "keywords",
        exclude: false,
        search: true,
        value: [],
        tag: "",
      },
      {
        id: 5,
        name: "company",
        exclude: false,
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 6,
        selectId: 9,
        name: "contacted",
        exclude: false,
        search: false,
        value: [
          { id: 1, value: null },
          { id: 2, value: null },
        ],
        tag: "",
      },
      {
        id: 7,
        name: "status",
        exclude: false,
        search: false,
        value: "",
        tag: "",
      },
      {
        id: 8,
        name: "createdSelect",
        exclude: false,
        search: false,
        value: null,
      },
      {
        id: 9,
        name: "contactedSelect",
        exclude: false,
        search: false,
        value: null,
      },
      {
        id: 10,
        name: "campaigns",
        exclude: false,
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 11,
        name: "exclude contactedSelect",
        exclude: false,
        search: false,
        value: null,
      },
      {
        id: 12,
        name: "NOT: keywords",
        exclude: true,
        search: true,
        value: [],
        tag: "",
      },
      {
        id: 13,
        name: "NOT: campaigns",
        exclude: true,
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 14,
        selectId: 11,
        name: "NOT: contacted",
        exclude: true,
        search: false,
        value: [
          { id: 1, value: null },
          { id: 2, value: null },
        ],
        tag: "",
      },
      {
        id: 15,
        selectId: 8,
        name: "created",
        exclude: false,
        search: false,
        value: [
          { id: 1, value: null },
          { id: 2, value: null },
        ],
        tag: "",
      },
    ];
    const isModal = this.props.from === "addToCampaignOfModal";
    if (isModal) {
      this.setState({
        filterDataForModal: filterDataInitial,
      });
    } else {
      this.setState({
        filterData: filterDataInitial,
      });
    }
  };

  clearValue = (id, option) => {
    const filterData =
      this.props.from === "addToCampaignOfModal"
        ? this.state.filterDataForModal
        : this.state.filterData;
    const isModal = this.props.from === "addToCampaignOfModal";

    const element = _.find(filterData, ["id", id]);
    const newValue = element.value.filter((item) => option.key !== item.key);
    element.value = newValue;

    const index = filterData.indexOf(element);
    filterData[index] = element;

    if (isModal) {
      this.setState({ filterDataForModal: filterData });
    } else {
      this.setState({ filterData: filterData });
    }
  };

  render() {
    return (
      <div className={`filter-container ${!this.props.show ? "hide" : ""}`}>
        <div className="filters-grid">
          <Grid
            container
            justifycontent="flex-start"
            alignItems="flex-start"
            className="grid"
          >
            <h4 className="filter-title">Include</h4>
            <Grid item xs={3}>
              <DropdownSimple
                id={1}
                multiple
                tags
                options={this.props.filters.options.colourOptions}
                label={"Connection"}
                placeholder="Connection"
                onChange={(e, newValue) => this.setValue(1, newValue)}
                onTagClick={this.clearValue}
                value={this.getValue(1)}
              />
            </Grid>
            <Grid item xs={3}>
              <DropdownSimple
                id={2}
                multiple
                tags
                customIcon={
                  <img
                    src={LocationIcon}
                    className={"dropdown-simple__icon-location"}
                    alt="dropdownicon"
                  />
                }
                getOptions={filters.getContactsLocation}
                options={this.props.filters.options.location}
                label={"Location"}
                placeholder="Search"
                onChange={(e, newValue) => this.setValue(2, newValue)}
                onTagClick={this.clearValue}
                value={this.getValue(2)}
                dataForAuto={{}}
              />
            </Grid>
            <Grid item xs={3}>
              <DropdownSimple
                options={this.props.filters.options.contacted}
                label={"Last Contacted"}
                placeholder="Select period"
                onChange={(e, newValue) => this.setDateValue(6, newValue)}
                value={this.getValue(9)}
              />
            </Grid>
            <Grid item xs={3}>
              {this.getValue(9) && this.getValue(9).value === "custom" && (
                <DatePickerRange
                  startDate={this.getStartEndValue(6, 1)}
                  onChangeStartDate={(date) =>
                    this.setStartEndValue(6, 1, date)
                  }
                  endDate={this.getStartEndValue(6, 2)}
                  onChangeEndDate={(date) => this.setStartEndValue(6, 2, date)}
                />
              )}
            </Grid>
          </Grid>
        </div>
        <div className="filters-grid">
          <Grid
            container
            justifycontent="flex-start"
            alignItems="flex-start"
            className="grid"
          >
            <Grid item xs={3}>
              <DropdownSimple
                id={10}
                multiple
                tags
                getOptions={filters.getCampaignsField}
                options={this.props.filters.options.campaigns}
                label={"Participated in Campaigns"}
                placeholder={"Select campaigns"}
                onChange={(e, newValue) => this.setValue(10, newValue)}
                onTagClick={this.clearValue}
                value={this.getValue(10)}
                dataForAuto={{
                  filter: "not (state eq 'Compose')",
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <DropdownSimple
                options={this.props.filters.options.contacted}
                label={`Added to ${CONSTS.PROJECT_NAME}`}
                placeholder="Select date"
                onChange={(e, newValue) => this.setDateValue(15, newValue)}
                value={this.getValue(8)}
              />
            </Grid>
            <Grid item xs={3}>
              {this.getValue(8) && this.getValue(8).value === "custom" && (
                <DatePickerRange
                  startDate={this.getStartEndValue(15, 1)}
                  onChangeStartDate={(date) =>
                    this.setStartEndValue(15, 1, date)
                  }
                  endDate={this.getStartEndValue(15, 2)}
                  onChangeEndDate={(date) => this.setStartEndValue(15, 2, date)}
                />
              )}
            </Grid>
          </Grid>
        </div>
        <div className="filters-grid">
          <Grid
            container
            justifycontent="flex-start"
            alignItems="flex-start"
            className="grid"
          >
            <Grid item xs={3}>
              <RadioGroupCustom
                label={"Account Status"}
                items={this.props.filters.options.status}
                value={this.getValue(7)}
                onChange={(e) => this.setValue(7, e.target.value)}
              />
            </Grid>
          </Grid>
        </div>
        <div className="filters-grid filters-grid_with-border">
          <Grid
            container
            justifycontent="flex-start"
            alignItems="flex-start"
            className="grid"
          >
            <h4 className="filter-title">Exclude</h4>
            {/* <Grid item xs={3}>
              <TextFieldTags
                id={12}
                label={"Keywords"}
                placeholder="Enter keywords"
                tags={this.getValue(12)}
                onEnter={(e) => this.onEnterValue(e, 12)}
                onTagClick={this.clearValue}
              />
            </Grid> */}
            <Grid item xs={3}>
              <DropdownSimple
                id={13}
                multiple
                tags
                getOptions={filters.getCampaignsField}
                options={this.props.filters.options.campaigns}
                label={"Participated in Campaigns"}
                placeholder={"Select campaigns"}
                onChange={(e, newValue) => this.setValue(13, newValue)}
                onTagClick={this.clearValue}
                value={this.getValue(13)}
                dataForAuto={{
                  filter: "not (state eq 'Compose')",
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <DropdownSimple
                options={this.props.filters.options.contacted}
                label={"Last Contacted"}
                placeholder="Select period"
                onChange={(e, newValue) => this.setDateValue(14, newValue)}
                value={this.getValue(11)}
              />
            </Grid>
            <Grid item xs={3}>
              {this.getValue(11) && this.getValue(11).value === "custom" && (
                <DatePickerRange
                  startDate={this.getStartEndValue(14, 1)}
                  onChangeStartDate={(date) =>
                    this.setStartEndValue(14, 1, date)
                  }
                  endDate={this.getStartEndValue(14, 2)}
                  onChangeEndDate={(date) => this.setStartEndValue(14, 2, date)}
                />
              )}
            </Grid>
          </Grid>
        </div>
        <div className="filter-controls">
          <button
            type="button"
            className="btn-outline alt"
            onClick={() => this.applyFilter()}
          >
            Apply
          </button>
          <button
            type="button"
            className="btn with-border semi-bold"
            onClick={this.clearAllFilters}
          >
            Clear all
          </button>
        </div>
      </div>
    );
  }
}

export default Filters;
