import React from "react";
import "./style.scss";
import arrow from "../../../assets/image/icons/svg/arrow-left.svg";
import fragment from "../../../assets/image/icons/svg/fragment.svg";

const InformationalTip = ({
  data,
  onClose,
  arrowPosition,
  centered,
  maxWidth,
}) => {
  return (
    <div
      className="main-modal-wrapper"
      style={{ right: centered, maxWidth: maxWidth }}
    >
      <img
        style={{ right: arrowPosition }}
        src={fragment}
        alt="fragment"
        className={"fragment"}
      />
      <p className="title" style={{ fontWeight: "400" }}>
        {data.title}
      </p>
      <ul>
        {data.content.map((item, i) => (
          <li
            key={i}
            dangerouslySetInnerHTML={{ __html: item }}
            style={{ whiteSpace: maxWidth ? "wrap" : "nowrap" }}
          />
        ))}
      </ul>
      {data.more && (
        <a
          href={data.more}
          className="link"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => onClose(false)}
        >
          More
          <img src={arrow} alt="arrow" />
        </a>
      )}
    </div>
  );
};

export default InformationalTip;
