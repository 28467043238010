import { automationApi } from "../api/automationApi";
import store from "../redux/store";
import { notification } from "./notification";

export const mailbox = {
  getInMailRecentConversations: async () => {
    try {
      const response = await automationApi.getRecentInMailConversationsApi();
      store.dispatch({
        type: "CONVERSATIONS_INMAIL",
        payload: {
          conversations: response.data.items,
          nextCursor: response.data.nextCursor,
        },
      });
    } catch (error) {
      notification.notificationUse(error);
    }
  },
  getFocusedRecentConversations: async () => {
    try {
      const response = await automationApi.getRecentFocusedConversationsApi();
      store.dispatch({
        type: "CONVERSATIONS_FOCUSED",
        payload: {
          conversations: response.data.items,
          nextCursor: response.data.nextCursor,
        },
      });
    } catch (error) {
      notification.notificationUse(error);
    }
  },
  getArchivedRecentConversations: async () => {
    try {
      const response = await automationApi.getRecentArchivedConversationsApi();
      store.dispatch({
        type: "CONVERSATIONS_ARCHIVE",
        payload: {
          conversations: response.data.items,
          nextCursor: response.data.nextCursor,
        },
      });
    } catch (error) {
      notification.notificationUse(error);
    }
  },
  getSpamRecentConversations: async () => {
    try {
      const response = await automationApi.getRecentSpamConversationsApi();
      store.dispatch({
        type: "CONVERSATIONS_SPAM",
        payload: {
          conversations: response.data.items,
          nextCursor: response.data.nextCursor,
        },
      });
    } catch (error) {
      notification.notificationUse(error);
    }
  },
  getUnreadRecentConversations: async () => {
    try {
      const response = await automationApi.getRecentUnreadConversationsApi();
      store.dispatch({
        type: "CONVERSATIONS_UNREAD",
        payload: {
          conversations: response.data.items,
          nextCursor: response.data.nextCursor,
        },
      });
    } catch (error) {
      notification.notificationUse(error);
    }
  },
  getStarredRecentConversations: async () => {
    try {
      const response = await automationApi.getRecentStarredConversationsApi();
      store.dispatch({
        type: "CONVERSATIONS_STARRED",
        payload: {
          conversations: response.data.items,
          nextCursor: response.data.nextCursor,
        },
      });
    } catch (error) {
      notification.notificationUse(error);
    }
  },
  getSearchRecentConversations: async (search) => {
    try {
      const response = await automationApi.getRecentSearchConversationsApi(
        search
      );
      store.dispatch({
        type: "CONVERSATIONS_SEARCH",
        payload: {
          conversations: response.data.items,
          nextCursor: response.data.nextCursor,
        },
      });
    } catch (error) {
      notification.notificationUse(error);
    }
  },
  getMyConnectionsRecentConversations: async () => {
    try {
      const response =
        await automationApi.getRecentMyConnectionsConversationsApi();
      store.dispatch({
        type: "CONVERSATIONS_MY_CONNECTIONS",
        payload: {
          conversations: response.data.items,
          nextCursor: response.data.nextCursor,
        },
      });
    } catch (error) {
      notification.notificationUse(error);
    }
  },
  getOtherRecentConversations: async () => {
    try {
      const response = await automationApi.getRecentOtherConversationsApi();
      store.dispatch({
        type: "CONVERSATIONS_OTHER",
        payload: {
          conversations: response.data.items,
          nextCursor: response.data.nextCursor,
        },
      });
    } catch (error) {
      notification.notificationUse(error);
    }
  },
  getPrimaryRecentConversations: async () => {
    try {
      const response = await automationApi.getRecentPrimaryConversationsApi();
      store.dispatch({
        type: "CONVERSATIONS_PRIMARY",
        payload: {
          conversations: response.data.items,
          nextCursor: response.data.nextCursor,
        },
      });
    } catch (error) {
      notification.notificationUse(error);
    }
  },
  getOtherNextConversations: async (nextCursor, prevState) => {
    try {
      const response = await automationApi.getNextOtherConversationsApi(
        nextCursor
      );
      store.dispatch({
        type: "CONVERSATIONS_OTHER",
        payload: {
          conversations: [...prevState, ...response.data.items],
          nextCursor: response.data.nextCursor,
        },
      });
    } catch (error) {
      notification.notificationUse(error);
    }
  },
  getSpamNextConversations: async (nextCursor, prevState) => {
    try {
      const response = await automationApi.getNextSpamConversationsApi(
        nextCursor
      );
      store.dispatch({
        type: "CONVERSATIONS_SPAM",
        payload: {
          conversations: [...prevState, ...response.data.items],
          nextCursor: response.data.nextCursor,
        },
      });
    } catch (error) {
      notification.notificationUse(error);
    }
  },
  getFocusedNextConversations: async (nextCursor, prevState) => {
    try {
      const response = await automationApi.getNextFocusedConversationsApi(
        nextCursor
      );
      store.dispatch({
        type: "CONVERSATIONS_FOCUSED",
        payload: {
          conversations: [...prevState, ...response.data.items],
          nextCursor: response.data.nextCursor,
        },
      });
    } catch (error) {
      notification.notificationUse(error);
    }
  },
  getArchivedNextConversations: async (nextCursor, prevState) => {
    try {
      const response = await automationApi.getNextArchivedConversationsApi(
        nextCursor
      );
      store.dispatch({
        type: "CONVERSATIONS_ARCHIVE",
        payload: {
          conversations: [...prevState, ...response.data.items],
          nextCursor: response.data.nextCursor,
        },
      });
    } catch (error) {
      notification.notificationUse(error);
    }
  },
  getPrimaryNextConversations: async (nextCursor, prevState) => {
    try {
      const response = await automationApi.getNextPrimaryConversationsApi(
        nextCursor
      );
      store.dispatch({
        type: "CONVERSATIONS_PRIMARY",
        payload: {
          conversations: [...prevState, ...response.data.items],
          nextCursor: response.data.nextCursor,
        },
      });
    } catch (error) {
      notification.notificationUse(error);
    }
  },
  getUnreadNextConversations: async (nextCursor, prevState) => {
    try {
      const response = await automationApi.getNextUnreadConversationsApi(
        nextCursor
      );
      store.dispatch({
        type: "CONVERSATIONS_UNREAD",
        payload: {
          conversations: [...prevState, ...response.data.items],
          nextCursor: response.data.nextCursor,
        },
      });
    } catch (error) {
      notification.notificationUse(error);
    }
  },
  getStarredNextConversations: async (nextCursor, prevState) => {
    try {
      const response = await automationApi.getNextStarredConversationsApi(
        nextCursor
      );
      store.dispatch({
        type: "CONVERSATIONS_STARRED",
        payload: {
          conversations: [...prevState, ...response.data.items],
          nextCursor: response.data.nextCursor,
        },
      });
    } catch (error) {
      notification.notificationUse(error);
    }
  },
  getMyConnectionsNextConversations: async (nextCursor, prevState) => {
    try {
      const response = await automationApi.getNextMyConnectionsConversationsApi(
        nextCursor
      );
      store.dispatch({
        type: "CONVERSATIONS_MY_CONNECTIONS",
        payload: {
          conversations: [...prevState, ...response.data.items],
          nextCursor: response.data.nextCursor,
        },
      });
    } catch (error) {
      notification.notificationUse(error);
    }
  },
  getInMailNextConversations: async (nextCursor, prevState) => {
    try {
      const response = await automationApi.getNextInMailConversationsApi(
        nextCursor
      );
      store.dispatch({
        type: "CONVERSATIONS_INMAIL",
        payload: {
          conversations: [...prevState, ...response.data.items],
          nextCursor: response.data.nextCursor,
        },
      });
    } catch (error) {
      notification.notificationUse(error);
    }
    const response = await automationApi.getNextInMailConversationsApi(
      nextCursor
    );
    return response;
  },
  getSearchNextConversations: async (nextCursor, search, prevState) => {
    try {
      const response = await automationApi.getNextSearchConversationsApi(
        nextCursor,
        search
      );
      store.dispatch({
        type: "CONVERSATIONS_SEARCH",
        payload: {
          conversations: [...prevState, ...response.data.items],
          nextCursor: response.data.nextCursor,
        },
      });
    } catch (error) {
      notification.notificationUse(error);
    }
  },
  getRecentEvents: async (id, refetch) => {
    const storedData = store.getState().mailbox.recentEvents[id];
    if (storedData && !refetch) {
      return;
    } else {
      try {
        const response = await automationApi.getRecentEventsApi(id);
        store.dispatch({
          type: "RECENT_EVENTS",
          payload: {
            events: response.data.items,
            eventsNextCursor: response.data.prevCursor,
            id,
          },
        });
        store.dispatch({
          type: "BUNDLED_EVENTS",
          payload: response.data.items,
        });
      } catch (error) {
        notification.notificationUse(error);
      }
    }
  },
  getNextEvents: async (id, prevState, cursor) => {
    try {
      const response = await automationApi.getNextEventsApi(id, cursor);
      store.dispatch({
        type: "NEXT_EVENTS",
        payload: {
          events: [...prevState, ...response.data.items],
          eventsNextCursor: response.data.prevCursor,
          id,
        },
      });
      store.dispatch({
        type: "BUNDLED_EVENTS",
        payload: response.data.items,
      });
    } catch (error) {
      notification.notificationUse(error);
    }
  },
  setChosenMailboxId: (mailboxId) => {
    store.dispatch({
      type: "CHOSEN_MAILBOX_ID",
      payload: mailboxId,
    });
  },
  setParticipants: (data) => {
    store.dispatch({
      type: "MAILBOX_PARTICIPANTS",
      payload: data,
    });
  },
  getParticipants: async (id) => {
    try {
      const response = await automationApi.getMailboxParticipants(id);
      store.dispatch({
        type: "MAILBOX_PARTICIPANTS",
        payload: response.data,
      });
    } catch (error) {
      notification.notificationUse(error);
    }
  },
  getAttachment: async (id, url, recommendedAcceptHeader) => {
    try {
      const response = await automationApi.getAttachment(
        id,
        url,
        recommendedAcceptHeader
      );
      const urlBlob = URL.createObjectURL(response.data);
      store.dispatch({
        type: "ATTACHMENT_URLS",
        payload: { id, url: urlBlob },
      });
    } catch (error) {
      notification.notificationUse(error);
    }
  },
};
