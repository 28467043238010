import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import snail from "../../../assets/image/snail-looking-for.png";
// Style
import "./style.scss";

export class FinishCreateTemplate extends Component {
  render() {
    return (
      <div className="finish-create-template" style={{ ...this.props.styles }}>
        {ReactHtmlParser(this.props.header)}
        <img className="prev-img" alt="snail" src={snail} width={150}></img>
        <span className="h-6">{this.props.description}</span>
        <div className="buttons-group">{this.props.children}</div>
      </div>
    );
  }
}

export default FinishCreateTemplate;
