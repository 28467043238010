import React from "react";
import contactsGif from "../../../../assets/image/contacts.gif";
import "./style.scss";
import { CONSTS } from "../../../../config/objectConst";
import subtract from "../../../../assets/image/icons/svg/subtract.svg";

const list = [
  "LinkedIn people search;",
  "LinkedIn group members;",
  "Sales Navigator people search;",
  "Sales Navigator leads list;",
];
const ExportContentSearch = () => {
  return (
    <div className="bottom-container-search">
      <div className="left-container">
        <img src={contactsGif} alt="gif" width={343} />
      </div>
      <div className="right-container">
        <div className="right-container_top">
          <p className="right-container_text">Export profiles from:</p>
          <ul className="list">
            {list.map((item) => (
              <li key={item} className="item">
                {item}
              </li>
            ))}
          </ul>
          <span className="right-container_text">
            Read more about search options{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://snaily.io/support/managing-contacts/"
            >
              here
            </a>
            {" or find relevant profiles right now."}
          </span>
        </div>
        <div className="container-link">
          <a
            href={CONSTS.LINKEDIN_URL}
            className="action-btn"
            target="_blank"
            rel="noopener noreferrer"
          >
            Search on <span className="linkedin-icon-ln">&nbsp;</span>
          </a>
          <a
            href={CONSTS.SALES_NAVIGATOR}
            className="action-btn-accent"
            target="_blank"
            rel="noopener noreferrer"
          >
            Search on{" "}
            <img
              src={subtract}
              className="action-img-subtract"
              alt="linkedin icon"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ExportContentSearch;
