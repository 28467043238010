import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/image/logo.svg";
// style
import "./style.scss";

export class Logo extends Component {
  render() {
    return (
      <Link to="/campaigns">
        <div className="logo">
          <img src={logo} alt="Logo" width={150} height={33} />
        </div>
      </Link>
    );
  }
}

export default Logo;
