/*
 *
 * campaign reducer
 *
 */
const initialState = {
  id: null,
  campaignLoaderController: false,
  createCampaignController: false,
  updateCampaignController: false,
  count: 0,
  list: [],
  statistics: {
    awaiting: 0,
    progress: 0,
    succeed: 0,
    failed: 0,
    skipped: 0,
    cancelled: 0,
  },
  actions: [],
  singleLoaderPage: true,
};

const campaign = (state = initialState, action) => {
  switch (action.type) {
    case "CAMPAIGN":
      return Object.assign({}, state, {
        ...action.payload,
      });

    case "CAMPAIGN_LOADER_CONTROLLER":
      return Object.assign({}, state, {
        campaignLoaderController: action.payload.campaignLoaderController,
      });

    case "CAMPAIGN_CONNECT_COUNT":
      return Object.assign({}, state, {
        count: action.payload.count,
      });

    case "CAMPAIGN_CONNECT_LIST":
      return Object.assign({}, state, {
        list: action.payload.list,
      });

    case "CREATE_CAMPAIGN_CONTROLLER":
      return Object.assign({}, state, {
        createCampaignController: action.payload.createCampaignController,
      });

    case "UPDATE_CAMPAIGN_CONTROLLER":
      return Object.assign({}, state, {
        updateCampaignController: action.payload.updateCampaignController,
      });

    case "CAMPAIGN_SINGLE_LOADER_CONTROLLER":
      return Object.assign({}, state, {
        singleLoaderPage: action.payload.singleLoaderPage,
      });

    default:
      return state;
  }
};

export default campaign;
