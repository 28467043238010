import React from "react";
import { Link } from "react-router-dom";
import snailyVerified from "../../../assets/image/snail-verified.png";
import "./style.scss";

const ModalVerified = () => {
  return (
    <div className="verified-page">
      <div className="verified-page__content">
        <div className="verified-page__text">
          <h1 className="verified-page__text-h1">Congratulations!</h1>
          <h3 className="verified-page__text-h3">You joined the team</h3>
          <img
            alt="snail"
            src={snailyVerified}
            className="verified-page__img"
          />
          <Link to={"/campaigns"} className="verified-page__link">
            Go home!
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ModalVerified;
