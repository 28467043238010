import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import RadioGroupCustom from "../../atoms/RadioGroupCustom";
import _ from "lodash";
// Style
import "./style.scss";
import DropdownSimple from "../../atoms/DropdownSimple";
import defaultImg from "../../../assets/image/icons/png/user.png";

export class FiltersTeam extends Component {
  static propTypes = {
    filtersData: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      filters: [
        {
          key: 1,
          text: "draft",
          value: `'Compose'`,
          color: "#ebedf2",
        },
        {
          key: 2,
          text: "paused",
          value: `'Stopped'`,
          color: "#ffcd50",
        },
        {
          key: 3,
          text: "active",
          value: `'Running'`,
          color: "#04c300",
        },
        {
          key: 4,
          text: "completed",
          value: `'Completed'`,
          color: "#2c82e4",
        },
      ],
      filtersExecutionProgress: [
        { key: 1, text: "All", value: "" },
        {
          key: 2,
          text: "Has contacts in queue",
          value: "in_queue_count gt 0",
        },
        {
          key: 3,
          text: "No contacts in queue",
          value: "in_queue_count eq 0",
        },
      ],
      filtersMembers: [],
    };
  }

  componentDidMount() {
    this.setState({
      filtersMembers: this.props.members
        ?.filter((member) => !member.pending)
        ?.map((el, i) => ({
          key: i,
          text: `${el.nickname} ${
            this.props.personaId === el.id ? "(you)" : ""
          }`,
          text2: `(${el.email})`,
          value: `'${el.id}'`,
          img: el.avatar ? el.avatar : defaultImg,
        })),
    });
  }

  applyFilter = async () => {
    await this.props.setInitialFilters(
      _.cloneDeep(this.props.initialFiltersLocale)
    );
    this.props.getCampaignsTeam();

    this.props.filtersControllerToggle();
  };

  setValue = (id, value) => {
    const filterData = this.props.initialFiltersLocale;
    const element = _.find(filterData, ["id", id]);
    element.value = value;
    const index = filterData.indexOf(element);
    filterData[index] = element;
    this.props.setInitialFiltersLocale(filterData);
  };

  clearValue = (id, option) => {
    const filterData = this.props.initialFiltersLocale;
    const element = _.find(this.props.initialFiltersLocale, ["id", id]);
    const newValue = element.value.filter((item) => option.key !== item.key);
    element.value = newValue;

    const index = filterData.indexOf(element);
    filterData[index] = element;

    this.props.setInitialFiltersLocale(filterData);
  };

  clearAllFilters = () => {
    this.props.setInitialFiltersLocale(this.props.createInitialState());
  };

  getValue = (id) => {
    const element = _.find(this.props.initialFiltersLocale, ["id", id]);
    return element.value;
  };

  render() {
    return (
      <div className="filter-container">
        <div className="filters-grid-team">
          <Grid
            container
            justifycontent="flex-start"
            alignItems="flex-start"
            className="grid"
          >
            <Grid item xs={3}>
              <DropdownSimple
                id={1}
                multiple
                tags
                options={this.state.filters}
                label={"Status"}
                placeholder={"Select Status"}
                onChange={(e, newValue) => this.setValue(1, newValue)}
                onTagClick={this.clearValue}
                value={this.getValue(1)}
              />
            </Grid>
            <Grid item xs={5}>
              <DropdownSimple
                id={2}
                multiple
                tags
                options={this.state.filtersMembers}
                label={"Members"}
                placeholder={"Select Member"}
                onChange={(e, newValue) => this.setValue(2, newValue)}
                onTagClick={this.clearValue}
                value={this.getValue(2)}
                fromTeams
              />
            </Grid>
          </Grid>

          <div className="filters-grid-team">
            <Grid
              container
              justifycontent="flex-start"
              alignItems="flex-start"
              className="grid"
            >
              <Grid item xs={6}>
                <RadioGroupCustom
                  label={"Show Campaigns"}
                  items={this.state.filtersExecutionProgress}
                  value={this.getValue(3)}
                  onChange={(e) => this.setValue(3, e.target.value)}
                />
              </Grid>
            </Grid>
          </div>

          <div className="filter-controls">
            <button
              type="button"
              className="btn-outline alt"
              onClick={() => this.applyFilter()}
            >
              Apply
            </button>
            <button
              type="button"
              className="btn with-border semi-bold"
              onClick={this.clearAllFilters}
            >
              Clear all
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default FiltersTeam;
