import React from "react";
import imagesAdd from "../../../assets/image/icons/buttons/add-svg-white.svg";
import ResponsiveButton from "../../../components/atoms/responsiveButton";
import welcomeSnaily from "../../../assets/image/welcome-snail.jpg";

import "./style.scss";
import { CONSTS } from "../../../config/objectConst.js";

const ModalNotTeam = ({ setIsModalInvitedOpen }) => {
  return (
    <div className="modal-not-team-yet">
      <h2 className="modal-not-team-yet__title">
        Welcome to {CONSTS.PROJECT_NAME} teams!
      </h2>
      <p className="modal-not-team-yet__description">
        Here you can meet your teammates and have a great collaboration.
      </p>
      <ResponsiveButton
        className="btn bold button-member-not-team-yet"
        onClick={() => setIsModalInvitedOpen(true)}
        isLoading={false}
        disabled={false}
      >
        <>
          <img src={imagesAdd} alt="timeIcon" />
          <span>Invite new member</span>
        </>
      </ResponsiveButton>
      <img
        className="modal-not-team-yet__img"
        alt="snail"
        src={welcomeSnaily}
      />
    </div>
  );
};

export default ModalNotTeam;
