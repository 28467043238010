import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ResponsiveButton from "../responsiveButton";
// actions
import { campaign } from "../../../actions/campaign";
import { contacts } from "../../../actions/contacts";
import { app } from "../../../actions/app";
import { filters } from "../../../actions/filters";
import { apiCampaign } from "../../../api/campaign/apiCampaign";
import ModalWindow from "../../molecules/ModalWindow";
import ConfirmationDialogRunning from "../ConfirmationDialogRunning";
import { contactsPageSize } from "../../../api/contactsPageSize";

const ButtonAddToCampaigns = (props) => {
  const { contactsCount, contacts: selectedContacts } = props;
  const [confirmationDialog, setConfirmationDialog] = useState({
    id: null,
    status: false,
    data: {},
  });
  const [needLoader, setNeedLoader] = useState(false);
  const refreshContacts = async () => {
    let pageSize = contactsPageSize.get();
    let newPage =
      props.page * pageSize >= contactsCount - pageSize &&
      props.page * pageSize === contactsCount - selectedContacts.length
        ? props.page - 1
        : props.page;
    newPage = newPage >= 0 ? newPage : 0;
    props.setPage(newPage);

    await props.getContacts({
      skip: newPage * pageSize,
      filter: props.filters.filtersQueryForModal
        ? props.filters.filtersQueryForModal
        : `not (campaigns/any(c:c eq '${props.campaign.id}'))`,
      keywords: props.keywordsForModal ? props.keywordsForModal : "",
    });
    props.clearCheckboxesFunc();

    if (
      props.from === "single-contacts" &&
      (props.campaign.information?.[0].value === "Running" ||
        props.campaign.information?.[0].value === "Stopped" ||
        props.campaign.information?.[0].value === "Completed")
    ) {
      await contacts.getContacts({
        skip: props.contactsPage * pageSize,
        orderby: "created asc",
        status: "Running",
        filter: `campaign_id eq '${props.campaign.id}'`,
      });
    } else if (props.from === "single-contacts") {
      await contacts.getContacts({
        skip: props.contactsPage * pageSize,
        filter: `campaigns/any(c:c eq '${props.campaign.id}')`,
        orderby: "imported asc",
      });
      campaign.getCampaignId(props.campaign.id);
    }
    if (
      props.from === "campaigns" &&
      props.campaign.information?.[0].value === "Compose"
    ) {
      const filtersArray = props.buildFiltersArray(props.campaignFilters);
      app.changePageContentLoaderStatus(true);
      await filters.getCampaigns({
        archived: false,
        filter: `${filtersArray.join(" or ")}`,
        keywords: props.searchValue,
      });
      app.changePageContentLoaderStatus(false);
      props.clearCheckboxesFunc();
    }
    if (props.history.location.state?.launchCampaignDialog === true) {
      campaign.openCampaignContacts(props, false);
    }
  };

  const handlerClick = async () => {
    let preview = "";
    if (
      props.campaign.information?.[0].value === "Running" ||
      props.campaign.information?.[0].value === "Stopped" ||
      props.campaign.information?.[0].value === "Completed"
    ) {
      setNeedLoader(true);
      preview = await apiCampaign.getPreviewAddTargetAudience({
        id: props.campaign.id,
        filter:
          props.filters.filtersQueryForModal && props.selectAllUsersValue
            ? props.filters.filtersQueryForModal
            : props.selectAllUsersValue
            ? `not (campaigns/any(c:c eq '${props.campaign.id}'))`
            : `id in (${props.contacts.map((item) => `'${item}'`)})`,
        keywords: props.keywordsForModal ? props.keywordsForModal : "",
      });
      setNeedLoader(false);
    }
    const campaignId = props.campaign.id;
    const activeImportId = props.match.path.includes("contacts/import/")
      ? this.props.activeImportId
      : "";
    const campaignName = props.campaign.name;
    if (
      (props.campaign.information?.[0].value === "Running" &&
        preview?.data?.validation_rules_summary?.length === 0) ||
      (props.campaign.information?.[0].value === "Stopped" &&
        preview?.data?.validation_rules_summary?.length === 0) ||
      (props.campaign.information?.[0].value === "Completed" &&
        preview?.data?.validation_rules_summary?.length === 0) ||
      (preview?.data?.validation_rules_summary?.length === 0 &&
        preview?.data?.valid_audience_count > 0) ||
      props.campaign.information?.[0].value === "Compose"
    ) {
      if (props.selectAllUsersValue) {
        campaign.addAllContactsToCampaign(
          props.filters,
          campaignId,
          campaignName,
          props.from,
          activeImportId,
          refreshContacts,
          props.keywordsForModal
        );

        return;
      }

      campaign.addContactsToCampaign(
        {
          id: campaignId,
          name: campaignName,
          audience: props.contacts,
          from: props.from,
          running: props.campaign.information?.[0].value !== "Compose",
        },
        refreshContacts
      );
    } else {
      return setConfirmationDialog({
        id: campaignId,
        status: true,
        data: preview.data,
      });
    }
  };
  const addContactsToRunningCampaignModal = () => {
    const campaignId = props.campaign.id;
    const activeImportId = props.match.path.includes("contacts/import/")
      ? this.props.activeImportId
      : "";
    const campaignName = props.campaign.name;
    if (props.selectAllUsersValue) {
      campaign.addAllContactsToCampaign(
        props.filters,
        campaignId,
        campaignName,
        props.from,
        activeImportId,
        refreshContacts,
        props.keywordsForModal
      );

      return;
    }

    campaign.addContactsToCampaign(
      {
        id: campaignId,
        name: campaignName,
        audience: props.contacts,
        from: props.from,
      },
      refreshContacts
    );
  };
  return (
    <>
      <ResponsiveButton
        className={props.classes}
        onClick={() => !props.createCampaignController && handlerClick()}
        isLoading={props.createCampaignController || needLoader}
        disabled={props.disabled}
      >
        {props.text}
      </ResponsiveButton>
      <ModalWindow
        open={confirmationDialog.status}
        closeHandler={() =>
          setConfirmationDialog({ id: null, status: false, data: {} })
        }
        classes={"confirmation-launch-modal"}
      >
        <ConfirmationDialogRunning
          confirmationDialog={confirmationDialog}
          closeHandler={() =>
            setConfirmationDialog({ id: null, status: false, data: {} })
          }
          addContacts={addContactsToRunningCampaignModal}
        />
      </ModalWindow>
    </>
  );
};

const mapStateToProps = function (state) {
  return {
    createCampaignController: state.campaign.createCampaignController,
    campaigns: state.campaigns.campaigns,
    filters: state.filters,
  };
};

export default connect(mapStateToProps)(withRouter(ButtonAddToCampaigns));
