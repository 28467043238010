import React from "react";
import DropZone from "../../../atoms/DropZone";
import ErrorExport from "../ErrorExport";
import _ from "lodash";
import ExportComplete from "../ExportComplete";
import exclaim from "../../../../assets/image/icons/svg/exclaim-red.svg";
import CsvExportUploader from "../../../atoms/CsvExportUploader";
import FoundContacts from "../FoundContacts";
import { exportTabs } from "../constants";
import information from "../../../../assets/image/icons/svg/information.svg";
import DropZoneFull from "../../../atoms/DropZoneFull";
import { CONSTS } from "../../../../config/objectConst";
import InformationalTip from "../../../molecules/InformationalTip";
import "./style.scss";

const ExportContent = ({
  readError,
  showExportImages,
  finishedUploading,
  errorUploadingData,
  linkedInContacts,
  readLoader,
  startUploading,
  startUploadingHandler,
  exportStartingLoader,
  refreshNickNames,
  uploadingProgress,
  stopUploadingHandler,
  showResultHandler,
  closeHandler,
  tab,
  exportCsvExample,
  setFullFile,
  setLinkedInContacts,
  file,
  setFile,
}) => {
  const [isInformationVisible, setIsInformationVisible] = React.useState(false);
  const showProfileURLS = () => {
    let result =
      tab === exportTabs.PASTE_PROFILE_URLS &&
      _.isEmpty(finishedUploading) &&
      !startUploading;
    return result;
  };

  const showUploadFile = () => {
    let result =
      !showExportImages() &&
      _.isEmpty(finishedUploading) &&
      tab === exportTabs.UPLOAD_FILE;
    return result;
  };

  return (
    <>
      {isInformationVisible && (
        <div
          className="backdrop"
          onClick={() => setIsInformationVisible(false)}
        />
      )}
      <div
        className={
          showProfileURLS() || showUploadFile()
            ? "bottom-container"
            : "bottom-container-accent"
        }
      >
        {readError && (
          <div className="csv-export__uploading-error">
            <img src={exclaim} alt="error icon" />
            {readError}
          </div>
        )}

        {showProfileURLS() && (
          <div className="container-text-full">
            <p className="profile-description">
              This export allows to export your own LinkedIn profiles list
              <img
                alt="information"
                src={information}
                width={14}
                className="information"
                onClick={() => setIsInformationVisible(true)}
              />
              {isInformationVisible && (
                <InformationalTip
                  centered={"47px"}
                  arrowPosition={"50px"}
                  onClose={() => setIsInformationVisible(false)}
                  data={CONSTS.TIPS["n4.4"]}
                  maxWidth={"509px"}
                />
              )}
            </p>
          </div>
        )}
        {showUploadFile() && (
          <div className="container-text-full">
            <p className="profile-description">
              This export allows to export customized contacts
              <img
                alt="information"
                src={information}
                width={14}
                className="information"
                onClick={() => setIsInformationVisible(true)}
              />
              {isInformationVisible && (
                <InformationalTip
                  centered={"47px"}
                  arrowPosition={"50px"}
                  onClose={() => setIsInformationVisible(false)}
                  data={CONSTS.TIPS["n4.5"]}
                  maxWidth={"509px"}
                />
              )}
            </p>
            <p
              onClick={() => exportCsvExample()}
              className="text-accent-upload"
            >
              Download CSV example
            </p>
          </div>
        )}

        {_.isEmpty(errorUploadingData) && (
          <>
            {tab === exportTabs.PASTE_PROFILE_URLS &&
              _.isEmpty(finishedUploading) &&
              !startUploading && (
                <DropZone
                  readLoader={readLoader}
                  startUploadingHandler={startUploadingHandler}
                  setLinkedInContacts={setLinkedInContacts}
                  file={file}
                  setFile={setFile}
                />
              )}
            {!linkedInContacts.length && tab === exportTabs.UPLOAD_FILE && (
              <DropZoneFull setFullFile={setFullFile} readLoader={readLoader} />
            )}
            {!!linkedInContacts.length &&
              !startUploading &&
              tab === exportTabs.UPLOAD_FILE &&
              _.isEmpty(finishedUploading) && (
                <FoundContacts
                  contacts={linkedInContacts}
                  startUploadingHandler={startUploadingHandler}
                  exportStartingLoader={exportStartingLoader}
                  refreshContactsHandler={refreshNickNames}
                />
              )}
            {startUploading && _.isEmpty(finishedUploading) && (
              <CsvExportUploader
                value={{
                  current: uploadingProgress,
                  total: linkedInContacts.length,
                }}
                stopUploadingHandler={stopUploadingHandler}
              />
            )}
            {!_.isEmpty(finishedUploading) && (
              <ExportComplete
                finishedUploading={finishedUploading}
                showResultHandler={showResultHandler}
                closeHandler={closeHandler}
              />
            )}
          </>
        )}
        {!_.isEmpty(errorUploadingData) && (
          <ErrorExport
            errorUploadingData={errorUploadingData}
            showResultHandler={showResultHandler}
            closeHandler={closeHandler}
          />
        )}
        {showExportImages()}
      </div>
    </>
  );
};

export default ExportContent;
