import React, { useCallback, useState } from "react";
import "./style.scss";
import Arrow from "../../../assets/image/icons/svg/icons/arrow-down-thin.svg";
import { CONSTS } from "../../../config/objectConst";
import Select, { components } from "react-select";
import { app } from "../../../actions/app";
import { notification } from "../../../actions/notification";
import { user } from "../../../actions/user";
import { useHistory } from "react-router-dom";
import { activity } from "../../../actions/activity";

const SelectTimeZone = ({
  loadedTimeZone,
  setLoadedTimeZone,
  connector,
  opacityValue,
  profile,
  getPeriodStatisticsAsync,
}) => {
  const history = useHistory();
  const [openMenu, setOpenMenu] = useState(false);
  const [value, setValue] = useState(null);
  const getCurrentTimeZone = useCallback(() => {
    if (value) {
      return CONSTS.TIME_ZONES.find((item) => +item.value === value);
    }
    if (!openMenu && profile.impersonatedPersona) {
      return CONSTS.TIME_ZONES.find(
        (item) => +item.value === profile.impersonatedPersona.timezoneOffset
      );
    }
    if (!openMenu && !profile.impersonatedPersona) {
      return CONSTS.TIME_ZONES.find(
        (item) => +item.value === profile.timezoneOffset
      );
    }
  }, [profile]);

  const DropdownIndicator = useCallback((props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img
          src={Arrow}
          alt="arrow"
          className={props.selectProps.menuIsOpen ? "open-icon" : ""}
        />
      </components.DropdownIndicator>
    );
  }, []);

  const handleChangeTimeZone = useCallback(
    async (event) => {
      if (+event.value !== profile.timezoneOffset) {
        setLoadedTimeZone(true);
        try {
          const response = await app.setNewData("workspace/timezone-offset", {
            timezone_offset: +event.value,
          });
          if (response.status === 200) {
            setValue(+event.value);
            await connector.auth.refreshAccessToken();
            user.createUserProfile({
              ...profile,
              timezoneOffset: +event.value,
            });
            getPeriodStatisticsAsync();
            notification.snacknotificationUse({
              open: true,
              title: CONSTS.notification["3.14"].title,
              content: CONSTS.notification["3.14"].content,
              button: CONSTS.notification["3.14"].button,
              style: CONSTS.notification["3.14"].style,
            });
            if (history.location.pathname === "/activities-queue") {
              activity.getActivities();
            }
          }
        } catch (e) {
          user.createUserProfile(profile);
          notification.notificationUse(e);
        } finally {
          setLoadedTimeZone(false);
        }
      }
    },
    [profile]
  );

  const style = {
    control: (base, state) => {
      return {
        ...base,
        "&:hover": {
          borderColor: state.selectProps.menuIsOpen ? "#0cb39f" : "transparent",
        },
        borderColor: state.selectProps.menuIsOpen ? "#0cb39f" : "transparent",
        boxShadow: "",
        fontWeight: 700,
        fontSize: 14,
        background: "transparent",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        opacity: loadedTimeZone ? (opacityValue ? opacityValue : ".3") : "1",
      };
    },
    singleValue: (base) => ({
      ...base,
      color: "#000",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: "0 10px 0 5px",
      height: "32px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "32px",
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      fontSize: 14,
      color: "#000",
      backgroundColor: isSelected ? "#0cb39f" : "transparent",
      "&:active": {
        backgroundColor: "transparent",
      },
    }),
    menuList: (base) => ({
      ...base,
      height: 160,
    }),
    menu: (base) => ({
      ...base,
      border: "1px solid #0cb39f",
      boxShadow: "",
      marginTop: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderTop: 0,
    }),
  };

  const onMenuOpen = () => {
    setOpenMenu(true);
  };

  const onMenuClose = () => {
    setOpenMenu(false);
  };

  return (
    <div className="select-time-zone">
      <div className="select-time-zone__change">
        <Select
          options={CONSTS.TIME_ZONES}
          value={getCurrentTimeZone()}
          styles={style}
          isDisabled={loadedTimeZone}
          onChange={handleChangeTimeZone}
          isSearchable={false}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          components={{ DropdownIndicator, IndicatorSeparator: () => null }}
        />
      </div>
    </div>
  );
};

export default SelectTimeZone;
