import React, { useEffect, useState } from "react";
// components
// icons
import ClockIcon from "../../../assets/image/icons/png/solid-medium/clock.png";
import { CONSTS } from "../../../config/objectConst";
// style
import "./timer.scss";

const TimerComponent = (props) => {
  const [minValue, setMinValue] = useState(0);
  const [hoursValue, setHoursValue] = useState(0);
  const [dayValue, setDayValue] = useState(0);

  useEffect(() => {
    let days = props.time / 60 / 24;
    let hours = (props.time - Math.floor(days) * 24 * 60) / 60;
    let mins = props.time - Math.floor(days) * 24 * 60 - Math.floor(hours) * 60;

    setDayValue(Math.floor(days));
    setHoursValue(Math.floor(hours));
    setMinValue(Math.floor(mins));
  }, [props.time]);

  useEffect(() => {
    const mValue = minValue ? minValue : 0;
    const hValue = hoursValue ? hoursValue : 0;
    const dValue = dayValue ? dayValue : 0;
    let result = mValue + hValue * 60 + dValue * 60 * 24;
    props.timerChanger(props.item, result, props.unique);
  }, [minValue, hoursValue, dayValue]);

  const handleChange = (value, flag) => {
    switch (flag) {
      case "minutes":
        setMinValue(value);
        break;
      case "hours":
        setHoursValue(value);
        break;
      case "days":
        setDayValue(value);
        break;

      default:
        break;
    }
  };

  return (
    <div
      className={
        props.className
          ? `timer-component ${props.className}`
          : "timer-component"
      }
    >
      {props.label && (
        <div className="timer-component__label">
          <img src={ClockIcon} alt="ClockIcon" className="icon-clock" />
          {props.label}
        </div>
      )}

      <div className="timer-component__inputs">
        <InputWithArrows
          flag="days"
          handleChange={handleChange}
          defaultValue={dayValue}
          maxValue={9999}
          minValue={
            props.showController ||
            (props.item.name === CONSTS.AVAILABLE_ACTIONS.Connect.Title &&
              !props.unique)
              ? 0
              : 1
          }
        />

        <InputWithArrows
          flag="hours"
          handleChange={handleChange}
          defaultValue={hoursValue}
          maxValue={23}
          minValue={0}
        />
        <InputWithArrows
          flag="minutes"
          handleChange={handleChange}
          defaultValue={minValue}
          maxValue={59}
          minValue={0}
        />
      </div>
    </div>
  );
};

export default TimerComponent;

export const InputWithArrows = (props) => {
  const [maxValue, setMaxValue] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    setMaxValue(props.maxValue);
  }, [props.maxValue]);

  useEffect(() => {
    setMinValue(props.minValue);
  }, [props.minValue]);

  const handleChange = (e) => {
    const value = e.target.value;
    if (value !== "") {
      if (value >= 0 && value <= maxValue) {
        props.handleChange(parseInt(value), props.flag);
      }
    } else {
      props.handleChange(value, props.flag);
    }
  };

  const onUpValue = () => {
    if (props.defaultValue + 1 <= maxValue) {
      props.handleChange(parseInt(props.defaultValue + 1), props.flag);
    }
  };

  const onDownValue = () => {
    if (props.defaultValue - 1 >= minValue) {
      props.handleChange(parseInt(props.defaultValue - 1), props.flag);
    }
  };

  const onFocusArrows = () => {
    setFocus(true);
  };

  const onBlurArrows = () => {
    setFocus(false);
  };

  const handleBlur = (e) => {
    const value = e.target.value;
    if (!value || value === "0") {
      props.handleChange(props.minValue, props.flag);
    }
  };

  return (
    <div className="timer-component-input">
      {props.flag && (
        <div className="timer-component-input__label">
          <span className="text-field-label">{props.flag}</span>
        </div>
      )}
      <div
        className={
          focus
            ? "timer-component-input__container timer-component-input__container--focus"
            : "timer-component-input__container"
        }
        onMouseOver={onFocusArrows}
        onMouseOut={onBlurArrows}
      >
        <input
          defaultValue={props.loadValue}
          value={props.defaultValue}
          onChange={handleChange}
          onBlur={handleBlur}
          type="number"
          pattern="[0-9]"
          min={0}
          max={9999}
        />
        <div className="input-arrows">
          <div className="input-arrows__container">
            <div className="input-arrows__up" onClick={onUpValue}></div>
            <div className="input-arrows__down" onClick={onDownValue}></div>
          </div>
        </div>
      </div>
    </div>
  );
};
