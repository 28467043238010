import React, { Component } from "react";
import "./style.scss";

export class Notification extends Component {
  render() {
    return (
      <div className={this.props.error ? `notification error` : `notification`}>
        {this.props.text}
      </div>
    );
  }
}

export default Notification;
