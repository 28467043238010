import React, { Component } from "react";
import { referrerDetector } from "../../api/referrerDetector";
import queryString from "query-string";
import { CircularProgress } from "@material-ui/core";

export class ReferrerPage extends Component {
  constructor(props) {
    super(props);
    let redirectUrl = queryString.parse(
      this.props.history.location.search
    ).redirect;
    referrerDetector.capture(this.props.match.params.id, redirectUrl);
  }

  render() {
    return (
      <div className="auth-call-back-preloader">
        <div className="auth-call-back-preloader-body">
          {
            <>
              <div className="table-material-loader-wrapper">
                <CircularProgress
                  color="primary"
                  size={60}
                  classes={{ colorPrimary: "material-loader" }}
                />
              </div>
            </>
          }
        </div>
      </div>
    );
  }
}
