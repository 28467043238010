import store from "../redux/store";
import _ from "lodash";

import { apiCampaign } from "../api/campaign/apiCampaign";
import { notification } from "./notification";
import { mapping } from "./mapping";
import { app } from "./app";
import { contacts } from "./contacts";
import { api } from "../api/api";
import { filters } from "./filters";
import { helpersFunc } from "../config/helpers";
// icons
import pageIcon from "../assets/image/icons/left-side-menu/page.svg";
import archiveIcon from "../assets/image/icons/svg/archive-icon-yellow.svg";
import turnIcon from "../assets/image/icons/svg/turn.svg";
import { CONSTS } from "../config/objectConst";
import { listeners } from "../api/listeners";

async function refreshAccessToken() {
  const connector = listeners.getConnector();
  await connector.auth.refreshAccessToken();
}

export const campaign = {
  loadCampaignList(data, type) {
    let response = {
      type: type ? type : "campaign",
      label: "Recently created",
      bottomList: [
        {
          id: 2,
          image: turnIcon,
          title: "Activities queue",
          link: "/activities-queue",
        },
        {
          id: 1,
          image: pageIcon,
          title: "All campaigns list",
          link: "/campaigns",
        },
        {
          id: 3,
          image: archiveIcon,
          title: "Archive",
          link: "/archive",
        },
      ],
      list: data,
    };
    store.dispatch({
      type: "LEFT_SIDE_MENU_LIST",
      payload: {
        leftSide: response,
      },
    });
  },

  changeCampaignLoaderController(value) {
    store.dispatch({
      type: "CAMPAIGN_LOADER_CONTROLLER",
      payload: {
        campaignLoaderController: value,
      },
    });
  },

  changeCreateCampaignController(value) {
    store.dispatch({
      type: "CREATE_CAMPAIGN_CONTROLLER",
      payload: {
        createCampaignController: value,
      },
    });
  },

  changeUpdateCampaignController(value) {
    store.dispatch({
      type: "UPDATE_CAMPAIGN_CONTROLLER",
      payload: {
        updateCampaignController: value,
      },
    });
  },

  changeCampaignsLoadController(value) {
    store.dispatch({
      type: "LOAD_CAMPAIGNS_CONTROLLER",
      payload: {
        loadCampaignsController: value,
      },
    });
  },

  changeCampaignUpdateStep(value) {
    store.dispatch({
      type: "CAMPAIGN_CREATE_FINISH_STEP",
      payload: {
        finishStep: value,
      },
    });
  },

  changeCampaginSingleLoaderPage(value) {
    store.dispatch({
      type: "CAMPAIGN_SINGLE_LOADER_CONTROLLER",
      payload: {
        singleLoaderPage: value,
      },
    });
  },

  getRecentCampaigns: async (type) => {
    store.getState().leftSideMenu.leftSide.type !== "campaign" &&
      campaign.loadCampaignList([]);
    try {
      const response = await api.getCampaignsApi({ take: 5 });
      if (response.status === 204) {
        campaign.loadCampaignList([]);
      }
      if (response.status === 200) {
        campaign.loadCampaignList(response.data.documents, type);
      }
    } catch (error) {
      notification.notificationUse(error);
      campaign.loadCampaignList([]);
    }
  },

  campaignClearId() {
    store.dispatch({
      type: "CAMPAIGN",
      payload: {
        ...{ id: null, statistics: {}, actions: [] },
      },
    });
  },

  async getCampaignId(id, rerender = true) {
    rerender && campaign.changeCampaginSingleLoaderPage(true);
    rerender && campaign.campaignClearId();
    let response;
    try {
      response = await apiCampaign.getCampaignById(id);
      if (response.status === 204) {
        return null;
      }
      //await campaign.getContactsByCampaign(id);
      app.changeActiveItem(id);
      const mappedObject = mapping.buildCampaignsArray([
        { ...response.data },
      ])[0];

      if (rerender) {
        store.dispatch({
          type: "CAMPAIGN",
          payload: {
            ...mappedObject,
          },
        });
      } else {
        store.dispatch({
          type: "CAMPAIGN",
          payload: {
            ...mappedObject,
            actions: store.getState().campaign.actions,
          },
        });
      }
    } finally {
      rerender && app.changePageContentLoaderStatus(false);
      rerender && campaign.changeCampaginSingleLoaderPage(false);
    }
    return response;
  },

  getContactsByCampaign: async (id) => {
    app.changePageContentLoaderStatus(true);
    app.changeTableContentLoaderStatus(true);
    try {
      const contactsRes = await api.getContactsApi({
        filter: `campaigns/any(c:c eq '${id}')`,
      });
      const contactsExecutionsRes = await api.getContactsExecutionsApi({
        filter: `campaign_id eq '${id}'`,
      });
      let executions = [];
      if (contactsExecutionsRes.status === 200) {
        executions = contactsExecutionsRes.data.documents;
      }
      if (contactsRes.data) {
        store.dispatch({
          type: "CAMPAIGN_CONNECT_LIST",
          payload: {
            list: mapping.buildCampaignContacts(
              contactsRes.data.documents,
              executions
            ),
          },
        });
      } else {
        store.dispatch({
          type: "CAMPAIGN_CONNECT_LIST",
          payload: {
            list: [],
          },
        });
      }
    } catch (error) {
      notification.notificationUse(error);
      store.dispatch({
        type: "CAMPAIGN_CONNECT_LIST",
        payload: {
          list: [],
        },
      });
    } finally {
      app.changePageContentLoaderStatus(false);
      app.changeTableContentLoaderStatus(false);
    }
  },

  async createCampaign(name, history) {
    campaign.changeCreateCampaignController(true);
    try {
      let response = await apiCampaign.createCampaign(name);

      campaign.getRecentCampaigns();

      notification.snacknotificationUse({
        open: true,
        title: CONSTS.notification["3.1"].title,
        content: CONSTS.notification["3.1"].content,
        button: CONSTS.notification["3.1"].button,
        style: CONSTS.notification["3.1"].style,
      });

      history.push(`/campaigns/${response.data.id}/tune`);
      app.changeActiveItem(response.data.id);
      //app.changeActiveItem(response.data.id);
    } catch (e) {
      notification.notificationUse(e);
    } finally {
      campaign.changeCreateCampaignController(false);
    }
  },

  async createCampaignWithContacts(data) {
    const {
      name,
      history,
      audience,
      selectAllUsersValue,
      filters,
      campId,
      from,
      activeImportId,
      keywords,
    } = { ...data };
    campaign.changeCreateCampaignController(true);
    try {
      let response = await apiCampaign.createCampaign(name);

      if (selectAllUsersValue) {
        await campaign.addAllContactsToCampaign(
          filters,
          response.data.id,
          name,
          from,
          activeImportId,
          campId,
          keywords
        );
      } else {
        await apiCampaign.addTargetAudience({
          id: response.data.id,
          audience: audience,
          params: `id in (${audience.map((item) => `'${item}'`)})`,
        });
      }

      campaign.getRecentCampaigns();

      notification.snacknotificationUse({
        open: true,
        title: CONSTS.notification["3.1"].title,
        content: CONSTS.notification["3.1"].content,
        button: CONSTS.notification["3.1"].button,
        style: CONSTS.notification["3.1"].style,
      });

      history.push(`/campaigns/${response.data.id}/tune`);
      app.changeActiveItem(response.data.id);
    } catch (e) {
      notification.notificationUse(e);
    } finally {
      campaign.changeCreateCampaignController(false);
    }
  },

  async updateCampaign(data) {
    campaign.changeUpdateCampaignController(true);
    try {
      let response = await apiCampaign.updateCampaign(data);
      campaign.getRecentCampaigns();
      return response;
    } catch (e) {
      notification.notificationUse(e);
    } finally {
      campaign.changeUpdateCampaignController(false);
    }
  },

  async getCampaignsParams({ updateLeftMenu }) {
    campaign.changeCampaignsLoadController(true);
    try {
      let response = await apiCampaign.getCampaigns();
      campaign.changeCampaignsLoadController(false);
      store.dispatch({
        type: "CAMPAIGNS_ARRAY",
        payload: {
          campaigns: mapping.buildCampaignsArray(response.data),
        },
      });
      if (response.data.length > 0) {
        campaign.haveCampaigns(true);
      }
      if (updateLeftMenu) {
        campaign.loadCampaignList(response.data);
      }
    } catch (e) {
      campaign.haveCampaigns(false);
      campaign.changeCampaignsLoadController(false);
      helpersFunc.logEnjectServicePromise(e);
      if (updateLeftMenu) {
        campaign.loadCampaignList([]);
      }
      //notification.notificationUse(5000, "Server Error", true);
    }
  },

  haveCampaigns: (status) => {
    store.dispatch({
      type: "HAVE_CAMPAIGNS",
      payload: {
        haveCampaigns: status,
      },
    });
  },

  searchValue: (value) => {
    store.dispatch({
      type: "SEARCH_VALUE",
      payload: {
        searchValue: value,
      },
    });
  },

  searchArchiveValue: (value) => {
    store.dispatch({
      type: "SEARCH_ARCHIVE_VALUE",
      payload: {
        searchArchiveValue: value,
      },
    });
  },

  addAllContactsToCampaign: async (
    data,
    importId,
    campaignName,
    fromKey,
    activeImportId,
    refreshContacts,
    keywordsForModal
  ) => {
    const filterData =
      fromKey === "single-contacts" ? data.filterDataForModal : data.filterData;
    const mainFilter = filters.buildFilterQuery(filterData);

    let filterQuery = mainFilter ?? "";
    if (activeImportId) {
      const importFilter = `tags/any(c:c eq '${activeImportId}')`;
      filterQuery = mainFilter
        ? `${importFilter} and ${mainFilter}`
        : importFilter;
    }

    const params = keywordsForModal
      ? `${filterQuery}&$searchQuery=${keywordsForModal}`
      : filterQuery;

    campaign.changeCreateCampaignController(true);

    try {
      await apiCampaign.addTargetAudience({ id: importId, params });

      const noteId = fromKey === "contacts" ? "3.4a" : "3.4b";
      notification.snacknotificationUse({
        open: true,
        title: CONSTS.notification[noteId].title,
        content: CONSTS.notification[noteId].content,
        style: CONSTS.notification[noteId].style,
        button:
          fromKey === "contacts"
            ? {
                title: CONSTS.notification["3.4a"].button.title,
                link: CONSTS.notification["3.4a"].button.link.replace(
                  "{var}",
                  importId
                ),
              }
            : false,
      });
      if (refreshContacts && fromKey === "single-contacts") {
        refreshContacts();
      }
    } catch (e) {
      notification.notificationUse(e);
    } finally {
      campaign.changeCreateCampaignController(false);
    }
  },

  removeAllContactsFromCampaign: async (data) => {
    app.changePageContentLoaderStatus(true);
    app.changeTableContentLoaderStatus(true);
    try {
      await apiCampaign.removeContactsFromCampaignApi(data);
      contacts.clearContacts();

      notification.snacknotificationUse({
        open: true,
        title: CONSTS.notification["3.5b"].title,
        content: CONSTS.notification["3.5b"].content,
        button: CONSTS.notification["3.5b"].button,
        style: CONSTS.notification["3.5b"].style,
      });
    } catch (e) {
      notification.notificationUse(e);
    } finally {
      app.changePageContentLoaderStatus(false);
      app.changeTableContentLoaderStatus(false);
    }
  },

  addContactsToCampaign: async (data, refreshContacts) => {
    campaign.changeCreateCampaignController(true);
    try {
      const response = await apiCampaign.addTargetAudience({
        id: data.id,
        audience: data.audience,
        params: `id in (${data.audience.map((item) => `'${item}'`)})`,
      });

      store.dispatch({
        type: "CAMPAIGN",
        payload: {
          ...mapping.buildCampaignsArray([{ ...response.data }])[0],
        },
      });

      const noteId = data.from === "contacts" ? "3.4a" : "3.4b";
      const dataButton =
        data.from === "contacts"
          ? {
              title: CONSTS.notification["3.4a"].button.title,
              link: CONSTS.notification["3.4a"].button.link.replace(
                "{var}",
                data.id
              ),
            }
          : false;
      notification.snacknotificationUse({
        open: true,
        title: CONSTS.notification[data.running ? "3.18" : noteId].title,
        content: CONSTS.notification[data.running ? "3.18" : noteId].content,
        button: data.running ? CONSTS.notification["3.18"].button : dataButton,
        style: CONSTS.notification["3.18"].style,
      });

      if (refreshContacts) {
        refreshContacts();
      }
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      campaign.changeCreateCampaignController(false);
    }
  },

  removeContactsFromCampaign: async (data) => {
    app.changePageContentLoaderStatus(true);
    app.changeTableContentLoaderStatus(true);
    try {
      const response = await apiCampaign.removeContactsFromCampaignApi(data);
      store.dispatch({
        type: "CAMPAIGN",
        payload: {
          ...mapping.buildCampaignsArray([{ ...response.data }])[0],
        },
      });
      const field =
        data.audience.length > 1
          ? CONSTS.notification["3.5b"]
          : CONSTS.notification["3.5a"];

      notification.snacknotificationUse({
        open: true,
        title: field.title,
        content: field.content,
        button: field.button,
        style: field.style,
      });
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      app.changePageContentLoaderStatus(false);
      app.changeTableContentLoaderStatus(false);
    }
  },

  startCampaign: async (id, status, getCampaigns) => {
    app.changeTableContentLoaderStatus(true);
    try {
      await api.startCampaignApi(id);
      const field = status !== "Stopped" ? "3.6" : "3.9";
      notification.snacknotificationUse({
        open: true,
        title: CONSTS.notification[field].title,
        content: CONSTS.notification[field].content,
        button: CONSTS.notification[field].button,
        style: CONSTS.notification[field].style,
      });

      if (getCampaigns) {
        await filters.getCampaigns({
          skip:
            getCampaigns.page > 0 ? getCampaigns.page * CONSTS.PAGE_SIZE : 0,
          archived: false,
          filter: `${getCampaigns.filtersArray.join(" or ")}`,
          keywords: getCampaigns.searchValue,
          orderby: getCampaigns.orderby,
        });
      } else {
        let response = await apiCampaign.getCampaignById(id);
        const mappedObject = mapping.buildCampaignsArray([
          { ...response.data },
        ])[0];

        const newCampaigns = store
          .getState()
          .campaigns.campaigns.map((item) => {
            if (item.id === id) {
              item = mappedObject;
            }
            return item;
          });

        store.dispatch({
          type: "CAMPAIGNS_ARRAY",
          payload: {
            campaigns: newCampaigns,
          },
        });
      }

      await refreshAccessToken();
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      app.changeTableContentLoaderStatus(false);
    }
  },

  stopCampaign: async (id, getCampaigns) => {
    app.changeTableContentLoaderStatus(true);
    try {
      await api.stopCampaignApi(id);

      if (getCampaigns) {
        await filters.getCampaigns({
          skip:
            getCampaigns.page > 0 ? getCampaigns.page * CONSTS.PAGE_SIZE : 0,
          archived: false,
          filter: `${getCampaigns.filtersArray.join(" or ")}`,
          keywords: getCampaigns.searchValue,
          orderby: getCampaigns.orderby,
        });
      } else {
        let response = await apiCampaign.getCampaignById(id);
        const mappedObject = mapping.buildCampaignsArray([
          { ...response.data },
        ])[0];

        const newCampaigns = store
          .getState()
          .campaigns.campaigns.map((item) => {
            if (item.id === id) {
              item = mappedObject;
            }
            return item;
          });

        store.dispatch({
          type: "CAMPAIGNS_ARRAY",
          payload: {
            campaigns: newCampaigns,
          },
        });
      }

      await refreshAccessToken();

      notification.snacknotificationUse({
        open: true,
        title: CONSTS.notification["3.7"].title,
        content: CONSTS.notification["3.7"].content,
        button: CONSTS.notification["3.7"].button,
        style: CONSTS.notification["3.7"].style,
      });
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      app.changeTableContentLoaderStatus(false);
    }
  },

  completeCampaign: async (id, getCampaigns) => {
    app.changeTableContentLoaderStatus(true);
    try {
      await api.completeCampaignApi(id);

      if (getCampaigns) {
        await filters.getCampaigns({
          skip:
            getCampaigns.page > 0 ? getCampaigns.page * CONSTS.PAGE_SIZE : 0,
          archived: false,
          filter: `${getCampaigns.filtersArray.join(" or ")}`,
          keywords: getCampaigns.searchValue,
          orderby: getCampaigns.orderby,
        });
      } else {
        let response = await apiCampaign.getCampaignById(id);

        const mappedObject = mapping.buildCampaignsArray([
          { ...response.data },
        ])[0];

        const newCampaigns = store
          .getState()
          .campaigns.campaigns.map((item) => {
            if (item.id === id) {
              item = mappedObject;
            }
            return item;
          });

        store.dispatch({
          type: "CAMPAIGNS_ARRAY",
          payload: {
            campaigns: newCampaigns,
          },
        });
      }

      await refreshAccessToken();

      notification.snacknotificationUse({
        open: true,
        title: CONSTS.notification["3.8"].title,
        content: CONSTS.notification["3.8"].content,
        button: CONSTS.notification["3.8"].button,
        style: CONSTS.notification["3.8"].style,
      });
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      app.changeTableContentLoaderStatus(false);
    }
  },

  cloneCampaign: async (id) => {
    app.changeTableContentLoaderStatus(true);
    try {
      let response = await api.cloneCampaignApi(id);
      notification.snacknotificationUse({
        open: true,
        title: CONSTS.notification["3.21"].title,
        content: CONSTS.notification["3.21"].content,
        style: CONSTS.notification["3.21"].style,
      });
      return response.data;
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      app.changeTableContentLoaderStatus(false);
    }
  },

  getCampaignExecutionsStatistics: async (id) => {
    try {
      const response = await api.getCampaignExecutionsStatisticsApi(id);
      store.dispatch({
        type: "CAMPAIGN",
        payload: {
          executionsStatistics: mapping.buildCampaignsStatistics(response.data),
          actions: campaign.buildActionsStatistics(
            _.filter(response.data.steps, (el) => el.is_visible === true)
          ),
        },
      });
    } catch (error) {
      helpersFunc.logEnjectServicePromise(error);
    }
  },

  buildActionsStatistics: (array) => {
    const actions = [...store.getState().campaign.actions];
    const newActions = [];
    array.forEach((item, indexOne) => {
      actions.forEach((element, indexTwo) => {
        if (indexOne === indexTwo) {
          newActions.push({
            ...element,
            ...mapping.buildActionStatistics(item),
          });
        }
      });
    });
    return newActions;
  },

  editCampaign: async (data) => {
    const { name, id } = { ...data };
    try {
      const response = await apiCampaign.editCampaignApi(data);
      const newList = store
        .getState()
        .leftSideMenu.leftSide.list.map((item) => {
          if (item.id === id) {
            item.name = name;
          }
          return item;
        });
      store.dispatch({
        type: "CAMPAIGN",
        payload: {
          name: response.data.name,
        },
      });
      store.dispatch({
        type: "LEFT_SIDE_MENU_LIST",
        payload: {
          leftSide: {
            ...store.getState().leftSideMenu.leftSide,
            list: newList,
          },
        },
      });
    } catch (error) {
      notification.notificationUse(error);
    }
  },

  addCampaignToArchive: async (id, filtersArray, keywords, page, orderby) => {
    try {
      await apiCampaign.addCampaignToArchiveApi(id);
      await filters.getCampaigns({
        archived: false,
        skip: page > 0 ? page * CONSTS.PAGE_SIZE : 0,
        filter: `${filtersArray.join(" or ")}`,
        keywords: keywords,
        orderby: orderby,
      });

      notification.snacknotificationUse({
        open: true,
        title: CONSTS.notification["3.10"].title,
        content: CONSTS.notification["3.10"].content,
        button: CONSTS.notification["3.10"].button,
        style: CONSTS.notification["3.10"].style,
      });
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      app.changeTableContentLoaderStatus(false);
    }
  },

  removeCampaignFromArchive: async (id, keywords, page, orderby) => {
    try {
      await apiCampaign.removeCampaignFromArchiveApi(id);
      await filters.getCampaigns({
        archived: true,
        skip: page > 0 ? page * CONSTS.PAGE_SIZE : 0,
        keywords: keywords,
        orderby: orderby,
      });
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      app.changeTableContentLoaderStatus(false);
    }
  },

  changeFilterStatus: async (object) => {
    store.dispatch({
      type: "CHANGE_FILTER_STATUS",
      payload: {
        filters: object,
      },
    });
  },

  openCampaignContacts: (props, launchCampaignDialog) => {
    let result = props.history.push({
      pathname: `/campaigns/${props.campaign.id}/contacts`,
      state: { launchCampaignDialog: launchCampaignDialog },
    });
    return result;
  },
};
