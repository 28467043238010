import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { RadioGroup, Radio, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

RadioGroupCustom.propTypes = {
  items: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

function RadioGroupCustom(props) {
  const label = labelStyles();
  const radio = radioStyles();
  const uniqueId = Math.floor(Math.random() * 1000);

  return (
    <div className="radio-group">
      {props.label && (
        <label
          htmlFor={`radioGroup-${uniqueId}`}
          className="select-label text-field__label"
        >
          {props.label}
        </label>
      )}
      <RadioGroup
        row
        id={`radioGroup-${uniqueId}`}
        name="radio-group-custom"
        style={{ width: "500px" }}
        value={props.value}
        onChange={props.onChange}
      >
        {props.items.map((item) => {
          return (
            <FormControlLabel
              key={item.key}
              value={item.value}
              control={
                <Radio
                  disableRipple
                  className={clsx(radio.root, radio.checked)}
                  checkedIcon={
                    <div className={clsx(radio.icon, radio.checkedIcon)}>
                      <div className={radio.checkedIconDot}></div>
                    </div>
                  }
                  icon={<div className={radio.icon} />}
                />
              }
              label={item.text}
              classes={label}
            />
          );
        })}
      </RadioGroup>
    </div>
  );
}

const labelStyles = makeStyles((theme) => ({
  label: {
    fontSize: "14px !important",
    lineHeight: "20px !important",
    color: "#202020 !important",
  },
}));

const radioStyles = makeStyles((theme) => ({
  root: {
    color: "#0cb39f !important",
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
  checked: {
    color: "#0cb39f !important",
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    backgroundColor: "transparent",
    border: "1px solid #EBEDF2",
  },
  checkedIconDot: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "#016069",
  },
  checkedIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    backgroundImage: "none",
  },
}));

export default RadioGroupCustom;
