import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Modal, Loader } from "semantic-ui-react";
// Actions
import { campaign } from "../../../actions/campaign";
import { contacts } from "../../../actions/contacts";
// Images, Icons
import closeIcon from "../../../assets/image/icons/svg/line/Close.svg";
import { app } from "../../../actions/app";
import DropdownSimple from "../DropdownSimple";
import { filters } from "../../../actions/filters";
import ResponsiveButton from "../../atoms/responsiveButton";
import { apiCampaign } from "../../../api/campaign/apiCampaign";
import ModalWindow from "../../molecules/ModalWindow";
import ConfirmationDialogRunning from "../ConfirmationDialogRunning";
import { CONSTS } from "../../../config/objectConst";
import imagesAdd from "../../../assets/image/icons/buttons/add.svg";
import imagesAddDisable from "../../../assets/image/icons/buttons/add-disabled.svg";
import imagesCreate from "../../../assets/image/icons/buttons/imagesCreate.svg";
import imagesCreateDisable from "../../../assets/image/icons/buttons/imagesCreateDisable.svg";

export class ButtonCreateCampaigns extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCloseModal: false,
      modalController: false,
      companyId: "",
      campaignName: "",
      error: false,
      needLoader: false,
      searchedCampaign: null,
      confirmationDialog: {
        id: null,
        status: false,
        data: {},
      },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.state.confirmationDialog.status !==
        prevState.confirmationDialog.status &&
        this.state.confirmationDialog.status === false) ||
      (this.state.isCloseModal !== prevState.isCloseModal &&
        this.state.isCloseModal === true)
    ) {
      this.handlerModal();
      this.setState({ isCloseModal: false, searchedCampaign: null });
      this.props.clearCheckboxesFunc();
    }
  }

  handlerModal = () => {
    if (this.props.from === "contacts" && this.props.type === "new") {
      localStorage.setItem("2.5", "true");
    }
    this.setState({
      modalController: !this.state.modalController,
    });
  };

  handlerChange = (e) => {
    if (e.target.value.length <= CONSTS.CAMPAIGN_MAX_LENGTH) {
      this.setState({ companyId: e.target.value });
      this.setState({
        error: false,
      });
    } else {
      this.setState({
        error: true,
      });
    }
  };

  handlerCompany = async (e) => {
    let preview = "";
    const activeImportId = this.props.match.path.includes("contacts/import/")
      ? this.props.activeImportId
      : "";
    if (
      (this.props.type === "add" &&
        this.props.campaigns.find(
          (campaign) => campaign.id === this.state.companyId
        ).information?.[0].value === "Running") ||
      (this.props.type === "add" &&
        this.props.campaigns.find(
          (campaign) => campaign.id === this.state.companyId
        ).information?.[0].value === "Stopped") ||
      (this.props.type === "add" &&
        this.props.campaigns.find(
          (campaign) => campaign.id === this.state.companyId
        ).information?.[0].value === "Completed")
    ) {
      this.setState({
        needLoader: true,
      });

      let filerQuery =
        activeImportId && !this.props.filters.filtersQuery
          ? `tags/any(c:c eq '${activeImportId}')`
          : this.props.filters.filtersQuery;

      preview = await apiCampaign.getPreviewAddTargetAudience({
        id: this.state.companyId,
        filter:
          filerQuery && this.props.selectAllUsersValue
            ? filerQuery
            : this.props.selectAllUsersValue
            ? ``
            : `id in (${this?.props?.contacts?.map((item) => `'${item}'`)})`,
        keywords: this.props.keywords ? this.props.keywords : "",
      });
      this.setState({
        needLoader: false,
      });
    }

    if (this.props.contacts) {
      if (this.props.type === "add") {
        if (
          (this.props.campaigns.find(
            (campaign) => campaign.id === this.state.companyId
          ).information?.[0].value === "Running" &&
            preview.data.validation_rules_summary.length === 0) ||
          (this.props.campaigns.find(
            (campaign) => campaign.id === this.state.companyId
          ).information?.[0].value === "Stopped" &&
            preview.data.validation_rules_summary.length === 0) ||
          (this.props.campaigns.find(
            (campaign) => campaign.id === this.state.companyId
          ).information?.[0].value === "Completed" &&
            preview.data.validation_rules_summary.length === 0) ||
          this.props.campaigns.find(
            (campaign) => campaign.id === this.state.companyId
          ).information?.[0].value === "Compose" ||
          (preview.data.validation_rules_summary.length === 0 &&
            preview.data.valid_audience_count > 0)
        ) {
          if (this.props.selectAllUsersValue) {
            await campaign.addAllContactsToCampaign(
              this.props.filters,
              this.state.companyId,
              this.state.campaignName,
              this.props.from,
              activeImportId,
              this.props.refreshContacts,
              this.props.keywords,
              () => {
                app.changeTableContentLoaderStatus(true);
                this.props.clearCheckboxesFunc();
              }
            );
          } else {
            await campaign.addContactsToCampaign({
              id: this.state.companyId,
              name: this.state.campaignName,
              audience: this.props.contacts,
              from: this.props.from,
            });
          }
        } else {
          return this.setState({
            confirmationDialog: {
              id: this.state.companyId,
              status: true,
              data: preview.data,
            },
          });
        }
      }
      if (this.props.type === "new") {
        await campaign.createCampaignWithContacts({
          name: this.state.companyId,
          audience: this.props.contacts,
          history: this.props.history,
          selectAllUsersValue: this.props.selectAllUsersValue,
          filters: this.props.filters,
          companyId: this.state.companyId,
          from: this.props.from,
          activeImportId,
          keywords: this.props.keywords,
        });
      }
      this.props.clearCheckboxesFunc();
    } else {
      if (this.props.type === "new") {
        await campaign.createCampaign(this.state.companyId, this.props.history);
      }
    }
    this.setState({
      companyId: "",
      modalController: !this.state.modalController,
      searchedCampaign: null,
    });
  };

  mapCampaigns = (array) => {
    let newArray = [];
    array.map((item) => {
      newArray.push({
        key: item.id,
        text: item.name,
        value: item.id,
        color: item.information?.[0].color,
      });
    });
    return newArray;
  };

  getCampaigns = async (data) => {
    await filters.getCampaigns({
      archived: false,
      filter: "",
      take: 10,
      ...data,
    });
  };

  autocompleteHandler = (e, newValue) => {
    this.setState({
      searchedCampaign: newValue ?? null,
      companyId: newValue?.value ?? "",
      campaignName: newValue?.name ?? "",
    });
  };

  addContactsToRunningCampaign = async () => {
    const activeImportId = this.props.match.path.includes("contacts/import/")
      ? this.props.activeImportId
      : "";
    if (this.props.selectAllUsersValue) {
      await campaign.addAllContactsToCampaign(
        this.props.filters,
        this.state.companyId,
        this.state.campaignName,
        this.props.from,
        activeImportId,
        this.props.refreshContacts,
        this.props.keywords,
        () => {
          app.changeTableContentLoaderStatus(true);
          this.props.clearCheckboxesFunc();
        }
      );
    } else {
      await campaign.addContactsToCampaign({
        id: this.state.companyId,
        name: this.state.campaignName,
        audience: this.props.contacts,
        from: this.props.from,
      });
    }
    this.props.clearCheckboxesFunc();
    this.setState({
      companyId: "",
      modalController: !this.state.modalController,
    });
  };
  modalBody = () => {
    let text = "";
    if (!this.props.createCampaignController && this.props.type === "add") {
      text = "Confirm";
    }
    if (!this.props.createCampaignController && this.props.type === "new") {
      text = "Create";
    }
    return (
      <div className={`modal-create-campaign ${this.props.type}`}>
        <div className="modal-create-campaign-header">
          <img
            src={closeIcon}
            alt="close"
            className="close"
            onClick={this.handlerModal}
          />
        </div>
        <div className="modal-create-campaign-body">
          {this.props.type === "new" && (
            <>
              <h2>Name your campaign</h2>
              <span className="h-5 regular modal-create-campaign__text">
                to set up the sequence of actions{" "}
                {this.props.contacts
                  ? `for ${
                      this.props.selectAllUsersValue
                        ? this.props.contactsCount
                        : this.props.contacts.length
                    } contacts`
                  : ""}
              </span>
            </>
          )}
          {this.props.type === "add" && (
            <>
              <h2>Add contacts to campaign</h2>
              <span className="h-5 regular modal-create-campaign__text">
                Select the campaign to add{" "}
                {this.props.selectAllUsersValue
                  ? this.props.contactsCount
                  : this.props.contacts.length}{" "}
                contacts
              </span>
            </>
          )}
          <div className="select-container">
            {this.props.type === "new" && (
              <input
                className="modal-create-campaign__name-input"
                value={this.state.companyId}
                onChange={this.handlerChange}
                autoFocus
                onKeyDown={(e) => {
                  if (e.key === "Enter" && this.state.companyId) {
                    this.handlerCompany();
                  }
                }}
              />
            )}
            {this.props.type === "add" && (
              <DropdownSimple
                id={12311}
                getOptions={this.getCampaigns}
                options={this.mapCampaigns(this.props.campaigns)}
                placeholder="Select the campaign"
                onChange={this.autocompleteHandler}
                value={this.state.searchedCampaign}
                dataForAuto={{}}
              />
            )}
            <ResponsiveButton
              className="btn bold modal-create-campaign-button"
              onClick={this.handlerCompany}
              isLoading={
                this.props.createCampaignController || this.state.needLoader
              }
              disabled={!this.state.companyId}
            >
              {text}
            </ResponsiveButton>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        <Modal
          open={this.state.modalController}
          onClose={this.handlerModal}
          className={this.props.type}
        >
          {this.modalBody()}
        </Modal>
        <button
          className={this.props.classes}
          onClick={this.handlerModal}
          disabled={this.props.disabled ? this.props.disabled : false}
        >
          {this.props.text === "Add to campaign" && (
            <img
              alt="export CSV"
              src={this.props.disabled ? imagesAddDisable : imagesAdd}
              className="export__container_img"
            />
          )}
          {this.props.text === "Create campaign" && (
            <img
              alt="export CSV"
              src={this.props.disabled ? imagesCreateDisable : imagesCreate}
              className="export__container_img"
            />
          )}
          {this.props.text}
        </button>

        <ModalWindow
          open={this.state.confirmationDialog.status}
          closeHandler={() =>
            this.setState({
              confirmationDialog: {
                id: null,
                status: false,
                data: {},
              },
            })
          }
          classes={"confirmation-launch-modal"}
        >
          <ConfirmationDialogRunning
            confirmationDialog={this.state.confirmationDialog}
            closeHandler={() =>
              this.setState({
                isCloseModal: true,
                confirmationDialog: {
                  id: null,
                  status: false,
                  data: {},
                },
              })
            }
            addContacts={this.addContactsToRunningCampaign}
          />
        </ModalWindow>
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    createCampaignController: state.campaign.createCampaignController,
    campaigns: state.campaigns.campaigns,
    filters: state.filters,
    contactsCount: state.contacts.contactsCount,
  };
};

export default connect(mapStateToProps)(withRouter(ButtonCreateCampaigns));
