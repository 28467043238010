import React, { useEffect } from "react";
import "./style.scss";
import { useDropzone } from "react-dropzone";
import { Loader } from "semantic-ui-react";

import exportIcon from "../../../assets/image/icons/svg/export.svg";

const DropZoneFull = ({ setFullFile, readLoader }) => {
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    multiple: false,
  });

  useEffect(() => {
    setFullFile(acceptedFiles);
  }, [acceptedFiles]);

  return (
    <div className="drop-zone">
      {readLoader ? (
        <Loader size="massive" active={true} />
      ) : (
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <div className="drop-zone__formats formats">{svgIcon}</div>
          <p className="drop-zone-full__text">
            Drop your file here or select a file
          </p>
          <button type="button" onClick={open} className="btn-start">
            <img src={exportIcon} alt="export icon" />
            Browse files
          </button>
        </div>
      )}
    </div>
  );
};

export default DropZoneFull;

const svgIcon = (
  <svg width="42" height="54" viewBox="0 0 42 54" fill="none">
    <path
      d="M0.375 0.0976562V53.7227H41.625V13.6973L41.0449 13.0527L28.6699
   0.677734L28.0254 0.0976562H0.375ZM4.5 4.22266H25.125V16.5977H37.5V49.5977H4.5V4.22266ZM29.25
    7.1875L34.5352 12.4727H29.25V7.1875ZM10.6875 20.7227V24.8477H31.3125V20.7227H10.6875ZM10.6875
     28.9727V33.0977H31.3125V28.9727H10.6875ZM10.6875 37.2227V41.3477H31.3125V37.2227H10.6875Z"
      fill="#B3B6BF"
    />
  </svg>
);
