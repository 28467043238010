import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { notification } from "../../../actions/notification";
// components
import AuthTemplate from "../../templates/authTemplate";
import ModalConfirmLinkedInProfile from "./modal";
// img
import unLinked from "../../../assets/image/auth-steps/unLinked.png";

export const NoLinkedinContainer = ({ connector, email }) => {
  const [open, setOpen] = useState(false);
  const [profile, setProfile] = useState({});
  const [loader, setLoader] = useState(false);

  const history = useHistory();
  const clickOnLinked = async () => {
    let linkedInProfile;
    setLoader(true);
    try {
      linkedInProfile = await connector.linkedIn.captureCurrentProfile();
      if (linkedInProfile) {
        try {
          setProfile(linkedInProfile);
          setOpen(true);
        } catch (e) {
          notification.notificationUse(e);
        }
      } else {
        // #1122 ( Snaily backlog) - just disable this error
        // notification.notificationUse(
        //   "Looks like you are not signed-n at LinkedIn. Please sign-in there and try again."
        // );
      }
    } catch (e) {
      notification.notificationUse(e);
    } finally {
      setLoader(false);
    }
  };

  const clickOnLogout = async () => {
    try {
      await connector.auth.signOut();
      document.location.reload();
    } catch (e) {
      notification.notificationUse(e);
    }
  };

  return (
    <AuthTemplate active={3}>
      <span className="text-page-auth-link" style={{ margin: "45px 0 22px 0" }}>
        {email}
      </span>
      <h2>Link your LinkedIn account</h2>
      <img src={unLinked} alt="unLinked" width={130} />
      <p className="text-page-auth-modal-desc">
        Please, make sure you are signed in to LinkedIn with right account.
        <br />
        Check it before you continue!
      </p>
      <div className="modal-create-linked__btn-container">
        <button onClick={clickOnLogout} className="btn-logout">
          Log Out
        </button>
        <button
          disabled={loader}
          onClick={clickOnLinked}
          className="btn bold"
          style={{ position: "relative" }}
        >
          {loader ? (
            <CircularProgress
              size={20}
              classes={{ colorPrimary: "material-loader-accent" }}
            />
          ) : (
            "Continue"
          )}
        </button>
      </div>
      <ModalConfirmLinkedInProfile
        setOpen={setOpen}
        open={open}
        profile={profile}
        connector={connector}
        history={history}
        confirm={false}
        setLoader={setLoader}
      />
    </AuthTemplate>
  );
};

const mapStateToProps = function (state) {
  return {
    email: state.user.profile.email,
    connector: state.app.connector,
  };
};

export default connect(mapStateToProps)(NoLinkedinContainer);
