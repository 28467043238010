import store from "../redux/store";
import { notification } from "./notification";
import { app } from "./app";
import { apiActivity } from "../api/activity/apiActivity";

export const activity = {
  getActivities: async (fromEvent) => {
    !fromEvent && app.changePageContentLoaderStatus(true);
    !fromEvent && app.changeTableContentLoaderStatus(true);
    !store.getState().app.errorRequest.activityQueue &&
      app.setErrorRequest({
        ...store.getState().app.errorRequest,
        activityQueue: true,
      });
    try {
      await apiActivity.getActivities();
      app.setErrorRequest({
        ...store.getState().app.errorRequest,
        activityQueue: false,
      });

      !fromEvent && app.changePageContentLoaderStatus(false);
      !fromEvent && app.changeTableContentLoaderStatus(false);
    } catch (error) {
      notification.notificationUse(error);
      app.changePageContentLoaderStatus(false);
      app.changeTableContentLoaderStatus(false);
    }
  },
};
