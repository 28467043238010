const months = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

const mailboxMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const formatChatDate = (dateString) => {
  const date = new Date(dateString);
  const today = new Date();
  const padZero = (num) => (num < 10 ? "0" + num : num);
  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  ) {
    return "TODAY";
  }
  if (date.getFullYear() === today.getFullYear()) {
    return `${padZero(date.getDate())} ${months[date.getMonth()]}`;
  }
  return `${months[date.getMonth()]} ${padZero(
    date.getDate()
  )}, ${date.getFullYear()}`;
};

export const formatTime = (timeString) => {
  const time = new Date(timeString);
  const hour = time.getHours();
  const minute = time.getMinutes();
  const formattedHour = hour < 10 ? `0${hour}` : hour;
  const formattedMinute = minute < 10 ? `0${minute}` : minute;
  return `${formattedHour}:${formattedMinute}`;
};

export const formatFileSize = (bytes) => {
  const units = ["B", "KB", "MB", "GB", "TB"];
  let size = bytes;
  let unitIndex = 0;
  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }
  size = Math.round(size);
  return size + " " + units[unitIndex];
};
export const formatDateTime = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const today = new Date();
  const padZero = (num) => (num < 10 ? "0" + num : num);
  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  ) {
    return `${padZero(date.getHours())}:${padZero(date.getMinutes())}`;
  }

  if (date.getFullYear() === today.getFullYear()) {
    return `${padZero(date.getDate())} ${mailboxMonths[date.getMonth()]}`;
  }

  return `${padZero(date.getDate())} ${
    mailboxMonths[date.getMonth()]
  }, ${date.getFullYear()}`;
};

export const findRenderContent = (data) => {
  const item = data.find((obj) =>
    Object.values(obj).some((value) => value !== null)
  );
  return item ? Object.keys(item).find((key) => item[key] !== null) : null;
};
