import { app } from "./app";
import { user } from "./user";
import { listeners } from "../api/listeners";
import _ from "lodash";
import { notification } from "./notification";
import { CONSTS } from "../config/objectConst";
import { helpersFunc } from "../config/helpers";
import FatalError from "../errors/FatalError";
import store from "../redux/store";
import { api } from "../api/api";

async function setUpInit() {
  let isInstalled = await connectorAPI.extensionIsInstalled();
  if (isInstalled) {
    let snailyProfile = await connectorAPI.getSnailyProfile();
    let linkedInProfile = await connectorAPI.getLinkedProfile();
    if (snailyProfile && linkedInProfile) {
      const subscription = await api.getSubscription();
      store.dispatch({
        type: "SUBSCRIPTION",
        payload: {
          subscription: subscription?.data,
        },
      });
    }
  }
}

let onGoingSetup = null;

export const connectorAPI = {
  authChecker: async (
    token,
    isInstalled,
    isInstalledDone,
    linkedProfile,
    linkedProfileDone,
    appProfile,
    appProfileDone
  ) => {
    //app.appAuth("loading");
    if (isInstalledDone && linkedProfileDone && appProfileDone) {
      if (isInstalled && !_.isEmpty(appProfile)) {
        user.createUserProfile(appProfile);
      }

      if (isInstalled && linkedProfile !== null && !_.isEmpty(appProfile)) {
        user.createUserProfileFull(appProfile, linkedProfile);
        if (appProfile.timezoneOffset !== null && appProfile.workingHoursSet) {
          app.appAuth("auth");
        } else {
          app.appAuth("none");
        }
      } else {
        app.appAuth("none");
      }
    }
  },

  setUpInit: async () => {
    if (!onGoingSetup) {
      onGoingSetup = setUpInit();
    }
    try {
      await onGoingSetup;
    } finally {
      onGoingSetup = null;
    }
  },

  getAccessToken: async () => {
    const connector = listeners.getConnector();
    try {
      let result = await connector.auth?.getAccessToken();
      return result;
    } catch (e) {
      if (e.name === "ExtensionNotInstalledError") {
        window.location.reload();
      }
      console.log(e);
      /* you are don't ever loged token error because for logged you needed token - helpersFunc.logEnjectServicePromise(e); */
    }
  },

  extensionIsInstalled: async () => {
    try {
      // app.extensionIsInstalled(false, false);
      const connector = listeners.getConnector();
      let result = connector.extension.isInstalled();
      app.extensionIsInstalled(result, true);
      return result;
    } catch (e) {
      app.extensionIsInstalled(false, true);
      helpersFunc.logEnjectServicePromise(e);
    }
  },

  getSnailyProfile: async () => {
    try {
      const snailyConnector = listeners.getConnector();
      let result = await snailyConnector.auth.getSnailyProfile();
      app.setAppProfile(result, true);
      return result;
    } catch (error) {
      helpersFunc.logEnjectServicePromise(error);
      //#88 introduce FatalError and throw it here instead of generic Error with Message FATAL ERROR
      throw new FatalError(
        `Unhandled exception occurs during getting ${CONSTS.PROJECT_NAME} profile`
      );
    }
  },

  getLinkedProfile: async () => {
    try {
      app.setLinkedProfile({}, false);
      const connector = listeners.getConnector();
      let result = await connector.auth.getAssociatedLinkedInProfile();
      app.setLinkedProfile(result, true);
      return result;
    } catch (e) {
      app.setLinkedProfile({}, true);
      notification.notificationUse(e);
    }
  },
};
