import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import { app } from "../../actions/app";
import { notification } from "../../actions/notification";
import { connectorAPI } from "../../actions/connector";
import Snackbar from "@material-ui/core/Snackbar";
// hotjar
import { hotjar } from "react-hotjar";
// Google Tag Manager
import TagManager from "react-gtm-module";
// connectorFactory
import { connectorFactory } from "./connectorFactory";
// Components
import ErrorBoundary from "./ErrorBoundary";
import CampaignsPage from "../../pages/campaigns";
import CampaignCreatePage from "../../pages/campaignCreate";
import SingleCampaignPage from "../../pages/singleCampaign";
import SingleContactsPage from "../../pages/singleContacts";
import ContactsPage from "../../pages/contacts";
import AccountPage from "../../pages/account";
import TeamsPage from "../../pages/team/members";
import StatisticsPage from "../../pages/team/statistics";
import InvitedPage from "../../pages/team/invitations";
import MailboxPage from "../../pages/mailbox";
import LayoutUser from "./LayoutUser";
import AuthCallback from "./authCallback";
import InvitationCallback from "./InvitationCallback";
import ArchiveCampaignsPage from "../../pages/archiveCampaigns";
import NotfoundPage from "../../pages/notfound";
import ActivityPage from "../../pages/activityQueue";
import UpgradePage from "../../pages/upgrade";
import SnackNotification from "../../components/atoms/SnackNotification";
import SnackNotificationAttention from "../../components/atoms/SnackNotificationAttention";

// configs
import { CONSTS } from "../../config/objectConst";
// helps
import { listeners } from "../../api/listeners";
// Styles
import "semantic-ui-css/semantic.min.css";
import "../../assets/main.scss";
import "./style.scss";
import * as Sentry from "@sentry/react";
import { referrerDetector } from "../../api/referrerDetector";
import { ReferrerPage } from "./ReferrerPage";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snacknotification: false,
    };
  }

  callback = async (type) => {
    switch (type) {
      case "SignedOut":
        document.location.reload();
        break;
      case "SignedIn":
        if (!this.props.token) {
          await connectorAPI.setUpInit();
        }
        break;

      case "addSnailyProfile":
        /*if profile is completely filled*/
        if (this.props.appProfile?.workingHoursSet) {
          await connectorAPI.getSnailyProfile();
        }

        await connectorAPI.setUpInit();
        break;

      case "LinkedInSignedOut":
        app.changeLinkedInAuthStatus(false);
        this.setState({
          addLinkedInSignedOutEventHandler: true,
        });
        return;

      case "LinkedInProfileDetected":
        this.props.auth === "auth" && this.checkIsAssociatedLinkedInProfile();
        app.changeLinkedInAuthStatus(true);
        return;

      default:
        break;
    }
  };

  initThirdPartyIntegration = (userId) => {
    if (process.env.REACT_APP_SENTRY === "true") {
      Sentry.init({
        dsn: "https://c563bd35cb0f4697bda0dfe29df80483@o1303236.ingest.sentry.io/6541994",
        //integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.5,
        allowUrls: [
          "app.snaily.io",
          "sandbox-app.snaily.io",
          "chrome-extension://febgaeoegckcdcdhammknmiobpjkpgbe",
          "chrome-extension://gjncjmifcnnbaggdakbkgdmfkmknijhb",
        ],
      });
      Sentry.setContext("app", {
        version: CONSTS.APP_VERSION,
        userId: userId,
      });
      Sentry.setTag("app_version", CONSTS.APP_VERSION);
    }

    if (process.env.REACT_APP_GTM === "true") {
      const tagManagerArgs = {
        gtmId: CONSTS.GOOGLE_TAG_MANAGER.ID,
      };
      TagManager.initialize(tagManagerArgs);
    }

    if (process.env.REACT_APP_HOTJAR === "true") {
      hotjar.initialize(CONSTS.HJ_ID, CONSTS.HJ_SV);
      hotjar.identify(userId, { userProperty: "value" });
    }
  };

  connectorEvents = async () => {
    let connector = connectorFactory(CONSTS.EXTENSION_ID);
    await connector.init();

    app.appInit();
    app.createConnector(connector);

    if (connector.extension.isInstalled()) {
      connector.eventHandlers.addLinkedInSignedOutEventHandler(() =>
        this.callback("LinkedInSignedOut")
      );
      connector.eventHandlers.addLinkedInProfileDetectedHandler(() =>
        this.callback("LinkedInProfileDetected")
      );
      connector.eventHandlers.addSnailySignedInEventHandler(() =>
        this.callback("SignedIn")
      );
      connector.eventHandlers.addSnailySignedOutEventHandler(() =>
        this.callback("SignedOut")
      );
      connector.eventHandlers.addSnailyProfileChangedEventHandler(() =>
        this.callback("addSnailyProfile")
      );
    }
  };

  componentDidMount() {
    referrerDetector.capture(null, null);
    this.connectorEvents();
    window.addEventListener("error", (err) => {
      notification.notificationUse(err.error);
    });

    // campaign.getCampaignsParams();
    // campaign.loadCampaignList();
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile.id !== prevProps.profile.id) {
      this.props.profile.id &&
        this.initThirdPartyIntegration(this.props.profile.id);
    }
  }

  checkIsAssociatedLinkedInProfile = () => {
    const connector = listeners.getConnector();
    const associatedLinkedInProfile =
      connector.auth.isSignedInUnderAssociatedLinkedInProfile();

    associatedLinkedInProfile.then((value) => {
      app.setAssociatedLinkedInProfile(!!value);
    });
  };

  needRedirect = () => {
    let result =
      this.props?.appProfile?.impersonatedPersona ||
      (this.props?.appProfile?.subscriptionIsActive &&
        this.props?.appProfile?.subscriptionOwnerId &&
        this.props.appProfile.subscriptionOwnerId !==
          this.props?.appProfile?.personaId);
    return result;
  };

  render() {
    return (
      <ErrorBoundary>
        <Router>
          <Switch>
            <LayoutUser exact path="/campaigns" component={CampaignsPage} />
            <Route exact path="/">
              <Redirect to="/campaigns" />
            </Route>
            {this.needRedirect() && (
              <Route exact path="/upgrade">
                <Redirect to="/campaigns" />
              </Route>
            )}
            <LayoutUser
              exact
              path="/activities-queue"
              component={ActivityPage}
            />
            <LayoutUser
              exact
              path="/archive"
              component={ArchiveCampaignsPage}
            />
            <LayoutUser
              exact
              path="/archive/:id"
              component={SingleCampaignPage}
              archive={true}
            />
            <LayoutUser
              exact
              path="/campaigns/:id/tune"
              component={CampaignCreatePage}
            />
            <LayoutUser
              exact
              path="/campaigns/:id"
              component={SingleCampaignPage}
            />
            <LayoutUser
              exact
              path="/campaigns/:id/contacts"
              component={SingleContactsPage}
            />
            <LayoutUser exact path="/contacts" component={ContactsPage} />
            <LayoutUser exact path="/team/members" component={TeamsPage} />

            <LayoutUser
              exact
              path="/team/statistics"
              component={StatisticsPage}
            />

            <LayoutUser
              exact
              path="/team/invitations"
              component={InvitedPage}
            />

            <LayoutUser exact path="/mailbox" component={MailboxPage} />
            <LayoutUser
              exact
              path="/mailbox/thread/:mailboxId"
              component={MailboxPage}
            />
            <LayoutUser
              exact
              path="/contacts/import/:id"
              component={ContactsPage}
            />
            <LayoutUser exact path="/upgrade" component={UpgradePage} />
            <LayoutUser exact path="/account" component={AccountPage} />
            <Route exact path="/auth_callback" component={AuthCallback} />
            <Route
              exact
              path="/invitation_callback"
              component={InvitationCallback}
            />
            <Route exact path="/referrer/:id" component={ReferrerPage} />
            <LayoutUser component={NotfoundPage} />
          </Switch>
        </Router>

        <Snackbar
          appProfile={this.props.appProfile}
          className={
            this.props.snacknotification.style === "default"
              ? "snackbar-notification"
              : this.props.snacknotification.style === "attention"
              ? "snackbar-notification-attention"
              : ""
          }
          open={this.props.snacknotification.open}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }
            notification.snacknotificationUse({
              open: false,
              title: "",
              content: "",
              button: null,
              style: "",
            });
          }}
          autoHideDuration={5000}
          message={
            this.props.snacknotification.style === "default" ? (
              <SnackNotification
                message={this.props.snacknotification}
                actionClose={() =>
                  notification.snacknotificationUse({
                    open: false,
                    title: "",
                    content: "",
                    button: null,
                    style: "",
                  })
                }
              />
            ) : (
              this.props.snacknotification.style === "attention" && (
                <SnackNotificationAttention
                  message={this.props.snacknotification}
                  actionClose={() =>
                    notification.snacknotificationUse({
                      open: false,
                      title: "",
                      content: "",
                      button: null,
                      style: "",
                    })
                  }
                />
              )
            )
          }
        />
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    snacknotification: state.notification.message,
    token: state.app.token,
    appProfile: state.app.appProfile,
    auth: state.app.auth,
    profile: state.user.profile,
  };
};

export default connect(mapStateToProps)(withRouter(App));
