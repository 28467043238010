import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import { useSelector } from "react-redux";
import Arrow from "../../../assets/image/icons/svg/icons/arrow-down-thin.svg";
import { CONSTS } from "../../../config/objectConst";
import Select, { components } from "react-select";
import { api } from "../../../api/api";
import { CircularProgress } from "@material-ui/core";
import { notification } from "../../../actions/notification";
import { useHistory } from "react-router-dom";
import { activity } from "../../../actions/activity";

const DailyLimitsEditor = ({ customStatistics, getPeriodStatisticsAsync }) => {
  const messagesPerDayLimit = CONSTS.DAILY_LIMITS.MESSAGES.find(
    (el) =>
      +el.value ===
      (customStatistics?.messages_limit === undefined
        ? 0
        : customStatistics.messages_limit)
  );
  const invitationPerDayLimit = CONSTS.DAILY_LIMITS.INVITATIONS.find(
    (el) =>
      +el.value ===
      (customStatistics?.invitations_limit === undefined
        ? 0
        : customStatistics.invitations_limit)
  );
  const likesPerDayLimit = CONSTS.DAILY_LIMITS.LIKES.find(
    (el) =>
      +el.value ===
      (customStatistics?.react_on_post_limit === undefined
        ? 0
        : customStatistics.react_on_post_limit)
  );
  const followPerDayLimit = CONSTS.DAILY_LIMITS.FOLLOW.find(
    (el) =>
      +el.value ===
      (customStatistics?.follow_limit === undefined
        ? 0
        : customStatistics.follow_limit)
  );
  const history = useHistory();
  const [loaderMessages, setLoaderMessages] = useState(false);
  const [loaderInvitation, setLoaderInvitation] = useState(false);
  const [loaderLikes, setLoaderLikes] = useState(false);
  const [loaderFollow, setLoaderFollow] = useState(false);
  const [currentLimits, setCurrentLimits] = useState({
    messagesPerDayLimit,
    invitationPerDayLimit,
    likesPerDayLimit,
    followPerDayLimit,
  });

  useEffect(() => {
    setCurrentLimits((state) => ({ ...state, invitationPerDayLimit }));
  }, [invitationPerDayLimit]);

  useEffect(() => {
    setCurrentLimits((state) => ({ ...state, messagesPerDayLimit }));
  }, [messagesPerDayLimit]);

  useEffect(() => {
    setCurrentLimits((state) => ({ ...state, followPerDayLimit }));
  }, [followPerDayLimit]);

  useEffect(() => {
    setCurrentLimits((state) => ({ ...state, likesPerDayLimit }));
  }, [likesPerDayLimit]);

  const DropdownIndicator = useCallback((props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img
          src={Arrow}
          alt="arrow"
          className={props.selectProps.menuIsOpen ? "open-icon" : ""}
        />
      </components.DropdownIndicator>
    );
  }, []);

  const style = {
    control: (base, state) => {
      return {
        ...base,
        "&:hover": {
          borderColor: state.selectProps.menuIsOpen ? "#0cb39f" : "transparent",
        },
        borderColor: state.selectProps.menuIsOpen ? "#0cb39f" : "transparent",
        boxShadow: "",
        fontWeight: 700,
        fontSize: 14,
        background: "transparent",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        opacity: "1",
      };
    },
    singleValue: (base) => ({
      ...base,
      color: "#000",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: "0 10px 0 5px",
      height: "32px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "32px",
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      fontSize: 14,
      color: "#000",
      paddingLeft: "8px",
      backgroundColor: isSelected ? "#0cb39f" : "transparent",
      "&:active": {
        backgroundColor: "transparent",
      },
    }),
    menuList: (base) => ({
      ...base,
      height: 160,

      overflowX: "hidden",
    }),
    menu: (base) => ({
      ...base,
      border: "1px solid #0cb39f",
      boxShadow: "",
      marginTop: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderTop: 0,
    }),
  };

  return (
    <div className="select-per-day">
      <div className="select-per-day__change">
        <p className="select-per-day__text">Max invitations per day:</p>
        <div
          style={{
            width: `${
              currentLimits?.invitationPerDayLimit?.value.length === 1 ? 47 : 55
            }px`,
          }}
        >
          {invitationPerDayLimit && !loaderInvitation && (
            <Select
              options={CONSTS.DAILY_LIMITS.INVITATIONS}
              value={currentLimits.invitationPerDayLimit}
              styles={style}
              onChange={async (invitationPerDayLimit) => {
                setLoaderInvitation(true);
                try {
                  const response = await api.setDailyLimits({
                    ...currentLimits,
                    invitationPerDayLimit,
                  });
                  if (response.status === 200) {
                    getPeriodStatisticsAsync();
                    notification.snacknotificationUse({
                      open: true,
                      title: CONSTS.notification["3.15"].title,
                      content: CONSTS.notification["3.15"].content,
                      button: CONSTS.notification["3.15"].button,
                      style: CONSTS.notification["3.15"].style,
                    });
                  }
                } finally {
                  setLoaderInvitation(false);
                }

                if (history.location.pathname === "/activities-queue") {
                  activity.getActivities();
                }
              }}
              isSearchable={false}
              components={{ DropdownIndicator, IndicatorSeparator: () => null }}
            />
          )}
          {loaderInvitation && (
            <div className="select-per-day__container-loader">
              <CircularProgress
                size={18}
                classes={{ colorPrimary: "material-loader" }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="select-per-day__change">
        <p className="select-per-day__text">Max messages per day:</p>
        <div
          style={{
            width: `${
              currentLimits?.messagesPerDayLimit?.value.length === 1
                ? 47
                : currentLimits?.messagesPerDayLimit?.value.length === 2
                ? 55
                : 63
            }px`,
          }}
        >
          {messagesPerDayLimit && !loaderMessages && (
            <Select
              options={CONSTS.DAILY_LIMITS.MESSAGES}
              value={currentLimits.messagesPerDayLimit}
              styles={style}
              onChange={(messagesPerDayLimit) => {
                setLoaderMessages(true);
                api
                  .setDailyLimits({ ...currentLimits, messagesPerDayLimit })
                  .then(() => {
                    getPeriodStatisticsAsync();
                    notification.snacknotificationUse({
                      open: true,
                      title: CONSTS.notification["3.15"].title,
                      content: CONSTS.notification["3.15"].content,
                      button: CONSTS.notification["3.15"].button,
                      style: CONSTS.notification["3.15"].style,
                    });
                  })
                  .catch((e) => notification.notificationUse(e))
                  .finally(() => setLoaderMessages(false));

                if (history.location.pathname === "/activities-queue") {
                  activity.getActivities();
                }
              }}
              isSearchable={false}
              components={{
                DropdownIndicator,
                IndicatorSeparator: () => null,
              }}
            />
          )}
          {loaderMessages && (
            <div className="select-per-day__container-loader">
              <CircularProgress
                size={18}
                classes={{ colorPrimary: "material-loader" }}
              />
            </div>
          )}
        </div>
      </div>

      <div className="select-per-day__change">
        <p className="select-per-day__text">Max likes per day:</p>
        <div
          style={{
            width: `${
              currentLimits?.likesPerDayLimit?.value.length === 1
                ? 47
                : currentLimits?.likesPerDayLimit?.value.length === 2
                ? 55
                : 63
            }px`,
          }}
        >
          {likesPerDayLimit && !loaderLikes && (
            <Select
              options={CONSTS.DAILY_LIMITS.LIKES}
              value={currentLimits.likesPerDayLimit}
              styles={style}
              onChange={(likesPerDayLimit) => {
                setLoaderLikes(true);
                api
                  .setDailyLimits({ ...currentLimits, likesPerDayLimit })
                  .then(() => {
                    getPeriodStatisticsAsync();
                    notification.snacknotificationUse({
                      open: true,
                      title: CONSTS.notification["3.15"].title,
                      content: CONSTS.notification["3.15"].content,
                      button: CONSTS.notification["3.15"].button,
                      style: CONSTS.notification["3.15"].style,
                    });
                  })
                  .catch((e) => notification.notificationUse(e))
                  .finally(() => setLoaderLikes(false));

                if (history.location.pathname === "/activities-queue") {
                  activity.getActivities();
                }
              }}
              isSearchable={false}
              components={{
                DropdownIndicator,
                IndicatorSeparator: () => null,
              }}
            />
          )}
          {loaderLikes && (
            <div className="select-per-day__container-loader">
              <CircularProgress
                size={18}
                classes={{ colorPrimary: "material-loader" }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="select-per-day__change">
        <p className="select-per-day__text">Max following per day:</p>
        <div
          style={{
            width: `${
              currentLimits?.followPerDayLimit?.value.length === 1
                ? 47
                : currentLimits?.followPerDayLimit?.value.length === 2
                ? 55
                : 63
            }px`,
          }}
        >
          {followPerDayLimit && !loaderFollow && (
            <Select
              options={CONSTS.DAILY_LIMITS.FOLLOW}
              value={currentLimits.followPerDayLimit}
              styles={style}
              onChange={async (followPerDayLimit) => {
                setLoaderFollow(true);
                try {
                  const response = api.setDailyLimits({
                    ...currentLimits,
                    followPerDayLimit,
                  });
                  if (response.status === 200) {
                    getPeriodStatisticsAsync();
                    notification.snacknotificationUse({
                      open: true,
                      title: CONSTS.notification["3.15"].title,
                      content: CONSTS.notification["3.15"].content,
                      button: CONSTS.notification["3.15"].button,
                      style: CONSTS.notification["3.15"].style,
                    });
                  }
                } finally {
                  setLoaderFollow(false);
                }

                if (history.location.pathname === "/activities-queue") {
                  activity.getActivities();
                }
              }}
              isSearchable={false}
              components={{
                DropdownIndicator,
                IndicatorSeparator: () => null,
              }}
            />
          )}
          {loaderFollow && (
            <div className="select-per-day__container-loader">
              <CircularProgress
                size={18}
                classes={{ colorPrimary: "material-loader" }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DailyLimitsEditor;
