class BadGatewayError extends Error {
  constructor(message) {
    super(message);
    this.name = "BadGatewayError";

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, BadGatewayError);
    }
  }

  get skipLogging() {
    return true;
  }
}

export default BadGatewayError;
