import store from "../redux/store";
import { api } from "../api/api";
import { notification } from "./notification";

export const imports = {
  editImports: async ({ id, name }) => {
    try {
      await api.editImportsApi({ id, name });
      const newList = store
        .getState()
        .leftSideMenu.leftSide.list.map((item) => {
          if (item.id === id) {
            item.name = name;
          }
          return item;
        });
      store.dispatch({
        type: "LEFT_SIDE_MENU_LIST",
        payload: {
          leftSide: {
            ...store.getState().leftSideMenu.leftSide,
            list: newList,
          },
        },
      });
    } catch (error) {
      notification.notificationUse(error);
    }
  },
};
