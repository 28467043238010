import React from "react";
import { CircularProgress } from "@material-ui/core";
import images from "../../../assets/image/icons/buttons/export.svg";
import imagesDisable from "../../../assets/image/icons/buttons/export-disabled.svg";

import "./style.scss";

const ExportToCSVButton = ({
  onClick,
  disabled,
  isLoading,
  text,
  isInteraction,
}) => (
  <button
    className={"button"}
    onClick={() => !isLoading && onClick()}
    disabled={disabled}
  >
    <div
      className={
        disabled
          ? "export__container_disabled"
          : isInteraction
          ? "export__container_isInteraction"
          : "export__container"
      }
    >
      {isLoading ? (
        <div className="export__container_loader">
          <CircularProgress
            size={16}
            classes={{ colorPrimary: "material-loader-black" }}
          />
        </div>
      ) : (
        <>
          <img
            alt="export CSV"
            src={disabled ? imagesDisable : images}
            className="export__container_img"
          />
          <span
            className={
              disabled
                ? "export__container_text_disabled"
                : "export__container_text"
            }
          >
            {text}
          </span>
        </>
      )}
    </div>
  </button>
);

export default ExportToCSVButton;
