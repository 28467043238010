import React from "react";
import { Link } from "react-router-dom";
import board from "../../../assets/image/board.png";

import "./style.scss";

const UpgradeSubscriptionTeam = () => {
  return (
    <div className="modal-includes-team">
      <h2 className="modal-includes-team__title">
        Upgrade your subscription to TEAM plan
      </h2>
      <h2 className="modal-includes-team__subscription">
        Move your subscription up!
      </h2>
      <img
        alt="snail"
        src={board}
        width={190}
        className="modal-upgrade-team__img"
      />
      <Link to={"/upgrade"} className="modal-includes-team__link">
        {"Upgrade now"}
      </Link>
    </div>
  );
};

export default UpgradeSubscriptionTeam;
