import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import snailyNotAccepted from "../../../assets/image/snail-not_accept.png";

const ModalIncludesTeam = () => {
  return (
    <div className="modal-includes-team">
      <h2 className="modal-includes-team__title">
        You dont have access to the team!
      </h2>
      <h2 className="modal-includes-team__subscription">
        Ask team owner to upgrade your subscription
      </h2>
      <img
        alt="snail"
        src={snailyNotAccepted}
        className="modal-includes-team__img"
      />
      <Link to={"/campaigns"} className="modal-includes-team__link">
        Go home!
      </Link>
    </div>
  );
};

export default ModalIncludesTeam;
