import React from "react";
import "./style.scss";
import contactsIcon from "../../../../assets/image/icons/svg/icons/contacts.svg";
import arrowIcon from "../../../../assets/image/icons/svg/arrow-right.svg";

const ExportComplete = ({
  finishedUploading,
  showResultHandler,
  closeHandler,
}) => {
  return (
    <div className="export-complete">
      <p className="export-complete__result">
        {finishedUploading.exported} profiles were uploaded
      </p>
      <button className="btn semi-bold" onClick={showResultHandler}>
        <img src={contactsIcon} alt="contacts-icon" />
        Show results
      </button>
      <button className="export-complete__skip" onClick={closeHandler}>
        <img src={arrowIcon} alt="go back icon" />
        Skip
      </button>
      <div className="hidden-close-icon" />
    </div>
  );
};

export default ExportComplete;
