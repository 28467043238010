import React from "react";
import "./style.scss";
import { Loader } from "semantic-ui-react";
import { useState, useEffect, useRef } from "react";
import jschardet from "jschardet";
import { useSelector } from "react-redux";

const DropZone = ({
  readLoader,
  startUploadingHandler,
  setLinkedInContacts,
  file,
  setFile,
}) => {
  const { connector } = useSelector((state) => state.app);
  const [readFileLoader, setReadFileLoader] = useState(false);
  const [linkedInContactsLocal, setLinkedInContactsLocal] = useState([]);
  const debounceTimeout = useRef(null);
  const [fileLocal, setFileLocal] = useState(file);

  useEffect(() => {
    return () => {
      if (fileLocal && file !== fileLocal) {
        setFile(fileLocal);
      }
    };
  }, [file, fileLocal]);

  const readTextContent = async function (text, onRead) {
    if (typeof text !== "string" || !text.trim()) {
      return;
    }
    const getExportState = await connector.profilesExport.getState();
    if (getExportState) {
      if (
        getExportState.phase === "completed" ||
        getExportState.phase === "failed"
      ) {
        await connector.profilesExport.clear();
      }
    }

    const encoding = jschardet.detect(text).encoding;

    onRead(text, encoding);
  };

  const handleFileRead = async (content) => {
    const response = await connector.profilesExport.extractLinkedInContacts(
      content
    );
    if (response.length) {
      setLinkedInContactsLocal(response);
    }
  };

  useEffect(() => {
    if (fileLocal) {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(async () => {
        setReadFileLoader(true);

        try {
          await readTextContent(fileLocal, handleFileRead);
        } finally {
          setReadFileLoader(false);
        }
      }, 300);
    } else {
      setLinkedInContactsLocal([]);
    }

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [fileLocal]);

  return (
    <div className="container-drop-zone">
      {readLoader ? (
        <Loader size="massive" active={true} />
      ) : (
        <div>
          <textarea
            placeholder={`https://www.linkedin.com/in/john-smit-123786987615876123/
https://www.linkedin.com/in/samantha-adams-987612312360123787/`}
            className="textarea"
            value={fileLocal}
            onChange={(event) => setFileLocal(event.target.value)}
          />
        </div>
      )}
      <div className="container-drop-zone_flex-container">
        <div className="container-drop-zone_flex-container-small">
          {fileLocal && (
            <p>{linkedInContactsLocal.length || 0} LinkedIn profiles found</p>
          )}
          {readFileLoader && <Loader size="tiny" active={true} />}
        </div>
        <button
          type="button"
          className="btn-start-drop-zone"
          onClick={() => {
            setLinkedInContacts(linkedInContactsLocal);
            startUploadingHandler(linkedInContactsLocal);
          }}
          disabled={linkedInContactsLocal.length === 0}
        >
          Upload
        </button>
      </div>
    </div>
  );
};

export default DropZone;
