import React, { Component } from "react";
// Components
import ButtonCreateCampaigns from "../../atoms/buttonCreateCampaigns";
// Image, Icons
import imageDog from "../../../assets/image/snail-looking-for.png";
// Style
import "./style.scss";
// import { notification } from "../../../actions/notification";
export class NoCampaignsYetTemplate extends Component {
  render() {
    return (
      <div className="no-camp-template">
        <div className="no-camp-template-body">
          <span className="h-2 title">Ready to start?</span>
          <img className="img-snail" src={imageDog} alt="snail" />
          <p>
            No campaigns yet. <br />
            Create your first campaign to get more leads on LinkedIn
          </p>
          <ButtonCreateCampaigns
            type="new"
            classes="btn-v1 semi-bold"
            text="Create now"
          />
        </div>
      </div>
    );
  }
}

export default NoCampaignsYetTemplate;
