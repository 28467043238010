/*
 *
 * App reducer
 *
 */
const initialState = {
  searchController: false,
  appPage: "/campaigns",
  pageContentLoader: true,
  tableContentLoader: false,
  connector: {},
  token: "",
  auth: "loading",
  isInstalled: true,
  isInstalledDone: false,
  appProfile: {},
  appProfileDone: false,
  linkedProfile: {},
  linkedProfileDone: false,
  errorRequest: {
    contacts: false,
    campaigns: false,
    activityQueue: false,
  },
  linkedInAuth: true,
  associatedLinkedInProfile: true,
  subscription: null,
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case "APP_EXTENSION_IS_INSTALLED":
      return Object.assign({}, state, {
        isInstalled: action.payload.isInstalled,
        isInstalledDone: action.payload.isInstalledDone,
      });

    case "APP_PROFILE":
      return Object.assign({}, state, {
        appProfile: action.payload.appProfile,
        appProfileDone: action.payload.appProfileDone,
      });
    case "APP_LINKED_PROFILE":
      return Object.assign({}, state, {
        linkedProfile: action.payload.linkedProfile,
        linkedProfileDone: action.payload.linkedProfileDone,
      });
    case "APP_EXTENSION_CONNECTOR":
      return Object.assign({}, state, {
        connector: action.payload.connector,
      });

    case "APP_AUTH":
      return Object.assign({}, state, {
        auth: action.payload.auth,
      });
    case "APP_SEARCH":
      return Object.assign({}, state, {
        searchController: action.payload.searchController,
      });
    case "APP_PAGE":
      return Object.assign({}, state, {
        appPage: action.payload.appPage,
      });
    case "PAGE_CONTENT_LOADER":
      return Object.assign({}, state, {
        pageContentLoader: action.payload.pageContentLoader,
      });
    case "TABLE_CONTENT_LOADER":
      return Object.assign({}, state, {
        tableContentLoader: action.payload.tableContentLoader,
      });
    case "APP_ERROR_REQUEST":
      return Object.assign({}, state, {
        errorRequest: action.payload.errorRequest,
      });
    case "LINKEDIN_AUTH_STATUS":
      return Object.assign({}, state, {
        linkedInAuth: action.payload.linkedInAuth,
      });
    case "ASSOCIATED_LINKEDIN_PROFILE":
      return Object.assign({}, state, {
        associatedLinkedInProfile: action.payload.associatedLinkedInProfile,
      });
    case "SUBSCRIPTION":
      return Object.assign({}, state, {
        subscription: action.payload.subscription,
      });
    default:
      return state;
  }
};

export default app;
