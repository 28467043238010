import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

// Actions
import { notification } from "../../actions/notification";
// Components
import Notification from "../molecules/notification";
import BadAuthContainer from "../containers/badAuthContainer";

// Icons and Images

import { CircularProgress } from "@material-ui/core";
export class authCallback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      showPreloader: true,
      showSupportTemplate: false,
    };
    this.intervalAction = null;
  }

  toggleVisibility = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };

  componentDidMount() {
    this.toggleVisibility();
    this.intervalAction = setInterval(() => {
      this.toggleVisibility();
    }, 2000);
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.connector !== this.props.connector) {
      if (queryString.parse(this.props.history.location.search).error) {
        this.setState({ showPreloader: false, showSupportTemplate: true });
        return notification.notificationUse(
          queryString.parse(this.props.history.location.search).error
        );
      }
      if (queryString.parse(this.props.history.location.search).code) {
        try {
          //#781 This is a stub, suppressEvents is a temp fix off app behavior, without it app flickers at switchToWorkspace
          // exchangeAuthorizationCode fires snailySignedIn, switchToWorkspace fires appProfileChanged, it leads hat app is flickering
          // It has to be fixed on app during #781, it should work when appProfileChanged is fired, there is no reason to show sign-in dialog for a second
          this.props.connector.eventHandlers.suppressEvents();
          try {
            const metadata = this.props.connector.utils.createMetadata();
            await this.props.connector.auth.exchangeAuthorizationCode(
              queryString.parse(this.props.history.location.search).code,
              metadata
            );
            let linkedInProfiles =
              await this.props.connector.api.getLinkedInProfiles(metadata);
            // starting from here need to check is user Beta or follow one or another flow
            if (linkedInProfiles.length > 0) {
              const targetLinkedInProfile = linkedInProfiles[0];
              await this.props.connector.auth.switchToWorkspace(
                targetLinkedInProfile.id,
                metadata
              );
            }
          } finally {
            this.props.connector.eventHandlers.enableEvents();
          }

          this.props.history.push(
            queryString.parse(this.props.history.location.search).state
          );
        } catch (e) {
          this.setState({ showPreloader: false });
          notification.notificationUse(e);
          return this.setState({ showSupportTemplate: true });
        }
      }
    }
  }

  componentWillUnmount() {
    if (this.intervalAction) clearInterval(this.intervalAction);
  }

  render() {
    return (
      <div className="auth-call-back-preloader">
        <div className="auth-call-back-preloader-header">
          {this.props.notification.status && (
            <Notification
              text={this.props.notification.text}
              error={this.props.notification.error}
            />
          )}
        </div>
        <div className="auth-call-back-preloader-body">
          {this.state.showPreloader && (
            <>
              <div className="table-material-loader-wrapper">
                <CircularProgress
                  color="primary"
                  size={60}
                  classes={{ colorPrimary: "material-loader" }}
                />
              </div>
            </>
          )}
          {this.state.showSupportTemplate && <BadAuthContainer />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    connector: state.app.connector,
    notification: state.notification,
  };
};

export default connect(mapStateToProps)(withRouter(authCallback));
