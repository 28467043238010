import store from "../redux/store";
// import { mapping } from "./mapping";
// import mathFunctions from "../tools/mathFunctions";
import { api } from "../api/api";
import { notification } from "./notification";
import { helpersFunc } from "../config/helpers";
// import { campaignCreate } from "./campaignCreate";

export const app = {
  createConnector(connector) {
    store.dispatch({
      type: "APP_EXTENSION_CONNECTOR",
      payload: {
        connector: connector,
      },
    });
  },
  extensionIsInstalled(isInstalled, isInstalledDone) {
    store.dispatch({
      type: "APP_EXTENSION_IS_INSTALLED",
      payload: {
        isInstalled: isInstalled,
        isInstalledDone: isInstalledDone,
      },
    });
  },

  setAppProfile(appProfile, appProfileDone) {
    store.dispatch({
      type: "APP_PROFILE",
      payload: {
        appProfile: appProfile,
        appProfileDone: appProfileDone,
      },
    });
  },

  setLinkedProfile(linkedProfile, linkedProfileDone) {
    store.dispatch({
      type: "APP_LINKED_PROFILE",
      payload: {
        linkedProfile: linkedProfile,
        linkedProfileDone: linkedProfileDone,
      },
    });
  },

  // Example action click
  appInit() {
    // if (
    //   localStorage.getItem("campaignCreateName") &&
    //   localStorage.getItem("campaignCreateName").length > 0
    // ) {
    //   campaignCreate.create(localStorage.getItem("campaignCreateName"));
    // }
  },
  appAuth(status) {
    store.dispatch({
      type: "APP_AUTH",
      payload: {
        auth: status,
      },
    });
  },
  changePage(page) {
    store.dispatch({
      type: "APP_PAGE",
      payload: {
        appPage: page,
      },
    });
  },
  changeActiveItem(active) {
    store.dispatch({
      type: "LEFT_SIDE_MENU_LIST_ACTIVE",
      payload: {
        active: active,
      },
    });
  },
  changePageContentLoaderStatus(status) {
    store.dispatch({
      type: "PAGE_CONTENT_LOADER",
      payload: {
        pageContentLoader: status,
      },
    });
  },
  changeTableContentLoaderStatus(status) {
    store.dispatch({
      type: "TABLE_CONTENT_LOADER",
      payload: {
        tableContentLoader: status,
      },
    });
  },

  changeLinkedInAuthStatus(value) {
    store.dispatch({
      type: "LINKEDIN_AUTH_STATUS",
      payload: {
        linkedInAuth: value,
      },
    });
  },
  setAssociatedLinkedInProfile(value) {
    store.dispatch({
      type: "ASSOCIATED_LINKEDIN_PROFILE",
      payload: {
        associatedLinkedInProfile: value,
      },
    });
  },
  setNewData: async (url, data) => {
    const response = await api.setNewData(url, data);
    return response;
  },
  setErrorRequest(value) {
    store.dispatch({
      type: "APP_ERROR_REQUEST",
      payload: {
        errorRequest: value,
      },
    });
  },
};
