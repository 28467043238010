import store from "../redux/store";
import _ from "lodash";
import { functions } from "../tools/functions";
import { mapping } from "./mapping";
import { contacts } from "./contacts";
import { api } from "../api/api";
import { campaign } from "./campaign";
import { notification } from "./notification";
import { app } from "./app";

export const filters = {
  changeFilters(object, isAllData) {
    let filterData;
    if (isAllData) {
      filterData = object;
    } else {
      filterData = [...store.getState().filters.filterData];
      const index = filterData.indexOf(object);
      filterData[index] = object;
    }

    store.dispatch({
      type: "FILTERS_PARAMS",
      payload: {
        filterData: filterData,
      },
    });
  },

  changeFiltersForModal(object, isAllData) {
    let filterData;
    if (isAllData) {
      filterData = object;
    } else {
      filterData = [...store.getState().filters.filterDataForModal];
      const index = filterData.indexOf(object);
      filterData[index] = object;
    }

    store.dispatch({
      type: "FILTERS_PARAMS_FOR_MODAL",
      payload: {
        filterDataForModal: filterData,
      },
    });
  },

  changeAllTags(array) {
    return array.map((item) => {
      return filters.changeTag(item, array);
    });
  },

  changeTag(object, array) {
    const filterData = [...array];
    const index = filterData.indexOf(object);
    if (object.hasOwnProperty("tag")) {
      object.tag = filters.buildTagText(object);
    }
    filterData[index] = object;
    return filterData;
  },

  buildTagText(item) {
    if (_.isArray(item.value) && _.find(item.value, "value")) {
      if (_.isDate(_.find(item.value, "value").value)) {
        let datesArray = [];
        if (item.value["0"].value) {
          datesArray.push(functions.parseDateToDMY(item.value["0"].value, "/"));
        }
        if (item.value["1"].value) {
          datesArray.push(functions.parseDateToDMY(item.value["1"].value, "/"));
        }
        return item.name + ": " + datesArray.join(" - ");
      } else {
        if (item.value.length > 1) {
          return item.name + " (" + item.value.length + ")";
        } else {
          if (item.value.length > 0 && item.exclude) {
            return "NOT: " + item.value[0].text;
          }
          if (item.value.length > 0) {
            return item.value[0].text;
          }
          return "";
        }
      }
    }
    if (_.isString(item.value) || _.isNumber(item.value)) {
      return item.value;
    }
    if (_.isDate(item.value)) {
      return (
        item.value.getDate() +
        " " +
        functions.getMonth(item.value.getMonth()) +
        " " +
        item.value.getFullYear()
      );
    }
    if (_.isPlainObject(item.value)) {
      return item.value.text;
    }
  },

  buildSearchQuery(array) {
    const newArray = [];
    array.forEach((item) => {
      if (item.search && item.value.length > 0) {
        newArray.push(`(${this.getSearchQuery(item.value, item.exclude)})`);
      }
    });
    return newArray.join(" AND ");
  },

  getSearchQuery(array, exclude) {
    const newArray = array.map((item) => {
      if (exclude) {
        return `NOT (${functions.escapeSpecialChars(item.value)})`;
      }
      return `(${functions.escapeSpecialChars(item.value)})`;
    });
    return newArray.join(" OR ");
  },

  buildFilterExecutionsQuery(array) {
    let filtersArray = [];
    const newArray = [...array];
    const renamedArray = newArray.map((item) => {
      let newItem = { ...item };
      if (newItem.id === 1) {
        newItem.name = "distance";
      }
      if (newItem.id === 4) {
        newItem.name = "linkedin_contact/is_premium";
        newItem.value = newItem.value ? true : "";
      }
      return newItem;
    });
    renamedArray.forEach((item) => {
      if (
        _.isArray(item.value) &&
        item.value.length > 0 &&
        _.find(item.value, "value") &&
        !item.search
      ) {
        if (_.isDate(_.find(item.value, "value").value)) {
          filtersArray.push(
            "(" + filters.buildDataFilterParams(item.value, item.name) + ")"
          );
        } else {
          if (item.name === "campaigns") {
            filtersArray.push("(" + filters.buildCampaigns(item.value) + ")");
          } else {
            filtersArray.push(
              "(" +
                filters
                  .buildArrayFilterParams(
                    item.value,
                    item.name === "state"
                      ? item.name
                      : `linkedin_contact/${item.name}`
                  )
                  .join(" or ") +
                ")"
            );
          }
        }
      }
      if (_.isBoolean(item.value)) {
        filtersArray.push(`${item.name} eq ${item.value}`);
      }
    });
    return filtersArray.join(" and ");
  },

  buildFilterQuery(array) {
    let filtersArray = [];
    const newArray = [...array];
    const renamedArray = newArray.map((item) => {
      let newItem = { ...item };
      if (newItem.id === 1) {
        newItem.name = "distance";
      }
      if (newItem.id === 7) {
        newItem.name = "is_premium";
        newItem.value = newItem.value ? true : "";
      }
      if (newItem.id === 13) {
        newItem.name = "campaigns";
      }
      if (newItem.id === 14) {
        newItem.name = "contacted";
      }
      return newItem;
    });
    renamedArray.forEach((item) => {
      if (
        _.isArray(item.value) &&
        item.value.length > 0 &&
        _.find(item.value, "value") &&
        !item.search
      ) {
        if (_.isDate(_.find(item.value, "value").value)) {
          if (item.exclude) {
            filtersArray.push(
              "not (" +
                filters.buildDataFilterParams(item.value, item.name) +
                ")"
            );
          } else {
            filtersArray.push(
              "(" + filters.buildDataFilterParams(item.value, item.name) + ")"
            );
          }
        } else {
          if (item.name === "campaigns") {
            if (item.exclude) {
              filtersArray.push(
                "not (" + filters.buildCampaigns(item.value) + ")"
              );
            } else {
              filtersArray.push("(" + filters.buildCampaigns(item.value) + ")");
            }
          } else {
            filtersArray.push(
              "(" +
                filters
                  .buildArrayFilterParams(item.value, item.name)
                  .join(" or ") +
                ")"
            );
          }
        }
      }
      if (_.isBoolean(item.value)) {
        filtersArray.push(`${item.name} eq ${item.value}`);
      }
    });
    return filtersArray.join(" and ");
  },

  buildDataFilterParams(array, name) {
    const dataArray = [];
    array.forEach((item) => {
      if (item.value) {
        if (item.id === 1) {
          dataArray.push(`${name} gt ${item.value.toISOString()}`);
        }
        if (item.id === 2) {
          dataArray.push(`${name} lt ${item.value.toISOString()}`);
        }
      }
      return item;
    });
    return dataArray.join(" and ");
  },

  buildArrayFilterParams(array, name) {
    return array.map((item) => {
      let result = `${name} eq `;
      result += isNaN(item.value) ? `'${item.value}'` : item.value;
      return result;
    });
  },

  addKeyword(value, id, isModal) {
    const element = _.find(
      store.getState().filters[isModal ? "filterDataForModal" : "filterData"],
      ["id", id]
    );

    filters.buildKeywords(element, value, isModal);
  },

  buildKeywords(element, value, isModal) {
    let newValues = [...element.value];
    if (value && !_.find(newValues, ["value", value])) {
      newValues.push({
        key: filters.setKey(newValues),
        text: value,
        value: value,
      });
      element.value = newValues;

      if (isModal) {
        filters.changeFiltersForModal(element);
      } else {
        filters.changeFilters(element);
      }
    }
  },

  buildCampaigns(array) {
    const newArray = array.map((item) => {
      return `campaigns/any(c:c eq '${item.value}')`;
    });
    return newArray.join(" or ");
  },

  setKey(array) {
    const newArray = [...array];
    for (let index = 0; index < 1000; index++) {
      if (!_.find(newArray, ["key", index + 1])) {
        return index + 1;
      }
    }
  },

  changeFilterQuery: (filtersArray, searchQuery) => {
    store.dispatch({
      type: "FILTERS_QUERY_FOR_MODAL",
      payload: {
        filtersQueryForModal: filtersArray,
      },
    });

    store.dispatch({
      type: "FILTERS_SEARCH_QUERY_FOR_MODAL",
      payload: {
        searchQueryForModal: searchQuery,
      },
    });
  },

  getContacts: async (
    filterData,
    importId,
    fromKey,
    _,
    search,
    location,
    sort
  ) => {
    const filtersArray = [];
    const filtersQuery = filters.buildFilterQuery(filterData);
    const searchQuery = filters.buildSearchQuery(filterData);
    if (importId) {
      if (fromKey === "contacts") {
        filtersArray.push(`tags/any(c:c eq '${importId}')`);
      }
      if (fromKey === "addToCampaign") {
        filtersArray.push(`not (campaigns/any(c:c eq '${importId}'))`);
      }
    }
    if (filtersQuery) {
      filtersArray.push(filtersQuery);
    }

    store.dispatch({
      type: "FILTERS_QUERY",
      payload: {
        filtersQuery: filtersArray.join(" and "),
      },
    });

    store.dispatch({
      type: "FILTERS_SEARCH_QUERY",
      payload: {
        searchQuery: searchQuery,
      },
    });
    contacts.getContacts({
      skip: 0,
      filter: filtersArray.join(" and "),
      keywords: search,
      orderby: location?.includes("/import")
        ? sort
          ? sort
          : "imported asc"
        : sort,
    });
  },

  changeFiltersQueryForModal: (filterData, fromKey, importId) => {
    const filtersArray = [];
    const filtersQuery = filters.buildFilterQuery(filterData);
    const searchQueryForModal = filters.buildSearchQuery(filterData);
    if (fromKey === "addToCampaignOfModal") {
      filtersArray.push(`not (campaigns/any(c:c eq '${importId}'))`);
    }
    if (filtersQuery) {
      filtersArray.push(filtersQuery);
    }
    store.dispatch({
      type: "FILTERS_SEARCH_QUERY_FOR_MODAL",
      payload: {
        searchQueryForModal: searchQueryForModal,
      },
    });
    store.dispatch({
      type: "FILTERS_QUERY_FOR_MODAL",
      payload: {
        filtersQueryForModal: filtersArray.length && filtersArray.join(" and "),
      },
    });
  },

  getContactsLocation: async (data) => {
    try {
      const response = await api.getContactsLocationApi(data);
      store.dispatch({
        type: "OPTIONS_PARAMS",
        payload: {
          location:
            response.status === 204
              ? []
              : mapping.buildOption(response.data, "locations"),
        },
      });
    } catch (error) {
      notification.notificationUse(error);
      store.dispatch({
        type: "OPTIONS_PARAMS",
        payload: {
          location: [],
        },
      });
    }
  },

  getCampaignsField: async (data) => {
    const objForRequest = data
      ? data
      : {
          take: 10,
          filter: "not (state eq 'Compose')",
        };
    try {
      const response = await api.getCampaignsApi(objForRequest);
      store.dispatch({
        type: "OPTIONS_PARAMS",
        payload: {
          campaigns:
            response.status === 204
              ? []
              : mapping.buildOption(response.data.documents, "campaigns"),
        },
      });
    } catch (error) {
      notification.notificationUse(error);
      store.dispatch({
        type: "OPTIONS_PARAMS",
        payload: {
          campaigns: [],
        },
      });
    }
  },

  getCampaigns: async (data, rerender = true) => {
    let { updateLeftMenu } = { ...data };
    !store.getState().app.errorRequest.campaigns &&
      app.setErrorRequest({
        ...store.getState().app.errorRequest,
        campaigns: true,
      });
    try {
      const response = await api.getCampaignsApi(data);
      store.dispatch({
        type: "CAMPAIGNS_ARRAY",
        payload: {
          campaigns: response.data
            ? mapping.buildCampaignsArray(response.data.documents)
            : [],
          campaignsCount: response.data.total,
        },
      });

      if (response.data) {
        if (response.data.documents.length > 0) {
          campaign.haveCampaigns(true);
        }
        if (updateLeftMenu) {
          campaign.loadCampaignList(response.data.documents);
        }
      }
      app.setErrorRequest({
        ...store.getState().app.errorRequest,
        campaigns: false,
      });
    } catch (error) {
      notification.notificationUse(error);
      store.dispatch({
        type: "CAMPAIGNS_ARRAY",
        payload: {
          campaigns: [],
        },
      });
      if (updateLeftMenu) {
        campaign.loadCampaignList([]);
      }
    } finally {
      rerender && app.changePageContentLoaderStatus(false);
      rerender && app.changeTableContentLoaderStatus(false);
    }
  },

  getArchivedCampaigns: async () => {
    try {
      return await api.getCampaignsApi({
        archived: true,
      });
    } catch (error) {
      notification.notificationUse(error);
      app.changePageContentLoaderStatus(false);
      app.changeTableContentLoaderStatus(false);
    }
  },

  clearFilter(item, from) {
    item.tag = "";
    const fromModal = from === "addToCampaignOfModal";
    if (_.isArray(item.value)) {
      // a bit stupid approach, let it be
      const isTimeRange = item.value.length === 2;
      if (isTimeRange) {
        item.value = [
          { id: 1, value: null },
          { id: 2, value: null },
        ];
        const element = _.find(
          [
            ...store.getState().filters[
              fromModal ? "filterDataForModal" : "filterData"
            ],
          ],
          ["id", item.selectId]
        );
        element.value = null;
        fromModal
          ? filters.changeFiltersForModal(element)
          : filters.changeFilters(element);
      } else {
        item.value = [];
      }
    }
    if (_.isString(item.value)) {
      item.value = "";
    }
    if (_.isPlainObject(item.value)) {
      item.value = null;
    }
    fromModal ? this.changeFiltersForModal(item) : this.changeFilters(item);
  },

  clearAllFilters(forModal) {
    const filterData = [
      {
        id: 1,
        name: "connection",
        exclude: false,
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 2,
        name: "location",
        exclude: false,
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 3,
        name: "industry",
        exclude: false,
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 4,
        name: "keywords",
        exclude: false,
        search: true,
        value: [],
        tag: "",
      },
      {
        id: 5,
        name: "company",
        exclude: false,
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 6,
        selectId: 9,
        name: "contacted",
        exclude: false,
        search: false,
        value: [
          { id: 1, value: null },
          { id: 2, value: null },
        ],
        tag: "",
      },
      {
        id: 7,
        name: "status",
        exclude: false,
        search: false,
        value: "",
        tag: "",
      },
      {
        id: 8,
        name: "createdSelect",
        exclude: false,
        search: false,
        value: null,
      },
      {
        id: 9,
        name: "contactedSelect",
        exclude: false,
        search: false,
        value: null,
      },
      {
        id: 10,
        name: "campaigns",
        exclude: false,
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 11,
        name: "exclude contactedSelect",
        exclude: false,
        search: false,
        value: null,
      },
      {
        id: 12,
        name: "NOT: keywords",
        exclude: true,
        search: true,
        value: [],
        tag: "",
      },
      {
        id: 13,
        name: "NOT: campaigns",
        exclude: true,
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 14,
        selectId: 11,
        name: "NOT: contacted",
        exclude: true,
        search: false,
        value: [
          { id: 1, value: null },
          { id: 2, value: null },
        ],
        tag: "",
      },
      {
        id: 15,
        selectId: 8,
        name: "created",
        exclude: false,
        search: false,
        value: [
          { id: 1, value: null },
          { id: 2, value: null },
        ],
        tag: "",
      },
    ];
    store.dispatch({
      type: forModal ? "FILTERS_PARAMS_FOR_MODAL" : "FILTERS_PARAMS",
      payload: {
        [forModal ? "filterDataForModal" : "filterData"]: filterData,
      },
    });
    return filterData;
  },
};
