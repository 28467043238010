import React from "react";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import { CONSTS } from "../../../config/objectConst";
// Style
import "./style.scss";
import DropdownSimple from "../../atoms/DropdownSimple";
import { filters } from "../../../actions/filters";
import LocationIcon from "../../../assets/image/icons/svg/location-icon-yellow.svg";
import { contacts } from "../../../actions/contacts";
import RadioGroupCustom from "../../atoms/RadioGroupCustom";
import { executionHumanState } from "../../../api/executionHumanState";
import { statesDescriptor } from "../../../api/statesDescriptor";
import { contactsPageSize } from "../../../api/contactsPageSize";

const FiltersExecutions = (props) => {
  const applyFilter = async () => {
    const filter = [`campaign_id eq '${props.id}'`];
    const filtersQuery = filters.buildFilterExecutionsQuery(
      props.filterDataExecutionsLocal
    );
    if (filtersQuery) {
      filter.push(filtersQuery);
      props.setIsFilterQuery(true);
    } else {
      props.setIsFilterQuery(false);
    }
    props.setFilterQueryExecutions(filter.join(" and "));
    contacts.getContacts({
      status: "Active",
      skip: 0,
      filter: filter.join(" and "),
      orderby: props.isFilteringExecutions
        ? props.isFilteringExecutions
        : "created asc",
      keywords: props.keywordsForExecutions ? props.keywordsForExecutions : "",
    });
    props.setFiltersControllerToggle(false);
    props.setFilterDataExecutions(_.cloneDeep(props.filterDataExecutionsLocal));
  };
  const clearAllFilters = () => {
    props.setFilterDataExecutionsLocal([
      {
        id: 1,
        name: "connection",
        exclude: false,
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 2,
        name: "location",
        exclude: false,
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 3,
        name: "state",
        exclude: false,
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 4,
        name: "status",
        exclude: false,
        search: false,
        value: "",
        tag: "",
      },
    ]);
  };
  const statusData = [
    {
      key: 1,
      text: statesDescriptor.getExecutionTitle(executionHumanState.NotStarted),
      value: executionHumanState.NotStarted,
      color: statesDescriptor.detectColor(executionHumanState.NotStarted),
    },
    {
      key: 2,
      text: statesDescriptor.getExecutionTitle(executionHumanState.InProgress),
      value: executionHumanState.InProgress,
      color: statesDescriptor.detectColor(executionHumanState.InProgress),
    },
    {
      key: 3,
      text: statesDescriptor.getExecutionTitle(executionHumanState.Succeed),
      value: executionHumanState.Succeed,
      color: statesDescriptor.detectColor(executionHumanState.Succeed),
    },
    {
      key: 4,
      text: statesDescriptor.getExecutionTitle(executionHumanState.Ignored),
      value: executionHumanState.Ignored,
      color: statesDescriptor.detectColor(executionHumanState.Ignored),
    },
    {
      key: 5,
      text: statesDescriptor.getExecutionTitle(executionHumanState.Cancelled),
      value: executionHumanState.Cancelled,
      color: CONSTS.COLORS_CAMPAIGNS.cancelled,
    },
    {
      key: 6,
      text: statesDescriptor.getExecutionTitle(executionHumanState.Skipped),
      value: executionHumanState.Skipped,
      color: statesDescriptor.detectColor(executionHumanState.Skipped),
    },
    {
      key: 7,
      text: statesDescriptor.getExecutionTitle(executionHumanState.Failed),
      value: executionHumanState.Failed,
      color: statesDescriptor.detectColor(executionHumanState.Failed),
    },
  ];
  const getValue = (id) => {
    const element = _.find(props.filterDataExecutionsLocal, ["id", id]);
    return element.value;
  };

  const setValue = (id, value) => {
    const filterDataExecutions = props.filterDataExecutionsLocal;
    const element = _.find(filterDataExecutions, ["id", id]);
    element.value = value;
    const index = filterDataExecutions.indexOf(element);
    filterDataExecutions[index] = element;
    props.setFilterDataExecutionsLocal([...filterDataExecutions]);
  };

  const clearValue = (id, option) => {
    const filterDataExecutions = props.filterDataExecutionsLocal;
    const element = _.find(filterDataExecutions, ["id", id]);
    const newValue = element.value.filter((item) => option.key !== item.key);
    element.value = newValue;
    const index = filterDataExecutions.indexOf(element);
    filterDataExecutions[index] = element;
    props.setFilterDataExecutionsLocal([...filterDataExecutions]);
  };

  return (
    <div className={"filter-container"}>
      <div className="filters-grid">
        <Grid
          container
          justifycontent="flex-start"
          alignItems="flex-start"
          className="grid"
        >
          <h4 className="filter-title">Include</h4>
          <Grid item xs={3}>
            <DropdownSimple
              id={1}
              multiple
              tags
              options={props.option.colourOptions}
              label={"Connection"}
              placeholder="Connection"
              onChange={(e, newValue) => setValue(1, newValue)}
              onTagClick={clearValue}
              value={getValue(1)}
            />
          </Grid>
          <Grid item xs={3}>
            <DropdownSimple
              id={2}
              multiple
              tags
              customIcon={
                <img
                  src={LocationIcon}
                  className={"dropdown-simple__icon-location"}
                  alt="dropdownicon"
                />
              }
              getOptions={filters.getContactsLocation}
              options={props.option.location}
              label={"Location"}
              placeholder="Search"
              onChange={(e, newValue) => setValue(2, newValue)}
              onTagClick={clearValue}
              value={getValue(2)}
              dataForAuto={{}}
            />
          </Grid>
          <Grid item xs={3}>
            <DropdownSimple
              id={3}
              multiple
              tags
              options={statusData}
              label={"Status"}
              placeholder="Select status"
              onChange={(e, newValue) => setValue(3, newValue)}
              onTagClick={clearValue}
              value={getValue(3)}
            />
          </Grid>
        </Grid>
      </div>
      <div className="filters-grid">
        <Grid
          container
          justifycontent="flex-start"
          alignItems="flex-start"
          className="grid"
        >
          <Grid item xs={3}>
            <RadioGroupCustom
              id={4}
              label={"Account Status"}
              items={props.option.status}
              value={getValue(4)}
              onChange={(e) => setValue(4, e.target.value)}
            />
          </Grid>
        </Grid>
      </div>
      <div className="filters-grid">
        <Grid
          container
          justifycontent="flex-start"
          alignItems="flex-start"
          className="grid"
        ></Grid>
      </div>
      <div className="filter-controls">
        <button
          type="button"
          className="btn-outline alt"
          onClick={() => applyFilter()}
        >
          Apply
        </button>
        <button
          type="button"
          className="btn with-border semi-bold"
          onClick={clearAllFilters}
        >
          Clear all
        </button>
      </div>
    </div>
  );
};

export default FiltersExecutions;
