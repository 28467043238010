import React from "react";
import Icon from "./icon";

const SortIconCampaigns = (props) => {
  const {
    scrollBarWidth,
    idx,
    orderby = "",
    setOrderby,
    isSortedAsc,
    setIsSortedAsc,
    sort,
    text,
    flexEnd,
  } = props;
  const isActive = orderby.includes(sort);
  const getSortType = (idx, type) => {
    switch (idx) {
      case "name":
        return `name ${type} `;
      case "created":
        return `created ${type}`;
      case "invitation_sent":
        return `statistics/invitation_sent ${type}`;
      case "persona_id":
        return `persona_id ${type}`;
      case "messages_sent":
        return `statistics/messages_sent ${type}`;
      case "started_count":
        return `statistics/started_count ${type}`;
      case "acceptance_rate":
        return `statistics/acceptance_rate ${type}`;
      case "reply_rate":
        return `statistics/reply_rate ${type}`;
      default:
        return `created ${type}`;
    }
  };

  return isSortedAsc[idx] ? (
    <div
      className={
        flexEnd && scrollBarWidth
          ? `container-icon-sort-end-scroll ${isActive ? "active" : ""}`
          : flexEnd && !scrollBarWidth
          ? `container-icon-sort-end ${isActive ? "active" : ""}`
          : `container-icon-sort ${isActive ? "active" : ""}`
      }
      onClick={() => {
        setIsSortedAsc(idx, !isActive ? true : false);
        setOrderby(`${getSortType(idx, !isActive ? "asc" : "desc")}`);
      }}
    >
      {text}
      <Icon
        name="asc"
        size={18}
        className={isActive ? "title-images-active" : "title-images"}
        onClick={() => {
          setIsSortedAsc(idx, !isActive ? true : false);
          setOrderby(`${getSortType(idx, !isActive ? "asc" : "desc")}`);
        }}
      />
    </div>
  ) : (
    <div
      className={
        flexEnd && scrollBarWidth
          ? `container-icon-sort-end-scroll ${isActive ? "active" : ""}`
          : flexEnd && !scrollBarWidth
          ? `container-icon-sort-end ${isActive ? "active" : ""}`
          : `container-icon-sort ${isActive ? "active" : ""}`
      }
      onClick={() => {
        setIsSortedAsc(idx, true);
        setOrderby(`${getSortType(idx, "asc")} `);
      }}
    >
      {text}
      <Icon
        name="desc"
        size={18}
        className={isActive ? "title-images-active" : "title-images"}
        onClick={() => {
          setIsSortedAsc(idx, true);
          setOrderby(`${getSortType(idx, "asc")} `);
        }}
      />
    </div>
  );
};

export default SortIconCampaigns;
