import React from "react";
import TextFieldCustom from "../../atoms/TextFieldCustom";
import iconSearch from "../../../assets/image/icons/svg/search-icon-grey.svg";
import "./style.scss";
import _ from "lodash";
import { contacts } from "../../../actions/contacts";
import Tag from "../../../components/atoms/Tag";
import { filters } from "../../../actions/filters";

const FilterNavigationExecutions = (props) => {
  const filtersData = props.filterDataExecutions;

  const tagsArray = filtersData.filter(
    (item) => item.value && item.value.length > 0
  );

  const clearAllFilters = () => {
    props.setFilterDataExecutionsLocal([
      {
        id: 1,
        name: "connection",
        exclude: false,
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 2,
        name: "location",
        exclude: false,
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 3,
        name: "state",
        exclude: false,
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 4,
        name: "status",
        exclude: false,
        search: false,
        value: "",
        tag: "",
      },
    ]);
    props.setFilterDataExecutions([
      {
        id: 1,
        name: "connection",
        exclude: false,
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 2,
        name: "location",
        exclude: false,
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 3,
        name: "state",
        exclude: false,
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 4,
        name: "status",
        exclude: false,
        search: false,
        value: "",
        tag: "",
      },
    ]);
    contacts.getContacts({
      status: "Active",
      skip: 0,
      filter: [`campaign_id eq '${props.id}'`],
      orderby: "created asc",
      keywords: props.keywordsForExecutions ? props.keywordsForExecutions : "",
    });
    props.setFilterQueryExecutions("");
  };

  const clearFilter = (item) => {
    const filterDataExecutions = props.filterDataExecutions;
    const element = _.find(filterDataExecutions, ["id", item.id]);
    if (item.id === 4) {
      element.value = "";
    } else {
      element.value = [];
    }
    const index = filterDataExecutions.indexOf(element);
    filterDataExecutions[index] = element;
    props.setFilterDataExecutions([...filterDataExecutions]);
    props.setFilterDataExecutionsLocal(_.cloneDeep(filterDataExecutions));

    const filter = [`campaign_id eq '${props.id}'`];
    const filtersQuery = filters.buildFilterExecutionsQuery(
      props.filterDataExecutions
    );
    if (filtersQuery) {
      filter.push(filtersQuery);
      props.setIsFilterQuery(true);
    } else {
      props.setIsFilterQuery(false);
    }
    if (filter.join(" and ") !== "") {
      props.setFilterQueryExecutions(filter.join(" and "));
    }

    contacts.getContacts({
      status: "Active",
      skip: 0,
      filter: filter.join(" and "),
      orderby: "created asc",
      keywords: props.keywordsForExecutions ? props.keywordsForExecutions : "",
    });
  };
  return (
    <>
      <div className="search-container">
        <TextFieldCustom
          value={props.keywordsForExecutions}
          onChange={(e) => props.setKeywordsForExecutions(e.target.value)}
          placeholder={"Search"}
          onEnter={() => props.setOnSearchEnter(true)}
          onBlur={() => props.setOnSearchEnter(true)}
          endAdornment={
            <img
              className="icon-search"
              src={iconSearch}
              alt="search"
              onClick={() => props.setOnSearchEnter(true)}
            />
          }
        />
      </div>
      <button
        type="button"
        className="btn-text-icon filter-icon"
        onClick={() => props.setFiltersControllerToggle(true)}
      >
        Filters
      </button>

      <div className="__tags-wrap">
        <div className="filter-navigation__tags">
          {tagsArray.map((item, index) => {
            if (index <= 2) {
              return (
                <Tag
                  key={item.id}
                  type="small"
                  text={
                    item.value === "premium"
                      ? "premium"
                      : item.value.length === 1
                      ? item.value[0].text
                      : `${item.name} (${item.value.length}) `
                  }
                  onClick={() => clearFilter(item)}
                />
              );
            }
            return "";
          })}
          {tagsArray.length > 3 &&
            props.showAll &&
            tagsArray.map((item, index) => {
              if (index >= 3) {
                return (
                  <Tag
                    key={item.id}
                    type="small"
                    text={
                      item.value === "premium"
                        ? "premium"
                        : item.value.length === 1
                        ? item.value.text
                        : item.name
                    }
                    onClick={() => clearFilter(item)}
                  />
                );
              }
              return "";
            })}
        </div>
        <div className="filter-navigation__controls">
          {tagsArray.length > 3 && !props.showAll && (
            <>
              <button
                type="button"
                className="filter-navigation__button"
                onClick={() => props.setShowAll(true)}
              >
                Show more
              </button>
              <div className="vertical-divider"></div>
            </>
          )}
          {tagsArray.length > 0 && (
            <button
              type="button"
              className="filter-navigation__button"
              onClick={clearAllFilters}
            >
              Clear all
            </button>
          )}
        </div>
      </div>
    </>
  );
};
export default FilterNavigationExecutions;
