import React from "react";
// config
import { helpersFunc } from "../../config/helpers";
import iconError from "../../assets/image/icons/png/error.png";
import images from "../../assets/image/wentWrong.png";
import FatalError from "../../errors/FatalError";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errorInfo: {}, hasError: false };
  }

  promiseRejectionHandler = (error) => {
    //#88 handle specific Error , not generic
    if (error.reason instanceof FatalError) {
      this.setState({
        hasError: true,
      });
    } else {
      helpersFunc.logEnjectServicePromise(error.reason);
    }
  };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidMount() {
    window.addEventListener("unhandledrejection", this.promiseRejectionHandler);
  }

  componentWillUnmount() {
    window.removeEventListener(
      "unhandledrejection",
      this.promiseRejectionHandler
    );
  }

  componentDidCatch(error, errorInfo) {
    helpersFunc.logEnjectServiceCatch(error);

    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  onClick = () => {
    document.location.assign("/campaigns"); //don't wait for props
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="containerError">
          <div className="containerError__content">
            <div className="containerError__text-container">
              <img
                src={iconError}
                alt="error"
                className="containerError__img"
              />
              <h3 className="containerError__title">
                Oooops.. Something went wrong.
              </h3>
            </div>
            <div className="wrapper-button">
              <button
                className="containerError__link-button"
                onClick={this.onClick}
              >
                Go home
              </button>

              <a
                href="mailto:support@snaily.io"
                className="containerError__link-button-accent"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div>
                  <p className="support__text-error">Support</p>
                </div>
              </a>
            </div>
            <img
              src={images}
              alt="error went wrong"
              className="containerError__images"
            />
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
