import React, { Component } from "react";
import _ from "lodash";
// Style
import "./style.scss";

export class FormField extends Component {
  render() {
    return (
      <div className={`field ${this.props.classes ? this.props.classes : ""}`}>
        <div className="field-body">
          <div
            className={`field-label ${
              this.props.beaforeLabel ? "large-label" : ""
            }`}
          >
            <span className="text-field-label bold">
              {this.props.label}{" "}
              {this.props.required ? <span className="required">*</span> : null}
            </span>
            <span className="text-small-field-label">
              {_.isArray(this.props.beaforeLabel)
                ? this.props.beaforeLabel.map((item, index) => (
                    <span key={index}>{item}</span>
                  ))
                : this.props.beaforeLabel}
            </span>
          </div>
          <div className="field-content">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default FormField;
