/*
 *
 * activity reducer
 *
 */
const initialState = {
  activity: [],
  total: -1,
};

const activity = (state = initialState, action) => {
  switch (action.type) {
    case "ALL_ACTIVITIES":
      return Object.assign({}, state, {
        activity: action.payload.activity,
        total: action.payload.total,
      });
    default:
      return state;
  }
};

export default activity;
