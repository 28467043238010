import React, { Component } from "react";
import "./style.scss";

export class ErrorHelperStep extends Component {
  render() {
    return (
      <div className="error-helper-step">
        <div className="error-helper__icon"></div>
        <div className="error-helper__text">{this.props.children}</div>
      </div>
    );
  }
}

export default ErrorHelperStep;
