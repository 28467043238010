/*
  This All action on api
  https://github.com/public-apis/public-apis
*/
import { CONSTS } from "../../config/objectConst";
import { httpRequest } from "../httpRequest";
import { user } from "../../actions/user";

export const apiCampaign = {
  createCampaign: async (name) => {
    //let data = new FormData();
    //data.append("name", name);
    return await httpRequest({
      url: `${CONSTS.API.URL}campaigns`,
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: { name: name },
    });
  },
  updateCampaign: async ({ id, steps }) => {
    //let data = new FormData();
    //data.append("name", name);
    return await httpRequest({
      url: `${CONSTS.API.URL}campaigns/${id}/update_steps`,
      method: "PUT",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: { steps: steps },
    });
  },
  getCampaignById: async (id) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}campaigns/${id}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
  getCampaigns: async () => {
    return await httpRequest({
      url: `${CONSTS.API.URL}campaigns/page?%24take=${CONSTS.PAGE_SIZE}&$orderby=created asc&$filter=archived eq false`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },

  addTargetAudience: async (data) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}campaigns/${data.id}/target_audience${
        data.params ? `?&$filter=${data.params}` : ""
      }`,

      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getPreviewAddTargetAudience: async (data) => {
    let { keywords, filter, id } = { ...data };
    const params = `?${keywords ? "&$searchQuery=" + keywords : ""}${
      filter ? "&$filter=" + filter : ""
    }`;
    return await httpRequest({
      url: `${CONSTS.API.URL}campaigns/${id}/target_audience/preview${params}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
  getPreviewRemoveTargetAudience: async (data) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}executions/delete/preview${
        data.filter ? "?&$filter=" + data.filter : ""
      }${data.keywords ? "?&$searchQuery=" + data.keywords : ""}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },

  removeContactsFromCampaignApi: async (data) => {
    let { campaignId, filter } = { ...data };
    return await httpRequest({
      url: `${CONSTS.API.URL}campaigns/${campaignId}/target_audience${
        filter ? `?&$filter=${filter}` : ""
      }`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  },

  removeExecutionsFromCampaignApi: async (data) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}executions/delete${
        data.filter ? "?&$filter=" + data.filter : ""
      }${data.keywords ? "?&$searchQuery=" + data.keywords : ""}`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  },

  editCampaignApi: async (data) => {
    let { id, name } = { ...data };
    return await httpRequest({
      url: `${CONSTS.API.URL}campaigns/${id}/update`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        name,
      },
    });
  },

  addCampaignToArchiveApi: async (id) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}campaigns/${id}/archive`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  removeCampaignFromArchiveApi: async (id) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}campaigns/${id}/unarchive`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  },

  getPreviewOfStartupCampaing: async (id) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}campaigns/${id}/preview`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
  exportCampaignsApi: async ({ filter, orderby, searchValue, archived }) => {
    let archivedParam = "";
    let statuses = filter ? filter : "";
    let resultFilters = "";
    if (!orderby) {
      orderby = "created desc";
    }

    if (archived !== undefined) {
      archivedParam = `(archived eq ${archived})`;
    }
    if (archivedParam || statuses) {
      resultFilters = `&$filter=${archivedParam}${
        statuses ? ` ${archivedParam ? "and" : ""} (${statuses})` : ""
      }`;
    }
    const params = `?${resultFilters}${
      searchValue ? "&$searchQuery=" + searchValue : ""
    }${orderby ? "&$orderby=" + orderby : ""}`;
    return await httpRequest({
      url: `${CONSTS.API.URL}campaigns/export${params}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
};
