import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
// Components
import ContainerStep from "./step";
// Actions
import { campaign } from "../../../actions/campaign";
// Style
import "./style.scss";
import { Element } from "react-scroll";
import { CircularProgress } from "@material-ui/core";
import exclaim from "./../../../assets/image/icons/svg/exclaim-black.svg";

export class CampaingsSteps extends Component {
  state = {
    contentLoader: true,
    isError: false,
  };

  componentDidMount() {
    this.getCampaignData();
  }

  getStatus = (info) => {
    return info.find((item) => item.id === 1).value;
  };

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.getCampaignData();
    }
  }

  getCampaignData = async () => {
    try {
      const res = await campaign.getCampaignId(this.props.match.params.id);
      if (!res) {
        this.props.pageFound(false);
      }
      campaign.changeCampaignUpdateStep(
        this.props.campaign.count > 0 ? "1" : "2"
      );
    } catch (e) {
      this.setState({ isError: true });
    } finally {
      this.setState({ contentLoader: false });
    }
  };

  render() {
    return (
      <div className="steps-template">
        {this.state.contentLoader && (
          <div className="material-loader-wrapper">
            <CircularProgress
              color="primary"
              size={60}
              classes={{ colorPrimary: "material-loader" }}
            />
          </div>
        )}
        <div className="flex-container-arrow">
          <div className="edit-title-text">
            <p>{this.props.labelName}</p>
          </div>
          <Element
            name="myScrollToElement-zoomed-message"
            className="steps-template-header"
          >
            <h3>{this.props.campaign.name}</h3>
          </Element>
        </div>

        {!this.props.campaign.allowAddSteps && !this.state.isError && (
          <div className="edit-notification">
            <img
              src={exclaim}
              alt="exclaim"
              className="edit-notification__icon"
            />
            <p className="edit-notification__text">
              The campaign has already started, editing is limited
            </p>
          </div>
        )}
        {!this.state.isError && (
          <ContainerStep campaign={this.props.campaign} />
        )}
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    campaign: state.campaign,
    campaignLoaderController: state.campaign.campaignLoaderController,
    loadCampaignsController: state.campaigns.loadCampaignsController,
    finishStep: state.campaignCreate.finishStep,
  };
};

export default connect(mapStateToProps)(withRouter(CampaingsSteps));
