/*
 *
 * notification reducer
 *
 */
const initialState = {
  status: null,
  text: "",
  message: {
    open: false,
    title: "",
    text: "",
  },
};

const notification = (state = initialState, action) => {
  switch (action.type) {
    case "NOTIFICATION":
      return Object.assign({}, state, {
        ...action.payload,
      });
    case "SNACK_NOTIFICATION":
      return Object.assign({}, state, {
        message: action.payload.message,
      });
    default:
      return state;
  }
};

export default notification;
