import React from "react";
import { CircularProgress } from "@material-ui/core";

const ResponsiveButton = ({
  children,
  onClick,
  disabled,
  isLoading,
  className,
}) => (
  <button
    className={className}
    onClick={() => !isLoading && onClick()}
    disabled={disabled}
  >
    {isLoading ? (
      <CircularProgress
        size={20}
        classes={{ colorPrimary: "material-loader-black" }}
      />
    ) : (
      children
    )}
  </button>
);

export default ResponsiveButton;
