import React, { useEffect, useState, useRef } from "react";
import "./style.scss";
import { notification } from "../../../actions/notification";
import { api } from "../../../api/api";
import { mapping } from "../../../actions/mapping";
import TableContainer from "@material-ui/core/TableContainer";
import { CircularProgress } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import notSelectedCheckboxIcon from "../../../assets/image/icons/tables/checkbox.svg";
import selectedCheckboxIcon from "../../../assets/image/icons/tables/checkbox2.svg";
import partiallySelectedCheckboxIcon from "../../../assets/image/icons/tables/checkbox3.svg";
import TableBody from "@material-ui/core/TableBody";
import { linkedInProfileOpener } from "../../../api/linkedInProfileOpener";
import _ from "lodash";
import inIcon from "../../../assets/image/icons/tables/in.svg";
import { functions } from "../../../tools/functions";
import TablePagination from "@material-ui/core/TablePagination";
import MTablePagination from "../../atoms/mTablePagination";
import TooltipCustom from "../../atoms/tooltipCustom";
import FilterNavigation from "../../molecules/filterNavigation";
import Filters from "../filters";
import NoSearchContacts from "../../templates/noSearchContacts";
import ButtonAddToCampaigns from "../../atoms/buttonAddToCampaigns";
import NoContactsYetTemplate from "../../templates/noContactsYetTemplate";
import SortIcon from "../../molecules/TableUsers/sortIcons";
import Select from "react-select";
import linkSvgGold from "../../../assets/image/icons/tables/goldLink.svg";
import { statesDescriptor } from "../../../api/statesDescriptor.js";
import { contactsPageSize } from "../../../api/contactsPageSize";
import avatarSvg from "../../../assets/image/icons/svg/user.svg";

const AddContactsModal = (props) => {
  const [contactsData, setContactsData] = useState([]);
  const [contactsCount, setContactsCount] = useState(null);
  const [selectedValues, setSelectedValues] = useState(0);
  const [tableContentLoader, setTableContentLoader] = useState(true);
  const [selectAllUsersValue, setSelectAllUsersValue] = useState(false);
  const [filtersController, setFiltersController] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(true);
  const [contactsOnPage, setContactsOnPage] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [clearFilterData, setClearFilterData] = useState(false);
  const [scrollBarWidth, setScrollBarWidth] = useState(false);
  const { width, height } = useWindowSize();
  const [isRerender, setIsRerender] = useState(false);
  const [isError, setIsError] = useState(null);
  const [optionsSizePagination, setOptionsSizePagination] = useState([]);
  const [isOpenMenuPagination, setIsOpenMenuPagination] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const wrapper = useRef();
  const [initialValuePagination, setInitialValuePagination] = useState(
    contactsPageSize.get()
  );

  useEffect(() => {
    const lengthFilter = !!props.filters.filterDataForModal.find((el) => {
      if (el?.value?.length && el.value.length > 0 && el.value[0]?.value)
        return true;
      else return false;
    });
    setIsFilterApplied(lengthFilter || props.keywordsForModal);
  }, [contactsCount]);

  useEffect(() => {
    setOptionsSizePagination([
      { value: "10", label: "Show 10 items" },
      { value: "25", label: "Show 25 items" },
      { value: "50", label: "Show 50 items" },
      { value: "100", label: "Show 100 items" },
    ]);
  }, []);

  const openLinkedInProfile = async (row) => {
    await linkedInProfileOpener.open(props.app.connector, row);
  };

  const generationClassName = (scrollBarWidth) => {
    if (scrollBarWidth) {
      return "date w9";
    }

    return "date w10";
  };

  useEffect(() => {
    const getContactsAsync = async () => {
      try {
        if (props.campaignId) {
          const filter = props.filters.filtersQueryForModal
            ? props.filters.filtersQueryForModal
            : `not (campaigns/any(c:c eq '${props.campaignId}'))`;
          const data = {
            take: initialValuePagination,
            skip: 0,
            filter: filter,
            orderby: props.isFilteringModal
              ? props.isFilteringModal
              : "imported desc",
            keywords: props.keywordsForModal,
          };
          await getContacts(data);
        }
      } catch (error) {
        setIsError(true);
        notification.notificationUse(error);
      }
    };
    getContactsAsync();
    setIsRerender(true);
  }, []);

  useEffect(() => {
    if (wrapper.current) {
      const scrollbarWidth =
        wrapper.current.offsetWidth - wrapper.current.clientWidth;

      if (scrollbarWidth > 0 && scrollBarWidth === false) {
        setScrollBarWidth(true);
      }

      if (scrollbarWidth === 0 && scrollBarWidth === true) {
        setScrollBarWidth(false);
      }
    }
  }, [width, height, tableContentLoader]);

  useEffect(() => {
    if (isRerender) {
      getContactsBySort();
    }
  }, [props.isFilteringModal]);

  useEffect(() => {
    !!contactsCount && handleChangePage(_, 0);
  }, [initialValuePagination]);

  const onSelectAllPageClick = (event) => {
    setSelectAllUsersValue(false);
    setSelectedValues(
      contactsCount <= initialValuePagination
        ? contactsCount
        : initialValuePagination
    );
    if (event.target.checked) {
      let newSelecteds = contactsData;
      newSelecteds = newSelecteds.filter((item) => !selected.includes(item));
      setSelected(
        selected.concat(newSelecteds).map((item, index) => {
          return item.id;
        })
      );
      return;
    }
    setSelected([]);
    setSelectedValues(0);
  };
  const stylePagination = {
    control: (base, state) => {
      return {
        ...base,
        "&:hover": {
          border: isOpenMenuPagination
            ? "1px solid #0cb39f"
            : "1px solid  #EBEDF2",
        },

        border: isOpenMenuPagination
          ? "1px solid #0cb39f"
          : "1px solid  #EBEDF2",
        backgroundColor: "#FFF",
        boxShadow: "",
        fontWeight: 400,
        fontSize: 14,

        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        opacity: "1",
        color: "#202020",
        width: "150px",
        cursor: "pointer",
        minHeight: "31px",
      };
    },
    indicatorsContainer: (base) => ({
      ...base,

      borderLeft: isOpenMenuPagination
        ? "1px solid #0cb39f"
        : "1px solid  #EBEDF2",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      backgroundColor: "transparent",
      paddingTop: "5px",
      paddingBottom: "5px",
    }),
    singleValue: (base) => ({
      ...base,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: "5px   3px",
      height: "32px",
      color: "#202020",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "32px",
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      fontSize: 14,
      color: "#000",
      backgroundColor: isSelected ? "#F7F7F7" : "transparent",
      "&:active": {
        backgroundColor: "transparent",
      },
      "&:hover": { backgroundColor: "#F7F7F7", cursor: "pointer" },
    }),
    menuList: (base) => ({
      ...base,
      position: "absolute",
      left: "-1px",
      bottom: "34px",
      backgroundColor: "#fff",
      width: "150px",
      border: "1px solid #0cb39f",
      borderBottom: "1px solid transparent",
    }),
    menu: (base) => ({
      ...base,
      boxShadow: "",
      marginTop: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderTop: 0,
    }),
  };
  const onSelectAllClick = () => {
    let newSelectedIds = contactsData.map((item) => item.id);
    setSelected(newSelectedIds);
    setSelectedValues(contactsCount);
    setSelectAllUsersValue(true);
  };

  const onClearAllClick = () => {
    setSelected([]);
    setSelectedValues(0);
    setSelectAllUsersValue(false);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      setSelectAllUsersValue(false);
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      setSelectAllUsersValue(false);
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      setSelectAllUsersValue(false);
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    setSelectedValues(newSelected.length);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const renderSteps2 = (row, item) => {
    let items = [];
    for (let index in row) {
      statesDescriptor.detectColor(item.human_state, row[index]) &&
        items.push(
          <TooltipCustom key={index} title={row[index].hint} arrow={true}>
            <div
              className={`steps-container-item`}
              style={{
                background: `${statesDescriptor.detectColor(
                  item.human_state,
                  row[index]
                )}`,
              }}
            ></div>
          </TooltipCustom>
        );
    }

    return items;
  };

  const getContactsBySort = () => {
    return getContacts({
      take: initialValuePagination,
      filter: props.filters.filtersQueryForModal
        ? props.filters.filtersQueryForModal
        : `not (campaigns/any(c:c eq '${props.campaignId}'))`,
      keywords: props.keywordsForModal,
      orderby: props.isFilteringModal
        ? props.isFilteringModal
        : "imported desc",
    });
  };

  const getContacts = async (data) => {
    setTableContentLoader(true);
    try {
      const response = await api.getContactsApi(data);
      if (response.status === 200) {
        setContactsData(mapping.buildContacts(response.data.documents));
        setContactsCount(response.data.total);
      } else {
        setContactsData([]);
        setContactsCount(0);
      }

      clearCheckboxes();
    } finally {
      // catch (e) {
      //   setContactsData([]);
      //   setContactsCount(0);
      //   throw e;
      // }
      setTableContentLoader(false);
    }
  };

  const filtersControllerByParams = (value) => {
    setFiltersController(value);
  };

  const handleChangePage = (event, newPage) => {
    try {
      setPage(newPage);
      if (props.campaignId) {
        if (props.type === "add-to-campaign") {
          const filter = props.filters.filtersQueryForModal
            ? props.filters.filtersQueryForModal
            : `not (campaigns/any(c:c eq '${props.campaignId}'))`;
          return getContacts({
            take: initialValuePagination,
            skip: newPage > 0 ? newPage * initialValuePagination : 0,
            filter: filter,
            keywords: props.keywordsForModal ? props.keywordsForModal : "",
            orderby: props.isFilteringModal
              ? props.isFilteringModal
              : "imported desc",
          });
        }
      }
      setContactsOnPage(contactsData);
    } catch (e) {
      notification.notificationUse(e);
    }
  };

  const clearCheckboxes = () => {
    setSelected([]);
  };

  return (
    <div className="add-contacts-modal-wrapper">
      <div className="hint-tooltip-wrapper">
        <div className="add-contacts-modal__header">
          <span className="add-contacts-modal__headline">
            {props.campaign.name}
          </span>
          <span className="add-contacts-modal__separator"></span>
          <span className="add-contacts-modal__title">Add more contacts</span>
        </div>
        {(tableContentLoader ||
          contactsCount > 0 ||
          isFilterApplied ||
          isError) && (
          <>
            <FilterNavigation
              from={"addToCampaignOfModal"}
              pageHandler={setPage}
              getContacts={getContacts}
              activeImportId={props.campaignId}
              filters={props.filters}
              filtersController={filtersController}
              filtersControllerToggle={() =>
                setFiltersController(!filtersController)
              }
              clearFilterController={setClearFilterData}
              setShowAll={setShowAll}
              showAll={showAll}
              setKeywords={props.setKeywordsForModal}
              keywords={props.keywordsForModal}
              filtersQueryForModal={props.filters.filtersQueryForModal}
            />
          </>
        )}
        {(tableContentLoader || contactsCount > 0 || isError) && (
          <div className={`table-container ${props.classesTableContainer}`}>
            <TableContainer className={"table-body with-checkboxs"}>
              {tableContentLoader && (
                <div className="table-material-loader-wrapper">
                  <CircularProgress
                    color="primary"
                    size={60}
                    classes={{ colorPrimary: "material-loader" }}
                  />
                </div>
              )}
              <Table
                className={"table users"}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox" className="checkbox">
                      <div className="checkbox-container">
                        <Checkbox
                          indeterminate={
                            contactsOnPage &&
                            contactsOnPage.length <
                              selected.filter((item) =>
                                contactsOnPage.includes(item)
                              ).length
                          }
                          checked={
                            selected &&
                            selected.length > 0 &&
                            contactsOnPage.length ===
                              selected.filter((item) =>
                                contactsOnPage.includes(item)
                              ).length
                          }
                          onChange={onSelectAllPageClick}
                          inputProps={{
                            "aria-label": "select all desserts",
                          }}
                          indeterminateIcon={
                            <img src={notSelectedCheckboxIcon} alt="checkbox" />
                          }
                          icon={
                            <img src={notSelectedCheckboxIcon} alt="checkbox" />
                          }
                          checkedIcon={
                            <img
                              src={
                                contactsData.length > selectedValues
                                  ? partiallySelectedCheckboxIcon
                                  : selectedCheckboxIcon
                              }
                              alt="checkbox"
                            />
                          }
                        />
                      </div>
                    </TableCell>
                    <TableCell className="name">
                      {selected.length > 0 ? (
                        <div className="selected-rows">
                          <div>
                            {selectedValues > 0 && (
                              <>
                                <span className="select-text">
                                  {selectedValues} of {contactsCount} selected |{" "}
                                </span>
                                {!selectAllUsersValue && (
                                  <span
                                    className="clear-all"
                                    onClick={onSelectAllClick}
                                  >
                                    Select all
                                  </span>
                                )}

                                {selectAllUsersValue && (
                                  <span
                                    className="clear-all"
                                    onClick={onClearAllClick}
                                  >
                                    Clear
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                          {props.checkboxs && (
                            <div className="btn-group-accent">
                              <div className="background-button">
                                <ButtonAddToCampaigns
                                  from={props.from}
                                  campaignFilters={props.campaignFilters}
                                  buildFiltersArray={props.buildFiltersArray}
                                  activeImportId={props.campaignId}
                                  classes={"button-modal"}
                                  setPage={setPage}
                                  campaign={props.campaign}
                                  getContacts={getContacts}
                                  text="Add to campaign"
                                  searchValue={props.searchValue}
                                  disabled={!selected.length}
                                  contacts={selected}
                                  clearCheckboxesFunc={clearCheckboxes}
                                  history={props.history}
                                  selectAllUsersValue={selectAllUsersValue}
                                  page={page}
                                  contactsCount={contactsCount}
                                  keywordsForModal={props.keywordsForModal}
                                  contactsPage={props.contactsPage}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="container-title-images">
                          <SortIcon
                            text={"Contact"}
                            sort={"full_name"}
                            idx={"contact"}
                            isFiltering={props.isFilteringModal}
                            setIsFiltering={props.setIsFilteringModal}
                            isSortedAsc={props.isIconActiveModal}
                            setIsSortedAsc={props.setActiveFilterIconModal}
                          />
                        </div>
                      )}
                    </TableCell>
                    <TableCell className="position">
                      <div className="container-title-images">
                        <SortIcon
                          text={"Headline"}
                          sort={"occupation"}
                          idx={"headline"}
                          isFiltering={props.isFilteringModal}
                          setIsFiltering={props.setIsFilteringModal}
                          isSortedAsc={props.isIconActiveModal}
                          setIsSortedAsc={props.setActiveFilterIconModal}
                        />
                      </div>
                    </TableCell>
                    <TableCell className="account">
                      <div className="container-title-images">
                        <SortIcon
                          text={"Location"}
                          sort={"location"}
                          idx={"location"}
                          isFiltering={props.isFilteringModal}
                          setIsFiltering={props.setIsFilteringModal}
                          isSortedAsc={props.isIconActiveModal}
                          setIsSortedAsc={props.setActiveFilterIconModal}
                        />
                      </div>
                    </TableCell>
                    <TableCell className="connection">
                      <div className="container-title-images">
                        <SortIcon
                          text={"Last Action"}
                          sort={"contacted"}
                          idx={"contacted"}
                          isFiltering={props.isFilteringModal}
                          setIsFiltering={props.setIsFilteringModal}
                          isSortedAsc={props.isIconActiveModal}
                          setIsSortedAsc={props.setActiveFilterIconModal}
                        />
                      </div>
                    </TableCell>
                    {props.showSteps && (
                      <TableCell className="steps">Status</TableCell>
                    )}

                    <TableCell className="dateAccent">
                      {" "}
                      <div className="container-title-images-accent">
                        <SortIcon
                          text={"Imported"}
                          sort={"imported"}
                          idx={"imported"}
                          isFiltering={props.isFilteringModal}
                          setIsFiltering={props.setIsFilteringModal}
                          isSortedAsc={props.isIconActiveModal}
                          setIsSortedAsc={props.setActiveFilterIconModal}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody ref={wrapper}>
                  {contactsData?.map((row, index) => (
                    <TableRow key={row.id} className="row">
                      {props.checkboxs &&
                        (!props.campaignStatus ||
                          props.campaignStatus === "Compose") && (
                          <TableCell padding="checkbox" className="checkbox">
                            <div className="checkbox-container">
                              <Checkbox
                                onClick={(event) => handleClick(event, row.id)}
                                checked={isSelected(row.id)}
                                inputProps={{
                                  "aria-labelledby": `enhanced-table-checkbox-${index}`,
                                }}
                                icon={
                                  <img
                                    src={notSelectedCheckboxIcon}
                                    alt="checkbox"
                                  />
                                }
                                checkedIcon={
                                  <img
                                    src={selectedCheckboxIcon}
                                    alt="checkbox"
                                  />
                                }
                              />
                            </div>
                          </TableCell>
                        )}

                      <TableCell className="name">
                        <div className="name-container">
                          <img
                            className="avatar"
                            src={row.avatar ? row.avatar : avatarSvg}
                            alt="avatar"
                          />
                          <div className="profile">
                            <span className="text-page first-name semi-bold">
                              {row.fullName}
                            </span>
                          </div>
                          {functions.numberWithEnding(row.connection)}
                          <div
                            className={`text-page account regular ${
                              row.account ? "premium" : "free"
                            }`}
                          >
                            {row.account && (
                              <img src={inIcon} alt={"premium"} />
                            )}
                          </div>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="#"
                            onClick={async (e) => {
                              e.preventDefault();
                              await openLinkedInProfile(row);
                            }}
                          >
                            <TooltipCustom
                              title="Open LinkedIn profile"
                              arrow={true}
                            >
                              <img
                                className="link-img-accent "
                                src={linkSvgGold}
                                alt="link svg"
                              />
                            </TooltipCustom>
                          </a>
                        </div>
                      </TableCell>
                      <TableCell className="text-page position regular">
                        <TooltipCustom
                          title={row.position?.length > 50 ? row.position : ""}
                          arrow={true}
                        >
                          <div
                            className={
                              row.location?.length > 50
                                ? "ellipsis"
                                : "left-list-text"
                            }
                          >
                            {row.position}
                          </div>
                        </TooltipCustom>
                      </TableCell>
                      <TableCell
                        className={`text-page account regular ${
                          row.account ? "premium" : "free"
                        }`}
                      >
                        <TooltipCustom
                          title={row.location?.length > 20 ? row.location : ""}
                          arrow={true}
                        >
                          <div className="flex-container-tooltip-accent">
                            <div
                              className={
                                row.location?.length > 20
                                  ? "ellipsis"
                                  : "left-list-text"
                              }
                            >
                              {row.location}
                            </div>
                          </div>
                        </TooltipCustom>
                      </TableCell>
                      <TableCell className="connection">
                        <div className="flex-container-tooltip">
                          <div className="row-created">
                            {functions.parseDateToDMY(row.contacted, "/")}
                          </div>
                        </div>
                      </TableCell>
                      {props.showSteps && (
                        <TableCell className="steps">
                          <div className="steps-container">
                            {row.campaignExecutions &&
                              renderSteps2(
                                row.campaignExecutions.step_executions,
                                row.campaignExecutions
                              )}
                          </div>
                        </TableCell>
                      )}

                      <TableCell
                        className={generationClassName(scrollBarWidth)}
                        style={{
                          paddingRight: scrollBarWidth ? "6px" : "18px",
                        }}
                      >
                        <div className="date-container single-action">
                          <div className="start-wrapper">
                            <div className="start-end-date">
                              {functions.parseDateToDMY(row.date, "/")}
                            </div>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="table-footer-modal">
              <div className="table-footer-pagination">
                {contactsCount && contactsCount > initialValuePagination ? (
                  <div className="container-table-pagination">
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[]}
                      count={contactsCount}
                      rowsPerPage={initialValuePagination}
                      page={
                        contactsCount % initialValuePagination === 0 &&
                        page === contactsCount / initialValuePagination &&
                        page > 0
                          ? page - 1
                          : page
                      }
                      onPageChange={handleChangePage}
                      ActionsComponent={(subProps) => (
                        <MTablePagination {...subProps} />
                      )}
                    />
                  </div>
                ) : null}
              </div>
              <div
                className={
                  contactsCount &&
                  contactsCount &&
                  contactsCount > initialValuePagination
                    ? "wrapper-select"
                    : "wrapper-select-accent-modal"
                }
              >
                <Select
                  onFocus={() => setIsOpenMenuPagination(true)}
                  onBlur={() => setIsOpenMenuPagination(false)}
                  options={optionsSizePagination}
                  onChange={(e) => {
                    setInitialValuePagination(Number(e.value));
                    localStorage.setItem(
                      "contactsPageSize",
                      JSON.stringify(Number(e.value))
                    );
                  }}
                  value={optionsSizePagination.find(
                    (el) => Number(el.value) === initialValuePagination
                  )}
                  styles={stylePagination}
                />
              </div>
            </div>
          </div>
        )}
        {!tableContentLoader && contactsCount === 0 && !isError && (
          <>
            {isFilterApplied ? (
              <NoSearchContacts />
            ) : (
              <NoContactsYetTemplate isModal={true} />
            )}
          </>
        )}
      </div>
      <div className={`filters-modal ${!filtersController ? "hide" : ""}`}>
        <div className="filters-modal__wrapper">
          <Filters
            loadHandlerForModal={setTableContentLoader}
            show={filtersController}
            from={"addToCampaignOfModal"}
            activeImportId={props.campaignId}
            filters={props.filters}
            getContacts={getContacts}
            page={page}
            pageHandler={setPage}
            filtersControllerByParams={filtersControllerByParams}
            filtersControllerToggle={() =>
              setFiltersController(!filtersController)
            }
            clearFilterData={clearFilterData}
            clearFilterController={setClearFilterData}
            history={props.history}
            keywordsForModal={props.keywordsForModal}
            orderby={props.isFilteringModal}
          />
        </div>
      </div>
    </div>
  );
};

export default AddContactsModal;

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
};
