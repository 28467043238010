import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Element } from "react-scroll";
import PropTypes from "prop-types";
import SupportButton from "../../atoms/SupportButton";
// Tools
// import mathFuctions from "../../../tools/mathFunctions";
// Action api
import { connect } from "react-redux";
// Components
import Header from "../../organisms/Header/index";

import LeftSideMenu from "../../molecules/leftSideMenu";
// Style
import "./style.scss";
import { CONSTS } from "../../../config/objectConst.js";

export class HomeTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="wrapper">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{CONSTS.PROJECT_NAME}</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
        <Header history={this.props.history} />
        <main className="home-template">
          <LeftSideMenu />
          <Element
            name="container"
            className={
              this.props?.location?.pathname.includes("activities-queue") ||
              this.props?.location?.pathname.includes("team")
                ? "container"
                : "containerAccent"
            }
            id={`${this.props.id ?? "homeTemplateContainerID"}`}
          >
            {this.props.children}
          </Element>
          <SupportButton />
        </main>
      </div>
    );
  }
}
/*
  propTypes checked props values
  example when lang - string
 */
HomeTemplate.propTypes = {
  text: PropTypes.object,
};

const mapStateToProps = function (state) {
  return {
    home: state.home,
  };
};

export default connect(mapStateToProps)(withRouter(HomeTemplate));
