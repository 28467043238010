import React, { Component } from "react";
// Image, Icons
import imageDog from "../../../assets/image/snail-looking-for.png";
// Style
import "./style.scss";
export class NoFilterCampaignsTemplate extends Component {
  render() {
    return (
      <div className="no-filter-template">
        <div className="no-filter-template-body">
          <span className="h-2">Ooops… Nothing was found!</span>
          <img src={imageDog} alt="snail" />
          <p className="h-5 additional-text">
            Please, change your search query or status filter to get results.
          </p>
        </div>
      </div>
    );
  }
}

export default NoFilterCampaignsTemplate;
