import React, { useState, useEffect } from "react";
import "./style.scss";
import { user } from "../../../actions/user";
import Performed from "../../atoms/Performed";
import { useSelector } from "react-redux";
import LinkedInBlock from "../../atoms/LinkedInBlock";
import { Loader } from "semantic-ui-react";
import DailyLimitsEditor from "../dailyLimitsEditor";
import SelectTimeZone from "../SelectTimeZone";
import { DateRangePicker } from "react-date-range";
import ResponsiveButton from "../../atoms/responsiveButton";
import calendar from "../../../assets/image/icons/svg/calendar.svg";
import { functions } from "../../../tools/functions";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

const AccountProfile = ({
  name,
  getPeriodStatisticsAsync,
  setCustomStatistics,
  customStatistics,
  rangeState,
  setRangeState,
}) => {
  const appProfile = useSelector((state) => state.app.appProfile);
  const { connector } = useSelector((state) => state.app);
  const { profileFull } = useSelector((state) => state.user);
  const [isDataRange, setIsDataRange] = useState(false);
  const [loader, setLoader] = useState(false);

  const [rangeStateCurrent, setRangeStateCurrent] = useState([
    {
      startDate: new Date(
        functions.getToday(profileFull.appProfile.timezoneOffset).setDate(1)
      ),
      endDate: functions.getToday(profileFull.appProfile.timezoneOffset),
      key: "selection",
      color: "#0cb39f",
    },
  ]);

  const setDate = async () => {
    setRangeStateCurrent(rangeState);

    setLoader(true);
    try {
      const customStatistics = await user.getPeriodStatistics(
        rangeState[0].startDate,
        rangeState[0].endDate
      );
      setCustomStatistics(customStatistics);
    } finally {
      setIsDataRange(false);
      setLoader(false);
    }
  };

  useEffect(() => {
    getPeriodStatisticsAsync();

    connector.eventHandlers.addAutomationEndedEventHandler(() => {
      getPeriodStatisticsAsync();
      user.syncSnailyProfile();
    });

    return () => {
      connector.eventHandlers.removeAutomationEndedEventHandlers();
    };
  }, []);

  return (
    <div className="account-profile">
      <div className="account-profile__name">
        {appProfile?.impersonatedPersona?.givenName ? (
          <>
            {appProfile?.impersonatedPersona?.givenName}{" "}
            {appProfile?.impersonatedPerson?.familyName}
          </>
        ) : (
          name
        )}
      </div>
      {profileFull && profileFull.appProfile.email && (
        <div className="account-profile__email">
          {appProfile?.impersonatedPersona?.email
            ? appProfile?.impersonatedPersona?.email
            : profileFull.appProfile.email}
        </div>
      )}
      <div className="line-list">&nbsp;</div>
      <div className="account-profile__time-and"></div>
      <LinkedInBlock profile={profileFull} connector={connector} />

      <div className="linkedin__per-day">
        <div className="linkedin__per-day-select">
          <DailyLimitsEditor
            customStatistics={customStatistics}
            getPeriodStatisticsAsync={getPeriodStatisticsAsync}
          />
        </div>
      </div>

      <div className="line-list">&nbsp;</div>

      {isDataRange && (
        <div
          className="overlay"
          onClick={() => {
            setIsDataRange(false);
          }}
        />
      )}

      <div className="title-date-container">
        <p className="performed__title">Performed </p>
        <div className="container-images-range">
          <p className="images-range-text">
            {" "}
            {functions.parseDateToDMY(rangeStateCurrent[0].startDate, "/")} -
            {functions.parseDateToDMY(rangeStateCurrent[0].endDate, "/")}
          </p>

          <img
            src={calendar}
            className="calendar-img"
            width={14}
            alt="calendar"
            onClick={() => {
              setRangeState(rangeStateCurrent);
              setIsDataRange(!isDataRange);
            }}
          />
        </div>
        {isDataRange && (
          <div className="data-range-container">
            <DateRangePicker
              onChange={(item) => {
                setRangeState([item.selection]);
              }}
              weekStartsOn={1}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={rangeState}
              direction="horizontal"
              maxDate={functions.getToday(
                profileFull.appProfile.timezoneOffset
              )}
            />
            <div className="container-range-button">
              <button
                className="working-hours__btn working-hours__btn_with-bg"
                onClick={() => setIsDataRange(false)}
              >
                Cancel
              </button>
              <ResponsiveButton
                className="working-hours__btn working-hours__btn_with-bg"
                onClick={setDate}
                isLoading={loader}
              >
                {"Apply"}
              </ResponsiveButton>
            </div>
          </div>
        )}
      </div>

      <Performed statistics={customStatistics} />
    </div>
  );
};

export default AccountProfile;
