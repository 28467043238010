import React, { Component } from "react";
// Components
import Tag from "../../../components/atoms/Tag";
// Actions
import { filters } from "../../../actions/filters";
import { contacts } from "../../../actions/contacts";
import { app } from "../../../actions/app";
import TextFieldCustom from "../../atoms/TextFieldCustom";
import iconSearch from "../../../assets/image/icons/svg/search-icon-grey.svg";

export class FilterNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keywords: this.props.keywords,
    };
  }

  clearFilter = async (item) => {
    if (this.props.from === "addToCampaignOfModal") {
      await filters.clearFilter(item, this.props.from);
      let filtersQuery = filters.buildFilterQuery(
        this.props.filters.filterDataForModal
      );
      if (filtersQuery) {
        filtersQuery = `not (campaigns/any(c:c eq '${this.props.activeImportId}')) and ${filtersQuery}`;
      } else {
        filtersQuery = `not (campaigns/any(c:c eq '${this.props.activeImportId}'))`;
      }
      const searchQuery = filters.buildSearchQuery(
        this.props.filters.filterDataForModal
      );
      filters.changeFilterQuery(filtersQuery, searchQuery);
      const data = {
        skip: 0,
        filter: filtersQuery,
        keywords: this.props.keywords,
      };
      this.props.pageHandler(0);
      this.props.getContacts(data);
    } else {
      app.changeTableContentLoaderStatus(true);
      await filters.clearFilter(item);
      contacts.changeContactsPage(0);
      filters.getContacts(
        this.props.filters.filterData,
        this.props.activeImportId,
        this.props.from,
        this.props.page,
        this.props.keywords
      );
    }
  };

  clearAllFilters = async () => {
    if (this.props.from === "addToCampaignOfModal") {
      filters.clearAllFilters(true);
      filters.changeFilterQuery(
        `not (campaigns/any(c:c eq '${this.props.activeImportId}'))`,
        ""
      );
      this.props.pageHandler(0);
      this.props.getContacts({
        skip: 0,
        filter: `not (campaigns/any(c:c eq '${this.props.activeImportId}'))`,
        keywords: this.props.keywords,
      });
      this.props.clearFilterController(true);
    } else {
      const filterData = filters.clearAllFilters();
      app.changeTableContentLoaderStatus(true);
      contacts.changeContactsPage(0);
      filters.getContacts(
        filterData,
        this.props.activeImportId,
        this.props.from,
        this.props.page,
        this.props.keywords
      );
      this.props.clearFilterController(true);
    }
  };

  onSetKeywords = () => {
    if (this.props.keywords !== this.state.keywords) {
      this.props.setKeywords(this.state.keywords);
    }
  };

  render() {
    const filtersData =
      this.props.from === "addToCampaignOfModal"
        ? this.props.filters.filterDataForModal
        : this.props.filters.filterData;
    const tagsArray = filtersData.filter(
      (item) => item.tag && item.tag.length > 0
    );
    return (
      <div
        className={`filter-navigation ${
          this.props.filtersController ? "open" : ""
        }`}
      >
        <div className="filter-navigation__filters">
          {this.props.filtersController === false && (
            <div className="container_textField">
              <TextFieldCustom
                value={this.state.keywords}
                onChange={(e) => this.setState({ keywords: e.target.value })}
                placeholder={"Search"}
                onEnter={this.onSetKeywords}
                onBlur={this.onSetKeywords}
                endAdornment={
                  <img
                    src={iconSearch}
                    alt="search"
                    onClick={this.onSetKeywords}
                    className="icon-search"
                  />
                }
              />
            </div>
          )}
          <button
            type="button"
            className="btn-text-icon filter-icon"
            onClick={this.props.filtersControllerToggle}
          >
            Filters
          </button>

          {!this.props.filtersController && (
            <div className="__tags-wrap">
              <div className="filter-navigation__tags">
                {tagsArray.map((item, index) => {
                  if (index <= 1) {
                    return (
                      <Tag
                        key={item.id}
                        type="small"
                        text={item.tag}
                        onClick={() => this.clearFilter(item)}
                      />
                    );
                  }
                  return "";
                })}
                {tagsArray.length > 2 &&
                  this.props.showAll &&
                  tagsArray.map((item, index) => {
                    if (index >= 2) {
                      return (
                        <Tag
                          key={item.id}
                          type="small"
                          text={item.tag}
                          onClick={() => this.clearFilter(item)}
                        />
                      );
                    }
                    return "";
                  })}
              </div>
              <div className="filter-navigation__controls">
                {tagsArray.length > 2 && !this.props.showAll && (
                  <>
                    <button
                      type="button"
                      className="filter-navigation__button"
                      onClick={() => this.props.setShowAll(true)}
                    >
                      Show more
                    </button>
                    <div className="vertical-divider"></div>
                  </>
                )}
                {tagsArray.length > 0 && (
                  <button
                    type="button"
                    className="filter-navigation__button"
                    onClick={this.clearAllFilters}
                  >
                    Clear all
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
        {this.props.from === "contacts" && (
          <button
            className="btn-text-icon add-icon"
            onClick={this.props.handlerExportModal(true)}
          >
            <div className="upload-icon-container">Add contacts</div>
          </button>
        )}
      </div>
    );
  }
}

export default FilterNavigation;
