import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./style.scss";
// Components
import HomeTemplate from "../../components/templates/homeTemplate";
import TableActivity from "../../components/molecules/tableActivity";
import { notification } from "../../actions/notification";
// Action
import { app } from "../../actions/app";
import { campaign } from "../../actions/campaign";
import { activity } from "../../actions/activity";
import { CircularProgress } from "@material-ui/core";
import ErrorNotification from "../../components/atoms/ErrorNotification";
import ExceededLimitNotification from "../../components/atoms/ExceededLimitNotification";
import WorkingHoursNotification from "../../components/atoms/WorkingHoursNotification";
import { listeners } from "../../api/listeners";
import { linkedInProfileOpener } from "../../api/linkedInProfileOpener";

export class ActivityPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filtersController: false,
      contactsTitle: "",
      importObject: {},
      isInWorkHours: null,
    };
    this.connector = listeners.getConnector();
  }

  componentDidMount() {
    this.getWorkingHours();
    app.changeActiveItem(2);
    campaign.getRecentCampaigns();
    activity.getActivities();

    this.connector.eventHandlers.addAutomationEndedEventHandler(() => {
      campaign.getRecentCampaigns();
      activity.getActivities(true);
    });
  }

  componentWillUnmount() {
    this.connector.eventHandlers.removeAutomationEndedEventHandlers();
  }

  openLinkedinProfile = async () => {
    const connector = listeners.getConnector();
    await linkedInProfileOpener.open(connector, this.props.profile.linkedInfo);
  };
  getWorkingHours = async () => {
    try {
      const workingHours = await this.connector.automation.isInWorkingHours();
      this.setState({
        isInWorkHours: workingHours,
      });
    } catch (e) {
      notification.notificationUse(e);
      this.setState({
        isInWorkHours: true,
      });
    }
  };

  render() {
    return (
      <HomeTemplate>
        {/*<HeaderTemplate labelName="Activities queue"></HeaderTemplate>*/}
        {this.props.app.pageContentLoader && (
          <div className="material-loader-wrapper">
            <CircularProgress
              color="primary"
              size={60}
              classes={{ colorPrimary: "material-loader" }}
            />
          </div>
        )}
        {!this.props.app.pageContentLoader && (
          <>
            <div className="activity-container">
              <p className="activity-container__title">Activities queue</p>
            </div>

            {!this.props.app.linkedInAuth ||
            !this.props.app.associatedLinkedInProfile ? (
              <ErrorNotification>
                <div className="error-notification__not-auth">
                  You are not logged in to LinkedIn with{" "}
                  <span onClick={this.openLinkedinProfile}>
                    {`${this.props.profile.linkedInfo.givenName} ${this.props.profile.linkedInfo.familyName} account.`}
                  </span>
                </div>
              </ErrorNotification>
            ) : this.props.app.appProfile?.exceedLinkedInInvitationsLimit ? (
              <ExceededLimitNotification>
                <div className="exceeded-notification__limit">
                  <span>
                    The weekly limit for sending invitations has been exceeded
                  </span>
                </div>
              </ExceededLimitNotification>
            ) : (
              !this.state.isInWorkHours &&
              this.state.isInWorkHours !== null && (
                <WorkingHoursNotification>
                  <div className="exceeded-notification__limit">
                    <span>
                      The activities will starts upon your working hours
                    </span>
                  </div>
                </WorkingHoursNotification>
              )
            )}

            <TableActivity
              classesContainer={"activity-container"}
              type="activity"
              contacts={this.props.contacts}
              showSteps={false}
              checkboxs={false}
              history={this.props.history}
              app={this.props.app}
              profile={this.state.profile}
            />
          </>
        )}
      </HomeTemplate>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    app: state.app,
    tableContentLoader: state.app.tableContentLoader,
    filters: state.filters,
    contacts: state.activity,
    leftSide: state.leftSideMenu.leftSide,
    profile: state.user.profileFull,
  };
};

export default connect(mapStateToProps)(withRouter(ActivityPage));
