/**
 * Create the store with asynchronously loaded reducers
 */

import { compose, createStore } from "redux";
import rootReducer from "./reducers/index";

const composeSetup =
  process.env.NODE_ENV !== "production" &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const store = createStore(rootReducer, composeSetup());

export default store;
