import React, { useState } from "react";
import linkIcon from "../../../assets/image/icons/svg/icons/Link.svg";
import linkIconAccent from "../../../assets/image/icons/svg/icons/linkIconAccent.svg";

import { useSelector } from "react-redux";

import "./style.scss";
import { linkedInProfileOpener } from "../../../api/linkedInProfileOpener";

const LinkedInBlock = ({ profile, connector }) => {
  const [loadedPicture, setLoadedPicture] = useState(false);
  const appProfile = useSelector((state) => state.app.appProfile);

  const openLinkedinProfile = async () => {
    await linkedInProfileOpener.open(connector, profile.linkedInfo);
  };
  const openLinkedinProfilePersona = async () => {
    await linkedInProfileOpener.open(
      connector,
      appProfile.impersonatedPersona.linkedInProfile
    );
  };

  return (
    <div className="linkedin">
      <div className="linkedin__title">
        LinkedIn profile
        {appProfile?.impersonatedPersona ? (
          <div className="linkedin__account-accent">
            <div className="linkedin__image">
              {appProfile.impersonatedPersona.linkedInProfile.pictureUrl && (
                <img
                  src={
                    appProfile.impersonatedPersona.linkedInProfile.pictureUrl
                  }
                  alt="avatar"
                  className={!loadedPicture ? "hidden-block" : ""}
                  onLoad={() => setLoadedPicture(true)}
                />
              )}
              {!appProfile.impersonatedPersona.linkedInProfile.pictureUrl && (
                <div className="fake-img qwe" />
              )}
              {appProfile.impersonatedPersona.linkedInProfile.pictureUrl &&
                !loadedPicture && <div className="fake-img test" />}
            </div>
            <div
              className="linkedin__link"
              onClick={openLinkedinProfilePersona}
            >
              <img src={linkIconAccent} alt="linkIcon" />
              <span className="text-accent">
                {appProfile.impersonatedPersona.givenName}{" "}
                {appProfile.impersonatedPersona.familyName}
              </span>
            </div>
          </div>
        ) : (
          <div className="linkedin__account">
            <div className="linkedin__image">
              {profile.linkedInfo.pictureUrl && (
                <img
                  src={profile.linkedInfo.pictureUrl}
                  alt="avatar"
                  className={!loadedPicture ? "hidden-block" : ""}
                  onLoad={() => setLoadedPicture(true)}
                />
              )}
              {!profile.linkedInfo.pictureUrl && (
                <div className="fake-img qwe" />
              )}
              {profile.linkedInfo.pictureUrl && !loadedPicture && (
                <div className="fake-img test" />
              )}
            </div>
            <div className="linkedin__link" onClick={openLinkedinProfile}>
              <img src={linkIcon} alt="linkIcon" />
              <span>
                {profile.linkedInfo.givenName} {profile.linkedInfo.familyName}
              </span>
            </div>
          </div>
        )}
        <div className="line__account"></div>
      </div>
    </div>
  );
};

export default LinkedInBlock;
