import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { teams } from "../../actions/teams";
import Notification from "../molecules/notification";
import ModalVerified from "../molecules/ModalVerified";
import ModalVerifiedError from "../molecules/ModalVerifiedError";
import { listeners } from "../../api/listeners";
import { CircularProgress } from "@material-ui/core";

export class InvitationCallback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      showPreloader: false,
      code: "",
      showNotification: false,
      response: "",
      error: "",
      isLoadingAcceptInvitation: false,
    };
    this.intervalAction = null;
  }

  componentDidMount() {
    this.setState({
      code: this.props.location.search.slice(
        6,
        this.props.location.search.length
      ),
    });
  }
  componentDidUpdate(prevProps, prevState) {
    this.state.code !== prevState.code &&
      this.acceptInvitation(this.state.code);
  }

  acceptInvitation = async (code) => {
    this.setState({ isLoadingAcceptInvitation: true });
    this.setState({ showPreloader: true });
    try {
      const response = await teams.acceptInvitation(code);
      this.setState({ response: response });
      if (response?.status === 200) {
        this.setState({ showNotification: true });
      }
      const connector = listeners.getConnector();
      await connector.auth.refreshAccessToken();
    } catch (error) {
      this.setState({ error: error.message });
    } finally {
      this.setState({ showPreloader: false });
      this.setState({ isLoadingAcceptInvitation: false });
    }
  };

  componentWillUnmount() {}

  render() {
    return (
      <>
        {this.state.showNotification && this.state.response?.status === 200 ? (
          <ModalVerified />
        ) : (
          <ModalVerifiedError
            errorText={this.state.error}
            isLoadingAcceptInvitation={this.state.isLoadingAcceptInvitation}
          />
        )}
        <div className="auth-call-back-preloader">
          <div className="auth-call-back-preloader-header">
            {this.props.notification.status && (
              <Notification
                text={this.props.notification.text}
                error={this.props.notification.error}
              />
            )}
          </div>
          <div className="auth-call-back-preloader-body">
            {this.state.showPreloader && (
              <>
                <div className="table-material-loader-wrapper">
                  <CircularProgress
                    color="primary"
                    size={60}
                    classes={{ colorPrimary: "material-loader" }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    connector: state.app.connector,
    notification: state.notification,
    auth: state.app.auth,
  };
};

export default connect(mapStateToProps)(withRouter(InvitationCallback));
