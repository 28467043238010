import React, { Component } from "react";

// import _ from "lodash";
// Styles
import "./style.scss";

export class StatisticList extends Component {
  render() {
    return (
      <div className={`statistic-list ${this.props.className}`}>
        {this.props.statistics.map((item) => {
          return (
            <div className="statistic-list-item">
              <div className="statistic-list-item-label text-page semi-bold">
                <div
                  className="status"
                  style={{ backgroundColor: `${item.color}` }}
                ></div>
                {item.title}
              </div>
              <div className="statistic-list-item-value text-page semi-bold">
                {item.value}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default StatisticList;
