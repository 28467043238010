import React, { useState, useEffect } from "react";
import Select from "react-select";
import ResponsiveButton from "../../atoms/responsiveButton";
import { teams } from "../../../actions/teams";
import { CONSTS } from "../../../config/objectConst";
import { notification } from "../../../actions/notification";
import { api } from "../../../api/api";
import snailyVerified from "../../../assets/image/snail-verified.png";
import close from "../../../assets/image/icons/svg/icon-close.svg";
import information from "../../../assets/image/icons/svg/information.svg";
import "./style.scss";

const ModalSubscription = ({
  dataPersona,
  setIsModalSubscriptionOpen,
  getMembers,
}) => {
  const initialOptions = [
    {
      value: CONSTS.SUBSCRIPTIONS.TRIAL.ID,
      planName: CONSTS.SUBSCRIPTIONS.TRIAL.NAME,
      label: CONSTS.SUBSCRIPTIONS.TRIAL.NAME,
    },
    {
      value: CONSTS.SUBSCRIPTIONS.PRO.ID,
      planName: CONSTS.SUBSCRIPTIONS.PRO.NAME,
      label: `${CONSTS.SUBSCRIPTIONS.PRO.NAME} ${CONSTS.SUBSCRIPTIONS.PRO.PRICE}$ / month`,
    },
    {
      value: CONSTS.SUBSCRIPTIONS.BASIC.ID,
      planName: CONSTS.SUBSCRIPTIONS.BASIC.NAME,
      label: `${CONSTS.SUBSCRIPTIONS.BASIC.NAME} ${CONSTS.SUBSCRIPTIONS.BASIC.PRICE}$ / month`,
    },
    {
      value: CONSTS.SUBSCRIPTIONS.TEAM.ID,
      planName: CONSTS.SUBSCRIPTIONS.TEAM.NAME,
      label: `${CONSTS.SUBSCRIPTIONS.TEAM.NAME} ${CONSTS.SUBSCRIPTIONS.TEAM.PRICE}$ / month`,
    },
  ];
  const [isLoadingPaymentMethods, setIsLoadingPaymentMethods] = useState(false);
  const [isLoadingUpgrade, setIsLoadingUpgrade] = useState(false);
  const [isError, setIsError] = useState(null);
  const options = [
    {
      value: CONSTS.SUBSCRIPTIONS.BASIC.ID,
      label: `${CONSTS.SUBSCRIPTIONS.BASIC.NAME} ${CONSTS.SUBSCRIPTIONS.BASIC.PRICE}$ / month`,
    },
    {
      value: CONSTS.SUBSCRIPTIONS.PRO.ID,
      label: `${CONSTS.SUBSCRIPTIONS.PRO.NAME} ${CONSTS.SUBSCRIPTIONS.PRO.PRICE}$ / month`,
    },
    {
      value: CONSTS.SUBSCRIPTIONS.TEAM.ID,
      label: `${CONSTS.SUBSCRIPTIONS.TEAM.NAME} ${CONSTS.SUBSCRIPTIONS.TEAM.PRICE}$ / month`,
    },
  ];
  const [isSelectedPlan, setIsSelectedPlan] = useState(false);

  let findPlan = function () {
    let result = initialOptions.find(
      (el) => el.value === dataPersona.subscription.plan_id
    );
    if (!result) {
      result = {
        value: dataPersona.subscription.plan_id,
        label: `${dataPersona.subscription.plan_id} ${dataPersona.subscription.actual_price} $ / month`,
      };
    }

    return result;
  };

  const [plan, setPlan] = useState(findPlan());
  const [currentPlan, setCurrentPlan] = useState(findPlan());
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const [coupon, setCoupon] = useState("");

  useEffect(() => {
    setCurrentPlan(plan);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsError(null);
    }, 10000);
  }, [isError]);

  const isNotActivePaidPlan = () => {
    let result =
      !dataPersona.subscription.is_active ||
      dataPersona.subscription.plan_id === CONSTS.SUBSCRIPTIONS.TRIAL.ID;
    return result;
  };
  const upgradeSubscriptionPersona = async () => {
    setIsLoadingUpgrade(true);
    let response;
    try {
      if (isNotActivePaidPlan()) {
        response = await teams.buyMemberSubscription(
          dataPersona.id,
          plan.value,
          coupon
        );
      } else {
        response = await teams.upgradeMemberSubscription(
          dataPersona.id,
          plan.value,
          coupon
        );
      }
      if (response?.status === 200) {
        setIsModalSubscriptionOpen(false);
        await getMembers();
        notification.snacknotificationUse({
          open: true,
          title: CONSTS.notification["n3.26"].title,
          content: CONSTS.notification["n3.26"].content,
          style: CONSTS.notification["n3.26"].style,
        });
      }
    } catch (error) {
      setIsError(error?.message);
    } finally {
      setIsLoadingUpgrade(false);
    }
  };

  const getCurrentPlanDescription = () => {
    let result;
    if (!isNotActivePaidPlan()) {
      result = currentPlan.label;
    } else {
      if (!dataPersona.subscription.is_active) {
        result = "Expired";
      } else {
        result = "Trial(free)";
      }
    }

    return result;
  };

  const style = {
    control: (base, state) => {
      return {
        ...base,
        "&:hover": {
          border: isOpenSelect ? "1px solid #0cb39f" : "1px solid  #EBEDF2",
        },

        border: isOpenSelect ? "1px solid #0cb39f" : "1px solid  #EBEDF2",
        boxShadow: "",
        fontWeight: 700,
        fontSize: 14,
        background: "#FFF",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        opacity: "1",
        color: "#202020",
        width: "100%",
        cursor: "pointer",
        minHeight: "48px",
      };
    },
    indicatorsContainer: (base) => ({
      ...base,

      borderLeft: isOpenSelect ? "1px solid #0cb39f" : "1px solid  #EBEDF2",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      backgroundColor: "transparent",
      paddingTop: "10px",
      paddingBottom: "10px",
    }),
    singleValue: (base) => ({
      ...base,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: "10px   5px",
      height: "48px",
      color: "#202020",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "48px",
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      height: "48px",
      color: "#000",
      paddingTop: "15px",
      fontWeight: 700,
      fontSize: 14,
      backgroundColor: isSelected ? "#F7F7F7" : "transparent",
      "&:active": {
        backgroundColor: "transparent",
      },
      "&:hover": { backgroundColor: "#F7F7F7", cursor: "pointer" },
    }),
    menuList: (base) => ({
      ...base,
    }),
    menu: (base) => ({
      ...base,
      border: "1px solid #0cb39f",
      boxShadow: "",
      marginTop: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderTop: 0,
    }),
  };
  return (
    <div className="modal-personas">
      {isError && (
        <div className="coupon-error">
          <p className="coupon-error-text">{isError}</p>
        </div>
      )}
      <img alt="snail" src={snailyVerified} className="modal-personas__img" />
      <img
        alt="icon-close"
        src={close}
        className="modal-personas__img-close"
        onClick={() => setIsModalSubscriptionOpen(false)}
      />
      <div className="modal-personas__top-container">
        {isNotActivePaidPlan() ? (
          <h2 className="modal-personas__title">Activate subscription</h2>
        ) : (
          <h2 className="modal-personas__title">Upgrade subscription</h2>
        )}

        <div className="modal-personas__container-input">
          <ResponsiveButton
            className="modal-personas__button-payment"
            onClick={async () => {
              setIsLoadingPaymentMethods(true);
              try {
                const response = await api.getManagementUrl();
                if (!response) {
                  return;
                }
                if (response.status === 200) {
                  window.open(response.data);
                }
              } catch (error) {
                notification.notificationUse(error);
              } finally {
                setIsLoadingPaymentMethods(false);
              }
            }}
            isLoading={isLoadingPaymentMethods}
          >
            {"Manage payment methods"}
          </ResponsiveButton>
          {isNotActivePaidPlan() && (
            <input
              className="mail-input-personas"
              type="mail"
              placeholder="Enter your promocode"
              value={coupon}
              onChange={(e) => setCoupon(e.target.value)}
            ></input>
          )}
        </div>
      </div>
      <div className="modal-personas__container-bottom">
        <p className="modal-personas__description">Selected account:</p>
        <div className="modal-personas__current-data">
          <div className="modal-personas__container-description-selected-account">
            {" "}
            {dataPersona.avatar && (
              <img
                className="modal-personas__avatar"
                src={dataPersona.avatar}
                alt="avatar"
              />
            )}
            {!dataPersona.avatar && <div className="out-avatar" />}
            <div className="profile text-box-wrapper">
              <div className="text-warning">{dataPersona.nickname}</div>
            </div>
          </div>
          <div className="modal-personas__current-plan">
            <span className="modal-personas__text">Current plan:</span>{" "}
            <span className="modal-personas__text-plan">
              {getCurrentPlanDescription()}
            </span>
          </div>
        </div>
        <p className="modal-personas__description">
          Choose the plan you would like to activate.
        </p>

        <Select
          onFocus={() => setIsOpenSelect(true)}
          onBlur={() => setIsOpenSelect(false)}
          options={options.filter(
            (plan) =>
              plan.value !== currentPlan.value ||
              !dataPersona.subscription.is_active
          )}
          onChange={(newValue) => {
            setPlan(newValue);
            setIsSelectedPlan(true);
          }}
          placeholder="Choose a plan"
          styles={style}
        />

        {isSelectedPlan && (
          <div className="container-text-warning">
            <img alt="information" src={information} />
            <p className="text-warning">
              The account will be upgraded to {plan.value.toUpperCase()} and the
              difference will be deducted from the next payment
            </p>
          </div>
        )}

        <ResponsiveButton
          className="btn bold invite-button"
          onClick={() => {
            upgradeSubscriptionPersona();
          }}
          disabled={!isSelectedPlan}
          isLoading={isLoadingUpgrade}
        >
          {isNotActivePaidPlan() ? "Activate" : "Upgrade"}
        </ResponsiveButton>
      </div>
    </div>
  );
};

export default ModalSubscription;
