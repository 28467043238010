class RequestTimedOutError extends Error {
  constructor(message) {
    super(message);
    this.name = "RequestTimedOutError";

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RequestTimedOutError);
    }
  }

  get skipLogging() {
    return false;
  }
}

export default RequestTimedOutError;
