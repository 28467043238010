import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import store from "../../redux/store";
// Components
import HomeTemplate from "../../components/templates/homeTemplate";
import PersonalCampaignsStatistics from "../../components/molecules/personalCampaignsStatistics";

// Actions
import { app } from "../../actions/app";
import { campaign } from "../../actions/campaign";
import { mapping } from "../../actions/mapping";
import NoCampaignsYetTemplate from "../../components/templates/noCampaignsYetTemplate";
import { CircularProgress } from "@material-ui/core";
import { filters } from "../../actions/filters";
import NoFilterCampaignsTemplate from "../../components/templates/noFilterCampaignsTemplate";
import CampaignFilters from "../../components/molecules/CampaignFilters";
import ErrorNotification from "../../components/atoms/ErrorNotification";
import { listeners } from "../../api/listeners";
import { linkedInProfileOpener } from "../../api/linkedInProfileOpener";

export class CampaignsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderby: "created desc",
      modalController: false,
      companyName: "",
      campaignId: null,
      campaignsLoading: false,
      isIconActiveModal: {
        contact: true,
        headline: true,
        account: true,
        location: true,
        contacted: true,
        imported: false,
      },
      isSearchOrFilterApplied: false,
      tableCampaignsStatisticsPage: 0,
    };
    this.connector = listeners.getConnector();
  }

  async componentDidMount() {
    app.changePageContentLoaderStatus(true);
    this.setState({ campaignsLoading: true });
    try {
      filters.clearAllFilters(true);
      filters.changeFilterQuery("", false);
      const filtersArray = this.buildFiltersArray(this.props.filters);
      app.changePage("/campaigns");
      app.changeActiveItem(1);

      await filters.getCampaigns({
        archived: false,
        filter: `${filtersArray.join(" or ")}`,
        keywords: this.props.searchValue,
        orderby: this.state.orderby,
      });

      await campaign.getRecentCampaigns();
    } finally {
      this.setState({ campaignsLoading: false });
      app.changePageContentLoaderStatus(false);
    }
  }

  componentWillUnmount() {
    this.connector.eventHandlers.removeAutomationEndedEventHandlers();
    store.dispatch({
      type: "SEARCH_VALUE",
      payload: {
        searchValue: "",
      },
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.campaignId &&
      prevState.campaignId !== this.state.campaignId
    ) {
      filters.clearAllFilters(true);
      filters.changeFilterQuery("", false);
    }
  }

  buildFiltersArray = (filters) => {
    const filtersArray = [];
    if (filters.active) {
      filtersArray.push(`(state eq 'Running')`);
    }
    if (filters.stopped) {
      filtersArray.push(`(state eq 'Stopped')`);
    }
    if (filters.completed) {
      filtersArray.push(`(state eq 'Completed')`);
    }
    if (filters.drafted) {
      filtersArray.push(`(state eq 'Compose')`);
    }

    return filtersArray;
  };

  openLinkedinProfile = async () => {
    const connector = listeners.getConnector();
    await linkedInProfileOpener.open(connector, this.props.profile.linkedInfo);
  };

  render() {
    return (
      <HomeTemplate id="campaigns">
        {(!this.props.app.linkedInAuth ||
          !this.props.app.associatedLinkedInProfile) && (
          <ErrorNotification>
            <div className="error-notification__not-auth">
              You are not logged in to LinkedIn with{" "}
              <span onClick={this.openLinkedinProfile}>
                {`${this.props.profile.linkedInfo.givenName} ${this.props.profile.linkedInfo.familyName} account.`}
              </span>
            </div>
          </ErrorNotification>
        )}
        {(this.props.app.pageContentLoader || this.state.campaignsLoading) && (
          <div className="material-loader-wrapper">
            <CircularProgress
              color="primary"
              size={60}
              classes={{ colorPrimary: "material-loader" }}
            />
          </div>
        )}

        {!this.props.haveCampaigns &&
        !this.props.error &&
        !this.props.leftSide.list?.length > 0 ? (
          <NoCampaignsYetTemplate />
        ) : (
          <>
            {/*<HeaderTemplate labelName="All Campaigns" />*/}
            <CampaignFilters
              isSearchOrFilterApplied={(value) =>
                this.setState({ isSearchOrFilterApplied: value })
              }
              filters={this.props.filters}
              searchValue={this.props.searchValue}
              orderby={this.state.orderby}
              buildFiltersArray={this.buildFiltersArray}
              setTableCampaignsStatisticsPage={(value) =>
                this.setState({ tableCampaignsStatisticsPage: value })
              }
              tableCampaignsStatisticsPage={
                this.state.tableCampaignsStatisticsPage
              }
            />

            {this.props.campaigns.length === 0 &&
            !this.props.error &&
            this.state.isSearchOrFilterApplied ? (
              <NoFilterCampaignsTemplate />
            ) : (
              <>
                <PersonalCampaignsStatistics
                  archive={false}
                  history={this.props.history}
                  filters={this.props.filters}
                  contactFilters={this.props.contactFilters}
                  setCampaignId={(id) => this.setState({ campaignId: id })}
                  buildFiltersArray={this.buildFiltersArray}
                  campaignsCount={this.props.campaignsCount}
                  searchValue={this.props.searchValue}
                  campaigns={mapping.campaignsForTable(this.props.campaigns)}
                  app={this.props.app}
                  profile={this.props.profile}
                  setOrderby={(value) => this.setState({ orderby: value })}
                  setTableCampaignsStatisticsPage={(value) =>
                    this.setState({ tableCampaignsStatisticsPage: value })
                  }
                  tableCampaignsStatisticsPage={
                    this.state.tableCampaignsStatisticsPage
                  }
                  orderby={this.state.orderby}
                />
              </>
            )}
          </>
        )}
      </HomeTemplate>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    app: state.app,
    createCampaignController: state.campaign.createCampaignController,
    campaigns: state.campaigns.campaigns,
    campaignsCount: state.campaigns.campaignsCount,
    haveCampaigns: state.campaigns.haveCampaigns,
    searchValue: state.campaigns.searchValue,
    contactFilters: state.filters,
    filters: state.campaigns.filters,
    connector: state.app.connector,
    error: state.app.errorRequest.campaigns,
    profile: state.user.profileFull,
    leftSide: state.leftSideMenu.leftSide,
  };
};

export default connect(mapStateToProps)(withRouter(CampaignsPage));
