export const CONSTS = {
  "PROJECT_NAME": "Snaily",
  "API": {
    "URL": "https://api.snaily.io/api/v1/",
    "URL_AUTOMATION": "https://automation.snaily.io/v2/"
  },
  "LINKEDIN_URL": "https://www.linkedin.com/search/results/people",
  "SALES_NAVIGATOR": "https://www.linkedin.com/sales/search/people",
  "EXTENSION_ID": "febgaeoegckcdcdhammknmiobpjkpgbe",
  "TERMS_AND_CONDITIONS_URL": "https://snaily.io/terms-and-conditions/",
  "PRIVACY_POLICY": "https://snaily.io/privacy-policy/",
  "EXTENSION_INSTALL_URL": "https://chrome.google.com/webstore/detail/febgaeoegckcdcdhammknmiobpjkpgbe",
  "DEFAULT_DATE_ACTIONS_VALUE": {
    "CONNECT": {
      "WITHDRAW": {
        "DEFAULT": 10
      },
      "DELAY": {
        "DEFAULT": 30,
        "MIN_VALUE_MINUTES": 0
      }
    },
    "MESSAGE_TIMEOUTS_DAYS": [
      3,
      7,
      14,
      30,
      30,
      30,
      30,
      30,
      30,
      30,
      30,
      30
    ]
  },
  "LOGS_SETTINGS": {
    "didCatch": false,
    "rejection": false
  },
  "AVAILABLE_ACTIONS": {
    "Connect": {
      "Type": "Connect",
      "Title": "Connect",
      "Visible": true,
      "MaxLength": 290
    },
    "Message": {
      "Type": "Message",
      "Title": "Message",
      "Visible": true,
      "MaxLength": 3000
    },
    "VisitProfile": {
      "Type": "VisitProfile",
      "Title": "Visit Profile",
      "Visible": false
    },
    "Follow": {
      "Type": "Follow",
      "Title": "Follow",
      "Visible": true
    },
    "ReactOnPost": {
      "Type": "ReactOnPost",
      "Title": "Like a post",
      "Visible": true
    },
    "EndorseSkill": {
      "Type": "EndorseSkill",
      "Title": "Endorse a skill",
      "Visible": false
    },
    "ScrapeProfile": {
      "Type": "ScrapeProfile",
      "Title": "Scrape profile",
      "Visible": false
    }
  },
  "COLORS_CAMPAIGNS": {
    "inQueue": "#DCDCDC",
    "inProgress": "#ffcd50",
    "responded": "#04C300",
    "ignored": "#EBD6FC",
    "cancelled": "#DEB4FF",
    "skipped": "#BC68FD",
    "failed": "#ED0000"
  },
  "PAGE_SIZE": 25,
  "CAMPAIGN_MAX_LENGTH": 50,
  "IMPORT_MAX_LENGTH": 50,
  "NICK_NAME_MAX_LENGTH": 50,
  "EMOTION": [
    "Like",
    "Celebrate",
    "Support",
    "Love",
    "Insightful",
    "Funny"
  ],
  "MAIN_CUSTOM_FIELDS": [
    "{first name}",
    "{last name}",
    "{company name}",
    "{position title}"
  ],
  "OVERFLOW_CUSTOM_FIELDS": [
    "{country}",
    "{custom field 1}",
    "{custom field 2}",
    "{custom field 3}"
  ],
  "TIME_ZONES": [
    {
      "value": "-720",
      "label": "GMT-12:00"
    },
    {
      "value": "-660",
      "label": "GMT-11:00"
    },
    {
      "value": "-600",
      "label": "GMT-10:00"
    },
    {
      "value": "-570",
      "label": "GMT-09:30"
    },
    {
      "value": "-540",
      "label": "GMT-09:00"
    },
    {
      "value": "-480",
      "label": "GMT-08:00"
    },
    {
      "value": "-420",
      "label": "GMT-07:00"
    },
    {
      "value": "-360",
      "label": "GMT-06:00"
    },
    {
      "value": "-300",
      "label": "GMT-05:00"
    },
    {
      "value": "-240",
      "label": "GMT-04:00"
    },
    {
      "value": "-210",
      "label": "GMT-03:30"
    },
    {
      "value": "-180",
      "label": "GMT-03:00"
    },
    {
      "value": "-120",
      "label": "GMT-02:00"
    },
    {
      "value": "-60",
      "label": "GMT-01:00"
    },
    {
      "value": "0",
      "label": "GMT-00:00"
    },
    {
      "value": "60",
      "label": "GMT+01:00"
    },
    {
      "value": "120",
      "label": "GMT+02:00"
    },
    {
      "value": "180",
      "label": "GMT+03:00"
    },
    {
      "value": "210",
      "label": "GMT+03:30"
    },
    {
      "value": "240",
      "label": "GMT+04:00"
    },
    {
      "value": "270",
      "label": "GMT+04:30"
    },
    {
      "value": "300",
      "label": "GMT+05:00"
    },
    {
      "value": "330",
      "label": "GMT+05:30"
    },
    {
      "value": "345",
      "label": "GMT+05:45"
    },
    {
      "value": "360",
      "label": "GMT+06:00"
    },
    {
      "value": "390",
      "label": "GMT+06:30"
    },
    {
      "value": "420",
      "label": "GMT+07:00"
    },
    {
      "value": "480",
      "label": "GMT+08:00"
    },
    {
      "value": "525",
      "label": "GMT+08:45"
    },
    {
      "value": "540",
      "label": "GMT+09:00"
    },
    {
      "value": "570",
      "label": "GMT+09:30"
    },
    {
      "value": "600",
      "label": "GMT+10:00"
    },
    {
      "value": "630",
      "label": "GMT+10:30"
    },
    {
      "value": "660",
      "label": "GMT+11:00"
    },
    {
      "value": "720",
      "label": "GMT+12:00"
    }
  ],
  "DAILY_LIMITS": {
    "MESSAGES": [
      {
        "value": "0",
        "label": "0"
      },
      {
        "value": "5",
        "label": "5"
      },
      {
        "value": "10",
        "label": "10"
      },
      {
        "value": "15",
        "label": "15"
      },
      {
        "value": "20",
        "label": "20"
      },
      {
        "value": "25",
        "label": "25"
      },
      {
        "value": "30",
        "label": "30"
      },
      {
        "value": "35",
        "label": "35"
      },
      {
        "value": "40",
        "label": "40"
      },
      {
        "value": "45",
        "label": "45"
      },
      {
        "value": "50",
        "label": "50"
      },
      {
        "value": "55",
        "label": "55"
      },
      {
        "value": "60",
        "label": "60"
      },
      {
        "value": "65",
        "label": "65"
      },
      {
        "value": "70",
        "label": "70"
      },
      {
        "value": "75",
        "label": "75"
      },
      {
        "value": "80",
        "label": "80"
      },
      {
        "value": "85",
        "label": "85"
      },
      {
        "value": "90",
        "label": "90"
      },
      {
        "value": "95",
        "label": "95"
      },
      {
        "value": "100",
        "label": "100"
      }
    ],
    "INVITATIONS": [
      {
        "value": "0",
        "label": "0"
      },
      {
        "value": "5",
        "label": "5"
      },
      {
        "value": "10",
        "label": "10"
      },
      {
        "value": "15",
        "label": "15"
      },
      {
        "value": "20",
        "label": "20"
      },
      {
        "value": "25",
        "label": "25"
      },
      {
        "value": "30",
        "label": "30"
      },
      {
        "value": "35",
        "label": "35"
      },
      {
        "value": "40",
        "label": "40"
      },
      {
        "value": "45",
        "label": "45"
      },
      {
        "value": "50",
        "label": "50"
      },
      {
        "value": "55",
        "label": "55"
      },
      {
        "value": "60",
        "label": "60"
      },
      {
        "value": "65",
        "label": "65"
      },
      {
        "value": "70",
        "label": "70"
      },
      {
        "value": "75",
        "label": "75"
      }
    ],
    "LIKES": [
      {
        "value": "0",
        "label": "0"
      },
      {
        "value": "5",
        "label": "5"
      },
      {
        "value": "10",
        "label": "10"
      },
      {
        "value": "15",
        "label": "15"
      },
      {
        "value": "20",
        "label": "20"
      },
      {
        "value": "25",
        "label": "25"
      },
      {
        "value": "30",
        "label": "30"
      },
      {
        "value": "35",
        "label": "35"
      },
      {
        "value": "40",
        "label": "40"
      },
      {
        "value": "45",
        "label": "45"
      },
      {
        "value": "50",
        "label": "50"
      },
      {
        "value": "55",
        "label": "55"
      },
      {
        "value": "60",
        "label": "60"
      },
      {
        "value": "65",
        "label": "65"
      },
      {
        "value": "70",
        "label": "70"
      },
      {
        "value": "75",
        "label": "75"
      },
      {
        "value": "80",
        "label": "80"
      },
      {
        "value": "85",
        "label": "85"
      },
      {
        "value": "90",
        "label": "90"
      },
      {
        "value": "95",
        "label": "95"
      },
      {
        "value": "100",
        "label": "100"
      }
    ],
    "FOLLOW": [
      {
        "value": "0",
        "label": "0"
      },
      {
        "value": "5",
        "label": "5"
      },
      {
        "value": "10",
        "label": "10"
      },
      {
        "value": "15",
        "label": "15"
      },
      {
        "value": "20",
        "label": "20"
      },
      {
        "value": "25",
        "label": "25"
      },
      {
        "value": "30",
        "label": "30"
      },
      {
        "value": "35",
        "label": "35"
      },
      {
        "value": "40",
        "label": "40"
      },
      {
        "value": "45",
        "label": "45"
      },
      {
        "value": "50",
        "label": "50"
      },
      {
        "value": "55",
        "label": "55"
      },
      {
        "value": "60",
        "label": "60"
      },
      {
        "value": "65",
        "label": "65"
      },
      {
        "value": "70",
        "label": "70"
      },
      {
        "value": "75",
        "label": "75"
      },
      {
        "value": "80",
        "label": "80"
      },
      {
        "value": "85",
        "label": "85"
      },
      {
        "value": "90",
        "label": "90"
      },
      {
        "value": "95",
        "label": "95"
      },
      {
        "value": "100",
        "label": "100"
      }
    ]
  },
  "WORKING_HOURS": 10,
  "notification": {
    "3.1": {
      "title": "Congratulations!",
      "content": "Campaign is created.",
      "style": "default"
    },
    "3.3": {
      "title": "The action is removed",
      "content": "",
      "style": "default"
    },
    "3.4a": {
      "title": "Contacts are added",
      "content": "",
      "button": {
        "title": "Go to campaign",
        "link": "/campaigns/{var}"
      },
      "style": "default"
    },
    "3.4b": {
      "title": "Contacts are added",
      "content": "",
      "style": "default"
    },
    "3.5a": {
      "title": "Contact is excluded",
      "content": "You may find it in all contacts.",
      "style": "default"
    },
    "3.5b": {
      "title": "Contacts are excluded",
      "content": "You may find them in all contacts.",
      "style": "default"
    },
    "3.6": {
      "title": "Campaign is launched",
      "content": "See the list of actions in the activities queue.",
      "button": {
        "title": "Activities queue",
        "link": "/activities-queue"
      },
      "style": "default"
    },
    "3.7": {
      "title": "Campaign is paused",
      "content": "You can resume it at any time.",
      "style": "default"
    },
    "3.8": {
      "title": "Campaign is cancelled",
      "content": "All campaign's activities have been removed <br> from the activities queue.",
      "button": {
        "title": "Activities queue",
        "link": "/activities-queue"
      },
      "style": "default"
    },
    "3.9": {
      "title": "Campaign is resumed",
      "content": "See the activities to be taken in the activities queue.",
      "button": {
        "title": "Activities queue",
        "link": "/activities-queue"
      },
      "style": "default"
    },
    "3.10": {
      "title": "Campaign is archived",
      "content": "You can find it in the archive.",
      "button": {
        "title": "Archive",
        "link": "/archive"
      },
      "style": "default"
    },
    "3.11": {
      "title": "Snaily is running",
      "content": "See the list of actions in the activities queue.",
      "button": {
        "title": "Activities queue",
        "link": "/activities-queue"
      },
      "style": "default"
    },
    "3.12": {
      "title": "Snaily is not running anymore",
      "content": "All activities have been postponed. <br> You can resume them at any time.",
      "style": "default"
    },
    "3.13": {
      "title": "Working hours are changed",
      "content": "All activities are rescheduled <br> according to new working hours.",
      "button": {
        "title": "Activities queue",
        "link": "/activities-queue"
      },
      "style": "default"
    },
    "3.14": {
      "title": "Time zone is changed",
      "content": "All activities are rescheduled <br> according to the new time zone.",
      "button": {
        "title": "Activities queue",
        "link": "/activities-queue"
      },
      "style": "default"
    },
    "3.15": {
      "title": "Daily limits is changed",
      "content": "All activities are rescheduled according to the new limits.",
      "button": {
        "title": "Activities queue",
        "link": "/activities-queue"
      },
      "style": "default"
    },
    "3.16": {
      "title": "Contacts are excluded",
      "content": "All activities are rescheduled.",
      "button": {
        "title": "Activities queue",
        "link": "/activities-queue"
      },
      "style": "default"
    },
    "3.17": {
      "title": "Subscription is expired",
      "content": "Upgrade your subscription to <br> continue using Snaily.",
      "button": {
        "title": "Upgrade subscription",
        "link": "/upgrade"
      },
      "style": "attention"
    },
    "3.18": {
      "title": "Contacts are added",
      "content": "All activities are rescheduled.",
      "button": {
        "title": "Activities queue",
        "link": "/activities-queue"
      },
      "style": "default"
    },
    "3.19": {
      "title": "Congratulations!",
      "content": "Your subscription is activated.",
      "style": "default"
    },
    "3.20": {
      "title": "Done!",
      "content": "Contacts are deleted from the system.",
      "style": "default"
    },
    "3.21": {
      "title": "Done!",
      "content": "Campaign is cloned.",
      "style": "default"
    },
    "n3.22": {
      "title": "Done!",
      "content": "Subscription has been upgraded.",
      "style": "default"
    },
    "n3.23": {
      "title": "Done!",
      "content": "Invitation has been sent. Await for confirmation.",
      "style": "default"
    },
    "n3.26": {
      "title": "Done!",
      "content": "Subscription has been upgraded.",
      "style": "default"
    },
    "n3.27": {
      "title": "Done!",
      "content": "The invitation has been resent.",
      "style": "default"
    },
    "n3.28": {
      "title": "Done!",
      "content": "Invitation for {email} is revoked.",
      "style": "default"
    },
    "3.29": {
      "title": "Done!",
      "content": "The subscription has been canceled.",
      "style": "default"
    },
    "n3.30": {
      "title": "Done!",
      "content": "The subscription has been resumed.",
      "style": "default"
    },
    "n3.31": {
      "title": "Done!",
      "content": "The team member has been deleted.",
      "style": "default"
    },
    "n3.32": {
      "title": "Done!",
      "content": "Contact name is changed",
      "style": "default"
    }
  },
  "TIPS": {
    "n4.1": {
      "content": [
        "You used all monthly personalized invitations which LinkedIn gives for free every month and now your automation is stuck.",
        "Due to that we have paused this campaign.",
        "",
        "To resume this campaign you need to make an action first, we propose you to consider 2 options:",
        "- leave connection invitation message empty, LinkedIn allows to send message less invitations for free;",
        "- buy Premium LinkedIn or Sales Navigator, paid subscriptions don't have such limitations;",
        "",
        "Once you settle this you should just run this campaign once again."
      ],
      "title": "Used free custom invitations",
      "more": ""
    },
    "n4.2": {
      "content": [
        "Looks like you are using free LinkedIn which limits invitation messages up to 200 symbols.",
        "Your invitation message in this campaign is getting longer and we can't send it.",
        "Due to that we have paused this campaign.",
        "",
        "We would like to recommend you to consider next options to resume your campaign:",
        "- make the invitation message shorter, ideally it should be 190-195 symbols to let it be less than 200 symbols after personalization.",
        "Take into account that LinkedIn allows a limited number of personalized invitations for Free profiles, usually it is 5-10 invitations per month",
        "- buy one of the paid LinkedIn subscriptions like Premium or Sales Navigator.",
        "Such subscriptions allow you to send invitation messages up to 300 symbols without monthly limits.",
        "",
        "Once you settle this you should just run this campaign once again."
      ],
      "title": "Too long invitation",
      "more": ""
    },
    "n4.3": {
      "content": [
        "The weekly LinkedIn's  invitation limit has been reached.",
        "Snaily will resume sending invitations next week."
      ],
      "title": "Out of invites",
      "more": ""
    },
    "n4.4": {
      "content": [
        "Paste a text containing urls of LinkedIn profiles and Snaily will automatically recognize them. Once you export them you will get only profile URLs, contact details will be scraped during campaign execution."
      ],
      "title": "Information",
      "more": ""
    },
    "n4.5": {
      "content": [
        "This is advanced export that allows customizing LinkedIn profiles by changing profile information or adding custom fields. It is working only with a specific CSV file format. Please download the example to get familiar with it."
      ],
      "title": "Information",
      "more": ""
    }
  },
  "ON_BOARDING": {
    "2.1": {
      "title": "Leave us your feedback",
      "isClosable": true,
      "conditions": {
        "onBoarding": true,
        "runningActivitiesEnabled": false
      },
      "content": "Let’s make Snaily better together! Tell us more about your experience."
    },
    "2.2": {
      "title": "Press to run activities on LinkedIn!",
      "sideTitle": "2/2",
      "isClosable": true,
      "conditions": {
        "onBoarding": true,
        "runningActivitiesEnabled": true
      },
      "content": "You can check scheduled actions in the activities queue."
    },
    "2.3": {
      "title": "Step 1",
      "isClosable": true,
      "conditions": {
        "onBoarding": true,
        "runningActivitiesEnabled": false
      },
      "content": "Choose an action to set it up!"
    },
    "2.4": {
      "title": "Add next action",
      "isClosable": true,
      "conditions": {
        "onBoarding": true,
        "runningActivitiesEnabled": false
      },
      "content": "Create a sequence of follow-up messages!"
    },
    "2.5": {
      "title": "Available contacts",
      "isClosable": true,
      "conditions": {
        "onBoarding": true,
        "runningActivitiesEnabled": false
      },
      "content": "Apply filters and add contacts to your campaign."
    },
    "2.6": {
      "title": "Find your campaigns here!",
      "isClosable": true,
      "conditions": {
        "onBoarding": true,
        "runningActivitiesEnabled": false
      },
      "sideTitle": "1/2",
      "content": "All your active, paused and finished campaigns will be listed here."
    },
    "2.7": {
      "title": "Launch your campaign!",
      "isClosable": true,
      "conditions": {
        "onBoarding": true,
        "runningActivitiesEnabled": false
      },
      "content": "Press to rocket your campaign right now!"
    }
  },
  "confirmationDialogs": {
    "1.1": {
      "title": "Cancel execution?",
      "content": "Stop all activities for the contact in this campaign."
    },
    "1.2": {
      "title": "Cancel campaign?",
      "content": "Stop all activities for this campaign."
    },
    "1.3": {
      "title": "Leave campaign master?",
      "content": "Changes will not be saved."
    },
    "1.4": {
      "title": "Delete this action?",
      "content": "This action and all next actions will be deleted."
    },
    "1.5": {
      "title": "Launch now?",
      "content": "Activities queue will be created for {contacts_count} contacts. <br>Edit of this campaign will be limited."
    },
    "1.6": {
      "title": "Exclude contacts?",
      "content": "Contacts will be excluded from this campaign."
    },
    "1.7": {
      "title": "Archive campaign?",
      "content": "It will be moved to the Archive."
    },
    "1.8": {
      "title": "Exclude contacts?",
      "content": "Contacts will be excluded from this campaign."
    },
    "1.9": {
      "title": "Delete contacts?",
      "content": "{contacts_count} contacts will be deleted from the system."
    },
    "1.10": {
      "title": "Clone campaign?",
      "content": "New campaign will be created.<br> It will contain the same steps."
    },
    "1.11": {
      "title": "Upgrade subscription?",
      "content": "Subscription will be upgraded.<br> You might be prorated charge for a difference."
    },
    "1.12": {
      "title": "Downgrade subscription?",
      "content": "Subscription will be downgraded."
    },
    "1.13": {
      "title": "Delete message?",
      "content": "This message will be deleted for all participants in the conversation."
    },
    "c1.13": {
      "title": "Revoke invitation?",
      "content": "The invitation for {email} be deleted from the system."
    },
    "c1.14": {
      "title": "Are you sure you want to remove this member?",
      "content": "{nickname}({email}) will be removed."
    }
  },
  "SUBSCRIPTIONS": {
    "STATE": {
      "ACTIVE": "active",
      "CANCELED": "canceled",
      "OVERDUE": "overdue"
    },
    "EXPERIMENTAL": {
      "NAME": "Early birds",
      "PRICE": 38,
      "ID": "early-birds"
    },
    "TRIAL": {
      "NAME": "Trial",
      "PRICE": 0,
      "ID": "trial"
    },
    "BASIC": {
      "NAME": "Basic",
      "PRICE": 38,
      "ID": "basic"
    },
    "PRO": {
      "NAME": "Pro ",
      "PRICE": 59,
      "ID": "pro"
    },
    "TEAM": {
      "NAME": "Team",
      "PRICE": 75,
      "ID": "team"
    }
  },
  "ROLES": {
    "User": "user",
    "Manager": "manager",
    "Owner": "owner"
  },
  "HELP_URL": "https://snaily.io/support/",
  "DELAY_TIMEOUT": 1576800,
  "HJ_ID": 2778252,
  "HJ_SV": 6,
  "GOOGLE_TAG_MANAGER": {
    "ID": "GTM-KSFX6VJ"
  },
  "APP_VERSION": "4.0.4.0"
}
