import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import _ from "lodash";
import { mapping } from "../../../actions/mapping";
// components
import TooltipCustom from "../../atoms/tooltipCustom";
// icons
import messageIcon from "../../../assets/image/icons/png/message.svg";
import infoBlackIcon from "../../../assets/image/icons/tables/info-black.svg";
import inIcon from "../../../assets/image/icons/tables/linkedin-icon-gray.svg";
import iconRemove from "../../../assets/image/icons/tables/remove.svg";
import iconStop from "../../../assets/image/icons/svg/icons/stop.svg";
import { CONSTS } from "../../../config/objectConst";
import ModalWindow from "../ModalWindow";
import { linkedInProfileOpener } from "../../../api/linkedInProfileOpener";
import { statesDescriptor } from "../../../api/statesDescriptor";

const BtnGroupContainer = (props) => {
  const [actions, setActions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (props.row.campaignExecutions.step_executions) {
      setActions(
        _.filter(
          props.row.campaignExecutions.step_executions,
          (el) => el.type !== "Delay"
        )
      );
    }
    return () => {
      setActions([]);
    };
  }, [props.row]);

  const menuClasses = () => {
    if (props.size > 6) {
      if (props.index > props.size - 3) {
        return "position-top";
      }
    }
    return "position-bottom";
  };

  const upwardController = () => {
    if (props.size > 6) {
      if (props.index > props.size - 3) {
        return true;
      }
    }
    return false;
  };

  const openLinkedinProfile = async () => {
    await linkedInProfileOpener.open(props.connector, props.row);
  };

  const cancelExecution = () => {
    props.row.campaignExecutions.actions.complete.enabled &&
      props.handlerCompleteExecutions(props.row.campaignExecutions.id);
    setIsOpen(false);
  };

  return (
    <div className="btn-group">
      <TooltipCustom
        title={
          props.dropDownControllerValue
            ? ""
            : props.row.campaignExecutions.actions.show_details.hint
        }
        arrow={true}
      >
        <Dropdown
          upward={upwardController()}
          className={
            props.row.campaignExecutions.actions.show_details.enabled
              ? "btn-accent btn-drop"
              : "btn-row btn-drop disabled"
          }
          onOpen={() =>
            props.row.campaignExecutions.actions.show_details.enabled &&
            props.dropDownController(true)
          }
          onClose={() => props.dropDownController(false)}
          icon={
            <img className="icon" src={infoBlackIcon} alt="infoBlackIcon" />
          }
        >
          <Dropdown.Menu className={menuClasses()}>
            <Dropdown.Item>
              <div className="drop-down-container">
                <p className="text-dropdown-p">
                  {props.row.campaignExecutions.hint}
                </p>
                <div className="drop-down-container__list">
                  {actions &&
                    actions.map((item, index) => {
                      return (
                        <div className="drop-down-container__item" key={index}>
                          <div className="drop-down-container__column">
                            <div className="drop-down-container__label">
                              Action #{index + 1}:{" "}
                            </div>
                            <div className="drop-down-container__title text-dropdown-value">
                              {mapping.activeName(item.type)}
                            </div>
                          </div>
                          <div className="drop-down-container__column">
                            <div className="drop-down-container__label">
                              Status:
                            </div>
                            <div className="drop-down-container__value">
                              <div
                                className={`drop-down-container__detector`}
                                style={{
                                  background: `${statesDescriptor.detectColor(
                                    props.row.campaignExecutions.human_state,
                                    item
                                  )}`,
                                }}
                              ></div>
                              <span className="text-dropdown-value">
                                {statesDescriptor.getStepTitle(
                                  item.human_state,
                                  item.type
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </TooltipCustom>

      <TooltipCustom
        title={props.row.campaignExecutions.actions.open_linkedin_profile.hint}
        arrow={true}
      >
        <button
          className={
            props.row.campaignExecutions.actions.open_linkedin_profile.enabled
              ? "btn-row info"
              : "btn-row info disabled"
          }
          onClick={openLinkedinProfile}
        >
          <img src={inIcon} alt="linkedin icon" />
        </button>
      </TooltipCustom>

      <TooltipCustom
        title={props.row.campaignExecutions.actions.open_conversation.hint}
        arrow={true}
      >
        <button
          className={
            props.row.campaignExecutions.actions.open_conversation.enabled
              ? "btn-row info"
              : "btn-row info disabled"
          }
          onClick={() => {
            props.row.campaignExecutions.actions.open_conversation.enabled &&
              window.open(
                props.row.campaignExecutions.conversation_url,
                "_blank"
              );
          }}
        >
          <img src={messageIcon} alt="messageIcon" />
        </button>
      </TooltipCustom>
      {props.type === "single" && props.campaignStatus === "Compose" && (
        <button
          className="btn-row time"
          onClick={() =>
            props.removeContactsFromCampaign([props.row], props.campaignId)
          }
        >
          <img src={iconRemove} alt="timeIcon" />
        </button>
      )}
      {props.type === "all" && (
        <button className="btn-row time">
          <img src={iconRemove} alt="timeIcon" />
        </button>
      )}
      <TooltipCustom
        title={props.row.campaignExecutions.actions.complete.hint}
        arrow={true}
      >
        <button
          className={
            props.row.campaignExecutions.actions.complete.enabled
              ? "btn-row complete"
              : "btn-row complete disabled"
          }
          onClick={(e) => {
            e.stopPropagation();
            props.row.campaignExecutions.actions.complete.enabled &&
              setIsOpen(true);
          }}
        >
          <img src={iconStop} alt="iconPause" />
        </button>
      </TooltipCustom>
      <ModalWindow
        open={isOpen}
        closeText="No"
        confirmText="Yes"
        title={CONSTS.confirmationDialogs[1.1].title}
        content={CONSTS.confirmationDialogs[1.1].content}
        closeHandler={() => setIsOpen(false)}
        confirmHandler={cancelExecution}
      />
    </div>
  );
};

export default BtnGroupContainer;
