import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import userImg from "./../../../assets/image/icons/svg/icons/user.svg";
import { user } from "../../../actions/user";
import "./dropDownMenu.scss";
import { api } from "../../../api/api";
import { CONSTS } from "../../../config/objectConst";
import Performed from "../../atoms/Performed";
import LinkedInBlock from "../../atoms/LinkedInBlock";
import ResponsiveButton from "../../atoms/responsiveButton";
import { listeners } from "../../../api/listeners";
import { functions } from "../../../tools/functions";

const DropDownMenu = ({
  profile,
  connector,
  openMenuHandler,
  impersonatedPersona,
  timezoneOffset,
}) => {
  let history = useHistory();
  const [rangeState, _] = useState({
    startDate: functions.getToday(timezoneOffset),
    endDate: functions.getToday(timezoneOffset),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingStopSession, setIsLoadingStopSession] = useState(false);
  const [dailyStatisticsState, setDailyStatisticsState] = useState({});

  useEffect(() => {
    getPeriodStatisticsAsync();
  }, []);

  const linkClickHandler = () => {
    if (history.location.pathname === "/account") {
      openMenuHandler(false);
    }
  };
  const signOut = async () => {
    const connector = listeners.getConnector();
    setIsLoadingStopSession(true);
    await connector.auth.signOutUnderTeamMember();
    document.location.href = "/team/members";
  };

  const getPeriodStatisticsAsync = async () => {
    const dailyStatistics = await user.getPeriodStatistics(
      rangeState?.startDate,
      rangeState?.endDate
    );
    setDailyStatisticsState(dailyStatistics);
  };

  return (
    <div className="drop-down-menu profile-menu">
      <div onClick={linkClickHandler}>
        <Link to="/account" className="btn with-border semi-bold">
          <img src={userImg} alt="use icon" />
          Go to account
        </Link>
      </div>
      <div className="line-list">&nbsp;</div>
      <LinkedInBlock profile={profile} connector={connector} />
      <p className="performed__title">Performed today: </p>
      <Performed statistics={dailyStatisticsState} limit />
      <div className="line-list">&nbsp;</div>
      {impersonatedPersona ? (
        <ResponsiveButton
          className={"profile-menu__log-out"}
          onClick={() => {
            setIsLoadingStopSession(true);
            signOut();
          }}
          isLoading={isLoadingStopSession}
          disabled={false}
        >
          {"Stop session"}
        </ResponsiveButton>
      ) : (
        <ResponsiveButton
          className="profile-menu__log-out"
          onClick={async () => {
            setIsLoading(true);
            await api.logoutAction(connector);
          }}
          isLoading={isLoading}
        >
          Log Out
        </ResponsiveButton>
      )}

      <div className="line-list">&nbsp;</div>
      <div className="profile-menu__footer">
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open(CONSTS.TERMS_AND_CONDITIONS_URL, "_blank");
          }}
        >
          Terms of use
        </span>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open(CONSTS.PRIVACY_POLICY, "_blank");
          }}
        >
          Privacy policy
        </span>
      </div>
    </div>
  );
};

export default DropDownMenu;
