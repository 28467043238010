const initialState = {
  campaignsTeams: [],
  haveCampaignsTeams: false,
  searchValueTeams: "",
  loadCampaignsTeamsController: false,
  campaignsTeamsCount: null,
  filters: {
    active: false,
    stopped: false,
    completed: false,
    drafted: false,
    cancelled: false,
  },
};

const campaignsTeams = (state = initialState, action) => {
  switch (action.type) {
    case "CAMPAIGNS_TEAMS_ARRAY":
      return Object.assign({}, state, {
        campaignsTeams: action.payload.campaignsTeams,
        campaignsTeamsCount: action.payload.campaignsTeamsCount,
      });
    case "LOAD_CAMPAIGNS_TEAMS_CONTROLLER":
      return Object.assign({}, state, {
        loadCampaignsTeamsController:
          action.payload.loadCampaignsTeamsController,
      });

    case "HAVE_CAMPAIGNS_TEAMS":
      return Object.assign({}, state, {
        haveCampaignsTeams: action.payload.haveCampaignsTeams,
      });
    case "SEARCH_VALUE_TEAMS":
      return Object.assign({}, state, {
        searchValueTeams: action.payload.searchValue,
      });

    case "CHANGE_FILTER_STATUS_TEAMS":
      return Object.assign({}, state, {
        filters: action.payload.filters,
      });

    default:
      return state;
  }
};

export default campaignsTeams;
