import React from "react";
import uploadIcon from "../../../../assets/image/icons/svg/export.svg";
import { CircularProgress } from "@material-ui/core";
import documentIcon from "../../../../assets/image/icons/svg/icons/document.svg";

const FoundContacts = ({
  contacts,
  startUploadingHandler,
  exportStartingLoader,
  refreshContactsHandler,
}) => {
  return (
    <div className="found-contacts">
      <p className="found-contacts__text">
        <span>{contacts.length} profiles</span> found
      </p>
      <button
        onClick={() => startUploadingHandler(contacts)}
        className="btn-start"
      >
        <img src={uploadIcon} alt="upload icon" />
        Upload
        {exportStartingLoader && (
          <CircularProgress
            size={20}
            classes={{ colorPrimary: "material-loader-accent" }}
          />
        )}
      </button>
      <button
        onClick={refreshContactsHandler}
        className="found-contacts__refresh"
      >
        <img src={documentIcon} alt="document icon" />
        Try with another file
      </button>
    </div>
  );
};

export default FoundContacts;
