import { CONSTS } from "../config/objectConst";

export const functions = {
  isDate(value) {
    switch (typeof value) {
      case "number":
        return true;
      case "string":
        return !isNaN(Date.parse(value));
      case "object":
        if (value instanceof Date) {
          return !isNaN(value.getTime());
        }
        break;
      default:
        return false;
    }
  },
  getMonth(monthIndex) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months.find((item, index) => index === monthIndex);
  },
  parseDateToDMY(date, divider = ".") {
    const dateParsed = Date.parse(date);
    const dateObject = new Date(dateParsed);

    if (!dateParsed) {
      return "...";
    }

    // TODO #698 change global date format
    // return `${
    //   dateObject.getMonth() + 1 < 10
    //     ? `0${dateObject.getMonth() + 1}`
    //     : dateObject.getMonth() + 1
    // }${divider}${
    //   dateObject.getDate() < 10
    //     ? `0${dateObject.getDate()}`
    //     : dateObject.getDate()
    // }${divider}${dateObject.getFullYear()}`;

    return `${
      dateObject.getDate() < 10
        ? `0${dateObject.getDate()}`
        : dateObject.getDate()
    } ${functions.getMonth(dateObject.getMonth())} ${dateObject.getFullYear()}`;

    return `${
      dateObject.getMonth() + 1 < 10
        ? `0${dateObject.getMonth() + 1}`
        : dateObject.getMonth() + 1
    }${divider}${
      dateObject.getDate() < 10
        ? `0${dateObject.getDate()}`
        : dateObject.getDate()
    }${divider}${dateObject.getFullYear()}`;
  },
  parseTimeToHM(date, divider = ":") {
    const dateParsed = Date.parse(date);
    const dateObject = new Date(dateParsed);
    return `${
      dateObject.getHours() < 10
        ? `0${dateObject.getHours()}`
        : dateObject.getHours()
    }${divider}${
      dateObject.getMinutes() < 10
        ? `0${dateObject.getMinutes()}`
        : dateObject.getMinutes()
    }`;
  },
  numberWithEnding(number) {
    switch (Number(number)) {
      case 1:
        return "1st";
      case 2:
        return "2nd";
      case 3:
        return "3rd";
      case 4 <= number < 21:
        return number + "th";
      default:
        return number;
    }
  },
  escapeSpecialChars(string) {
    return string.replace(
      /[\+\-\&\|\!\(\)\{\}\[\]\^\"\~\*\?\:\\]/gm,
      (char) => `\\${char}`
    );
  },
  validateHours(value) {
    const startTimeInHours = value && value.split("-")[0].split(":")[0];
    const startTimeInMinutes = value && value.split("-")[0].split(":")[1];
    const endTimeInHours = value && value.split("-")[1].split(":")[0];
    const endTimeInMinutes = value && value.split("-")[1].split(":")[1];
    const totalTime = this.getTotalTime(
      Number(startTimeInHours),
      Number(endTimeInHours),
      Number(startTimeInMinutes),
      Number(endTimeInMinutes)
    );

    if (!value) {
      return "Input the time";
    } else if (
      startTimeInMinutes < 0 ||
      endTimeInMinutes < 0 ||
      startTimeInMinutes > 59 ||
      endTimeInMinutes > 59 ||
      startTimeInHours < 0 ||
      startTimeInHours > 24 ||
      endTimeInHours < 0 ||
      endTimeInHours > 24 ||
      startTimeInHours > endTimeInHours ||
      startTimeInHours.indexOf("_") !== -1 ||
      endTimeInHours.indexOf("_") !== -1 ||
      startTimeInMinutes.indexOf("_") !== -1 ||
      endTimeInMinutes.indexOf("_") !== -1
    ) {
      return "Invalid interval format";
    } else if (totalTime / 60 > CONSTS.WORKING_HOURS) {
      return `Total interval can't exceed ${CONSTS.WORKING_HOURS} hours`;
    } else {
      return "";
    }
  },
  getTotalTime(
    startTimeInHours,
    endTimeInHours,
    startTimeInMinutes,
    endTimeInMinutes
  ) {
    if (endTimeInHours === 0) {
      return 24 - startTimeInHours;
    }
    return Math.abs(
      startTimeInHours * 60 +
        startTimeInMinutes -
        (endTimeInHours * 60 + endTimeInMinutes)
    );
  },
  isValidOnBoardingConditions(appProfile, onBoardingNumber) {
    //#113, for a reason appProfile is null, it should not lead to an error
    if (!appProfile) {
      return true;
    }
    const conditions = CONSTS.ON_BOARDING[onBoardingNumber].conditions;
    for (const key of Object.keys(conditions)) {
      if (conditions[key] !== appProfile[key]) {
        return false;
      }
    }
    return true;
  },
  calculatePercentage(number, total) {
    const dimension = 10;
    let result =
      total !== 0
        ? Math.round((number / total + Number.EPSILON) * 100 * dimension) /
          dimension
        : 0;
    return result;
  },

  roundToDecimal(number) {
    let result = Math.round(number * 10 + Number.EPSILON) / 10;
    return result;
  },

  getFileName() {
    let date = new Date();
    return `${CONSTS.PROJECT_NAME}Export_${
      String(date.getDate()).padStart(2, "0") +
      "_" +
      String(date.getMonth() + 1).padStart(2, "0") +
      "_" +
      date.getFullYear() +
      "_" +
      date.getHours() +
      "_" +
      date.getMinutes() +
      "_" +
      date.getSeconds()
    }.csv`;
  },

  getToday(timezoneOffset) {
    let pcTimeZoneOffset = new Date().getTimezoneOffset();
    const result = new Date(
      Date.now() + (pcTimeZoneOffset + timezoneOffset) * 60 * 1000
    );
    return result;
  },
};
export const saveData = (function () {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  return function (data, fileName) {
    const json = data,
      blob = new Blob([json], { type: "octet/stream" }),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();
