import React, { Component } from "react";
import NotFoundContainer from "../../components/templates/notFoundTemplate";
import { Link } from "react-router-dom";
import Image404 from "../../assets/image/404.png";
import { helpersFunc } from "../../config/helpers";

export class NotfoundPage extends Component {
  componentDidMount() {
    helpersFunc.logEnjectService404();
  }
  render() {
    return (
      <NotFoundContainer>
        <div className="notfound-page">
          <div className="notfound-page__content">
            <img src={Image404} alt="404" className="notfound-page__image" />
            <div className="notfound-page__text">
              <h1 className="notfound-page__text-h1">Oooopss...</h1>
              <h3 className="notfound-page__text-h3">
                Its seems like the page that are you looking for is no longer
                here
              </h3>
              <Link to={"/campaigns"} className="notfound-page__link">
                Go home!
              </Link>
            </div>
          </div>
        </div>
      </NotFoundContainer>
    );
  }
}

export default NotfoundPage;
