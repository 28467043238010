import React, { useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

const SnackNotification = ({ message, actionClose }) => {
  const text = useRef(message.content);
  return (
    <div className="snackbar-notification__message">
      <div className="snackbar-notification__icon" />
      <div className="snackbar-notification__body">
        <div className="snackbar-notification__title">
          <span>{message.title}</span>
          <div
            className="snackbar-notification__icon-close"
            onClick={actionClose}
          />
        </div>
        <div className="snackbar-notification__text">{text.current}</div>
        {message.button && (
          <div className="notification-button">
            <a
              href={message.button.link}
              className="btn"
              target="_blank"
              rel="noopener noreferrer"
            >
              {message.button.title}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    snacknotification: state.notification.message,
  };
};

export default connect(mapStateToProps)(withRouter(SnackNotification));
