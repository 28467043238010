import axios from "axios";
import BadGatewayError from "../errors/BadGatewayError";
import NotAuthorizedError from "../errors/NotAuthorizedError";
import { connectorAPI } from "../actions/connector";
import BackendValidationError from "../errors/BackendValidationError";
import RequestTimedOutError from "../errors/RequestTimedOutError";

export const httpRequest = async (data) => {
  let startTime = Date.now();
  try {
    let token = await connectorAPI.getAccessToken();
    axios.defaults.headers.common["Authorization"] = token;

    //defined once again in a case if getAccessToken was slow, exchanged tokens
    startTime = Date.now();

    let result = await axios(data);
    return result;
  } catch (error) {
    let endTime = Date.now();
    if (
      error.response?.status === 502 ||
      error.response?.status === 503 ||
      error.response?.status === 504 ||
      error.message.includes("Network Error")
    ) {
      throw new BadGatewayError(
        "Bad network or service is temporarily unavailable."
      );
    }
    if (error.response?.status === 422) {
      throw new BackendValidationError(error.response.data.errors);
    }
    if (error.response?.status === 403) {
      throw new NotAuthorizedError("Not authorized");
    }

    if (error.message.includes("timeout exceeded")) {
      throw new RequestTimedOutError(
        `${data.method} ${data.url} is timed out, took ${endTime - startTime}`
      );
    }

    if (error) {
      throw new HttpRequestError(error);
    }
  }
};

export class HttpRequestError extends Error {
  #axiosError;

  constructor(axiosError) {
    super(axiosError.message);
    this.#axiosError = axiosError;
    this.name = "HttpRequestError";

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HttpRequestError);
    }
  }

  get axios() {
    return this.#axiosError;
  }

  get skipLogging() {
    return true;
  }
}
