/*
 *
 * User reducer
 *
 */
const initialState = {
  profile: {},
  profileFull: {},
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case "USER_PROFILE":
      return Object.assign({}, state, {
        profile: action.payload.profile,
      });

    case "USER_PROFILE_FULL":
      return Object.assign({}, state, {
        profileFull: action.payload.profileFull,
      });

    default:
      return state;
  }
};

export default user;
