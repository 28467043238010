import { executionHumanState } from "./executionHumanState";

export const stepExecutionState = {
  NotStarted: executionHumanState.NotStarted,
  InQueue: "InQueue",
  InProgress: executionHumanState.InProgress,
  Succeed: executionHumanState.Succeed,
  Ignored: executionHumanState.Ignored,
  Failed: executionHumanState.Failed,
  Cancelled: executionHumanState.Cancelled,
  Skipped: executionHumanState.Skipped,
};
