import React, { Component } from "react";
// Style
import "./style.scss";
import TextFieldBig from "../../atoms/TextFieldBig";
import { campaign } from "../../../actions/campaign";
import iconLoading from "../../../assets/image/icons/gif/input-loader.gif";
import { CONSTS } from "../../../config/objectConst";
import { notification } from "../../../actions/notification";

export class HeaderTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      editTitle: false,
      value: "",
    };
  }

  clickOnEditAction = () => {
    this.setState({ editTitle: true, value: this.props.campaign.name });
  };

  onChange = (value) => {
    if (value.length <= CONSTS.CAMPAIGN_MAX_LENGTH) {
      this.setState({ value: value });
    }
  };

  onEnter = async () => {
    this.setState({ loading: true });
    try {
      await campaign.editCampaign({
        id: this.props.campaign.id,
        name: this.state.value,
      });
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      this.setState({ editTitle: false, loading: false });
    }
  };

  onBlur = () => {
    this.onEnter();
  };

  render() {
    return (
      <div className="header-template">
        <div>
          <div className="header-template-label h-4">
            {!this.state.editTitle ? (
              <div className="edit-title">
                <div className="edit-title-text">
                  {this.props.campaign.name}
                </div>{" "}
                {!this.props.location.pathname.includes("contacts") && (
                  <button
                    className="btn-edit"
                    onClick={this.clickOnEditAction}
                  ></button>
                )}
              </div>
            ) : (
              <TextFieldBig
                fullWidth
                autoFocus
                value={this.state.value}
                onChange={(e) => this.onChange(e.target.value)}
                onEnter={this.onEnter}
                onBlur={this.onBlur}
                endAdornment={
                  this.state.loading ? (
                    <img src={iconLoading} alt="loading" />
                  ) : (
                    ""
                  )
                }
              />
            )}
          </div>
        </div>
        <div>{this.props.children}</div>
      </div>
    );
  }
}

export default HeaderTemplate;
