import { api } from "../api/api";
import ErrorStackParser from "error-stack-parser";
import _ from "lodash";
import * as Sentry from "@sentry/browser";
// configs
import { CONSTS } from "./objectConst";
import { HttpRequestError } from "../api/httpRequest";

const isThirdPartyExtensionError = (stackTrace) => {
  let result =
    stackTrace.includes("chrome-extension://") &&
    !stackTrace.includes(`chrome-extension://${CONSTS.EXTENSION_ID}`);
  return result;
};

const isExtensionDisabled = (error) => {
  let result =
    error instanceof Error && error.name === "ExtensionNotInstalledError";
  return result;
};

const isMissMatchWithBackend = (error) => {
  let result =
    error instanceof HttpRequestError &&
    error.axios.response &&
    error.axios.response.status === 404;
  return result;
};

const reportToSentry = (error) => {
  if (process.env.REACT_APP_SENTRY === "true") {
    Sentry.captureException(error);
  }
};

const logEnjectService = async (error) => {
  if (isExtensionDisabled() || isMissMatchWithBackend()) {
    window.location.reload();
  }

  if (error.skipLogging) {
    console.warn(error);
    return;
  }

  let stackTrace;
  try {
    stackTrace = _.join(
      [
        ...ErrorStackParser.parse(error).map((item) => {
          return item.source;
        }),
      ],
      " \n "
    ).trim();

    if (isThirdPartyExtensionError(stackTrace)) {
      return;
    }

    reportToSentry(error);
  } catch (e) {
    reportToSentry(error);
    reportToSentry(e);
    return;
  }

  let data = {
    severity: "Error",
    event_id: "AppGenericError",
    message: "Unhandled app error {Message}, {StackTrace},{Version}",
    args: [`${error.name}:${error.message}`, stackTrace, CONSTS.APP_VERSION],
  };

  await api.createLogEnjectService(data);
};

export const helpersFunc = {
  logEnjectServiceCatch: (error) => {
    if (CONSTS.LOGS_SETTINGS.didCatch) {
      logEnjectService(error);
    }
  },
  logEnjectServicePromise: (error) => {
    console.log(error);
    if (CONSTS.LOGS_SETTINGS.rejection) {
      logEnjectService(error);
    }
  },
  logEnjectService404: async () => {
    let data = {
      severity: "Warning",
      event_id: "AppGenericError",
      message: `404 page  {Href}`,
      args: [window.location.href],
    };
    await api.createLogEnjectService(data);
  },
};
