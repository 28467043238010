import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
// Actions
import { app } from "../../actions/app";
// Components
import HomeTemplate from "../../components/templates/homeTemplate";
import CampaingsSteps from "../../components/organisms/CampaingsStepCreate";
import { campaign } from "../../actions/campaign";
import NotfoundPage from "../notfound";

export class CampaignsCreatedPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageFound: true,
    };
  }

  componentDidMount() {
    app.changePage("/campaigns");
    app.changeActiveItem(1);
    campaign.getRecentCampaigns();

    //campaign.getCampaignsParams({ updateLeftMenu: false });
  }

  render() {
    return (
      <>
        {this.state.pageFound ? (
          <HomeTemplate>
            <CampaingsSteps
              pageFound={(status) => this.setState({ pageFound: status })}
            />
          </HomeTemplate>
        ) : (
          <NotfoundPage />
        )}
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    createCampaignController: state.campaign.createCampaignController,
    campaigns: state.campaigns.campaigns,
    campaign: state.campaign,
  };
};

export default connect(mapStateToProps)(withRouter(CampaignsCreatedPage));
