class NotAuthorizedError extends Error {
  constructor(message) {
    super(message);
    this.name = "NotAuthorizedError";

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NotAuthorizedError);
    }
  }
}

export default NotAuthorizedError;
