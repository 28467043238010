import React, { Component } from "react";
// Style
import "./style.scss";

export class DescriptionList extends Component {
  render() {
    return (
      <div className={`description-list ${this.props.className}`}>
        <span className="text-page semi-bold">{this.props.label}</span>
        <div className="text-wrapper">
          <div className="text-page">
            <pre className="word-break">{this.props.children}</pre>
          </div>
        </div>
      </div>
    );
  }
}

export default DescriptionList;
