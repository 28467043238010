import React, { Component } from "react";
import _ from "lodash";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { CircularProgress } from "@material-ui/core";
import { Dropdown, Loader } from "semantic-ui-react";
// Components
import TooltipCustom from "../../atoms/tooltipCustom";

// actions and functions
import { campaign } from "../../../actions/campaign";
import { contacts } from "../../../actions/contacts";
import { app } from "../../../actions/app";

// icons
import infoBlackIcon from "../../../assets/image/icons/tables/info-black.svg";
import iconArrow from "../../../assets/image/icons/tables/arrow-rigth.svg";
import iconMessage from "../../../assets/image/icons/tables/message.svg";
import avatarSvg from "../../../assets/image/icons/svg/user.svg";

// Style
import "../TableUsers/style.scss";
import "./style.scss";
import inIcon from "../../../assets/image/icons/tables/linkedin-icon-gray.svg";
import { apiActivity } from "../../../api/activity/apiActivity";
import { linkedInProfileOpener } from "../../../api/linkedInProfileOpener";
import { statesDescriptor } from "../../../api/statesDescriptor";
import { contactsPageSize } from "../../../api/contactsPageSize";

export class TableActivity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      rowsPerPage: contactsPageSize.get(),
      contactsOnPage: [],
      selected: [],
      selectedOnPage: [],
      filterRows: [],
      dropDownController: false,
      scrollBarWidth: false,
      actionMessage: "",
      actionMessageLoader: true,
    };
    this.wrapper = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.setState({
      contactsOnPage: this.getContactsOnPage(this.state.page),
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidUpdate() {
    const scrollbarWidth =
      this.wrapper.current.offsetWidth - this.wrapper.current.clientWidth;

    if (scrollbarWidth > 0 && this.state.scrollBarWidth === false) {
      this.setState({ scrollBarWidth: true });
    }

    if (scrollbarWidth === 0 && this.state.scrollBarWidth === true) {
      this.setState({ scrollBarWidth: false });
    }
  }

  handleResize = () => {
    const scrollbarWidth =
      this.wrapper.current.offsetWidth - this.wrapper.current.clientWidth;

    if (scrollbarWidth > 0 && this.state.scrollBarWidth === false) {
      this.setState({ scrollBarWidth: true });
    }

    if (scrollbarWidth === 0 && this.state.scrollBarWidth === true) {
      this.setState({ scrollBarWidth: false });
    }
  };

  getContactsOnPage = (page) => {
    let newContacts = this.props.contacts.activity.filter(
      (item, index) =>
        this.state.rowsPerPage * page <= index &&
        index < this.state.rowsPerPage * (page + 1)
    );
    return newContacts.map((item) => item.id);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  Sort = () => {
    this.setState({
      rows: _.sortBy(this.props.contacts.activity, [
        function (o) {
          return o.name;
        },
      ]),
    });
  };

  Filter = () => {
    this.setState({
      rows: _.filter(this.props.contacts.activity, { status: 1 }),
    });
  };

  handleClick = (event, name) => {
    const selectedIndex = this.state.selected.indexOf(name);
    //console.log(selectedIndex);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selected.slice(0, selectedIndex),
        this.state.selected.slice(selectedIndex + 1)
      );
    }
    this.setState({
      selected: newSelected,
    });
  };

  isSelected = (name) => this.state.selected.indexOf(name) !== -1;

  onSelectAllPageClick = (event) => {
    if (event.target.checked) {
      let newSelecteds = this.getContactsOnPage(this.state.page);
      newSelecteds = newSelecteds.filter(
        (item) => !this.state.selected.includes(item)
      );
      this.setState({
        selected: this.state.selected.concat(newSelecteds),
      });
      return;
    }
    this.setState({
      selected: this.state.selected.filter(
        (item) => !this.state.contactsOnPage.includes(item)
      ),
    });
  };

  onSelectAllClick = () => {
    let newSelecteds = this.props.contacts.activity.map((item) => item.id);
    this.setState({
      selected: newSelecteds,
    });
  };

  onClearAllClick = () => {
    this.setState({
      selected: [],
    });
  };

  clearCheckboxes = () => {
    this.setState({ selected: [] });
  };

  dropDownController = async (value, campaignExecutionId, stepNumber) => {
    this.setState({
      dropDownController: value,
    });
    if (campaignExecutionId) {
      try {
        this.setState({ actionMessageLoader: true });
        const response = await apiActivity.getStepMessage(
          campaignExecutionId,
          stepNumber
        );
        this.setState({ actionMessage: response.data });
      } finally {
        this.setState({ actionMessageLoader: false });
      }
    } else {
      this.setState({ actionMessage: "" });
    }
  };

  removeContactsFromCampaign = async (audience, campaignId) => {
    app.changeTableContentLoaderStatus(true);
    await campaign.removeContactsFromCampaign({
      id: campaignId,
      audience: audience,
    });
    this.setState({
      selected: [],
    });
    //await campaign.getContactsByCampaign(campaignId);
    await campaign.getCampaignId(campaignId);

    await contacts.getContacts({
      skip: 0,
      filter: `campaigns/any(c:c eq '${campaignId}')`,
      orderby: "created asc",
    });

    app.changeTableContentLoaderStatus(false);
  };

  menuClasses = (index) => {
    if (this.props.contacts.activity.length > 6) {
      if (index > this.props.contacts.activity.length - 3) {
        return "position-top";
      }
    }
    return "position-bottom";
  };

  upwardController = (index) => {
    if (this.props.contacts.activity.length > 6) {
      if (index > this.props.contacts.activity.length - 3) {
        return true;
      }
    }
    return false;
  };

  buttonsActions = (row, index) => {
    const openLinkedinProfile = async () => {
      await linkedInProfileOpener.open(this.props.app.connector, row);
    };

    return (
      row.actions && (
        <div className="btn-group">
          <TooltipCustom
            title={
              !this.state.dropDownController
                ? row.actions.show_details.hint
                : ""
            }
            arrow={true}
          >
            <Dropdown
              upward={this.upwardController(index)}
              className={
                row.actions.show_details.enabled
                  ? "btn-accent btn-drop"
                  : "btn-row btn-drop disabled"
              }
              onOpen={() =>
                row.actions.show_details.enabled &&
                this.dropDownController(
                  true,
                  row.campaignExecutionId,
                  row.stepNumber
                )
              }
              onClose={() => this.dropDownController(false)}
              icon={
                <img className="icon" src={infoBlackIcon} alt="infoBlackIcon" />
              }
              direction="left"
            >
              <Dropdown.Menu
                className={`activity-info ${this.menuClasses(index)}`}
              >
                <Dropdown.Item>
                  <div className="drop-down-container">
                    <span className="drop-down-container__label">
                      Action #{row.indexAction}:
                    </span>
                    <h5 className="headline">{row.header}</h5>
                    <div className="drop-down-container__description">
                      <div className="text-wrapper">
                        <div className="text-dropdown-p">
                          {!this.state.actionMessageLoader ? (
                            <pre className="word-break">
                              {this.state.actionMessage}
                            </pre>
                          ) : (
                            <Loader size="small" active={true} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </TooltipCustom>

          <TooltipCustom
            title={row.actions.open_linkedin_profile.hint}
            arrow={true}
          >
            <button
              className={
                row.actions.open_linkedin_profile.enabled
                  ? "btn-row info"
                  : "btn-row info disabled"
              }
              onClick={openLinkedinProfile}
            >
              <img src={inIcon} alt="linkedin icon" />
            </button>
          </TooltipCustom>

          <TooltipCustom
            title={row.actions.open_conversation.hint}
            arrow={true}
          >
            <button
              className={
                row.actions.open_conversation.enabled
                  ? "btn-row info"
                  : "btn-row info disabled"
              }
              onClick={() => {
                row.actions.open_conversation.enabled &&
                  window.open(row.conversation_url, "_blank");
              }}
            >
              <img src={iconMessage} alt="messageIcon" />
            </button>
          </TooltipCustom>

          <TooltipCustom title={row.actions.open_campaign.hint} arrow={true}>
            <button
              className={
                row.actions.open_campaign.enabled
                  ? "btn-row info"
                  : "btn-row info disabled"
              }
              onClick={() =>
                row.actions.open_campaign.enabled &&
                this.props.history.push(`/campaigns/${row.campaign_id}`)
              }
            >
              <img src={iconArrow} alt="arrowIcon" />
            </button>
          </TooltipCustom>
        </div>
      )
    );
  };

  findColor = (state) => {
    switch (state) {
      case "findColor":
        return "red";

      default:
        break;
    }
  };

  renderSteps = (row, item) => {
    let items = [];
    for (let index in row) {
      row[index].is_visible &&
        items.push(
          <TooltipCustom key={index} title={row[index].hint} arrow={true}>
            <div
              className={`steps-container-item`}
              style={{
                background: `${statesDescriptor.detectColor(
                  item.human_state,
                  row[index]
                )}`,
              }}
            ></div>
          </TooltipCustom>
        );
    }

    return items;
  };

  buildStartIn = (value) => {
    if (value === 0) {
      return "processing";
    }
    if (value < 60) {
      return `${value} min`;
    }

    if (value >= 60 && value < 1440) {
      let getHours = Math.floor(value / 60);
      let lastMinute = value - getHours * 60;
      let hourName = getHours > 1 ? "hours" : "hour";

      return `${getHours}${" "} ${hourName} ${
        lastMinute > 0 ? `${lastMinute} min` : ""
      }`;
    }

    if (value > 1440) {
      let getDays = Math.floor(value / 1440);
      let lastHour = Math.floor((value - getDays * 1440) / 60);
      let lastMinute = value - getDays * 1440 - lastHour * 60;
      let hourName = lastHour > 1 ? "hours" : "hour";

      return `${getDays > 1 ? `${getDays} days` : `${getDays} day`} ${
        lastHour > 0 ? `${lastHour} ${" "} ${hourName}` : ""
      }  ${lastMinute > 0 ? `${lastMinute} min` : ""}`;
    }

    return value;
  };

  render() {
    return (
      <div
        className={`table-container ${this.props.classesContainer}`} /*className={`table-container ${this.props.classesContainer} ${
        this.props.contacts.total &&
        this.props.contacts.total > this.state.rowsPerPage
          ? "table-with-footer"
          : ""
      }`}*/
      >
        <TableContainer
          className={
            this.props.checkboxs &&
            (!this.props.campaignStatus ||
              this.props.campaignStatus === "Compose")
              ? "table-body with-checkboxs"
              : "table-body single-contacts"
          }
        >
          {this.props.app.tableContentLoader &&
            !this.props.app.pageContentLoader && (
              <div className="table-material-loader-wrapper">
                <CircularProgress
                  color="primary"
                  size={60}
                  classes={{ colorPrimary: "material-loader" }}
                />
              </div>
            )}
          <Table
            className={"table users"}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell className="name-activities">
                  {this.state.selected.length > 0 ? (
                    <div className="selected-rows">
                      {this.state.selected.length ===
                      this.props.contacts.activity.length ? (
                        <>
                          <span className="select-text">
                            {this.state.selected.length} selected |{" "}
                          </span>
                          <span
                            className="clear-all"
                            onClick={this.onClearAllClick}
                          >
                            Clear
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="select-text">
                            {this.state.selected.length} of{" "}
                            {this.props.contacts.activity.length} selected |{" "}
                          </span>
                          <span
                            className="clear-all"
                            onClick={this.onSelectAllClick}
                          >
                            Select all
                          </span>
                        </>
                      )}
                    </div>
                  ) : (
                    "Contact"
                  )}
                </TableCell>
                {/* <TableCell className="company">Company</TableCell> */}
                <TableCell className="campaign">Campaign</TableCell>
                <TableCell className="activity">Activity</TableCell>

                <TableCell className="steps">Status</TableCell>

                <TableCell className="started">Starts In</TableCell>
              </TableRow>
            </TableHead>
            <TableBody ref={this.wrapper}>
              {this.props.contacts.activity
                // .slice(
                //   this.state.page * this.state.rowsPerPage,
                //   this.state.page * this.state.rowsPerPage +
                //     this.state.rowsPerPage
                // )
                .map((row, index) => (
                  <TableRow key={row.id} className="row">
                    <TableCell className="name-activities">
                      <div className="name-container">
                        <img
                          className="avatar"
                          src={row.avatar ? row.avatar : avatarSvg}
                          alt="avatar"
                        />
                        <div className="profile text-box-wrapper">
                          <div className="text-page first-name semi-bold ellipsis">
                            {row.fullName}
                          </div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell className="text-page campaign regular">
                      <div className="text-box-wrapper">
                        {row.campaign_name}
                      </div>
                    </TableCell>
                    <TableCell className={`text-page activity regular`}>
                      <div className="text-box-wrapper">{row.activity}</div>
                    </TableCell>
                    <TableCell className="steps">
                      <div className="steps-container">
                        {this.renderSteps(row.campaignExecutions, row)}
                      </div>
                    </TableCell>
                    <TableCell
                      className="started"
                      style={{
                        width: this.state.scrollBarWidth ? "19%" : "20%",
                      }}
                    >
                      <div className="start-wrapper">
                        <div className="start-end-date">
                          {this.buildStartIn(row.start_in)}
                        </div>
                        {this.buttonsActions(row, index)}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/*<div className="table-footer">*/}
        {/*  <div className="table-footer-pagination">*/}
        {/*    {!!this.props.contacts.total &&*/}
        {/*      this.props.contacts.total > this.state.rowsPerPage && (*/}
        {/*        <TablePagination*/}
        {/*          component="div"*/}
        {/*          rowsPerPageOptions={[]}*/}
        {/*          count={this.props.contacts.total}*/}
        {/*          rowsPerPage={this.state.rowsPerPage}*/}
        {/*          page={this.state.page}*/}
        {/*          onPageChange={this.handleChangePage}*/}
        {/*          ActionsComponent={(subProps) => (*/}
        {/*            <MTablePagination {...subProps} />*/}
        {/*          )}*/}
        {/*        />*/}
        {/*      )}*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    );
  }
}

export default TableActivity;
