import React, { useState } from "react";
import ResponsiveButton from "../../atoms/responsiveButton";
import { teams } from "../../../actions/teams";
import { CONSTS } from "../../../config/objectConst";
import { listeners } from "../../../api/listeners";
import {
  notification,
  isSubscriptionExpired,
} from "../../../actions/notification";
import { setTabs } from "../../../pages/team/functions";
import "./style.scss";

const ModalPersonas = ({ setIsModalInvitedOpen, getPersonas }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isEmptyField, setIsEmptyField] = useState(false);

  async function refreshAccessToken() {
    const connector = listeners.getConnector();
    await connector.auth.refreshAccessToken();
  }

  const sendInvite = async () => {
    setLoading(true);
    try {
      const response = await teams.inviteMember(email.trim());
      if (response?.status === 200) {
        await refreshAccessToken();
        await getPersonas();

        notification.snacknotificationUse({
          open: true,
          title: CONSTS.notification["n3.23"].title,
          content: CONSTS.notification["n3.23"].content,
          style: CONSTS.notification["n3.23"].style,
        });
        setIsModalInvitedOpen(false);
        setTabs.call(this, false);
      }
    } catch (error) {
      if (isSubscriptionExpired(error)) {
        setIsModalInvitedOpen(false);
      } else {
        setError(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="modal-personas-invite">
      <h2 className="modal-personas-invite__title">Invite new member</h2>
      <p className="modal-personas-invite__description">
        Onboard your team member by sending an invite
      </p>
      <div className="modal-personas-invite__container-input">
        <input
          className="mail-input-personas-invite"
          type="mail"
          placeholder="email"
          value={email}
          autoFocus
          onChange={(e) => {
            setEmail(e.target.value);
            setIsEmptyField(false);
            setError("");
          }}
        />
        <ResponsiveButton
          className="btn bold invite-button-personas"
          onClick={() => {
            email.trim() && sendInvite();
            !email.trim() && setIsEmptyField(true);
          }}
          isLoading={loading}
        >
          {"Invite member"}
        </ResponsiveButton>
      </div>
      <div className="container-relative">
        <>
          {error && (
            <div className="container-text-error-personas">
              <p className="text-error-personas">{error}</p>
            </div>
          )}
          {isEmptyField && (
            <div className="container-text-error-personas">
              <p className="text-error-personas">Please enter email</p>
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default ModalPersonas;
