import { CONSTS } from "../../../config/objectConst";

export const subscriptionHelper = {
  getStatusTags: (subscription) => {
    const result = [];
    if (subscription.is_active) {
      result.push({
        text: "Active",
        style: "containerBilling__name-bold-green",
      });
    } else {
      result.push({
        text: "Inactive",
        style: "containerBilling__name-bold-grey",
      });
    }

    if (subscription.state === CONSTS.SUBSCRIPTIONS.STATE.CANCELED) {
      result.push({
        text: "Canceled",
        style: "containerBilling__name-bold-red",
      });
    }

    if (subscription.state === CONSTS.SUBSCRIPTIONS.STATE.OVERDUE) {
      result.push({
        text: "Overdue",
        style: "containerBilling__name-bold-orange",
      });
    }

    return result;
  },

  showNextCharge: (subscription) => {
    let result = subscription.state === CONSTS.SUBSCRIPTIONS.STATE.ACTIVE;
    return result;
  },

  showNextChargePrice: (subscription) => {
    let result = subscription.actual_price !== 0;
    return result;
  },
};
