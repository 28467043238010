import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// Components
import HomeTemplate from "../../components/templates/homeTemplate";
import TableCampaigns from "../../components/molecules/tableCampaigns";
// Actions
import { app } from "../../actions/app";
import { campaign } from "../../actions/campaign";
import { mapping } from "../../actions/mapping";
import { CircularProgress } from "@material-ui/core";
import { filters } from "../../actions/filters";
import CampaignFilters from "../../components/molecules/CampaignFilters";
import NoFilterCampaignsTemplate from "../../components/templates/noFilterCampaignsTemplate";

export class ArchiveCampaignsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalController: false,
      companyName: "",
      isSearchOrFilterApplied: false,
      tableCampaignsStatisticsPage: 0,
    };
  }
  async componentDidMount() {
    app.changePage("/campaigns");
    app.changeActiveItem(3);
    app.changePageContentLoaderStatus(true);
    campaign.getRecentCampaigns();
    await filters.getCampaigns({
      archived: true,
      keywords: this.props.searchArchiveValue,
    });
    app.changePageContentLoaderStatus(false);
  }

  buildFiltersArray = (filters) => {
    const filtersArray = [];
    if (filters.active) {
      filtersArray.push(`(state eq 'Running')`);
    }
    if (filters.stopped) {
      filtersArray.push(`(state eq 'Stopped')`);
    }
    if (filters.completed) {
      filtersArray.push(`(state eq 'Completed')`);
    }
    if (filters.drafted) {
      filtersArray.push(`(state eq 'Compose')`);
    }

    return filtersArray;
  };

  render() {
    return (
      <HomeTemplate>
        {this.props.app.pageContentLoader && (
          <div className="material-loader-wrapper">
            <CircularProgress
              color="primary"
              size={60}
              classes={{ colorPrimary: "material-loader" }}
            />
          </div>
        )}
        <CampaignFilters
          archive
          isSearchOrFilterApplied={(value) =>
            this.setState({ isSearchOrFilterApplied: value })
          }
          filters={this.props.filters}
          searchValue={this.props.searchValue}
          buildFiltersArray={this.buildFiltersArray}
          setTableCampaignsStatisticsPage={(value) =>
            this.setState({ tableCampaignsStatisticsPage: value })
          }
          tableCampaignsStatisticsPage={this.state.tableCampaignsStatisticsPage}
        />
        {this.props.campaignsCount === 0 &&
        this.state.isSearchOrFilterApplied ? (
          <NoFilterCampaignsTemplate />
        ) : (
          <TableCampaigns
            archive={true}
            filters={this.props.filters}
            buildFiltersArray={this.buildFiltersArray}
            campaignsCount={this.props.campaignsCount}
            searchValue={this.props.searchArchiveValue}
            history={this.props.history}
            campaigns={mapping
              .campaignsForTable(this.props.campaigns)
              .reverse()}
            app={this.props.app}
            setTableCampaignsStatisticsPage={(value) =>
              this.setState({ tableCampaignsStatisticsPage: value })
            }
            tableCampaignsStatisticsPage={
              this.state.tableCampaignsStatisticsPage
            }
          />
        )}
      </HomeTemplate>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    app: state.app,
    filters: state.filters,
    createCampaignController: state.campaign.createCampaignController,
    campaignsCount: state.campaigns.campaignsCount,
    campaigns: state.campaigns.campaigns,
    haveCampaigns: state.campaigns.haveCampaigns,
    searchValue: state.campaigns.searchValue,
    leftSide: state.leftSideMenu.leftSide,
  };
};

export default connect(mapStateToProps)(withRouter(ArchiveCampaignsPage));
