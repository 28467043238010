class BackendValidationError extends Error {
  #errors;
  constructor(errors) {
    super(errors[0].description);
    this.#errors = errors;
    this.name = "BackendValidationError";

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, BackendValidationError);
    }
  }
  get axios() {
    return this.#errors;
  }
}

export default BackendValidationError;
