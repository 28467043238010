import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./style.scss";
import HomeTemplate from "../../../components/templates/homeTemplate";
import imagesAdd from "../../../assets/image/icons/buttons/add.svg";
import ResponsiveButton from "../../../components/atoms/responsiveButton";
import TableTeams from "../../../components/molecules/TableTeams";
import ErrorNotification from "../../../components/atoms/ErrorNotification";
import ModalWindow from "../../../components/molecules/ModalWindow";
import ModalPersonas from "../../../components/molecules/ModalPersonas";
import ModalNotTeam from "../../../components/molecules/ModalNotTeam";
import ModalIncludesTeam from "../../../components/molecules/ModalIncludesTeam";
import { CircularProgress } from "@material-ui/core";
import UpgradeSubscriptionTeam from "../../../components/molecules/UpgradeSubscriptionTeam";
import { app } from "../../../actions/app";
import {
  getMembers,
  openLinkedinProfile,
  showAddNewMember,
  showMembers,
  showOnBoardingScreen,
  showRequirePermissions,
  showRequireSubscriptionUpgrade,
  updateComponentTeam,
  mountComponentTeam,
} from "../functions";

export class MembersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderby: "created desc",
      isModalInvitedOpen: false,
      personas: [],
      isInviteButtonVisible: null,
      localAppProfile: this.props.appProfile,
      loader: false,
      loaderStatistics: false,
      members: null,
    };
  }

  componentDidMount() {
    mountComponentTeam.call(this);
  }
  componentDidUpdate(prevProps, prevState) {
    app.changeActiveItem(1);
    updateComponentTeam.call(this, prevProps, prevState);
  }

  render() {
    return (
      <HomeTemplate id="teams">
        {(!this.props.app.linkedInAuth ||
          !this.props.app.associatedLinkedInProfile) && (
          <ErrorNotification>
            <div className="error-notification__not-auth">
              You are not logged in to LinkedIn with{" "}
              <span onClick={openLinkedinProfile.bind(this)}>
                {`${this.props.profile.linkedInfo.givenName} ${this.props.profile.linkedInfo.familyName} account.`}
              </span>
            </div>
          </ErrorNotification>
        )}

        <>
          <ModalWindow
            open={this.state.isModalInvitedOpen}
            classes="invite-modal"
            closeHandler={() => this.setState({ isModalInvitedOpen: false })}
          >
            <ModalPersonas
              getPersonas={getMembers.bind(this)}
              setIsModalInvitedOpen={(value) =>
                this.setState({
                  isModalInvitedOpen: value,
                })
              }
            />
          </ModalWindow>
          <div className="flex-container-team-members">
            {showMembers.call(this) && (
              <h2 className="teams-main-title-members">Members</h2>
            )}{" "}
            {showAddNewMember.call(this) && (
              <ResponsiveButton
                className="btn bold button-member"
                onClick={() => this.setState({ isModalInvitedOpen: true })}
                isLoading={false}
                disabled={false}
              >
                <>
                  <img src={imagesAdd} alt="timeIcon" />
                  <span>Invite new member</span>
                </>
              </ResponsiveButton>
            )}
          </div>

          {this.state.loader && (
            <div className="material-loader-wrapper">
              <CircularProgress
                color="primary"
                size={60}
                classes={{ colorPrimary: "material-loader" }}
              />
            </div>
          )}
          {showRequirePermissions.call(this) && <ModalIncludesTeam />}
          {showOnBoardingScreen.call(this) && (
            <ModalNotTeam
              setIsModalInvitedOpen={(value) =>
                this.setState({
                  isModalInvitedOpen: value,
                })
              }
            />
          )}
          {showRequireSubscriptionUpgrade.call(this) && (
            <UpgradeSubscriptionTeam />
          )}
          {showMembers.call(this) && (
            <TableTeams
              history={this.props.history}
              personas={this.state.personas}
              getPersonas={getMembers.bind(this)}
              localAppProfile={this.state.localAppProfile}
            />
          )}
        </>
      </HomeTemplate>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    app: state.app,
    profile: state.user.profileFull,
    appProfile: state.app.appProfile,
    leftSide: state.leftSideMenu.leftSide,
  };
};

export default connect(mapStateToProps)(withRouter(MembersPage));
