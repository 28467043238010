import React from "react";
import "./style.scss";

import dangerIcon from "../../../assets/image/icons/svg/icons/danger.svg";

const ErrorNotification = ({ children }) => {
  return (
    <div className="error-notification">
      <img
        src={dangerIcon}
        alt="danger icon"
        className="error-notification__icon"
      />
      {children}
    </div>
  );
};

export default ErrorNotification;
