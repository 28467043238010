import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./style.scss";
import HomeTemplate from "../../components/templates/homeTemplate";
import { CONSTS } from "../../config/objectConst";
import doneSvg from "../../assets/image/icons/svg/done.svg";

import board from "../../assets/image/board.png";
import { notification } from "../../actions/notification";
import { api } from "../../api/api";
import { CircularProgress } from "@material-ui/core";
import store from "../../redux/store";
import ModalWindow from "../../components/molecules/ModalWindow";

const UpgradePage = () => {
  const { profileFull } = useSelector((state) => state.user);
  const { connector } = useSelector((state) => state.app);
  const [subscription, setSubscription] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [coupon, setCoupon] = useState(null);
  const [isUpgradedModalOpened, setIsUpgradedModalOpened] = useState(false);
  const [isDowngradeModalOpened, setIsDowngradeModalOpened] = useState(false);
  const [activeId, setActiveId] = useState("");

  useEffect(() => {
    let response = {
      type: "upgrade",
    };
    store.dispatch({
      type: "LEFT_SIDE_MENU_LIST",
      payload: {
        leftSide: response,
      },
    });
  }, []);

  const plans = [
    {
      id: CONSTS.SUBSCRIPTIONS.BASIC.ID,
      active:
        subscription &&
        subscription.is_active &&
        (subscription.plan_id === CONSTS.SUBSCRIPTIONS.EXPERIMENTAL.ID ||
          subscription.plan_id === CONSTS.SUBSCRIPTIONS.BASIC.ID),
      path: CONSTS.SUBSCRIPTIONS.BASIC.ID,
      name:
        subscription?.plan_id === CONSTS.SUBSCRIPTIONS.EXPERIMENTAL.ID &&
        subscription?.is_active
          ? CONSTS.SUBSCRIPTIONS.EXPERIMENTAL.NAME
          : CONSTS.SUBSCRIPTIONS.BASIC.NAME,
      description:
        subscription?.plan_id === CONSTS.SUBSCRIPTIONS.EXPERIMENTAL.ID &&
        subscription?.is_active
          ? "Includes bonus features for early-birds!"
          : "Your basic snaily that searching for leads",
      price:
        subscription?.plan_id === CONSTS.SUBSCRIPTIONS.EXPERIMENTAL.ID &&
        subscription?.is_active
          ? CONSTS.SUBSCRIPTIONS.EXPERIMENTAL.PRICE
          : CONSTS.SUBSCRIPTIONS.BASIC.PRICE,
      arrayData: [
        "Connect people",
        "Export & Filter contacts",
        "Send messages",
        "Campaign statistics",
        "Send auto follow ups",
        "Auto withdraw invites",
        "CSV Export contacts",
        "Unlimited campaigns",
      ],
    },
    {
      id: CONSTS.SUBSCRIPTIONS.PRO.ID,
      active:
        subscription?.is_active &&
        subscription?.plan_id === CONSTS.SUBSCRIPTIONS.PRO.ID,
      path: CONSTS.SUBSCRIPTIONS.PRO.ID,
      name: CONSTS.SUBSCRIPTIONS.PRO.NAME,
      description: "For pro hunters that require  extended features",
      price: CONSTS.SUBSCRIPTIONS.PRO.PRICE,
      arrayData: [
        "Basic features",
        "Cloud automation",
        "Sales navigator",
        "Activities log CSV export",
        "Zapier - under construction",
      ],
    },
    {
      id: CONSTS.SUBSCRIPTIONS.TEAM.ID,
      active:
        subscription?.is_active &&
        subscription?.plan_id === CONSTS.SUBSCRIPTIONS.TEAM.ID,
      path: CONSTS.SUBSCRIPTIONS.TEAM.ID,
      name: CONSTS.SUBSCRIPTIONS.TEAM.NAME,
      description: "For teams and companies",
      price: CONSTS.SUBSCRIPTIONS.TEAM.PRICE,
      arrayData: ["Pro features", "Team work"],
    },
  ];

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      try {
        if (store.getState().app.subscription === null) {
          await getSubscription();
        } else {
          setSubscription(store.getState().app.subscription);
        }
      } catch (e) {
        notification.notificationUse(e);
      } finally {
        setIsLoading(false);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    setSubscription(store.getState().app.subscription);
  }, [store.getState().app.subscription]);

  const openCheckout = (persona, subscriptionPath, coupon) => {
    let session = {
      reset: true,
      paymentContact: {
        email: persona.email,
        firstName: persona.givenName,
        lastName: persona.familyName,
        country: "LI",
      },
      products: [
        {
          path: subscriptionPath,
          quantity: 1,
        },
      ],
      tags: {
        personaId: persona.personaId,
        ownerId: persona.personaId,
        version: "1.0",
      },
      coupon: coupon,
    };
    window.fastspring.builder.push(session);
    window.fastspring.builder.checkout();
  };

  window.fastSpringCheckoutClosed = fastSpringCheckoutClosed;

  const downgradePlan = (id) => {
    setIsDowngradeModalOpened(true);
    setActiveId(id);
  };

  const upgradePlan = (id) => {
    setIsUpgradedModalOpened(true);
    setActiveId(id);
  };

  const changeSubscription = (id) => {
    let plan = plans.find((plan) => plan.id === id);
    if (subscription.base_price < plan.price) {
      upgradePlan(id);
    } else {
      downgradePlan(id);
    }
  };

  async function fastSpringCheckoutClosed(data) {
    if (data) {
      setIsLoading(true);
      try {
        const response = await api.ordersSync(data.id, data.reference);
        if (response.status === 200) {
          await connector.auth.refreshAccessToken();
          await getSubscription();
          notification.snacknotificationUse({
            open: true,
            title: CONSTS.notification["3.19"].title,
            content: CONSTS.notification["3.19"].content,
            style: CONSTS.notification["3.19"].style,
          });
        }
      } catch (e) {
        notification.notificationUse(e);
      } finally {
        setIsLoading(false);
      }
    }
  }

  const getSubscription = async () => {
    try {
      const subscription = await api.getSubscription();
      setSubscription(subscription.data);
    } catch (e) {
      notification.notificationUse(e);
    }
  };
  const upgradeSubscription = async (planId) => {
    setIsUpgradedModalOpened(false);
    setIsDowngradeModalOpened(false);
    setIsLoading(true);
    try {
      const response = await api.upgradeSubscription(planId);
      if (response.status === 200) {
        await getSubscription();
        await connector.auth.refreshAccessToken();
        notification.snacknotificationUse({
          open: true,
          title: CONSTS.notification["n3.22"].title,
          content: CONSTS.notification["n3.22"].content,
          style: CONSTS.notification["n3.22"].style,
        });
      }
    } catch (e) {
      notification.notificationUse(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <HomeTemplate>
      {!isLoading && (
        <div className="containerUpgrade">
          <h2 className="containerUpgrade__main-title">
            Choose subscription plan
          </h2>

          <div className="containerUpgrade__wrapper">
            <img
              src={board}
              alt="board"
              className="containerUpgrade__team-img"
            />
            {plans.map((plan, i) => (
              <div
                key={i}
                className={
                  plan.active
                    ? "containerUpgrade__individual-active"
                    : "containerUpgrade__individual"
                }
              >
                <h2 className="containerUpgrade__title-accent">{plan.name}</h2>
                <p className="containerUpgrade__description">
                  {plan.description}
                </p>
                <div className="containerUpgrade__line"></div>
                <p className="containerUpgrade__price">
                  {plan.price}$ per month
                </p>
                {
                  <>
                    {plan.active ? (
                      <button
                        className={
                          "containerUpgrade__button-subscription-active"
                        }
                      >
                        Current plan
                      </button>
                    ) : (
                      <button
                        onClick={() =>
                          subscription.plan_id ===
                            CONSTS.SUBSCRIPTIONS.TRIAL.ID ||
                          !subscription.is_active
                            ? openCheckout(
                                profileFull.appProfile,
                                plan.path,
                                coupon
                              )
                            : changeSubscription(plan.id)
                        }
                        className={"containerUpgrade__button-subscription"}
                      >
                        Upgrade subscription
                      </button>
                    )}
                  </>
                }
                <p className="containerUpgrade__text">Features overview:</p>
                <ul className="containerUpgrade__list">
                  {plan.arrayData.map((item) => (
                    <li key={item} className="containerUpgrade__item">
                      <img
                        className="containerUpgrade__img"
                        src={doneSvg}
                        alt="done"
                      />
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="containerUpgrade__container-link">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="containerUpgrade__link"
              href="https://snaily.io/terms-and-conditions/"
            >
              Terms and Conditions
            </a>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="material-loader-wrapper">
          <CircularProgress
            color="primary"
            size={60}
            classes={{ colorPrimary: "material-loader" }}
          />
        </div>
      )}
      <ModalWindow
        open={isUpgradedModalOpened}
        closeText="No"
        confirmText="Yes"
        title={CONSTS.confirmationDialogs[1.11].title}
        content={CONSTS.confirmationDialogs[1.11].content}
        closeHandler={() => setIsUpgradedModalOpened(false)}
        confirmHandler={() => upgradeSubscription(activeId)}
      />
      <ModalWindow
        open={isDowngradeModalOpened}
        closeText="No"
        confirmText="Yes"
        title={CONSTS.confirmationDialogs[1.12].title}
        content={CONSTS.confirmationDialogs[1.12].content}
        closeHandler={() => setIsDowngradeModalOpened(false)}
        confirmHandler={() => upgradeSubscription(activeId)}
      />
    </HomeTemplate>
  );
};

export default UpgradePage;
