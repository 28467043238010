import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { CircularProgress } from "@material-ui/core";
import { teams } from "../../../actions/teams";
import "./style.scss";
import { connect } from "react-redux";
import { CONSTS } from "../../../config/objectConst";
import { notification } from "../../../actions/notification";
import ModalWindow from "../ModalWindow";
import ResponsiveButton from "../../atoms/responsiveButton";
import { functions } from "../../../tools/functions";
import TooltipCustom from "../../atoms/tooltipCustom";
import emailSvg from "../../../assets/image/icons/svg/reSentEmail.svg";
import deleteInviteSvg from "../../../assets/image/icons/svg/deleteInvite.svg";

export class TableTeamsInvite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      isDeleteInviteOpened: false,
      isDeleteInviteLoading: false,
      isSentInviteLoading: false,
      id: "",
    };
  }

  reSendInvited = async (id) => {
    this.setState({ isSentInviteLoading: true });
    try {
      await teams.resendInvitation(id);
      notification.snacknotificationUse({
        open: true,
        title: CONSTS.notification["n3.27"].title,
        content: CONSTS.notification["n3.27"].content,
        style: CONSTS.notification["n3.27"].style,
      });
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      this.setState({ isSentInviteLoading: false });
    }
  };

  deleteTeamMember = async (persona) => {
    this.setState({ isDeleteInviteOpened: false });
    this.setState({ isDeleteInviteLoading: true });
    try {
      const response = await teams.deleteMember(persona.id);
      if (response.status === 200) {
        await this.getMember();
        notification.snacknotificationUse({
          open: true,
          title: CONSTS.notification["n3.28"].title,
          content: CONSTS.notification["n3.28"].content.replace(
            "{email}",
            persona.email
          ),
          style: CONSTS.notification["n3.28"].style,
        });
      }
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      this.setState({ isDeleteInviteLoading: false });
    }
  };

  getMember = async () => {
    await this.props.getPersonas();
  };

  render() {
    return (
      <div className="table-container container-height">
        <TableContainer
          className={this.props.checkboxs && "table-body with-checkboxs"}
        >
          {this.state.loader && (
            <div className="table-material-loader-wrapper">
              <CircularProgress
                color="primary"
                size={60}
                classes={{ colorPrimary: "material-loader" }}
              />
            </div>
          )}
          <Table
            className={"table users"}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell className="email-invite">Email</TableCell>
                <TableCell className={"connection-invite"}>
                  <div className="container-title-images">Status</div>
                </TableCell>
                <TableCell className="connection-bottom">
                  <div className="container-title-images">Invited</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody ref={this.wrapper}>
              {this.props.personas
                .filter((el) => el.pending)
                .map((row, index) => (
                  <TableRow key={row.id} className="row">
                    <TableCell className="email-invite">
                      <p className={"left-list-text"}>{row.email}</p>
                    </TableCell>
                    <TableCell className="connection-invite">
                      <p className={"left-list-text-accent"}>Pending</p>
                    </TableCell>
                    <TableCell className="connection-bottom">
                      <div className="flex-container-team">
                        <p className={"left-list-text-accent"}>
                          {functions.parseDateToDMY(row.added, "/")}
                        </p>
                        <div className="wrapper-team-button">
                          <ResponsiveButton
                            className={"team-button-resend"}
                            onClick={() => {
                              this.reSendInvited(row.id);
                              this.setState({ id: row.id });
                            }}
                            isLoading={
                              this.state.id === row.id &&
                              this.state.isSentInviteLoading
                            }
                            disabled={false}
                          >
                            <TooltipCustom
                              title="resend invitation"
                              arrow={true}
                            >
                              <img
                                className={"team-reSent-image"}
                                alt="emailSvg"
                                src={emailSvg}
                              />
                            </TooltipCustom>
                          </ResponsiveButton>
                          <ResponsiveButton
                            className={"team-button-delete-image"}
                            onClick={() => {
                              this.setState({ isDeleteInviteOpened: true });
                              this.setState({ selectedPersona: row });
                            }}
                            isLoading={
                              this.state.selectedPersona?.id === row.id &&
                              this.state.isDeleteInviteLoading
                            }
                            disabled={false}
                          >
                            <TooltipCustom
                              title="revoke invitation"
                              arrow={true}
                            >
                              <img
                                className={"team-re-sent-image"}
                                alt="deleteInviteSvg"
                                src={deleteInviteSvg}
                              />
                            </TooltipCustom>
                          </ResponsiveButton>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ModalWindow
          open={this.state.isDeleteInviteOpened}
          closeText="No"
          confirmText="Yes"
          closeHandler={() => this.setState({ isDeleteInviteOpened: false })}
          confirmHandler={() =>
            this.deleteTeamMember(this.state.selectedPersona)
          }
          title={CONSTS.confirmationDialogs["c1.13"].title}
          content={CONSTS.confirmationDialogs["c1.13"].content.replace(
            "{email}",
            this.state.selectedPersona?.email ?? ""
          )}
        />
      </div>
    );
  }
}

export default connect()(TableTeamsInvite);
