/*
  This All action on api
  https://github.com/public-apis/public-apis
*/
import { CONSTS } from "../config/objectConst";
import { httpRequest } from "./httpRequest";
import { contactsPageSize } from "./contactsPageSize";

const createParams = (data) => {
  let { skip, archived, filter, keywords, orderby } = data;
  let archivedParam = "";
  let statuses = filter ? filter : "";
  let resultFilters = "";
  let take = CONSTS.PAGE_SIZE;
  if (!orderby) {
    orderby = orderby ? orderby : "created desc";
  }
  if (archived !== undefined) {
    archivedParam = `archived eq ${archived}`;
  }
  if (archivedParam || statuses) {
    resultFilters = `&$filter=${archivedParam}${
      statuses ? ` ${archivedParam ? "and" : ""} ${statuses}` : ""
    }`;
  }

  return `?${take ? "&$take=" + take : ""}${
    skip ? "&$skip=" + skip : ""
  }${resultFilters}${keywords ? "&$searchQuery=" + keywords : ""}${
    orderby ? "&$orderby=" + orderby : ""
  }`;
};

export const api = {
  logoutAction: async (connector, history) => {
    await connector.auth.signOut();
  },
  getRecentImportsApi: async () => {
    return await httpRequest({
      url: `${CONSTS.API.URL}imports/recent`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getImportsApi: async (id) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}imports/${id}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getContactsExecutionsApi: async (data) => {
    let { skip, filter, keywords, orderby } = data;
    let take = contactsPageSize.get();
    let newKeywords = keywords;
    if (keywords && keywords.includes("\\*")) {
      newKeywords = keywords.replaceAll("\\*", "*");
    }
    const params = `?${take ? "&$take=" + take : ""}${
      skip ? "&$skip=" + skip : ""
    }${filter ? "&$filter=" + filter : ""}${
      newKeywords ? "&$searchQuery=" + newKeywords : ""
    }${orderby ? "&$orderby=" + orderby : ""}`;
    return await httpRequest({
      url: `${CONSTS.API.URL}executions/page${params}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getContactsApi: async (data) => {
    let { skip, filter, keywords, orderby } = { ...data };
    const take = contactsPageSize.get();
    if (!orderby) {
      orderby = "imported desc";
    }
    let newKeywords = keywords;
    if (keywords && keywords.includes("\\*")) {
      newKeywords = keywords.replaceAll("\\*", "*");
    }
    const params = `?${take ? "&$take=" + take : ""}${
      skip ? "&$skip=" + skip : ""
    }${filter ? "&$filter=" + filter : ""}${
      newKeywords ? "&$searchQuery=" + newKeywords : ""
    }${orderby ? "&$orderby=" + orderby : ""}`;
    return await httpRequest({
      url: `${CONSTS.API.URL}contacts/page${params}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getTeamMembers: async () => {
    return await httpRequest({
      url: `${CONSTS.API.URL}team/personas`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  inviteTeamMember: async (email) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}team/personas/invite`,
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },

      data: {
        email,
      },
    });
  },
  resendTeamInvitation: async (id) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}team/personas/${id}/resend-invitation`,
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },

  updateRoleApi: async (id, role) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}team/personas/${id}`,
      method: "PUT",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      data: {
        role,
      },
    });
  },
  deleteTeamMember: async (id) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}team/personas/${id}`,
      method: "DELETE",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  cancelTeamMemberSubscriptionApi: async (id) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}team/personas/${id}/subscription/cancel`,
      method: "PUT",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  resumeTeamMemberSubscriptionApi: async (id) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}team/personas/${id}/subscription/resume`,
      method: "PUT",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  acceptTeamInvitation: async (code) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}team/personas/accept-invitation`,
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },

      data: {
        code,
      },
    });
  },

  buyMemberSubscriptionApi: async (id, plan, coupon) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}team/personas/${id}/subscription`,
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },

      data: {
        plan_id: plan,
        coupon,
      },
    });
  },

  upgradeMemberSubscriptionApi: async (id, plan, coupon) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}team/personas/${id}/subscription`,
      method: "PUT",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },

      data: {
        plan_id: plan,
        coupon,
      },
    });
  },

  exportContactsApi: async (data) => {
    let { filter, keywords, orderby } = { ...data };

    if (!orderby) {
      orderby = "imported desc";
    }
    let newKeywords = keywords;
    if (keywords && keywords.includes("\\*")) {
      newKeywords = keywords.replaceAll("\\*", "*");
    }
    const params = `?${filter ? "&$filter=" + filter : ""}${
      newKeywords ? "&$searchQuery=" + newKeywords : ""
    }${orderby ? "&$orderby=" + orderby : ""}`;
    return await httpRequest({
      url: `${CONSTS.API.URL}contacts/export${params}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },

  exportCsvExampleApi: async () => {
    return await httpRequest({
      url: `${CONSTS.API.URL}contacts/csv-example`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },

  deleteContacts: async (data) => {
    let { filter, keywords } = { ...data };
    let newKeywords = keywords;
    if (keywords && keywords.includes("\\*")) {
      newKeywords = keywords.replaceAll("\\*", "*");
    }

    let params = `?${filter ? "$filter=" + filter : ""}`;
    params += `${newKeywords ? "&$searchQuery=" + newKeywords : ""}`;
    return await httpRequest({
      url: `${CONSTS.API.URL}contacts${params}`,
      method: "DELETE",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  previewContacts: async (data) => {
    let { filter, keywords } = { ...data };
    let newKeywords = keywords;
    if (keywords && keywords.includes("\\*")) {
      newKeywords = keywords.replaceAll("\\*", "*");
    }

    let params = `?${filter ? "$filter=" + filter : ""}`;
    params += `${newKeywords ? "&$searchQuery=" + newKeywords : ""}`;
    return await httpRequest({
      url: `${CONSTS.API.URL}contacts/delete/preview${params}`,
      method: "get",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },

  exportContactsApiExecutions: async (data) => {
    let { filter, keywords, orderby } = { ...data };

    if (!orderby) {
      orderby = "imported desc";
    }
    let newKeywords = keywords;
    if (keywords && keywords.includes("\\*")) {
      newKeywords = keywords.replaceAll("\\*", "*");
    }
    const params = `?${filter ? "&$filter=" + filter : ""}${
      newKeywords ? "&$searchQuery=" + newKeywords : ""
    }${orderby ? "&$orderby=" + orderby : ""}`;
    return await httpRequest({
      url: `${CONSTS.API.URL}executions/export${params}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },

  exportRecentActivitiesApi: async (data) => {
    let { filter, keywords } = { ...data };

    let newKeywords = keywords;
    if (keywords && keywords.includes("\\*")) {
      newKeywords = keywords.replaceAll("\\*", "*");
    }

    const params = `?${filter ? "&$filter=" + filter : ""}${
      newKeywords ? "&$searchQuery=" + newKeywords : ""
    }`;
    return await httpRequest({
      url: `${CONSTS.API.URL}recent-activities/export${params}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },

  getContactsCountApi: async (data) => {
    let { keywords, filter } = { ...data };
    const params = `?${keywords ? "&$searchQuery=" + keywords : ""}${
      filter ? "&$filter=" + filter : ""
    }`;
    return await httpRequest({
      url: `${CONSTS.API.URL}contacts/count${params}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getCampaignsApi: async (data) => {
    let { take, skip, archived, filter, keywords, orderby } = { ...data };
    let archivedParam = "";
    let statuses = filter ? filter : "";
    let resultFilters = "";

    if (!take) {
      take = CONSTS.PAGE_SIZE;
    }
    if (!orderby) {
      orderby = orderby ? orderby : "created desc";
    }
    if (archived !== undefined) {
      archivedParam = `(archived eq ${archived})`;
    }
    if (archivedParam || statuses) {
      resultFilters = `&$filter=${archivedParam}${
        statuses ? ` ${archivedParam ? "and" : ""} (${statuses})` : ""
      }`;
    }

    const params = `?${take ? "&$take=" + take : ""}${
      skip ? "&$skip=" + skip : ""
    }${resultFilters}${keywords ? "&$searchQuery=" + keywords : ""}${
      orderby ? "&$orderby=" + orderby : ""
    }`;

    return await httpRequest({
      url: `${CONSTS.API.URL}campaigns/page${params}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },

  getCampaignsTeamApi: async (data) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}team/campaigns${createParams(data)}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
  exportCampaignsTeamApi: async (data) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}team/campaigns/export${createParams(data)}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },

  getContactsLocationApi: async (data) => {
    let { keywords, take } = { ...data };
    if (!take) {
      take = 10;
    }
    const params = `?${keywords ? "&$searchQuery=" + keywords : ""}${
      take ? "&$take=" + take : ""
    }`;
    return await httpRequest({
      url: `${CONSTS.API.URL}contacts/locations${params}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  startCampaignApi: async (id) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}campaigns/${id}/run`,
      method: "PUT",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
  },
  stopCampaignApi: async (id) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}campaigns/${id}/stop`,
      method: "PUT",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
  },
  completeCampaignApi: async (id) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}campaigns/${id}/complete`,
      method: "PUT",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
  },
  cloneCampaignApi: async (id) => {
    let result = await httpRequest({
      url: `${CONSTS.API.URL}campaigns/${id}/clone`,
      method: "PUT",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
    return result;
  },
  getCampaignExecutionsStatisticsApi: async (id) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}campaigns/${id}/executions-statistics`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },

  createLogEnjectService: async (data) => {
    try {
      return await httpRequest({
        url: `${CONSTS.API.URL}logs`,
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        data: data,
      });
    } catch (e) {
      console.error(e);
    }
  },

  setNewData: async (url, data) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}${url}`,
      method: "PUT",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      data: data,
    });
  },
  getWorkingHours: async () => {
    return await httpRequest({
      url: `${CONSTS.API.URL}workspace/working-hours-in-minutes`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getPeriodStatistics: async (startDate, endDate) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}workspace/period-statistics/?start=${startDate}&end=${endDate}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },

  setDailyLimits: async (limits) => {
    const data = {
      daily_linkedin_messages_limit: +limits.messagesPerDayLimit.value,
      daily_linkedin_invitations_limit: +limits.invitationPerDayLimit.value,
      daily_linkedin_react_on_post_limit: +limits.likesPerDayLimit.value,
      daily_linkedin_follow_limit: +limits.followPerDayLimit.value,
    };
    return await httpRequest({
      url: `${CONSTS.API.URL}workspace/daily-limits`,
      method: "PUT",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      data,
    });
  },
  setExecutionComplete: async (id) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}executions/${id}/complete`,
      method: "PUT",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
  },
  getSubscription: async () => {
    return await httpRequest({
      url: `${CONSTS.API.URL}subscription`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  upgradeSubscription: async (id) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}subscription/upgrade`,
      method: "PUT",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      data: { plan_id: id },
    });
  },
  cancelSubscription: async () => {
    return await httpRequest({
      url: `${CONSTS.API.URL}subscription/cancel`,
      method: "PUT",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
  },
  resumeSubscription: async () => {
    return await httpRequest({
      url: `${CONSTS.API.URL}subscription/resume`,
      method: "PUT",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
  },

  getManagementUrl: async () => {
    return await httpRequest({
      url: `${CONSTS.API.URL}subscription/management-url`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },

  ordersSync: async (orderId, orderReference) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}orders/sync`,
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        order_id: orderId,
        reference: orderReference,
      },
    });
  },

  editImportsApi: async (data) => {
    let { id, name } = { ...data };
    return await httpRequest({
      url: `${CONSTS.API.URL}imports/${id}`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        name,
      },
    });
  },
  editMemberNickNameApi: async (data) => {
    let { id, name } = { ...data };
    return await httpRequest({
      url: `${CONSTS.API.URL}team/personas/${id}/nickname`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        name,
      },
    });
  },
  getAutomationMode: async () => {
    return await httpRequest({
      url: `${CONSTS.API.URL}workspace/automation-mode`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
};
