import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Select, MenuItem } from "@material-ui/core";
import { Element, scroller } from "react-scroll";
import _ from "lodash";

// Actions
import { campaignCreate } from "../../../actions/campaignCreate";
import ErrorHelper from "../../atoms/ErrorHelper";
// Components
import SelectContainer from "../../molecules/selectContainer";
import FormField from "../../molecules/formField";
import TimerComponent from "./timer";
import ModalWindow from "../../molecules/ModalWindow";
// Images
import deleteIcon from "../../../assets/image/icons/svg/icons/delete-icon.svg";
// icons
import ArrowIcon from "../../../assets/image/icons/svg/icons/Arrow-down.svg";
import PlusIcon from "../../../assets/image/icons/svg/icons/Plus2.svg";
import HintTooltip from "../../atoms/HintTooltip";
import { notification } from "../../../actions/notification";
// configs
import { CONSTS } from "../../../config/objectConst";
import { functions } from "../../../tools/functions";
import ResponsiveButton from "../../atoms/responsiveButton";
import { campaign } from "../../../actions/campaign";

const fillInWithData = (from) => {
  return from.map((item) => {
    return {
      type: item.type,
      delay: item.values.delay,
      period: item.values.period,
      textarea: item.values.textarea,
    };
  });
};
const objectsAreSame = (x, y) => {
  let objectsAreSame = true;
  const xValues = Object.values(x);
  const yValues = Object.values(y);
  xValues.forEach((item, index) => {
    if (item !== yValues[index]) return (objectsAreSame = false);
  });
  return objectsAreSame;
};
export class Step extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isConnectDelay: null,
      campaignId: null,
      actions: [
        {
          available: CONSTS.AVAILABLE_ACTIONS.Connect.Visible,
          localeAvailable: true,
          name: CONSTS.AVAILABLE_ACTIONS.Connect.Title,
          type: CONSTS.AVAILABLE_ACTIONS.Connect.Type,
          arrow: true,
          params: {
            launchActionIn: false,
            mainCustomFields: CONSTS.MAIN_CUSTOM_FIELDS,
            overflowCustomFields: CONSTS.OVERFLOW_CUSTOM_FIELDS,
            textarea: String,
            period: Number,
            sendMessageTo: false,
          },
          values: {
            launchActionIn: 0,
            mainCustomFields: CONSTS.MAIN_CUSTOM_FIELDS,
            overflowCustomFields: CONSTS.OVERFLOW_CUSTOM_FIELDS,
            textarea: "",
            period:
              CONSTS.DEFAULT_DATE_ACTIONS_VALUE.CONNECT.WITHDRAW.DEFAULT * 1440,
            sendMessageTo: "Contacts, who never replied",
            delay: CONSTS.DEFAULT_DATE_ACTIONS_VALUE.CONNECT.DELAY.DEFAULT,
          },
          open: true,
          validation: {
            actionName: true,
          },
          status: false,
        },
        {
          name: CONSTS.AVAILABLE_ACTIONS.Message.Title,
          type: CONSTS.AVAILABLE_ACTIONS.Message.Type,
          arrow: true,
          available: CONSTS.AVAILABLE_ACTIONS.Message.Visible,
          localeAvailable: true,
          params: {
            launchActionIn: false,
            mainCustomFields: CONSTS.MAIN_CUSTOM_FIELDS,
            overflowCustomFields: CONSTS.OVERFLOW_CUSTOM_FIELDS,
            textarea: String,
            period: false,
            sendMessageTo: false,
          },
          open: true,
          status: false,
        },
        {
          name: CONSTS.AVAILABLE_ACTIONS.VisitProfile.Title,
          type: CONSTS.AVAILABLE_ACTIONS.VisitProfile.Type,
          available: CONSTS.AVAILABLE_ACTIONS.VisitProfile.Visible,
          localeAvailable: true,
          arrow: false,
          params: {
            launchActionIn: false,
            period: false,
            sendMessageTo: false,
          },
          open: false,
          status: false,
        },
        {
          name: CONSTS.AVAILABLE_ACTIONS.Follow.Title,
          type: CONSTS.AVAILABLE_ACTIONS.Follow.Type,
          available: CONSTS.AVAILABLE_ACTIONS.Follow.Visible,
          localeAvailable: true,
          arrow: false,
          params: {
            launchActionIn: false,
            period: false,
            sendMessageTo: false,
          },
          open: false,
          status: false,
        },
        {
          name: CONSTS.AVAILABLE_ACTIONS.ReactOnPost.Title,
          type: CONSTS.AVAILABLE_ACTIONS.ReactOnPost.Type,
          mainCustomFields: CONSTS.MAIN_CUSTOM_FIELDS,
          available: CONSTS.AVAILABLE_ACTIONS.ReactOnPost.Visible,
          localeAvailable: true,
          arrow: true,
          params: {
            currentEmotion: "Like",
            emotion: CONSTS.EMOTION,
            launchActionIn: false,
            period: false,
            sendMessageTo: false,
          },
          open: true,
          status: false,
        },
        {
          name: CONSTS.AVAILABLE_ACTIONS.EndorseSkill.Title,
          type: CONSTS.AVAILABLE_ACTIONS.EndorseSkill.Type,
          available: CONSTS.AVAILABLE_ACTIONS.EndorseSkill.Visible,
          localeAvailable: true,
          arrow: false,
          params: {
            launchActionIn: false,
            period: false,
            sendMessageTo: false,
          },
          open: false,
          status: false,
        },
        {
          name: CONSTS.AVAILABLE_ACTIONS.ScrapeProfile.Title,
          type: CONSTS.AVAILABLE_ACTIONS.ScrapeProfile.Type,
          available: CONSTS.AVAILABLE_ACTIONS.ScrapeProfile.Visible,
          localeAvailable: true,
          arrow: false,
          params: {
            launchActionIn: false,
            period: false,
            sendMessageTo: false,
          },
          open: false,
          status: false,
        },
      ],
      saveDisabledController: false,
      actionsList: [],
      actionsListInitial: [],
      isOpenModalCancel: false,
      modalDeleteValue: null,
      chooseActionOpen: false,
      chooseActionMessageOpen: false,

      isDataChanged: false,
      actionId: null,
      scrollBarWidth: false,
    };
    this.saveCaseActionsValue = [];
    this.wrapper = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    if (this.props.scrollToAction) {
      setTimeout(() => {
        scroller.scrollTo(
          `myScrollToElement-${this.props.scrollToAction}`,
          {
            duration: 150,
            delay: 0,
            smooth: false,
            containerId: "homeTemplateContainerID",
            offset: 250,
          },
          0
        );
        campaignCreate.editScrollEvent(false);
      }, 0);
    }

    if (this.props.campaign.id !== null) {
      this.setState({
        actionsList: this.props.campaign.actions,
        campaignId: this.props.campaign.id,
      });
    }
  }

  componentWillUnmount() {
    campaignCreate.zoomMessageWindow("");
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.campaign.id &&
      prevProps.campaign.id !== this.props.campaign.id
    ) {
      this.setState({
        actionsList: this.props.campaign.actions,
        actionsListInitial: fillInWithData(this.props.campaign.actions),
      });
    }
    this.handleResize();
  }

  actualType = (type) => {
    switch (type) {
      case CONSTS.AVAILABLE_ACTIONS.Connect.Type:
        return "connect";

      case CONSTS.AVAILABLE_ACTIONS.Message.Type:
        return "message";

      case CONSTS.AVAILABLE_ACTIONS.VisitProfile.Type:
        return "view-profile";

      case CONSTS.AVAILABLE_ACTIONS.Follow.Type:
        return "follow";

      case CONSTS.AVAILABLE_ACTIONS.ReactOnPost.Type:
        return "like-a-post";

      case CONSTS.AVAILABLE_ACTIONS.EndorseSkill.Type:
        return "endorse-a-skill";

      case CONSTS.AVAILABLE_ACTIONS.ScrapeProfile.Type:
        return "scrape-profile";

      default:
        throw new Error(`Not supported action type ${type}`);
    }
  };

  handleResize = () => {
    if (this.wrapper.current) {
      const scrollbarWidth =
        this.wrapper.current.offsetWidth - this.wrapper.current.clientWidth;

      if (scrollbarWidth > 0 && this.state.scrollBarWidth === false) {
        this.setState({ scrollBarWidth: true });
      }

      if (scrollbarWidth === 0 && this.state.scrollBarWidth === true) {
        this.setState({ scrollBarWidth: false });
      }
    }
  };

  scrollToElement = (id) => {
    setTimeout(() => {
      scroller.scrollTo(
        `myScrollToElement-Action_${id}`,
        {
          duration: 150,
          delay: 0,
          smooth: false,
          containerId: "homeTemplateContainerID",
        },
        0
      );
    }, 0);
  };

  setUniqueId = (array) => {
    const newArray = [...array];
    for (let index = 0; index < 1000; index++) {
      if (!_.find(newArray, ["index", `Action_${index + 1}`])) {
        return index + 1;
      }
    }
  };

  handleSelect = (e) => {
    let choseObject = {
      ..._.find(this.state.actions, { name: e.target.value }),
    };
    let choseObjectParams = { ...choseObject.params };
    let newChoseObject = { ...choseObject, params: choseObjectParams };
    let buildObject = {
      id: this.setUniqueId(this.state.actionsList),
      index: `Action_${this.setUniqueId(this.state.actionsList)}`,
      open: true,
      validation: {
        actionName: true,
        textarea: true,
        period: true,
      },
      values: {
        launchActionIn: 0,
        currentEmotion: "Like",
        mainCustomFields: CONSTS.MAIN_CUSTOM_FIELDS,
        overflowCustomFields: CONSTS.OVERFLOW_CUSTOM_FIELDS,
        emotion: CONSTS.EMOTION,
        textarea: "",
        period:
          CONSTS.DEFAULT_DATE_ACTIONS_VALUE.MESSAGE_TIMEOUTS_DAYS[
            this.state.actionsList.length
          ] * 1440,
        sendMessageTo: "Contacts, who never replied",
      },
      ...newChoseObject,
    };
    setTimeout(() => {
      scroller.scrollTo(
        `myScrollToElement-${buildObject.index}`,
        {
          duration: 150,
          delay: 0,
          smooth: false,
          containerId: "homeTemplateContainerID",
          offset: -25,
        },
        0
      );
    }, 0);

    if (e.target.value === CONSTS.AVAILABLE_ACTIONS.Message.Title) {
      this.setState((state) => ({
        actionsList: state.actionsList.map((item) => (item.open = false)),
      }));
    }

    if (
      this.state.actionsList.length <
      CONSTS.DEFAULT_DATE_ACTIONS_VALUE.MESSAGE_TIMEOUTS_DAYS.length
    ) {
      this.setState({ actionsList: [...this.state.actionsList, buildObject] });
    }
  };

  // Saves Action
  getSave = (index) => {
    this.state.actionsList.forEach((item, itemIndex) => {
      if (
        this.state.actionsList.length === 1 &&
        item.name === CONSTS.AVAILABLE_ACTIONS.Connect.Title
      ) {
        item.values.delay = 0;
      }
      if (!this.saveCaseActionsValue.includes(item) && item.index === index) {
        this.saveCaseActionsValue.push(item);
      }
    });
    if (this.saveCaseActionsValue.length === this.state.actionsList.length) {
      campaignCreate.save(
        this.saveCaseActionsValue,
        this.props.campaign.id,
        () => {
          if (this.props.campaign.allowAddSteps) {
            return campaign.openCampaignContacts(this.props, true);
          } else {
            return this.props.history.push({
              pathname: `/campaigns/${this.props.campaign.id}`,
            });
          }
        }
      );
    }
  };

  save = () => {
    this.state.actionsList.map((item, index) => {
      return this.refs[`child${index + 1}`].save();
    });
  };

  checkMessage = (message) => {
    const fields = [...message.matchAll(/{(?<customField>.*?)}/gim)];

    const customFields = [
      ...CONSTS.MAIN_CUSTOM_FIELDS,
      ...CONSTS.OVERFLOW_CUSTOM_FIELDS,
    ];

    return fields.filter((el) => !customFields.some((rule) => rule === el[0]));
  };

  isError = () =>
    this.state.actionsList.map((el) => this.checkMessage(el.values.textarea));

  saveDisabledController = (array) => {
    // This function checked status validation
    // and return value
    let ar = [];
    if (array.length > 0) {
      array.forEach((item) => {
        for (let el in item.validation) {
          ar.push(item.validation[el]);
        }
      });
      if (_.filter(ar, (item) => item === false).length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  textAreaLengthValidation = () => {
    return this.state.actionsList.some(
      (item) =>
        item.values.textarea.length >
        (item.type === CONSTS.AVAILABLE_ACTIONS.Connect.Type
          ? CONSTS.AVAILABLE_ACTIONS.Connect.MaxLength
          : CONSTS.AVAILABLE_ACTIONS.Message.MaxLength)
    );
  };

  findIndexActionsWithError = (list) => {
    let result = _.filter(list, (el) => {
      return (
        !el.validation.textarea &&
        el.type === CONSTS.AVAILABLE_ACTIONS.Message.Type
      );
    });

    /* TODO if find try, scroll to element and open acardion */

    if (result.length > 0) {
      result.map((el) => {
        el.open = true;
      });

      this.setState({ actionsList: this.state.actionsList });

      setTimeout(() => {
        scroller.scrollTo(
          `myScrollToElement-${result[0].index}`,
          {
            duration: 500,
            delay: 0,
            smooth: true,
            containerId: "homeTemplateContainerID",
            offset: -25,
          },
          0
        );
      }, 0);
    }
  };

  setOpenConnect = () => {
    setTimeout(() => {
      scroller.scrollTo(
        `myScrollToElement-Action_1`,
        {
          duration: 500,
          delay: 0,
          smooth: true,
          containerId: "homeTemplateContainerID",
          offset: -25,
        },
        0
      );
    }, 0);
  };

  setFieldsData = () => {
    this.setState({ isConnectDelay: true });

    this.showErrorMessages(() => {
      this.showErrorMessageCallback();
      this.findIndexActionsWithError(this.state.actionsList);
    });
  };

  isMenuItemDisabled = (item) => {
    const findEl = this.state.actionsList.filter(
      (el) =>
        el.name === CONSTS.AVAILABLE_ACTIONS.Message.Title ||
        el.name === "Endorse a skill" ||
        el.name === CONSTS.AVAILABLE_ACTIONS.Connect.Title
    );

    if (
      !item.localeAvailable ||
      (item.name === CONSTS.AVAILABLE_ACTIONS.Connect.Title &&
        findEl.length > 0)
    )
      return true;
  };

  showErrorMessageCallback = () => {
    if (this.saveDisabledController(this.state.actionsList)) {
      ((!this.textAreaLengthValidation() &&
        this.state.actionsList.find(
          (item) => item.name === CONSTS.AVAILABLE_ACTIONS.Connect.Title
        )?.values?.delay >=
          CONSTS.DEFAULT_DATE_ACTIONS_VALUE.CONNECT.DELAY.MIN_VALUE_MINUTES) ||
        this.state.actionsList.find(
          (item) => item.name === CONSTS.AVAILABLE_ACTIONS.Connect.Title
        )?.values?.delay === undefined) &&
        !this.isError()?.some((el) => el.length !== 0) &&
        this.save();
    }
  };

  showErrorMessages = (callback) => {
    const newActionsList = this.state.actionsList.map((item) => {
      if (
        (item.validation.hasOwnProperty("textarea") &&
          item.validation.textarea &&
          item.values.textarea.length === 0 &&
          item.type === CONSTS.AVAILABLE_ACTIONS.Message.Type) ||
        (item.validation.hasOwnProperty("textarea") &&
          item.validation.textarea &&
          this.checkMessage(item.values.textarea).length !== 0)
      ) {
        item.validation.textarea = false;
      }
      return item;
    });
    this.setState(
      { actionsList: newActionsList },
      callback ? () => callback() : null
    );
  };

  // validation message
  validationMessage = (value, index) => {
    const newActionsList = this.state.actionsList.map((item) => {
      const maxLength =
        item.type === CONSTS.AVAILABLE_ACTIONS.Connect.Type
          ? CONSTS.AVAILABLE_ACTIONS.Connect.MaxLength
          : CONSTS.AVAILABLE_ACTIONS.Message.MaxLength;

      if (item.index === index) {
        if (item.type === CONSTS.AVAILABLE_ACTIONS.Connect.Type) {
          item.validation.textarea = maxLength >= value.length;
        }
        if (item.type === CONSTS.AVAILABLE_ACTIONS.Message.Type) {
          item.validation.textarea =
            value.length > 0 && maxLength >= value.length;
        }

        item.values.textarea = value;
      }
      return item;
    });
    this.setState({
      actionsList: newActionsList,
    });
  };

  saveReaction = (value, index) => {
    const newActionsList = this.state.actionsList.map((item) => {
      if (item.index === index) {
        if (item.type === CONSTS.AVAILABLE_ACTIONS.ReactOnPost.Type) {
          item.values.currentEmotion = value;
        }
      }
      return item;
    });
    this.setState({
      actionsList: newActionsList,
    });
  };

  validationLaunchActionIn = (value, index) => {
    const newActionsList = this.state.actionsList.map((item) => {
      if (item.index === index) {
        item.values.launchActionIn = value;
      }
      return item;
    });
    this.setState({
      actionsList: newActionsList,
    });
  };

  validationPeriod = (value, index) => {
    const newActionsList = this.state.actionsList.map((item) => {
      if (item.index === index) {
        item.values.period = value;
      }
      return item;
    });
    this.setState({
      actionsList: newActionsList,
    });
  };

  deleteAction = (id) => {
    try {
      const el = this.state.actionsList.filter((item) => item.index === id);
      const ind = this.state.actionsList.indexOf(el[0]);
      let newActionsList = this.state.actionsList.splice(0, ind);
      this.setState(
        { actionsList: newActionsList, modalDeleteValue: null },
        () =>
          notification.snacknotificationUse({
            open: true,
            title: CONSTS.notification["3.3"].title,
            content: CONSTS.notification["3.3"].content,
            button: CONSTS.notification["3.3"].button,
            style: CONSTS.notification["3.3"].style,
          })
      );
    } catch (e) {
      notification.notificationUse(e);
    }
  };

  isEqualValues = () => {
    let isEqual = true;
    const values = fillInWithData(this.state.actionsList);
    fillInWithData(this.state.actionsList, values);
    values.forEach((item, index) => {
      if (!objectsAreSame(item, this.state.actionsListInitial[index])) {
        return (isEqual = false);
      }
    });
    return isEqual;
  };

  handleCancel = () => {
    if (
      this.state.actionsListInitial.length !== this.state.actionsList.length ||
      !this.isEqualValues()
    ) {
      this.setState({ isOpenModalCancel: true });
    } else {
      this.props.history.push("/campaigns");
    }
  };

  accordionClick = (e, item) => {
    const newActionsList = this.state.actionsList.map((el) => {
      if (el.id === item.id) {
        el.open = !item.open;
      }
      return el;
    });
    this.setState({
      actionsList: newActionsList,
    });
  };

  timerChanger = (item, result, unique) => {
    let nowObject = _.find(this.state.actionsList, { index: item.index });
    if (!unique) {
      this.state.isConnectDelay && this.setState({ isConnectDelay: false });
      if (item.name === CONSTS.AVAILABLE_ACTIONS.Connect.Title) {
        if (nowObject.values.delay !== result) {
          const findEl = this.state.actionsList.filter(
            (el) => el.name === CONSTS.AVAILABLE_ACTIONS.Connect.Title
          );

          const findInd = this.state.actionsList.indexOf(findEl[0]);
          this.state.actionsList[findInd].values.delay = result;
        }
      } else {
        if (nowObject.values.delay !== result) {
          nowObject.values.period = result;

          return nowObject;
        }
      }
    } else {
      const findEl = this.state.actionsList.filter(
        (el) => el.name === CONSTS.AVAILABLE_ACTIONS.Connect.Title
      );
      const findInd = this.state.actionsList.indexOf(findEl[0]);
      this.state.actionsList[findInd].values.period = result;
    }
  };

  accordionGeneratorLabelText = (item, index, arrow) => {
    if (item.name === CONSTS.AVAILABLE_ACTIONS.Message.Title && index > 0) {
      return (
        <p className={arrow ? "action-label" : "action-label-with-out-cursor"}>
          Action {index + 1}: <span>Follow up message</span>
        </p>
      );
    } else {
      return (
        <p className={arrow ? "action-label" : "action-label-with-out-cursor"}>
          Action {index + 1}:<span>{item.name}</span>
        </p>
      );
    }
  };

  getDelayDescription = (item) => {
    switch (item.type) {
      case CONSTS.AVAILABLE_ACTIONS.Connect.Type:
        return ["Once connection is", <br />, "accepted, wait"];
      case CONSTS.AVAILABLE_ACTIONS.Message.Type:
        return ["If no response in"];
      default:
        return ["After execution"];
    }
  };

  renderMainActionField = () => {
    return (
      <div className="hint-tooltip-wrapper">
        <FormField
          classes={this.state.actionsList.length === 0 ? "icon" : ""}
          beaforeLabel={""}
          required={false}
        >
          <Select
            classes={{
              icon: "field-arrow-icon",
            }}
            onOpen={() => {
              this.setState({ chooseActionOpen: true });
              localStorage.setItem("2.3", "true");
            }}
            open={this.state.chooseActionOpen}
            onClose={() => this.setState({ chooseActionOpen: false })}
            onChange={this.handleSelect}
            value="title"
            icon={"none"}
            className="select chose-action"
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            }}
          >
            <MenuItem value="title" className="select-item-placeholder">
              <div className="flex-container-action">
                <img
                  src={PlusIcon}
                  alt="PlusIcon"
                  className="event-action-add__icon"
                />
                <div className="action-text">
                  {this.state.actionsList.length === 0
                    ? "Add action"
                    : "Add next action"}
                </div>
              </div>
            </MenuItem>

            {this.state.actions
              .filter((el) => el.available)
              .map((item, index) => {
                return (
                  <MenuItem
                    disabled={this.isMenuItemDisabled(item)}
                    key={index + 1}
                    value={item.name}
                    className={
                      this.isMenuItemDisabled(item)
                        ? "select-item-disabled"
                        : "select-item"
                    }
                  >
                    {item.name}
                  </MenuItem>
                );
              })}
          </Select>
          <HintTooltip
            placement="bottom-start"
            classes="first-action-tooltip"
            enabled={
              functions.isValidOnBoardingConditions(
                this.props.appProfile,
                "2.3"
              ) &&
              !this.state.actionsList.length &&
              !window.localStorage.getItem("2.3")
            }
            tooltip={CONSTS.ON_BOARDING["2.3"]}
            handlerClose={() => {
              window.localStorage.setItem("2.3", "true");
            }}
          />
        </FormField>
      </div>
    );
  };

  render() {
    const actionNumber = this.state.actionsList.length;
    return (
      <div className="form-step-wrapper">
        <div className="hidden-step-line" />
        <div className="form-step">
          <div
            className={`form-step-actions multiple-actions ${
              !!this.props.zoomMessageWindow ? "hidden-block" : ""
            }`}
          >
            {this.state.actionsList.map((item, index) => {
              return (
                <React.Fragment key={item.index}>
                  <Element
                    className="action-group-wrap"
                    name={`myScrollToElement-${item.index}`}
                  >
                    <div
                      className={`${this.actualType(item.type)} ${
                        item.open &&
                        this.state.actionsList &&
                        this.state.actionsList[
                          this.state.actionsList.length - 1
                        ]?.name === CONSTS.AVAILABLE_ACTIONS.Message.Title
                          ? "action-group-accardion-open open"
                          : this.state.actionsList &&
                            this.state.actionsList[
                              this.state.actionsList.length - 1
                            ]?.name === CONSTS.AVAILABLE_ACTIONS.Connect.Title
                          ? "action-group-accardion-connect"
                          : item.open &&
                            this.state.actionsList &&
                            this.state.actionsList[
                              this.state.actionsList.length - 1
                            ]?.name ===
                              CONSTS.AVAILABLE_ACTIONS.ReactOnPost.Title
                          ? "action-group-accardion-react open"
                          : "action-group-accardion"
                      }`}
                    >
                      <div
                        onClick={(e) =>
                          item.arrow && this.accordionClick(e, item)
                        }
                        className="action-label-wrap"
                      >
                        {item.arrow && (
                          <img
                            className="icon-arrow"
                            src={ArrowIcon}
                            alt="arrow"
                          />
                        )}
                        <div className="text-field-label">
                          {this.accordionGeneratorLabelText(
                            item,
                            index,
                            item.arrow
                          )}
                        </div>
                      </div>

                      {this.props.campaign.allowAddSteps && (
                        <div
                          className="delete-action"
                          onClick={() => {
                            const days =
                              CONSTS.DEFAULT_DATE_ACTIONS_VALUE
                                .MESSAGE_TIMEOUTS_DAYS[
                                this.state.actionsList.length - 2
                              ] * 1440;
                            const newArr = [...this.state.actionsList];

                            newArr[
                              this.state.actionsList.length - 1
                            ].values.period = days;
                            this.setState({
                              modalDeleteValue: item.index,
                              actionsList: newArr,
                            });
                          }}
                        >
                          <img
                            src={deleteIcon}
                            alt="delete action"
                            className="delete-action__icon"
                          />
                          <div className="delete-action__text">Delete</div>
                        </div>
                      )}
                    </div>
                    <div
                      className={
                        item.open ? "action-group open" : "action-group"
                      }
                    >
                      <SelectContainer
                        ref={`child${index + 1}`}
                        item={item}
                        index={index}
                        textAreaLengthValidation={this.textAreaLengthValidation}
                        getSave={this.getSave}
                        validationMessage={this.validationMessage}
                        saveReaction={this.saveReaction}
                        validationLaunchActionIn={this.validationLaunchActionIn}
                        validationPeriod={this.validationPeriod}
                        timerChanger={this.timerChanger}
                        setActionId={(id) => this.setState({ actionId: id })}
                        checkMessage={this.checkMessage}
                      />
                    </div>
                    {(item.name !== CONSTS.AVAILABLE_ACTIONS.Connect.Title ||
                      (this.state.actionsList &&
                        this.state.actionsList[
                          this.state.actionsList.length - 1
                        ]?.name !==
                          CONSTS.AVAILABLE_ACTIONS.Connect.Title)) && (
                      <FormField
                        label="Delay"
                        beaforeLabel={this.getDelayDescription(item)}
                        classes="short-label delay-field bottom-timer"
                      >
                        <TimerComponent
                          item={item}
                          timerChanger={this.timerChanger}
                          showController={true}
                          time={
                            item.name === CONSTS.AVAILABLE_ACTIONS.Connect.Type
                              ? item.values.delay
                              : item.values.period
                          }
                          className="timer"
                        />
                        {item.name === CONSTS.AVAILABLE_ACTIONS.Connect.Title &&
                          this.state.actionsList.find(
                            (item) =>
                              item.name ===
                              CONSTS.AVAILABLE_ACTIONS.Connect.Title
                          )?.values?.delay <
                            CONSTS.DEFAULT_DATE_ACTIONS_VALUE.CONNECT.DELAY
                              .MIN_VALUE_MINUTES &&
                          this.state.isConnectDelay && (
                            <div className="error-container-accent">
                              <ErrorHelper>
                                {" "}
                                Must be at least{" "}
                                {
                                  CONSTS.DEFAULT_DATE_ACTIONS_VALUE.CONNECT
                                    .DELAY.MIN_VALUE_MINUTES
                                }{" "}
                                minutes
                              </ErrorHelper>
                            </div>
                          )}
                      </FormField>
                    )}
                  </Element>
                </React.Fragment>
              );
            })}
          </div>
          {!this.props.zoomMessageWindow && (
            <div
              className={`${
                this.state.actionsList &&
                this.state.actionsList[this.state.actionsList.length - 1]
                  ?.name === CONSTS.AVAILABLE_ACTIONS.Message.Title
                  ? "event-action-add-open "
                  : "event-action-add"
              } ${actionNumber === 0 ? "short" : ""} ${
                actionNumber === 1 ? "no-border" : ""
              }`}
            >
              {this.props.campaign.allowAddSteps &&
                actionNumber <
                  CONSTS.DEFAULT_DATE_ACTIONS_VALUE.MESSAGE_TIMEOUTS_DAYS
                    .length &&
                this.renderMainActionField()}

              <div className="buttons-group">
                <button
                  className="btn semi-bold text-button-small alt"
                  onClick={this.handleCancel}
                >
                  Cancel
                </button>
                <ResponsiveButton
                  className="btn semi-bold text-button-small"
                  onClick={this.setFieldsData}
                  isLoading={this.props.updateCampaignController}
                  disabled={
                    actionNumber === 0 &&
                    this.saveDisabledController(this.state.actionsList)
                  }
                >
                  {"Save"}
                </ResponsiveButton>
                {((this.state.actionsList.find(
                  (item) => item.name === CONSTS.AVAILABLE_ACTIONS.Connect.Title
                )?.values?.delay <
                  CONSTS.DEFAULT_DATE_ACTIONS_VALUE.CONNECT.DELAY
                    .MIN_VALUE_MINUTES &&
                  this.state.isConnectDelay &&
                  this.setOpenConnect()) ||
                  (this.state.actionsList.find(
                    (item) =>
                      item.name === CONSTS.AVAILABLE_ACTIONS.Connect.Title
                  )?.values?.delay <
                    CONSTS.DEFAULT_DATE_ACTIONS_VALUE.CONNECT.DELAY
                      .MIN_VALUE_MINUTES &&
                    this.state.isConnectDelay &&
                    actionNumber > 1) ||
                  !this.saveDisabledController(this.state.actionsList) ||
                  this.isError()?.some((el) => el.length !== 0)) && (
                  <div className="error-container">
                    <ErrorHelper>Please fix all warnings</ErrorHelper>
                  </div>
                )}
              </div>
            </div>
          )}
          {!!this.props.zoomMessageWindow && (
            <div className="zoomed-message">
              <div
                className="change-size collapse"
                onClick={() => {
                  campaignCreate.zoomMessageWindow("");
                  this.scrollToElement(this.state.actionId);
                }}
              >
                &nbsp;
              </div>
              <div
                className={`text ${
                  this.state.scrollBarWidth ? "text-with-scroll" : ""
                }`}
              >
                <pre ref={this.wrapper} className="word-break">
                  {this.props.zoomMessageWindow}
                </pre>
              </div>
            </div>
          )}
          <ModalWindow
            open={this.state.isOpenModalCancel}
            closeText="No"
            confirmText="Yes"
            title={CONSTS.confirmationDialogs[1.3].title}
            content={CONSTS.confirmationDialogs[1.3].content}
            closeHandler={() => this.setState({ isOpenModalCancel: false })}
            confirmHandler={() => this.props.history.push("/campaigns")}
          />
          <ModalWindow
            open={!!this.state.modalDeleteValue}
            closeText="No"
            confirmText="Yes"
            title={CONSTS.confirmationDialogs[1.4].title}
            content={CONSTS.confirmationDialogs[1.4].content}
            closeHandler={() => this.setState({ modalDeleteValue: null })}
            confirmHandler={() =>
              this.deleteAction(this.state.modalDeleteValue)
            }
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    count: state.campaign.count,
    updateCampaignController: state.campaign.updateCampaignController,
    scrollToAction: state.campaigns.scrollToAction,
    zoomMessageWindow: state.campaignCreate.zoomMessageWindow,
    appProfile: state.app.appProfile,
  };
};

export default connect(mapStateToProps)(withRouter(Step));
