import React from "react";
import "./style.scss";
import icon from "../../../assets/image/icons/svg/icons/support.svg";
import { CONSTS } from "../../../config/objectConst";

const SupportButton = () => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href={CONSTS.HELP_URL}
    className="support"
  >
    <div className="support__container">
      <img src={icon} alt="icon support button" className="support__icon" />
      <p className="support__text">Help Center</p>
    </div>
  </a>
);

export default SupportButton;
