import { combineReducers } from "redux";
import app from "./app";
import activity from "./activity";
import home from "./home";
import user from "./user";
import campaignCreate from "./campaignCreate";
import campaigns from "./campaigns";
import campaignsTeams from "./teams";
import campaign from "./campaign";
import contacts from "./contacts";
import filters from "./filters";
import leftSideMenu from "./leftSideMenu";
import notification from "./notification";
import mailbox from "./mailbox";

export default combineReducers({
  app,
  activity,
  user,
  home,
  campaignCreate,
  campaign,
  campaignsTeams,
  filters,
  campaigns,
  contacts,
  leftSideMenu,
  notification,
  mailbox,
});
