/*
 *
 * Home reducer
 *
 */
const initialState = {
  ready: false,
  error: null,
};

const home = (state = initialState, action) => {
  switch (action.type) {
    case "HOME_READY":
      return Object.assign({}, state, {
        value: action.payload.value,
        ready: true,
        error: null,
      });

    case "HOME_ACTION_API":
      return Object.assign({}, state, {
        value: action.payload.value,
        ready: true,
        error: null,
      });

    default:
      return state;
  }
};

export default home;
