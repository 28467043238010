import React, { Component } from "react";
import PropTypes from "prop-types";
import ArrowLeftIcon from "../../../assets/image/icons/svg/arrow-left-icon-grey.svg";
import "./style.scss";

export class StepsTags extends Component {
  static propTypes = {
    steps: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      showAll: false,
    };
  }

  render() {
    return (
      <div className="steps-container">
        {this.props.steps.map((item, index) => {
          if (index <= 1) {
            return (
              <span key={index} className="step">
                {item.name}
              </span>
            );
          }
          return "";
        })}
        {this.props.steps.length - 2 > 0 &&
          this.state.showAll &&
          this.props.steps.map((item, index) => {
            if (index > 1) {
              return (
                <span key={index} className="step">
                  {item.name}
                </span>
              );
            }
            return "";
          })}
        {this.props.steps.length - 2 > 0 && !this.state.showAll && (
          <div
            className="steps-show"
            onClick={() => this.setState({ showAll: true })}
          >{`(+${this.props.steps.length - 2})`}</div>
        )}
        {this.props.steps.length - 2 > 0 && this.state.showAll && (
          <div
            className="steps-hide"
            onClick={() => this.setState({ showAll: false })}
          >
            <img src={ArrowLeftIcon} alt="arrow" />
          </div>
        )}
      </div>
    );
  }
}

export default StepsTags;
