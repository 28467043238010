import React, { useEffect, useState } from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
// actions
import { connectorAPI } from "../../actions/connector";
// components
import NoAuthContainer from "../containers/noAuthContainer";
import NoInstallContainer from "../containers/noInstallContainer";
import NoLinkedinContainer from "../containers/noLinkedinContainer";
import NoTimeZoneContainer from "../containers/noTimeZoneContainer";
import { NoWorkingHoursContainer } from "../containers/noWorkingHoursContainer";
import { app } from "../../actions/app";
import { listeners } from "../../api/listeners";
import { CircularProgress } from "@material-ui/core";

const LayoutUser = ({
  component: Component,
  token,
  isInstalled,
  isInstalledDone,
  appProfile,
  linkedProfile,
  appProfileDone,
  linkedProfileDone,
  profileFull,
  ...args
}) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (!_.isEmpty(args.connector)) {
      connectorAPI.setUpInit();
    }
  }, [args.connector, args.auth]);

  useEffect(() => {
    authController();
  }, [appProfile, linkedProfileDone]);

  useEffect(() => {
    if (!isInstalled && !appProfileDone) {
      app.appAuth("none");
    }
    if (isInstalled && appProfileDone && !_.isEmpty(linkedProfile)) {
      checkIsAssociatedLinkedInProfile();
    }
  }, [isInstalled, appProfileDone, linkedProfile]);

  const checkIsAssociatedLinkedInProfile = () => {
    const connector = listeners.getConnector();
    const associatedLinkedInProfile =
      connector.auth.isSignedInUnderAssociatedLinkedInProfile();

    associatedLinkedInProfile.then((value) => {
      app.setAssociatedLinkedInProfile(!!value);
    });
  };

  useEffect(() => {
    connectorAPI.authChecker(
      token,
      isInstalled,
      isInstalledDone,
      linkedProfile,
      linkedProfileDone,
      appProfile,
      appProfileDone
    );
  }, [isInstalledDone, linkedProfileDone, appProfileDone]);

  const authController = () => {
    if (
      isInstalled &&
      linkedProfile &&
      appProfile &&
      appProfile.timezoneOffset === null
    ) {
      return <NoTimeZoneContainer />;
    }
    if (
      (isInstalled &&
        linkedProfile &&
        appProfile &&
        !appProfile.workingHoursSet) ||
      (linkedProfile && appProfile && open)
    ) {
      return (
        <NoWorkingHoursContainer
          setOpen={setOpen}
          open={open}
          appProfile={appProfile}
          connector={args.connector}
        />
      );
    }
    if (isInstalled && linkedProfile === null && !_.isEmpty(appProfile)) {
      return <NoLinkedinContainer />;
    }
    if (!isInstalled) {
      return <NoInstallContainer />;
    }

    if (isInstalled) {
      return <NoAuthContainer />;
    }
  };

  const LayoutUserController = () => {
    switch (args.auth) {
      case "auth":
        return (
          <Route
            path={args.path}
            render={() => {
              return <Component {...args} />;
            }}
          />
        );

      case "none":
        return (
          <Route
            path={args.path}
            render={() => {
              return authController();
            }}
          />
        );

      case "loading":
        return (
          <Route
            path={args.path}
            render={() => {
              return (
                <div className="material-loader-wrapper">
                  <CircularProgress
                    color="primary"
                    size={60}
                    classes={{ colorPrimary: "material-loader" }}
                  />
                </div>
              );
            }}
          />
        );

      default:
        return (
          <Route
            path={args.path}
            render={() => {
              return <div>loading ...</div>;
            }}
          />
        );
    }
  };

  return LayoutUserController();
};

const mapStateToProps = function (state) {
  return {
    isInstalled: state.app.isInstalled,
    isInstalledDone: state.app.isInstalledDone,
    appProfile: state.app.appProfile,
    linkedProfile: state.app.linkedProfile,
    linkedProfileDone: state.app.linkedProfileDone,
    appProfileDone: state.app.appProfileDone,
    auth: state.app.auth,
    token: state.app.token,
    connector: state.app.connector,
    profileFull: state.user.profileFull,
  };
};

export default connect(mapStateToProps)(withRouter(LayoutUser));
