import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
// Component
import DescriptionList from "../../molecules/descriptionList";
import Chart from "../../organisms/chart";
// Style
import "./style.scss";
import editIcon from "../../../assets/image/icons/svg/icons/pencil.svg";
import arrowBackIcon from "../../../assets/image/icons/svg/icons/arrow-back.svg";
import timeBackIcon from "../../../assets/image/icons/svg/icons/time-back.svg";
import messageIcon from "../../../assets/image/icons/tables/message.svg";
import { CONSTS } from "../../../config/objectConst";
import { chartModel } from "../chart/chartModel";

export class DescriptionSegment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showIndex: 0,
    };
  }

  changeAction = (index) => {
    this.setState({
      showIndex: index,
    });
  };

  showPeriod = (period) => {
    if (period >= 1440 && period % 1440 === 0) {
      return `${period / 1440} days`;
    } else if (period >= 1440 && period % 1440 !== 0) {
      return `${Math.floor(period / 1440)}+ days`;
    } else if (period >= 60 && period % 60 === 0) {
      return `${period / 60} hours`;
    } else if (period >= 60 && period % 60 !== 0) {
      return `${Math.floor(period / 60)}+ hours`;
    } else if (period > 0) {
      return `${period} min`;
    } else {
      return `no delay`;
    }
  };

  getMessageTitle = (action) => {
    const result =
      action.type === CONSTS.AVAILABLE_ACTIONS.Message.Type
        ? `Message:`
        : action.type === CONSTS.AVAILABLE_ACTIONS.Connect.Type &&
          action.values.textarea !== ""
        ? "Note message:"
        : "";
    return result;
  };

  isMessageFullAction = (action) => {
    const result =
      action.type === CONSTS.AVAILABLE_ACTIONS.Connect.Type ||
      action.type === CONSTS.AVAILABLE_ACTIONS.Message.Type;
    return result;
  };

  renderSegment = (action) => {
    return (
      <>
        <div className="d-segment-body">
          <Grid container justifyContent="center">
            <Grid container item xs={6} direction="column">
              <DescriptionList
                label={this.getMessageTitle(action)}
                className="d-segment-body-description"
              >
                {action.values.textarea === "" &&
                this.isMessageFullAction(action) ? (
                  <>
                    <div className="d-segment-body-description__empty">
                      <img
                        src={messageIcon}
                        alt="message-icon"
                        className="d-segment-body-description__empty-icon"
                      />
                      <p>Your note message is empty</p>
                    </div>
                  </>
                ) : (
                  action.values.textarea
                )}
              </DescriptionList>
            </Grid>
            <Grid item xs={6} className="grid-left-border">
              {action.statistics && (
                <Chart
                  title={"Action Statistics:"}
                  statistics={chartModel.personalizeActionStatistics(action)}
                />
              )}
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  render() {
    return (
      <>
        <div className="d-segment-tabs">
          <div className="d-segment-tabs__wrapper">
            {this.props.campaign.actions.map((item, index) => {
              let buildTabName;
              let buildTabIcon;
              if (item.type === CONSTS.AVAILABLE_ACTIONS.Connect.Type) {
                buildTabIcon = (
                  <img
                    src={arrowBackIcon}
                    alt="arrow-back-icon"
                    className="d-segment-tabs__icon"
                  />
                );
              } else {
                buildTabIcon = (
                  <img
                    src={timeBackIcon}
                    alt="time-back-icon"
                    className="d-segment-tabs__icon"
                  />
                );
              }

              if (index === 0) {
                buildTabName = `#1: ${item.name}`;
              }

              if (index > 0) {
                buildTabName = `#${index + 1}: ${item.name}`;
              }
              return (
                <div
                  onClick={() => this.changeAction(index)}
                  className={`${
                    this.state.showIndex === index
                      ? "d-segment-tabs-item active"
                      : "d-segment-tabs-item"
                  }`}
                  key={index}
                >
                  <span className="no-wrap"> {buildTabName}</span>
                  {item.values.period < CONSTS.DELAY_TIMEOUT && (
                    <>
                      <span className="d-segment-tabs__dot"></span>
                      {buildTabIcon}{" "}
                      <span className="no-wrap">
                        {this.showPeriod(item.values.period)}
                      </span>
                    </>
                  )}
                </div>
              );
            })}
          </div>
          {this.props.campaign.allowEditSteps &&
            Boolean(this.props.campaign.actions.length) && (
              <button
                className="btn with-border semi-bold"
                onClick={() => {
                  this.props.history.push(
                    `/campaigns/${this.props.campaign.id}/tune`
                  );
                }}
              >
                <img src={editIcon} alt="edit-icon" />
                Edit
              </button>
            )}
        </div>
        {this.props.campaign.actions.map((action, index) => {
          return (
            <div
              className={`${
                this.state.showIndex === index
                  ? "d-segment actions active"
                  : "d-segment actions"
              }`}
              key={index}
            >
              {this.renderSegment(action)}
            </div>
          );
        })}
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    campaign: state.campaign,
  };
};

export default connect(mapStateToProps)(withRouter(DescriptionSegment));
