/*
 *
 * Contacts reducer
 *
 */
const initialState = {
  imports: [],
  contacts: [],
  contactsCount: null,
  haveContacts: false,
  contactsPage: 0,
};

const contacts = (state = initialState, action) => {
  switch (action.type) {
    case "ALL_CONTACTS":
      return Object.assign({}, state, {
        contacts: action.payload.contacts,
      });
    case "CONTACTS_COUNT":
      return Object.assign({}, state, {
        contactsCount: action.payload.contactsCount,
      });
    case "CONTACTS_PAGE":
      return Object.assign({}, state, {
        contactsPage: action.payload.contactsPage,
      });
    case "RECENT_IMPORTS":
      return Object.assign({}, state, {
        imports: action.payload.imports,
      });
    case "HAVE_CONTACTS":
      return Object.assign({}, state, {
        haveContacts: action.payload.haveContacts,
      });
    case "UPDATE_IMPORTED_CONTACT":
      return Object.assign({}, state, {
        imports: action.payload.imports,
      });
    default:
      return state;
  }
};

export default contacts;
