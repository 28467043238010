import store from "../redux/store";
import { api } from "../api/api";
// actions
import { connectorAPI } from "./connector";
import { notification } from "./notification";

export const user = {
  createUserProfile(profile) {
    store.dispatch({
      type: "USER_PROFILE",
      payload: {
        profile: profile,
      },
    });
  },

  createUserProfileFull(appProfile, linkedInfo) {
    let object = {
      appProfile: appProfile,
      linkedInfo: linkedInfo,
    };
    store.dispatch({
      type: "USER_PROFILE_FULL",
      payload: {
        profileFull: object,
      },
    });
  },

  getPeriodStatistics: async (startDate, endDate) => {
    const result = {
      messages_count: 0,
      messages_limit: 50,
      invitations_count: 0,
      invitations_limit: 50,
      withdrawn_count: 0,
      withdrawn_limit: null,
      react_on_post_count: 0,
      react_on_post_limit: 5,
      follow_count: 0,
      follow_limit: 50,
    };

    const getStartData = (date) => {
      return new Date(
        Date.UTC(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          0,
          0,
          0,
          0
        )
      ).toISOString();
    };
    const getEndData = (date) => {
      return new Date(
        Date.UTC(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          23,
          59,
          59,
          999
        )
      ).toISOString();
    };
    try {
      const response = await api.getPeriodStatistics(
        getStartData(startDate),
        getEndData(endDate)
      );
      if (response.status === 200) {
        result.messages_count = response.data.messages_count;
        result.messages_limit = response.data.messages_limit;
        result.invitations_count = response.data.invitations_count;
        result.invitations_limit = response.data.invitations_limit;
        result.withdrawn_count = response.data.withdrawn_count;
        result.withdrawn_limit = response.data.withdrawn_limit;
        result.react_on_post_count = response.data.react_on_post_count;
        result.react_on_post_limit = response.data.react_on_post_limit;
        result.follow_count = response.data.follow_count;
        result.follow_limit = response.data.follow_limit;
      }
    } catch (error) {
      notification.notificationUse(error);
    }

    return result;
  },

  syncSnailyProfile: async () => {
    const result = await connectorAPI.getSnailyProfile();
    user.createUserProfile(result);
  },
};
