import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
// Component
import Chart from "../../organisms/chart";
// Style
import "./style.scss";

import contactsIcon from "../../../assets/image/icons/svg/icons/contacts.svg";
import HeaderTemplate from "../headerTemplate";
import { chartModel } from "../chart/chartModel";
import SegmentButtonGroup from "../../atoms/SegmentButtonGroup";

import { buildStatus } from "../../molecules/buildStatusCampaign";

export class CampaignDescriptionSegment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignIdWithTip: "",
    };
  }

  onClickStatus = (id) => {
    this.setState({
      campaignIdWithTip: id,
    });
  };

  convertValue = (item) => {
    if (item.id === 1) {
      return buildStatus({
        id: this.props.campaign.id,
        pauseReasonStatus: item.pause_reason,
        status: item.value,
        campaignIdWithTip: this.state.campaignIdWithTip,
        onClick: this.onClickStatus,
      });
    }

    if (item.id === 2) {
      return <span className="enlarged-font">{item.value}</span>;
    }

    if (item.id === 3 || item.id === 4) {
      if (!item.value) {
        return <div className="empty-date" />;
      } else {
        return <span className="enlarged-font">{item.value}</span>;
      }
    }

    return item.value;
  };

  render() {
    let contactsBtnClassName = "btn with-border semi-bold";
    let contactsBtnIconClassName = "";
    let contactsBtnCommand = "Contacts";
    return (
      <>
        {this.state.campaignIdWithTip && (
          <div
            className="backdrop"
            onClick={() =>
              this.setState({
                campaignIdWithTip: "",
              })
            }
          />
        )}
        <div className="d-segment active d-segment-statistic">
          <div className="d-segment-header">
            <div className="d-segment-title">
              <HeaderTemplate
                campaign={this.props.campaign}
                location={this.props.location}
              />
            </div>
            {!this.props.archive && <SegmentButtonGroup />}
          </div>
          <div className="d-segment-body">
            <Grid container justifyContent="center">
              <Grid container item xs={6} direction="column">
                <div className={`statistic-list large-list`}>
                  {this.props.campaign.information.map((item) => {
                    if (item.label === "Contacts:" && item.value === 0) {
                      contactsBtnClassName = "add-contacts-button large";
                      contactsBtnIconClassName = "hidden";
                      contactsBtnCommand = "Add contacts";
                    }
                    return (
                      <div className="statistic-list-item" key={item.id}>
                        <div className="statistic-list-item-label text-page semi-bold">
                          {item.label}
                        </div>
                        <div className="flex-container">
                          {this.convertValue(item)}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="d-segment-body__contacts">
                  <button
                    className="btn with-border semi-bold"
                    onClick={() => {
                      this.props.history.push(
                        `/campaigns/${this.props.campaign.id}/contacts`
                      );
                    }}
                  >
                    <img
                      className={contactsBtnIconClassName}
                      src={contactsIcon}
                      alt="contacts-icon"
                    />
                    <span>{contactsBtnCommand}</span>
                  </button>
                </div>
              </Grid>
              <Grid item xs={6} className="grid-left-border">
                {this.props.campaign.executionsStatistics && (
                  <Chart
                    title={"Campaign Statistics:"}
                    statistics={chartModel.personalizeCampaignStatistics(
                      this.props.campaign.executionsStatistics
                    )}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    campaign: state.campaign,
  };
};

export default connect(mapStateToProps)(withRouter(CampaignDescriptionSegment));
