import React from "react";
import "./style.scss";
import AccountProfile from "../../molecules/account/accountProfile";

import { useSelector } from "react-redux";
import WorkingHoursProfile from "../../molecules/account/workingHoursProfile/index.js";

import { user } from "../../../actions/user";
import { useState, useEffect } from "react";
import { functions } from "../../../tools/functions";
import store from "../../../redux/store";
import accountSvg from "../../../assets/image/icons/svg/account.svg";

const Account = () => {
  const { profileFull } = useSelector((state) => state.user);
  const [customStatistics, setCustomStatistics] = useState({});
  const [rangeState, setRangeState] = useState([
    {
      startDate: new Date(
        functions.getToday(profileFull.appProfile.timezoneOffset).setDate(1)
      ),
      endDate: functions.getToday(profileFull.appProfile.timezoneOffset),
      key: "selection",
      color: "#0cb39f",
    },
  ]);

  useEffect(() => {
    let response = {
      type: "account",
      bottomList: [
        {
          id: 1,
          image: accountSvg,
          title: "Snaily account",
          link: "/account",
        },
      ],
    };
    store.dispatch({
      type: "LEFT_SIDE_MENU_LIST",
      payload: {
        leftSide: response,
      },
    });
  }, []);

  const getPeriodStatisticsAsync = async () => {
    const customStatistics = await user.getPeriodStatistics(
      rangeState[0]?.startDate,
      rangeState[0]?.endDate
    );
    setCustomStatistics(customStatistics);
  };

  const name = `${
    profileFull.appProfile.givenName ? profileFull.appProfile.givenName : ""
  } ${
    profileFull.appProfile.familyName ? profileFull.appProfile.familyName : ""
  }`;

  return (
    <div className="account-container">
      <AccountProfile
        profile={profileFull}
        name={name}
        getPeriodStatisticsAsync={getPeriodStatisticsAsync}
        customStatistics={customStatistics}
        setCustomStatistics={setCustomStatistics}
        rangeState={rangeState}
        setRangeState={setRangeState}
      />
      <WorkingHoursProfile
        getPeriodStatisticsAsync={getPeriodStatisticsAsync}
      />
    </div>
  );
};

export default Account;
