import React, { Component } from "react";
import _ from "lodash";
import "./style.scss";
import { listeners } from "../../../api/listeners";
import { connect } from "react-redux";
import { teams } from "../../../actions/teams";
import { CONSTS } from "../../../config/objectConst";
import TablePagination from "@material-ui/core/TablePagination";
import MTablePagination from "../../atoms/mTablePagination";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { functions, saveData } from "../../../tools/functions";
import { api } from "../../../api/api";
import TooltipCustom from "../../atoms/tooltipCustom";
import { linkedInProfileOpener } from "../../../api/linkedInProfileOpener";
import Checkbox from "@material-ui/core/Checkbox";
import { CircularProgress } from "@material-ui/core";
import linkSvgGold from "../../../assets/image/icons/tables/goldLink.svg";
import selectedCheckboxIcon from "../../../assets/image/icons/tables/checkbox2.svg";
import partiallySelectedCheckboxIcon from "../../../assets/image/icons/tables/checkbox3.svg";
import notSelectedCheckboxIcon from "../../../assets/image/icons/tables/checkbox.svg";
import SortIconCampaigns from "../personalCampaignsStatistics/sortIconsCampaigns";
import ExportToCSVButton from "../../atoms/ExportToCSVButton";
import CampaignFiltersTeams from "../CampaignFiltersTeams";
import { notification } from "../../../actions/notification";
import { FiltersTeam } from "../../organisms/filtersTeam";
import iconArrow from "../../../assets/image/icons/tables/arrow-rigth.svg";
import avatarSvg from "../../../assets/image/icons/svg/user.svg";
import { buildStatus } from "../buildStatusCampaign";

export class TeamCampaignStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtersController: false,
      filtersTeams: null,
      initialFiltersLocale: this.createInitialState(),
      initialFilters: this.createInitialState(),
      isIconActive: {
        name: true,
        started: false,
        acceptance_rate: true,
        invitation_sent: true,
        persona_id: true,
        messages_sent: true,
        reply_rate: true,
        started_count: true,
        currentId: null,
      },
      showAll: false,
      page: 0,
      rowsPerPage: CONSTS.PAGE_SIZE,
      loader: false,
      scrollBarWidth: false,
      selected: [],
      selectedValues: 0,
      selectAllCampaignsValue: false,
      campaignIdWithTip: "",
    };
    this.wrapper = React.createRef();
  }

  componentDidMount() {
    this.getCampaignsTeam();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.orderby !== prevProps?.orderby) {
      this.getCampaignsBySort();
    }
    this.handleResize();
  }

  filtersControllerToggle = () => {
    this.setState({
      filtersController: !this.state.filtersController,
    });
  };

  openLinkedInProfile = async (row) => {
    const connector = listeners.getConnector();
    await linkedInProfileOpener.open(connector, row);
  };
  handleClick = (event, name) => {
    const selectedIndex = this.state.selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, name);
    } else if (selectedIndex === 0) {
      this.setState({
        selectAllCampaignsValue: false,
      });
      newSelected = newSelected.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      this.setState({
        selectAllCampaignsValue: false,
      });
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      this.setState({
        selectAllCampaignsValue: false,
      });
      newSelected = newSelected.concat(
        this.state.selected.slice(0, selectedIndex),
        this.state.selected.slice(selectedIndex + 1)
      );
    }
    this.setState({
      selected: newSelected,
      selectedValues: newSelected.length,
    });
  };

  createFiltersData = () => {
    const executionsFilter = this.state.initialFilters.find(
      (val) => val.name === "Show Campaigns"
    ).value;

    const filters = this.state.initialFilters
      .filter((el) => el.name !== "Show Campaigns")
      .map((el) => {
        const res = el.value.map((val) => val.value).join(",");
        return el.name === "members" && res.length > 0
          ? `persona_id in (${res})`
          : el.name === "status" && res.length > 0
          ? `state in (${res})`
          : res;
      });

    if (executionsFilter.length > 0) {
      filters.push(`statistics/${executionsFilter} and state ne 'Compose'`);
    }

    return filters;
  };

  getCampaignsTeam = async () => {
    const filtersData = this.createFiltersData();
    this.props.setLoaderStatistics(true);

    await teams.getCampaignsTeam({
      archived: false,
      skip:
        this.props.tableCampaignsStatisticsPage > 0
          ? this.props.tableCampaignsStatisticsPage * CONSTS.PAGE_SIZE
          : 0,
      keywords: this.props.searchValue,
      orderby: this.props.orderby,
      filter: filtersData
        ? filtersData.filter((el) => el.length > 0).join(" and ")
        : "",
    });

    this.props.setLoaderStatistics(false);
  };

  clearAllFilters = async () => {
    await this.setState({
      initialFilters: this.createInitialState(),
    });
    this.setState({
      initialFiltersLocale: this.createInitialState(),
    });
    this.getCampaignsTeam();
  };

  onClickStatus = (id) => {
    this.setState({
      campaignIdWithTip: id,
    });
  };
  createInitialState = () => {
    return [
      {
        id: 1,
        name: "status",
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 2,
        name: "members",
        search: false,
        value: [],
        tag: "",
      },
      {
        id: 3,
        name: "Show Campaigns",
        search: false,
        value: "",
        tag: "",
      },
    ];
  };
  clearFilter = async (id) => {
    const idx = this.state.initialFilters.findIndex((el) => el.id === id);
    if (id === 3) {
      this.state.initialFilters[idx].value = "";
    } else {
      this.state.initialFilters[idx].value = [];
    }

    this.setState({
      initialFilters: this.state.initialFilters,
    });
    this.setState({
      initialFiltersLocale: _.cloneDeep(this.state.initialFilters),
    });

    this.getCampaignsTeam();
  };

  getCampaignsBySort = async (newPage) => {
    const filtersData = this.createFiltersData();
    this.props.setLoaderStatistics(true);
    await teams.getCampaignsTeam({
      archived: false,
      skip:
        this.props.tableCampaignsStatisticsPage > 0
          ? this.props.tableCampaignsStatisticsPage * CONSTS.PAGE_SIZE
          : 0,
      filter: filtersData
        ? filtersData.filter((el) => el.length > 0).join(" and ")
        : "",
      keywords: this.props.searchValue,
      orderby: this.props.orderby,
    });
    this.props.setLoaderStatistics(false);
  };
  handleResize = () => {
    const scrollbarWidth =
      this.wrapper.current.offsetWidth - this.wrapper.current.clientWidth;

    if (scrollbarWidth > 0 && this.state.scrollBarWidth === false) {
      this.setState({ scrollBarWidth: true });
    }

    if (scrollbarWidth === 0 && this.state.scrollBarWidth === true) {
      this.setState({ scrollBarWidth: false });
    }
  };

  buildClassByStatus = (status) => {
    switch (status) {
      case "Running":
        return "active";
      case "Stopped":
        return "paused";
      case "Completed":
        return "completed";
      case "Compose":
        return "drafted";

      default:
        break;
    }
  };

  signIn = async (row) => {
    const connector = listeners.getConnector();
    this.props.setLoaderStatistics(true);
    try {
      await connector.auth.signInUnderTeamMember(row.personaId);
      this.props.history.push(`/campaigns/${row.id}`);
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      this.props.setLoaderStatistics(false);
    }
  };
  signInAddActions = async (row) => {
    const connector = listeners.getConnector();
    this.props.setLoaderStatistics(true);
    try {
      await connector.auth.signInUnderTeamMember(row.personaId);
      this.props.history.push(`/campaigns/${row.id}/tune`);
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      this.props.setLoaderStatistics(true);
    }
  };

  addContactsHandler = async (e, row) => {
    e.stopPropagation();
    const connector = listeners.getConnector();
    try {
      await connector.auth.signInUnderTeamMember(row.personaId);
      this.props.history.push(`campaigns/${row.id}/contacts`);
    } catch (error) {
      notification.notificationUse(error);
    }
  };

  buttonsActions = (row) => {
    return (
      <div className="btn-group position-button-group-team">
        <TooltipCustom title="Open campaign" arrow={true}>
          <button
            className={"btn-row info"}
            onClick={() => {
              this.props.localAppProfile.personaId === row.personaId
                ? this.props.history.push(`/campaigns/${row.id}`)
                : this.signIn(row);
            }}
          >
            <img src={iconArrow} alt="arrowIcon" />
          </button>
        </TooltipCustom>
      </div>
    );
  };

  clearFilterController = (value) => {
    this.setState({ clearFilterData: value });
  };

  onSelectAllClick = () => {
    let newSelecteds = this.props.campaignsTeams.map((item) => item.id);
    this.setState({
      selected: newSelecteds,
      selectedValues: this.props.campaignsTeamsCount,
      selectAllCampaignsValue: true,
    });
  };

  onClearAllClick = () => {
    this.setState({
      selected: [],
      selectedValues: 0,
      selectAllCampaignsValue: false,
    });
  };

  onSelectAllPageClick = (event) => {
    this.setState({
      selectAllCampaignsValue: false,
      selectedValues:
        this.props.campaignsTeamsCount <= this.props.campaignsTeams.length
          ? this.props.campaignsTeamsCount
          : this.props.campaignsTeams.length,
    });

    if (event.target.checked) {
      let newSelected = this.props.campaignsTeams;
      newSelected = newSelected.filter(
        (item) => !this.state.selected.includes(item)
      );

      this.setState({
        selected: this.state.selected.concat(newSelected).map((item, index) => {
          return item.id;
        }),
      });
      return;
    }

    this.setState({
      selected: [],
      selectedValues: 0,
    });
  };

  isSelected = (name) => this.state.selected.indexOf(name) !== -1;

  exportCampaigns = async () => {
    const filtersData = this.createFiltersData();
    const currentFilter = filtersData
      ? filtersData.filter((el) => el.length > 0).join(" and ")
      : "";
    const selectId = this.state.selected;

    try {
      this.setState({ isLoadingCSV: true });
      const response = await api.exportCampaignsTeamApi({
        filter: this.state.selectAllCampaignsValue
          ? currentFilter
          : `id in (${selectId.map((item) => `'${item}'`)})`,
        orderby: this.props.orderby,
        archived: false,
        keywords: this.state.selectAllCampaignsValue
          ? this.props.searchValue
          : "",
      });
      if (response.status === 200) {
        const data = response.data,
          fileName = functions.getFileName();
        saveData(data, fileName);
      }
    } catch (error) {
      notification.notificationUse(error);
    }
    this.setState({
      selected: [],
    });
    this.setState({ isLoadingCSV: false });
    this.setState({
      selectAllCampaignsValue: false,
    });
  };

  handleChangePage = async (event, newPage) => {
    const filtersData = this.createFiltersData();
    this.props.setLoaderStatistics(true);
    await teams.getCampaignsTeam({
      skip: newPage > 0 ? newPage * CONSTS.PAGE_SIZE : 0,
      archived: this.props.archive,
      keywords: this.props.searchValue,
      orderby: this.props.orderby,
      filter: filtersData
        ? filtersData.filter((el) => el.length > 0).join(" and ")
        : "",
    });
    this.setState({ page: newPage });
    this.props.setLoaderStatistics(false);
  };

  setActiveFilterIcon(key, value) {
    this.setState({
      isIconActive: {
        ...{
          name: true,
          started: true,
          acceptance_rate: true,
          invitation_sent: true,
          persona_id: true,
          messages_sent: true,
          reply_rate: true,
          started_count: true,
        },
        [key]: value,
      },
    });
  }

  render() {
    const getDataNameWidth = (row) =>
      (row?.name?.length > 30 &&
        document.documentElement.clientWidth > 1000 &&
        document.documentElement.clientWidth < 1537) ||
      (row?.name?.length > 40 &&
        document.documentElement.clientWidth > 1537 &&
        document.documentElement.clientWidth < 1746);

    const getDataClientWidth = (row) => {
      const lengthRow =
        row?.nickname?.length +
        (this.props.localAppProfile.personaId === row.id ? 3 : 0);

      return (
        (lengthRow > 13 &&
          document.documentElement.clientWidth > 1200 &&
          document.documentElement.clientWidth < 1535) ||
        (lengthRow > 22 &&
          document.documentElement.clientWidth > 1535 &&
          document.documentElement.clientWidth < 1744) ||
        (lengthRow > 30 &&
          document.documentElement.clientWidth > 1744 &&
          document.documentElement.clientWidth < 1919) ||
        (lengthRow > 35 &&
          document.documentElement.clientWidth > 1919 &&
          document.documentElement.clientWidth < 2132) ||
        (lengthRow > 43 &&
          document.documentElement.clientWidth > 2132 &&
          document.documentElement.clientWidth < 2401)
      );
    };
    const getDataClientWidthEmail = (row) => {
      return (
        (row.email.length > 19 &&
          document.documentElement.clientWidth > 1200 &&
          document.documentElement.clientWidth < 1535) ||
        (row.email.length > 28 &&
          document.documentElement.clientWidth > 1535 &&
          document.documentElement.clientWidth < 1744) ||
        (row.email.length > 36 &&
          document.documentElement.clientWidth > 1744 &&
          document.documentElement.clientWidth < 1919) ||
        (row.email.length > 41 &&
          document.documentElement.clientWidth > 1919 &&
          document.documentElement.clientWidth < 2132) ||
        (row.email.length > 49 &&
          document.documentElement.clientWidth > 2132 &&
          document.documentElement.clientWidth < 2401)
      );
    };
    return (
      <div
        className={`statistics-table-container ${
          this.props.campaignsTeamsCount > this.state.rowsPerPage
            ? "statistics-table-container-with-footer"
            : ""
        }`}
      >
        {this.state.campaignIdWithTip && (
          <div
            className="backdrop"
            onClick={() =>
              this.setState({
                campaignIdWithTip: "",
              })
            }
          />
        )}
        <div
          className={`filters-modal ${
            !this.state.filtersController ? "hide" : ""
          }`}
        >
          <div className="filters-modal__wrapper filter-team-position">
            <FiltersTeam
              createInitialState={this.createInitialState}
              initialFiltersLocale={this.state.initialFiltersLocale}
              setInitialFiltersLocale={(value) =>
                this.setState({
                  initialFiltersLocale: value,
                })
              }
              getCampaignsTeam={this.getCampaignsTeam}
              personaId={this.props.localAppProfile.personaId}
              setFiltersTeams={(value) =>
                this.setState({
                  filtersTeams: value,
                })
              }
              setLoader={this.props.setLoaderStatistics}
              initialFilters={this.state.initialFilters}
              setInitialFilters={(value) =>
                this.setState({
                  initialFilters: value,
                })
              }
              keywords={this.props.searchValue}
              orderby={this.props.orderby}
              members={this.props.personas}
              show={this.state.filtersController}
              from={"contacts"}
              filters={this.props.filters}
              page={this.state.page}
              filtersControllerToggle={this.filtersControllerToggle}
              filtersControllerByParams={this.filtersControllerByParams}
              clearFilterData={this.state.clearFilterData}
              clearFilterController={this.clearFilterController}
              history={this.props.history}
              isFiltering={this.state.isFiltering}
            />
          </div>
        </div>
        <div className="hint-tooltip-wrapper">
          <TableContainer className="table-body campaigns  with-checkboxs">
            {this.props.loaderStatistics && !this.props.loader && (
              <div className="table-material-loader-wrapper-teams">
                <CircularProgress
                  color="primary"
                  size={60}
                  classes={{ colorPrimary: "material-loader" }}
                />
              </div>
            )}

            <CampaignFiltersTeams
              clearFilter={this.clearFilter}
              clearAllFilters={this.clearAllFilters}
              getCampaignsTeam={this.getCampaignsTeam}
              setLoader={this.props.setLoaderStatistics}
              initialFilters={this.state.initialFilters}
              setInitialFilters={(value) =>
                this.setState({
                  initialFilters: value,
                })
              }
              setShowAll={(value) =>
                this.setState({
                  showAll: value,
                })
              }
              orderby={this.props.orderby}
              campaignsTeam={this.props.campaignsTeams}
              filtersController={this.state.filtersController}
              filtersControllerToggle={this.filtersControllerToggle}
              showAll={this.state.showAll}
              filters={this.props.filters}
              clearFilterController={this.clearFilterController}
              searchValue={this.props.searchValue}
              setPage={(value) => this.setState({ page: value })}
            />
            <Table
              className={"table"}
              size="small"
              aria-label="a dense table"
              style={{ tableLayout: "auto" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "5%", position: "relative" }}>
                    <div className="checkbox-container-header-teams">
                      <Checkbox
                        indeterminate={
                          this.props.campaignsTeams &&
                          this.props.campaignsTeams.length <
                            this.state.selected.filter((item) =>
                              this.props.campaignsTeams.includes(item)
                            ).length
                        }
                        checked={this.state.selected.length > 0}
                        onChange={this.onSelectAllPageClick}
                        inputProps={{ "aria-label": "select all desserts" }}
                        indeterminateIcon={
                          <img src={notSelectedCheckboxIcon} alt="checkbox" />
                        }
                        icon={
                          <img src={notSelectedCheckboxIcon} alt="checkbox" />
                        }
                        checkedIcon={
                          <img
                            src={
                              this.props.campaignsTeams.length >
                              this.state.selectedValues
                                ? partiallySelectedCheckboxIcon
                                : selectedCheckboxIcon
                            }
                            alt="checkbox"
                          />
                        }
                      />
                    </div>
                  </TableCell>

                  <TableCell
                    className="name title-company "
                    style={{ width: "26.8%" }}
                  >
                    {this.state.selected.length > 0 ? (
                      <div className="selected-rows-statistics">
                        <>
                          {this.state.selectedValues > 0 && (
                            <div>
                              <span className="select-text">
                                {this.state.selectedValues} of{" "}
                                {this.props.campaignsTeamsCount} selected |{" "}
                              </span>
                              {!this.state.selectAllCampaignsValue && (
                                <span
                                  className="clear-all"
                                  onClick={this.onSelectAllClick}
                                >
                                  Select all
                                </span>
                              )}

                              {this.state.selectAllCampaignsValue && (
                                <span
                                  className="clear-all"
                                  onClick={this.onClearAllClick}
                                >
                                  Clear
                                </span>
                              )}
                            </div>
                          )}
                          <div className="background-button">
                            <ExportToCSVButton
                              disabled={false}
                              isLoading={false}
                              onClick={() => {
                                this.exportCampaigns();
                              }}
                              text="Export to CSV"
                            ></ExportToCSVButton>
                          </div>
                        </>
                      </div>
                    ) : (
                      <div className="container-title-images">
                        <SortIconCampaigns
                          text={"Campaign"}
                          sort={"name"}
                          idx={"name"}
                          orderby={this.props.orderby}
                          setOrderby={this.props.setOrderby}
                          setIsSortedAsc={this.setActiveFilterIcon.bind(this)}
                          isSortedAsc={this.state.isIconActive}
                        />
                      </div>
                    )}
                  </TableCell>
                  <TableCell style={{ width: "20%" }}>
                    <div className="stats">
                      {" "}
                      <SortIconCampaigns
                        text={"Account"}
                        sort={"persona_id"}
                        idx={"persona_id"}
                        orderby={this.props.orderby}
                        setOrderby={this.props.setOrderby}
                        setIsSortedAsc={this.setActiveFilterIcon.bind(this)}
                        isSortedAsc={this.state.isIconActive}
                      />
                    </div>
                  </TableCell>
                  <TableCell className="stats-team" style={{ width: "7%" }}>
                    Status
                  </TableCell>
                  <TableCell className="contacts" style={{ width: "6.2%" }}>
                    <div className="container-title-images">
                      <SortIconCampaigns
                        text={"Invites sent"}
                        sort={"invitation_sent"}
                        idx={"invitation_sent"}
                        orderby={this.props.orderby}
                        setOrderby={this.props.setOrderby}
                        setIsSortedAsc={this.setActiveFilterIcon.bind(this)}
                        isSortedAsc={this.state.isIconActive}
                      />
                    </div>
                  </TableCell>
                  <TableCell className="contacts" style={{ width: "8%" }}>
                    <div className="container-title-images">
                      <SortIconCampaigns
                        text={"Acceptance rate"}
                        sort={"acceptance_rate"}
                        idx={"acceptance_rate"}
                        orderby={this.props.orderby}
                        setOrderby={this.props.setOrderby}
                        setIsSortedAsc={this.setActiveFilterIcon.bind(this)}
                        isSortedAsc={this.state.isIconActive}
                      />
                    </div>
                  </TableCell>
                  <TableCell className="contacts" style={{ width: "7%" }}>
                    <div className="container-title-images">
                      <SortIconCampaigns
                        text={"Messages sent"}
                        sort={"messages_sent"}
                        idx={"messages_sent"}
                        orderby={this.props.orderby}
                        setOrderby={this.props.setOrderby}
                        setIsSortedAsc={this.setActiveFilterIcon.bind(this)}
                        isSortedAsc={this.state.isIconActive}
                      />
                    </div>
                  </TableCell>

                  <TableCell
                    className="steps"
                    style={{
                      width: "6%",
                    }}
                  >
                    <div className="container-title-images">
                      <SortIconCampaigns
                        text={"Reply rate"}
                        sort={"reply_rate"}
                        idx={"reply_rate"}
                        orderby={this.props.orderby}
                        setOrderby={this.props.setOrderby}
                        setIsSortedAsc={this.setActiveFilterIcon.bind(this)}
                        isSortedAsc={this.state.isIconActive}
                      />
                    </div>
                  </TableCell>

                  <TableCell className="date-reply" style={{ width: "9%" }}>
                    <div className="container-title-images-teams">
                      <SortIconCampaigns
                        text={"Created"}
                        sort={"created"}
                        idx={"created"}
                        orderby={this.props.orderby}
                        setOrderby={this.props.setOrderby}
                        setIsSortedAsc={this.setActiveFilterIcon.bind(this)}
                        isSortedAsc={this.state.isIconActive}
                        flexEnd={true}
                        scrollBarWidth={this.state.scrollBarWidth}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody ref={this.wrapper}>
                {this.props.campaignsTeams.map((row, index) => (
                  <TableRow key={row.id} className="table-row-teams">
                    <TableCell padding="checkbox" style={{ width: "5%" }}>
                      <div className="checkbox-container-teams">
                        <Checkbox
                          onClick={(event) => this.handleClick(event, row.id)}
                          checked={this.isSelected(row.id)}
                          inputProps={{
                            "aria-labelledby": `enhanced-table-checkbox-${index}`,
                          }}
                          icon={
                            <img src={notSelectedCheckboxIcon} alt="checkbox" />
                          }
                          checkedIcon={
                            <img src={selectedCheckboxIcon} alt="checkbox" />
                          }
                        />
                      </div>
                    </TableCell>

                    <TableCell
                      style={{ width: "26.8%" }}
                      scope="row"
                      className="name semi-bold title-company"
                    >
                      <div className="name-text-container">
                        <div className="wrapper-performed-bar">
                          <TooltipCustom
                            title={getDataNameWidth(row) ? row.name : ""}
                            arrow={true}
                          >
                            <p
                              className={
                                getDataNameWidth(row)
                                  ? "ellipsis"
                                  : "left-list-text"
                              }
                            >
                              {row.name}
                            </p>
                          </TooltipCustom>

                          {row.status !== "Compose" ? (
                            <div className="flex-container-performed-progress-campaigns">
                              <div className="performed-progress-campaigns">
                                <div
                                  className={`performed-bar ${this.buildClassByStatus(
                                    row.status
                                  )}`}
                                  style={{
                                    width: `${
                                      row.statistics.started_count &&
                                      row.target_audience_count
                                        ? (row.statistics.started_count /
                                            row.target_audience_count) *
                                          100
                                        : "0"
                                    }%`,
                                  }}
                                >
                                  &nbsp;
                                </div>
                              </div>

                              <div className="performed-bar-value">
                                <div className="performed-bar-text">
                                  {row.statistics.started_count}
                                </div>
                                <div className="performed-bar-text">
                                  {row.statistics.in_queue_count}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="flex-container">
                              <div className="team-contacts-text">
                                {row.target_audience_count ? (
                                  `Contacts: ${row.target_audience_count}`
                                ) : (
                                  <div
                                    className="add-contacts-button-team"
                                    onClick={(e) => {
                                      this.props.localAppProfile.personaId ===
                                      row.personaId
                                        ? this.props.history.push(
                                            `campaigns/${row.id}/contacts`
                                          )
                                        : this.addContactsHandler(e, row);
                                    }}
                                  >
                                    Add contacts
                                  </div>
                                )}
                              </div>
                              <div>
                                {row.steps.length === 0 ? (
                                  <button
                                    className="add-contacts-button-team"
                                    onClick={() => {
                                      this.props.localAppProfile.personaId ===
                                      row.personaId
                                        ? this.props.history.push(
                                            `/campaigns/${row.id}/tune`
                                          )
                                        : this.signInAddActions(row);
                                    }}
                                  >
                                    Add actions
                                  </button>
                                ) : (
                                  <div className="team-contacts-text">
                                    {`${row.steps[0].name} `}
                                    {row.steps.length - 1 > 0
                                      ? `(+${row.steps.length - 1})`
                                      : ""}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell className="account" style={{ width: "20%" }}>
                      <div className="account-container-team">
                        <img
                          className="avatar"
                          src={row.avatar ? row.avatar : avatarSvg}
                          alt="avatar"
                        />
                        <div className="ellipsis">
                          <div className="flex-wrapper-team-accent">
                            {(row.entityId || row.publicIdentifier) && (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="/#"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  await this.openLinkedInProfile(row);
                                }}
                              >
                                <TooltipCustom
                                  title="Open LinkedIn profile"
                                  arrow={true}
                                >
                                  <img
                                    className="link-img-accent "
                                    src={linkSvgGold}
                                    alt="link svg"
                                  />
                                </TooltipCustom>
                              </a>
                            )}
                            <div className={"text-box-wrapper"}>
                              <div>
                                <div className="edit-title ">
                                  <div
                                    className={
                                      getDataClientWidth(row)
                                        ? "text-box-wrapper-hover "
                                        : "text-box-wrapper"
                                    }
                                  >
                                    <TooltipCustom
                                      title={
                                        getDataClientWidth(row)
                                          ? row.nickname
                                          : ""
                                      }
                                      arrow={true}
                                    >
                                      <div className="text-page first-name semi-bold ellipsis">
                                        {row.nickname}
                                      </div>
                                    </TooltipCustom>
                                  </div>
                                  {this.props.localAppProfile.personaId ===
                                    row.personaId && "(you)"}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className={
                              getDataClientWidthEmail(row)
                                ? "text-box-wrapper-hover"
                                : "text-box-wrapper"
                            }
                          >
                            <TooltipCustom
                              title={
                                getDataClientWidthEmail(row) ? row.email : ""
                              }
                              arrow
                            >
                              <p className={"email-team ellipsis"}>
                                {row.email}
                              </p>
                            </TooltipCustom>
                          </div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell className="contacts" style={{ width: "7%" }}>
                      {buildStatus({
                        id: row.id,
                        pauseReasonStatus: row.pause_reason,
                        status: row.status,
                        campaignIdWithTip: this.state.campaignIdWithTip,
                        onClick: this.onClickStatus,
                      })}
                    </TableCell>
                    <TableCell
                      className="contacts   text-accent"
                      style={{ width: "6.2%" }}
                    >
                      {row.statistics.invitation_sent}
                    </TableCell>
                    <TableCell className="contacts" style={{ width: "8%" }}>
                      <div className="start-wrapper-accent">
                        <div className="start-end-date text-accent">
                          {row.statistics.acceptance_rate !== null
                            ? functions
                                .roundToDecimal(row.statistics.acceptance_rate)
                                .toString() + "%"
                            : "..."}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      className="contacts   text-accent"
                      style={{ width: "7%" }}
                    >
                      {row.statistics.messages_sent}
                    </TableCell>

                    <TableCell
                      className={
                        this.state.scrollBarWidth
                          ? "common-wrapper-scroll-team"
                          : "common-wrapper-team"
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div
                          className={`contacts  text-accent ${
                            this.state.scrollBarWidth
                              ? "width-with_scroll-percent"
                              : "width-with-out_scroll-percent"
                          }`}
                        >
                          {row.statistics.reply_rate !== null
                            ? functions
                                .roundToDecimal(row.statistics.reply_rate)
                                .toString() + "%"
                            : "..."}
                        </div>
                        <div
                          className={
                            this.state.scrollBarWidth
                              ? "width-with_scroll-teams"
                              : "width-with-out_scroll-teams"
                          }
                        >
                          <div className="start-end-date-teams">
                            {`${
                              row.created
                                ? functions.parseDateToDMY(row.created, "/")
                                : "..."
                            }`}
                          </div>
                          {this.buttonsActions(row)}
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {this.props.campaignsTeamsCount > this.state.rowsPerPage && (
            <div
              className={
                this.props.campaignsTeamsCount &&
                this.props.campaignsTeamsCount > this.state.rowsPerPage
                  ? "table-footer-accent"
                  : "table-footer"
              }
            >
              <div className="table-footer-pagination">
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[]}
                  count={this.props.campaignsTeamsCount}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onPageChange={this.handleChangePage}
                  ActionsComponent={(subProps) => (
                    <MTablePagination {...subProps} />
                  )}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    appPage: state.app.appPage,
    notification: state.notification,
    appProfile: state.app.appProfile,
    pageContentLoader: state.app.pageContentLoader,
    activity: state.activity,
    subscription: state.app.subscription,
    campaignsTeamsCount: state.campaignsTeams.campaignsTeamsCount,
    filters: state.campaigns.filters,
    searchValue: state.campaignsTeams.searchValueTeams,
    teams: state.campaignsTeams,
  };
};
export default connect(mapStateToProps)(TeamCampaignStatistics);
