import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import "./style.scss";
import TextField from "@material-ui/core/TextField";
import ArrowIcon from "../../../assets/image/icons/svg/arrow-down-grey.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tag from "../Tag";
import { Chip, Box } from "@material-ui/core";
import _ from "lodash";

DropdownSimple.propTypes = {
  id: PropTypes.number,
  multiple: PropTypes.bool,
  tags: PropTypes.bool,
  customIcon: PropTypes.node,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onTagClick: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  dataForAuto: PropTypes.any,
};

export function DropdownSimple(props) {
  const [showAll, setShowAll] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles(props.fromTeams);
  const uniqueId = _.uniqueId("dropdown-");
  const [inputVal, setInputVal] = React.useState(null);

  const getOptions = async () => {
    setOpen(true);
    setLoading(true);
    if (props.options.length === 0 || props.dataForAuto) {
      await props.getOptions();
    }
    //await props.getOptions({ take: 10 });
    setLoading(false);
  };

  const handleShowAll = (value) => {
    setShowAll(value);
  };

  useEffect(() => {
    let delayDebounceFn;
    setLoading(true);

    if (props.dataForAuto && inputVal !== null && open) {
      delayDebounceFn = setTimeout(async () => {
        await props.getOptions({
          ...props.dataForAuto,
          take: 10,
          keywords: inputVal + "*",
        });
        setLoading(false);
      }, 1000);
    } else {
      setLoading(false);
    }

    return () => clearTimeout(delayDebounceFn);
  }, [inputVal]);

  const onInputChange = async (value) => {
    if (props.dataForAuto) {
      setInputVal(value);
    }
  };

  return (
    <div
      className={props.fromTeams ? "dropdown-simple-accent" : "dropdown-simple"}
    >
      {props.label && (
        <label
          htmlFor={uniqueId}
          className="select-label dropdown-simple__select-label"
        >
          {props.label}
        </label>
      )}
      <Autocomplete
        multiple={!!props.multiple}
        blurOnSelect
        id={uniqueId}
        open={open}
        onOpen={getOptions}
        onClose={() => {
          setOpen(false);
        }}
        loading={loading}
        autoHighlight
        options={loading ? [] : props.options}
        getOptionLabel={(option) => {
          return option.text;
        }}
        renderOption={(props, option) => (
          <>
            {props.color && (
              <Box
                component="li"
                sx={{
                  width: "8px",
                  minWidth: "8px",
                  height: "8px",
                  backgroundColor: props.color,
                  borderRadius: "50%",
                  marginRight: "10px",
                }}
              />
            )}
            {props.img && (
              <Box
                component="img"
                atl="avatar"
                src={props.img}
                sx={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  marginRight: "10px",
                }}
              />
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "400px",
              }}
            >
              {props.text}
              {props.text2 && (
                <Box
                  component="div"
                  sx={{
                    fontSize: "13px",
                    color: "#b3b6bf",
                  }}
                >
                  {props.text2}
                </Box>
              )}
            </div>
          </>
        )}
        value={props.value}
        getOptionSelected={(option, value) => option.key === value.key}
        onChange={props.onChange}
        onInputChange={(event, value, reason) => onInputChange(value)}
        popupIcon={
          props.customIcon ? (
            props.customIcon
          ) : (
            <img
              src={ArrowIcon}
              className={"dropdown-simple__icon"}
              alt="dropdownicon"
            />
          )
        }
        classes={classes}
        className={props.customIcon ? "custom-icon" : ""}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={props.placeholder}
            variant="outlined"
            margin="none"
            padding="none"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option.text}
              size="small"
              {...getTagProps({ index })}
            />
          ))
        }
      />
      {props.multiple && props.tags && (
        <div className="dropdown-simple__tags">
          {props.value.map((item, index) => {
            if (index <= 2) {
              return (
                <Tag
                  key={item.key}
                  type="big"
                  text={item.text}
                  onClick={() => props.onTagClick(props.id, item)}
                />
              );
            }
            return "";
          })}
          {props.value.length > 3 && (
            <>
              {showAll ? (
                <div className={`dropdown-simple__hidden-tags`}>
                  {props.value.map((item, index) => {
                    if (index > 2) {
                      return (
                        <Tag
                          key={item.key}
                          type="big"
                          text={item.text}
                          onClick={() => props.onTagClick(props.id, item)}
                        />
                      );
                    }
                    return "";
                  })}
                </div>
              ) : (
                <button
                  type="button"
                  className="dropdown-simple__show-all"
                  onClick={() => handleShowAll(true)}
                >
                  Show more
                </button>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles(() => ({
  inputRoot: {
    padding: "6px 50px 6px 13px !important",
    backgroundColor: "#ffffff",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#EBEDF2",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#0cb39f",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#0cb39f",
      borderWidth: "1px",
    },
    "& .MuiAutocomplete-popupIndicator:hover": {
      backgroundColor: "transparent",
    },
  },
  input: {
    color: "#202020",
    padding: "0 !important",
    fontWeight: "normal !important",
    fontSize: "14px !important",
    lineHeight: "20px !important",
  },
  option: {
    color: "#202020 !important",
    padding: "6px 12px !important",
    fontWeight: "normal !important",
    fontSize: "14px !important",
    lineHeight: "20px !important",
  },
  noOptions: {
    color: "#202020 !important",
    fontWeight: "normal !important",
    fontSize: "14px !important",
    lineHeight: "20px !important",
  },
  tag: {
    display: "none !important",
  },
  popper: {},
  paper: {
    padding: "0 !important",
    margin: "0 !important",
    boxShadow: "none",
    borderRight: "1px solid #0cb39f",
    borderLeft: "1px solid #0cb39f",
    borderBottom: "1px solid #0cb39f",
    borderRadius: "4px",
    color: "#202020 !important",
    fontWeight: "normal !important",
    fontSize: "14px !important",
    lineHeight: "20px !important",
  },
}));

export default DropdownSimple;
