import { CONSTS } from "../config/objectConst";
import { stepExecutionState } from "./stepExecutionState";
import { executionHumanState } from "./executionHumanState";

let InProgressDefaultTitle = "In Progress";
let FailedDefaultTitle = "System error";
let CancelledDefaultTitle = "Cancelled by you";
let SkippedDefaultTitle = "Skipped by system";
let NotStartedDefaultTitle = "Not started";
let SucceedDefaultTitle = "Responded";
let IgnoredDefaultTitle = "Not responded";

function getInProgressDescription(stepType) {
  switch (stepType) {
    case CONSTS.AVAILABLE_ACTIONS.Connect.Type:
      return "Awaiting for connect";
    case CONSTS.AVAILABLE_ACTIONS.Message.Type:
      return "Awaiting for response";
    default:
      return InProgressDefaultTitle;
  }
}

function getIgnoredDescription(stepType) {
  switch (stepType) {
    case CONSTS.AVAILABLE_ACTIONS.Connect.Type:
      return "Withdrawn";
    default:
      return IgnoredDefaultTitle;
  }
}

function getSucceedDescription(stepType) {
  switch (stepType) {
    case CONSTS.AVAILABLE_ACTIONS.Connect.Type:
      return "Connected";
    default:
      return SucceedDefaultTitle;
  }
}

export const statesDescriptor = {
  getStepTitle: function (stepState, stepType) {
    switch (stepState) {
      case stepExecutionState.NotStarted:
        return NotStartedDefaultTitle;
      case stepExecutionState.InQueue:
        return "In queue";
      case stepExecutionState.InProgress:
        return getInProgressDescription(stepType);
      case stepExecutionState.Succeed:
        return getSucceedDescription(stepType);
      case stepExecutionState.Ignored:
        return getIgnoredDescription(stepType);
      case stepExecutionState.Failed:
        return FailedDefaultTitle;
      case stepExecutionState.Cancelled:
        return CancelledDefaultTitle;
      case stepExecutionState.Skipped:
        return SkippedDefaultTitle;
      default:
        throw new Error(`Not supported step state - ${stepState}`);
    }
  },

  getExecutionTitle: function (state) {
    switch (state) {
      case executionHumanState.NotStarted:
        return NotStartedDefaultTitle;
      case stepExecutionState.InProgress:
        return InProgressDefaultTitle;
      case stepExecutionState.Succeed:
        return SucceedDefaultTitle;
      case stepExecutionState.Ignored:
        return IgnoredDefaultTitle;
      case stepExecutionState.Cancelled:
        return CancelledDefaultTitle;
      case stepExecutionState.Skipped:
        return SkippedDefaultTitle;
      case stepExecutionState.Failed:
        return FailedDefaultTitle;
      default:
        throw new Error(`Not supported execution state - ${state}`);
    }
  },

  detectColor: function (state, step) {
    // NotStarted step is a special case, it is colored by own color.
    // All other steps are colored by execution state.
    if (step?.human_state === "NotStarted") {
      return "#EBEDF2";
    }

    switch (state) {
      case stepExecutionState.NotStarted:
      case stepExecutionState.InQueue:
        return CONSTS.COLORS_CAMPAIGNS.inQueue;
      case stepExecutionState.InProgress:
        return CONSTS.COLORS_CAMPAIGNS.inProgress;
      case stepExecutionState.Succeed:
        return CONSTS.COLORS_CAMPAIGNS.responded;
      case stepExecutionState.Ignored:
        return CONSTS.COLORS_CAMPAIGNS.ignored;
      case stepExecutionState.Cancelled:
        return CONSTS.COLORS_CAMPAIGNS.cancelled;
      case stepExecutionState.Skipped:
        return CONSTS.COLORS_CAMPAIGNS.skipped;
      case stepExecutionState.Failed:
        return CONSTS.COLORS_CAMPAIGNS.failed;
      default:
        throw new Error(`Not supported execution state ${state}`);
    }
  },
};
