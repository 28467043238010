import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((margin) => ({
  customTooltip: {
    // I used the rgba color for the standard "secondary" color
    fontSize: "14px",
    lineHeight: "19px",
    padding: "2px 12px 3px 12px",
    width: "fit-content",
    maxWidth: "95vw",
    marginTop: margin ? "7px" : "15px",
  },
}));

const TooltipCustom = (props) => {
  const { arrow, title, children, margin } = props;
  const classes = useStyles(margin);
  return title !== undefined ? (
    <Tooltip
      classes={{
        tooltip: classes.customTooltip,
        arrow: classes.customArrow,
      }}
      title={
        title && (
          <div>
            {title?.split("\n").map((paragraph, index) => (
              <p key={index} style={{ minHeight: "12px" }}>
                {paragraph}
              </p>
            ))}
          </div>
        )
      }
      enterDelay={500}
      enterNextDelay={500}
      leaveDelay={0}
      arrow={arrow}
    >
      {children}
    </Tooltip>
  ) : (
    children
  );
};

export default TooltipCustom;
