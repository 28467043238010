import React, { useState } from "react";
import "./styles.scss";
import { mailboxType } from "./Mailbox/Mailbox";

export const Tabs = ({ handleResetSearchQuery, filter, setFilter }) => {
  const [isActive, setIsActive] = useState({
    focused: filter === mailboxType.Focused || filter === mailboxType.Search,
    other: filter === mailboxType.Other,
  });
  return (
    <div className="container-nav-mailbox">
      <button
        className={
          isActive.focused ? "buttonFocused activeTab" : "buttonFocused"
        }
        onClick={() => {
          setIsActive({
            focused: true,
            other: false,
          });
          setFilter(mailboxType.Focused);
          handleResetSearchQuery();
        }}
      >
        <div
          className={
            isActive.focused
              ? "mailbox-tab__title"
              : "mailbox-tab__title-disabled"
          }
        >
          Focused
        </div>
      </button>
      <button
        className={isActive.other ? "buttonOther activeTab" : "buttonOther"}
        onClick={() => {
          setIsActive({
            focused: false,
            other: true,
          });
          setFilter(mailboxType.Other);
          handleResetSearchQuery();
        }}
      >
        <div
          className={
            isActive.other
              ? "mailbox-tab__title"
              : "mailbox-tab__title-disabled"
          }
        >
          Other
        </div>
      </button>
    </div>
  );
};

export default Tabs;
