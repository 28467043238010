import React from "react";
import "./style.scss";
import arrowIcon from "../../../../assets/image/icons/svg/arrow-right.svg";
import contactsIcon from "../../../../assets/image/icons/svg/icons/contacts.svg";

const ErrorExport = ({
  errorUploadingData,
  showResultHandler,
  closeHandler,
}) => {
  return (
    <div className="error-export">
      <p className="error-export__result">
        <span>
          {errorUploadingData.exported}/{errorUploadingData.expected ?? "0"}{" "}
          profiles
        </span>{" "}
        were uploaded
      </p>
      {errorUploadingData.navigationUrl && (
        <button className="btn semi-bold" onClick={showResultHandler}>
          <img src={contactsIcon} alt="contacts-icon" />
          Show results
        </button>
      )}
      <button className="error-export__skip" onClick={closeHandler}>
        <img src={arrowIcon} alt="go back icon" />
        Skip
      </button>
      <div className="hidden-close-icon" />
    </div>
  );
};

export default ErrorExport;
