/*
 *
 * Contacts reducer
 *
 */
const initialState = {
  campaigns: [],
  haveCampaigns: false,
  searchValue: "",
  searchArchiveValue: "",
  scrollToAction: false,
  loadCampaignsController: false,
  campaignsCount: null,
  filters: {
    active: false,
    stopped: false,
    completed: false,
    drafted: false,
    cancelled: false,
  },
};

const campaigns = (state = initialState, action) => {
  switch (action.type) {
    case "CAMPAIGNS_ARRAY":
      return Object.assign({}, state, {
        campaigns: action.payload.campaigns,
        campaignsCount: action.payload.campaignsCount,
      });
    case "LOAD_CAMPAIGNS_CONTROLLER":
      return Object.assign({}, state, {
        loadCampaignsController: action.payload.loadCampaignsController,
      });
    case "CAMPAIGN_EDIT_SCROLL_TO_ACTION":
      return Object.assign({}, state, {
        scrollToAction: action.payload.scrollToAction,
      });
    case "HAVE_CAMPAIGNS":
      return Object.assign({}, state, {
        haveCampaigns: action.payload.haveCampaigns,
      });
    case "SEARCH_VALUE":
      return Object.assign({}, state, {
        searchValue: action.payload.searchValue,
      });
    case "SEARCH_ARCHIVE_VALUE":
      return Object.assign({}, state, {
        searchArchiveValue: action.payload.searchArchiveValue,
      });
    case "CHANGE_FILTER_STATUS":
      return Object.assign({}, state, {
        filters: action.payload.filters,
      });

    default:
      return state;
  }
};

export default campaigns;
