import React, { Component } from "react";
import _ from "lodash";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { functions, saveData } from "../../../tools/functions";
import TooltipCustom from "../../atoms/tooltipCustom";
import { listeners } from "../../../api/listeners";
import Checkbox from "@material-ui/core/Checkbox";
// actions
import { campaign } from "../../../actions/campaign";
import { app } from "../../../actions/app";
// Components
import MTablePagination from "../../atoms/mTablePagination";
import { CircularProgress } from "@material-ui/core";

// Icons
import selectedCheckboxIcon from "../../../assets/image/icons/tables/checkbox2.svg";
import partiallySelectedCheckboxIcon from "../../../assets/image/icons/tables/checkbox3.svg";
import notSelectedCheckboxIcon from "../../../assets/image/icons/tables/checkbox.svg";
import iconArch from "../../../assets/image/icons/svg/icons/arch.svg";
import iconClone from "../../../assets/image/icons/svg/icons/clone.svg";
import iconUnarchive from "../../../assets/image/icons/svg/icons/unarch.svg";
import iconStart from "../../../assets/image/icons/svg/icons/start.svg";
import iconPause from "../../../assets/image/icons/svg/icons/pause.svg";
import iconStop from "../../../assets/image/icons/svg/icons/stop.svg";

// Style
import "./style.scss";
import { CONSTS } from "../../../config/objectConst";
import { notification } from "../../../actions/notification";
import HintTooltip from "../../atoms/HintTooltip";
import ModalWindow from "../ModalWindow";
import { apiCampaign } from "../../../api/campaign/apiCampaign";
import ConfirmationDialog from "../../atoms/ConfirmationDialog";
import { filters } from "../../../actions/filters";
import AddContactsModal from "../../organisms/AddContactsModal";
import ExportToCSVButton from "../../atoms/ExportToCSVButton";
import { pauseReason } from "../../../api/pauseReason";
import { buildStatus } from "../buildStatusCampaign";

export class PersonalCampaignsStatistics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isIconActive: {
        name: true,
        started: false,
        acceptance_rate: true,
        invitation_sent: true,
        messages_sent: true,
        reply_rate: true,
        started_count: true,
      },
      isLoadingCSV: false,
      campaignsOnPage: [],
      selected: [],
      rows: [],
      campaignIdWithTip: "",
      rowsPerPage: CONSTS.PAGE_SIZE,
      filterRows: [],
      paginationLabel: "",
      modalData: null,
      confirmationDialog: {
        id: null,
        status: false,
        data: {},
      },
      selectAllCampaignsValue: false,
      addNewContactsModal: false,
      selectedValues: 0,
      rowData: {
        id: null,
        name: "",
      },
      scrollBarWidth: false,
    };
    this.wrapper = React.createRef();
    this.connector = listeners.getConnector();
  }

  componentDidMount() {
    this.connector.eventHandlers.addAutomationEndedEventHandler(async () => {
      campaign.getRecentCampaigns();
      const filtersArray = this.props.buildFiltersArray(this.props.filters);
      await filters.getCampaigns({
        archived: false,
        skip:
          this.props.tableCampaignsStatisticsPage > 0
            ? this.props.tableCampaignsStatisticsPage * CONSTS.PAGE_SIZE
            : 0,
        filter: `${filtersArray.join(" or ")}`,
        keywords: this.props.searchValue,
        orderby: this.props.orderby,
      });
    });

    this.getCampaignsBySort();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    this.connector.eventHandlers.removeAutomationEndedEventHandlers();
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.orderby !== prevProps?.orderby) {
      this.getCampaignsBySort();
    }
    this.handleResize();
  }

  onSelectAllClick = () => {
    let newSelecteds = this.props.campaigns.map((item) => item.id);
    this.setState({
      selected: newSelecteds,
      selectedValues: this.props.campaignsCount,
      selectAllCampaignsValue: true,
    });
  };

  signInAddActions = (e, row) => {
    e.stopPropagation();
    this.props.history.push(`/campaigns/${row.id}/tune`);
  };
  onClearAllClick = () => {
    this.setState({
      selected: [],
      selectedValues: 0,
      selectAllCampaignsValue: false,
    });
  };

  handleClick = (event, name) => {
    const selectedIndex = this.state.selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, name);
    } else if (selectedIndex === 0) {
      this.setState({
        selectAllCampaignsValue: false,
      });
      newSelected = newSelected.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      this.setState({
        selectAllCampaignsValue: false,
      });
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      this.setState({
        selectAllCampaignsValue: false,
      });
      newSelected = newSelected.concat(
        this.state.selected.slice(0, selectedIndex),
        this.state.selected.slice(selectedIndex + 1)
      );
    }
    this.setState({
      selected: newSelected,
      selectedValues: newSelected.length,
    });
  };

  handleResize = () => {
    const scrollbarWidth =
      this.wrapper.current.offsetWidth - this.wrapper.current.clientWidth;

    if (scrollbarWidth > 0 && this.state.scrollBarWidth === false) {
      this.setState({ scrollBarWidth: true });
    }

    if (scrollbarWidth === 0 && this.state.scrollBarWidth === true) {
      this.setState({ scrollBarWidth: false });
    }
  };

  isSelected = (name) => this.state.selected.indexOf(name) !== -1;

  getCampaignsBySort = async (newPage) => {
    app.changeTableContentLoaderStatus(true);
    const filtersArray = this.props.buildFiltersArray(this.props.filters);
    await filters.getCampaigns({
      skip:
        this.props.tableCampaignsStatisticsPage > 0
          ? this.props.tableCampaignsStatisticsPage * CONSTS.PAGE_SIZE
          : 0,
      archived: this.props.archive,
      filter: `${filtersArray.join(" or ")}`,
      orderby: this.props.orderby,

      keywords: this.props.searchValue,
    });

    app.changeTableContentLoaderStatus(false);
  };

  handleChangePage = async (event, newPage) => {
    app.changeTableContentLoaderStatus(true);
    const filtersArray = this.props.buildFiltersArray(this.props.filters);
    await filters.getCampaigns({
      skip: newPage > 0 ? newPage * CONSTS.PAGE_SIZE : 0,
      archived: this.props.archive,
      filter: `${filtersArray.join(" or ")}`,
      keywords: this.props.searchValue,
      orderby: this.props.orderby,
    });
    this.props.setTableCampaignsStatisticsPage(newPage);
    app.changeTableContentLoaderStatus(false);
  };

  setActiveFilterIcon(key, value) {
    this.setState({
      isIconActive: {
        ...{
          name: true,
          started: true,
          acceptance_rate: true,
          invitation_sent: true,
          messages_sent: true,
          reply_rate: true,
          started_count: true,
        },
        [key]: value,
      },
    });
  }

  onClickCampaign = (id) => {
    this.props.archive
      ? this.props.history.push(`/archive/${id}`)
      : this.props.history.push(`/campaigns/${id}`);
  };

  onClickStatus = (id) => {
    this.setState({
      campaignIdWithTip: id,
    });
  };

  addToArchive = async (id) => {
    app.changeTableContentLoaderStatus(true);
    const filtersArray = this.props.buildFiltersArray(this.props.filters);
    await campaign.addCampaignToArchive(
      id,
      filtersArray,
      this.props.searchValue,
      this.props.tableCampaignsStatisticsPage,
      this.props.orderby
    );
    this.setState({
      selected: this.state.selected.filter((item) => item !== id),
      selectedValues: this.state.selectedValues - 1,
    });
  };

  onSelectAllPageClick = (event) => {
    this.setState({
      selectAllCampaignsValue: false,
      selectedValues:
        this.props.campaignsCount <= this.props.campaigns.length
          ? this.props.campaignsCount
          : this.props.campaigns.length,
    });

    if (event.target.checked) {
      let newSelected = this.props.campaigns;
      newSelected = newSelected.filter(
        (item) => !this.state.selected.includes(item)
      );

      this.setState({
        selected: this.state.selected.concat(newSelected).map((item, index) => {
          return item.id;
        }),
      });
      return;
    }

    this.setState({
      selected: [],
      selectedValues: 0,
    });
  };

  exportCampaigns = async () => {
    const filtersArray = this.props.buildFiltersArray(this.props.filters);
    const selectAllFilter =
      filtersArray.length > 0 ? `${filtersArray.join(" or ")}` : "";
    const selectId = this.state.selected;
    try {
      this.setState({ isLoadingCSV: true });
      const response = await apiCampaign.exportCampaignsApi({
        filter: this.state.selectAllCampaignsValue
          ? selectAllFilter
          : `id in (${selectId.map((item) => `'${item}'`)})`,
        orderby: this.props.orderby,
        archived: false,
        searchValue: this.state.selectAllCampaignsValue
          ? this.props.searchValue
          : "",
      });
      if (response.status === 200) {
        const data = response.data,
          fileName = functions.getFileName();
        saveData(data, fileName);
      }
    } catch (error) {
      notification.notificationUse(error);
    }
    this.setState({
      selected: [],
    });
    this.setState({ isLoadingCSV: false });
  };

  stopCampaign = (e, events, id) => {
    e.stopPropagation();
    const filtersArray = this.props.buildFiltersArray(this.props.filters);
    events.stop.enabled &&
      campaign.stopCampaign(id, {
        page: this.props.tableCampaignsStatisticsPage,
        filtersArray,
        searchValue: this.props.searchValue,
        orderby: this.props.orderby,
      });
  };
  completeCampaign = (e, events, id) => {
    e.stopPropagation();
    events.complete.enabled &&
      this.setState({
        modalData: { type: "1.2", id: id, events: events },
      });
  };

  cloneCampaign = (e, events, id) => {
    e.stopPropagation();
    events.clone.enabled &&
      this.setState({
        modalData: { type: "1.10", id: id, events: events },
      });
  };

  archiveCampaign = (e, events, id) => {
    e.stopPropagation();
    events.archive.enabled &&
      this.setState({
        modalData: { type: "1.7", id: id, events: events },
      });
  };
  unArchiveCampaign = (e, events, id) => {
    e.stopPropagation();
    events.unarchive.enabled && this.removeFromArchive(id);
  };

  runCampaign = (e, events, id, status) => {
    e.stopPropagation();
    events.run.enabled && this.startHandler(id, events, status);
  };

  removeFromArchive = (id) => {
    app.changeTableContentLoaderStatus(true);
    campaign.removeCampaignFromArchive(
      id,
      this.props.searchValue,
      this.props.tableCampaignsStatisticsPage,
      this.props.orderby
    );
  };

  cloneHandler = async (campaignId) => {
    let response = await campaign.cloneCampaign(campaignId);
    if (response) {
      this.props.history.push(`/campaigns/${response.id}/tune`);
    }
  };

  actionHandler = ({ type, id, events, status }) => {
    const filtersArray = this.props.buildFiltersArray(this.props.filters);
    try {
      switch (type) {
        case "1.2":
          events.complete.enabled &&
            campaign.completeCampaign(id, {
              page: this.props.tableCampaignsStatisticsPage,
              filtersArray,
              searchValue: this.props.searchValue,
              orderby: this.props.orderby,
            });
          break;

        case "1.5":
          events.run.enabled &&
            campaign.startCampaign(id, status, {
              page: this.props.tableCampaignsStatisticsPage,
              filtersArray,
              searchValue: this.props.searchValue,
              orderby: this.props.orderby,
            });
          break;

        case "1.7":
          events.archive.enabled && this.addToArchive(id);
          break;

        case "1.10":
          events.clone.enabled && this.cloneHandler(id);
          break;
        default:
          break;
      }
    } finally {
      this.setState({ modalData: null });
    }
  };

  startHandler = async (id, events, status) => {
    try {
      if (status === "Stopped") {
        const filtersArray = this.props.buildFiltersArray(this.props.filters);
        return await campaign.startCampaign(id, status, {
          page: this.props.tableCampaignsStatisticsPage,
          filtersArray,
          searchValue: this.props.searchValue,
          orderby: this.props.orderby,
        });
      }

      app.changeTableContentLoaderStatus(true);
      const preview = await apiCampaign.getPreviewOfStartupCampaing(id);

      if (!preview.data.validation_rules_summary.length) {
        this.setState({
          modalData: {
            type: "1.5",
            id: id,
            events: events,
            status: status,
            data: preview.data,
          },
        });
      } else {
        this.setState({
          confirmationDialog: {
            id: id,
            status: true,
            data: preview.data,
          },
          modalData: {
            type: "1.5",
            id: id,
            events: events,
            status: status,
            data: preview.data,
          },
        });
      }
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      app.changeTableContentLoaderStatus(false);
    }
  };

  addContactsHandler = (e, row) => {
    e.stopPropagation();
    this.props.history.push(`campaigns/${row.id}/contacts`);
  };

  buttonsActions = (id, status, events, date) => {
    return (
      <div
        className="btn-group group-position"
        style={{ position: "absolute" }}
      >
        {!this.props.archive && (
          <>
            <TooltipCustom title={events.run.hint} arrow={true}>
              <button
                className={
                  events.run.enabled
                    ? "btn-row start"
                    : "btn-row start disabled"
                }
                onClick={(e) => {
                  this.runCampaign(e, events, id, status);
                }}
              >
                <img src={iconStart} alt="iconStart" />
              </button>
            </TooltipCustom>

            <TooltipCustom title={events.stop.hint} arrow={true}>
              <button
                className={
                  events.stop.enabled ? "btn-row stop" : "btn-row stop disabled"
                }
                onClick={(e) => {
                  this.stopCampaign(e, events, id);
                }}
              >
                <img src={iconPause} alt="iconStop" />
              </button>
            </TooltipCustom>

            <TooltipCustom title={events.complete.hint} arrow={true}>
              <button
                className={
                  events.complete.enabled
                    ? "btn-row complete"
                    : "btn-row complete disabled"
                }
                onClick={(e) => {
                  this.completeCampaign(e, events, id);
                }}
              >
                <img src={iconStop} alt="iconPause" />
              </button>
            </TooltipCustom>

            <TooltipCustom title={events.clone.hint} arrow={true}>
              <button
                className={
                  events.clone.enabled
                    ? "btn-row complete"
                    : "btn-row complete disabled"
                }
                onClick={(e) => {
                  this.cloneCampaign(e, events, id);
                }}
              >
                <img src={iconClone} alt="clone" />
              </button>
            </TooltipCustom>

            <TooltipCustom title={events.archive.hint} arrow={true}>
              <button
                className={`btn-row archive ${
                  !this.props.archive ? "show" : ""
                } ${events.archive.enabled ? "" : "disabled"}`}
                onClick={(e) => {
                  this.archiveCampaign(e, events, id);
                }}
              >
                <img src={iconArch} alt="archive" />
              </button>
            </TooltipCustom>
          </>
        )}

        {this.props.archive && (
          <>
            <TooltipCustom title={events.unarchive.hint} arrow={true}>
              <button
                className={`btn-row unarchive ${
                  this.props.archive ? "show" : ""
                } ${events.unarchive.enabled ? "" : "disabled"}`}
                onClick={(e) => {
                  this.unArchiveCampaign(e, events, id);
                }}
              >
                <img src={iconUnarchive} alt="unarchive" />
              </button>
            </TooltipCustom>
          </>
        )}
      </div>
    );
  };

  buildClassByStatus = (status) => {
    switch (status) {
      case "Running":
        return "active";
      case "Stopped":
        return "paused";
      case "Completed":
        return "completed";
      case "Compose":
        return "drafted";

      default:
        break;
    }
  };

  render() {
    const getDataClientWidth = (row) =>
      (row?.name?.length > 30 &&
        document.documentElement.clientWidth > 1000 &&
        document.documentElement.clientWidth < 1537) ||
      (row?.name?.length > 45 &&
        document.documentElement.clientWidth > 1537 &&
        document.documentElement.clientWidth < 1746);

    return (
      <div
        className={`table-container campaigns-container ${
          this.props.campaignsCount > this.state.rowsPerPage
            ? "table-with-footer"
            : ""
        }`}
      >
        {this.state.campaignIdWithTip && (
          <div
            className="backdrop"
            onClick={() =>
              this.setState({
                campaignIdWithTip: "",
              })
            }
          />
        )}
        <div className="hint-tooltip-wrapper">
          <TableContainer className="table-body campaigns  with-checkboxs">
            {this.props.app.tableContentLoader &&
              !this.props.app.pageContentLoader && (
                <div className="table-material-loader-wrapper">
                  <CircularProgress
                    color="primary"
                    size={60}
                    classes={{ colorPrimary: "material-loader" }}
                  />
                </div>
              )}
            <Table
              className={"table"}
              size="small"
              aria-label="a dense table"
              style={{ tableLayout: "auto" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "5%" }}>
                    <div className="checkbox-container-header">
                      <Checkbox
                        indeterminate={
                          this.props.campaigns &&
                          this.props.campaigns.length <
                            this.state.selected.filter((item) =>
                              this.props.campaigns.includes(item)
                            ).length
                        }
                        checked={this.state.selected.length > 0}
                        onChange={this.onSelectAllPageClick}
                        inputProps={{ "aria-label": "select all desserts" }}
                        indeterminateIcon={
                          <img src={notSelectedCheckboxIcon} alt="checkbox" />
                        }
                        icon={
                          <img src={notSelectedCheckboxIcon} alt="checkbox" />
                        }
                        checkedIcon={
                          <img
                            src={
                              this.props.campaigns.length >
                              this.state.selectedValues
                                ? partiallySelectedCheckboxIcon
                                : selectedCheckboxIcon
                            }
                            alt="checkbox"
                          />
                        }
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    className="name title-company"
                    style={{ width: "28%" }}
                  >
                    {this.state.selected.length > 0 ? (
                      <div className="selected-rows">
                        <>
                          {this.state.selectedValues > 0 && (
                            <div>
                              <span className="select-text">
                                {this.state.selectedValues} of{" "}
                                {this.props.campaignsCount} selected |{" "}
                              </span>
                              {!this.state.selectAllCampaignsValue && (
                                <span
                                  className="clear-all"
                                  onClick={this.onSelectAllClick}
                                >
                                  Select all
                                </span>
                              )}

                              {this.state.selectAllCampaignsValue && (
                                <span
                                  className="clear-all"
                                  onClick={this.onClearAllClick}
                                >
                                  Clear
                                </span>
                              )}
                            </div>
                          )}
                          <div className="background-button">
                            <ExportToCSVButton
                              disabled={false}
                              isLoading={false}
                              onClick={() => {
                                this.exportCampaigns();
                              }}
                              text="Export to CSV"
                            ></ExportToCSVButton>
                          </div>
                        </>
                      </div>
                    ) : (
                      "Campaign"
                    )}
                  </TableCell>
                  <TableCell className="stats" style={{ width: "10%" }}>
                    Status
                  </TableCell>
                  <TableCell className="contacts" style={{ width: "10%" }}>
                    Invites sent
                  </TableCell>
                  <TableCell className="contacts" style={{ width: "10%" }}>
                    Acceptance rate
                  </TableCell>
                  <TableCell className="contacts" style={{ width: "10%" }}>
                    Messages sent
                  </TableCell>
                  <TableCell
                    className="contacts"
                    style={{
                      width: "10%",
                    }}
                  >
                    Reply rate
                  </TableCell>
                  <TableCell className="date-reply " style={{ width: "11%" }}>
                    <div
                      className={`width-with_scroll-campaigns ${
                        this.state.scrollBarWidth
                          ? "title-padding-scroll"
                          : "title-padding"
                      }`}
                    >
                      Created
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody ref={this.wrapper}>
                {this.props.campaigns.map((row, index) => (
                  <TableRow key={row.id} className="table-row">
                    <TableCell padding="checkbox" style={{ width: "5%" }}>
                      <div className="checkbox-container">
                        <Checkbox
                          onClick={(event) => this.handleClick(event, row.id)}
                          checked={this.isSelected(row.id)}
                          inputProps={{
                            "aria-labelledby": `enhanced-table-checkbox-${index}`,
                          }}
                          icon={
                            <img src={notSelectedCheckboxIcon} alt="checkbox" />
                          }
                          checkedIcon={
                            <img src={selectedCheckboxIcon} alt="checkbox" />
                          }
                        />
                      </div>
                    </TableCell>

                    <TableCell
                      onClick={() => this.onClickCampaign(row.id)}
                      style={{ width: "28%" }}
                      scope="row"
                      className="name semi-bold title-company"
                    >
                      <div className="name-text-container">
                        <div className="wrapper-performed-bar">
                          <TooltipCustom
                            title={getDataClientWidth(row) ? row.name : ""}
                            arrow={true}
                          >
                            <p
                              className={
                                getDataClientWidth(row)
                                  ? "ellipsis"
                                  : "left-list-text"
                              }
                            >
                              {row.name}
                            </p>
                          </TooltipCustom>
                          {row.status !== "Compose" ? (
                            <div className="flex-container-performed-progress-campaigns">
                              <div className="performed-progress-campaigns">
                                <div
                                  className={`performed-bar ${this.buildClassByStatus(
                                    row.status
                                  )}`}
                                  style={{
                                    width: `${
                                      row.statistics.started_count &&
                                      row.target_audience_count
                                        ? (row.statistics.started_count /
                                            row.target_audience_count) *
                                          100
                                        : "0"
                                    }%`,
                                  }}
                                >
                                  &nbsp;
                                </div>
                              </div>

                              <div className="performed-bar-value">
                                <div className="performed-bar-text">
                                  {row.statistics.started_count}
                                </div>
                                <div className="performed-bar-text">
                                  {row.statistics.in_queue_count}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="flex-container-team-statistics">
                              <div className="contacts-count">
                                {row.target_audience_count ? (
                                  `Contacts: ${row.target_audience_count}`
                                ) : (
                                  <div
                                    className="add-contacts-button-team"
                                    onClick={(e) => {
                                      this.addContactsHandler(e, row);
                                    }}
                                  >
                                    Add contacts
                                  </div>
                                )}
                              </div>
                              <div>
                                {row.steps.length === 0 ? (
                                  <button
                                    className="add-contacts-button-team"
                                    onClick={(e) => {
                                      this.signInAddActions(e, row);
                                    }}
                                  >
                                    Add actions
                                  </button>
                                ) : (
                                  <div className="contacts-count">
                                    {`${row.steps[0].name} `}
                                    {row.steps.length - 1 > 0
                                      ? `(+${row.steps.length - 1})`
                                      : ""}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        row.pause_reason !== pauseReason.exceed.code &&
                          row.pause_reason !== pauseReason.tooLong.code &&
                          this.onClickCampaign(row.id);
                      }}
                      className="contacts flex-container"
                      style={{ width: "10%" }}
                    >
                      {buildStatus({
                        id: row.id,
                        pauseReasonStatus: row.pause_reason,
                        status: row.status,
                        campaignIdWithTip: this.state.campaignIdWithTip,
                        onClick: this.onClickStatus,
                      })}
                    </TableCell>
                    <TableCell
                      onClick={() => this.onClickCampaign(row.id)}
                      className="contacts   text-accent"
                      style={{ width: "10%" }}
                    >
                      {row.statistics.invitation_sent}
                    </TableCell>
                    <TableCell
                      onClick={() => this.onClickCampaign(row.id)}
                      className="contacts"
                      style={{ width: "10%" }}
                    >
                      <div className="start-wrapper-accent">
                        <div className="start-end-date text-accent">
                          {row.statistics.acceptance_rate
                            ? functions
                                .roundToDecimal(row.statistics.acceptance_rate)
                                .toString() + "%"
                            : "..."}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      onClick={() => this.onClickCampaign(row.id)}
                      className="contacts   text-accent"
                      style={{ width: "10%" }}
                    >
                      {row.statistics.messages_sent}
                    </TableCell>

                    <TableCell
                      className={
                        this.state.scrollBarWidth
                          ? "common-wrapper-scroll"
                          : "common-wrapper"
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div
                          onClick={() => this.onClickCampaign(row.id)}
                          className={`contacts  text-accent ${
                            this.state.scrollBarWidth
                              ? "width-with_scroll-percent"
                              : "width-with-out_scroll-percent"
                          }`}
                        >
                          {row.statistics.reply_rate !== null
                            ? functions
                                .roundToDecimal(row.statistics.reply_rate)
                                .toString() + "%"
                            : "..."}
                        </div>
                        <div
                          onClick={() => this.onClickCampaign(row.id)}
                          className={
                            this.state.scrollBarWidth
                              ? "width-with_scroll-teams"
                              : "width-with-out_scroll-teams"
                          }
                        >
                          <div className="start-end-date-teams">
                            {`${
                              row.created
                                ? functions.parseDateToDMY(row.created, "/")
                                : "..."
                            }`}
                          </div>
                        </div>
                        <div className="column-width">
                          {this.buttonsActions(
                            row.id,
                            row.status,
                            row.events,
                            row.date
                          )}
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <HintTooltip
            placement="top-start"
            classes="add-contacts-tooltip"
            enabled={
              functions.isValidOnBoardingConditions(
                this.props.app.appProfile,
                "2.6"
              ) &&
              this.props.campaigns.length &&
              !this.props.app.pageContentLoader &&
              !localStorage.getItem("2.6")
            }
            tooltip={CONSTS.ON_BOARDING["2.6"]}
            handlerClose={() => {
              localStorage.setItem("2.6", "true");
            }}
          />
        </div>
        {this.props.campaignsCount > this.state.rowsPerPage && (
          <div
            className={
              this.props.campaignsCount &&
              this.props.campaignsCount > this.state.rowsPerPage
                ? "table-footer-accent"
                : "table-footer"
            }
          >
            <div className="table-footer-pagination">
              <TablePagination
                component="div"
                rowsPerPageOptions={[]}
                count={this.props.campaignsCount}
                rowsPerPage={this.state.rowsPerPage}
                page={this.props.tableCampaignsStatisticsPage}
                onPageChange={this.handleChangePage}
                ActionsComponent={(subProps) => (
                  <MTablePagination {...subProps} />
                )}
              />
            </div>
          </div>
        )}
        <ModalWindow
          open={!!this.state.modalData}
          closeText="No"
          confirmText="Yes"
          closeHandler={() => this.setState({ modalData: null })}
          confirmHandler={() => this.actionHandler(this.state.modalData)}
          title={
            this.state?.modalData
              ? CONSTS.confirmationDialogs[this.state.modalData?.type].title
              : ""
          }
          content={
            this.state.modalData
              ? CONSTS.confirmationDialogs[
                  this.state.modalData?.type
                ].content.replace(
                  "{contacts_count}",
                  this.state.modalData?.data?.valid_audience_count
                )
              : ""
          }
        />

        <ModalWindow
          open={this.state.confirmationDialog.status}
          closeHandler={() =>
            this.setState({
              confirmationDialog: { id: null, status: false, data: {} },
              modalData: null,
            })
          }
          classes={"confirmation-launch-modal"}
        >
          <ConfirmationDialog
            confirmationDialog={this.state.confirmationDialog}
            closeHandler={() =>
              this.setState({
                confirmationDialog: { id: null, status: false, data: {} },
                modalData: null,
              })
            }
            startCampaign={() => {
              this.actionHandler(this.state.modalData);
            }}
          />
        </ModalWindow>
        <ModalWindow
          open={this.state.addNewContactsModal}
          classes="add-contacts-modal"
          closeHandler={() => this.setState({ addNewContactsModal: false })}
        >
          <AddContactsModal
            classesTableContainer={"add-to-camp-container"}
            type="add-to-campaign"
            from="campaigns"
            showSteps={false}
            checkboxs={true}
            campaign={this.state.rowData}
            history={this.props.history}
            app={this.props.app}
            searchValue={this.props.searchValue}
            campaignId={this.state.rowData.id}
            profile={this.props.profile}
            buildFiltersArray={this.props.buildFiltersArray}
            filters={this.props.contactFilters}
            campaignFilters={this.props.filters}
          />
        </ModalWindow>
      </div>
    );
  }
}

export default PersonalCampaignsStatistics;
