import React, { Component } from "react";
import {
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import FormField from "../formField";
import TimerComponent, {
  InputWithArrows,
} from "../../organisms/CampaingsStepCreate/timer";
import ErrorHelper from "../../atoms/ErrorHelper";
import ErrorHelperStep from "../../atoms/ErrorHelperStep";
import { campaignCreate } from "../../../actions/campaignCreate";
import TextareaAutosize from "react-textarea-autosize";
import { scroller } from "react-scroll";
import { CONSTS } from "../../../config/objectConst";
import imgPoints from "../../../assets/image/icons/svg/points.svg";

import Like from "../../../assets/image/icons/emotions/like.png";
import Support from "../../../assets/image/icons/emotions/support.png";
import Celebrate from "../../../assets/image/icons/emotions/celebrate.png";
import Funny from "../../../assets/image/icons/emotions/funny.png";
import Love from "../../../assets/image/icons/emotions/love.png";
import Insightful from "../../../assets/image/icons/emotions/insightful.png";

const imageMap = {
  Like,
  Support,
  Celebrate,
  Funny,
  Love,
  Insightful,
};

export class SelectContainer extends Component {
  constructor(props) {
    super(props);
    this.message = React.createRef();

    this.state = {
      id: props.item.id ? props.item.id : "",
      name: props.item.name ? props.item.name : "",
      index: props.index ? props.index : "",
      launchActionIn: props.item.values.launchActionIn
        ? props.item.values.launchActionIn
        : 0,
      period: props.item.values.period ? props.item.values.period : "",
      message: props.item.values.textarea ? props.item.values.textarea : "",
      messageSelectionStart: 0,
      messageSelectionEnd: 0,
      sendMessageTo: props.item.values.sendMessageTo
        ? props.item.values.sendMessageTo
        : "Contacts, who never replied",
      values: props.item.values ? props.item.values : [],
      validation: props.item.validation ? props.item.validation : [],
      customNestedFields: false,
      currentEmotion: props.item.params.currentEmotion || "Like",
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.item.name !== prevProps.item.name) {
      this.setState({
        name: this.props.item.name,
      });
    }
  }

  save = () => {
    this.props.getSave(this.props.item.index);
  };

  arrayErrorMessages = (message) => {
    const newArrayErrorMessages = [];
    this.props
      .checkMessage(message)
      .map((el) => newArrayErrorMessages.push(el[0]));
    return [...new Set(newArrayErrorMessages)];
  };
  // launchAction and period Selected
  launchActionIn = (e, item) => {
    this.setState({
      launchActionIn: e.target.value,
    });
    this.props.validationLaunchActionIn(e.target.value, item.index);
  };
  handlePeriod = (value, item) => {
    this.setState({
      period: value,
    });
    this.props.validationPeriod(value, item.index);
  };

  // Textarea Actions
  handleMessage = (e, type) => {
    this.setState({
      message: e.target.value,
      messageSelectionStart: e.target.selectionStart,
      messageSelectionEnd: e.target.selectionEnd,
    });
    if (this.props.item.type) {
      this.props.validationMessage(e.target.value, this.props.item.index);
    }
  };
  handleMessageKeyDown = (e) => {
    this.setState({
      messageSelectionStart: e.target.selectionStart,
      messageSelectionEnd: e.target.selectionEnd,
    });
  };
  handleClickMessage = (e) => {
    this.setState({
      messageSelectionStart: e.target.selectionStart,
      messageSelectionEnd: e.target.selectionEnd,
    });
  };

  scrollHandler = () => {
    scroller.scrollTo(
      `myScrollToElement-zoomed-message`,
      {
        duration: 150,
        delay: 0,
        smooth: false,
        containerId: "homeTemplateContainerID",
        offset: -25,
      },
      0
    );
  };

  // Event click on field label -
  // add to textarea new values
  clickOnField = (value) => {
    let prevText = this.state.message.slice(
      0,
      this.state.messageSelectionStart
    );
    let beforeText = this.state.message.slice(
      this.state.messageSelectionEnd,
      this.message.length
    );
    let newText = "";
    this.setState({
      message: newText.concat(prevText, value, beforeText),
    });
    this.props.validationMessage(
      newText.concat(prevText, value, beforeText),
      this.props.item.index
    );
    this.message.current.focus();
    setTimeout(() => {
      this.message.current.setSelectionRange(
        this.state.messageSelectionStart + value.length,
        this.state.messageSelectionStart + value.length
      );
      this.setState({
        messageSelectionStart: this.state.messageSelectionStart + value.length,
        messageSelectionEnd: this.state.messageSelectionStart + value.length,
      });
    }, 0);
  };

  // Radio buttons - actions
  handleRadio = (e) => {
    this.setState({
      sendMessageTo: e.target.value,
    });
  };

  isValidLength = () => {
    return (
      this.state.message.length >
      (this.props.item.type === CONSTS.AVAILABLE_ACTIONS.Connect.Type
        ? CONSTS.AVAILABLE_ACTIONS.Connect.MaxLength
        : CONSTS.AVAILABLE_ACTIONS.Message.MaxLength)
    );
  };

  resizeHandler = () => {
    if (this.props.item.values.textarea) {
      this.props.setActionId(this.props.item.id);
      this.scrollHandler();
    }

    campaignCreate.zoomMessageWindow(this.state.message);
  };

  render() {
    return (
      <div className="select-container">
        {/* Launch action in */}
        {this.props.item.params.launchActionIn && (
          <FormField
            label={"Launch action in"}
            beaforeLabel={""}
            required={false}
          >
            <Select
              classes={{
                icon: "field-arrow-icon",
              }}
              onChange={(e) => this.launchActionIn(e, this.props.item)}
              value={Number(this.state.launchActionIn)}
              icon={"none"}
              className="select chose-action"
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
            >
              <MenuItem value={0}>Immediately</MenuItem>
              <MenuItem value={1 * 60}>1 hour</MenuItem>
              <MenuItem value={2 * 60}>2 hrs</MenuItem>
              <MenuItem value={24 * 60}>1 day</MenuItem>
              <MenuItem value={48 * 60}>2 days</MenuItem>
              <MenuItem value={72 * 60}>3 days</MenuItem>
              <MenuItem value={96 * 60}>1 week</MenuItem>
            </Select>
          </FormField>
        )}
        {/* Custom fields */}
        {this.state.customNestedFields && (
          <div
            className="backdrop"
            onClick={() =>
              this.setState({
                customNestedFields: false,
              })
            }
          ></div>
        )}

        {this.props.item.params.mainCustomFields && (
          <FormField
            label={"Personalize message"}
            beaforeLabel={"Add text to message"}
            required={false}
          >
            <div className="wrapper-field-list">
              <ul className="field-list">
                {this.props.item.params.mainCustomFields.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className="field-text"
                      onClick={() => {
                        this.clickOnField(item);
                      }}
                    >
                      {item}
                    </li>
                  );
                })}
              </ul>
              <div
                className="container-points"
                onClick={() =>
                  this.setState({
                    customNestedFields: !this.state.customNestedFields,
                  })
                }
              >
                <img src={imgPoints} alt="points" />
              </div>
              {this.state.customNestedFields &&
                this.props.item.params.overflowCustomFields && (
                  <ul className="field-list-accent">
                    {this.props.item.params.overflowCustomFields.map(
                      (item, index) => {
                        return (
                          <li
                            key={index}
                            className="field-text"
                            onClick={() => {
                              this.clickOnField(item);
                              this.setState({
                                customNestedFields: false,
                              });
                            }}
                          >
                            {item}
                          </li>
                        );
                      }
                    )}
                  </ul>
                )}
            </div>
          </FormField>
        )}
        {this.props.item.params.emotion && (
          <FormField
            label={"Select a reaction"}
            beaforeLabel={"React on last post"}
            required={false}
          >
            <ul className="field-list">
              {this.props.item.params.emotion.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={
                      this.state.currentEmotion === item
                        ? "field-text-emotion-active"
                        : "field-text-emotion"
                    }
                    onClick={() => {
                      this.props.saveReaction(item, this.props.item.index);
                      this.setState({
                        currentEmotion: item,
                      });
                    }}
                  >
                    <img
                      alt={item}
                      src={imageMap[item]}
                      className="action-emotion"
                      height="30px"
                    />
                    <p
                      className={
                        this.state.currentEmotion === item
                          ? "text-emotion-active"
                          : "text-emotion"
                      }
                    >
                      {item}
                    </p>
                  </li>
                );
              })}
            </ul>
          </FormField>
        )}
        {/* Text message */}
        {this.props.item.params.textarea && (
          <FormField
            label={
              this.props.item.type === CONSTS.AVAILABLE_ACTIONS.Message.Type
                ? CONSTS.AVAILABLE_ACTIONS.Message.Title
                : "Note message"
            }
            beaforeLabel={
              this.props.item.type === CONSTS.AVAILABLE_ACTIONS.Message.Type
                ? "Customize your message to get max results"
                : "Explain the reason of connection"
            }
            required={
              this.props.item.type === CONSTS.AVAILABLE_ACTIONS.Message.Type
            }
            classes={`note-message ${
              this.props.item.type === CONSTS.AVAILABLE_ACTIONS.Message.Type
                ? "short-label"
                : ""
            }`}
          >
            <div
              className={`textarea ${
                (this.props.item.validation.textarea !== undefined &&
                  !this.props.item.validation.textarea &&
                  this.state.message.length === 0 &&
                  this.props.item.type ===
                    CONSTS.AVAILABLE_ACTIONS.Message.Type) ||
                this.isValidLength()
                  ? "error"
                  : ""
              }`}
            >
              <div className="change-size" onClick={this.resizeHandler}>
                &nbsp;
              </div>
              <TextareaAutosize
                className="textarea-actions"
                ref={this.message}
                minRows={4}
                maxRows={10}
                onChange={(e) => this.handleMessage(e, this.props.item.type)}
                onClick={this.handleClickMessage}
                onKeyDown={this.handleMessageKeyDown}
                value={this.state.message}
                placeholder="Note something..."
              />
              <div className="counter">
                <span
                  className={`${
                    this.state.message.length >
                    (this.props.item.type ===
                    CONSTS.AVAILABLE_ACTIONS.Connect.Type
                      ? CONSTS.AVAILABLE_ACTIONS.Connect.MaxLength
                      : CONSTS.AVAILABLE_ACTIONS.Message.MaxLength)
                      ? "counter__error"
                      : ""
                  }`}
                >
                  {this.state.message.length}
                </span>
                /
                {this.props.item.type === CONSTS.AVAILABLE_ACTIONS.Connect.Type
                  ? CONSTS.AVAILABLE_ACTIONS.Connect.MaxLength
                  : CONSTS.AVAILABLE_ACTIONS.Message.MaxLength}
              </div>
            </div>
            {this.props.item.validation.textarea !== undefined &&
              !this.props.item.validation.textarea &&
              this.state.message.length === 0 &&
              this.props.item.type ===
                CONSTS.AVAILABLE_ACTIONS.Message.Type && (
                <ErrorHelper>Enter your message</ErrorHelper>
              )}

            {this.props.checkMessage(this.state.message).length !== 0 && (
              <div className="wrapper-list">
                {this.arrayErrorMessages(this.state.message).map((el) => (
                  <ErrorHelperStep key={el}>
                    {" "}
                    {el} is not supported
                  </ErrorHelperStep>
                ))}
              </div>
            )}

            {this.state.message.length >
              (this.props.item.type === CONSTS.AVAILABLE_ACTIONS.Connect.Type
                ? CONSTS.AVAILABLE_ACTIONS.Connect.MaxLength
                : CONSTS.AVAILABLE_ACTIONS.Message.MaxLength) && (
              <ErrorHelper>Too long message</ErrorHelper>
            )}
          </FormField>
        )}
        {/* Send message to */}
        {this.props.item.params.sendMessageTo && (
          <FormField label={"Send message to"} beaforeLabel={""}>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={this.state.sendMessageTo}
              onChange={this.handleRadio}
            >
              <FormControlLabel
                value="All contacts"
                control={<Radio className="radio-button" />}
                label="All contacts"
              />
              <FormControlLabel
                value="Contacts, who never replied"
                control={<Radio className="radio-button" />}
                label="Contacts, who never replied"
              />
            </RadioGroup>
          </FormField>
        )}
        {/* Checking period */}
        {this.props.item.params.period &&
          this.state.name === CONSTS.AVAILABLE_ACTIONS.Connect.Title && (
            <FormField
              label={"Withdraw request in"}
              beaforeLabel={"Cancel connection request that was not accepted"}
              required={true}
              classes="short-label"
            >
              <div className="days-flex-container">
                <TimerComponent
                  showController={true}
                  className="withdraw-timer"
                  item={this.props.item}
                  timerChanger={this.props.timerChanger}
                  time={Number(this.props.item.values.period)}
                  unique={"withdraw"}
                />
              </div>
            </FormField>
          )}
      </div>
    );
  }
}

export default SelectContainer;
