import store from "../redux/store";
import { campaign } from "./campaign";
import { notification } from "./notification";
import { CONSTS } from "../config/objectConst";
export const campaignCreate = {
  editScrollEvent(value) {
    store.dispatch({
      type: "CAMPAIGN_EDIT_SCROLL_TO_ACTION",
      payload: {
        scrollToAction: value,
      },
    });
  },

  buildTimeOutValue(item) {
    return item.values.period;
  },

  async save(stepValues, initId, history) {
    try {
      let steps = [];
      stepValues.map((item) => {
        return steps.push({
          type: item.type,
          message_template: item.values.textarea ? item.values.textarea : "",
          timeout: item.values.period
            ? campaignCreate.buildTimeOutValue(item)
            : 0,
          reaction:
            item.type === CONSTS.AVAILABLE_ACTIONS.ReactOnPost.Type
              ? item.values.currentEmotion
              : undefined,
        });
      });

      let indexOfConnect = stepValues.findIndex(
        (el) => el.name === CONSTS.AVAILABLE_ACTIONS.Connect.Type
      );
      if (indexOfConnect >= 0 && indexOfConnect != stepValues.length - 1) {
        let delayObject = {
          type: "Delay",
          timeout: stepValues[indexOfConnect].values.delay
            ? stepValues[indexOfConnect].values.delay
            : 0,
        };
        steps.splice(indexOfConnect + 1, 0, delayObject);
      }

      let data = {
        steps: steps,
        id: initId,
      };

      let response = await campaign.updateCampaign(data);
      if (response) {
        history();
      }
    } catch (e) {
      notification.notificationUse(e);
    }
  },

  changeFinishStep(value) {
    store.dispatch({
      type: "CAMPAIGN_CREATE_FINISH_STEP",
      payload: {
        finishStep: value,
      },
    });
  },

  zoomMessageWindow(value) {
    store.dispatch({
      type: "ZOOM_MESSAGE_WINDOW",
      payload: {
        zoomMessageWindow: value,
      },
    });
  },
};
