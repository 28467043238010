import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
// Actions
import { app } from "../../actions/app";
import { campaign } from "../../actions/campaign";
// Components
import HomeTemplate from "../../components/templates/homeTemplate";
import DescriptionSegment from "../../components/organisms/descriptionSegment";
import CampaignDescriptionSegment from "../../components/organisms/CampaignDescriptionSegment";
import addIcon from "../../assets/image/icons/svg/icons/Plus3.svg";
import dotWithBone from "../../assets/image/snail-catches.png";
import { CircularProgress } from "@material-ui/core";
import NotfoundPage from "../notfound";
import { listeners } from "../../api/listeners";
import { notification } from "../../actions/notification";
import snail from "../../assets/image/snail-catches.png";

export class singleCampaignPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      controller: true,
      pageFound: true,
    };
    this.connector = listeners.getConnector();
  }

  componentDidMount() {
    app.changePage("/campaigns");
    app.changeActiveItem(this.props.match.params.id);
    this.getCampaign(this.props.match.params.id, true);
    this.connector.eventHandlers.addAutomationEndedEventHandler(async () => {
      await this.getCampaign(this.props.match.params.id, false);
    });
  }

  componentWillUnmount() {
    this.connector.eventHandlers.removeAutomationEndedEventHandlers();
  }

  componentWillReceiveProps(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      campaign.campaignClearId();
      this.getCampaign(prevProps.match.params.id, true);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.campaign.id !== this.props.campaign.id &&
      this.state.controller !== false
    ) {
      this.setState({ controller: false });
    }
  }

  getStatus = (info) => {
    return info.find((item) => item.id === 1).value;
  };

  getCampaign = async (campaignId, rerender) => {
    try {
      rerender && app.changePageContentLoaderStatus(true);
      await campaign.getRecentCampaigns();
      let response = await campaign.getCampaignId(campaignId, rerender);
      await campaign.getCampaignExecutionsStatistics(campaignId);
      if (!response) {
        this.setState({ pageFound: false });
      }
    } catch (e) {
      notification.notificationUse(e);
      this.setState({ isError: true });
    } finally {
      app.changePageContentLoaderStatus(false);
    }
  };

  render() {
    return (
      <>
        {this.state.pageFound ? (
          <HomeTemplate>
            {this.props.app.pageContentLoader && (
              <div className="material-loader-wrapper">
                <CircularProgress
                  color="primary"
                  size={60}
                  classes={{ colorPrimary: "material-loader" }}
                />
              </div>
            )}
            {!this.state.controller && !this.state.isError && (
              <>
                <CampaignDescriptionSegment
                  campaign={this.props.campaign}
                  archive={this.props.archive}
                />
                {!this.props.archive &&
                  this.props.campaign.information &&
                  this.getStatus(this.props.campaign.information) ===
                    "Compose" &&
                  !this.props.campaign.actions.length && (
                    <div className="add-action">
                      <div className="add-action__wrapper">
                        <img src={`${dotWithBone}`} alt="snail" width={190} />
                        <button
                          className="btn with-border semi-bold"
                          onClick={() => {
                            this.props.history.push(
                              `/campaigns/${this.props.campaign.id}/tune`
                            );
                          }}
                        >
                          <img src={addIcon} alt="add-icon" />
                          Add actions
                        </button>
                      </div>
                    </div>
                  )}
                <DescriptionSegment campaign={this.props.campaign} />
              </>
            )}
          </HomeTemplate>
        ) : (
          <NotfoundPage />
        )}
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    app: state.app,
    campaign: state.campaign,
    singleLoaderPage: state.campaign.singleLoaderPage,
  };
};

export default connect(mapStateToProps)(withRouter(singleCampaignPage));
