import store from "../redux/store";
import pageIcon from "../assets/image/icons/left-side-menu/page.svg";
import { api } from "../api/api";
import { mapping } from "./mapping";
import { app } from "./app";
import { helpersFunc } from "../config/helpers";
import { notification } from "./notification";
import _ from "lodash";

export const contacts = {
  loadContactsList: (data) => {
    let response = {
      type: "contacts",
      label: "Recently imported",
      bottomList: [
        {
          id: 1,
          image: pageIcon,
          title: "All contacts",
          link: "/contacts",
        },
      ],
      list: data,
    };
    store.dispatch({
      type: "LEFT_SIDE_MENU_LIST",
      payload: {
        leftSide: response,
      },
    });
  },
  getRecentImports: async () => {
    store.getState().leftSideMenu.leftSide.type !== "contact" &&
      contacts.loadContactsList([]);
    try {
      const response = await api.getRecentImportsApi();
      const formatResponse = mapping.buildRecentImports(
        response.data ? response.data : []
      );
      store.dispatch({
        type: "RECENT_IMPORTS",
        payload: {
          imports: response.data,
        },
      });
      contacts.loadContactsList(formatResponse);
    } catch (error) {
      store.dispatch({
        type: "RECENT_IMPORTS",
        payload: {
          imports: [],
        },
      });
      contacts.loadContactsList([]);
      helpersFunc.logEnjectServicePromise(error);
    }
  },
  getImports: async (id) => {
    try {
      const response = await api.getImportsApi(id);
      return response;
    } catch (error) {}
  },

  getContacts: async (data, status, companyId, rerender = true) => {
    if (rerender) {
      contacts.clearContacts();
      app.changePageContentLoaderStatus(true);
      app.changeTableContentLoaderStatus(true);
    }
    let contactsArray = [];
    let executions = [];
    let response;
    try {
      if (
        (data.status && data.status !== "Compose") ||
        (status && status !== "Compose")
      ) {
        const contactsExecutionsRes = await api.getContactsExecutionsApi({
          skip: data.skip,
          filter: companyId ? `campaign_id eq '${companyId}'` : data.filter,
          orderby:
            data.orderby && data.orderby !== "imported asc"
              ? data.orderby
              : "created asc, id asc",
          keywords: data.keywords,
        });
        if (contactsExecutionsRes.status === 200) {
          executions = contactsExecutionsRes.data.documents;
        }
        response = contactsExecutionsRes;
        const linkedin_contacts = contactsExecutionsRes.data.documents.map(
          (el) => {
            const { id, ...linkedin_contact } = el.linkedin_contact;
            return { ...el, ...linkedin_contact };
          }
        );

        response = {
          ...contactsExecutionsRes,
          data: {
            ...contactsExecutionsRes.data,
            documents: [...linkedin_contacts],
          },
        };
      } else {
        response = await api.getContactsApi(data);
      }

      if (!response.data) {
        contacts.haveContacts(false);
      }

      if (response.data) {
        if (response.data.documents.length > 0) {
          contacts.haveContacts(true);
          store.dispatch({
            type: "CONTACTS_COUNT",
            payload: {
              contactsCount: response.data.total,
            },
          });
        }
        contactsArray =
          (data.status && data.status !== "Compose") ||
          (status && status !== "Compose")
            ? mapping.buildCampaignContacts(response.data.documents, executions)
            : mapping.buildContacts(response.data.documents);
      }

      app.setErrorRequest({
        ...store.getState().app.errorRequest,
        contacts: false,
      });

      store.dispatch({
        type: "ALL_CONTACTS",
        payload: {
          contacts: contactsArray,
        },
      });
      return response;
    } catch (error) {
      app.setErrorRequest({
        ...store.getState().app.errorRequest,
        contacts: true,
      });
      contacts.haveContacts(false);
      notification.notificationUse(error);
      store.dispatch({
        type: "ALL_CONTACTS",
        payload: {
          contacts: [],
        },
      });
      return { status: "error", response: error.response };
    } finally {
      rerender && app.changePageContentLoaderStatus(false);
      rerender && app.changeTableContentLoaderStatus(false);
    }
  },
  haveContacts: (status) => {
    store.dispatch({
      type: "HAVE_CONTACTS",
      payload: {
        haveContacts: status,
      },
    });
  },

  changeContactsPage: (page) => {
    store.dispatch({
      type: "CONTACTS_PAGE",
      payload: {
        contactsPage: page,
      },
    });
  },

  clearContacts: () => {
    store.dispatch({
      type: "ALL_CONTACTS",
      payload: {
        contacts: [],
      },
    });
    store.dispatch({
      type: "CONTACTS_COUNT",
      payload: {
        contactsCount: 0,
      },
    });

    store.dispatch({
      type: "HAVE_CONTACTS",
      payload: {
        haveContacts: false,
      },
    });
  },
  cancelExecution: (data) => {
    store.dispatch({
      type: "ALL_CONTACTS",
      payload: {
        contacts: data,
      },
    });
    app.changeTableContentLoaderStatus(false);
  },
  updateImportedContact: (data) => {
    const tempImports = store.getState().contacts.imports;
    const index = _.findIndex(tempImports, {
      id: data.id,
    });
    tempImports.splice(index, 1, data);
    store.dispatch({
      type: "UPDATE_IMPORTED_CONTACT",
      payload: {
        imports: tempImports,
      },
    });
  },
};
