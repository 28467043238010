import React from "react";
import "./style.scss";

const ExportTab = ({
  activeTabsProfile,
  setActiveTabsProfile,
  setFullFile,
  setReadError,
  tab,
  text,
  icon,
  iconActive,
}) => {
  return (
    <div
      onClick={() => {
        activeTabsProfile !== tab && setActiveTabsProfile(tab);
        setFullFile([]);
        setReadError("");
      }}
      className={
        activeTabsProfile === tab ? "tabs-container-active" : "tabs-container"
      }
    >
      <img
        src={activeTabsProfile === tab ? iconActive : icon}
        alt="icon Upload Profile"
      />
      <p className="csv-export__headline">{text}</p>
    </div>
  );
};

export default ExportTab;
