import React, { Component } from "react";
import PropTypes from "prop-types";
import CloseIcon from "../../../assets/image/icons/svg/close-icon-grey.svg";
import "./style.scss";

export class Tag extends Component {
  static propTypes = {
    type: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func,
  };

  render() {
    return (
      <>
        {this.props.type === "small" && (
          <div className="tag tag_small" onClick={this.props.onClick}>
            <img
              className="tag__close-icon"
              src={CloseIcon}
              onClick={this.props.onClick}
              alt="close"
            />
            <span className="tag__text">{this.props.text}</span>
          </div>
        )}
        {this.props.type === "big" && (
          <div className="tag tag_big" onClick={this.props.onClick}>
            <img
              className="tag__close-icon"
              src={CloseIcon}
              onClick={this.props.onClick}
              alt="close"
            />
            <div className="tag__text">{this.props.text}</div>
          </div>
        )}
      </>
    );
  }
}

export default Tag;
