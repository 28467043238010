import { httpRequest } from "./httpRequest";
import { CONSTS } from "../config/objectConst";

export const automationApi = {
  getRecentFocusedConversationsApi: async () => {
    return await httpRequest({
      url: `${CONSTS.API.URL_AUTOMATION}mailboxes/focused/conversations/recent`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getRecentArchivedConversationsApi: async () => {
    return await httpRequest({
      url: `${CONSTS.API.URL_AUTOMATION}mailboxes/archive/conversations/recent`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getRecentSpamConversationsApi: async () => {
    return await httpRequest({
      url: `${CONSTS.API.URL_AUTOMATION}mailboxes/spam/conversations/recent`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getRecentUnreadConversationsApi: async () => {
    return await httpRequest({
      url: `${CONSTS.API.URL_AUTOMATION}mailboxes/unread/conversations/recent`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getRecentMyConnectionsConversationsApi: async () => {
    return await httpRequest({
      url: `${CONSTS.API.URL_AUTOMATION}mailboxes/my-connections/conversations/recent`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getRecentInMailConversationsApi: async () => {
    return await httpRequest({
      url: `${CONSTS.API.URL_AUTOMATION}mailboxes/in-mail/conversations/recent`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getRecentSearchConversationsApi: async (search) => {
    return await httpRequest({
      url: `${CONSTS.API.URL_AUTOMATION}mailboxes/search/conversations/recent?keywords=${search}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getRecentStarredConversationsApi: async () => {
    return await httpRequest({
      url: `${CONSTS.API.URL_AUTOMATION}mailboxes/starred/conversations/recent`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getNextFocusedConversationsApi: async (nextCursor) => {
    return await httpRequest({
      url: `${CONSTS.API.URL_AUTOMATION}mailboxes/focused/conversations/next?nextCursor=${nextCursor}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getRecentOtherConversationsApi: async () => {
    return await httpRequest({
      url: `${CONSTS.API.URL_AUTOMATION}mailboxes/other/conversations/recent`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getRecentPrimaryConversationsApi: async () => {
    return await httpRequest({
      url: `${CONSTS.API.URL_AUTOMATION}mailboxes/primary/conversations/recent`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getNextOtherConversationsApi: async (nextCursor) => {
    return await httpRequest({
      url: `${CONSTS.API.URL_AUTOMATION}mailboxes/other/conversations/next?nextCursor=${nextCursor}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getNextArchivedConversationsApi: async (nextCursor) => {
    return await httpRequest({
      url: `${CONSTS.API.URL_AUTOMATION}mailboxes/archive/conversations/next?nextCursor=${nextCursor}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getNextSpamConversationsApi: async (nextCursor) => {
    return await httpRequest({
      url: `${CONSTS.API.URL_AUTOMATION}mailboxes/spam/conversations/next?nextCursor=${nextCursor}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getNextInMailConversationsApi: async (nextCursor) => {
    return await httpRequest({
      url: `${CONSTS.API.URL_AUTOMATION}mailboxes/in-mail/conversations/next?nextCursor=${nextCursor}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getNextSearchConversationsApi: async (nextCursor, search) => {
    return await httpRequest({
      url: `${CONSTS.API.URL_AUTOMATION}mailboxes/search/conversations/next?nextCursor=${nextCursor}&keywords=${search}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getNextMyConnectionsConversationsApi: async (nextCursor) => {
    return await httpRequest({
      url: `${CONSTS.API.URL_AUTOMATION}mailboxes/my-connections/conversations/next?nextCursor=${nextCursor}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getNextPrimaryConversationsApi: async (nextCursor) => {
    return await httpRequest({
      url: `${CONSTS.API.URL_AUTOMATION}mailboxes/primary/conversations/next?nextCursor=${nextCursor}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getNextUnreadConversationsApi: async (nextCursor) => {
    return await httpRequest({
      url: `${CONSTS.API.URL_AUTOMATION}mailboxes/unread/conversations/next?nextCursor=${nextCursor}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getNextStarredConversationsApi: async (nextCursor) => {
    return await httpRequest({
      url: `${CONSTS.API.URL_AUTOMATION}mailboxes/starred/conversations/next?nextCursor=${nextCursor}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getRecentEventsApi: async (id) => {
    return await httpRequest({
      url: `${CONSTS.API.URL_AUTOMATION}conversations/${id}/events/recent`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getNextEventsApi: async (id, cursor) => {
    return await httpRequest({
      url: `${CONSTS.API.URL_AUTOMATION}conversations/${id}/events/next?prevCursor=${cursor}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getMailboxSettings: async () => {
    return await httpRequest({
      url: `${CONSTS.API.URL_AUTOMATION}settings`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getMailboxParticipants: async (conversationId) => {
    return await httpRequest({
      url: `${CONSTS.API.URL_AUTOMATION}conversations/${conversationId}/participants`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getAttachment: async (
    conversationId,
    attachmentUrl,
    recommendedAcceptHeader
  ) => {
    return await httpRequest({
      url: `${
        CONSTS.API.URL_AUTOMATION
      }conversations/${conversationId}/attachments/${encodeURIComponent(
        attachmentUrl
      )}`,
      method: "GET",
      responseType: "blob",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Accept": recommendedAcceptHeader,
      },
    });
  },
  sendMessage: async (conversationId, message) => {
    return await httpRequest({
      url: `${CONSTS.API.URL_AUTOMATION}conversations/${conversationId}/events`,
      method: "POST",
      data: { message, attachments: [] },
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  editMessage: async (conversationId, eventId, message) => {
    return await httpRequest({
      url: `${CONSTS.API.URL_AUTOMATION}conversations/${conversationId}/events/${eventId}`,
      method: "PUT",
      data: message,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  markConversationAsRead: async (conversationId) => {
    return await httpRequest({
      url: `${CONSTS.API.URL_AUTOMATION}conversations/${conversationId}/read`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  deleteMessage: async (conversationId, eventId) => {
    return await httpRequest({
      url: `${CONSTS.API.URL_AUTOMATION}conversations/${conversationId}/events/${eventId}`,
      method: "DELETE",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
};
