import React, { useState } from "react";
import "../style.scss";
import clockIconActive from "../../../../assets/image/icons/svg/icons/clock.svg";
import clockIcon from "../../../../assets/image/icons/svg/icons/recentUsual.svg";
import dollarActive from "../../../../assets/image/icons/svg/icons/dollarBlack.svg";
import dollar from "../../../../assets/image/icons/svg/icons/dollar.svg";
import auto from "../../../../assets/image/icons/svg/icons/auto-mode.svg";
import autoBlack from "../../../../assets/image/icons/svg/icons/auto-mode-black.svg";
import WorkingHours from "../../../organisms/userProfilePersona/workingHours";
import SubscriptionBilling from "../subscriptionBilling";
import { useSelector } from "react-redux";
import AutomationMode from "../automationMode";

const WorkingHoursProfile = ({ getPeriodStatisticsAsync }) => {
  const { connector } = useSelector((state) => state.app);
  const [isActive, setIsActive] = useState({
    automationMode: true,
    workingHours: false,
    subscriptionBilling: false,
  });

  return (
    <div className="working-hours-profile">
      <div className="working-hours-profile__header">
        <div className="container-nav-account">
          <button
            className={
              isActive.automationMode
                ? "buttonWorkingHoursActive"
                : "buttonWorkingHours"
            }
            onClick={() =>
              setIsActive({
                automationMode: true,
              })
            }
          >
            <div
              className={
                isActive.automationMode
                  ? "working-hours-profile__title"
                  : "working-hours-profile__title-disabled"
              }
            >
              <img
                src={isActive.automationMode ? autoBlack : auto}
                alt="clock icon "
              />
              Automation Mode
            </div>
          </button>
          <button
            className={
              isActive.workingHours
                ? "buttonWorkingHoursActive"
                : "buttonWorkingHours"
            }
            onClick={() =>
              setIsActive({
                workingHours: true,
              })
            }
          >
            <div
              className={
                isActive.workingHours
                  ? "working-hours-profile__title"
                  : "working-hours-profile__title-disabled"
              }
            >
              <img
                src={isActive.workingHours ? clockIconActive : clockIcon}
                alt="clock icon "
              />
              Working hours
            </div>
          </button>
          <button
            className={
              isActive.subscriptionBilling
                ? "buttonSubscriptionBillingActive"
                : "buttonSubscriptionBilling"
            }
            onClick={() =>
              setIsActive({
                subscriptionBilling: true,
              })
            }
          >
            <div
              className={
                isActive.subscriptionBilling
                  ? "working-hours-profile__title"
                  : "working-hours-profile__title-disabled"
              }
            >
              <img
                src={isActive.subscriptionBilling ? dollarActive : dollar}
                alt="dollar "
              />
              Subscription & Billing
            </div>
          </button>
        </div>
      </div>
      <div className="working-line-list">&nbsp;</div>
      {isActive.automationMode && <AutomationMode />}
      {isActive.workingHours && (
        <WorkingHours getPeriodStatisticsAsync={getPeriodStatisticsAsync} />
      )}
      {isActive.subscriptionBilling && (
        <SubscriptionBilling connector={connector} />
      )}
    </div>
  );
};

export default WorkingHoursProfile;
