import React, { Component } from "react";
import { PieChart } from "react-minimal-pie-chart";
import _ from "lodash";
// Components
import StatisticList from "../../molecules/statisticList";
// Style
import "./style.scss";
import { functions } from "../../../tools/functions";

const defaultLabelStyle = {
  fontSize: "8px",
  fontFamily: "sans-serif",
};

const lineWidth = 60;

function composeHoverTitle(item, total) {
  let result =
    total > 0
      ? `${item.title} - ${functions.calculatePercentage(item.value, total)}%`
      : item.title;
  return result;
}

function composeSegmentTitle(value, total) {
  let percentage = functions.calculatePercentage(value, total);
  let result = percentage >= 5 ? `${percentage}%` : "";
  return result;
}

export class Chart extends Component {
  calcProcessedCount = () => {
    let result = 0;
    let statistics = this.props.statistics;
    Object.keys(statistics).forEach(function (key) {
      result += statistics[key].value;
    });
    result -= this.props.statistics.notStarted.value;
    return result;
  };

  calcSuccessRate = () => {
    let sum = this.calcProcessedCount();
    let percentage = `${functions.calculatePercentage(
      this.props.statistics.succeed.value,
      sum
    )}%`;
    let result = `${percentage} (${this.props.statistics.succeed.value} of ${sum})`;
    return result;
  };

  calcProcessedRate = () => {
    let processedCount = this.calcProcessedCount();
    let totalCount = processedCount + this.props.statistics.notStarted.value;
    let percentage = `${functions.calculatePercentage(
      processedCount,
      totalCount
    )}%`;
    let result = `${percentage} (${processedCount} of ${totalCount})`;
    return result;
  };

  buildChartModel() {
    let result = [];
    let processed = this.calcProcessedCount();
    if (processed > 0) {
      let statistics = _.cloneDeep(this.props.statistics);
      delete statistics.notStarted;

      Object.keys(statistics).forEach(function (key) {
        let fieldValue = statistics[key];
        let item = {
          color: fieldValue.color,
          title: composeHoverTitle(fieldValue, processed),
          value: fieldValue.value,
        };
        result.push(item);
      });
    } else {
      let fieldValue = this.props.statistics.notStarted;
      let item = {
        color: fieldValue.color,
        title: composeHoverTitle(
          fieldValue,
          this.props.statistics.notStarted.value
        ),
        value: fieldValue.value,
      };
      result.push(item);
    }

    return result;
  }

  buildStatisticsListModel() {
    let result = [];
    let statistics = this.props.statistics;
    Object.keys(statistics).forEach(function (key) {
      let fieldValue = statistics[key];
      let item = {
        color: fieldValue.color,
        title: fieldValue.title,
        value: fieldValue.value,
      };
      result.push(item);
    });
    return result;
  }

  render() {
    return (
      <div className="chart-block">
        <div className="chart-block-header">
          <span className="text-page semi-bold">{this.props.title}</span>
          <span className="text-page-grey success-rate-text">{`Success rate: ${this.calcSuccessRate()}`}</span>
          <span className="text-page-grey success-rate-text">{`Processed: ${this.calcProcessedRate()}`}</span>
          <div className="chart-block-order">
            <PieChart
              style={{
                ...defaultLabelStyle,
              }}
              data={this.buildChartModel()}
              radius={50 - 6}
              lineWidth={lineWidth}
              label={({ dataEntry }) =>
                composeSegmentTitle(dataEntry.value, this.calcProcessedCount())
              }
              labelPosition={100 - lineWidth / 2}
              labelStyle={{
                ...defaultLabelStyle,
              }}
            />
          </div>
        </div>
        <div className="chart-block-body">
          <div className="info">
            <StatisticList
              className="small-list"
              classValue="text-page"
              statistics={this.buildStatisticsListModel()}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Chart;
