import React, { useState } from "react";
import "./style.scss";
import Close from "../../../assets/image/icons/svg/icons/Close-black.svg";

const HintTooltip = ({
  handlerClose,
  placement,
  classes,
  tooltip,
  enabled,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  return enabled && isOpen ? (
    <div
      className={`hint-tooltip ${placement ?? "top-start"} ${classes ?? ""}`}
    >
      {tooltip.sideTitle ? (
        <div className="hint-tooltip__side-title">{tooltip.sideTitle}</div>
      ) : (
        <div className="hint-tooltip__image"></div>
      )}
      <div className="hint-tooltip__text">
        <span className="hint-tooltip__title">{tooltip.title}</span>
        <p className="hint-tooltip__content">{tooltip.content}</p>
      </div>
      {tooltip.isClosable && (
        <span
          className="hint-tooltip__close"
          onClick={() => {
            setIsOpen(false);
            handlerClose();
          }}
        >
          <img src={Close} alt="close icon" />
        </span>
      )}
      <span className="hint-tooltip__arrow"></span>
    </div>
  ) : (
    <></>
  );
};

export default HintTooltip;
