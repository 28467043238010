import React from "react";

import lock from "../../../assets/image/icons/svg/lock.svg";

// Style
import "./style.scss";
import { CONSTS } from "../../../config/objectConst";

import InformationalTip from "../InformationalTip";
import { pauseReason } from "../../../api/pauseReason";

export const buildStatus = ({
  id,
  pauseReasonStatus,
  status,
  campaignIdWithTip,
  onClick,
}) => {
  switch (status) {
    case "Running":
      return <div className="status-accent active">active</div>;
    case "Stopped":
      return (
        <div className="status-accent paused">
          paused{" "}
          <div className="icon-container">
            {(pauseReasonStatus === pauseReason.exceed.code ||
              pauseReasonStatus === pauseReason.tooLong.code) && (
              <img
                src={lock}
                alt="iconStop"
                className="clock-icon"
                onClick={() => onClick(id)}
              />
            )}
            {campaignIdWithTip === id && (
              <InformationalTip
                centered={"-415px"}
                arrowPosition={"415px"}
                onClose={(value) => () => {}}
                data={
                  CONSTS.TIPS[
                    Object.values(pauseReason).find(
                      (item) => item.code === pauseReasonStatus
                    ).tipId
                  ]
                }
              />
            )}
          </div>
        </div>
      );
    case "Completed":
      return <div className="status-accent completed">completed</div>;
    case "Compose":
      return <div className="status-accent drafted">draft</div>;

    default:
      break;
  }
};
