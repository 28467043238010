import React from "react";
import { Modal } from "semantic-ui-react";
import CloseIcon from "../../../assets/image/icons/svg/close-icon-grey.svg";
import { CircularProgress } from "@material-ui/core";
// Style
import "./style.scss";

const ModalWindow = (props) => {
  return (
    <Modal
      className={`modal-window ${props.classes ?? ""}`}
      open={!!props.open}
      onClose={props.closeHandler}
      closeOnDimmerClick={props.closeOnDimmerClick}
    >
      <div className="modal-wrap">
        <div className="modal-close" onClick={props.closeHandler}>
          <img src={CloseIcon} alt="Close" />
        </div>
        <div className="modal-content">
          {props.children ? (
            props.children
          ) : (
            <>
              <p className="title">{props.title}</p>
              {props.content.split("<br>").map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </>
          )}
        </div>
        {(props.closeText || props.confirmText) && (
          <div className="modal-actions">
            <button className="modal-button alt" onClick={props.closeHandler}>
              {props.closeText}
            </button>
            <button className="modal-button" onClick={props.confirmHandler}>
              {!props.loading ? (
                props.confirmText
              ) : (
                <CircularProgress
                  size={20}
                  classes={{ colorPrimary: "material-loader-accent" }}
                />
              )}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ModalWindow;
