import React from "react";
import "./style.scss";

import dangerIcon from "../../../assets/image/icons/svg/icons/dangerIconRed.svg";

import noPng from "../../../assets/image/snail-no.png";

const ExceededLimitNotification = ({ children }) => {
  return (
    <div className="working-hours-notification">
      <img
        src={noPng}
        alt="exceeded limit icon"
        className="working-hours-notification__png"
        width={40}
      />
      <img
        src={dangerIcon}
        alt="exceeded limit icon"
        className="working-hours-notification__icon"
      />
      {children}
    </div>
  );
};

export default ExceededLimitNotification;
