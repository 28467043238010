import React, { Component } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";
import { TextField, InputAdornment } from "@material-ui/core";
import DateIcon from "../../../assets/image/icons/svg/date-icon-yellow.svg";
import ArrowLeftIcon from "../../../assets/image/icons/svg/calendar/arrow-left-light-grey.svg";
import ArrowRightIcon from "../../../assets/image/icons/svg/calendar/arrow-right-light-grey.svg";
import DoubleArrowLeftIcon from "../../../assets/image/icons/svg/calendar/double-arrow-left-grey.svg";
import DoubleArrowRightIcon from "../../../assets/image/icons/svg/calendar/double-arrow-right-grey.svg";
import { functions } from "../../../tools/functions";

export class ReactDatePicker extends Component {
  static propTypes = {
    value: PropTypes.any,
    selectsStart: PropTypes.bool,
    selectsEnd: PropTypes.bool,
    startDate: PropTypes.any,
    endDate: PropTypes.any,
    maxDate: PropTypes.any,
    minDate: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    popperPlacement: PropTypes.string,
  };
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const uniqueId = Math.floor(Math.random() * 1000);
    return (
      <div className="datepicker-wrap">
        {this.props.label && (
          <label
            htmlFor={`datePicker-${uniqueId}`}
            className="select-label text-field__label"
          >
            {this.props.label}
          </label>
        )}
        <DatePicker
          showPopperArrow={false}
          todayButton="Today"
          placeholderText={this.props.placeholder}
          selected={this.props.value}
          selectsStart={this.props.selectsStart}
          selectsEnd={this.props.selectsEnd}
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          minDate={this.props.minDate}
          maxDate={this.props.maxDate}
          onChange={this.props.onChange}
          className="datepicker"
          calendarClassName="datepicker__calendar"
          dateFormat="MM-dd-yyyy"
          popperPlacement={this.props.popperPlacement}
          customInput={
            <TextField
              className="datepicker__input"
              variant="outlined"
              margin="none"
              padding="none"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img
                      src={DateIcon}
                      className={"datepicker__icon"}
                      alt="dropdownicon"
                    />
                  </InputAdornment>
                ),
              }}
            />
          }
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            decreaseYear,
            increaseYear,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
            prevYearButtonDisabled,
            nextYearButtonDisabled,
          }) => (
            <div className={"custom-header-content"}>
              <div className="arrow-group">
                <button
                  className={"arrows"}
                  onClick={decreaseYear}
                  disabled={prevYearButtonDisabled}
                >
                  <img
                    src={DoubleArrowLeftIcon}
                    className={"calendar__year"}
                    alt="dropdownicon"
                  />
                </button>
                <button
                  className={"arrows"}
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  <img
                    src={ArrowLeftIcon}
                    className={"calendar__month"}
                    alt="dropdownicon"
                  />
                </button>
              </div>
              <div className="current-date">
                {functions.getMonth(date.getMonth())} {date.getFullYear()}
              </div>
              <div className="arrow-group">
                <button
                  className={"arrows"}
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  <img
                    src={ArrowRightIcon}
                    className={"calendar__month"}
                    alt="dropdownicon"
                  />
                </button>
                <button
                  className={"arrows"}
                  onClick={increaseYear}
                  disabled={nextYearButtonDisabled}
                >
                  <img
                    src={DoubleArrowRightIcon}
                    className={"calendar__year"}
                    alt="dropdownicon"
                  />
                </button>
              </div>
            </div>
          )}
        />
      </div>
    );
  }
}

export default ReactDatePicker;
