import React, { useEffect, useState } from "react";
import "./style.scss";
import { shallowEqual, useSelector } from "react-redux";
import _ from "lodash";
import { contacts } from "../../../actions/contacts";
import { useHistory } from "react-router-dom";
import { filters } from "../../../actions/filters";
import { api } from "../../../api/api";
import jschardet from "jschardet";
import dogWithBone from "./../../../assets/image/snail-verified.png";
import diggingDog from "./../../../assets/image/snail-looking-for.png";
import dogThinks from "./../../../assets/image/snail-typing.png";
import checkMark from "../../../assets/image/icons/svg/icons/check-mark.svg";
import NotAuthorizedError from "../../../errors/NotAuthorizedError";
import BadGatewayError from "../../../errors/BadGatewayError";
import { saveData } from "../../../tools/functions";
import { notification } from "../../../actions/notification";
import iconUploadContacts from "../../../assets/image/icons/svg/uploadFullContacts.svg";
import iconUploadProfile from "../../../assets/image/icons/svg/uploadProfile.svg";
import iconUploadSearch from "../../../assets/image/icons/svg/uploadSearch.svg";
import iconUploadSearchActive from "../../../assets/image/icons/svg/uploadSearchActive.svg";
import iconUploadContactsActive from "../../../assets/image/icons/svg/uploadFullContactsActive.svg";
import iconUploadProfileActive from "../../../assets/image/icons/svg/uploadProfileActive.svg";
import { CONSTS } from "../../../config/objectConst.js";
import { exportTabs } from "./constants";
import ExportTab from "../ExportTab";
import ExportContent from "./ExportContent";
import ExportContentSearch from "./ExportContentSearch";

const CsvExport = ({ closeExportHandler, isUpdateContacts }) => {
  let history = useHistory();
  const { filterData } = useSelector((state) => state.filters, shallowEqual);
  const [file, setFile] = useState("");
  const [fullFile, setFullFile] = useState([]);
  const [linkedInContacts, setLinkedInContacts] = useState([]);
  const { connector } = useSelector((state) => state.app);

  const [readLoader, setReadLoader] = useState(false);
  const [readError, setReadError] = useState("");
  const [startUploading, setStartUploading] = useState(false);
  const [exportStartingLoader, setExportStartingLoader] = useState(false);
  const [uploadingProgress, setUploadingProgress] = useState(0);
  const [finishedUploading, setFinishedUploading] = useState({});
  const [errorUploadingData, setErrorUploadingData] = useState({});
  const [activeTabsProfile, setActiveTabsProfile] = useState(
    exportTabs.SEARCH_FROM_LINKEDIN
  );

  const handleFileFullRead = async (content) => {
    try {
      const response =
        await connector.profilesExport.extractFullLinkedInContacts(content);

      if (response?.length) {
        setReadError("");
        setLinkedInContacts(response);
      } else {
        setReadError(
          "There are no LinkedIn profiles. Please, try another file."
        );
      }
    } catch (e) {
      setReadError(e.message);
    } finally {
      setReadLoader(false);
    }
  };

  const handleReaderError = () => {
    setReadLoader(false);
    setReadError("There was a problem uploading your file. Please try again.");
  };

  useEffect(() => {
    (async () => {
      await readFileContent(fullFile, handleFileFullRead);
    })();
  }, [fullFile]);

  const readFileContent = async function (targetFile, onRead) {
    if (targetFile.length) {
      const getExportState = await connector.profilesExport.getState();
      if (getExportState) {
        if (
          getExportState.phase === "completed" ||
          getExportState.phase === "failed"
        ) {
          await connector.profilesExport.clear();
        } else {
          return setReadError("Finish the started export");
        }
      }
      setReadLoader(true);

      const file = _.cloneDeep(targetFile);
      const fileEncodingReader = new FileReader();
      fileEncodingReader.readAsBinaryString(file[0]);
      await new Promise((resolve, reject) => {
        fileEncodingReader.onload = resolve;
        fileEncodingReader.onerror = reject;
      });

      const fileReader = new FileReader();
      fileReader.onload = () => onRead(fileReader.result);
      fileReader.onerror = handleReaderError;
      const encoding = jschardet.detect(fileEncodingReader.result).encoding;
      fileReader.readAsText(targetFile[0], encoding);
    }
  };

  const exportCsvExample = async () => {
    try {
      const response = await api.exportCsvExampleApi();
      if (response.status === 200) {
        const data = response.data,
          fileName = `${CONSTS.PROJECT_NAME}_Template.csv`;
        saveData(data, fileName);
      }
    } catch (error) {
      notification.notificationUse(error);
    }
  };

  const callback = async (event) => {
    const exportState = await connector.profilesExport.getState();
    if (
      exportState.type === "CsvContactsListExport" ||
      // obsolete, it should be deleted after 1 July 2023
      exportState.type === "ContactsPublicIdentifierListExport"
    ) {
      switch (event) {
        case "ExportStartingHandler":
          setExportStartingLoader(true);
          break;
        case "ExportStartedHandler":
          setExportStartingLoader(false);
          setStartUploading(true);
          break;
        case "ExportChangedHandler":
          setUploadingProgress(exportState.exported);
          break;
        case "ExportFailedHandler":
          let exportError;
          switch (exportState.exportError.name) {
            case "NotAuthorizedError":
              exportError = new NotAuthorizedError("Not authorized");
              break;

            case "BadGatewayError":
              exportError = new BadGatewayError("Bad gateway");
              break;

            default:
              exportError = new Error("Export is failed");
          }

          notification.notificationUse(exportError);
          setErrorUploadingData(exportState);
          history.location.pathname.includes("/contacts") &&
            contacts.getRecentImports();
          break;
        case "ExportCompletedHandler":
          setFinishedUploading(exportState);
          history.location.pathname.includes("/contacts") &&
            contacts.getRecentImports();
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    connector.profilesExport.addExportStartingHandler(() =>
      callback("ExportStartingHandler")
    );
    connector.profilesExport.addExportStartedHandler(() => {
      callback("ExportStartedHandler");
    });
    connector.profilesExport.addExportChangedHandler((e) => {
      callback("ExportChangedHandler");
    });
    connector.profilesExport.addExportFailedHandler(() =>
      callback("ExportFailedHandler")
    );
    connector.profilesExport.addExportFailedHandler(() =>
      callback("ExportFailedHandler")
    );
    connector.profilesExport.addExportCompletedHandler(() =>
      callback("ExportCompletedHandler")
    );

    return () => {
      connector.profilesExport.removedAllHandlers();
    };
  }, []);

  const showExportImages = () => {
    if (_.isEmpty(errorUploadingData)) {
      if (startUploading && _.isEmpty(finishedUploading)) {
        return (
          <div className="uploading-progress-image">
            <img src={diggingDog} alt="snail" width={110} />
          </div>
        );
      }
      if (
        linkedInContacts.length &&
        _.isEmpty(finishedUploading) &&
        activeTabsProfile === exportTabs.UPLOAD_FILE
      ) {
        return (
          <div className="found-contacts-image">
            <img src={dogWithBone} alt="snail" width={110} />
          </div>
        );
      }
    }
    if (!_.isEmpty(errorUploadingData)) {
      return (
        <div
          className={`uploading-data-error-image ${
            !errorUploadingData.navigationUrl ? "error-image-small" : ""
          }`}
        >
          <img src={dogThinks} alt="snail" width={190} />
        </div>
      );
    }
    return "";
  };

  const startUploadingHandler = async (contacts) => {
    try {
      await connector.profilesExport.start(contacts);
    } catch (error) {
      notification.notificationUse(error);
    }
  };

  const stopUploadingHandler = async () => {
    await connector.profilesExport.stop();
  };

  const createExportHeadline = () => {
    if (!_.isEmpty(errorUploadingData) && _.isEmpty(finishedUploading)) {
      return "Upload is unexpectable stopped";
    }
    if (!_.isEmpty(finishedUploading)) {
      return "Upload is completed";
    }
    return "LinkedIn URLs";
  };

  const showResultHandler = () => {
    connector.profilesExport.clear();
    closeExportHandler();
    history.push(
      `/contacts/import/${finishedUploading.navigationUrl.split("/").pop()}`
    );
  };

  const closeHandler = () => {
    closeExportHandler();
    connector.profilesExport.clear();
    if (isUpdateContacts) {
      filters.getContacts(filterData, "", "contacts", 0);
    }
  };

  const refreshNickNames = () => {
    connector.profilesExport.clear();
    setFullFile([]);
    setLinkedInContacts([]);
  };

  return (
    <div className="csv-export">
      {!_.isEmpty(finishedUploading) && (
        <>
          <div className="csv-export__finish-icon">
            <img src={checkMark} alt="complete icon" />
          </div>
          <div className="title-upload-export">{createExportHeadline()}</div>
        </>
      )}
      <div className="top-container">
        {showExportImages() && _.isEmpty(finishedUploading) ? (
          <div className="title-csv-export">Upload Linkedin Profiles</div>
        ) : (
          <>
            {_.isEmpty(finishedUploading) && (
              <div className="title-csv-export">
                Select the method to export LinkedIn profiles
              </div>
            )}
          </>
        )}
        {((!showExportImages() && _.isEmpty(finishedUploading)) ||
          (activeTabsProfile === exportTabs.PASTE_PROFILE_URLS &&
            _.isEmpty(finishedUploading) &&
            !startUploading)) && (
          <div className="wrapper-tabs">
            <ExportTab
              activeTabsProfile={activeTabsProfile}
              setActiveTabsProfile={setActiveTabsProfile}
              setFullFile={setFullFile}
              setReadError={setReadError}
              createExportHeadline={createExportHeadline}
              tab={exportTabs.SEARCH_FROM_LINKEDIN}
              text="Search on LinkedIn"
              icon={iconUploadSearch}
              iconActive={iconUploadSearchActive}
            />
            <ExportTab
              activeTabsProfile={activeTabsProfile}
              setActiveTabsProfile={setActiveTabsProfile}
              setFullFile={setFullFile}
              setReadError={setReadError}
              createExportHeadline={createExportHeadline}
              tab={exportTabs.PASTE_PROFILE_URLS}
              text={createExportHeadline()}
              icon={iconUploadProfile}
              iconActive={iconUploadProfileActive}
            />
            <ExportTab
              activeTabsProfile={activeTabsProfile}
              setActiveTabsProfile={setActiveTabsProfile}
              setFullFile={setFullFile}
              setReadError={setReadError}
              createExportHeadline={createExportHeadline}
              tab={exportTabs.UPLOAD_FILE}
              text="Customized Contacts"
              icon={iconUploadContacts}
              iconActive={iconUploadContactsActive}
            />
          </div>
        )}
      </div>
      {activeTabsProfile === exportTabs.SEARCH_FROM_LINKEDIN && (
        <ExportContentSearch />
      )}
      {activeTabsProfile === exportTabs.PASTE_PROFILE_URLS && (
        <ExportContent
          file={file}
          setFile={setFile}
          readError={readError}
          showExportImages={showExportImages}
          errorUploadingData={errorUploadingData}
          linkedInContacts={linkedInContacts}
          readLoader={readLoader}
          startUploading={startUploading}
          exportStartingLoader={exportStartingLoader}
          refreshNickNames={refreshNickNames}
          uploadingProgress={uploadingProgress}
          stopUploadingHandler={stopUploadingHandler}
          startUploadingHandler={startUploadingHandler}
          finishedUploading={finishedUploading}
          showResultHandler={showResultHandler}
          closeHandler={closeHandler}
          tab={exportTabs.PASTE_PROFILE_URLS}
          setReadLoader={setReadLoader}
          setReadError={setReadError}
          setLinkedInContacts={setLinkedInContacts}
        />
      )}
      {activeTabsProfile === exportTabs.UPLOAD_FILE && (
        <ExportContent
          readError={readError}
          showExportImages={showExportImages}
          errorUploadingData={errorUploadingData}
          linkedInContacts={linkedInContacts}
          readLoader={readLoader}
          startUploading={startUploading}
          exportStartingLoader={exportStartingLoader}
          refreshNickNames={refreshNickNames}
          uploadingProgress={uploadingProgress}
          stopUploadingHandler={stopUploadingHandler}
          startUploadingHandler={startUploadingHandler}
          finishedUploading={finishedUploading}
          showResultHandler={showResultHandler}
          closeHandler={closeHandler}
          tab={exportTabs.UPLOAD_FILE}
          exportCsvExample={exportCsvExample}
          setFullFile={setFullFile}
        />
      )}
    </div>
  );
};

export default CsvExport;
