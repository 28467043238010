import { CONSTS } from "../config/objectConst";

export const contactsPageSize = {
  get() {
    let result = JSON.parse(
      localStorage.getItem("contactsPageSize") || CONSTS.PAGE_SIZE
    );
    return result;
  },
};
