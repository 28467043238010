import React from "react";
import sprite from "../../../assets/image/icons/svg/icons/sprite.svg";

const Icon = ({ name, size = 24, className, onClick, style }) => (
  <svg
    width={size}
    height={size}
    className={className}
    viewBox="0 0 20 20"
    onClick={onClick}
    style={style}
  >
    <use href={`${sprite}#${name}`} />
  </svg>
);

export default Icon;
