import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
// Configs
import { CONSTS } from "../../../config/objectConst";
// Images, and Icons
import happySnailyImage from "../../../assets/image/snail-subscription.png";
import unhappySnailyImage from "../../../assets/image/snail-no.png";
// Style
import "./style.scss";

export class TrialNotification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowExportModal: false,
    };
  }

  isImpersonatedSignedIn = () => {
    let result = !!this.state.localAppProfile?.impersonatedPersona;
    return result;
  };

  isActiveTrial = () => {
    const result =
      this.props.subscription?.plan_id === CONSTS.SUBSCRIPTIONS.TRIAL.ID &&
      this.props.subscription?.is_active &&
      !this.isImpersonatedSignedIn();
    return result;
  };

  isExpiredTrial = () => {
    const result =
      this.props.subscription?.plan_id === CONSTS.SUBSCRIPTIONS.TRIAL.ID &&
      !this.props.subscription?.is_active &&
      !this.isImpersonatedSignedIn();
    return result;
  };

  isActiveSubscription = () => {
    const result =
      this.props.subscription?.plan_id !== CONSTS.SUBSCRIPTIONS.TRIAL.ID &&
      this.props.subscription?.is_active &&
      !this.isImpersonatedSignedIn();
    return result;
  };

  isInactiveSubscription = () => {
    const result =
      this.props.subscription?.plan_id !== CONSTS.SUBSCRIPTIONS.TRIAL.ID &&
      !this.props.subscription?.is_active &&
      !this.isImpersonatedSignedIn();
    return result;
  };

  getExpireIn = () => {
    let diffTime =
      Date.parse(this.props.subscription.next_charge_date) -
      Date.parse(new Date().toUTCString());
    let hours = Math.ceil(diffTime / (1000 * 60 * 60));
    if (hours === 1) {
      return "1 hour";
    }
    if (hours < 24) {
      return `${hours} hours`;
    }

    let days = Math.ceil(hours / 24);
    if (days === 1) {
      return "1 day";
    } else {
      return `${days} days`;
    }
  };

  generateTitle = () => {
    if (this.isActiveTrial()) {
      return `Trial will expire in ${this.getExpireIn()}`;
    }
    if (this.isExpiredTrial()) {
      return "Trial period is expired";
    }
    if (this.isActiveSubscription()) {
      return "Subscription is active";
    }
    if (this.isInactiveSubscription()) {
      return "Subscription is inactive";
    }
  };

  isSubscriptionExpired = () => {
    if (this.isExpiredTrial() || this.isInactiveSubscription()) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    return this.props.subscription?.plan_id ? (
      <div>
        {
          <div
            className={
              this.isSubscriptionExpired()
                ? "left-menu__container-subscription-accent"
                : "left-menu__container-subscription"
            }
          >
            <p
              className={
                this.isSubscriptionExpired()
                  ? "left-menu__container-subscription-accent-title"
                  : "left-menu__container-subscription-title"
              }
            >
              {this.generateTitle()}
            </p>

            <Link
              to={"/upgrade"}
              className={
                this.isSubscriptionExpired()
                  ? "left-menu__btn_with-bg-main_accent"
                  : "left-menu__btn_with-bg-main"
              }
            >
              {"Upgrade subscription"}
            </Link>
            <img
              src={
                this.isSubscriptionExpired()
                  ? unhappySnailyImage
                  : happySnailyImage
              }
              alt="snail"
              width={42}
              className={
                this.isSubscriptionExpired()
                  ? "left-menu__img-subscription-accent"
                  : "left-menu__img-subscription"
              }
            />
          </div>
        }
      </div>
    ) : (
      <></>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    leftSide: state.leftSideMenu.leftSide,
    active: state.leftSideMenu.active,
    pageContentLoader: state.app.pageContentLoader,
    campaign: state.campaign,
    appPage: state.app.appPage,
    appProfile: state.app.appProfile,
    subscription: state.app.subscription,
  };
};

export default connect(mapStateToProps)(withRouter(TrialNotification));
