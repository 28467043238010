import React from "react";
// components
import AuthTemplate from "../../templates/authTemplate";
// configs
import { CONSTS } from "../../../config/objectConst";
// img
import unInstall from "../../../assets/image/auth-steps/unInstall.png";

export const NoInstallContainer = () => {
  return (
    <AuthTemplate active={1}>
      <h2>Install {CONSTS.PROJECT_NAME} Chrome Extension</h2>
      <img src={unInstall} alt="unAuth" width={250} />
      <p className="text-page-auth-modal-desc">
        To perfom all actions on LinkedIn, You have to install <br />
        {CONSTS.PROJECT_NAME} Chrome Extension
      </p>
      <div className="modal-create-linked__btn-container">
        <button
          onClick={() => {
            window.open(CONSTS.EXTENSION_INSTALL_URL, "_blank");
          }}
        >
          Install
        </button>
      </div>
    </AuthTemplate>
  );
};

export default NoInstallContainer;
