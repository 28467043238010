import { CONSTS } from "../../../config/objectConst";
import { statesDescriptor } from "../../../api/statesDescriptor";
import { stepExecutionState } from "../../../api/stepExecutionState";
import { executionHumanState } from "../../../api/executionHumanState";

function personalizeCampaignStatistics(statistics) {
  let result = {
    notStarted: {
      color: statesDescriptor.detectColor(executionHumanState.NotStarted),
      title: statesDescriptor.getExecutionTitle(executionHumanState.NotStarted),
      value: statistics.not_started ? statistics.not_started : 0,
    },
    inProgress: {
      color: statesDescriptor.detectColor(executionHumanState.InProgress),
      title: statesDescriptor.getExecutionTitle(executionHumanState.InProgress),
      value: statistics.in_progress ? statistics.in_progress : 0,
    },
    succeed: {
      color: statesDescriptor.detectColor(executionHumanState.Succeed),
      title: statesDescriptor.getExecutionTitle(executionHumanState.Succeed),
      value: statistics.succeed ? statistics.succeed : 0,
    },
    ignored: {
      color: statesDescriptor.detectColor(executionHumanState.Ignored),
      title: statesDescriptor.getExecutionTitle(executionHumanState.Ignored),
      value: statistics.ignored ? statistics.ignored : 0,
    },
    cancelled: {
      color: statesDescriptor.detectColor(executionHumanState.Cancelled),
      title: statesDescriptor.getExecutionTitle(executionHumanState.Cancelled),
      value: statistics.cancelled ? statistics.cancelled : 0,
    },
    skipped: {
      color: statesDescriptor.detectColor(executionHumanState.Skipped),
      title: statesDescriptor.getExecutionTitle(executionHumanState.Skipped),
      value: statistics.skipped ? statistics.skipped : 0,
    },
    failed: {
      color: statesDescriptor.detectColor(executionHumanState.Failed),
      title: statesDescriptor.getExecutionTitle(executionHumanState.Failed),
      value: statistics.failed ? statistics.failed : 0,
    },
  };
  return result;
}

function personalizeActionStatistics(action) {
  let statistics = action.statistics;
  let result = {
    notStarted: {
      color: CONSTS.COLORS_CAMPAIGNS.inQueue,
      title: statesDescriptor.getStepTitle(
        stepExecutionState.NotStarted,
        action.type
      ),
      value: action.statistics.not_started ? statistics.not_started : 0,
    },
    inProgress: {
      color: CONSTS.COLORS_CAMPAIGNS.inProgress,
      title: statesDescriptor.getStepTitle(
        stepExecutionState.InProgress,
        action.type
      ),
      value: statistics.in_progress ? statistics.in_progress : 0,
    },
    succeed: {
      color: CONSTS.COLORS_CAMPAIGNS.responded,
      title: statesDescriptor.getStepTitle(
        stepExecutionState.Succeed,
        action.type
      ),
      value: statistics.succeed ? statistics.succeed : 0,
    },
    ignored: {
      color: CONSTS.COLORS_CAMPAIGNS.ignored,
      title: statesDescriptor.getStepTitle(
        stepExecutionState.Ignored,
        action.type
      ),
      value: statistics.ignored ? statistics.ignored : 0,
    },
    cancelled: {
      color: CONSTS.COLORS_CAMPAIGNS.cancelled,
      title: statesDescriptor.getStepTitle(
        stepExecutionState.Cancelled,
        action.type
      ),
      value: statistics.cancelled ? statistics.cancelled : 0,
    },
    skipped: {
      color: CONSTS.COLORS_CAMPAIGNS.skipped,
      title: statesDescriptor.getStepTitle(
        stepExecutionState.Skipped,
        action.type
      ),
      value: statistics.skipped ? statistics.skipped : 0,
    },
    failed: {
      color: CONSTS.COLORS_CAMPAIGNS.failed,
      title: statesDescriptor.getStepTitle(
        stepExecutionState.Failed,
        action.type
      ),
      value: statistics.failed ? statistics.failed : 0,
    },
  };
  return result;
}

export const chartModel = {
  personalizeCampaignStatistics: personalizeCampaignStatistics,
  personalizeActionStatistics: personalizeActionStatistics,
};
