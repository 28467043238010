const initialState = {
  focusedConversations: {},
  spamConversations: {},
  archiveConversations: {},
  inMailConversations: {},
  myConnectionsConversations: {},
  otherConversations: {},
  searchConversations: {},
  starredConversations: {},
  unreadConversations: {},
  primaryConversations: {},
  recentEvents: {},
  chosenMailboxId: {},
  mailboxParticipants: null,
  bundledEvents: {},
  chosenMailbox: {},
  attachmentUrls: {},
  eventsNextCursor: null,
};

const mailbox = (state = initialState, action) => {
  switch (action.type) {
    case "CONVERSATIONS_INMAIL":
      const { nextCursor, conversations } = action.payload;
      return Object.assign({}, state, {
        inMailConversations: { conversations, nextCursor },
      });
      break;
  }
  switch (action.type) {
    case "CONVERSATIONS_FOCUSED":
      const { nextCursor, conversations } = action.payload;
      return Object.assign({}, state, {
        focusedConversations: { conversations, nextCursor },
      });
      break;
  }
  switch (action.type) {
    case "CONVERSATIONS_OTHER":
      const { nextCursor, conversations } = action.payload;
      return Object.assign({}, state, {
        otherConversations: { conversations, nextCursor },
      });
      break;
  }
  switch (action.type) {
    case "CONVERSATIONS_ARCHIVE":
      const { nextCursor, conversations } = action.payload;
      return Object.assign({}, state, {
        archiveConversations: { conversations, nextCursor },
      });
      break;
  }
  switch (action.type) {
    case "CONVERSATIONS_UNREAD":
      const { nextCursor, conversations } = action.payload;
      return Object.assign({}, state, {
        unreadConversations: { conversations, nextCursor },
      });
      break;
  }
  switch (action.type) {
    case "CONVERSATIONS_STARRED":
      const { nextCursor, conversations } = action.payload;
      return Object.assign({}, state, {
        starredConversations: { conversations, nextCursor },
      });
      break;
  }
  switch (action.type) {
    case "CONVERSATIONS_MY_CONNECTIONS":
      const { nextCursor, conversations } = action.payload;
      return Object.assign({}, state, {
        myConnectionsConversations: { conversations, nextCursor },
      });
      break;
  }
  switch (action.type) {
    case "CONVERSATIONS_SPAM":
      const { nextCursor, conversations } = action.payload;
      return Object.assign({}, state, {
        spamConversations: { conversations, nextCursor },
      });
      break;
  }
  switch (action.type) {
    case "CONVERSATIONS_SEARCH":
      const { nextCursor, conversations } = action.payload;
      return Object.assign({}, state, {
        searchConversations: { conversations, nextCursor },
      });
      break;
  }
  switch (action.type) {
    case "CONVERSATIONS_PRIMARY":
      const { nextCursor, conversations } = action.payload;
      return Object.assign({}, state, {
        primaryConversations: { conversations, nextCursor },
      });
      break;
  }
  switch (action.type) {
    case "RECENT_EVENTS":
      const { id, events, eventsNextCursor } = action.payload;
      return Object.assign({}, state, {
        recentEvents: {
          ...state.recentEvents,
          [id]: { events, eventsNextCursor },
        },
      });
      break;
  }
  switch (action.type) {
    case "NEXT_EVENTS":
      const { id, events, eventsNextCursor } = action.payload;
      return Object.assign({}, state, {
        recentEvents: {
          ...state.recentEvents,
          [id]: { events, eventsNextCursor },
        },
      });
      break;
  }
  switch (action.type) {
    case "BUNDLED_EVENTS":
      return Object.assign({}, state, {
        bundledEvents: action.payload,
      });
      break;
  }
  switch (action.type) {
    case "ATTACHMENT_URLS":
      const { id, url } = action.payload;
      return Object.assign({}, state, {
        attachmentUrls: {
          ...state.attachmentUrls,
          [id]: { url },
        },
      });
      break;
  }
  switch (action.type) {
    case "CHOSEN_MAILBOX_ID":
      return Object.assign({}, state, {
        chosenMailboxId: action.payload,
      });
      break;
  }
  switch (action.type) {
    case "MAILBOX_PARTICIPANTS":
      return Object.assign({}, state, {
        mailboxParticipants: action.payload,
      });
      break;
    default:
      return state;
  }
};

export default mailbox;
