import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { app } from "../../../actions/app";
import { connectorAPI } from "../../../actions/connector";
import { notification } from "../../../actions/notification";
import { CONSTS } from "../../../config/objectConst";
// components
import AuthTemplate from "../../templates/authTemplate";
import Select from "react-select";
import ResponsiveButton from "../../atoms/responsiveButton";

export const NoTimeZoneContainer = ({ connector }) => {
  const [zone, setZone] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const key = new Date().getTimezoneOffset() * -1;
    setZone(CONSTS.TIME_ZONES.find((item) => item.value === key.toString()));
  }, []);
  const saveTimeZone = async () => {
    setLoading(true);
    try {
      const response = await app.setNewData("workspace/timezone-offset", {
        timezone_offset: Number(zone.value),
      });
      if (response.status === 200) {
        await connector.auth.refreshAccessToken();
        await connectorAPI.getSnailyProfile();
      }
    } catch (e) {
      notification.notificationUse(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthTemplate active={4}>
      <h2>
        Choose your GMT and <br />
        working hours
      </h2>
      <p className="auth-modal-description">
        You can reset this step in personal account later
      </p>
      <Select
        className="simple-select"
        classNamePrefix="select"
        options={CONSTS.TIME_ZONES}
        onChange={(newValue) => setZone(newValue)}
        value={zone}
      />
      <div className="modal-create-linked__btn-container">
        <ResponsiveButton
          className="btn bold"
          onClick={saveTimeZone}
          isLoading={loading}
        >
          {"Next"}
        </ResponsiveButton>
      </div>
    </AuthTemplate>
  );
};

const mapStateToProps = function (state) {
  return {
    connector: state.app.connector,
  };
};

export default connect(mapStateToProps)(NoTimeZoneContainer);
