import React, { Component } from "react";
// Components
import { notification } from "../../../actions/notification";
// Icons
import arrow from "../../../assets/image/icons/svg/icons/arrow.svg";
import arrowd from "../../../assets/image/icons/svg/icons/arrow-double.svg";
import yarrow from "../../../assets/image/icons/svg/icons/yellow-arrow.svg";
import yarrowd from "../../../assets/image/icons/svg/icons/yellow-arrow-double.svg";
// Style
import "./style.scss";

export class MTablePagination extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageTotal: Math.ceil(this.props.count / this.props.rowsPerPage),
      stepBack: this.props.page !== 0 ? this.props.page - 1 : 0,
      stepPush:
        this.props.page !==
        Math.ceil(this.props.count / this.props.rowsPerPage) - 1
          ? this.props.page + 1
          : Math.ceil(this.props.count / this.props.rowsPerPage) - 1,
      startPosition: 1,
      lastPositon: this.props.rowsPerPage,
    };
  }

  setPaginationInform = () => {
    const startPosition = this.props.page * this.props.rowsPerPage + 1;
    const lastPositon = (this.props.page + 1) * this.props.rowsPerPage;
    return `${startPosition}-${
      lastPositon > this.props.count ? this.props.count : lastPositon
    } of ${this.props.count}`;
  };

  onPageChange = (e, value) => {
    try {
      this.props.onPageChange(e, value);
    } catch (error) {
      notification.notificationUse(error);
    }
  };

  render() {
    // console.log("ping");
    return (
      <div className="custom-pagintion">
        <button
          disabled={!this.props.page}
          onClick={(e) => this.onPageChange(e, 0)}
        >
          <img
            className={this.props.page > 0 ? "rotate" : ""}
            src={this.props.page > 0 ? yarrowd : arrowd}
            alt="arrow"
          />
        </button>
        <button
          disabled={!this.props.page}
          onClick={(e) => this.onPageChange(e, this.state.stepBack)}
        >
          <img
            className={this.props.page > 0 ? "rotate" : ""}
            src={this.props.page > 0 ? yarrow : arrow}
            alt="arrow"
          />
        </button>
        <div className="information">{this.setPaginationInform()}</div>
        <button
          disabled={this.props.page + 1 === this.state.pageTotal}
          onClick={(e) => this.onPageChange(e, this.state.stepPush)}
        >
          <img
            className={
              this.props.page + 1 < this.state.pageTotal ? "" : "rotate"
            }
            src={this.props.page + 1 < this.state.pageTotal ? yarrow : arrow}
            alt="arrow"
          />
        </button>
        <button
          disabled={this.props.page + 1 === this.state.pageTotal}
          onClick={(e) => this.onPageChange(e, this.state.pageTotal - 1)}
        >
          <img
            className={
              this.props.page + 1 < this.state.pageTotal ? "" : "rotate"
            }
            src={this.props.page + 1 < this.state.pageTotal ? yarrowd : arrowd}
            alt="arrow"
          />
        </button>
      </div>
    );
  }
}

export default MTablePagination;
