export const pauseReason = {
  exceed: {
    code: "ExceedFreeMonthlyPersonalizedInvitations",
    tipId: "n4.1",
  },
  tooLong: {
    code: "TooLongInvitationMessage",
    tipId: "n4.2",
  },
};
