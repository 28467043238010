import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
// components
import OutsideClickHandler from "react-outside-click-handler";
// Style
import "./style.scss";
import DropDownMenu from "./dropDownMenu";
export class UserProfilePersona extends Component {
  constructor(props) {
    super(props);
    // noinspection JSAnnotator
    this.state = {
      open: false,
      name: `${
        this.getCurrentAppProfile().givenName
          ? this.getCurrentAppProfile().givenName
          : ""
      } ${
        this.getCurrentAppProfile().familyName
          ? this.getCurrentAppProfile().familyName
          : ""
      }`,
    };
  }

  getCurrentAppProfile = () => {
    let result = this.props.appProfile.impersonatedPersona
      ? this.props.appProfile.impersonatedPersona
      : this.props.appProfile;
    return result;
  };

  handleOpenMenu = async () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    return (
      <>
        <OutsideClickHandler
          onOutsideClick={() => this.setState({ open: false })}
        >
          <div className="user-profile-wrapper">
            <div className={"user-profile"} onClick={this.handleOpenMenu}>
              <span className="user-profile__name">{this.state.name}</span>
              <span className="user-profile__email-accent">
                {this.getCurrentAppProfile().email}
              </span>
            </div>

            {this.state.open && (
              <DropDownMenu
                timezoneOffset={this.props.profile.appProfile.timezoneOffset}
                profile={this.props.profile}
                impersonatedPersona={this.props.appProfile?.impersonatedPersona}
                openMenuHandler={(value) => this.setState({ open: value })}
                connector={this.props.connector}
              />
            )}
          </div>
        </OutsideClickHandler>
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    user: state.user.profile,
    profile: state.user.profileFull,
    connector: state.app.connector,
    appProfile: state.app.appProfile,
  };
};

export default connect(mapStateToProps)(withRouter(UserProfilePersona));
