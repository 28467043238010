import React, { useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import snail from "../../../assets/image/snail-catches.png";

const SnackNotificationAttention = ({ message, actionClose }) => {
  const text = useRef(message.content);
  return (
    <div className="snackbar-notification-attention__message">
      <img
        className="snackbar-notification-attention__icon"
        alt="snail"
        src={snail}
        width={120}
        height={80}
      />
      <div className="snackbar-notification-attention__body">
        <div className="snackbar-notification-attention__title">
          <span>{message.title}</span>
          <div
            className="snackbar-notification-attention__icon-close"
            onClick={actionClose}
          />
        </div>
        <div className="snackbar-notification-attention__text">
          {text.current}
        </div>
        {message.button && (
          <div className="notification-button-attention">
            <a
              href={message.button.link}
              className="btn-attention"
              target="_blank"
              rel="noopener noreferrer"
            >
              {message.button.title}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    snacknotification: state.notification.message,
  };
};

export default connect(mapStateToProps)(withRouter(SnackNotificationAttention));
