import React, { Component } from "react";
import imageDog from "../../../assets/image/snail-looking-for.png";
import "./style.scss";
export class NoSearchContacts extends Component {
  render() {
    return (
      <div className="no-filter-template">
        <div className="no-filter-template-body">
          <span className="h-2">Ooops… Nothing was found!</span>
          <img src={imageDog} alt="snail" />
          <p className="h-5 additional-text">
            Please, change filters or search query to get results
          </p>
        </div>
      </div>
    );
  }
}

export default NoSearchContacts;
