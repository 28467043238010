import React, { useEffect } from "react";
import { HomeTemplate } from "../../components/templates/homeTemplate";
import { app } from "../../actions/app";
import Account from "../../components/organisms/Account";
import { useHistory } from "react-router";

const AccountPage = () => {
  const history = useHistory();
  useEffect(() => {
    app.changePage("/account");
  }, []);

  return (
    <HomeTemplate history={history}>
      <Account />
    </HomeTemplate>
  );
};

export default AccountPage;
