import React, { useEffect, useState } from "react";
import { notification } from "../../../../actions/notification";
import { api } from "../../../../api/api";
import { CONSTS } from "../../../../config/objectConst";
import { functions } from "../../../../tools/functions";
import ResponsiveButton from "../../../atoms/responsiveButton";
import { Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import store from "../../../../redux/store";
import { useSelector } from "react-redux";
import { subscriptionHelper } from "../../TableTeams/subscriptionHelper";
import "./style.scss";

const SubscriptionBilling = (props) => {
  const [subscription, setSubscription] = useState(null);
  const [appProfileChanged, setAppProfileChanged] = useState(null);
  const [isCancelSubscription, setIsCancelSubscription] = useState(false);
  const [isResumeSubscription, setIsResumeSubscription] = useState(false);
  const [isLoadingPaymentMethods, setIsLoadingPaymentMethods] = useState(false);
  const [isLoadingSubscription, setIsLoadingSubscription] = useState(null);
  const needLoading = isResumeSubscription || isCancelSubscription;
  const appProfile = useSelector((state) => state.app.appProfile);

  useEffect(() => {}, [appProfileChanged]);
  useEffect(() => {
    if (store.getState().app.subscription === null) {
      getSubscription();
    } else {
      setSubscription(store.getState().app.subscription);
    }
  }, [store.getState().app.subscription]);

  const getCurrentUser = () => {
    let result = appProfile.impersonatedPersona
      ? appProfile.impersonatedPersona
      : appProfile;
    return result;
  };

  const isActivatedByOwner = () => {
    let currentUser = getCurrentUser();
    let result =
      appProfile.teamRole &&
      currentUser.subscriptionOwnerId &&
      currentUser.subscriptionOwnerId !== currentUser.personaId;
    return result;
  };

  const isActivatedByUserItself = () => {
    let currentUser = getCurrentUser();
    let result =
      appProfile.teamRole &&
      currentUser.subscriptionOwnerId &&
      currentUser.subscriptionOwnerId === currentUser.personaId;
    return result;
  };

  const isAllowedToManageSubscription = () => {
    let result =
      !appProfile.impersonatedPersona &&
      (!appProfile.subscriptionOwnerId ||
        appProfile.subscriptionOwnerId === appProfile.personaId);
    return result;
  };

  const isActiveTrial = () => {
    let result =
      subscription &&
      subscription.plan_id === CONSTS.SUBSCRIPTIONS.TRIAL.ID &&
      subscription.is_active;
    return result;
  };

  const isInactiveTrial = () => {
    let result =
      subscription &&
      subscription.plan_id === CONSTS.SUBSCRIPTIONS.TRIAL.ID &&
      !subscription.is_active;
    return result;
  };

  const isActiveNotCancelledNotTrial = () => {
    let result =
      subscription &&
      subscription.plan_id !== CONSTS.SUBSCRIPTIONS.TRIAL.ID &&
      subscription.is_active &&
      subscription.state !== CONSTS.SUBSCRIPTIONS.STATE.CANCELED;
    return result;
  };

  const isActiveCancelledNotTrial = () => {
    let result =
      subscription &&
      subscription.plan_id !== CONSTS.SUBSCRIPTIONS.TRIAL.ID &&
      subscription.is_active &&
      subscription.state === CONSTS.SUBSCRIPTIONS.STATE.CANCELED;
    return result;
  };

  const isInactiveNotTrial = () => {
    let result =
      subscription &&
      subscription.plan_id !== CONSTS.SUBSCRIPTIONS.TRIAL.ID &&
      !subscription.is_active;
    return result;
  };

  const getPlanName = (planId) => {
    let result = CONSTS.SUBSCRIPTIONS[planId]
      ? CONSTS.SUBSCRIPTIONS[planId].NAME
      : planId;
    return result;
  };

  const getSubscription = async () => {
    isLoadingSubscription === null && setIsLoadingSubscription(true);
    try {
      const subscription = await api.getSubscription();
      store.dispatch({
        type: "SUBSCRIPTION",
        payload: {
          subscription: subscription?.data,
        },
      });
      setSubscription(subscription.data);
    } catch (e) {
      notification.notificationUse(e);
    } finally {
      setIsLoadingSubscription(false);
    }
  };

  const getNextChargeValue = () => {
    let result = subscriptionHelper.showNextCharge(subscription)
      ? getOriginalNextChargeValue()
      : "-";
    return result;
  };

  const getOriginalNextChargeValue = () => {
    let result = functions.parseDateToDMY(subscription.next_charge_date, "/");
    return result;
  };

  return (
    <div className="containerBilling">
      {isActiveTrial() && (
        <>
          <div className="containerBilling__container-text">
            <span className="containerBilling__text">Subscription plan </span>
            <p className="containerBilling__name">
              {`${CONSTS.SUBSCRIPTIONS.TRIAL.NAME}`}
            </p>
          </div>
          <div className="containerBilling__container-top">
            <div className="containerBilling__container-left">
              <p className="containerBilling__text">Activated Date</p>
              <p className="containerBilling__name-bold">
                {functions.parseDateToDMY(subscription.activation_date, "/")}
              </p>
              <div className="containerBilling__container-left-bottom">
                <p className="containerBilling__text">Expires on</p>
                <p className="containerBilling__name-bold">
                  {getOriginalNextChargeValue()}
                </p>
              </div>
            </div>
            <div>
              <p className="containerBilling__text">Status</p>
              <div className="containerBilling__flex-color-container">
                {subscriptionHelper
                  .getStatusTags(subscription)
                  .map((status) => (
                    <p key={status.text} className={status.style}>
                      {status.text}
                    </p>
                  ))}
              </div>
              <div className="containerBilling__container-left-bottom">
                <p className="containerBilling__text">Price</p>
                <p className="containerBilling__name-bold">Free</p>
              </div>
            </div>
          </div>
          {isAllowedToManageSubscription() && (
            <Link to={"/upgrade"} className="containerBilling__btn_with-bg">
              {"Upgrade subscription"}
            </Link>
          )}
        </>
      )}
      {isInactiveTrial() && (
        <>
          <div className="containerBilling__container-text">
            <span className="containerBilling__text">Subscription plan </span>
            <p className="containerBilling__name">
              {`${CONSTS.SUBSCRIPTIONS.TRIAL.NAME}`}
            </p>
          </div>
          <div className="containerBilling__container-top">
            <div className="containerBilling__container-left">
              <p className="containerBilling__text">Activated Date</p>
              <p className="containerBilling__name-bold">
                {functions.parseDateToDMY(subscription.activation_date, "/")}
              </p>
              <div className="containerBilling__container-left-bottom">
                <p className="containerBilling__text">Expired on</p>
                <p className="containerBilling__name-bold">
                  {getOriginalNextChargeValue()}
                </p>
              </div>
            </div>
            <div>
              <p className="containerBilling__text">Status</p>
              <div className="containerBilling__flex-color-container">
                {subscription &&
                  subscriptionHelper
                    .getStatusTags(subscription)
                    .map((status) => (
                      <p key={status.text} className={status.style}>
                        {status.text}
                      </p>
                    ))}
              </div>
              <div className="containerBilling__container-left-bottom">
                <p className="containerBilling__text">Price</p>
                <p className="containerBilling__name-bold">Free</p>
              </div>
            </div>
          </div>
          <Link to={"/upgrade"} className="containerBilling__btn_with-bg">
            {"Upgrade subscription"}
          </Link>
        </>
      )}

      {isActiveNotCancelledNotTrial() && (
        <>
          <div className="containerBilling__container-text">
            <span className="containerBilling__text">Subscription plan </span>
            <p className="containerBilling__name">{`${getPlanName(
              subscription.plan_id
            )}`}</p>
          </div>
          <div className="containerBilling__container-top">
            <div className="containerBilling__container-left">
              <p className="containerBilling__text">Activated Date</p>
              <p className="containerBilling__name-bold">
                {functions.parseDateToDMY(subscription.activation_date, "/")}
              </p>
              <div className="containerBilling__container-left-bottom">
                <p className="containerBilling__text">Next charge</p>
                <p className="containerBilling__name-bold">
                  {getNextChargeValue()}
                </p>
              </div>
            </div>
            <div>
              <p className="containerBilling__text">Status</p>
              <div className="containerBilling__flex-color-container">
                {subscription &&
                  subscriptionHelper
                    .getStatusTags(subscription)
                    .map((status) => (
                      <p key={status.text} className={status.style}>
                        {status.text}
                      </p>
                    ))}
              </div>
              <div className="containerBilling__container-left-bottom">
                <p className="containerBilling__text">Price</p>
                <div className="containerBilling__container-price">
                  <p className="containerBilling__name-bold">
                    {subscription.actual_price}
                    $/mo
                  </p>
                  {subscription.actual_price !== subscription.base_price && (
                    <p className="containerBilling__name-bold-accent">
                      {subscription.base_price}$
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          {isActivatedByOwner() && (
            <>
              <p className="text-activated">Activated by: </p>
              <span className="text-activated-accent">team owner</span>
            </>
          )}
          {isActivatedByUserItself() && (
            <>
              <p className="text-activated">Activated by: </p>
              <p className="text-activated-accent">
                {getCurrentUser().givenName} {getCurrentUser().familyName}
                <span className="text-activated-accent-email">
                  ({getCurrentUser().email})
                </span>
              </p>
            </>
          )}
          {isAllowedToManageSubscription() && (
            <>
              <div className="containerBilling__container-button">
                <Link to={"/upgrade"} className="containerBilling__btn_with-bg">
                  {"Upgrade subscription"}
                </Link>
                <ResponsiveButton
                  className="containerBilling__button-accent-cancel "
                  isLoading={needLoading}
                  onClick={async () => {
                    setIsCancelSubscription(true);
                    try {
                      const response = await api.cancelSubscription();
                      if (!response) {
                        return;
                      }
                      if (response.status === 200) {
                        await props.connector.auth.refreshAccessToken();
                        setAppProfileChanged(true);
                        await getSubscription();
                      }
                    } catch (error) {
                      notification.notificationUse(error);
                    } finally {
                      setIsCancelSubscription(false);
                    }
                  }}
                >
                  {"Cancel subscription"}
                </ResponsiveButton>
              </div>
              <div className="containerBilling__line"></div>
              <p className="containerBilling__name">Payment details</p>
              <ResponsiveButton
                className="containerBilling__border-black"
                isLoading={isLoadingPaymentMethods}
                onClick={async () => {
                  setIsLoadingPaymentMethods(true);
                  try {
                    const response = await api.getManagementUrl();
                    if (!response) {
                      return;
                    }
                    if (response.status === 200) {
                      window.open(response.data);
                    }
                  } catch (error) {
                    notification.notificationUse(error);
                  } finally {
                    setIsLoadingPaymentMethods(false);
                  }
                }}
              >
                {"Manage payment methods"}
              </ResponsiveButton>
            </>
          )}
        </>
      )}

      {isActiveCancelledNotTrial() && (
        <>
          <div className="containerBilling__container-text">
            <span className="containerBilling__text">Subscription plan </span>
            <p className="containerBilling__name">{`${getPlanName(
              subscription.plan_id
            )}`}</p>
          </div>
          <div className="containerBilling__container-top">
            <div className="containerBilling__container-left">
              <p className="containerBilling__text">Activated Date</p>
              <p className="containerBilling__name-bold">
                {functions.parseDateToDMY(subscription.activation_date, "/")}
              </p>
              <div className="containerBilling__container-left-bottom">
                <p className="containerBilling__text">Next charge</p>
                <p className="containerBilling__name-bold">-</p>
              </div>
            </div>
            <div>
              <p className="containerBilling__text">Status</p>
              <div className="containerBilling__flex-color-container">
                {subscription &&
                  subscriptionHelper
                    .getStatusTags(subscription)
                    .map((status) => (
                      <p key={status.text} className={status.style}>
                        {status.text}
                      </p>
                    ))}
              </div>
              <div className="containerBilling__container-left-bottom">
                <p className="containerBilling__text">Price</p>
                <div className="containerBilling__container-price">
                  <p className="containerBilling__name-bold">
                    {subscription.actual_price}
                    $/mo
                  </p>
                  {subscription.actual_price !== subscription.base_price && (
                    <p className="containerBilling__name-bold-accent">
                      {subscription.base_price}$
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          {isActivatedByOwner() && (
            <>
              <p className="text-activated">Activated by: </p>
              <span className="text-activated-accent">team owner</span>
            </>
          )}
          {isActivatedByUserItself() && (
            <>
              <p className="text-activated">Activated by: </p>
              <p className="text-activated-accent">
                {getCurrentUser().givenName} {getCurrentUser().familyName}
                <span className="text-activated-accent-email">
                  ({getCurrentUser().email})
                </span>
              </p>
            </>
          )}
          {isAllowedToManageSubscription() && (
            <>
              <div className="containerBilling__container-button">
                <Link to={"/upgrade"} className="containerBilling__btn_with-bg">
                  {"Upgrade subscription"}
                </Link>
                <ResponsiveButton
                  className=" containerBilling__button-accent "
                  isLoading={needLoading}
                  onClick={async () => {
                    setIsResumeSubscription(true);
                    try {
                      const response = await api.resumeSubscription();
                      if (!response) {
                        return;
                      }
                      if (response.status === 200) {
                        await props.connector.auth.refreshAccessToken();
                        setAppProfileChanged(true);
                        await getSubscription();
                      }
                    } catch (error) {
                      notification.notificationUse(error);
                    } finally {
                      setIsResumeSubscription(false);
                    }
                  }}
                >
                  {"Resume subscription"}
                </ResponsiveButton>
              </div>
              <div className="containerBilling__line"></div>
              <p className="containerBilling__name">Payment details</p>
              <ResponsiveButton
                className="containerBilling__border-black"
                isLoading={isLoadingPaymentMethods}
                onClick={async () => {
                  setIsLoadingPaymentMethods(true);
                  try {
                    const response = await api.getManagementUrl();
                    if (!response) {
                      return;
                    }
                    if (response.status === 200) {
                      window.open(response.data);
                    }
                  } catch (error) {
                    notification.notificationUse(error);
                  } finally {
                    setIsLoadingPaymentMethods(false);
                  }
                }}
              >
                {"Manage payment methods"}
              </ResponsiveButton>
            </>
          )}
        </>
      )}

      {isInactiveNotTrial() && (
        <>
          <div className="containerBilling__container-text">
            <span className="containerBilling__text">Subscription plan </span>
            <p className="containerBilling__name">{`${getPlanName(
              subscription.plan_id
            )}`}</p>
          </div>
          <div className="containerBilling__container-top">
            <div className="containerBilling__container-left">
              <p className="containerBilling__text">Activated Date</p>
              <p className="containerBilling__name-bold">
                {functions.parseDateToDMY(subscription.activation_date, "/")}
              </p>
              <div className="containerBilling__container-left-bottom">
                <p className="containerBilling__text">Next charge</p>
                <p className="containerBilling__name-bold">-</p>
              </div>
            </div>
            <div>
              <div className="containerBilling__flex-color-container">
                <p className="containerBilling__text">Status</p>
                {subscription &&
                  subscriptionHelper
                    .getStatusTags(subscription)
                    .map((status) => (
                      <p key={status.text} className={status.style}>
                        {status.text}
                      </p>
                    ))}
              </div>
              <div className="containerBilling__container-left-bottom">
                <p className="containerBilling__text">Price</p>
                <div className="containerBilling__container-price">
                  <p className="containerBilling__name-bold">
                    {subscription.actual_price}
                    $/mo
                  </p>
                  {subscription.actual_price !== subscription.base_price && (
                    <p className="containerBilling__name-bold-accent">
                      {subscription.base_price}$
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Link to={"/upgrade"} className="containerBilling__btn_with-bg">
            {"Upgrade subscription"}
          </Link>
          <div className="containerBilling__line"></div>
          <p className="containerBilling__name">Payment details</p>
          <ResponsiveButton
            className="containerBilling__border-black"
            isLoading={isLoadingPaymentMethods}
            onClick={async () => {
              setIsLoadingPaymentMethods(true);
              try {
                const response = await api.getManagementUrl();
                if (!response) {
                  return;
                }
                if (response.status === 200) {
                  window.open(response.data);
                }
              } catch (error) {
                notification.notificationUse(error);
              } finally {
                setIsLoadingPaymentMethods(false);
              }
            }}
          >
            {"Manage payment methods"}
          </ResponsiveButton>
        </>
      )}
      {isLoadingSubscription && (
        <div className="table-material-loader-wrapper">
          <CircularProgress
            color="primary"
            size={60}
            classes={{ colorPrimary: "material-loader" }}
          />
        </div>
      )}
    </div>
  );
};

export default SubscriptionBilling;
