import React, { Component } from "react";
import { connect } from "react-redux";
// Components
import Notification from "../../molecules/notification";
import Logo from "../../atoms/Logo";
import SupportButton from "../../atoms/SupportButton";
// configs
import { CONSTS } from "../../../config/objectConst";
// Style
import "./style.scss";

class AuthTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      steps: [1, 2, 3, 4, 5],
    };
  }

  date = new Date().getFullYear();

  render() {
    return (
      <div className="template-wrapper-auth">
        <div className="template-wrapper-auth__header">
          <SupportButton />
          {this.props.notification.status && (
            <Notification
              text={this.props.notification.text}
              error={this.props.notification.error}
            />
          )}
          <Logo />
        </div>
        <div className="template-wrapper-auth__body">
          <div className="box">{this.props.children}</div>
          <div className="steps">
            {this.state.steps.map((item, index) => {
              let controller = this.props.show
                ? index + 1 > this.props.show
                  ? false
                  : true
                : true;
              return (
                controller && (
                  <div
                    key={index}
                    className={
                      item === this.props.active ? "step active" : "step"
                    }
                  />
                )
              );
            })}
          </div>
        </div>
        <div className="template-wrapper-auth__footer">
          <span
            className="text-page-auth-footer"
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open(CONSTS.TERMS_AND_CONDITIONS_URL, "_blank");
            }}
          >
            Terms and Conditions
          </span>
          <span className="text-page-auth-footer">
            {`© ${CONSTS.PROJECT_NAME}  ${this.date}`}
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    connector: state.app.connector,
    notification: state.notification,
  };
};

export default connect(mapStateToProps)(AuthTemplate);
