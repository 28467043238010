export const linkedInProfileOpener = {
  open: async (connector, profile) => {
    let linkedInProfile = await connector.linkedIn.getCurrentProfile();
    let id = "";
    if (linkedInProfile) {
      id = profile.entityId ? profile.entityId : profile.publicIdentifier;
    } else {
      id = profile.publicIdentifier
        ? profile.publicIdentifier
        : profile.entityId;
    }

    window.open(`https://www.linkedin.com/in/${id}/`, "_blank");
  },
};
