import React, { useEffect, useState } from "react";
import { app } from "../../../actions/app";
import { useHistory } from "react-router-dom";
import { notification } from "../../../actions/notification";
import { CONSTS } from "../../../config/objectConst";
// components
import AuthTemplate from "../../templates/authTemplate";
import ModalConfirmLinkedInProfile from "../noLinkedinContainer/modal";
import DateSelection from "../../molecules/dateSelection";
import { functions } from "../../../tools/functions";
import ResponsiveButton from "../../atoms/responsiveButton";

export const NoWorkingHoursContainer = ({
  connector,
  appProfile,
  open,
  setOpen,
}) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [timeZone, setTimeZone] = useState(0);
  const [loader, setLoader] = useState(false);

  const initialHours = "09:00-19:00";
  const [hours, setHours] = useState({
    monday: {
      isSelected: true,
      value: initialHours,
      errorMessage: "",
    },
    tuesday: {
      isSelected: true,
      value: initialHours,
      errorMessage: "",
    },
    wednesday: {
      isSelected: true,
      value: initialHours,
      errorMessage: "",
    },
    thursday: {
      isSelected: true,
      value: initialHours,
      errorMessage: "",
    },
    friday: {
      isSelected: true,
      value: initialHours,
      errorMessage: "",
    },
    saturday: {
      isSelected: false,
      value: initialHours,
      errorMessage: "",
    },
    sunday: {
      isSelected: false,
      value: initialHours,
      errorMessage: "",
    },
  });

  useEffect(() => {
    appProfile &&
      appProfile.timezoneOffset !== undefined &&
      setTimeZone(
        CONSTS.TIME_ZONES.find(
          (item) => item.value === appProfile.timezoneOffset.toString()
        ).label
      );
  }, [appProfile]);

  const saveWorkingHours = async (hours) => {
    const tempHours = { ...hours };
    let isValid = true;
    let oneOrMoreSelected = false;
    const requestHours = {};

    for (const [key, value] of Object.entries(tempHours)) {
      if (value.isSelected) {
        const validationMessage = functions.validateHours(value.value);
        const startTimeInHours =
          value.value && value.value.split("-")[0].split(":")[0];
        const startTimeInMinutes =
          value.value && value.value.split("-")[0].split(":")[1];
        const endTimeInHours =
          value.value && value.value.split("-")[1].split(":")[0];
        const endTimeInMinutes =
          value.value && value.value.split("-")[1].split(":")[1];
        const totalTime = functions.getTotalTime(
          Number(startTimeInHours),
          Number(endTimeInHours),
          Number(startTimeInMinutes),
          Number(endTimeInMinutes)
        );

        tempHours[key].errorMessage = validationMessage;
        if (validationMessage.length) isValid = false;
        oneOrMoreSelected = true;
        requestHours[key] = [
          {
            start: Number(startTimeInHours) * 60 + Number(startTimeInMinutes),
            duration: totalTime,
          },
        ];
      }
    }

    setHours(tempHours);

    if (isValid && oneOrMoreSelected) {
      setLoading(true);
      try {
        const response = await app.setNewData(
          "workspace/working-hours-in-minutes",
          requestHours
        );

        if (response.status === 200) {
          setOpen(true);
        }
      } catch (e) {
        notification.notificationUse(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const dateSelectionHandler = (day) => {
    setHours({
      ...hours,
      [day]: { ...hours[day], isSelected: !hours[day].isSelected },
    });
  };

  const handleDateChange = (day, value) => {
    setHours({ ...hours, [day]: { ...hours[day], value: value } });
  };

  const checkIsSelectedHours = () => {
    return Object.keys(hours).some((key) => hours[key].isSelected);
  };

  return (
    <AuthTemplate active={5}>
      <h2>Working hours</h2>
      <p className="auth-modal-description">{timeZone}</p>
      <p className="notion">
        <span className="exclaim">&nbsp;</span>You can work up to{" "}
        {CONSTS.WORKING_HOURS} hours a day.
      </p>
      <div className="schedule-steps-wrap">
        <div className="schedule-steps__fields">
          <div className="schedule-steps__item">
            <DateSelection
              dayOfWeek="monday"
              dateSelectionHandler={dateSelectionHandler}
              handleDateChange={handleDateChange}
              hours={hours.monday}
            />
          </div>
          <div className="schedule-steps__item">
            <DateSelection
              dayOfWeek="tuesday"
              dateSelectionHandler={dateSelectionHandler}
              handleDateChange={handleDateChange}
              hours={hours.tuesday}
            />
          </div>
          <div className="schedule-steps__item">
            <DateSelection
              dayOfWeek="wednesday"
              dateSelectionHandler={dateSelectionHandler}
              handleDateChange={handleDateChange}
              hours={hours.wednesday}
            />
          </div>
          <div className="schedule-steps__item">
            <DateSelection
              dayOfWeek="thursday"
              dateSelectionHandler={dateSelectionHandler}
              handleDateChange={handleDateChange}
              hours={hours.thursday}
            />
          </div>
          <div className="schedule-steps__item">
            <DateSelection
              dayOfWeek="friday"
              dateSelectionHandler={dateSelectionHandler}
              handleDateChange={handleDateChange}
              hours={hours.friday}
            />
          </div>
          <div className="schedule-steps__item">
            <DateSelection
              dayOfWeek="saturday"
              dateSelectionHandler={dateSelectionHandler}
              handleDateChange={handleDateChange}
              hours={hours.saturday}
            />
          </div>
          <div className="schedule-steps__item">
            <DateSelection
              dayOfWeek="sunday"
              dateSelectionHandler={dateSelectionHandler}
              handleDateChange={handleDateChange}
              hours={hours.sunday}
            />
          </div>
        </div>
        <div className="modal-create-linked__btn-container">
          <ResponsiveButton
            className="btn bold hours"
            onClick={() => saveWorkingHours(hours)}
            isLoading={loading}
            disabled={!checkIsSelectedHours()}
          >
            <div className="btn-content">
              Accept <span className="arrow-right">&nbsp;</span>
            </div>
          </ResponsiveButton>
        </div>
      </div>
      <ModalConfirmLinkedInProfile
        setOpen={setOpen}
        open={open}
        connector={connector}
        history={history}
        confirm={true}
        setLoader={setLoader}
      />
    </AuthTemplate>
  );
};

export default NoWorkingHoursContainer;
