import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { CircularProgress } from "@material-ui/core";
import store from "../../../redux/store";
import ResponsiveButton from "../../atoms/responsiveButton";
import { functions } from "../../../tools/functions";
import { teams } from "../../../actions/teams";
import "./style.scss";
import { connect } from "react-redux";
import ModalSubscription from "../ModalSubscription";
import ModalWindow from "../ModalWindow";
import TooltipCustom from "../../atoms/tooltipCustom";
import linkSvgGold from "../../../assets/image/icons/tables/goldLink.svg";
import { linkedInProfileOpener } from "../../../api/linkedInProfileOpener";
import { listeners } from "../../../api/listeners";
import { CONSTS } from "../../../config/objectConst";
import { notification } from "../../../actions/notification";
import TextFieldBig from "../../atoms/TextFieldBig";
import { subscriptionHelper } from "./subscriptionHelper";
import singIn from "../../../assets/image/icons/svg/singIn.svg";
import arrow from "../../../assets/image/icons/svg/arrow-up-right.svg";
import threeDots from "../../../assets/image/icons/svg/three-dots.svg";
import deleteInviteSvg from "../../../assets/image/icons/svg/deleteInvite.svg";
import iconStop from "../../../assets/image/icons/svg/icons/stop.svg";
import iconLoading from "../../../assets/image/icons/gif/input-loader.gif";
import avatarSvg from "../../../assets/image/icons/svg/user.svg";
import Icon from "../TableUsers/icon";

export class TableTeams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      personas: [],
      dataPersona: {},
      isModalSubscriptionOpen: false,
      personaId: "",
      currentPlan: "",
      currentId: null,
      currentIdRorEditor: null,
      isSignInLoading: false,
      isContainerSelectVisible: false,
      isCancelSubscriptionLoading: false,
      isResumeSubscriptionLoading: false,
      isLeaveInviteLoading: false,
      isSortAsc: null,
      isSortAscAdded: true,
      isSortAscName: null,
      editTitle: false,
      value: "",
      loading: false,
      options: [
        { value: "user", label: "User" },
        { value: "manager", label: "Manager" },
      ],
    };
  }

  onEnter = async () => {
    this.setState({ loading: true });
    try {
      const response = await teams.editMemberNickName({
        id: this.state.currentIdRorEditor,
        name: this.state.value,
      });
      if (response.status === 200) {
        const connector = listeners.getConnector();
        await connector.auth.refreshAccessToken();
        await this.props.getPersonas();
      }
      if (response.status === 200) {
        notification.snacknotificationUse({
          open: true,
          title: CONSTS.notification["n3.32"].title,
          content: CONSTS.notification["n3.32"].content,
          style: CONSTS.notification["n3.32"].style,
        });
      }
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      this.setState({ loading: false, currentIdRorEditor: null });
    }
  };

  onBlur = () => {
    this.onEnter();
  };

  onChangeEdit = (value) => {
    if (value.length <= CONSTS.NICK_NAME_MAX_LENGTH) {
      this.setState({ value: value });
    }
  };
  clickOnEditAction = (row) => {
    this.setState({ editTitle: true, value: row.nickname });
  };

  showDots = (row) => {
    let result =
      this.showRemoveMember(row) ||
      this.showCancelSubscription(row) ||
      this.showResumeSubscription(row);
    return result;
  };

  showRemoveMember = (row) => {
    let result =
      !row.subscription.is_active ||
      row.subscription.owner_id === row.id ||
      !row.subscription.owner_id;
    return result;
  };

  showCancelSubscription = (row) => {
    let result =
      this.isUpgradeAllowedForCurrentUser() &&
      !this.isActiveSelfBoughtSubscription(row) &&
      row.subscription.is_active &&
      row.subscription.state !== CONSTS.SUBSCRIPTIONS.STATE.CANCELED &&
      row.subscription.plan_id !== CONSTS.SUBSCRIPTIONS.TRIAL.ID;
    return result;
  };

  showResumeSubscription = (row) => {
    let result =
      this.isUpgradeAllowedForCurrentUser() &&
      !this.isActiveSelfBoughtSubscription(row) &&
      row.subscription.is_active &&
      row.subscription.state === CONSTS.SUBSCRIPTIONS.STATE.CANCELED &&
      row.subscription.plan_id !== CONSTS.SUBSCRIPTIONS.TRIAL.ID;
    return result;
  };

  removeMember = async (persona) => {
    this.setState({ isDeleteInviteOpened: false });
    this.setState({ loader: true });
    try {
      const response = await teams.deleteMember(persona.id);
      if (response.status === 200) {
        const connector = listeners.getConnector();
        await connector.auth.refreshAccessToken();
        await this.props.getPersonas();
      }
      if (response.status === 200) {
        notification.snacknotificationUse({
          open: true,
          title: CONSTS.notification["n3.31"].title,
          content: CONSTS.notification["n3.31"].content,
          style: CONSTS.notification["n3.31"].style,
        });
      }
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      this.setState({ loader: false });
    }
  };

  getCurrentAppProfile = () => {
    let result = this.props.localAppProfile.impersonatedPersona
      ? this.props.localAppProfile.impersonatedPersona
      : this.props.localAppProfile;
    return result;
  };

  cancelTeamMemberSubscription = async (id) => {
    this.setState({ loader: true });
    this.setState({ isContainerSelectVisible: false });
    try {
      const response = await teams.cancelTeamMemberSubscription(id);
      await this.props.getPersonas();
      if (response.status === 200) {
        notification.snacknotificationUse({
          open: true,
          title: CONSTS.notification["3.29"].title,
          content: CONSTS.notification["3.29"].content,
          style: CONSTS.notification["3.29"].style,
        });
      }
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      this.setState({ loader: false });
    }
  };

  resumeTeamMemberSubscription = async (id) => {
    this.setState({ loader: true });
    this.setState({ isContainerSelectVisible: false });
    try {
      const response = await teams.resumeTeamMemberSubscription(id);
      await this.props.getPersonas();
      if (response.status === 200) {
        notification.snacknotificationUse({
          open: true,
          title: CONSTS.notification["n3.30"].title,
          content: CONSTS.notification["n3.30"].content,
          style: CONSTS.notification["n3.30"].style,
        });
      }
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      this.setState({ loader: false });
    }
  };

  signIn = async (personaId) => {
    const connector = listeners.getConnector();
    this.setState({
      isSignInLoading: true,
    });
    try {
      await connector.auth.signInUnderTeamMember(personaId);
      this.props.history.push("/campaigns");
      this.setState({
        isSignInLoading: false,
      });
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      this.setState({
        isSignInLoading: false,
      });
    }
  };

  openLinkedInProfile = async (row) => {
    const connector = listeners.getConnector();
    await linkedInProfileOpener.open(connector, row);
  };

  isTeamTrial = (currentUser) => {
    let activationDate = new Date(currentUser.subscriptionActivationDate);
    let result =
      new Date(2023, 3, 15) > activationDate &&
      new Date() < new Date(2023, 4, 15);
    return result;
  };

  isUpgradeAllowedForCurrentUser = () => {
    let currentUser = this.props.localAppProfile;
    let result =
      !this.props.localAppProfile.impersonatedPersona &&
      currentUser.subscriptionPlanId !== CONSTS.SUBSCRIPTIONS.TRIAL.ID &&
      (currentUser.teamRole === CONSTS.ROLES.Owner ||
        this.isTeamTrial(currentUser));
    return result;
  };

  isActiveSelfBoughtSubscription = (row) => {
    let result =
      row.subscription.owner_id === row.id && row.subscription.is_active;
    return result;
  };

  isOwner = (row) => {
    let result = row.role === "owner";
    return result;
  };

  showSigninColumn = () => {
    let result = !this.props.localAppProfile.impersonatedPersona;
    return result;
  };

  customSort = () => {
    let result;
    if (this.state.isSortAsc) {
      result = this.props.personas.sort(
        (a, b) =>
          Date.parse(a?.subscription?.next_charge_date) -
          Date.parse(b?.subscription?.next_charge_date)
      );
    } else if (this.state.isSortAsc === false) {
      result = this.props.personas.sort(
        (a, b) =>
          Date.parse(b?.subscription?.next_charge_date) -
          Date.parse(a?.subscription?.next_charge_date)
      );
    } else if (this.state.isSortAscAdded) {
      result = this.props.personas.sort(
        (a, b) => Date.parse(a?.added) - Date.parse(b?.added)
      );
    } else if (this.state.isSortAscAdded === false) {
      result = this.props.personas.sort(
        (a, b) => Date.parse(b?.added) - Date.parse(a?.added)
      );
    } else if (this.state.isSortAscName) {
      result = this.props.personas
        .filter((member) => !member.pending)
        .sort((a, b) => {
          let nameA = a.nickname.toLowerCase(),
            nameB = b.nickname.toLowerCase();
          if (nameA > nameB) return 1;
          if (nameA < nameB) return -1;
          return 0;
        });
    } else if (this.state.isSortAscName === false) {
      result = this.props.personas
        .filter((member) => !member.pending)
        .sort((a, b) => {
          let nameA = a.nickname.toLowerCase(),
            nameB = b.nickname.toLowerCase();
          if (nameA > nameB) return -1;
          if (nameA < nameB) return 1;
          return 0;
        });
    } else {
      result = this.props.personas;
    }
    return result;
  };
  allowSignIn = (row) => {
    let result =
      this.getCurrentAppProfile().personaId !== row.id &&
      !this.props.localAppProfile.impersonatedPersona;
    return result;
  };

  render() {
    const getDataClientWidth = (row) => {
      const lengthRow =
        row?.nickname?.length +
        (this.props.localAppProfile.personaId === row.id ? 3 : 0);

      return (
        (lengthRow > 13 &&
          document.documentElement.clientWidth > 1200 &&
          document.documentElement.clientWidth < 1535) ||
        (lengthRow > 22 &&
          document.documentElement.clientWidth > 1535 &&
          document.documentElement.clientWidth < 1744) ||
        (lengthRow > 30 &&
          document.documentElement.clientWidth > 1744 &&
          document.documentElement.clientWidth < 1919) ||
        (lengthRow > 35 &&
          document.documentElement.clientWidth > 1919 &&
          document.documentElement.clientWidth < 2132) ||
        (lengthRow > 43 &&
          document.documentElement.clientWidth > 2132 &&
          document.documentElement.clientWidth < 2401)
      );
    };

    const getDataClientWidthEmail = (row) => {
      return (
        (row.email.length > 19 &&
          document.documentElement.clientWidth > 1200 &&
          document.documentElement.clientWidth < 1535) ||
        (row.email.length > 28 &&
          document.documentElement.clientWidth > 1535 &&
          document.documentElement.clientWidth < 1744) ||
        (row.email.length > 36 &&
          document.documentElement.clientWidth > 1744 &&
          document.documentElement.clientWidth < 1919) ||
        (row.email.length > 41 &&
          document.documentElement.clientWidth > 1919 &&
          document.documentElement.clientWidth < 2132) ||
        (row.email.length > 49 &&
          document.documentElement.clientWidth > 2132 &&
          document.documentElement.clientWidth < 2401)
      );
    };
    return (
      <div className="table-container container-height">
        <ModalWindow
          open={this.state.isModalSubscriptionOpen}
          classes="invite-modal"
          closeHandler={() => this.setState({ isModalSubscriptionOpen: false })}
        >
          <ModalSubscription
            getMembers={this.props.getPersonas}
            dataPersona={this.state.dataPersona}
            setIsModalSubscriptionOpen={(value) =>
              this.setState({
                isModalSubscriptionOpen: value,
              })
            }
          />
        </ModalWindow>
        <TableContainer
          className={this.props.checkboxs && "table-body with-checkboxs"}
        >
          {this.state.loader && (
            <div className="table-material-loader-wrapper">
              <CircularProgress
                color="primary"
                size={60}
                classes={{ colorPrimary: "material-loader" }}
              />
            </div>
          )}
          <Table
            className={"table users"}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell className="name-team">
                  <div className="flex-container-accent">
                    {this.state.isSortAscName === null && (
                      <div
                        className={"container-title-text"}
                        onClick={() => {
                          this.setState({ isSortAscName: true });
                          this.setState({ isSortAsc: null });
                          this.setState({ isSortAscAdded: null });
                        }}
                      >
                        Account
                        <Icon
                          name="asc"
                          size={18}
                          className={
                            this.state.isSortAscName === false
                              ? "title-images-active"
                              : "title-images"
                          }
                        />
                      </div>
                    )}

                    {this.state.isSortAscName && (
                      <div
                        className={"container-title-text-accent"}
                        onClick={() => {
                          this.setState({ isSortAscName: false });
                          this.setState({ isSortAsc: null });
                          this.setState({ isSortAscAdded: null });
                        }}
                      >
                        Account
                        <Icon
                          name="asc"
                          size={18}
                          className="title-images-active"
                        />
                      </div>
                    )}
                    {this.state.isSortAscName === false && (
                      <div
                        className={"container-title-text-accent"}
                        onClick={() => {
                          this.setState({ isSortAscName: true });
                          this.setState({ isSortAsc: null });
                          this.setState({ isSortAscAdded: null });
                        }}
                      >
                        Account
                        <Icon
                          name="desc"
                          size={18}
                          className="title-images-active"
                        />
                      </div>
                    )}
                  </div>
                </TableCell>
                <TableCell className={"account-team"}>
                  <div className="container-title-images">Status</div>
                </TableCell>
                <TableCell className={"connection-team"}>
                  <div className="container-title-images">Role</div>
                </TableCell>
                <TableCell className={"date-accent-team"}>
                  <div className="container-title-images">Plan</div>
                </TableCell>
                <TableCell className={"date-accent-activate"}>
                  <div className="flex-container-accent">
                    {this.state.isSortAscAdded === null && (
                      <div
                        className="container-title-text"
                        onClick={() => {
                          this.setState({ isSortAscAdded: true });
                          this.setState({ isSortAscName: null });
                          this.setState({ isSortAsc: null });
                        }}
                      >
                        Added
                        <Icon
                          name="asc"
                          size={18}
                          className={
                            this.state.isSortAscAdded === false
                              ? "title-images-active"
                              : "title-images"
                          }
                        />
                      </div>
                    )}
                    {this.state.isSortAscAdded && (
                      <div
                        className="container-title-text-accent"
                        onClick={() => {
                          this.setState({ isSortAscAdded: false });
                          this.setState({ isSortAscName: null });
                          this.setState({ isSortAsc: null });
                        }}
                      >
                        Added
                        <Icon
                          name="asc"
                          size={18}
                          className="title-images-active"
                        />
                      </div>
                    )}
                    {this.state.isSortAscAdded === false && (
                      <div
                        className="container-title-text-accent"
                        onClick={() => {
                          this.setState({ isSortAscAdded: true });
                          this.setState({ isSortAscName: null });
                          this.setState({ isSortAsc: null });
                        }}
                      >
                        Added
                        <Icon
                          name="desc"
                          size={18}
                          className="title-images-active"
                        />
                      </div>
                    )}
                  </div>
                </TableCell>
                <TableCell className={"date-accent-charge"}>
                  <div className="flex-container-accent">
                    {this.state.isSortAsc === null && (
                      <div
                        className="container-title-text"
                        onClick={() => {
                          this.setState({ isSortAsc: true });
                          this.setState({ isSortAscAdded: null });
                          this.setState({ isSortAscName: null });
                        }}
                      >
                        <p className="team-title"> Next Charge</p>

                        <Icon
                          name="asc"
                          size={18}
                          className={
                            this.state.isSortAsc === false
                              ? "title-images-active"
                              : "title-images"
                          }
                        />
                      </div>
                    )}
                    {this.state.isSortAsc && (
                      <div
                        className="container-title-text-accent"
                        onClick={() => {
                          this.setState({ isSortAsc: false });
                          this.setState({ isSortAscAdded: null });
                          this.setState({ isSortAscName: null });
                        }}
                      >
                        <p className="team-title"> Next Charge</p>
                        <Icon
                          name="asc"
                          size={18}
                          className="title-images-active"
                        />
                      </div>
                    )}
                    {this.state.isSortAsc === false && (
                      <div
                        className="container-title-text-accent"
                        onClick={() => {
                          this.setState({ isSortAsc: true });
                          this.setState({ isSortAscAdded: null });
                          this.setState({ isSortAscName: null });
                        }}
                      >
                        <p className="team-title"> Next Charge</p>
                        <Icon
                          name="desc"
                          size={18}
                          className="title-images-active"
                        />
                      </div>
                    )}
                  </div>
                </TableCell>
                {this.showSigninColumn() && (
                  <TableCell className={"sign-in-team"}>
                    <div className="container-title-images">Actions</div>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody ref={this.wrapper}>
              {this.customSort()
                .filter((el) => !el.pending)
                .map((row, index) => (
                  <TableRow key={row.id} className="row">
                    <TableCell className="name-team">
                      <div className="name-container">
                        <img
                          className="avatar"
                          src={row.avatar ? row.avatar : avatarSvg}
                          alt="avatar"
                        />
                        <div className="ellipsis">
                          <div className="flex-wrapper-team-accent">
                            {(row.entityId || row.publicIdentifier) && (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="/#"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  await this.openLinkedInProfile(row);
                                }}
                              >
                                <TooltipCustom
                                  title="Open LinkedIn profile"
                                  arrow={true}
                                >
                                  <img
                                    className="link-img-accent "
                                    src={linkSvgGold}
                                    alt="link svg"
                                  />
                                </TooltipCustom>
                              </a>
                            )}
                            <div className={"text-box-wrapper"}>
                              <div>
                                {this.state.currentIdRorEditor !== row.id ? (
                                  <div className="edit-title ">
                                    <div
                                      className={
                                        getDataClientWidth(row)
                                          ? "text-box-wrapper-hover "
                                          : "text-box-wrapper"
                                      }
                                    >
                                      <TooltipCustom
                                        title={
                                          getDataClientWidth(row)
                                            ? row.nickname
                                            : ""
                                        }
                                        arrow={true}
                                      >
                                        <div className="text-page first-name semi-bold ellipsis">
                                          {row.nickname}
                                        </div>
                                      </TooltipCustom>
                                    </div>

                                    {this.props.localAppProfile.personaId ===
                                      row.id && "(you)"}
                                    <button
                                      className="btn-edit size-btn-edit"
                                      onClick={() => {
                                        this.clickOnEditAction(row);
                                        this.setState({
                                          currentIdRorEditor: row.id,
                                        });
                                      }}
                                    ></button>
                                  </div>
                                ) : (
                                  <TextFieldBig
                                    fullWidth
                                    autoFocus
                                    value={this.state.value}
                                    onChange={(e) =>
                                      this.onChangeEdit(e.target.value)
                                    }
                                    onEnter={this.onEnter}
                                    onBlur={this.onBlur}
                                    endAdornment={
                                      this.state.loading ? (
                                        <img src={iconLoading} alt="loading" />
                                      ) : (
                                        ""
                                      )
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              getDataClientWidthEmail(row)
                                ? "text-box-wrapper-hover"
                                : "text-box-wrapper"
                            }
                          >
                            <TooltipCustom
                              title={
                                getDataClientWidthEmail(row) ? row.email : ""
                              }
                              arrow
                            >
                              <p className={"email-team ellipsis"}>
                                {row.email}
                              </p>
                            </TooltipCustom>
                          </div>
                        </div>
                      </div>
                    </TableCell>

                    <TableCell className="account-team">
                      <div className="containerBilling__flex-color-container">
                        {subscriptionHelper
                          .getStatusTags(row.subscription)
                          .map((status) => (
                            <p key={status.text} className={status.style}>
                              {status.text}
                            </p>
                          ))}
                      </div>
                    </TableCell>
                    <TableCell className="connection-team">
                      <p className={"left-list-text-accent-role"}>{row.role}</p>
                    </TableCell>
                    <TableCell className="date-accent-team">
                      <div className="flex-wrapper-team">
                        <p className={"left-list-text-accent"}>
                          {row.subscription.plan_id.toUpperCase()}
                        </p>
                        {this.isUpgradeAllowedForCurrentUser() &&
                          !this.isOwner(row) &&
                          !this.isActiveSelfBoughtSubscription(row) && (
                            <div className="flex-container-accent">
                              <ResponsiveButton
                                className={"link-upgrade"}
                                onClick={() => {
                                  this.setState({
                                    isModalSubscriptionOpen: true,
                                  });
                                  this.setState({
                                    dataPersona: row,
                                  });
                                }}
                                isLoading={false}
                                disabled={false}
                              >
                                {"Upgrade"}
                              </ResponsiveButton>
                              <img alt="arrow" src={arrow} />
                            </div>
                          )}
                        {this.isUpgradeAllowedForCurrentUser() &&
                          !this.isOwner(row) &&
                          this.isActiveSelfBoughtSubscription(row) && (
                            <p className="link-upgrade-accent">Self-Paid</p>
                          )}
                      </div>
                    </TableCell>
                    <TableCell className="date-accent-activate">
                      <p className={"left-list-text-accent"}>
                        {functions.parseDateToDMY(row.added, "/")}
                      </p>
                    </TableCell>
                    <TableCell className="date-accent-charge">
                      {subscriptionHelper.showNextCharge(row.subscription) && (
                        <>
                          <p className={"left-list-text-accent"}>
                            {functions.parseDateToDMY(
                              row.subscription.next_charge_date,
                              "/"
                            )}
                          </p>
                          {subscriptionHelper.showNextChargePrice(
                            row.subscription
                          ) && (
                            <p className={"left-list-text-accent"}>
                              (${row.subscription.actual_price} USD)
                            </p>
                          )}
                        </>
                      )}
                    </TableCell>
                    {this.showSigninColumn(row) && (
                      <TableCell className="sign-in-team">
                        <div className="dots-container">
                          {this.allowSignIn(row) && (
                            <ResponsiveButton
                              className={"team-button-sign-in-image"}
                              onClick={() => {
                                this.setState({
                                  currentId: row.id,
                                });
                                this.signIn(row.id);
                              }}
                              isLoading={
                                this.state.currentId === row.id &&
                                this.state.isSignInLoading
                              }
                            >
                              <img
                                className={"team-sign-in-image"}
                                alt="singIn"
                                src={singIn}
                              />
                              Sign in
                            </ResponsiveButton>
                          )}
                          {this.showDots(row) && (
                            <>
                              {!this.isOwner(row) && (
                                <img
                                  onClick={() => {
                                    this.setState({
                                      isContainerSelectVisible:
                                        !this.state.isContainerSelectVisible,
                                      currentId: row.id,
                                    });
                                  }}
                                  className="img-threeDots"
                                  alt="threeDots"
                                  src={threeDots}
                                />
                              )}
                              {this.state.isContainerSelectVisible &&
                                row.id === this.state.currentId && (
                                  <>
                                    <div
                                      className="backdrop-team"
                                      onClick={() => {
                                        this.setState({
                                          isContainerSelectVisible: false,
                                        });
                                      }}
                                    ></div>
                                    <div className="container-select">
                                      {this.showRemoveMember(row) && (
                                        <ResponsiveButton
                                          className={"select-button"}
                                          onClick={() => {
                                            this.setState({
                                              selectedPersona: row,
                                            });
                                            this.setState({
                                              isDeleteInviteOpened: true,
                                            });
                                            this.setState({
                                              isContainerSelectVisible: false,
                                            });
                                          }}
                                          isLoading={false}
                                          disabled={false}
                                        >
                                          <img
                                            alt="deleteInviteSvg"
                                            src={deleteInviteSvg}
                                            height="15"
                                            width="12"
                                          />
                                          Remove member
                                        </ResponsiveButton>
                                      )}
                                      {this.showCancelSubscription(row) && (
                                        <ResponsiveButton
                                          className={"select-button"}
                                          onClick={() => {
                                            this.cancelTeamMemberSubscription(
                                              row.id
                                            );
                                          }}
                                          isLoading={false}
                                          disabled={false}
                                        >
                                          <img src={iconStop} alt="iconPause" />
                                          Cancel subscription
                                        </ResponsiveButton>
                                      )}
                                      {this.showResumeSubscription(row) && (
                                        <ResponsiveButton
                                          className={"select-button"}
                                          onClick={() => {
                                            this.resumeTeamMemberSubscription(
                                              row.id
                                            );
                                          }}
                                          isLoading={false}
                                          disabled={false}
                                        >
                                          Resume subscription
                                        </ResponsiveButton>
                                      )}
                                    </div>
                                  </>
                                )}
                            </>
                          )}
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ModalWindow
          open={this.state.isDeleteInviteOpened}
          closeText="No"
          confirmText="Yes"
          closeHandler={() => this.setState({ isDeleteInviteOpened: false })}
          confirmHandler={() => this.removeMember(this.state.selectedPersona)}
          title={CONSTS.confirmationDialogs["c1.14"].title}
          content={CONSTS.confirmationDialogs["c1.14"].content
            .replace("{nickname}", this.state.selectedPersona?.nickname ?? "")
            .replace("{email}", this.state.selectedPersona?.email ?? "")}
        />
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    appPage: state.app.appPage,
    notification: state.notification,
    appProfile: state.app.appProfile,
    pageContentLoader: state.app.pageContentLoader,
    activity: state.activity,
    subscription: state.app.subscription,
  };
};
export default connect(mapStateToProps)(TableTeams);
