import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import TooltipCustom from "../../atoms/tooltipCustom";

// Actions
import { campaign } from "../../../actions/campaign";

// Icons

import startIcon from "../../../assets/image/icons/svg/icons/start.svg";
import pauseIcon from "../../../assets/image/icons/svg/icons/pause.svg";
import stopIcon from "../../../assets/image/icons/svg/icons/stop.svg";
import { connectorAPI } from "../../../actions/connector";
import { listeners } from "../../../api/listeners";
import { CONSTS } from "../../../config/objectConst";
import { notification } from "../../../actions/notification";
import HintTooltip from "../HintTooltip";
import ModalWindow from "../../molecules/ModalWindow";
import ConfirmationDialog from "../ConfirmationDialog";
import { apiCampaign } from "../../../api/campaign/apiCampaign";
import { app } from "../../../actions/app";
import { functions } from "../../../tools/functions";

export class SegmentButtonGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalData: null,
      modalOpen: false,
      confirmationDialog: {
        id: null,
        status: false,
        data: {},
      },
    };
  }

  clickOnEditAction = () => {
    this.props.history.push(`/campaigns/${this.props.campaign.id}/tune`);
  };

  startCampaign = async (id) => {
    await campaign.startCampaign(
      id,
      this.props.campaign.information?.[0].value
    );
    await campaign.getCampaignId(id);
    (await this.props.getContacts) && this.props.getContacts();
    campaign.getCampaignExecutionsStatistics(id);

    const snailyProfile = await connectorAPI.getSnailyProfile();
    if (snailyProfile.onBoarding && !snailyProfile.runningActivitiesEnabled) {
      const connector = listeners.getConnector();
      await connector.auth.refreshAccessToken();
    }

    if (this.props.withRedirectToCamping) {
      this.props.history.push(`/campaigns/${id}`);
    }
  };

  stopCampaign = async (id) => {
    await campaign.stopCampaign(id);
    await campaign.getCampaignId(id);
    campaign.getCampaignExecutionsStatistics(id);
  };

  completeCampaign = async (id) => {
    await campaign.completeCampaign(id);
    await campaign.getCampaignId(id);
    (await this.props.getContacts) && this.props.getContacts();
    campaign.getCampaignExecutionsStatistics(id);
  };

  actionHandler = async ({ type, id }) => {
    this.setState({ modalOpen: false });
    switch (type) {
      case "1.2":
        await this.completeCampaign(id);
        this.setState({ modalData: null });
        break;
      case "1.5":
        await this.startCampaign(id);
        this.setState({ modalData: null });
        break;
      default:
        this.setState({ modalData: null });
    }
  };

  startHandler = async () => {
    try {
      if (this.props.campaign.information?.[0].value === "Stopped") {
        return await this.startCampaign(this.props.campaign.id);
      }

      app.changeTableContentLoaderStatus(true);
      const preview = await apiCampaign.getPreviewOfStartupCampaing(
        this.props.campaign.id
      );

      if (!preview.data.validation_rules_summary.length) {
        this.setState({
          modalOpen: true,
          modalData: {
            type: "1.5",
            id: this.props.campaign.id,
            data: preview.data,
          },
        });
      } else {
        this.setState({
          confirmationDialog: {
            id: this.props.campaign.id,
            status: true,
            data: preview.data,
          },
        });
      }
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      app.changeTableContentLoaderStatus(false);
    }
  };

  render() {
    return (
      <div
        className={
          this.props.tableContentLoader
            ? "d-segment-btn-group loading"
            : "d-segment-btn-group"
        }
      >
        {this.props.tableContentLoader ? (
          <div className="d-segment-btn-group__loader">
            <CircularProgress
              color="primary"
              size={20}
              classes={{ colorPrimary: "material-loader" }}
            />
          </div>
        ) : (
          <>
            {this.props.campaign.events.complete.enabled && (
              <TooltipCustom
                title={this.props.campaign.events.complete.hint}
                arrow
              >
                <button
                  className="btn-stop"
                  onClick={() =>
                    this.setState({
                      modalOpen: true,
                      modalData: { type: "1.2", id: this.props.campaign.id },
                    })
                  }
                >
                  <img src={stopIcon} alt="stopIcon" />
                  {this.props.campaign.events.complete.title}
                </button>
              </TooltipCustom>
            )}
            {this.props.campaign.events.stop.enabled && (
              <TooltipCustom title={this.props.campaign.events.stop.hint} arrow>
                <button
                  className="btn-start btn-pause"
                  onClick={() => this.stopCampaign(this.props.campaign.id)}
                >
                  <img src={pauseIcon} alt="pauseIcon" />
                  {this.props.campaign.events.stop.title}
                </button>
              </TooltipCustom>
            )}
            {this.props.campaign.events.run.enabled && (
              <div className="hint-tooltip-wrapper">
                <TooltipCustom
                  title={this.props.campaign.events.run.hint}
                  arrow={true}
                >
                  <button
                    className="btn-start"
                    disabled={
                      this.props.campaign.count === 0 ||
                      this.props.campaign.actions.length === 0
                    }
                    onClick={this.startHandler}
                  >
                    <img src={startIcon} alt="startIcon" />
                    {this.props.campaign.events.run.title}
                  </button>
                </TooltipCustom>
                <HintTooltip
                  placement="bottom-end"
                  classes="start-camping-tooltip"
                  enabled={
                    functions.isValidOnBoardingConditions(
                      this.props.appProfile,
                      "2.7"
                    ) && !localStorage.getItem("2.7")
                  }
                  tooltip={CONSTS.ON_BOARDING["2.7"]}
                  handlerClose={() => {
                    localStorage.setItem("2.7", "true");
                  }}
                />
              </div>
            )}
            {this.props.edit && (
              <button
                className="btn-edit"
                onClick={this.clickOnEditAction}
              ></button>
            )}
          </>
        )}
        <ModalWindow
          open={this.state.modalOpen}
          closeText="No"
          confirmText="Yes"
          closeHandler={() => this.setState({ modalOpen: false })}
          confirmHandler={() => this.actionHandler(this.state.modalData)}
          title={
            this.state?.modalData
              ? CONSTS.confirmationDialogs[this.state.modalData?.type].title
              : ""
          }
          content={
            this.state.modalData
              ? CONSTS.confirmationDialogs[
                  this.state.modalData?.type
                ].content.replace(
                  "{contacts_count}",
                  this.state.modalData?.data?.valid_audience_count
                )
              : ""
          }
        />

        <ModalWindow
          open={this.state.confirmationDialog.status}
          closeHandler={() =>
            this.setState({
              confirmationDialog: { id: null, status: false, data: {} },
            })
          }
          classes={"confirmation-launch-modal"}
        >
          <ConfirmationDialog
            confirmationDialog={this.state.confirmationDialog}
            closeHandler={() =>
              this.setState({
                confirmationDialog: { id: null, status: false, data: {} },
              })
            }
            startCampaign={this.startCampaign}
          />
        </ModalWindow>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    tableContentLoader: state.app.tableContentLoader,
    campaign: state.campaign,
    appProfile: state.app.appProfile,
  };
};

export default connect(mapStateToProps)(withRouter(SegmentButtonGroup));
