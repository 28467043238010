import { functions } from "../tools/functions";
import _ from "lodash";
import { CONSTS } from "../config/objectConst";

export const mapping = {
  buildCampaignsArray: (array) => {
    return array.map((item) => {
      return {
        id: item.id,
        name: item.name,
        count: item.target_audience_count,
        statistics: item.statistics,
        email: item?.owner?.email,
        avatar: item?.owner?.linkedin_profile?.picture_url,
        entityId: item?.owner?.linkedin_profile?.linkedin_entity_id,
        nickname: item?.owner?.nickname,
        personaId: item?.owner?.persona_id,
        publicIdentifier:
          item?.owner?.linkedin_profile?.linkedin_public_identifier,
        linkedin_profile: item?.owner?.linkedin_profile,
        created: item.created,
        target_audience_count: item.target_audience_count,
        in_queue_count: item.in_queue_count,
        archive: item.archived,
        allowEditSteps: !item.archived && item.state !== "Completed",
        allowAddSteps: !item.archived && item.state === "Compose",
        events: item.actions,
        complete_reason: item.complete_reason ? item.complete_reason : false,
        pause_reason: item.pause_reason ? item.pause_reason : "",
        information: [
          {
            id: 1,
            label: "Status:",
            value: mapping.initStatusValue(item.state, item.complete_reason),
            color: mapping.initColorValue(
              mapping.initStatusValue(item.state, item.complete_reason)
            ),
            pause_reason: item.pause_reason ? item.pause_reason : "",
          },
          {
            id: 2,
            label: "Contacts:",
            value: item.target_audience_count,
          },
          {
            id: 3,
            label: "Start date:",
            value:
              item.hasOwnProperty("started") && item.started
                ? mapping.initDateValue(new Date(item.started))
                : "", //: mapping.initDateValue(new Date(item.created)),
          },
          {
            id: 4,
            label: "End date:",
            value:
              item.hasOwnProperty("completed") && item.completed
                ? mapping.initDateValue(new Date(item.completed))
                : "", //: mapping.initDateValue(new Date(item.created)),
          },
        ],
        completed_date: item.completed ? new Date(item.completed) : false,
        actions:
          item.steps.length > 0 ? mapping.buildCampaignsSteps(item.steps) : [],
      };
    });
  },
  buildCampaignsStatistics(object) {
    let {
      not_started,
      in_progress,
      succeed,
      ignored,
      cancelled,
      skipped,
      failed,
    } = {
      ...object,
    };

    return {
      not_started: not_started,
      in_progress: in_progress,
      succeed: succeed,
      ignored: ignored,
      cancelled: cancelled,
      skipped: skipped,
      failed: failed,
    };
  },
  buildActionStatistics(object) {
    let {
      not_started,
      succeed,
      cancelled,
      in_progress,
      ignored,
      skipped,
      failed,
    } = {
      ...object,
    };
    return {
      statistics: {
        not_started: not_started,
        succeed: succeed,
        cancelled: cancelled,
        in_progress: in_progress,
        ignored: ignored,
        skipped: skipped,
        failed: failed,
      },
    };
  },

  activeName(type) {
    switch (type) {
      case CONSTS.AVAILABLE_ACTIONS.Connect.Type:
        return CONSTS.AVAILABLE_ACTIONS.Connect.Title;

      case CONSTS.AVAILABLE_ACTIONS.Message.Type:
        return CONSTS.AVAILABLE_ACTIONS.Message.Title;

      case CONSTS.AVAILABLE_ACTIONS.VisitProfile.Type:
        return CONSTS.AVAILABLE_ACTIONS.VisitProfile.Title;

      case CONSTS.AVAILABLE_ACTIONS.Follow.Type:
        return CONSTS.AVAILABLE_ACTIONS.Follow.Title;

      case CONSTS.AVAILABLE_ACTIONS.ReactOnPost.Type:
        return CONSTS.AVAILABLE_ACTIONS.ReactOnPost.Title;

      case CONSTS.AVAILABLE_ACTIONS.EndorseSkill.Type:
        return CONSTS.AVAILABLE_ACTIONS.EndorseSkill.Title;

      case CONSTS.AVAILABLE_ACTIONS.ScrapeProfile.Type:
        return CONSTS.AVAILABLE_ACTIONS.ScrapeProfile.Title;

      default:
        throw new Error(`Not supported action type ${type}`);
    }
  },
  buildCampaignsSteps(steps) {
    const campaignSteps = steps.map((item, index) => {
      if (item.type !== "Delay") {
        return {
          id: index + 1,
          name: mapping.activeName(item.type),
          type: item.type,
          mainCustomFields:
            item.type === "ReactOnPost" && CONSTS.MAIN_CUSTOM_FIELDS,
          index: `Action_${index + 1}`,
          information: [],
          arrow:
            item.type === CONSTS.AVAILABLE_ACTIONS.Connect.Type ||
            item.type === CONSTS.AVAILABLE_ACTIONS.Message.Type ||
            item.type === CONSTS.AVAILABLE_ACTIONS.ReactOnPost.Type,
          open: false,
          params: {
            launchActionIn: false,
            mainCustomFields:
              item.type !== "ReactOnPost" && CONSTS.MAIN_CUSTOM_FIELDS,
            overflowCustomFields:
              item.type !== "ReactOnPost" && CONSTS.OVERFLOW_CUSTOM_FIELDS,
            currentEmotion: item.type === "ReactOnPost" && item.reaction,
            emotion: item.type === "ReactOnPost" && CONSTS.EMOTION,
            textarea: item.type !== "ReactOnPost" && String,
            period: item.type !== "ReactOnPost" ? Number : false,
            sendMessageTo: false,
          },
          values: {
            launchActionIn: 0,
            mainCustomFields: CONSTS.MAIN_CUSTOM_FIELDS,
            overflowCustomFields: CONSTS.OVERFLOW_CUSTOM_FIELDS,
            textarea: item.message_template,
            period: item.timeout,
            currentEmotion: item.type === "ReactOnPost" && item.reaction,
            sendMessageTo: "",
            delay: 0,
          },
          validation: {
            actionName: true,
            textarea: true,
          },
          status: false,
        };
      }
      return false;
    });

    let connectStep = _.find(campaignSteps, {
      type: CONSTS.AVAILABLE_ACTIONS.Connect.Type,
    });
    if (connectStep) {
      let delayStep = _.find(steps, { type: "Delay" });
      connectStep.values.delay = delayStep
        ? delayStep.timeout
        : CONSTS.DEFAULT_DATE_ACTIONS_VALUE.CONNECT.DELAY.DEFAULT;
    }
    return campaignSteps.filter((i) => i);
  },

  campaignsForTable(array) {
    let newArray = [];
    array.map((item) => {
      return newArray.push({
        id: item.id,
        name: item.name,
        status: _.find(item.information, ["id", 1]).value,
        steps: item.actions,
        qtyContacts: _.find(item.information, ["id", 2]).value,
        events: item.events,
        complete_reason: item.complete_reason,
        statistics: item.statistics,
        nickname: item.nickname ? item.nickname : "",
        personaId: item.personaId ? item.personaId : "",
        email: item.email ? item.email : "",
        avatar: item.avatar ? item.avatar : "",
        entityId: item.entityId ? item.entityId : "",
        publicIdentifier: item.publicIdentifier ? item.publicIdentifier : "",
        linkedin_profile: item.linkedin_profile ? item.linkedin_profile : "",
        target_audience_count: item.target_audience_count,
        pause_reason: item.pause_reason ? item.pause_reason : "",
        in_queue_count: item.in_queue_count,
        created: item.created,
        date: [
          _.find(item.information, ["id", 3]).value,
          item.completed_date ? item.completed_date : false,
        ],
      });
    });
    return newArray;
  },
  buildRecentImports: (array) => {
    const newArray = array.map((item) => {
      const newItem = {};
      newItem.id = item.id;
      newItem.name = item.name;
      return newItem;
    });
    return newArray;
  },
  buildContacts: (array) => {
    const newArray = array.map((item) => {
      const newItem = {};
      newItem.actions = item.actions;
      newItem.id = item.id;
      newItem.date = new Date(item.created);
      newItem.avatar = item.picture_url;
      newItem.fullName = item.full_name
        ? item.full_name
        : item.linkedin_public_identifier;
      newItem.position = item.occupation;
      newItem.account = item.is_premium;
      newItem.connection = item.distance;
      newItem.entityId = item.linkedin_entity_id;
      newItem.publicIdentifier = item.linkedin_public_identifier;
      newItem.location = item.location;
      newItem.lastJob = item.last_job;
      newItem.created = item.created;
      newItem.contacted = item.contacted;

      return newItem;
    });
    return newArray;
  },
  buildPersonas: (array) => {
    const newArray = array.map((item) => {
      const newItem = {};
      newItem.email = item.email;
      newItem.id = item.persona_id;
      newItem.role = item.role;
      newItem.nickname = item.nickname;
      newItem.avatar = item?.linkedin_profile?.picture_url;
      newItem.pending = item.pending;
      newItem.added = item.added;
      newItem.entityId = item?.linkedin_profile?.linkedin_entity_id;
      newItem.publicIdentifier =
        item?.linkedin_profile?.linkedin_public_identifier;
      newItem.linkedin_profile = item?.linkedin_profile;
      newItem.subscription = item.subscription;
      return newItem;
    });
    return newArray;
  },
  buildActivity: (array) => {
    const newArray = array.map((item, index) => {
      const { campaign, execution } = item;

      let indexAction = _.find(execution.step_executions, { type: "Delay" })
        ? item.step_number > 0
          ? item.step_number
          : 1
        : item.step_number + 1;
      let header =
        item.operation_type === CONSTS.AVAILABLE_ACTIONS.Connect.Type
          ? "Note message"
          : CONSTS.AVAILABLE_ACTIONS.Message.Title;
      return {
        id: index,
        contact_id: execution.linkedin_contact.id,
        fullName: execution.linkedin_contact.full_name
          ? execution.linkedin_contact.full_name
          : execution.linkedin_contact.linkedin_public_identifier,
        campaign_name: `${campaign.name}`,
        campaign_id: campaign.id,
        activity: mapping.activeName(item.operation_type),
        campaignExecutions: execution.step_executions,
        start_in: item.run_in_minutes,
        state: execution.state,
        human_state: execution.human_state,
        actions: item.actions,
        indexAction: indexAction,
        campaignExecutionId: execution.id,
        stepNumber: item.step_number,
        message: item.message,
        header: header,
        conversation_url: execution.conversation_url,
        avatar: execution.linkedin_contact.picture_url,
        entityId: execution.linkedin_contact.linkedin_entity_id,
        publicIdentifier: execution.linkedin_contact.linkedin_public_identifier,
      };
    });
    return newArray;
  },
  buildCampaignContacts: (contacts, executions) => {
    const newArray = contacts.map((item) => {
      const newItem = {};
      newItem.id = item.id;
      newItem.date = new Date(item.created);
      newItem.avatar = item.picture_url;
      newItem.fullName = item.full_name;

      newItem.position = item.occupation;
      newItem.account = item.is_premium;
      newItem.connection = item.distance;
      newItem.campaignExecutions = {};
      newItem.entityId = item.linkedin_entity_id;
      newItem.publicIdentifier = item.linkedin_public_identifier;
      newItem.lastJob = item.linkedin_contact.last_job;
      newItem.contacted = item.linkedin_contact.contacted;
      newItem.location = item.linkedin_contact.location;

      if (executions.length > 0) {
        newItem.campaignExecutions = executions.find(
          (elem) => elem.id === item.id
        );
      }
      return newItem;
    });
    return newArray;
  },
  buildOption: (array, type) => {
    const newArray = array.map((item, index) => {
      const newItem = {};
      switch (type) {
        case "locations":
          newItem.key = index + 1;
          newItem.text = item.key;
          newItem.value = item.key;
          break;
        case "campaigns":
          newItem.key = index + 1;
          newItem.text = item.name;
          newItem.value = item.id;
          newItem.color = mapping.initColorValue(
            mapping.initStatusValue(item.state, item.complete_reason)
          );
          break;

        default:
          break;
      }
      return newItem;
    });
    return newArray;
  },
  initStatusValue: (state, complete_reason) => {
    if (
      state === "Completed" &&
      (complete_reason === "User" || complete_reason === "System")
    ) {
      return "Completed";
    }

    return state;
  },

  initDateValue: (value) => {
    return _.isDate(value)
      ? functions.parseDateToDMY(value, "/") +
          " " +
          functions.parseTimeToHM(value)
      : String(value);
  },

  initColorValue: (status) => {
    switch (status) {
      case "Compose":
        return "#B3B6BF";

      case "Running":
        return "#04c300";

      case "Stopped":
        return "#ffcd50";

      case "Completed":
        return "#2c82e4";
      default:
        break;
    }
  },
};
