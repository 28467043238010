export const errors = {
  build: (error) => {
    switch (error.name) {
      case "OldExtensionError":
        return "Please download new extension version";
      case "BadGatewayError":
      case "RequestTimedOutError":
        return "Bad network or service is temporarily unavailable. Please, try again later.";

      default:
        return error.message ? error.message : error.name;
    }
  },
};
