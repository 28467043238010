import React from "react";
import InputMask from "react-input-mask";
import "./style.scss";

const ClockIcon = ({ disabled }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M8 0C6.55556 0 5.20833 0.368056 3.95833 1.10417C2.75 1.79861 1.79861 2.75 1.10417 3.95833C0.368056
       5.20833 0 6.55556 0 8C0 9.44444 0.368056 10.7917 1.10417 12.0417C1.79861 13.25 2.75 14.2083 3.95833
       14.9167C5.20833 15.6389 6.55556 16 8 16C9.44444 16 10.7917 15.6389 12.0417 14.9167C13.25 14.2083
       14.2083 13.25 14.9167 12.0417C15.6389 10.7917 16 9.44444 16 8C16 6.55556 15.6389 5.20833 14.9167
       3.95833C14.2083 2.75 13.25 1.79861 12.0417 1.10417C10.7917 0.368056 9.44444 0 8 0ZM8 1.33333C9.20833
       1.33333 10.3333 1.63889 11.375 2.25C12.375 2.83333 13.1667 3.625 13.75 4.625C14.3611 5.66667 14.6667
       6.79167 14.6667 8C14.6667 9.20833 14.3611 10.3333 13.75 11.375C13.1667 12.375 12.375 13.1667 11.375
       13.75C10.3333 14.3611 9.20833 14.6667 8 14.6667C6.79167 14.6667 5.66667 14.3611 4.625 13.75C3.625 13.1667
        2.83333 12.375 2.25 11.375C1.63889 10.3333 1.33333 9.20833 1.33333 8C1.33333 6.79167 1.63889 5.66667 2.25
         4.625C2.83333 3.625 3.625 2.83333 4.625 2.25C5.66667 1.63889 6.79167 1.33333 8 1.33333ZM7.33333
          2.66667V8.66667H12V7.33333H8.66667V2.66667H7.33333Z"
        fill={disabled ? "#B3B6BF" : "#016069"}
      />
    </svg>
  );
};

const DateInput = ({
  disabled,
  day,
  handleDateChange,
  errorMessage,
  value,
}) => {
  const dateHandler = (e) => {
    const value = e.target.value;
    handleDateChange(day, value);
  };

  return (
    <div className={`date-input ${disabled ? "date-input-disabled" : ""}`}>
      <div className="date-input__wrapper">
        <InputMask
          mask="99:99-99:99"
          value={value}
          disabled={disabled}
          placeholder="Enter working hours"
          onChange={dateHandler}
        />
        <ClockIcon disabled={disabled} />
      </div>
      {errorMessage.length && !disabled ? (
        <div className="date-input__error">{errorMessage}</div>
      ) : null}
    </div>
  );
};

export default DateInput;
