import store from "../redux/store";

function getConnector(state) {
  return state.app.connector;
}

export const listeners = {
  getConnector: () => {
    let connector = getConnector(store.getState());
    return connector;
  },
};
