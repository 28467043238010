import React, { Component } from "react";
import "./style.scss";

export class ErrorHelper extends Component {
  render() {
    return (
      <div className="error-helper">
        <div className="error-helper__icon"></div>
        <div className="error-helper__text">{this.props.children}</div>
      </div>
    );
  }
}

export default ErrorHelper;
