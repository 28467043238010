import React, { Component } from "react";
import TextFieldCustom from "../../atoms/TextFieldCustom";
import iconSearch from "../../../assets/image/icons/svg/search-icon-grey.svg";
import "./style.scss";
import Tag from "../../../components/atoms/Tag";
import _ from "lodash";
import { CONSTS } from "../../../config/objectConst";
import { teams } from "../../../actions/teams";

class CampaignFiltersTeams extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: "",
      filters: {
        active: false,
        stopped: false,
        completed: false,
        drafted: false,
        cancelled: false,
      },
    };
  }

  onSearchChange = (e) => {
    teams.searchValueTeams(e.target.value);
    this.setState({ searchValue: e.target.value });
  };

  onSearchEnter = async (e) => {
    this.props.setLoader(true);
    this.props.setPage(0);

    this.props.getCampaignsTeam();
    this.setState({ searchValue: "" });
  };

  render() {
    const tagsArray = this.props.initialFilters.filter(
      (item) => item.value && item.value.length > 0
    );
    return (
      <div
        className={`filter-navigation-teams ${
          this.props.filtersController ? "open" : ""
        }`}
      >
        <div className="table-container-controllers-teams">
          <div className="btn-group">
            <div className="filter-search-teams">
              {this.props.filtersController === false && (
                <div className="search-teams">
                  <TextFieldCustom
                    value={this.props.searchValue}
                    onChange={this.onSearchChange}
                    onEnter={this.onSearchEnter}
                    placeholder={"Search"}
                    endAdornment={
                      <img
                        className="icon-search"
                        src={iconSearch}
                        alt="search"
                        onClick={this.onSearchEnter}
                      />
                    }
                  />
                </div>
              )}
              <button
                type="button"
                className="btn-text-icon filter-icon"
                onClick={this.props.filtersControllerToggle}
              >
                Filters
              </button>
              {!this.props.filtersController && (
                <div className="__tags-wrap">
                  <div className="filter-navigation__tags">
                    {tagsArray.map((item, index) => {
                      if (index <= 2) {
                        return (
                          <Tag
                            key={item.id}
                            type="small"
                            text={
                              item.name === "Show Campaigns"
                                ? item.value === "in_queue_count gt 0"
                                  ? "Has contacts in queue"
                                  : "No contacts in queue"
                                : item.value.length === 1
                                ? item.value[0].text
                                : `${item.name} (${item.value.length}) `
                            }
                            onClick={() => this.props.clearFilter(item.id)}
                          />
                        );
                      }
                      return "";
                    })}
                    {tagsArray.length > 3 &&
                      this.props.showAll &&
                      tagsArray.map((item, index) => {
                        if (index >= 3) {
                          return (
                            <Tag
                              key={item.id}
                              type="small"
                              text={
                                item.name === "Show Campaigns"
                                  ? item.value === "in_queue_count gt 0"
                                    ? "Has contacts in queue"
                                    : "No contacts in queue"
                                  : item.value.length === 1
                                  ? item.value[0].text
                                  : `${item.name} (${item.value.length}) `
                              }
                              onClick={() => {
                                this.clearValue(item.id, item);
                              }}
                            />
                          );
                        }
                        return "";
                      })}
                  </div>
                  <div className="filter-navigation__controls">
                    {tagsArray.length > 2 && !this.props.showAll && (
                      <>
                        <button
                          type="button"
                          className="filter-navigation__button"
                          onClick={() => this.props.setShowAll(true)}
                        >
                          Show more
                        </button>
                        <div className="vertical-divider"></div>
                      </>
                    )}
                    {tagsArray.length > 0 && (
                      <button
                        type="button"
                        className="filter-navigation__button"
                        onClick={this.props.clearAllFilters}
                      >
                        Clear all
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CampaignFiltersTeams;
