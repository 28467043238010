/*
 *
 * Filters reducer
 *
 */

export const initialFilterData = [
  {
    id: 1,
    name: "connection",
    exclude: false,
    search: false,
    value: [],
    tag: "",
  },
  {
    id: 2,
    name: "location",
    exclude: false,
    search: false,
    value: [],
    tag: "",
  },
  {
    id: 3,
    name: "industry",
    exclude: false,
    search: false,
    value: [],
    tag: "",
  },
  {
    id: 4,
    name: "keywords",
    exclude: false,
    search: true,
    value: [],
    tag: "",
  },
  {
    id: 5,
    name: "company",
    exclude: false,
    search: false,
    value: [],
    tag: "",
  },
  {
    id: 6,
    selectId: 9,
    name: "contacted",
    exclude: false,
    search: false,
    value: [
      { id: 1, value: null },
      { id: 2, value: null },
    ],
    tag: "",
  },
  {
    id: 7,
    name: "status",
    exclude: false,
    search: false,
    value: "",
    tag: "",
  },
  {
    id: 8,
    name: "createdSelect",
    exclude: false,
    search: false,
    value: null,
  },
  {
    id: 9,
    name: "contactedSelect",
    exclude: false,
    search: false,
    value: null,
  },
  {
    id: 10,
    name: "campaigns",
    exclude: false,
    search: false,
    value: [],
    tag: "",
  },
  {
    id: 11,
    name: "exclude contactedSelect",
    exclude: false,
    search: false,
    value: null,
  },
  {
    id: 12,
    name: "NOT: keywords",
    exclude: true,
    search: true,
    value: [],
    tag: "",
  },
  {
    id: 13,
    name: "NOT: campaigns",
    exclude: true,
    search: false,
    value: [],
    tag: "",
  },
  {
    id: 14,
    selectId: 11,
    name: "NOT: contacted",
    exclude: true,
    search: false,
    value: [
      { id: 1, value: null },
      { id: 2, value: null },
    ],
    tag: "",
  },
  {
    id: 15,
    selectId: 8,
    name: "created",
    exclude: false,
    search: false,
    value: [
      { id: 1, value: null },
      { id: 2, value: null },
    ],
    tag: "",
  },
];

const initialState = {
  filterData: [...initialFilterData],
  filterDataForModal: [...initialFilterData],
  options: {
    colourOptions: [
      { key: 1, text: "1st", value: 1 },
      { key: 2, text: "2nd", value: 2 },
      { key: 3, text: "3rd+", value: 3 },
    ],
    location: [],
    industry: [
      { key: 1, text: "Design", value: "Design" },
      { key: 2, text: "Development", value: "Development" },
      { key: 3, text: "Management", value: "Management" },
    ],
    keywords: [
      { key: 1, text: "CEO", value: "CEO" },
      { key: 2, text: "Web Design", value: "Web Design" },
      { key: 3, text: "UX Design", value: "UX Design" },
    ],
    contacted: [
      { key: 1, text: "Last day", value: "day" },
      { key: 2, text: "Last week", value: "week" },
      { key: 3, text: "Last month", value: "month" },
      { key: 4, text: "Last year", value: "year" },
      { key: 5, text: "Custom", value: "custom" },
    ],
    campaigns: [],
    status: [
      { key: 1, text: "All", value: "" },
      { key: 2, text: "Premium only", value: "premium" },
    ],
  },
  filtersQuery: false,
  filtersQueryForModal: false,
  searchQuery: false,
  searchQueryForModal: false,
  stringParams: "",
};

const filters = (state = initialState, action) => {
  switch (action.type) {
    case "FILTERS_PARAMS":
      return Object.assign({}, state, {
        filterData: action.payload.filterData,
      });
    case "FILTERS_PARAMS_FOR_MODAL":
      return Object.assign({}, state, {
        filterDataForModal: action.payload.filterDataForModal,
      });
    case "OPTIONS_PARAMS":
      return Object.assign({}, state, {
        options: { ...state.options, ...action.payload },
      });
    case "FILTERS_QUERY":
      return Object.assign({}, state, {
        filtersQuery: action.payload.filtersQuery,
      });
    case "FILTERS_QUERY_FOR_MODAL":
      return Object.assign({}, state, {
        filtersQueryForModal: action.payload.filtersQueryForModal,
      });
    case "FILTERS_SEARCH_QUERY":
      return Object.assign({}, state, {
        searchQuery: action.payload.searchQuery,
      });
    case "FILTERS_QUERY_CLEAR":
      return Object.assign({}, state, {
        searchQuery: "",
        filtersQuery: "",
      });
    case "FILTERS_SEARCH_QUERY_FOR_MODAL":
      return Object.assign({}, state, {
        searchQueryForModal: action.payload.searchQueryForModal,
      });
    case "FILTERS_STRING_PARAMS":
      return Object.assign({}, state, {
        stringParams: action.payload.stringParams,
      });

    default:
      return state;
  }
};

export default filters;
