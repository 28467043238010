import { CONSTS } from "../../config/objectConst";
import { httpRequest } from "../httpRequest";
import store from "../../redux/store";
import { mapping } from "../../actions/mapping";

export const apiActivity = {
  getActivities: async (data) => {
    let { take } = { ...data };
    const params = `?${take ? "&$take=" + take : ""}`;
    try {
      let response = await httpRequest({
        url: `${CONSTS.API.URL}workspace/activities-queue${params}`,
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (response.data) {
        store.dispatch({
          type: "ALL_ACTIVITIES",
          payload: {
            activity: response.data
              ? mapping.buildActivity(response.data.documents)
              : [],

            total: response.data.total,
          },
        });
      }

      if (!response.data) {
        store.dispatch({
          type: "ALL_ACTIVITIES",
          payload: {
            activity: [],
            total: -1,
          },
        });
      }
    } catch (error) {
      store.dispatch({
        type: "ALL_ACTIVITIES",
        payload: {
          activity: [],
          total: -1,
        },
      });
      throw error;
    }
  },

  getStepMessage: async (campaignExecutionId, stepNumber) => {
    return await httpRequest({
      url: `${CONSTS.API.URL}executions/${campaignExecutionId}/steps/${stepNumber}/message`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
};
