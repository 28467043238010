import React, { Component } from "react";
import _ from "lodash";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";
import { CircularProgress } from "@material-ui/core";
import { CONSTS } from "../../../config/objectConst";
import Select from "react-select";
import linkSvgGold from "../../../assets/image/icons/tables/goldLink.svg";

// Components
import MTablePagination from "../../atoms/mTablePagination";
import ButtonCreateCampaigns from "../../atoms/buttonCreateCampaigns";
import BtnGroupContainer from "./btn-group";
import TooltipCustom from "../../atoms/tooltipCustom";
import { notification } from "../../../actions/notification";
import ModalWindow from "../ModalWindow";
import ExportToCSVButton from "../../atoms/ExportToCSVButton";
// actions and functions
import { functions, saveData } from "../../../tools/functions";
import { campaign } from "../../../actions/campaign";
import { contacts } from "../../../actions/contacts";
import { app } from "../../../actions/app";
import { api } from "../../../api/api";
import { apiCampaign } from "../../../api/campaign/apiCampaign";
import ConfirmationDialogExclude from "../../atoms/ConfirmationDialogExclude";
import ConfirmationDialogContacts from "../../atoms/ConfirmationDialogContacts";

// icons
import notSelectedCheckboxIcon from "../../../assets/image/icons/tables/checkbox.svg";
import selectedCheckboxIcon from "../../../assets/image/icons/tables/checkbox2.svg";
import partiallySelectedCheckboxIcon from "../../../assets/image/icons/tables/checkbox3.svg";
import iconRemove from "../../../assets/image/icons/tables/remove.svg";
import iconRemoveDisabled from "../../../assets/image/icons/tables/remove-disabled.svg";
import iconExclude from "../../../assets/image/icons/tables/exclude.svg";
import iconExcludeDisabled from "../../../assets/image/icons/tables/exclude-disabled.svg";
import inIcon from "../../../assets/image/icons/tables/in.svg";
import inIconGray from "../../../assets/image/icons/tables/linkedin-icon-gray.svg";
import SortIcon from "./sortIcons.js";
import { contactsPageSize } from "../../../api/contactsPageSize";
import avatarSvg from "../../../assets/image/icons/svg/user.svg";
// Style
import "./style.scss";
import { connect } from "react-redux";
import ResponsiveButton from "../../atoms/responsiveButton";
import { linkedInProfileOpener } from "../../../api/linkedInProfileOpener";
import { statesDescriptor } from "../../../api/statesDescriptor";

export class TableUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactsOnPage: [],
      selected: [],
      selectedOnPage: [],
      filterRows: [],
      dropDownController: false,
      selectAllUsersValue: false,
      selectedValues: 0,
      isExcludeContactsOpened: false,
      isDeleteContactsOpened: false,
      optionsSizePagination: [],
      initialValuePagination: contactsPageSize.get(),
      isOpenMenuPagination: false,
      scrollBarWidth: false,
      isLoadingCSV: false,
      isLoadingCSVCampaigns: false,
      isLoadingInteractionLog: false,
      isRunningCampaigns:
        this.props.type === "single" && this.props.campaignStatus !== "Compose",
      confirmationDialog: {
        id: null,
        status: false,
        data: {},
      },
      confirmationDialogContacts: {
        status: false,
        data: {},
      },

      needLoader: false,
      isDelete: false,
      isCampaignsRunning:
        this.props.campaignStatus === "Running" ||
        this.props.campaignStatus === "Stopped" ||
        this.props.campaignStatus === "Completed",
    };
    this.wrapper = React.createRef();
  }

  componentDidMount() {
    this.setState({
      optionsSizePagination: [
        { value: "10", label: "Show 10 items" },
        { value: "25", label: "Show 25 items" },
        { value: "50", label: "Show 50 items" },
        { value: "100", label: "Show 100 items" },
      ],
    });
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps, prevState) {
    const scrollbarWidth =
      this.wrapper.current.offsetWidth - this.wrapper.current.clientWidth;

    if (scrollbarWidth > 0 && this.state.scrollBarWidth === false) {
      this.setState({ scrollBarWidth: true });
    }

    if (scrollbarWidth === 0 && this.state.scrollBarWidth === true) {
      this.setState({ scrollBarWidth: false });
    }

    if (this.props.match.url !== prevProps.match.url) {
      this.props.dispatch({
        type: "FILTERS_QUERY_CLEAR",
      });
    }
    if (
      this.props.isFiltering !== prevProps.isFiltering &&
      this.type !== "add-to-campaign"
    ) {
      this.getContactsBySort();
    }
    if (
      this.props.isFilteringExecutions !== prevProps.isFilteringExecutions &&
      this.type !== "add-to-campaign"
    ) {
      this.getContactsBySort();
    }
    if (
      this.state.initialValuePagination !== prevState.initialValuePagination
    ) {
      this.handleChangePage();
    }
  }
  openLinkedInProfile = async (row) => {
    await linkedInProfileOpener.open(this.props.app.connector, row);
  };
  stylePagination = {
    control: (base, state) => {
      return {
        ...base,
        "&:hover": {
          border: this.state.isOpenMenuPagination
            ? "1px solid #0cb39f"
            : "1px solid  #EBEDF2",
        },

        border: this.state.isOpenMenuPagination
          ? "1px solid #0cb39f"
          : "1px solid  #EBEDF2",
        boxShadow: "",
        fontWeight: 400,
        fontSize: 14,
        background: "#FFF",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        opacity: "1",
        color: "#202020",
        width: "150px",
        cursor: "pointer",
        minHeight: "32px",
      };
    },
    indicatorsContainer: (base) => ({
      ...base,

      borderLeft: this.state.isOpenMenuPagination
        ? "1px solid #0cb39f"
        : "1px solid  #EBEDF2",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      backgroundColor: "transparent",
      paddingTop: "5px",
      paddingBottom: "5px",
    }),
    singleValue: (base) => ({
      ...base,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: "5px   3px",
      height: "32px",
      color: "#202020",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "32px",
    }),
    option: (styles, { isSelected }) => ({
      ...styles,

      fontSize: 14,
      color: "#000",
      backgroundColor: isSelected ? "#F7F7F7" : "transparent",
      "&:active": {
        backgroundColor: "transparent",
      },
      "&:hover": { backgroundColor: "#F7F7F7", cursor: "pointer" },
    }),
    menuList: (base) => ({
      ...base,
      position: "absolute",
      left: "-1px",
      bottom: "34px",
      backgroundColor: "#fff",
      width: "150px",
      border: "1px solid #0cb39f",
      borderBottom: "1px solid transparent",
    }),
    menu: (base) => ({
      ...base,
      boxShadow: "",
      marginTop: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderTop: 0,
    }),
  };
  handleResize = () => {
    const scrollbarWidth =
      this.wrapper.current.offsetWidth - this.wrapper.current.clientWidth;

    if (scrollbarWidth > 0 && this.state.scrollBarWidth === false) {
      this.setState({ scrollBarWidth: true });
    }

    if (scrollbarWidth === 0 && this.state.scrollBarWidth === true) {
      this.setState({ scrollBarWidth: false });
    }
  };

  handleChangePage = (event, newPage) => {
    try {
      contacts.changeContactsPage(newPage);
      if (this.props.match.params.id) {
        if (this.props.type === "add-to-campaign") {
          contacts.getContacts({
            take: this.state.initialValuePagination,
            skip: newPage > 0 ? newPage * this.state.initialValuePagination : 0,
            filter: `not (campaigns/any(c:c eq '${this.props.match.params.id}'))`,
          });
          return;
        }
        if (
          this.props.type === "single" &&
          this.props.campaignStatus === "Compose"
        ) {
          contacts.getContacts(
            {
              take: this.state.initialValuePagination,
              skip:
                newPage > 0 ? newPage * this.state.initialValuePagination : 0,
              filter: `campaigns/any(c:c eq '${this.props.match.params.id}')`,
              orderby: this.props.isFiltering
                ? this.props.isFiltering
                : "imported asc",
            },
            this.props.campaignStatus,
            this.props.match.params.id
          );
          return;
        }
        if (
          this.props.type === "single" &&
          this.props.campaignStatus !== "Compose"
        ) {
          contacts.getContacts(
            {
              take: this.state.initialValuePagination,
              skip:
                newPage > 0 ? newPage * this.state.initialValuePagination : 0,
              filter: this.props.filterQueryExecutions
                ? this.props.filterQueryExecutions
                : [`campaign_id eq '${this.props.match.params.id}'`],
              orderby: this.props.isFilteringExecutions
                ? this.props.isFilteringExecutions
                : "created asc",
              keywords: this.props.keywordsExecutions
                ? this.props.keywordsExecutions
                : "",
            },
            this.props.campaignStatus
          );
          return;
        }
        contacts.getContacts({
          take: this.state.initialValuePagination,
          orderby: this.props.isFiltering
            ? this.props.isFiltering
            : "imported asc",
          skip: newPage > 0 ? newPage * this.state.initialValuePagination : 0,
          filter: this.props.filters.filtersQuery
            ? this.props.filters.filtersQuery
            : `tags/any(c:c eq '${this.props.match.params.id}')`,
          keywords: this.props.filters.searchQuery
            ? this.props.filters.searchQuery
            : this.props.keywords
            ? this.props.keywords
            : "",
        });
      } else {
        contacts.getContacts({
          take: this.state.initialValuePagination,
          skip: newPage > 0 ? newPage * this.state.initialValuePagination : 0,
          filter: this.props.filters.filtersQuery
            ? this.props.filters.filtersQuery
            : "",
          keywords: this.props.keywords ? this.props.keywords : "",
          orderby: this.props.isFiltering ? this.props.isFiltering : "",
        });
      }
      this.setState({
        contactsOnPage: this.getContactsOnPage(newPage),
      });
    } catch (e) {
      notification.notificationUse(e);
    }
  };

  getContactsBySort = () => {
    if (
      this.props.type === "single" &&
      this.props.campaignStatus !== "Compose"
    ) {
      contacts.getContacts({
        status: "Active",
        skip: 0,
        take: this.state.initialValuePagination,
        filter: this.props.filterQueryExecutions
          ? this.props.filterQueryExecutions
          : [`campaign_id eq '${this.props.match.params.id}'`],
        orderby: this.props.isFilteringExecutions.includes("created")
          ? this.props.isFilteringExecutions
          : this.props.isFilteringExecutions,

        keywords: this.props.keywordsExecutions
          ? this.props.keywordsExecutions
          : "",
      });
    }
    if (
      this.props.type === "single" &&
      this.props.campaignStatus === "Compose"
    ) {
      contacts.getContacts({
        take: this.state.initialValuePagination,
        skip: 0,
        filter: `campaigns/any(c:c eq '${this.props.campaignId}')`,
        orderby: this.props.isFiltering,
      });
    }
    if (this?.props?.history?.location?.pathname.includes("/import")) {
      contacts.getContacts({
        take: this.state.initialValuePagination,
        orderby: this.props.isFiltering,
        skip: 0,
        filter: this.props.filters.filtersQuery
          ? this.props.filters.filtersQuery
          : `tags/any(c:c eq '${this.props.match.params.id}')`,
        keywords: this.props.keywords
          ? this.props.keywords
          : ""
          ? this.props.filters.searchQuery
          : "",
      });
    }
    if (this?.props?.history?.location?.pathname === "/contacts") {
      contacts.getContacts({
        take: this.state.initialValuePagination,
        orderby:
          this.props.history.location.pathname === "/contacts" ||
          this.props.history.location.pathname.includes("/campaigns")
            ? this.props.isFiltering
            : "imported asc",
        filter: this?.props?.filters?.filtersQuery
          ? this.props.filters.filtersQuery
          : "",
        keywords: this.props.keywords
          ? this.props.keywords
          : ""
          ? this.props.filters.searchQuery
          : "",
      });
    }
  };

  getContactsOnPage = (page) => {
    /*let newContacts = this.props.contacts.filter(
      (item, index) =>
        this.state.rowsPerPage * page <= index &&
        index < this.state.rowsPerPage * (page + 1)
    );*/
    return this.props.contacts;
  };

  Sort = () => {
    this.setState({
      rows: _.sortBy(this.props.contacts, [
        function (o) {
          return o.name;
        },
      ]),
    });
  };

  Filter = () => {
    this.setState({
      rows: _.filter(this.props.contacts, { status: 1 }),
    });
  };

  handleClick = (event, name) => {
    const selectedIndex = this.state.selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, name);
    } else if (selectedIndex === 0) {
      this.setState({
        selectAllUsersValue: false,
      });
      newSelected = newSelected.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      this.setState({
        selectAllUsersValue: false,
      });
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      this.setState({
        selectAllUsersValue: false,
      });
      newSelected = newSelected.concat(
        this.state.selected.slice(0, selectedIndex),
        this.state.selected.slice(selectedIndex + 1)
      );
    }
    this.setState({
      selected: newSelected,
      selectedValues: newSelected.length,
    });
  };

  isSelected = (name) => this.state.selected.indexOf(name) !== -1;

  onSelectAllPageClick = (event) => {
    if (event.target.checked) {
      let newSelected = this.getContactsOnPage(this.props.page);
      newSelected = newSelected.filter(
        (item) => !this.state.selected.includes(item)
      );
      this.setState({
        selectAllUsersValue: false,
        selectedValues:
          this.props.contactsCount <= this.state.initialValuePagination
            ? this.props.contactsCount
            : newSelected.length,
      });
      this.setState({
        selected: this.state.selected.concat(newSelected).map((item, index) => {
          return item.id;
        }),
      });
      return;
    }
    this.setState({
      selected: [],
      selectedValues: 0,
    });
  };

  onSelectAllClick = () => {
    let newSelecteds = this.props.contacts.map((item) => item.id);
    this.setState({
      selected: newSelecteds,
      selectedValues: this.props.contactsCount,
      selectAllUsersValue: true,
    });
  };

  onClearAllClick = () => {
    this.setState({
      selected: [],
      selectedValues: 0,
      selectAllUsersValue: false,
    });
  };

  clearCheckboxes = () => {
    this.setState({ selected: [] });
  };

  dropDownController = (value) => {
    this.setState({
      dropDownController: value,
    });
  };

  getNewPage = () => {
    let newPage =
      this.props.page * this.state.initialValuePagination >=
        this.props.contactsCount - this.state.initialValuePagination &&
      this.props.page * this.state.initialValuePagination ===
        this.props.contactsCount
        ? this.props.page - 1
        : this.props.page;
    return (newPage = newPage >= 0 ? newPage : 0);
  };

  removeContactsFromCampaign = async (audience, campaignId) => {
    if (this.state.selectAllUsersValue) {
      await campaign.removeAllContactsFromCampaign({
        campaignId,
      });
    } else {
      await campaign.removeContactsFromCampaign({
        campaignId: campaignId,
        filter: `id in (${audience.map((item) => `'${item}'`)})`,
        audience,
      });
    }

    let newPage = this.getNewPage();
    await contacts.getContacts({
      take: this.state.initialValuePagination,
      skip: newPage * this.state.initialValuePagination,
      filter: `campaigns/any(c:c eq '${campaignId}')`,
      orderby: this.props.isFiltering ? this.props.isFiltering : "imported asc",
    });
    this.setState({
      selected: [],
    });
    await campaign.getCampaignId(campaignId);
  };

  deleteContacts = async () => {
    this.setState({ isDeleteContactsOpened: false });
    app.changePageContentLoaderStatus(true);
    try {
      let data = this.createCriteriaForSelectedContacts();
      await api.deleteContacts(data);
      let criteria = {
        take: this.state.initialValuePagination,
        skip: this.props.page * this.state.initialValuePagination,
        filter: this.createFilter(),
        keywords: this.props.filters.searchQuery
          ? this.props.filters.searchQuery
          : "",
        orderby: this.props.isFiltering ? this.props.isFiltering : "",
      };

      let response = await contacts.getContacts(criteria);
      if (
        response.status === 200 &&
        response.data.documents.length === 0 &&
        response.data.total > 0
      ) {
        criteria.skip -= this.state.initialValuePagination;
        response = await contacts.getContacts(criteria);
      }

      if (response.status === 200) {
        notification.snacknotificationUse({
          open: true,
          title: CONSTS.notification["3.20"].title,
          content: CONSTS.notification["3.20"].content,
          style: CONSTS.notification["3.20"].style,
        });
      }
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      this.setState({
        selected: [],
      });
      app.changePageContentLoaderStatus(false);
    }
  };

  previewContacts = async () => {
    try {
      let data = this.createCriteriaForSelectedContacts();
      let preview = await api.previewContacts(data);

      if (preview.status === 200) {
        if (preview.data?.validation_rules_summary.length === 0) {
          this.setState({ isDeleteContactsOpened: true });
        } else {
          return this.setState({
            confirmationDialogContacts: {
              status: true,
              data: preview.data,
            },
          });
        }
        this.getNewPage();
      }
    } catch (error) {
      notification.notificationUse(error);
    } finally {
    }
  };

  removeExecutionsFromCampaign = async (campaignId) => {
    this.props.setIsExcludeLoader(true);
    await apiCampaign.removeExecutionsFromCampaignApi({
      filter:
        this.state.selectAllUsersValue && this.props.filterQueryExecutions
          ? `${this.props.filterQueryExecutions}${
              this.props.keywordsExecutions
                ? "&$searchQuery=" + this.props.keywordsExecutions
                : ""
            }`
          : this.state.selectAllUsersValue
          ? `campaign_id eq '${this.props.campaignId}'${
              this.props.keywordsExecutions
                ? "&$searchQuery=" + this.props.keywordsExecutions
                : ""
            }`
          : `id in (${this.state.selected.map((item) => `'${item}'`)})`,
    });

    let newPage = this.getNewPage();

    await contacts.getContacts({
      take: this.state.initialValuePagination,
      skip: newPage * this.state.initialValuePagination,
      orderby: this.props.isFiltering ? this.props.isFiltering : "created asc",
      status: "Running",
      filter:
        this.props.filterQueryExecutions || this.props.keywordsExecutions
          ? `${
              this.props.filterQueryExecutions
                ? this.props.filterQueryExecutions
                : ""
            }${
              this.props.keywordsExecutions
                ? "&$searchQuery=" + this.props.keywordsExecutions
                : ""
            }`
          : `campaign_id eq '${this.props.campaignId}'`,
    });
    this.setState({
      selected: [],
    });
    this.props.setIsExcludeLoader(false);
    const field = CONSTS.notification["3.16"];
    notification.snacknotificationUse({
      open: true,
      title: field.title,
      content: field.content,
      button: field.button,
      style: field.style,
    });
  };

  upwardController = (index) => {
    if (this.props.contacts.length > 6) {
      if (index > this.props.contacts.length - 3) {
        return true;
      }
    }
    return false;
  };

  handlerCompleteExecutions = async (id) => {
    try {
      app.changeTableContentLoaderStatus(true);
      const response = await api.setExecutionComplete(id);
      if (response.status === 200) {
        const campaignExecutionsId = response.data.id;
        const executionIndex = this.props.contacts.findIndex((item) => {
          return item.campaignExecutions.id === campaignExecutionsId;
        });
        const tempContacts = this.props.contacts;
        tempContacts[executionIndex].campaignExecutions = response.data;
        contacts.cancelExecution(tempContacts);
      }
      await campaign.getCampaignId(this.props.match.params.id);
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      app.changeTableContentLoaderStatus(false);
    }
  };

  createFilter = () => {
    const result =
      this.props.history.location.pathname !== "/contacts"
        ? this.props.filters.filtersQuery
          ? this.props.filters.filtersQuery
          : `tags/any(c:c eq '${this.props.activeImportId}')`
        : this.props.filters.filtersQuery;
    return result;
  };

  createCriteriaForSelectedContacts = () => {
    const selectId = this.state.selected;
    const filterContacts = this.createFilter();
    const filterImport = `id in (${selectId.map((item) => `'${item}'`)})`;
    const result = {
      filter: this.state.selectAllUsersValue ? filterContacts : filterImport,
      keywords: this.props.keywords ? this.props.keywords : "",
      orderby:
        this.props.history.location.pathname === "/contacts"
          ? this.props.isFiltering
          : this.props.isFiltering,
    };
    return result;
  };

  exportContacts = async () => {
    let data = this.createCriteriaForSelectedContacts();
    try {
      this.setState({ isLoadingCSV: true });
      const response = await api.exportContactsApi(data);
      if (response.status === 200) {
        const data = response.data,
          fileName = functions.getFileName();
        saveData(data, fileName);
      }
    } catch (error) {
      notification.notificationUse(error);
    }
    this.setState({
      selected: [],
    });
    this.setState({ isLoadingCSV: false });
  };

  exportContactsForCampaign = async () => {
    const selectId = this.state.selected;
    const filterCompose = this.state.selectAllUsersValue
      ? `campaigns/any(c:c eq '${this.props.match.params.id}')`
      : `id in (${selectId.map((item) => `'${item}'`)})`;
    const filterNoCompose = this.state.selectAllUsersValue
      ? this.props.filterQueryExecutions
        ? this.props.filterQueryExecutions
        : `(campaign_id eq  '${this.props.campaignId}')`
      : `id in (${selectId.map((item) => `'${item}'`)})`;

    const dataCompose = {
      filter: filterCompose,
      orderby: this.props.isFiltering ? this.props.isFiltering : "created asc",
    };
    const dataExecutions = {
      filter: filterNoCompose,
      orderby: this.props.isFilteringExecutions.includes("import")
        ? "created asc"
        : this.props.isFilteringExecutions,
      keywords: this.props.keywordsExecutions
        ? this.props.keywordsExecutions
        : "",
    };

    try {
      this.setState({ isLoadingCSVCampaigns: true });
      const response =
        this.props.campaignStatus === "Compose"
          ? await api.exportContactsApi(dataCompose)
          : await api.exportContactsApiExecutions(dataExecutions);
      if (response.status === 200) {
        const data = response.data,
          fileName = functions.getFileName();
        saveData(data, fileName);
      }
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      this.setState({
        selected: [],
      });
      this.setState({ isLoadingCSVCampaigns: false });
    }
  };

  exportRecentActivities = async () => {
    const selectedIds = this.state.selected;
    const filterExpression = this.state.selectAllUsersValue
      ? this.props.filterQueryExecutions
        ? this.props.filterQueryExecutions
        : `(campaign_id eq  '${this.props.campaignId}')`
      : `id in (${selectedIds.map((item) => `'${item}'`)})`;

    const queryParameters = {
      filter: filterExpression,
      keywords: this.props.keywordsExecutions
        ? this.props.keywordsExecutions
        : "",
    };

    try {
      this.setState({ isLoadingInteractionLog: true });
      const response = await api.exportRecentActivitiesApi(queryParameters);
      if (response.status === 200) {
        const data = response.data,
          fileName = functions.getFileName();
        saveData(data, fileName);
      }
    } catch (error) {
      notification.notificationUse(error);
    } finally {
      this.setState({
        selected: [],
      });
      this.setState({ isLoadingInteractionLog: false });
    }
  };

  buttonsActions = (row, type, index) => {
    const openLinkedinProfile = async () => {
      await linkedInProfileOpener.open(this.props.app.connector, row);
    };

    if (type === "single" || type === "all") {
      if (row.campaignExecutions?.actions) {
        return (
          row.campaignExecutions?.actions && (
            <BtnGroupContainer
              index={index}
              removeContactsFromCampaign={this.removeContactsFromCampaign}
              dropDownController={this.dropDownController}
              dropDownControllerValue={this.state.dropDownController}
              handlerCompleteExecutions={this.handlerCompleteExecutions}
              row={row}
              connector={this.props.app.connector}
              profile={this.props.profile}
              type={type}
              campaignStatus={this.props.campaignStatus}
              campaignId={this.props.campaignId}
              size={this.props.contacts.length}
            />
          )
        );
      }
      if (row.actions?.open_linkedin_profile.enabled) {
        return (
          <div className="btn-group with-linkedin">
            <TooltipCustom
              title={row.actions?.open_linkedin_profile.hint}
              arrow={true}
            >
              <button
                className={
                  row.actions?.open_linkedin_profile.enabled
                    ? "btn-row info"
                    : "btn-row info disabled"
                }
                onClick={openLinkedinProfile}
              >
                <img src={inIconGray} alt="linkedin icon" />
              </button>
            </TooltipCustom>
          </div>
        );
      }
    }
    // Кирилл закоментировал следующий блок , поскольку это старая заглушка, которая может быть случайно показана
    // return (
    //   <div className="btn-group">
    //     <Dropdown
    //       upward={this.upwardController(index)}
    //       className="btn-row btn-drop"
    //       onOpen={() => this.dropDownController(true)}
    //       onClose={() => this.dropDownController(false)}
    //       icon={
    //         <img className="icon" src={infoBlackIcon} alt="infoBlackIcon" />
    //       }
    //     >
    //       <Dropdown.Menu>
    //         <Dropdown.Item>
    //           <div className="drop-down-container" style={{ padding: "20px" }}>
    //             <div className="drop-down-container__item">
    //               <span className="label">Campaign:</span>
    //               <span className="value name">Berlin</span>
    //             </div>
    //           </div>
    //         </Dropdown.Item>
    //       </Dropdown.Menu>
    //     </Dropdown>
    //     <button className="btn-row info">
    //       <img src={timeIcon} alt="timeIcon" />
    //     </button>
    //     {this.props.type === "single" &&
    //       this.props.campaignStatus === "Compose" && (
    //         <button
    //           className="btn-row time"
    //           onClick={() =>
    //             this.removeContactsFromCampaign([row], this.props.campaignId)
    //           }
    //         >
    //           <img src={iconRemove} alt="timeIcon" />
    //         </button>
    //       )}
    //     {this.props.type === "all" && (
    //       <button className="btn-row time">
    //         <img src={iconRemove} alt="timeIcon" />
    //       </button>
    //     )}
    //   </div>
    // );
  };

  findColor = (state) => {
    switch (state) {
      case "findColor":
        return "red";

      default:
        break;
    }
  };
  countSteps = (steps) => {
    let color = "";
    let quantity = 0;
    steps.step_executions.forEach((item) => {
      quantity++;
      color = this.findColor(item.state);
    });
    return {
      stepsColor: color,
      stepsQuantity: quantity,
    };
  };

  buildSteps = (stepsConfig, allStepsLength) => {
    let items = [];
    for (let j = 0; j < stepsConfig.stepsQuantity; j++) {
      items.push(
        <div
          key={j}
          className={`steps-container-item`}
          style={{ background: `${stepsConfig.stepsColor}` }}
        ></div>
      );
    }
    for (let j = stepsConfig.stepsQuantity; j < allStepsLength; j++) {
      items.push(
        <div
          key={j}
          className={`steps-container-item`}
          style={{ background: `#EBEDF2` }}
        ></div>
      );
    }
    return <div className="steps-container">{items}</div>;
  };

  renderSteps = (row, item) => {
    let items = [];
    for (let index in row) {
      row[index].is_visible &&
        items.push(
          <TooltipCustom key={index} title={row[index].hint} arrow={true}>
            <div
              className={`steps-container-item`}
              style={{
                background: `${statesDescriptor.detectColor(
                  item.human_state,
                  row[index]
                )}`,
              }}
            ></div>
          </TooltipCustom>
        );
    }

    return items;
  };

  /*renderSteps = (contact) => {
    let stepsConfig = {};
    if (!_.isEmpty(contact.campaignExecutions)) {
      stepsConfig = this.countSteps(contact.campaignExecutions);
      return this.buildSteps(
        stepsConfig,
        contact.campaignExecutions.step_executions.length
      );
    }
  };*/

  selectAllUsers = () => {
    this.setState(
      {
        selectAllUsersValue: !this.state.selectAllUsersValue,
      },
      () => {}
    );
  };

  deleteHandler = () => {
    this.setState({ isExcludeContactsOpened: false });
    if (this.state.isCampaignsRunning) {
      this.removeExecutionsFromCampaign(
        this.state.selected,
        this.props.campaignId
      );
    } else {
      this.removeContactsFromCampaign(
        this.state.selected,
        this.props.campaignId
      );
    }
  };
  onClickExclude = async () => {
    let preview = "";
    if (this.state.isCampaignsRunning) {
      this.setState({
        needLoader: true,
      });
      preview = await apiCampaign.getPreviewRemoveTargetAudience({
        filter:
          this.state.selectAllUsersValue && this.props.filterQueryExecutions
            ? `${this.props.filterQueryExecutions}${
                this.props.keywordsExecutions
                  ? "&$searchQuery=" + this.props.keywordsExecutions
                  : ""
              }`
            : this.state.selectAllUsersValue
            ? `campaign_id eq '${this.props.campaignId}'${
                this.props.keywordsExecutions
                  ? "&$searchQuery=" + this.props.keywordsExecutions
                  : ""
              }`
            : `id in (${this.state.selected.map((item) => `'${item}'`)})`,
      });
      this.setState({
        needLoader: false,
      });
    }

    if (
      preview &&
      ((this.props.campaignStatus === "Running" &&
        preview?.data?.validation_rules_summary?.length === 0) ||
        (this.props.campaignStatus === "Stopped" &&
          preview?.data?.validation_rules_summary?.length === 0) ||
        (this.props.campaignStatus === "Completed" &&
          preview?.data?.validation_rules_summary?.length === 0) ||
        (preview?.data?.validation_rules_summary?.length === 0 &&
          preview?.data?.valid_audience_count > 0))
    ) {
      this.setState({ isExcludeContactsOpened: true });
    } else {
      return this.setState({
        confirmationDialog: {
          id: this.state.companyId,
          status: true,
          data: preview.data,
        },
      });
    }
  };

  generationClassName = (scrollBarWidth) => {
    let checkbox =
      !this.props.campaignStatus || this.props.campaignStatus === "Compose";
    if (scrollBarWidth) {
      if (this.props.showSteps) {
        if (checkbox) {
          return "date w12";
        }

        return "date w14";
      }

      return "date w9";
    }

    if (this.props.showSteps) {
      if (checkbox) {
        return "date w13";
      }

      return "date w15";
    }

    return "date w10";
  };

  areDisabledSelectionActions() {
    let result = this.state.selected.length === 0;
    return result;
  }

  render() {
    const getDataClientWidth = (row) =>
      (row?.position?.length > 35 &&
        document.documentElement.clientWidth > 1200 &&
        document.documentElement.clientWidth < 1535) ||
      (row?.position?.length > 55 &&
        document.documentElement.clientWidth > 1535 &&
        document.documentElement.clientWidth < 1744) ||
      (row?.position?.length > 60 &&
        document.documentElement.clientWidth > 1744 &&
        document.documentElement.clientWidth < 1919) ||
      (row?.position?.length > 76 &&
        document.documentElement.clientWidth > 1919 &&
        document.documentElement.clientWidth < 2132) ||
      (row?.position?.length > 77 &&
        document.documentElement.clientWidth > 2132 &&
        document.documentElement.clientWidth < 2401);

    const getDataNameWidth = (row) => {
      const lengthRow = row.fullName?.length;
      return (
        (lengthRow > 14 &&
          document.documentElement.clientWidth > 1200 &&
          document.documentElement.clientWidth < 1535) ||
        (lengthRow > 22 &&
          document.documentElement.clientWidth > 1535 &&
          document.documentElement.clientWidth < 1744) ||
        (lengthRow > 30 &&
          document.documentElement.clientWidth > 1744 &&
          document.documentElement.clientWidth < 1919) ||
        (lengthRow > 35 &&
          document.documentElement.clientWidth > 1919 &&
          document.documentElement.clientWidth < 2132) ||
        (lengthRow > 43 &&
          document.documentElement.clientWidth > 2132 &&
          document.documentElement.clientWidth < 2401)
      );
    };
    return (
      <div
        className={`table-container ${this.props.classesContainer} ${
          this.props.contactsCount &&
          this.props.contactsCount > this.state.initialValuePagination
            ? "table-with-footer"
            : ""
        } ${
          this.props.type === "single" &&
          this.props.campaignStatus === "Compose" &&
          !(
            this.props.contactsCount &&
            this.props.contactsCount > this.state.initialValuePagination
          )
            ? "with-delete-single"
            : this.props.campaignStatus !== "Compose" &&
              this.props.contactsCount &&
              this.props.contactsCount > this.state.initialValuePagination
            ? "with-delete1"
            : "with-delete"
        }`}
      >
        <TableContainer
          className={this.props.checkboxs && "table-body with-checkboxs"}
        >
          {this.props.app.tableContentLoader &&
            !this.props.app.pageContentLoader && (
              <div className="table-material-loader-wrapper">
                <CircularProgress
                  color="primary"
                  size={60}
                  classes={{ colorPrimary: "material-loader" }}
                />
              </div>
            )}
          <Table
            className={"table users"}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                {this.props.checkboxs && (
                  <TableCell padding="checkbox" className="checkbox">
                    <div className="checkbox-container">
                      <Checkbox
                        indeterminate={
                          this.state.contactsOnPage &&
                          this.state.contactsOnPage.length <
                            this.state.selected.filter((item) =>
                              this.state.contactsOnPage.includes(item)
                            ).length
                        }
                        checked={
                          this.state.selected.length > 0 &&
                          this.state.contactsOnPage.length ===
                            this.state.selected.filter((item) =>
                              this.state.contactsOnPage.includes(item)
                            ).length
                        }
                        onChange={this.onSelectAllPageClick}
                        inputProps={{ "aria-label": "select all desserts" }}
                        indeterminateIcon={
                          <img src={notSelectedCheckboxIcon} alt="checkbox" />
                        }
                        icon={
                          <img src={notSelectedCheckboxIcon} alt="checkbox" />
                        }
                        checkedIcon={
                          <img
                            src={
                              this.props.contacts.length >
                              this.state.selectedValues
                                ? partiallySelectedCheckboxIcon
                                : selectedCheckboxIcon
                            }
                            alt="checkbox"
                          />
                        }
                      />
                    </div>
                  </TableCell>
                )}
                <TableCell className="name">
                  {this.state.selected.length > 0 ? (
                    <div className="selected-rows">
                      <>
                        {this.state.selectedValues > 0 && (
                          <div>
                            <span className="select-text">
                              {this.state.selectedValues} of{" "}
                              {this.props.contactsCount} selected |{" "}
                            </span>
                            {!this.state.selectAllUsersValue && (
                              <span
                                className="clear-all"
                                onClick={this.onSelectAllClick}
                              >
                                Select all
                              </span>
                            )}

                            {this.state.selectAllUsersValue && (
                              <span
                                className="clear-all"
                                onClick={this.onClearAllClick}
                              >
                                Clear
                              </span>
                            )}
                          </div>
                        )}
                        {this.props.checkboxs && (
                          <div className="btn-group-accent">
                            {this.props.type === "all" && (
                              <>
                                <div className="background-button">
                                  <ExportToCSVButton
                                    disabled={this.areDisabledSelectionActions()}
                                    isLoading={this.state.isLoadingCSV}
                                    onClick={() => {
                                      this.exportContacts();
                                    }}
                                    text="Export Contacts"
                                  ></ExportToCSVButton>
                                </div>
                                {/* const importFilter = `tags/any(c:c eq '${activeImportId}')`; */}
                                <div className="background-button">
                                  <ButtonCreateCampaigns
                                    from={this.props.from}
                                    activeImportId={this.props.activeImportId}
                                    type="add"
                                    classes={
                                      this.areDisabledSelectionActions()
                                        ? "btn with-transparent-background-disabled "
                                        : " btn with-transparent-background-active "
                                    }
                                    text="Add to campaign"
                                    selectAllUsersValue={
                                      this.state.selectAllUsersValue
                                    }
                                    disabled={this.areDisabledSelectionActions()}
                                    contacts={this.state.selected}
                                    clearCheckboxesFunc={this.clearCheckboxes}
                                    history={this.props.history}
                                    keywords={this.props.keywords}
                                  />
                                </div>
                                <div className="background-button">
                                  <ButtonCreateCampaigns
                                    from={this.props.from}
                                    activeImportId={this.props.activeImportId}
                                    type="new"
                                    classes={
                                      this.areDisabledSelectionActions()
                                        ? "btn with-transparent-background-disabled "
                                        : " btn with-transparent-background-active "
                                    }
                                    text="Create campaign"
                                    selectAllUsersValue={
                                      this.state.selectAllUsersValue
                                    }
                                    disabled={this.areDisabledSelectionActions()}
                                    contacts={this.state.selected}
                                    clearCheckboxesFunc={this.clearCheckboxes}
                                    history={this.props.history}
                                    keywords={this.props.keywords}
                                  />
                                </div>
                                <div className="background-button">
                                  <ResponsiveButton
                                    className={
                                      this.areDisabledSelectionActions()
                                        ? "btn-pagination-remove-disabled"
                                        : "btn-pagination-remove"
                                    }
                                    disabled={this.areDisabledSelectionActions()}
                                    onClick={() => {
                                      this.previewContacts();
                                    }}
                                  >
                                    <>
                                      <img
                                        src={
                                          this.areDisabledSelectionActions()
                                            ? iconRemoveDisabled
                                            : iconRemove
                                        }
                                        alt="timeIcon"
                                      />
                                      <span>Delete</span>
                                    </>
                                  </ResponsiveButton>
                                </div>
                              </>
                            )}
                            {this.props.type === "single" && (
                              <>
                                {this.props.campaignStatus !== "Compose" && (
                                  <div className="background-button">
                                    {this.props.profile.appProfile
                                      ?.isProPlanAccessible === false ? (
                                      <>
                                        <TooltipCustom
                                          title="Upgrade your plan to Pro"
                                          arrow={true}
                                        >
                                          <div className="wrapper-background-button-pro">
                                            <div className="background-button-pro">
                                              pro
                                            </div>
                                          </div>
                                        </TooltipCustom>
                                        <ExportToCSVButton
                                          disabled={true}
                                          onClick={() => {
                                            this.exportRecentActivities();
                                          }}
                                          isLoading={
                                            this.state.isLoadingInteractionLog
                                          }
                                          text="Export Interaction Log"
                                          isInteraction={true}
                                        />
                                      </>
                                    ) : (
                                      <ExportToCSVButton
                                        disabled={false}
                                        onClick={() => {
                                          this.exportRecentActivities();
                                        }}
                                        isLoading={
                                          this.state.isLoadingInteractionLog
                                        }
                                        text="Export Interaction Log"
                                        isInteraction={true}
                                      />
                                    )}
                                  </div>
                                )}
                                <div className="background-button">
                                  <ExportToCSVButton
                                    disabled={this.areDisabledSelectionActions()}
                                    onClick={() => {
                                      this.exportContactsForCampaign();
                                    }}
                                    isLoading={this.state.isLoadingCSVCampaigns}
                                    text="Export Contacts"
                                  ></ExportToCSVButton>
                                </div>
                              </>
                            )}
                            {this.props.type === "single" && (
                              <div className="background-button">
                                <button
                                  className={
                                    this.areDisabledSelectionActions()
                                      ? "btn-row-exclude-disabled"
                                      : "btn-row-exclude"
                                  }
                                  disabled={this.areDisabledSelectionActions()}
                                  onClick={() =>
                                    (this.state.isCampaignsRunning &&
                                      this.onClickExclude()) ||
                                    this.setState({
                                      isExcludeContactsOpened: true,
                                    })
                                  }
                                >
                                  {this.state.needLoader ? (
                                    <div className="container-loader-executions">
                                      <CircularProgress
                                        size={20}
                                        classes={{
                                          colorPrimary:
                                            "material-loader-accent",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <>
                                      <img
                                        src={
                                          this.areDisabledSelectionActions()
                                            ? iconExcludeDisabled
                                            : iconExclude
                                        }
                                        alt="timeIcon"
                                      />
                                      <span>Exclude</span>
                                    </>
                                  )}
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    </div>
                  ) : (
                    <div className="container-title-images">
                      <SortIcon
                        text={<p>Contact</p>}
                        isRunning={this.state.isRunningCampaigns}
                        sort={"full_name"}
                        idx={"contact"}
                        isFiltering={
                          this.state.isRunningCampaigns
                            ? this.props.isFilteringExecutions
                            : this.props.isFiltering
                        }
                        setIsFiltering={
                          this.state.isRunningCampaigns
                            ? this.props.setIsFilteringExecutions
                            : this.props.setIsFiltering
                        }
                        isSortedAsc={
                          this.state.isRunningCampaigns
                            ? this.props.isIconActiveExecutions
                            : this.props.isIconActive
                        }
                        setIsSortedAsc={
                          this.state.isRunningCampaigns
                            ? this.props.setActiveFilterIconExecutions
                            : this.props.setActiveFilterIcon
                        }
                      />
                    </div>
                  )}
                </TableCell>

                <TableCell
                  className={`${
                    this.state.isRunningCampaigns
                      ? "positionActive"
                      : "position"
                  }`}
                >
                  <div className="container-title-images">
                    <SortIcon
                      text={<p>Headline</p>}
                      isRunning={this.state.isRunningCampaigns}
                      sort={"occupation"}
                      idx={"headline"}
                      isFiltering={
                        this.state.isRunningCampaigns
                          ? this.props.isFilteringExecutions
                          : this.props.isFiltering
                      }
                      setIsFiltering={
                        this.state.isRunningCampaigns
                          ? this.props.setIsFilteringExecutions
                          : this.props.setIsFiltering
                      }
                      isSortedAsc={
                        this.state.isRunningCampaigns
                          ? this.props.isIconActiveExecutions
                          : this.props.isIconActive
                      }
                      setIsSortedAsc={
                        this.state.isRunningCampaigns
                          ? this.props.setActiveFilterIconExecutions
                          : this.props.setActiveFilterIcon
                      }
                    />
                  </div>
                </TableCell>
                <TableCell
                  className={
                    this.state.isRunningCampaigns ? "account" : "accountAccent"
                  }
                >
                  <div className="container-title-images">
                    <SortIcon
                      text={<p>Location</p>}
                      isRunning={this.state.isRunningCampaigns}
                      sort={"location"}
                      idx={"location"}
                      isFiltering={
                        this.state.isRunningCampaigns
                          ? this.props.isFilteringExecutions
                          : this.props.isFiltering
                      }
                      setIsFiltering={
                        this.state.isRunningCampaigns
                          ? this.props.setIsFilteringExecutions
                          : this.props.setIsFiltering
                      }
                      isSortedAsc={
                        this.state.isRunningCampaigns
                          ? this.props.isIconActiveExecutions
                          : this.props.isIconActive
                      }
                      setIsSortedAsc={
                        this.state.isRunningCampaigns
                          ? this.props.setActiveFilterIconExecutions
                          : this.props.setActiveFilterIcon
                      }
                    />
                  </div>
                </TableCell>
                {this.props.showSteps && this.state.isRunningCampaigns && (
                  <TableCell className="steps">
                    <div className="container-title-images">
                      {this.state.isRunningCampaigns && (
                        <SortIcon
                          text={<p>Status</p>}
                          isRunning={this.state.isRunningCampaigns}
                          sort={"state"}
                          idx={"status"}
                          isFiltering={
                            this.state.isRunningCampaigns
                              ? this.props.isFilteringExecutions
                              : this.props.isFiltering
                          }
                          setIsFiltering={
                            this.state.isRunningCampaigns
                              ? this.props.setIsFilteringExecutions
                              : this.props.setIsFiltering
                          }
                          isSortedAsc={
                            this.state.isRunningCampaigns
                              ? this.props.isIconActiveExecutions
                              : this.props.isIconActive
                          }
                          setIsSortedAsc={
                            this.state.isRunningCampaigns
                              ? this.props.setActiveFilterIconExecutions
                              : this.props.setActiveFilterIcon
                          }
                        />
                      )}
                    </div>
                  </TableCell>
                )}
                <TableCell
                  className={
                    this.state.isRunningCampaigns
                      ? "connection"
                      : "connectionAccent"
                  }
                >
                  <div className="container-title-images">
                    <SortIcon
                      text={<p>Last Action</p>}
                      isRunning={this.state.isRunningCampaigns}
                      sort={"contacted"}
                      idx={"contacted"}
                      isFiltering={
                        this.state.isRunningCampaigns
                          ? this.props.isFilteringExecutions
                          : this.props.isFiltering
                      }
                      setIsFiltering={
                        this.state.isRunningCampaigns
                          ? this.props.setIsFilteringExecutions
                          : this.props.setIsFiltering
                      }
                      isSortedAsc={
                        this.state.isRunningCampaigns
                          ? this.props.isIconActiveExecutions
                          : this.props.isIconActive
                      }
                      setIsSortedAsc={
                        this.state.isRunningCampaigns
                          ? this.props.setActiveFilterIconExecutions
                          : this.props.setActiveFilterIcon
                      }
                    />
                  </div>
                </TableCell>

                <TableCell
                  className={
                    this.state.isRunningCampaigns ? "date" : "dateAccent"
                  }
                >
                  <div className="container-title-images-accent">
                    <SortIcon
                      text={
                        <p>
                          {this.state.isRunningCampaigns ? "Added" : "Imported"}
                        </p>
                      }
                      isRunning={this.state.isRunningCampaigns}
                      sort={
                        this.state.isRunningCampaigns ? "created" : "imported"
                      }
                      idx={
                        this.state.isRunningCampaigns ? "created" : "imported"
                      }
                      isFiltering={
                        this.state.isCampaignsRunning
                          ? this.props.isFilteringExecutions
                          : this.props.isFiltering
                      }
                      setIsFiltering={
                        this.state.isCampaignsRunning
                          ? this.props.setIsFilteringExecutions
                          : this.props.setIsFiltering
                      }
                      isSortedAsc={
                        this.state.isCampaignsRunning
                          ? this.props.isIconActiveExecutions
                          : this.props.isIconActive
                      }
                      setIsSortedAsc={
                        this.state.isCampaignsRunning
                          ? this.props.setActiveFilterIconExecutions
                          : this.props.setActiveFilterIcon
                      }
                      status={this.props.type === "single"}
                    />
                  </div>
                </TableCell>
                {!this.props.checkboxs && (
                  <TableCell className="scroll"></TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody ref={this.wrapper}>
              {this.props.contacts.map((row, index) => (
                <TableRow key={row.id} className="row">
                  {this.props.checkboxs && (
                    <TableCell padding="checkbox" className="checkbox">
                      <div className="checkbox-container">
                        <Checkbox
                          onClick={(event) => this.handleClick(event, row.id)}
                          checked={this.isSelected(row.id)}
                          inputProps={{
                            "aria-labelledby": `enhanced-table-checkbox-${index}`,
                          }}
                          icon={
                            <img src={notSelectedCheckboxIcon} alt="checkbox" />
                          }
                          checkedIcon={
                            <img src={selectedCheckboxIcon} alt="checkbox" />
                          }
                        />
                      </div>
                    </TableCell>
                  )}

                  <TableCell className="name">
                    <div className="name-container">
                      <img
                        className="avatar"
                        src={row.avatar ? row.avatar : avatarSvg}
                        alt="avatar"
                      />
                      <div
                        className={
                          getDataNameWidth(row)
                            ? "text-box-wrapper-hover"
                            : "text-box-wrapper"
                        }
                      >
                        <TooltipCustom
                          title={getDataNameWidth(row) ? row.fullName : ""}
                          arrow
                        >
                          <div className="text-page first-name semi-bold ellipsis">
                            {row.fullName}
                          </div>
                        </TooltipCustom>
                      </div>
                      {row.connection && (
                        <div className="connection">
                          <p>•</p>
                          <div className="text-table-users">
                            {functions.numberWithEnding(row.connection)}
                          </div>
                        </div>
                      )}
                      <div
                        className={`text-page  regular ${
                          row.account ? "premium" : "free"
                        } ${
                          this.state.isRunningCampaigns
                            ? "account"
                            : "accountAccent"
                        }`}
                      >
                        {row.account ? (
                          <img src={inIcon} alt={"premium"} />
                        ) : (
                          <></>
                        )}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="#"
                          onClick={async (e) => {
                            e.preventDefault();
                            await this.openLinkedInProfile(row);
                          }}
                        >
                          <TooltipCustom
                            title="Open LinkedIn profile"
                            arrow={true}
                          >
                            <img
                              className="link-img-accent "
                              src={linkSvgGold}
                              alt="link svg"
                            />
                          </TooltipCustom>
                        </a>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    className={`text-page ${
                      this.state.isRunningCampaigns
                        ? "positionActive"
                        : "position"
                    } regular`}
                  >
                    <div
                      className={
                        getDataClientWidth(row)
                          ? "text-box-wrapper-hover"
                          : "text-box-wrapper"
                      }
                    >
                      <TooltipCustom
                        title={getDataClientWidth(row) ? row.position : ""}
                        arrow={true}
                      >
                        <p
                          className={
                            getDataClientWidth(row)
                              ? "ellipsis"
                              : "left-list-text"
                          }
                        >
                          {row.position}
                        </p>
                      </TooltipCustom>
                    </div>
                  </TableCell>
                  <TableCell
                    className={`text-page  regular ${
                      row.account ? "premium" : "free"
                    } ${
                      this.state.isRunningCampaigns
                        ? "account"
                        : "accountAccent"
                    }`}
                  >
                    <TooltipCustom
                      title={row.location?.length > 20 ? row.location : ""}
                      arrow={true}
                    >
                      <div className="flex-container-tooltip-accent">
                        <p
                          className={
                            row.location?.length > 20
                              ? "ellipsis"
                              : "left-list-text"
                          }
                        >
                          {row.location}
                        </p>
                      </div>
                    </TooltipCustom>
                  </TableCell>

                  {this.props.showSteps && this.state.isRunningCampaigns && (
                    <TableCell className="steps">
                      <div className="steps-container">
                        {row.campaignExecutions &&
                          this.renderSteps(
                            row.campaignExecutions.step_executions,
                            row.campaignExecutions
                          )}
                      </div>
                    </TableCell>
                  )}
                  <TableCell
                    className={
                      this.state.isRunningCampaigns
                        ? "connection"
                        : "connectionAccent"
                    }
                  >
                    <div className="flex-container-tooltip">
                      <p className="row-created">
                        {functions.parseDateToDMY(row.contacted, "/")}
                      </p>
                    </div>
                  </TableCell>
                  <TableCell
                    className={this.generationClassName(
                      this.state.scrollBarWidth
                    )}
                    style={{
                      paddingRight: this.state.scrollBarWidth ? "6px" : "18px",
                    }}
                  >
                    <div
                      className={`date-container ${
                        row.actions?.open_linkedin_profile.enabled
                          ? "single-action"
                          : ""
                      }`}
                    >
                      <div className="start-wrapper">
                        <div
                          className={`start-end-date ${
                            this.props.campaignStatus
                              ? this.props.campaignStatus
                              : ""
                          }`}
                        >
                          {functions.parseDateToDMY(row.date, "/")}
                        </div>
                        {(this.props.type === "single" ||
                          this.props.type === "all") &&
                          this.buttonsActions(row, this.props.type, index)}
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div
          className={
            this.props.contactsCount &&
            this.props.contactsCount > this.state.initialValuePagination
              ? "table-footer-accent"
              : "table-footer"
          }
        >
          <div className="table-footer-pagination">
            {this.props.contactsCount &&
            this.props.contactsCount > this.state.initialValuePagination ? (
              <TablePagination
                component="div"
                rowsPerPageOptions={[]}
                count={this.props.contactsCount}
                rowsPerPage={this.state.initialValuePagination}
                page={this.getNewPage()}
                onPageChange={this.handleChangePage}
                ActionsComponent={(subProps) => (
                  <MTablePagination {...subProps} />
                )}
              />
            ) : null}
          </div>
          <div
            className={
              this.props.contactsCount &&
              this.props.contactsCount > this.state.initialValuePagination
                ? "wrapper-select"
                : "wrapper-select-accent"
            }
          >
            <Select
              onFocus={() => {
                this.setState({
                  isOpenMenuPagination: true,
                });
              }}
              onBlur={() => {
                this.setState({
                  isOpenMenuPagination: false,
                });
              }}
              options={this.state.optionsSizePagination}
              onChange={(e) => {
                this.setState({
                  initialValuePagination: Number(e.value),
                });
                this.props.setInitialValuePagination(
                  JSON.stringify(Number(e.value))
                );
                localStorage.setItem(
                  "contactsPageSize",
                  JSON.stringify(Number(e.value))
                );
              }}
              value={this.state.optionsSizePagination.find(
                (el) => Number(el.value) === this.state.initialValuePagination
              )}
              styles={this.stylePagination}
            />
          </div>
        </div>
        <ModalWindow
          setIsDelete={(value) => {
            this.setState({ isDelete: value });
          }}
          open={this.state.isExcludeContactsOpened}
          closeText="No"
          confirmText="Yes"
          title={
            CONSTS.confirmationDialogs[
              this.state.isCampaignsRunning ? 1.8 : 1.6
            ].title
          }
          content={
            CONSTS.confirmationDialogs[
              this.state.isCampaignsRunning ? 1.8 : 1.6
            ].content
          }
          closeHandler={() => this.setState({ isExcludeContactsOpened: false })}
          confirmHandler={this.deleteHandler}
        />
        {this.state.isCampaignsRunning && (
          <ModalWindow
            open={this.state.confirmationDialog.status}
            closeHandler={() =>
              this.setState({
                confirmationDialog: {
                  id: null,
                  status: false,
                  data: {},
                },
              })
            }
            classes={"confirmation-launch-modal"}
          >
            <ConfirmationDialogExclude
              confirmationDialog={this.state.confirmationDialog}
              closeHandler={() =>
                this.setState({
                  confirmationDialog: {
                    id: null,
                    status: false,
                    data: {},
                  },
                })
              }
              exclude={this.removeExecutionsFromCampaign}
            />
          </ModalWindow>
        )}
        <ModalWindow
          open={this.state.confirmationDialogContacts.status}
          closeHandler={() =>
            this.setState({
              confirmationDialogContacts: {
                id: null,
                status: false,
                data: {},
              },
            })
          }
          classes={"confirmation-launch-modal"}
        >
          <ConfirmationDialogContacts
            confirmationDialogContacts={this.state.confirmationDialogContacts}
            closeHandler={() =>
              this.setState({
                confirmationDialogContacts: {
                  id: null,
                  status: false,
                  data: {},
                },
              })
            }
            deleted={this.deleteContacts}
          />
        </ModalWindow>

        <ModalWindow
          open={this.state.isDeleteContactsOpened}
          closeText="No"
          confirmText="Yes"
          closeHandler={() => this.setState({ isDeleteContactsOpened: false })}
          confirmHandler={this.deleteContacts}
          title={CONSTS.confirmationDialogs[1.9].title.replace(
            "{contacts_count}",
            this.state.selectedValues
          )}
          content={CONSTS.confirmationDialogs[1.9].content.replace(
            "{contacts_count}",
            this.state.selectedValues
          )}
        />
      </div>
    );
  }
}

export default connect()(TableUsers);
