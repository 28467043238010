import React, { Component } from "react";
import { connect } from "react-redux";
import { listeners } from "../../../api/listeners";
import linkIconAccent from "../../../assets/image/icons/svg/icons/linkIconAccent.svg";
// Components

import UserProfilePersona from "../userProfilePersona";
import NavigationMenu from "../../molecules/navigationMenu";
import Logo from "../../atoms/Logo";
import Notification from "../../molecules/notification";
import timer from "../../../assets/image/icons/svg/timer.svg";
import ResponsiveButton from "../../atoms/responsiveButton";
import { linkedInProfileOpener } from "../../../api/linkedInProfileOpener";
// Style
import "./style.scss";

export class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      timer: null,
      isTimerVisible: null,
      timerId: 0,
      prevTimerId: -1,
      localAppProfile: this.props.appProfile,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.appProfile &&
      this.props.appProfile !== prevProps.appProfile
    ) {
      this.setState({
        localAppProfile: this.props.appProfile,
      });
    }
    if (
      this.state.localAppProfile.impersonatedPersona &&
      !this.state.timer &&
      !this.state.isLoading
    ) {
      this.setState({
        timer: Date.parse(
          this.state.localAppProfile.impersonatedPersona.accessTokenExpiresAt
        ),
      });

      const timerId = setInterval(() => {
        this.setState({
          isTimerVisible: (this.state.timer - Date.now()) / 1000,
        });
        if (this.state.isTimerVisible && this.state.isTimerVisible < 30) {
          clearInterval(timerId);
        }
      }, 1000);
    }
    if (
      this.state.isTimerVisible &&
      this.state.isTimerVisible <= 30 &&
      this.state.timer &&
      !this.state.isLoading
    ) {
      this.signOut();
      this.setState({
        timer: null,
      });
    }
  }

  getCurrentAppProfile = () => {
    let result = this.props.localAppProfile.impersonatedPersona
      ? this.props.localAppProfile.impersonatedPersona
      : this.props.localAppProfile;
    return result;
  };

  openImpersonatedPersonaLinkedinProfile = async () => {
    await linkedInProfileOpener.open(
      this.props.connector,
      this.state.localAppProfile.impersonatedPersona.linkedInProfile
    );
  };

  signOut = async () => {
    this.setState({
      isLoading: true,
    });

    const connector = listeners.getConnector();
    await connector.auth.signOutUnderTeamMember();

    document.location.href = "/team/members";
  };

  isImpersonatedSignedIn = () => {
    let result = !!this.state.localAppProfile?.impersonatedPersona;
    return result;
  };

  render() {
    return (
      <>
        <div className="header">
          <Logo />
          <div className="header-navigation">
            <NavigationMenu appPage={this.props.appPage} />
            {this.isImpersonatedSignedIn() && (
              <div className="header__subscribe-accent">
                {this.state.isTimerVisible && this.state.isTimerVisible <= 300 && (
                  <>
                    <img alt="timer" src={timer} />
                    <span className="header__minutes">
                      {Math.round(this.state.isTimerVisible / 60)} min
                    </span>{" "}
                    <span className="header__line">|</span>
                  </>
                )}
                <ResponsiveButton
                  className={"button-stop-session"}
                  onClick={() => this.signOut()}
                  isLoading={this.state.isLoading}
                  disabled={false}
                >
                  {"Stop session"}
                </ResponsiveButton>
              </div>
            )}

            <div
              className={
                this.isImpersonatedSignedIn()
                  ? "header-navigation-profile-accent"
                  : "header-navigation-profile"
              }
            >
              {this.isImpersonatedSignedIn() && (
                <img
                  className="image-icon-accent"
                  onClick={this.openImpersonatedPersonaLinkedinProfile}
                  src={linkIconAccent}
                  alt="linkIcon"
                />
              )}

              <UserProfilePersona />
            </div>

            {this.props.notification.status && (
              <Notification
                text={this.props.notification.text}
                error={this.props.notification.error}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    appPage: state.app.appPage,
    notification: state.notification,
    appProfile: state.app.appProfile,
    pageContentLoader: state.app.pageContentLoader,
    activity: state.activity,
    subscription: state.app.subscription,
    connector: state.app.connector,
  };
};

export default connect(mapStateToProps)(Header);
