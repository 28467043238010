import { mapping } from "../../actions/mapping";
import { teams } from "../../actions/teams";
import { linkedInProfileOpener } from "../../api/linkedInProfileOpener";
import { listeners } from "../../api/listeners";
import { CONSTS } from "../../config/objectConst";
import { app } from "../../actions/app";
import store from "../../redux/store";
import members from "../../assets/image/icons/svg/people-group-active.svg";
import statistics from "../../assets/image/icons/svg/statisticsActive.svg";
import invited from "../../assets/image/icons/svg/sent-inventActive.svg";

export async function getMembers() {
  this.setState({ loader: true });

  try {
    const response = await teams.getMembers();

    this.setState({
      personas: mapping.buildPersonas(response.data.documents),
    });
  } finally {
    this.setState({ loader: false });
  }
}

export function getCurrentAppProfile() {
  let result = this.state.localAppProfile?.impersonatedPersona
    ? this.state.localAppProfile.impersonatedPersona
    : this.state.localAppProfile;
  return result;
}

export const openLinkedinProfile = async () => {
  const connector = listeners.getConnector();
  await linkedInProfileOpener.open(connector, this.props.profile.linkedInfo);
};

export function isSelfBoughSubscription() {
  let appProfile = getCurrentAppProfile.call(this);
  let result = appProfile.personaId === appProfile.subscriptionOwner_id;
  return result;
}

export function isCurrentUserOwner() {
  let appProfile = getCurrentAppProfile.call(this);
  let result = appProfile.teamRole === CONSTS.ROLES.Owner;
  return result;
}

export function isLimitedTeamMember() {
  let appProfile = getCurrentAppProfile.call(this);
  let result = !appProfile.isTeamPlanAccessible && appProfile.teamId;
  return result;
}

export function showMembers() {
  let appProfile = getCurrentAppProfile.call(this);
  let result = appProfile.isTeamPlanAccessible && appProfile.teamId;
  return result;
}

export function showOnBoardingScreen() {
  let appProfile = getCurrentAppProfile.call(this);
  let result =
    !appProfile.teamId &&
    (appProfile.isTeamPlanAccessible ||
      (!appProfile.isTeamPlanAccessible && !appProfile.subscriptionIsActive));
  return result;
}

export function showAddNewMember() {
  let appProfile = getCurrentAppProfile.call(this);
  let result = appProfile.teamId && appProfile.isTeamPlanAccessible;
  return result;
}

export function showInvitedMembers() {
  let appProfile = getCurrentAppProfile.call(this);
  let result =
    this.state.isInviteButtonVisible && appProfile.isTeamPlanAccessible;
  return result;
}

export function showRequirePermissions() {
  let result =
    isLimitedTeamMember.call(this) &&
    !isCurrentUserOwner.call(this) &&
    !isSelfBoughSubscription.call(this);
  return result;
}

export function showRequireSubscriptionUpgrade() {
  let appProfile = getCurrentAppProfile.call(this);
  let result =
    (isLimitedTeamMember.call(this) && !showRequirePermissions.call(this)) ||
    (!appProfile.isTeamPlanAccessible &&
      !appProfile.teamId &&
      appProfile.subscriptionIsActive);
  return result;
}

export function setTabs(check) {
  const items = [
    {
      id: 1,
      image: members,
      title: "Members",
      link: "/team/members",
    },
    {
      id: 2,
      image: statistics,
      title: "Statistics",
      link: "/team/statistics",
      condition: showMembers,
    },
    {
      id: 3,
      image: invited,
      title: "Pending invites",
      link: "/team/invitations",
      condition: showInvitedMembers,
    },
  ];

  const bottomList = items.filter(
    (item) => !check || !item.condition || item.condition.call(this)
  );

  store.dispatch({
    type: "LEFT_SIDE_MENU_LIST",
    payload: {
      leftSide: {
        type: "team",
        bottomList,
      },
    },
  });
  return true;
}

export function updateComponentTeam(prevProps, prevState) {
  if (
    this.state.personas.length > 0 &&
    this.state.personas !== prevState.personas &&
    this.state.personas.find((el) => el.pending)
  ) {
    this.setState({ isInviteButtonVisible: true });
  } else if (
    this.state.personas.length > 0 &&
    this.state.personas !== prevState.personas &&
    !this.state.personas.find((el) => el.pending)
  ) {
    this.setState({ isInviteButtonVisible: false });
  }
  if (this.props.appProfile && this.props.appProfile !== prevProps.appProfile) {
    this.setState({
      localAppProfile: this.props.appProfile,
    });
  }

  if (
    this.state.isInviteButtonVisible &&
    this.state.isInviteButtonVisible !== prevState.isInviteButtonVisible
  ) {
    setTabs.call(this);
  }
}

export async function mountComponentTeam() {
  if (showMembers.call(this)) {
    await getMembers.call(this);
  }
  (this.props.leftSide.bottomList.length === 0 ||
    this.props.leftSide.type !== "team") &&
    setTabs.call(this, true);
}
